import {
	UserTypes,
	UserType,
	markKeysForbidden,
} from "@app/api/helper-schemas";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";

export const UserProfileSchema = Joi.object({
	id: UnsignedIntegerSchema.required(),
	userId: UnsignedIntegerSchema.required(),
	userType: Joi.string()
		.valid(...UserTypes)
		.required(),
	school: UnsignedIntegerSchema.allow(null).required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IUserProfile {
	id: number;
	userId: number;
	userType: UserType;
	school: number | null;
	createdAt: Date;
	updatedAt: Date;
}

export const RUserProfileSchema = UserProfileSchema.fork(
	"id",
	markKeysForbidden
);
export type IRUserProfile = Omit<IUserProfile, "id">;
