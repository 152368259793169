import React from "react";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import styles from "./styles/tv-school-landing.module.css";
import { ReactComponent as CloseIcon } from "./styles/imgs/close.svg";
import { FormattedMessage } from "react-intl";
import { useLocale } from "@app/hooks/intl";

export const useVideoInstructionId = () => {
	const locale = useLocale();
	return locale === "en" ? "-RLUtRSKc-4" : "eRAU-xXZHJA";
};

export const VideoInstruction: React.FC<{
	onClose: () => void;
	videoId?: string;
}> = React.memo(({ onClose, videoId }) => {
	const defaultId = useVideoInstructionId();
	const id = videoId || defaultId;
	const closeIcon = (
		<div className={styles.closeIconWithTextContainer}>
			<FormattedMessage id="student:prodTour.close" />
			<CloseIcon className={styles.closeIcon} />
		</div>
	);
	return (
		<div>
			<Popup onClose={onClose}>
				<PopupContentWithClose
					onClose={onClose}
					icon={closeIcon}
					popupBodyClassname={styles.popupBodyClassname}
					className={styles.popup}
				>
					<iframe
						className={styles.videoContainer}
						allowTransparency={true}
						frameBorder={0}
						style={{ backgroundColor: "white" }}
						allow="autoplay"
						src={`https://www.youtube.com/embed/${id}?rel=0;autoplay=1&mute=0&enablejsapi=1;playlist=${id}&loop=1;allow='autoplay'`}
						title={"publicVideo"}
					></iframe>
				</PopupContentWithClose>
			</Popup>
		</div>
	);
});
