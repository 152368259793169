import { CometChat } from "@cometchat-pro/chat";
import { AccessibleUserListRequest } from "../../../../extensions/accessible-users";

export class UserListManager {

    userRequest = null;
    userListenerId = "userlist_" + new Date().getTime();

	constructor({ searchKey, userId }) {
		this.usersRequest = new AccessibleUserListRequest({ userId, searchKey });
    }

    fetchNextUsers() {
        return this.usersRequest.fetchNext();
    }

    attachListeners(callback) {
        
        CometChat.addUserListener(
            this.userListenerId,
            new CometChat.UserListener({
                onUserOnline: onlineUser => {
                    /* when someuser/friend comes online, user will be received here */
                    callback(onlineUser);
                },
                onUserOffline: offlineUser => {
                    /* when someuser/friend went offline, user will be received here */
                    callback(offlineUser);
                }
            })
        );
    }

    removeListeners() {

        CometChat.removeUserListener(this.userListenerId);
    }
}