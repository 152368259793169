import Joi from "@app/utils/joi";
import { ObjectId } from "../../utils/generics";

export const FileShortInfoSchema = Joi.object({
	fileName: Joi.string().required(),
	fileUrl: Joi.string().required(),
});

export interface IFileShortInfo {
	fileName: string;
	fileUrl: string;
}

export interface IUserAttachment {
	_id: ObjectId;
	file: IFileShortInfo;
	author: number;
	date: Date;
}
export interface IAPOSTUploadUserAttachment {
	file: IFileShortInfo;
	date: Date;
}

export interface IAPOSTUploadAttachment {
	file: IFileShortInfo;
	author: number;
	date: Date;
}

export interface IADELETEUserAttachment {
	_id: ObjectId;
}
