import React, { useCallback, useState } from "react";
import { DynamicContext } from "react-flexible-contexts";

export const playground1Id = "classrooms_app_playground1";

type AddElement = (
	fn: (args: { onClose: () => void }) => React.ReactNode
) => void;

const PlaygroundContext = DynamicContext.createDestructured<{
	addElement: AddElement;
}>();

export const useAddElementFn = () =>
	PlaygroundContext.useProperty("addElement");

export const ComponentsPlayground = React.memo<{ children: React.ReactNode }>(
	function ComponentsPlayground({ children }) {
		const [elements, setElements] = useState<
			{
				jsx: React.ReactNode;
				key: string;
			}[]
		>([]);

		const addElement: AddElement = useCallback(fn => {
			const key = Math.random() + "-" + Math.random();
			const onClose = () =>
				setElements(current => current.filter(e => e.key !== key));
			const jsx = fn({ onClose });
			setElements(current => [...current, { jsx, key }]);
		}, []);

		return (
			<PlaygroundContext.Provider addElement={addElement}>
				<div id={playground1Id} />
				{elements.map(({ jsx, key }) => (
					<React.Fragment key={key}>{jsx}</React.Fragment>
				))}
				{children}
			</PlaygroundContext.Provider>
		);
	}
);
