import { CodeInput } from "@app/components/auth/input-forms";
import {
	Button,
	openConfirmationPopup,
} from "@app/components/widgets/confirmation-popup";
import { useBoolean, useErrors } from "@app/hooks/general";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import ReplayIcon from "@material-ui/icons/Replay";
import React, { useCallback, useState } from "react";
import genStyles from "./styles/general_styles.module.css";

export const CheckCode: React.FC<{
	setDidPasswordUpdate: (args: boolean) => void;
	mobileRef: React.MutableRefObject<string>;
	codeRef: React.MutableRefObject<string>;
	setToken: (args: string) => void;
	token: string;
}> = props => {
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const user = useClassroomsUser()!;

	const [counter, setCouter] = useState(0);

	const { errors, removeErrorMessage, setErrors } = useErrors<{
		code?: string;
	}>(null);

	const onCodeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			props.codeRef.current = e.target.value;
			removeErrorMessage("code");
		},
		[props.codeRef, removeErrorMessage]
	);

	const checkCode = useCallback(() => {
		startLoading();
		const UsersController = inject("UsersController");

		UsersController.verifyMobileCode({
			code: props.codeRef.current,
			token: props.token,
			mobile: props.mobileRef.current,
		})
			.then(() => {
				stopLoading();
				props.setDidPasswordUpdate(true);
			})
			.catch(error => {
				if (
					error.response &&
					error.response.data === "incorrect code"
				) {
					setErrors({
						code: getFormattedMessage(
							"validations.specialCodeNotCorrect"
						),
					});
				}

				stopLoading();
			});
	}, [props, startLoading, stopLoading, setErrors]);

	const sendCodeRequest = useCallback(() => {
		startLoading();
		const UsersController = inject("UsersController");
		UsersController.sendVerificationCode({
			mobile: props.mobileRef?.current,
			userId: user.id,
		})
			.then(({ token }) => {
				props.setToken(token);
				stopLoading();
			})

			.catch(e => {
				stopLoading();
			});
	}, [props, startLoading, stopLoading, user.id]);

	const onClickResendCode = useCallback(() => {
		setCouter(x => x + 1);
		if (counter <= 10) {
			sendCodeRequest();
		} else {
			openConfirmationPopup({ text: getFormattedMessage("errorAlert") });
		}
	}, [counter, sendCodeRequest]);

	return (
		<div className={genStyles.personal_info_form}>
			<h2 className={genStyles.personal_info_form__title}>
				ტელეფონი <span>(დაადასტურეთ მობილური ნომერი)</span>
			</h2>

			<section className={genStyles.personal_info_form__input_wrapper}>
				<section className={genStyles.confirm_code}>
					<CodeInput
						onChange={onCodeChange}
						isDisabled={isLoading}
						errorText={!errors ? undefined : errors.code}
						placeholder={"შეიყვანეთ კოდი"}
						className={genStyles.confirmationInput}
						fullWidthInput={{ width: "100%" }}
					/>
				</section>

				<section
					className={genStyles.arrow_loader}
					onClick={onClickResendCode}
				>
					<ReplayIcon
						style={{
							fill: "#5273e6",
							width: "40px",
							fontSize: "36px",
						}}
					/>
				</section>
				<Button
					isLoading={isLoading}
					onClick={checkCode}
					className={genStyles.personal_info_form__code_btn}
					text={"დადასტურება"}
				/>
			</section>
			<p className={genStyles.personal_info_form__text}>
				* თქვენს ნომერზე გამოგზავნილია ერთჯერადი კოდი
			</p>
		</div>
	);
};
