import * as React from "react";
import memoizeOne from "memoize-one";
import { AnyComponent } from "../../../../utils/generics";
import { css } from "emotion";
import { IStatement } from "../../../../schemas/questions/contnets/common-schemas";
import {
	mergeComponentObjects,
	mergeStylesObjects,
	IAnyObj,
} from "../../../../utils";
import { normalizeHTML } from "../../../editor/html";
import { covertASCIIMathToLaTex } from "../../../editor/math";

export interface IItemContainerComponents {
	text?: AnyComponent<IItemTextProps>;
}
export interface IItemClassNames {
	text?: string;
}

export type IItemContainerClassNames = IItemClassNames & {
	container?: string;
};

export interface IItemContainerProps {
	statement: IStatement;
	components?: IItemContainerComponents;
	styles?: IItemContainerClassNames;
	index: number;
	extraInfo?: IAnyObj;
	helperProps?: any;
}

const itemContainerClass = css({
	// background: "red",
});

export class ItemContainer extends React.PureComponent<IItemContainerProps> {
	defaultStyles = {
		container: itemContainerClass,
		text: statementClassName,
	};

	defaultComponents = {
		text: ItemText,
	};

	getStyles = memoizeOne((styles: IItemContainerProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});
	getComponents = memoizeOne(
		(components: IItemContainerProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	render() {
		const components = this.getComponents(this.props.components);
		const styles = this.getStyles(this.props.styles);
		return (
			<div className={styles.container}>
				<components.text
					extraInfo={this.props.extraInfo}
					stat={this.props.statement}
					className={styles.text}
					helperProps={this.props.helperProps}
				/>
			</div>
		);
	}
}

export interface IItemTextProps {
	stat: IStatement;
	className?: string;
	index?: number;
	extraInfo?: IAnyObj;
	helperProps?: any;
}

const statementClassName = css({
	// display: "block",
});

export const ItemText: React.FC<IItemTextProps> = props => {
	return (
		<div
			className={props.className}
			dangerouslySetInnerHTML={{
				__html: covertASCIIMathToLaTex(
					normalizeHTML(props.stat.text || "")
				),
			}}
		/>
	);
};
