import React, { useCallback } from "react";
import Styles from "./styles/LogOutComponent.module.css";
import { useHistory } from "@app/hooks/front";
import { inject } from "@app/modules";

const LogOutComponent = React.memo(() => {
	const history = useHistory();
	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout();
		history.push(`/`);
	}, [history]);

	return (
		<section className={Styles.LogOut_wrapper} onClick={onLogout}>
			<img src="/imgs/Log_Out.svg" alt="" />
		</section>
	);
});

export default LogOutComponent;
