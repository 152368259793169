import Joi from "@app/utils/joi";
import { ISchoolStructure, SchoolStructureSchema } from "./helper-schemas";

export const AGETSchoolStructureSchema = Joi.object({
	schoolId: Joi.number().required(),
});

export interface IAGETSchoolStructure {
	schoolId: number;
}

export const RGETSchoolStructureSchema = SchoolStructureSchema;
export type IRGETSchoolStructure = ISchoolStructure;
