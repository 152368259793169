export function getHTMLElementCoords(elem: HTMLElement) {
	// crossbrowser version
	const box = elem.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const scrollLeft =
		window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	const clientTop = docEl.clientTop || body.clientTop || 0;
	const clientLeft = docEl.clientLeft || body.clientLeft || 0;

	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;

	return { top, left };
}

export const animateWindowScroll = (
	whereToScrollTop: number,
	duration: number,
	initialScrollTop?: number
) => {
	const scrollTop =
		initialScrollTop !== undefined
			? initialScrollTop
			: window.pageYOffset || document.documentElement!.scrollTop;
	const startTime = performance.now();
	const animateScroll = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		window.scrollTo(
			0,
			percentage * (whereToScrollTop - scrollTop) + scrollTop
		);
		if (percentage < 1) window.requestAnimationFrame(animateScroll);
	};
	window.requestAnimationFrame(animateScroll);
};

export const animateCallback = (
	startValue: number,
	endValue: number,
	duration: number,
	cb: (newVal: number) => void
) => {
	const startTime = performance.now();
	const animate = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		const newVal = percentage * (endValue - startValue) + startValue;
		cb(newVal);
		if (percentage < 1) window.requestAnimationFrame(animate);
	};
	window.requestAnimationFrame(animate);
};
