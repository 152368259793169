import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { BasicDocumentSchema, BasicDocument } from "@app/api/interface";

const SchoolLabelCategorySchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	parentCategoryIds: Joi.array()
		.items(Joi.objectId())
		.min(1)
		.allow(null)
		.required(),
});

export interface ISchoolLabelCategory {
	_id: ObjectId;
	name: string;
	parentCategoryIds: ObjectId[] | null;
}

const SchoolLabelSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	categoryId: Joi.objectId().required(),
	parentLabelIds: Joi.array()
		.items(Joi.objectId())
		.min(1)
		.allow(null)
		.required(),
});

export interface ISchoolLabel {
	_id: ObjectId;
	name: string;
	categoryId: ObjectId;
	parentLabelIds: ObjectId[] | null;
}

export const SchoolStructureSchema = BasicDocumentSchema.keys({
	schoolId: Joi.number().required(),
	labelCategories: Joi.array()
		.items(SchoolLabelCategorySchema)
		.required(),
	labels: Joi.array()
		.items(SchoolLabelSchema)
		.required(),
});

export interface ISchoolStructure extends BasicDocument {
	schoolId: number;
	labelCategories: ISchoolLabelCategory[];
	labels: ISchoolLabel[];
}
