import { Conference } from "@app/models/conference";
import { inject } from "@app/modules";
import { removeKeys } from "@app/utils/common";
import { OptionalKeys } from "@app/utils/generics";
import { collmise } from "collmise";
import stringify from "json-stable-stringify";
import { IRequest } from "../requests";
import { IConference } from "./helper-schemas";
import {
	APOSTCreateSchoolConferenceSchema,
	APUTUpdateSchoolConferenceSchema,
	IADELETESchoolConference,
	IAGETSchoolConference,
	IAGETSchoolConferences,
	IAPOSTCreateSchoolConference,
	IAPUTUpdateSchoolConference,
	IRGETSchoolConference,
	IRPOSTCreateSchoolConference,
	IRPUTUpdateSchoolConference,
	RGETSchoolConferenceSchema,
	RGETSchoolConferencesSchema,
	RPOSTCreateSchoolConferenceSchema,
	RPUTUpdateSchoolConferenceSchema,
} from "./validators";

export class ConferencesController {
	private readonly Request: IRequest;

	private readonly _ConferenceModel = inject("ConferenceModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (args: IAPOSTCreateSchoolConference): Promise<Conference> =>
		this.Request.send("POST", "/api/conferences", args, null, {
			requestSchema: APOSTCreateSchoolConferenceSchema,
			responseSchema: RPOSTCreateSchoolConferenceSchema,
		}).then((data: IRPOSTCreateSchoolConference) => {
			return this._ConferenceModel.loadOneSync(data);
		});

	update = async (args: IAPUTUpdateSchoolConference): Promise<Conference> =>
		this.Request.send("PUT", "/api/conferences/:_id", args, null, {
			requestSchema: APUTUpdateSchoolConferenceSchema,
			responseSchema: RPUTUpdateSchoolConferenceSchema,
		}).then((data: IRPUTUpdateSchoolConference) => {
			return this._ConferenceModel.loadOneSync(data);
		});

	delete = async (args: IADELETESchoolConference): Promise<void> =>
		this.Request.send("DELETE", "/api/conferences/:_id", args, null).then(
			() => {
				this._ConferenceModel.deleteByIdSync(args._id);
			}
		);

	conferenceQueryGetter = collmise<
		OptionalKeys<IAGETSchoolConferences, "schoolLabelId">,
		IConference[],
		Conference[]
	>({
		serializeId: stringify,
		dataTransformer: (data, query) => {
			const oldIds = (this._ConferenceModel.findByQuery(query) || []).map(
				e => e._id
			);
			this._ConferenceModel.meta.updateLoadTime(query);
			return this._ConferenceModel.loadManySync(data, oldIds);
		},
		findCachedData: query => {
			if (this._ConferenceModel.meta.isLoaded(query)) {
				return this._ConferenceModel.findByQuery(query);
			}
		},
	});

	getBySchool = async (
		args: IAGETSchoolConferences
	): Promise<Conference[]> => {
		const modifiedQuery: OptionalKeys<
			IAGETSchoolConferences,
			"schoolLabelId"
		> = // if schoolLabelId is null, search in entire school
			args.schoolLabelId === null
				? removeKeys(args, "schoolLabelId")
				: args;
		return this.conferenceQueryGetter.on(modifiedQuery).request(() =>
			this.Request.send("GET", "/api/conferences", args, null, {
				responseSchema: RGETSchoolConferencesSchema,
			})
		);
	};

	getById = async (
		args: IAGETSchoolConference,
		loadFresh?: boolean
	): Promise<Conference> => {
		if (!loadFresh) {
			const conference = this._ConferenceModel.findByIdSync(args._id);
			if (conference) return conference;
		}
		return this.Request.send("GET", "/api/conferences/:_id", args, null, {
			responseSchema: RGETSchoolConferenceSchema,
		}).then((data: IRGETSchoolConference) => {
			return this._ConferenceModel.loadOneSync(data);
		});
	};
}
