import { indexArray } from "./array";

export const tryPromiseMultipleTimes = async <T extends any>({
	getPromise,
	maxTrials,
	delayBetweenTrials = 0,
}: {
	getPromise: () => Promise<T>;
	maxTrials: number;
	delayBetweenTrials: number;
}): Promise<T> => {
	if (maxTrials <= 0) {
		throw new Error("maxTrials must be positive");
	}
	let lastError: any = null;
	for (let i = 0; i < maxTrials; i++) {
		try {
			return await getPromise();
		} catch (e) {
			lastError = e;
			await delayPromise(delayBetweenTrials);
		}
	}
	throw lastError;
};

export const delayPromise = (milliseconds: number) =>
	new Promise(resolve => setTimeout(resolve, milliseconds));

// eslint-disable-next-line max-lines-per-function
export const PromiseConcurrent = async <K>(
	promises: readonly ((() => Promise<K>) | (() => PromiseLike<K>) | null)[],
	concurrentcy = Infinity,
	delayInMs = 0
): Promise<K[]> => {
	if (promises.length === 0) return [];
	const wait = () => delayPromise(delayInMs);
	return new Promise<K[]>(async (resolve, rej) => {
		let isCancelled = false;
		const reject = (e: any) => {
			isCancelled = true;
			rej(e);
		};
		try {
			const result: K[] = [];
			let numOfConcurrentPromises = 0;
			let lastIndex = -1;

			const callNext = async () => {
				try {
					if (isCancelled) return;
					numOfConcurrentPromises++;
					lastIndex++;
					const myIndex = lastIndex;
					if (!promises[myIndex]) {
						finishPromise(promises[myIndex] as any, myIndex);
						return;
					}
					const promise = promises[myIndex]!() as Promise<K>;
					const data = await promise;
					finishPromise(data, myIndex);
				} catch (e) {
					reject(e);
				}
			};
			const finishPromise = (data: K, index) => {
				if (isCancelled) return;
				result[index] = data;
				numOfConcurrentPromises--;
				if (
					lastIndex === promises.length - 1 &&
					numOfConcurrentPromises === 0
				) {
					resolve(result);
					return;
				}
				wait().then(() => {
					if (
						numOfConcurrentPromises < concurrentcy &&
						lastIndex < promises.length - 1
					) {
						callNext();
					}
				});
			};
			const n = Math.min(concurrentcy, promises.length);
			for (let i = 0; i < n; i++) {
				await wait();
				if (lastIndex < i) callNext();
			}
		} catch (e) {
			reject(e);
		}
	});
};
