import * as React from "react";
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowDropUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckIcon from "@material-ui/icons/Check";
import DragIndicatorIcon from "../../../icons/drag";
import memoizeOne from "memoize-one";
import { AnyComponent, NotUndefined } from "../../../../utils/generics";
import { css, cx } from "emotion";
import { IItemTextProps, ItemText } from "../common/stats";
import {
	IRSortItemsContent,
	ISortItemsContent,
	ISortItemsUserAns,
} from "../../../../schemas/questions/contnets/sort-items/schema";
import { mergeComponentObjects, mergeStylesObjects } from "../../../../utils";

export type SIItem =
	| IRSortItemsContent["items"][number]
	| ISortItemsContent["items"][number];

export interface IItemStyles {
	label?: string;
	container?: {
		general?: string;
		correct?: string;
		incorrect?: string;
	};
	item?: string;
	icon?: IIconStyles;
}

interface IItemMainProps {
	index: number;
	id: number;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	correctIndex?: number;
}

export interface IItemComponents {
	text?: AnyComponent<IItemTextProps>;
	containerBefore?: AnyComponent<IItemMainProps>;
	containerAfter?: AnyComponent<IItemMainProps>;
	icon?: AnyComponent<IIconProps>;
}

export interface IItemProps {
	index: number;
	item: SIItem;
	components?: IItemComponents;
	userAnswer?: ISortItemsUserAns;
	styles?: IItemStyles;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	correctIndex?: number;
}

const itemLabelClassName = css({
	display: "table",
	cursor: "grab",
	"&>*": {
		display: "table-cell",
		verticalAlign: "middle",
		padding: 4,
		position: "relative",
	},
});

const itemContainerCSS = css`
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 20px;
`;
const itemContainerCorrectCSS = css`
	border-color: green;
`;
const itemContainerIncorrectCSS = css`
	border-color: red;
`;

class Item extends React.PureComponent<IItemProps> {
	getCheckMark = memoizeOne(
		(dragIndicator?: AnyComponent<any>): AnyComponent<any> => {
			if (!dragIndicator) return 5 as any;
			return dragIndicator;
		}
	);
	// tslint:disable:bool-param-default
	getMainProps = memoizeOne(
		(
			index: number,
			displayAnswer: boolean,
			disableEditingAnswer: boolean,
			correctIndex?: number
		): IItemMainProps => {
			return {
				index,
				id: this.props.item.id,
				displayAnswer,
				disableEditingAnswer,
				correctIndex,
			};
		}
	);

	getComponents = memoizeOne((components: IItemProps["components"]) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	defaultStyles = {
		label: itemLabelClassName,
		container: {
			general: itemContainerCSS,
			correct: itemContainerCorrectCSS,
			incorrect: itemContainerIncorrectCSS,
		},
	} as IItemStyles;

	defaultComponents = {
		icon: ItemIcon,
		text: ItemText,
	} as NotUndefined<IItemProps["components"]>;

	getStyles = memoizeOne((styles: IItemProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	render() {
		const { index, correctIndex } = this.props;
		const styles = this.getStyles(this.props.styles);
		const components = this.getComponents(this.props.components);
		const QChoiceStat = components.text!;
		const Icon = components.icon!;
		const mainProps = this.getMainProps(
			index,
			this.props.displayAnswer,
			this.props.disableEditingAnswer,
			this.props.correctIndex
		);
		let containerCSS = styles.container!.general;
		if (this.props.displayAnswer && typeof correctIndex !== "undefined") {
			if (index === correctIndex) {
				containerCSS = cx(containerCSS, styles.container!.correct);
			} else {
				containerCSS = cx(containerCSS, styles.container!.incorrect);
			}
		}
		return (
			<div style={{ padding: "5px 0" }}>
				{components.containerBefore && (
					<components.containerBefore {...mainProps} />
				)}
				<div className={containerCSS}>
					<div className={styles.label}>
						<Icon {...mainProps} styles={styles.icon} />
						<QChoiceStat
							index={index}
							stat={this.props.item}
							className={styles.item}
						/>
					</div>
				</div>
				{components.containerAfter && (
					<components.containerAfter {...mainProps} />
				)}
			</div>
		);
	}
}

const dragIconClassName = css({
	display: "flex",
	cursor: "grab",
	alignItems: "center",
	minWidth: 40,
	justifyContent: "space-around",
	fontSize: 20,
});

const dragContainerClassName = css({});

export interface IIconStyles {
	container?: string;
	general?: string;
	correct?: string;
	incorrect?: string;
}

export interface IIconProps {
	index: number;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	correctIndex?: number;
	styles?: IIconStyles;
}

class ItemIcon extends React.PureComponent<IIconProps> {
	defaultStyles = {
		general: dragIconClassName,
		container: dragContainerClassName,
	} as IIconStyles;

	getStyles = memoizeOne((styles: IIconProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	render() {
		const { index, correctIndex } = this.props;
		const styles = this.getStyles(this.props.styles);
		let IconComponent: AnyComponent<any> | undefined;
		if (!this.props.disableEditingAnswer && !this.props.displayAnswer) {
			IconComponent = DragIndicatorIcon;
		} else if (
			this.props.displayAnswer &&
			typeof correctIndex !== "undefined"
		) {
			if (index === correctIndex) {
				IconComponent = CheckIcon;
			} else if (index > correctIndex) {
				IconComponent = ArrowDropUpIcon;
			} else {
				IconComponent = ArrowDropDownIcon;
			}
		}
		return (
			<div className={styles.container}>
				<div className={styles.general}>
					<span>{this.props.index + 1}</span>
					{!!IconComponent && <IconComponent />}
				</div>
			</div>
		);
	}
}

export { Item };
