import { MarginContainer } from "@app/components/ui/containers";
import { as } from "@app/utils/common";
import { useResponsiveSize } from "@pckgs/responsive/resize";
import * as React from "react";
import styles from "./inline-select-styles.module.scss";

interface InlineSelectProps<V extends string | number> {
	options: { value: V; label: string }[];
	itemStyle?: React.CSSProperties;
	itemClassName?: string;
	direction?: "column" | "row";
}

interface InlineMultipleSelect<V extends string | number>
	extends InlineSelectProps<V> {
	value: V[] | null;
	onChange: (values: V[]) => void;
	multiple: true;
}

interface InlineSingleSelect<V extends string | number>
	extends InlineSelectProps<V> {
	value: V | null;
	onChange: (value: V) => void;
	multiple?: false;
}

interface InlineSelectType {
	<V extends string | number>(
		props: InlineMultipleSelect<V>
	): JSX.Element | null;
	<V extends string | number>(
		props: InlineSingleSelect<V>
	): JSX.Element | null;
}

export const InlineSelect: InlineSelectType = React.memo(function InlineSelect({
	options,
	value,
	onChange,
	itemStyle,
	direction,
	multiple,
	itemClassName,
}: InlineSingleSelect<any> | InlineMultipleSelect<any>) {
	const valuesSet = new Set(multiple ? value || [] : [value]);
	const handleClick = (val: string) => {
		if (multiple) {
			const isSelected = valuesSet.has(val);
			as<string[]>(value);
			as<(values: string[]) => void>(onChange);
			if (isSelected) {
				onChange(value.filter(el => el !== val));
			} else {
				onChange([...value, val]);
			}
		} else {
			as<string>(val);
			as<(newValue: string) => void>(onChange);
			onChange(val);
		}
	};
	const itemsMargin = 10;
	const size = useResponsiveSize({
		sizes: {
			small: 0,
			wide: options.length * 200 + (options.length - 1) * itemsMargin,
		},
	});
	return (
		<MarginContainer
			ref={size.getRef()}
			itemsMargin={10}
			className={`${size.is("small") ? "block" : "flex"} flex-wrap ${
				direction === "column" ? "flex-col" : ""
			}`}
		>
			{options.map((option, i) => (
				<div
					className={styles.checkBoxDiv + " " + (itemClassName || "")}
					key={option.value}
					onClick={() => handleClick(option.value)}
					style={itemStyle}
				>
					<div className={styles.square}>
						{valuesSet.has(option.value) && (
							<div className={styles.innerCheckbox} />
						)}
					</div>
					<p className="flex-1">{option.label}</p>
				</div>
			))}
		</MarginContainer>
	);
});
