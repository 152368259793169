import { IRawQuestionContent } from "../../../schemas/questions/contnets/schemas";

export interface IContentEditorProps {
	generateUniqueIds: (numOfIds?: number) => number[];
}

export enum ContentErrorToken {
	mcNoCorrectChoice = "mc-no-correct-choice",
	emptyStatement = "empty-stat",
}

export interface ContentError {
	engMessage: string;
	errorToken: ContentErrorToken;
	details?: any;
}

export interface IContentEditor<T extends IRawQuestionContent> {
	getCurrentIds: () => number[];
	getData: () => T;
	getErrors: () => ContentError[];
}
export interface IIdGenerators {
	generateUniqueIds: (numOfIds?: number) => number[];
	getCurrentIds: () => number[];
}
