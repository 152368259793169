/* eslint-disable max-lines-per-function */
import React, { useCallback } from "react";
import { MarginContainer } from "@app/components/ui/containers";
import { ReactComponent as AttachIcon } from "@app/components/styles/img/icons/Attach.svg";
import { FileBox } from "./file-box";
import { getFormattedMessage } from "@app/utils/locale";

interface IFile {
	name: string;
	path: string;
}

interface FilesInputProps {
	files?: IFile[];
	onDelete?: (file: IFile) => void;
	onAttachFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	showPreviewOnClick?: boolean;
	direction: "left" | "right";
	mutliple?: boolean;
}

export const FilesInput: React.FC<FilesInputProps> = React.memo(
	({
		files,
		onAttachFile,
		onDelete,
		direction,
		mutliple = true,
		showPreviewOnClick = true,
	}) => {
		const fileRef = React.createRef<HTMLInputElement>();

		const nullifyValue = () => {
			if (fileRef.current) {
				fileRef.current.value = "";
			}
		};

		const handleFileClick = useCallback(() => {
			if (fileRef.current === null) return;
			fileRef.current.click();
		}, [fileRef]);

		return (
			<div>
				<div
					style={{
						display: "flex",
						justifyContent:
							direction === "left" ? "flex-start" : "flex-end",
						marginTop: "10px",
						cursor: "pointer",
						alignItems: "center",
						fontFamily: "Roboto Geo Nus",
					}}
				>
					{files ? (
						<MarginContainer
							style={{
								display: "flex",
								fontSize: "18px",
								maxWidth: "480px",
								flexFlow: "wrap",
							}}
							itemsMargin={7}
						>
							{files.map((file, i) => (
								<FileBox
									key={i}
									canDownload={false}
									fileName={file.name}
									fileUrl={file.path}
									showPreviewOnClick={showPreviewOnClick}
									onDelete={
										onDelete && (() => onDelete(file))
									}
								/>
							))}
						</MarginContainer>
					) : null}

					{onAttachFile && (
						<React.Fragment>
							<div>
								<input
									ref={fileRef}
									style={{ display: "none" }}
									type="file"
									multiple={mutliple}
									onChange={onAttachFile}
									onClick={nullifyValue}
								/>
							</div>
							<div
								onClick={handleFileClick}
								style={{
									display: "flex",
									alignItems: "center",
									marginLeft: "15px",
								}}
							>
								<AttachIcon
									style={{
										width: "22px",
										height: "22px",
										marginRight: "5px",
										fill: "currentColor",
									}}
								/>
								{getFormattedMessage("attachFile")}{" "}
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
);
