import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { IAGetTestQuestion } from "@tests-core/components/tests";

export const getQuestionIdsOfFirstNotFullyAskedText = (
	args: Pick<
		IAGetTestQuestion,
		"passedQuestions" | "passedTexts" | "selectedQuestionIds"
	>
): ObjectId[] => {
	const selectedTextIds: string[] = [];
	const selectedTextIdsObj: Record<string, true | undefined> = {};
	const selectedQuestionIdsObj: Record<string, true | undefined> = {};

	const questions = arrayToObject(args.passedQuestions, "_id");

	for (const qId of args.selectedQuestionIds) {
		if (!qId) continue;
		selectedQuestionIdsObj[qId] = true;
		const question = questions[qId];
		if (question && question.textId) {
			if (!selectedTextIdsObj[question.textId]) {
				selectedTextIds.push(question.textId);
				selectedTextIdsObj[question.textId] = true;
			}
		}
	}

	const texts = arrayToObject(args.passedTexts, "_id");
	const questionsOfFirstNotFullyAskedText: string[] = [];
	for (const textId of selectedTextIds) {
		const text = texts[textId];
		if (!text) continue;
		const textQuestions = text.questions;
		for (const qId of textQuestions) {
			if (!selectedQuestionIdsObj[qId]) {
				questionsOfFirstNotFullyAskedText.push(qId);
				selectedQuestionIdsObj[qId] = true;
			}
		}
		if (questionsOfFirstNotFullyAskedText.length > 0) {
			break;
		}
	}
	return questionsOfFirstNotFullyAskedText;
};
