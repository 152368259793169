import Joi, { ObjectId } from "../../utils/joi";
import { DatePropertySchema, IDateProperty } from "./templates";

export type IValue = number | string | IDateProperty;
export const ValueSchema = Joi.alternatives([
	Joi.number().required(),
	Joi.string().required(),
	DatePropertySchema,
]);

export const PreciseEntryValueInfoSchema = Joi.object({
	isNotPrecise: Joi.boolean().valid(false),
	val: ValueSchema.required(),
});
export interface IPreciseEntryValueInfo {
	val: IValue;
	isNotPrecise?: false | undefined;
}

export const ImpreciseEntryValueInfoSchema = Joi.object({
	isNotPrecise: Joi.boolean()
		.valid(true)
		.required(),
	isInterval: Joi.boolean(),
	val: Joi.array()
		.items(ValueSchema)
		.required(),
});
export interface IImpreciseEntryValueInfo {
	isNotPrecise: true;
	val: IValue[];
	isInterval?: boolean | undefined;
}

export const EntrySchema = Joi.alternatives([
	PreciseEntryValueInfoSchema.keys({
		id: Joi.number()
			.integer()
			.required(),
		labels: Joi.array().items(Joi.number()),
	}),
	ImpreciseEntryValueInfoSchema.keys({
		id: Joi.number()
			.integer()
			.required(),
		labels: Joi.array().items(Joi.number()),
	}),
]);

export type IEntry = {
	id: number;
	labels?: number[];
} & IEntryValueInfo;

export const CardPropertySchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	entries: Joi.alternatives([
		EntrySchema,
		Joi.array().items(EntrySchema),
	]).required(),
});
export interface ICardProperty {
	id: number;
	entries: IEntry | IEntry[];
}

export const EntryValueInfoSchema = Joi.alternatives([
	PreciseEntryValueInfoSchema,
	ImpreciseEntryValueInfoSchema,
]);
export type IEntryValueInfo = IPreciseEntryValueInfo | IImpreciseEntryValueInfo;

/** currently possible types for IEntry["val"] */
export type IEntryValue = IEntry["val"];

export const CardSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	templateId: Joi.objectId().required(),
	properties: Joi.array()
		.items(CardPropertySchema.required())
		.required(),
	authorId: Joi.number()
		.integer()
		.required(),
});
export interface ICard {
	_id: ObjectId;
	name: string;
	templateId: string;
	properties: ICardProperty[];
	authorId: number;
}
