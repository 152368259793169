import { css, cx } from "emotion";
import memoizeOne from "memoize-one";
import * as React from "react";
import { ContentType } from "../../../../schemas/questions/contnets/common-schemas";
import {
	IMultipleChoiceContent,
	MCContentDesignStructure,
} from "../../../../schemas/questions/contnets/multiple-choice/schema";
import {
	EditorText,
	IAnyObj,
	mergeComponentObjects,
	mergeStylesObjects,
} from "../../../../utils";
import { copyTextToClipboard } from "../../../../utils//clipboard";
import { getStatsFromText, removeKeys } from "../../../../utils/common";
import {
	AnyComponentOptionalProps,
	NotUndefined,
	NotUndefinedAtAll,
	Omit,
	OptionalKeys,
} from "../../../../utils/generics";
import { HANDLED, NOT_HANDLED } from "../../../editor";
import {
	itemEditClassName,
	itemEditContainerClassName,
} from "../common/items-edit";
import { IEditCommonProps } from "../edit";
import {
	ContentError,
	ContentErrorToken,
	IContentEditor,
	IContentEditorProps,
} from "../interfaces";
import {
	IMCComponents,
	IMCContentComponents,
	IProps as MCTestMode,
	ITwoColumnsStyles,
	MultipleChoiceContentCont,
} from "./";
import MultipleChoice from "./class";
import { MCEditComponents } from "./edit-components";
import {
	IMCSettingsProps,
	IQSettingTexts,
	MCSettingsHead,
	MCSettingsTail,
} from "./edit-settings";

interface IChoiceTexts {
	checkedAnswer?: string;
	otherAnswer?: string;
	delete?: string;
	add?: string;
}

interface IStatementTexts {
	placeholder: string;
	twoColumns?: {
		mainPlaceholder?: string;
		APlaceholder?: string;
		BPlaceholder?: string;
	};
}

interface IExplanationTexts {
	placeholder: string;
}

interface ITexts {
	choices?: IChoiceTexts;
	statement?: IStatementTexts;
	explanation?: IExplanationTexts;
	settings?: IQSettingTexts;
}

interface IMCSettingsComponent {
	head?: AnyComponentOptionalProps<IMCSettingsProps>;
	tail?: AnyComponentOptionalProps<IMCSettingsProps>;
}

type IMCEditContentComponents = IMCContentComponents & {
	settings?: IMCSettingsComponent;
};

// tslint:disable-next-line:max-union-size
export type IProps = Partial<IEditCommonProps> &
	Omit<
		MCTestMode,
		| ("content" | "onUserAnswerChange")
		| ("disableEditingAnswer" | "displayAnswer")
		| "components"
	> & {
		content: IMultipleChoiceContent;
		toHTML: (editorState: EditorText) => string;
		toEditorState: (html: string) => EditorText;
		texts?: ITexts;
		components?: IMCEditContentComponents;
	} & IContentEditorProps;

export type IMCEditPassableProps = OptionalKeys<
	Omit<IProps, "content">,
	("generateUniqueIds" | "texts") | "toEditorState" | "toHTML"
>;

interface IState {
	choices: (Omit<IMultipleChoiceContent["choices"][number], "text"> & {
		text: EditorText;
	})[];
	canSelectMultiple: NotUndefined<
		IMultipleChoiceContent["canSelectMultiple"]
	>;
	disableShuffle: NotUndefined<IMultipleChoiceContent["disableShuffle"]>;
	statement: Omit<IMultipleChoiceContent["statement"], "text"> & {
		id: number;
		text: EditorText;
	};
	explanation?: Omit<
		NotUndefined<IMultipleChoiceContent["explanation"]>,
		"text"
	> & { text: EditorText };
	designStructure?: MCContentDesignStructure;
}

const defaultLabelClassName = css({
	cursor: "initial",
	border: "1px solid #ccc",
	borderRadius: 20,
	paddingRight: 5,
	margin: "5px 0",
	transition: "0.3s",
	"&:focus-within": {
		boxShadow: "0 0 2px rgba(0,0,0,0.4)",
	},
	".DraftEditor-root": {
		minWidth: 200,
	},
});

const TwoColumnsSingleContainerCSS = css`
	border: none;
	padding: 0px;
`;

const MainTextContainerCSS = css`
	text-align: left;
`;

const labelMultipleSelectClassName = css({
	borderRadius: 5,
});
const labelSingleSelectClassName = css({
	borderRadius: 20,
});

class MultipleChoiceEditor extends React.PureComponent<IProps, IState>
	implements IContentEditor<IMultipleChoiceContent> {
	defaultTexts = {
		choices: {
			checkedAnswer: "Enter correct choice",
			otherAnswer: "Enter choice",
			delete: "Delete Choice",
			add: "Add Choice",
		},
		statement: {
			placeholder: "Enter statement",
			twoColumns: {
				mainPlaceholder: "Enter main statement",
				APlaceholder: "Enter A column statement",
				BPlaceholder: "Enter B column statement",
			},
		},
		settings: {
			multipleSelect: "Multiple Select",
			disableShuffle: "Never allow shuffling choices",
		},
		explanation: {
			placeholder: "Enter explanation",
		},
	} as NotUndefinedAtAll<IProps["texts"]>;

	getStyles = memoizeOne(
		(styles: IProps["styles"], canSelectMultiple: boolean) => {
			return mergeStylesObjects(
				styles || {},
				this.defaultStyles(canSelectMultiple)
			);
		}
	);
	getComponents = memoizeOne((components: IProps["components"]) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});
	getTexts = memoizeOne((texts: IProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	constructor(props: IProps) {
		super(props);
		let disableShuffle = !!props.content.disableShuffle;
		const statTexts: IAnyObj = {
			text: this.props.toEditorState(props.content.statement.text),
		};
		if (
			props.content.designStructure ===
			MCContentDesignStructure.twoColumns
		) {
			statTexts.textA = this.props.toEditorState(
				props.content.statement.textA || ""
			);
			statTexts.textB = this.props.toEditorState(
				props.content.statement.textB || ""
			);
			disableShuffle = true;
		}
		this.state = {
			statement: { ...props.content.statement, ...statTexts },
			choices: props.content.choices.map(e => ({
				...e,
				text: this.props.toEditorState(e.text),
			})),
			canSelectMultiple: !!props.content.canSelectMultiple,
			disableShuffle,
			explanation: props.content.explanation
				? {
						...props.content.explanation,
						text: this.props.toEditorState(
							props.content.explanation.text
						),
				  }
				: undefined,
			designStructure: props.content.designStructure,
		};
	}

	componentDidMount() {
		if (!this.state.explanation) {
			this.setState({
				explanation: {
					id: this.props.generateUniqueIds()[0],
					text: this.props.toEditorState(""),
				},
			});
		}
	}

	defaultComponents = {
		choices: {
			innerChoice: {
				text: MCEditComponents.InnerChoiceText,
				choiceContainerAfter:
					MCEditComponents.InnerChoiceContainerAfter,
			},
			after: MCEditComponents.ChoiceAfter,
		} as Partial<IMCComponents["choices"]>,
		statement: {
			text: MCEditComponents.StatementText,
		},
		container: MultipleChoiceContentCont,
		settings: {
			head: MCSettingsHead,
			tail: MCSettingsTail,
		} as NotUndefined<IMCSettingsComponent>,
		explanation: MCEditComponents.Explanation,
	} as NotUndefined<IProps["components"]>;
	// tslint:disable-next-line:max-line-length

	onPaste = (text: string): HANDLED | NOT_HANDLED => {
		try {
			if (
				this.props.toHTML(this.state.statement.text).length === 0 &&
				this.state.choices.every(
					ch => this.props.toHTML(ch.text).length === 0
				)
			) {
				// empty statement and choices. time to check whether there is some template
				const res = getStatsFromText(text);
				if (!res) return NOT_HANDLED;
				const ids = this.props.generateUniqueIds(res.choices.length);
				this.setState({
					statement: {
						...this.state.statement,
						text: this.props.toEditorState(res.stat),
					},
					choices: ids.map((choId, index) => ({
						...this.state.choices[index],
						id: choId,
						text: this.props.toEditorState(res.choices[index]),
						score: index === 0 ? 1 : undefined,
					})),
				});
				return HANDLED;
			}
		} catch (e) {
			return NOT_HANDLED;
		}
		return NOT_HANDLED;
	};

	defaultStyles = (canSelectMultiple: boolean) =>
		({
			choices: {
				label: cx(
					defaultLabelClassName,
					canSelectMultiple
						? labelMultipleSelectClassName
						: labelSingleSelectClassName
				),
				text: itemEditClassName,
			},
			statement: {
				container: itemEditContainerClassName,
				text: itemEditClassName,
				twoColumns: {
					AContainer: TwoColumnsSingleContainerCSS,
					BContainer: TwoColumnsSingleContainerCSS,
					MainTextContainer: MainTextContainerCSS,
				} as ITwoColumnsStyles,
			},
			explanation: {
				container: itemEditContainerClassName,
			},
		} as NotUndefinedAtAll<IProps["styles"]>);

	getData = () => {
		const expText = this.state.explanation
			? this.props.toHTML(this.state.explanation.text)
			: undefined;
		const statement: IMultipleChoiceContent["statement"] = {
			...this.state.statement,
			text: this.props.toHTML(this.state.statement.text),
		};
		if (
			this.state.designStructure === MCContentDesignStructure.twoColumns
		) {
			statement.textA = this.props.toHTML(this.state.statement.textA);
			statement.textB = this.props.toHTML(this.state.statement.textB);
		}
		return {
			...this.state,
			type: ContentType.MultipleChoice as ContentType.MultipleChoice,
			statement,
			choices: this.state.choices
				.map(e => ({
					...e,
					text: this.props.toHTML(e.text),
				}))
				.filter(e => e.score || e.text.trim()),
			explanation: expText
				? { ...this.state.explanation!, text: expText }
				: undefined,
			designStructure: this.state.designStructure,
		};
	};

	getErrors = () => {
		const data = this.getData();
		const errors: ContentError[] = [];
		const hasCorrectAnswer = data.choices.some(e => !!e.score);
		if (!hasCorrectAnswer) {
			errors.push({
				engMessage: "Choose correct answer",
				errorToken: ContentErrorToken.mcNoCorrectChoice,
			});
		}
		return errors;
	};

	getCurrentIds = () => {
		const ids: number[] = [];
		ids.push(this.state.statement.id);
		this.state.choices.forEach(e => ids.push(e.id));
		if (this.state.explanation) ids.push(this.state.explanation.id);
		return ids;
	};

	onStatementTextAChange = (stat: EditorText) => {
		this.setState(({ statement }) => ({
			statement: {
				...statement,
				...removeKeys(stat, "text"),
				textA: stat.text,
			},
		}));
	};
	onStatementTextBChange = (stat: EditorText) => {
		this.setState(({ statement }) => ({
			statement: {
				...statement,
				...removeKeys(stat, "text"),
				textB: stat.text,
			},
		}));
	};

	onStatementChange = (stat: EditorText) => {
		this.setState(({ statement }) => ({
			statement: { ...statement, ...stat },
		}));
	};

	onExplanationChange = (stat: EditorText) => {
		this.setState({
			explanation: stat,
		});
	};

	onChoiceCheck = (id: number) => {
		this.setState({
			choices: this.state.choices.map(c => {
				// FIXME: correct this algorithm
				if (c.id !== id) {
					if (this.state.canSelectMultiple) return c;
					// tslint:disable-next-line:no-shadowed-variable
					const { score, ...rest } = c;
					return rest;
				}
				if (!c.score) return { ...c, score: 1 };
				const { score, ...rest } = c;
				return rest;
			}),
		});
	};

	onChoiceDelete = (id: number) => {
		this.setState({
			choices: this.state.choices.filter(c => c.id !== id),
		});
	};

	onChoiceChange = ({ id, text }: { id: number; text: EditorText }) => {
		this.setState({
			choices: this.state.choices.map(c =>
				c.id === id ? { ...c, text } : c
			),
		});
	};

	onAddChoice = () => {
		this.setState({
			choices: [
				...this.state.choices,
				{
					id: this.props.generateUniqueIds()[0],
					text: this.props.toEditorState("") as any,
				},
			],
		});
	};

	onMultipleSelectabilityChange = (checked: boolean) => {
		const firstChoiceWithScore = this.state.choices.findIndex(
			c => c.score! > 0
		);
		this.setState({
			canSelectMultiple: checked,
			choices: this.state.choices.map((c, i) => {
				if (i === firstChoiceWithScore) return c;
				const { score, ...rest } = c;
				return rest;
			}),
		});
	};

	onDisablingShuffleChange = (checked: boolean) => {
		this.setState({
			disableShuffle: checked,
		});
	};

	handleCopyingContent = () => {
		const content = new MultipleChoice(this.props.content);
		const randomStr = "!!@!" + Math.floor(Math.random() * 1e9) + "!@!!";
		const serialized = content.serialize(randomStr);
		let i = -1;
		const numOfChoices = this.props.content.choices.length;
		const numOfSeprarators = serialized.split(randomStr).length - 1;
		const normalized = serialized.replace(
			new RegExp(randomStr, "g"),
			() => {
				i++;
				if (i < numOfSeprarators - numOfChoices) return "\n";
				const choiceIndex = i - (numOfSeprarators - numOfChoices);
				return "\n" + (choiceIndex + 1) + ") ";
			}
		);
		copyTextToClipboard(normalized);
	};

	getContextValue = memoizeOne(
		(
			styles: NonNullable<IProps["styles"]>,
			texts: NonNullable<IProps["texts"]>,
			galleryComponent: IProps["galleryComponent"],
			designStructure: MCContentDesignStructure | undefined
		): MCEditContextValue => {
			return {
				styles,
				texts,
				designStructure,
				galleryComponent,
				onAddChoice: this.onAddChoice,
				onExplanationChange: this.onExplanationChange,
				onChoiceDelete: this.onChoiceDelete,
				onChoiceChange: this.onChoiceChange,
				onPaste: this.onPaste,
				onStatementChange: this.onStatementChange,
				onStatementTextAChange: this.onStatementTextAChange,
				onStatementTextBChange: this.onStatementTextBChange,
			};
		}
	);

	render() {
		const texts = this.getTexts(this.props.texts);
		const styles = this.getStyles(
			this.props.styles,
			this.state.canSelectMultiple
		);
		const components = this.getComponents(this.props.components);
		const content = {
			...this.props.content,
			choices: this.state.choices,
			statement: this.state.statement,
			explanation: this.state.explanation,
			canSelectMultiple: this.state.canSelectMultiple,
			disableShuffle: this.state.disableShuffle,
		};
		const settings: IMCSettingsProps = {
			canSelectMultiple: this.state.canSelectMultiple,
			onMultipleSelectabilityChange: this.onMultipleSelectabilityChange,
			disableShuffle: this.state.disableShuffle,
			onDisablingShuffleChange: this.onDisablingShuffleChange,
			texts: texts.settings,
			onCopy: this.handleCopyingContent,
		};
		const SettingsHead = components.settings!.head!;
		const SettingsTail = components.settings!.tail!;
		const Container = components.container!;
		return (
			<div className={styles.container}>
				<MCEditContext.Provider
					value={this.getContextValue(
						styles,
						texts,
						this.props.galleryComponent,
						this.state.designStructure
					)}
				>
					<SettingsHead {...settings} />
					<Container
						content={content}
						onChoiceCheck={this.onChoiceCheck}
						components={components}
						styles={styles}
						checkOnlyOnCheckmark={true}
						displayAnswer={true}
						disableEditingAnswer={false}
						shuffleKey={0}
					/>
					<SettingsTail {...settings} />
				</MCEditContext.Provider>
			</div>
		);
	}
}

export interface MCEditContextValue {
	texts: NonNullable<IProps["texts"]>;
	styles: NonNullable<IProps["styles"]>;
	onAddChoice: () => void;
	onExplanationChange: (stat: EditorText) => void;
	galleryComponent: IProps["galleryComponent"];
	onChoiceDelete: (id: number) => void;
	onChoiceChange: (args: { id: number; text: EditorText }) => void;
	designStructure: MCContentDesignStructure | undefined;
	onPaste: (text: string) => HANDLED | NOT_HANDLED;
	onStatementChange: (stat: EditorText) => void;
	onStatementTextAChange: (stat: EditorText) => void;
	onStatementTextBChange: (stat: EditorText) => void;
}

export const MCEditContext = React.createContext({} as MCEditContextValue);

export default MultipleChoiceEditor;
