import { Card } from "@app/models/card";
import { inject } from "@app/modules";
import { createCoursedModelCollmise } from "../collmise-helpers";
import { ItemType } from "../folders/helper-schemas";
import { IRequest } from "../requests";
import {
	IADELETECard,
	IAGETCard,
	IAGETManyByIds,
	IAGETNamesByIds,
	IAPOSTCreateCard,
	IAPUTCard,
	IRGETNamesByIds,
	IRPOSTCreateCard,
	RGETCardSchema,
	RGETManyByIdsSchema,
	RGETNamesByIdsSchema,
	RPOSTCreateCardSchema,
} from "./validators";

export class CardsController {
	private readonly Request: IRequest;

	private readonly _CardModel = inject("CardModel");
	private readonly _FolderItemsService = inject("FolderItemsService");

	private cardCollmise = createCoursedModelCollmise({
		model: this._CardModel,
		name: "Card",
		getMany: args =>
			this.Request.send("POST", "/api/cards/get-bulk", args, null, {
				responseSchema: RGETManyByIdsSchema,
			}),
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCard): Promise<Card> =>
		this.Request.send("POST", "/api/cards", args, null, {
			responseSchema: RPOSTCreateCardSchema,
		}).then((data: IRPOSTCreateCard) => {
			this._FolderItemsService.addItemInParentSync({
				parentFolderId: args.folderId,
				item: { id: data._id, name: data.name, type: ItemType.card },
				courseId: args.courseId,
			});
			return this._CardModel.loadOneSync(data);
		});

	update = async (args: IAPUTCard): Promise<Card | null> =>
		this.Request.send("PUT", "/api/cards/:_id", args).then(() => {
			const { courseId, folderId, ...obj } = args;
			this._FolderItemsService.updateItemSync({
				item: { id: args._id, type: ItemType.card, name: args.name },
				parentFolderId: folderId,
				courseId: courseId,
			});
			return this._CardModel.updateOneSync({ _id: obj._id }, obj);
		});

	getById = async (args: IAGETCard, loadFresh?: boolean): Promise<Card> => {
		return this.cardCollmise
			.on(args)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/cards/:_id", args, null, {
					responseSchema: RGETCardSchema,
				})
			);
	};

	getBulk = async (args: IAGETManyByIds): Promise<Card[]> => {
		return this.cardCollmise.collectors.many(args).request();
	};

	getNames = async (args: IAGETNamesByIds): Promise<IRGETNamesByIds> =>
		this.Request.send("POST", "/api/cards/get-names", args, null, {
			responseSchema: RGETNamesByIdsSchema,
		});

	deleteById = async (args: IADELETECard): Promise<void> =>
		this.Request.send("DELETE", "/api/cards/:_id", args).then(() => {
			this._CardModel.deleteByIdSync(args._id);
			this._FolderItemsService.deleteItemSync({
				courseId: args.courseId,
				deleteOnlyInParentFolder: true,
				itemId: args._id,
				parentFolderId: args.folderId,
				type: ItemType.card,
			});
		});
}
