import React, { useCallback, useMemo, useState, useEffect } from "react";
import { ObjectId } from "@app/utils/generics";
import { useClassroomsUser } from "@app/hooks/users";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { SecondaryButton, EmptyButton, FilledButton } from "./wrapper";
import { FormattedMessage } from "react-intl";
import { MarginContainer } from "@app/components/ui/containers";
import { RegisterPopup } from "@app/components/landing/register-type-selection";
import { UserType } from "@app/api/helper-schemas";
import { LoginPopup } from "@app/components/landing/login";
import StudentRegisterStyles from "../../../auth/styles/student-register-page.module.css";
import { ReactComponent as SentSVG } from "../styles/imgs/sent.svg";
import Style from "./styles/styles.module.css";
import { getFeedBackForTest } from "./helper-functions";
import { IFeedback } from "@app/api/feedback/helper-schemas";
import { inject } from "@app/modules";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";

export const PublicAssignmentSentPage: React.FC<{
	onGotoNext?: () => void;
	maxCredit?: number;
	totalCredit?: number;
	testFeedBack?: ObjectId;
}> = React.memo(props => {
	const [feedback, setFeedBack] = useState<IFeedback>();
	const user = useClassroomsUser();
	const { width } = useWindowSize();
	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);

	useEffect(() => {
		if (!props.testFeedBack) return;
		inject("FeedbackController")
			.getOneById({ _id: props.testFeedBack })
			.then(e => setFeedBack(e))
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, [props.testFeedBack]);

	const TestFeedBack = useMemo(() => {
		const { maxCredit, totalCredit } = props;
		if (maxCredit === undefined || totalCredit === undefined || !feedback) {
			return null;
		}
		return (
			<div className={Style.feedback}>
				<div className={Style.title}>სიტყვიერი შეფასება</div>
				<div className={Style.text}>
					{getFeedBackForTest(feedback, totalCredit, maxCredit)}
				</div>
			</div>
		);
	}, [feedback, props]);

	return (
		<div style={{ textAlign: "center" }}>
			<h2
				style={{
					fontFamily: "Roboto Geo Nus",
					color: "#5273e6",
					fontSize: width < 450 ? "18px" : "26px",
					marginTop: -15,
				}}
			>
				{feedback ? (
					"ტესტი გაგზავნილია!"
				) : (
					<FormattedMessage id="AssignmentIsSent" />
				)}
			</h2>
			{props.totalCredit !== undefined && feedback && (
				<div className={Style.totalCredit}>
					<div className={Style.text}>შენი შედეგი:</div>
					<div className={Style.credit}>{props.totalCredit} ქულა</div>
				</div>
			)}
			{(!!user || width > 450) && (
				<SentSVG
					style={{
						width: "90%",
						maxWidth: "300px",
						display: "block",
						margin: "10px auto",
					}}
				/>
			)}
			{TestFeedBack ? TestFeedBack : null}
			{props.onGotoNext && (
				<SecondaryButton
					onClick={props.onGotoNext}
					style={{ marginTop: 10 }}
				>
					<FormattedMessage id="student:test.returnFromTestAfterFinish" />
				</SecondaryButton>
			)}
			{!user && (
				<React.Fragment>
					<MarginContainer topMargin={width < 450 ? 35 : 70}>
						<p className={Style.text}>
							{feedback ? (
								"დარეგისტრირდი ან გაიარე ავტორიზაცია, რომ შეინახო გაგზავნილი ტესტი შენს პროფილში."
							) : (
								<FormattedMessage id="registerToSeeAnswers" />
							)}
						</p>
					</MarginContainer>

					<div className={Style.btnWrapper}>
						<EmptyButton onClick={() => openLogin()}>
							<FormattedMessage id="signInTitle"></FormattedMessage>
						</EmptyButton>
						<FilledButton onClick={() => openRegisterPage()}>
							<FormattedMessage id="signUp" />
						</FilledButton>
					</div>
				</React.Fragment>
			)}

			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
					userType={UserType.student}
					isCountryInputInvissible={true}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={() => openRegisterPage()}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
				/>
			)}
		</div>
	);
});
