import React from "react";
// import styles from "../teacher-profile/styles/teacherProfile.module.css";
import genStayles from "./styles/general_styles.module.css";
import { useClassroomsUser } from "@app/hooks/users";
import { Button } from "@app/components/auth/input-forms";

export const Confirmed: React.FC<{ handleChangeNumber: () => void }> = ({
	handleChangeNumber,
}) => {
	const user = useClassroomsUser()!;

	return (
		<div className={genStayles.personal_info_form}>
			<h2 className={genStayles.personal_info_form__title}>
				ტელეფონი <span>(დადასტურებულია)</span>
			</h2>

			<section className={genStayles.personal_info_form__input_wrapper}>
				<section className={genStayles.change_mobile_number}>
					<section
						className={genStayles.confirmed__input}
						style={{ display: "flex", alignItems: "center" }}
					>
						{user.mobile}
					</section>
				</section>

				<Button
					onClick={() => {
						handleChangeNumber();
					}}
					text={"ნომრის შეცვლა"}
					className={genStayles.personal_info_form__code_btn}
					wrapperDivClassName={genStayles.fullWidth}
				/>
			</section>
		</div>
	);
};
