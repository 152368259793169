import { IRPOSTLogin } from "@app/api/auth/validators";
import { IStateUser } from "@app/redux/reducers/user";
import {
	LOAD_USER,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
	USERDATA_CHANGE,
	CHANGE_ACTIVE_TYPE,
} from "@app/redux/action-types";
import { setUserForAnalytics } from "@app/utils/events";
import { UserType } from "@app/api/helper-schemas";

export const updateUserDataAction = (
	userData?: IRPOSTLogin["userData"],
	coursesUserData?: IRPOSTLogin["coursesUserData"]
) => {
	return {
		type: USERDATA_CHANGE as typeof USERDATA_CHANGE,
		userData,
		coursesUserData,
	};
};
type IUpdateUserDataAction = ReturnType<typeof updateUserDataAction>;

interface ILoginAction {
	type: typeof USER_AUTHORIZATION_SUCCESS;
	userData: IRPOSTLogin["userData"];
	coursesUserData: IRPOSTLogin["coursesUserData"];
}

export const loginAction = (
	userData: IRPOSTLogin["userData"],
	coursesUserData: IRPOSTLogin["coursesUserData"]
): ILoginAction => {
	return {
		type: USER_AUTHORIZATION_SUCCESS,
		userData,
		coursesUserData,
	};
};

export interface ILogoutAction {
	type: typeof USER_LOG_OUT;
}
const logoutAction = (): ILogoutAction => ({
	type: USER_LOG_OUT,
});
export { logoutAction };

interface ILoadUserAction {
	type: typeof LOAD_USER;
	user: IStateUser;
}
export const loadUser = (user: IStateUser): ILoadUserAction => {
	if (user.userData) {
		setUserForAnalytics(user.userData);
	}
	return {
		type: LOAD_USER,
		user,
	};
};
interface IChangeActiveTypeAction {
	type: typeof CHANGE_ACTIVE_TYPE;
	desiredType: UserType;
}
export const changeActiveTypeAction = (
	desiredType: UserType
): IChangeActiveTypeAction => {
	return {
		type: CHANGE_ACTIVE_TYPE,
		desiredType,
	};
};

export type UserActions =
	| ILoginAction
	| ILoadUserAction
	| ILogoutAction
	| IUpdateUserDataAction
	| IChangeActiveTypeAction;
