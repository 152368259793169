import { Assignment } from "@app/models/assignment";
import { inject } from "@app/modules";
import { ObjectId, ReplaceIn } from "@app/utils/generics";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import {
	IAGETUserTest,
	IRGETUserTest,
	RGETQuestionsByTaskTypesSchema,
} from "../tests/validators";
import { INoteAssignment } from "./helper-schemas";
import {
	AGETHomeworksByStudentIdSchema,
	APOSTMakeWrittenAssignmentRewritableSchema,
	APOSTStartAssignmentTimerScheme,
	APOSTSubmitPublicAssignment,
	APUTEditAssignmentSchema,
	IAGETByClassroom,
	IAGETCountByClassroom,
	IAGETHomeworksByStudentId,
	IAGETManyByIds,
	IAGETNoteAssignments,
	IAGETPublicAssignmentByCode,
	IAGETSingleAssignmentForTeacher,
	IAGETSingleUserAssignmentForTeacher,
	IAGETStudentHomeworkList,
	IAGETTaskTypeContents,
	IAGETTeacherHomeworkList,
	IAGETUserWrittenAssignment,
	IAPOSTCreateAssignment,
	IAPOSTCreatePublicAssignment,
	IAPOSTMakeWrittenAssignmentRewritable,
	IAPOSTSaveNoteAssignment,
	IAPOSTStartAssignmentTimer,
	IAPOSTSubmitPublicAssignment,
	IAPUTArchiveAssignment,
	IAPUTCloseAssignment,
	IAPUTEditAssignment,
	IAPUTOpenAssignment,
	IAPUTSaveAssignmentProgress,
	IAPUTWrittenAssignment,
	IRGETAllForTeacher,
	IRGETByClassroom,
	IRGETById,
	IRGETCountByClassroom,
	IRGETCountForTeacher,
	IRGETHomeworkListForStudent,
	IRGETHomeworkListForTeacher,
	IRGETManyByIds,
	IRGETPublicAssignmentByCodeWithIncorrcetKey,
	IRGETSingleAssignmentForTeacher,
	IRGETSingleUserAssignmentForTeacher,
	IRGETTaskTypeContents,
	IRGETUserWrittenAssignment,
	IRPOSTCreateAssignment,
	IRPOSTCreatePublicAssignment,
	IRPOSTStartAssignmentTimer,
	IRPOSTSubmitPublicAssignment,
	IRPUTArchiveAssignment,
	IRPUTCloseAssignment,
	IRPUTEditAssignment,
	IRPUTOpenAssignment,
	IRPUTSaveAssignmentProgress,
	IRPUTStartAssignment,
	IRPUTWrittenAssignment,
	IStudentHomeworkListItem,
	RGETAllForTeacherSchema,
	RGETByClassroomSchema,
	RGETByIdSchema,
	RGETCountByClassroomSchema,
	RGETCountForTeacherSchema,
	RGETHomeworkListForStudentSchema,
	RGETHomeworkListForTeacherSchema,
	RGETHomeworksByStudentIdSchema,
	RGETManyByIdsSchema,
	RGETPublicAssignmentByCodeSchemaWithIncorrcetKey,
	RGETSingleAssignmentForTeacherSchema,
	RGETSingleUserAssignmentForTeacherSchema,
	RGETUserWrittenAssignmentSchema,
	RPOSTCreateAssignmentSchema,
	RPOSTCreatePublicAssignmentSchema,
	RPOSTStartAssignmentTimerScheme,
	RPUTEditAssignmentSchema,
	RPUTSaveAssignmentProgressSchema,
	RPUTStartAssignmentSchema,
} from "./validators";
import { useSubWebsite } from "../../hooks/bc";
import { SubWebsiteOrigin } from "../../globals";

export class AssignmentsController {
	private readonly Request: IRequest;
	private readonly CoursesRequest: IRequest;

	private teacherAssignmentsCollmise = collmise<
		ObjectId,
		IRGETSingleAssignmentForTeacher
	>({
		collectingTimeoutMS: 6,
	});
	private assignmentCollmise = collmise<ObjectId, Assignment>();

	private readonly _AssignmentModel = inject("AssignmentModel");
	private readonly _ClassroomModel = inject("ClassroomModel");

	constructor(request: IRequest, coursesRequest: IRequest) {
		this.Request = request;
		this.CoursesRequest = coursesRequest;
	}

	getCountForTeacher = async (): Promise<IRGETCountForTeacher> => {
		return this.Request.send(
			"GET",
			"/api/teachers/assignments/count",
			undefined,
			null,
			{
				responseSchema: RGETCountForTeacherSchema,
			}
		).then((data: IRGETCountForTeacher) => {
			this._AssignmentModel.meta.setItem(
				"numOfCreatedAssignments",
				data.count
			);
			return data;
		});
	};

	getAllForTeacher = async (): Promise<IRGETAllForTeacher> => {
		return this.Request.send(
			"GET",
			"/api/teachers/assignments",
			undefined,
			null,
			{
				responseSchema: RGETAllForTeacherSchema,
			}
		).then((data: IRGETAllForTeacher) => {
			this._AssignmentModel.meta.setItem(
				"numOfCreatedAssignments",
				data.length
			);
			return this._AssignmentModel.loadManySync(data, "replaceAll");
		});
	};

	create = (args: IAPOSTCreateAssignment): Promise<Assignment> => {
		return this.Request.send(
			"POST",
			"/api/assignments",
			args,
			this.getCustomConfig(),
			{
				responseSchema: RPOSTCreateAssignmentSchema,
			}
		).then((data: IRPOSTCreateAssignment) => {
			sessionStorage.removeItem("isFirstAssignment");
			this._AssignmentModel.meta.incrementCreatedAssignemnts();
			return this._AssignmentModel.loadOneSync(data);
		});
	};

	saveNoteAssignment = (args: IAPOSTSaveNoteAssignment) => {
		return this.Request.send("POST", "/api/note-assignments", args, null);
	};

	getNoteAssignments = async (
		args: IAGETNoteAssignments
	): Promise<INoteAssignment[] | INoteAssignment> => {
		// TODO: use collmise for optimization
		return this.Request.send("GET", "/api/note-assignments", args, null);
	};

	createPublic = (
		args: IAPOSTCreatePublicAssignment
	): Promise<Assignment> => {
		const bc = useSubWebsite() === SubWebsiteOrigin.britishCenter;
		return this.Request.send(
			"POST",
			"/api/public-assignments",
			{ ...args, schoolId: bc ? 4437 : args.schoolId },
			undefined,
			{
				responseSchema: RPOSTCreatePublicAssignmentSchema,
			}
		).then((data: IRPOSTCreatePublicAssignment) => {
			sessionStorage.removeItem("isFirstAssignment");
			this._AssignmentModel.meta.incrementCreatedAssignemnts();
			console.log(data.classroomIds);
			if (data.classroomIds) {
				this._ClassroomModel.meta.clear();
				console.log("cleared");
				for (const classroomId of data.classroomIds) {
					this._ClassroomModel.updateOneSync(
						{ _id: classroomId },
						{ isProgrammaticallyHidden: false }
					);
				}
			}
			return this._AssignmentModel.loadOneSync(data);
		});
	};

	getOneForTeacher = (
		args: IAGETSingleAssignmentForTeacher
	): Promise<IRGETSingleAssignmentForTeacher> =>
		this.teacherAssignmentsCollmise.on(args.assignmentId).request(() =>
			this.Request.send(
				"GET",
				"/api/assignments/for-teacher/:assignmentId",
				args,
				undefined,
				{
					responseSchema: RGETSingleAssignmentForTeacherSchema,
				}
			).then((data: IRGETSingleAssignmentForTeacher) => {
				sessionStorage.removeItem("isFirstAssignment");
				this._AssignmentModel.loadOneSync(data.assignment);
				return data;
			})
		);

	getUserAssignmentForTeacher = (
		args: IAGETSingleUserAssignmentForTeacher
	): Promise<IRGETSingleUserAssignmentForTeacher> =>
		this.Request.send(
			"GET",
			"/api/assignments/for-teacher/:assignmentId/users/:userId",
			args,
			undefined,
			{
				responseSchema: RGETSingleUserAssignmentForTeacherSchema,
			}
		).then((data: IRGETSingleUserAssignmentForTeacher) => {
			this._AssignmentModel.loadOneSync(data.assignment);
			return data;
		});

	getUserTestForTeacher = async (
		args: IAGETUserTest & { studentId: number }
	): Promise<IRGETUserTest> =>
		this.Request.send(
			"GET",
			"/api/assignments/user-test-for-teacher",
			args,
			this.getCustomConfig()
		).then((data: IRGETUserTest) => {
			return data;
		});

	getTeacherHomeworks = (
		args: IAGETTeacherHomeworkList
	): Promise<IRGETHomeworkListForTeacher> =>
		this.Request.send(
			"GET",
			"/api/assignments/teacher-homeworks",
			args,
			undefined,
			{
				responseSchema: RGETHomeworkListForTeacherSchema,
			}
		).then((data: IRGETHomeworkListForTeacher) => {
			this._AssignmentModel.loadManySync(data.map(e => e.assignment));
			return data;
		});

	getClassroomCount = (
		args: IAGETCountByClassroom
	): Promise<IRGETCountByClassroom> =>
		this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/assignments/count",
			args,
			undefined,
			{ responseSchema: RGETCountByClassroomSchema }
		);

	getHomeworksByStudentId = (
		args: IAGETHomeworksByStudentId
	): Promise<
		ReplaceIn<
			IStudentHomeworkListItem,
			{
				assignment: Assignment;
			}
		>[]
	> =>
		this.Request.send(
			"GET",
			"/api/assignments/homeworks/:studentId",
			args,
			null,
			{
				responseSchema: RGETHomeworksByStudentIdSchema,
				requestSchema: AGETHomeworksByStudentIdSchema,
			}
		).then((data: IRGETHomeworkListForStudent) => {
			this._AssignmentModel.loadManySync(data.map(e => e.assignment));
			return data.map(d => ({
				...d,
				assignment: this._AssignmentModel.findByIdSync(
					d.assignment._id
				)!,
			}));
		});

	getStudentHomeworks = (
		args: IAGETStudentHomeworkList
	): Promise<IFinalGETHomeworkListForStudent> =>
		this.Request.send(
			"GET",
			"/api/assignments/student-homeworks",
			args,
			undefined,
			{
				responseSchema: RGETHomeworkListForStudentSchema,
			}
		).then((data: IRGETHomeworkListForStudent) => {
			this._AssignmentModel.loadManySync(data.map(e => e.assignment));
			return data.map(d => ({
				...d,
				assignment: this._AssignmentModel.findByIdSync(
					d.assignment._id
				)!,
			}));
		});

	getOneById = (_id: ObjectId): Promise<Assignment> =>
		this.assignmentCollmise.on(_id).request(() =>
			this.Request.send(
				"GET",
				"/api/assignments/:_id",
				{ _id },
				undefined,
				{ responseSchema: RGETByIdSchema }
			).then((data: IRGETById) => {
				return this._AssignmentModel.loadOneSync(data);
			})
		);

	// as teacher
	getManyByIds = (args: IAGETManyByIds): Promise<Assignment[]> => {
		return this.Request.send(
			"GET",
			"/api/assignments/:_id",
			args,
			undefined,
			{
				responseSchema: RGETManyByIdsSchema,
			}
		).then((data: IRGETManyByIds) => {
			return this._AssignmentModel.loadManySync(data);
		});
	};

	update = (args: IAPUTEditAssignment): Promise<Assignment> =>
		this.Request.send(
			"PUT",
			"/api/assignments/:_id",
			args,
			this.getCustomConfig(),
			{
				requestSchema: APUTEditAssignmentSchema,
				responseSchema: RPUTEditAssignmentSchema,
			}
		).then((data: IRPUTEditAssignment) => {
			return this._AssignmentModel.loadOneSync(data);
		});

	getManyByClassroom = (args: IAGETByClassroom): Promise<Assignment[]> => {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/assignments",
			args,
			undefined,
			{
				responseSchema: RGETByClassroomSchema,
			}
		).then((data: IRGETByClassroom) => {
			return this._AssignmentModel.loadManySync(data);
		});
	};

	start = (assignmentId: ObjectId): Promise<IRPUTStartAssignment> =>
		this.Request.send(
			"PUT",
			"/api/assignments/:_id/start",
			{ _id: assignmentId },
			this.getCustomConfig(),
			{
				responseSchema: RPUTStartAssignmentSchema,
			}
		).then((data: IRPUTStartAssignment) => {
			return data;
		});

	saveProgress = (
		args: IAPUTSaveAssignmentProgress
	): Promise<IRPUTSaveAssignmentProgress> => {
		return this.Request.send(
			"PUT",
			"/api/assignments/:assignmentId/save-progress",
			args,
			this.getCustomConfig(),
			{
				responseSchema: RPUTSaveAssignmentProgressSchema,
			}
		);
	};

	submit = (assignmentId: ObjectId): Promise<IRPUTStartAssignment> => {
		return this.Request.send(
			"PUT",
			"/api/assignments/:_id/submit",
			{ _id: assignmentId },
			this.getCustomConfig(),
			{
				responseSchema: RPUTStartAssignmentSchema,
			}
		).catch(e => {
			if (e && e.isJoi) {
				return;
			}
			throw e;
		});
	};

	close = async (args: IAPUTCloseAssignment): Promise<IRPUTCloseAssignment> =>
		this.Request.send("PUT", "/api/assignments/:assignmentId/close", args);

	open = async (args: IAPUTOpenAssignment): Promise<IRPUTOpenAssignment> =>
		this.Request.send("PUT", "/api/assignments/:assignmentId/open", args);

	archive = async (
		args: IAPUTArchiveAssignment
	): Promise<IRPUTArchiveAssignment> =>
		this.Request.send(
			"PUT",
			"/api/assignments/:assignmentId/archive",
			args
		);

	getTaskTypeContents = async (
		args: IAGETTaskTypeContents
	): Promise<IRGETTaskTypeContents> => {
		return this.Request.send(
			"POST",
			"/api/assignments/task-type-contents",
			args,
			this.getCustomConfig(),
			{
				responseSchema: RGETQuestionsByTaskTypesSchema,
			}
		);
	};

	async getByCode(
		args: IAGETPublicAssignmentByCode
	): Promise<IRGETPublicAssignmentByCodeWithIncorrcetKey> {
		return this.Request.send(
			"GET",
			"/api/public-assignments/code/:code",
			args,
			this.getCustomConfig(),
			{
				responseSchema: RGETPublicAssignmentByCodeSchemaWithIncorrcetKey,
			}
		);
	}

	async submitPublic(
		args: IAPOSTSubmitPublicAssignment
	): Promise<IRPOSTSubmitPublicAssignment> {
		return this.Request.send(
			"POST",
			"/api/public-assignments/:assignmentId/submit",
			args,
			null,
			{
				requestSchema: APOSTSubmitPublicAssignment,
			}
		);
	}

	changeWrittenAssignmentRewritability = async (
		args: IAPOSTMakeWrittenAssignmentRewritable
	): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/written/:writtenAssignmentId/rewritable",
			args,
			null,
			{ requestSchema: APOSTMakeWrittenAssignmentRewritableSchema }
		);

	getWrittenAssignmentById = (
		args: IAGETUserWrittenAssignment
	): Promise<IRGETUserWrittenAssignment> =>
		this.Request.send(
			"GET",
			"/api/user-written-assignments",
			args,
			undefined,
			{
				responseSchema: RGETUserWrittenAssignmentSchema,
			}
		).then((data: IRGETUserWrittenAssignment) => {
			// this._AssignmentModel.loadOneSync(data.assignment);
			return data;
		});

	updateWrittenAssignment = async (args: IAPUTWrittenAssignment) => {
		this.Request.send("PUT", "/api/assignments/:_id/written", args).then(
			(data: IRPUTWrittenAssignment) => data
		);
	};

	getStartAssignmentToken = async (
		args: IAPOSTStartAssignmentTimer
	): Promise<IRPOSTStartAssignmentTimer> =>
		this.Request.send(
			"POST",
			"/api/assignemnts/:assignmentId/timer-token",
			args,
			null,
			{
				requestSchema: APOSTStartAssignmentTimerScheme,
				responseSchema: RPOSTStartAssignmentTimerScheme,
			}
		);

	private getCustomConfig = () => {
		return {
			headers: {
				"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
			},
		};
	};
}

export type IFinalGETHomeworkListForStudent = ReplaceIn<
	IStudentHomeworkListItem,
	{ assignment: Assignment }
>[];

export type IFinalGETCognitiveHomeworkListForStudent = ReplaceIn<
	IStudentHomeworkListItem,
	{ assignment: Assignment }
>[];
