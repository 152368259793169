import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import memoizeOne from "memoize-one";
import Select from "react-select";
import {
	addButtonClassName,
	IItemEditClassNames,
	IItemEditEditProps,
	ItemEdit,
	itemEditClassName,
	itemEditContainerClassName,
} from "../common/items-edit";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	Omit,
	OptionalKeys,
} from "../../../../utils/generics";
import {
	CheckerType,
	CheckStrictness,
	IFillingBlanksContent,
	IInputItem,
	INonCheckableInputItem,
	FBItemType,
	FBContentDesignStructure,
	FBInputSize,
} from "../../../../schemas/questions/contnets/filling-blanks/schema";
import { ContentType } from "../../../../schemas/questions/contnets/common-schemas";
import { css, cx } from "emotion";
import {
	EditorText,
	mergeComponentObjects,
	mergeStylesObjects,
} from "../../../../utils";
import { FBItemsContainer, IProps as FBTestMode } from "./";
import { IContentEditor, IContentEditorProps } from "../interfaces";
import { IEditCommonProps } from "../edit";
import { IItemContainerProps } from "../common/stats";
import { ItemAfterLabel } from "../common/items";
import { removeKeys } from "../../../../utils/common";

interface ITexts {
	input?: IFBItemInputTexts;
	statement: {
		placeholder?: string;
	};
	nonCheckableItem: IFBItemNonCheckableTexts;
	explanation: {
		placeholder?: string;
	};
}

type missingParams =
	| "content"
	| "disableEditingAnswer"
	| "displayAnswer"
	| "onUserAnswerChange";

interface IFillingBalnksEditorComponents {
	input?: IFBItemInputsComponents;
	statement?: IFBItemInputsComponents;
	item?: {
		head?: AnyComponent<any>;
		tail?: AnyComponent<IItemEditEditProps>;
	};
}

type IProps = Partial<IEditCommonProps> &
	Omit<FBTestMode, missingParams> & {
		content: IFillingBlanksContent;
		toHTML: (editorState: EditorText) => string;
		toEditorState: (html: string) => EditorText;
		texts?: ITexts;
		components?: IFillingBalnksEditorComponents;
	} & IContentEditorProps;

export type IFBEditPassableProps = OptionalKeys<
	Omit<IProps, "content">,
	("generateUniqueIds" | "texts") | "toEditorState" | "toHTML"
>;

interface IState {
	items: IFillingBlanksContent["items"];
	ignoreOrderOfInputs: boolean;
	isAddingNewItem: boolean;
	explanation?: Omit<
		NotUndefined<IFillingBlanksContent["explanation"]>,
		"text"
	> & { text: EditorText };
	designStructure: IFillingBlanksContent["designStructure"];
}

const statContClassName = css`
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
`;

const contClassName = css`
	display: block;
	margin: 5px;
`;

const textClassName = css({});

class FillingBalnksEditor extends React.PureComponent<IProps, IState>
	implements IContentEditor<IFillingBlanksContent> {
	defaultStyles = {
		statement: {
			container: cx(itemEditContainerClassName, contClassName),
			text: cx(itemEditClassName, statContClassName),
		},
		input: {
			container: cx(itemEditContainerClassName, contClassName),
			text: cx(itemEditClassName, statContClassName),
		},
		explanation: {
			container: itemEditContainerClassName,
		},
	} as NotUndefined<IProps["styles"]>;

	getStyles = memoizeOne((styles: IProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	defaultTexts = {
		statement: {
			placeholder: "Enter statement",
		},
		explanation: {
			placeholder: "Enter explanation",
		},
	} as NotUndefinedAtAll<IProps["texts"]>;

	getTexts = memoizeOne((texts: IProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	getComponents = memoizeOne(
		(
			components: IProps["components"],
			texts: NotUndefinedAtAll<IProps["texts"]>,
			styles: NotUndefined<IProps["styles"]>
		) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents(texts, styles)
			);
		}
	);

	constructor(props: IProps) {
		super(props);
		this.state = {
			items: props.content.items.map(item => {
				if (item.type === FBItemType.Text) {
					return { ...item, text: props.toEditorState(item.text) };
				}
				if (item.type === FBItemType.Input) {
					return {
						...item,
						correctInputs: item.correctInputs.map(inp => ({
							...inp,
							text: props.toEditorState(inp.text),
						})),
					};
				}
				return item;
			}),
			ignoreOrderOfInputs: !!props.content.ignoreOrderOfInputs,
			explanation: props.content.explanation
				? {
						...props.content.explanation,
						text: this.props.toEditorState(
							props.content.explanation.text
						),
				  }
				: undefined,
			isAddingNewItem: false,
			designStructure: props.content.designStructure,
		};
	}

	defaultComponents = (
		texts: NotUndefinedAtAll<IProps["texts"]>,
		styles: NotUndefined<IProps["styles"]>
	) =>
		({
			statement: {
				tail: props => (
					<ItemAfterLabel
						{...props}
						id={props.stat.id}
						onDelete={this.onDelete}
						deleteText="Delete"
					/>
				),
				container: (props: IItemContainerProps) => (
					<ItemEdit
						key={props.statement.id}
						stat={props.statement}
						onChange={this.onChange}
						placeholder={texts.statement.placeholder}
						components={
							this.defaultComponents(texts, styles).statement
						}
						styles={styles.statement}
						galleryComponent={this.props.galleryComponent}
					/>
				),
			},
			input: {
				container: props => (
					<FBItemInputs
						key={props.item.id}
						item={props.item as IInputItem}
						texts={texts.input}
						styles={styles.input}
						onChange={this.onChange}
						onDelete={this.onDelete}
						toEditorState={this.props.toEditorState}
						generateUniqueIds={this.props.generateUniqueIds}
					/>
				),
			},
			nonCheckable: {
				container: props => (
					<FBItemNonCheckable
						key={props.item.id}
						item={props.item as INonCheckableInputItem}
						onChange={this.onChange}
						onDelete={this.onDelete}
						texts={texts.nonCheckableItem}
					/>
				),
			},
			explanation: props => (
				<>
					{props.explanation && (
						<ItemEdit
							stat={props.explanation as any}
							onChange={this.onExplanationChange}
							styles={props.styles}
							placeholder={texts.explanation!.placeholder}
							galleryComponent={this.props.galleryComponent}
						/>
					)}
				</>
			),
			items: {
				after: () =>
					!this.state.isAddingNewItem ? (
						<button
							onClick={this.onAddItemClick}
							className={addButtonClassName}
						>
							<AddIcon />
							<span>Add</span>
						</button>
					) : (
						<ChooseNewItem
							onChange={this.onItemAdd}
							onCancel={this.onAddItemCancel}
						/>
					),
			},
		} as NotUndefined<IProps["components"]>);

	componentDidMount() {
		if (!this.state.explanation) {
			this.setState({
				explanation: {
					id: this.props.generateUniqueIds()[0],
					text: this.props.toEditorState(""),
				},
			});
		}
	}

	getData = () => {
		const expText = this.state.explanation
			? this.props.toHTML(this.state.explanation.text)
			: undefined;
		return {
			...removeKeys(this.state, "isAddingNewItem"),
			type: ContentType.FillingBlanks as ContentType.FillingBlanks,
			items: this.state.items.map(item => {
				if (item.type === FBItemType.Text) {
					return { ...item, text: this.props.toHTML(item.text) };
				}
				if (item.type === FBItemType.Input) {
					return {
						...item,
						correctInputs: item.correctInputs.map(inp => ({
							...inp,
							text: this.props.toHTML(inp.text),
						})),
					};
				}
				return item;
			}),
			designStructure: this.state.designStructure,
			explanation: expText
				? { ...this.state.explanation!, text: expText }
				: undefined,
		};
	};

	getErrors = () => {
		// TODO: estimate errors
		return [];
	};

	onDelete = (id: number) => {
		this.setState({
			items: this.state.items.filter(e => e.id !== id),
		});
	};

	onChange = (stat: { id: number; [key: string]: any }) => {
		this.setState({
			items: this.state.items.map(item =>
				item.id !== stat.id ? item : { ...item, ...stat }
			),
		});
	};

	onExplanationChange = (stat: EditorText) => {
		this.setState({
			explanation: stat,
		});
	};

	onItemAdd = (itemType: FBItemType) => {
		const [id] = this.props.generateUniqueIds();
		let item: IFillingBlanksContent["items"][number];
		if (itemType === FBItemType.Text) {
			item = { id, type: itemType, text: this.props.toEditorState("") };
		} else if (itemType === FBItemType.Input) {
			item = {
				id,
				type: itemType,
				score: 1,
				correctInputs: [
					{
						id: this.props.generateUniqueIds()[0],
						text: this.props.toEditorState(""),
					},
				],
				checkStrictness: CheckStrictness.MEDIUM,
			};
		} else if (itemType === FBItemType.NonCheckableInput) {
			item = { id, type: itemType, whoWillCheck: CheckerType.NoOne };
		} else {
			console.error(`unsupported item type ${itemType}`);
			return;
		}
		this.setState({
			items: [...this.state.items, item],
			isAddingNewItem: false,
		});
	};

	getCurrentIds = (): number[] => {
		const ids: number[] = [];
		this.state.items.forEach(item => {
			ids.push(item.id);
			if (item.type === FBItemType.Input) {
				item.correctInputs.forEach(inp => ids.push(inp.id));
			}
		});
		return ids;
	};

	onAddItemClick = () => {
		this.setState({
			isAddingNewItem: true,
		});
	};

	onAddItemCancel = () => {
		this.setState({
			isAddingNewItem: false,
		});
	};

	onUserAnsChange = () => {
		//
	};

	getContextValue = memoizeOne(
		(
			styles: NonNullable<IProps["styles"]>,
			texts: NonNullable<IProps["texts"]>,
			galleryComponent: IProps["galleryComponent"],
			designStructure: FBContentDesignStructure | undefined
		): FBEditContextValue => {
			return {
				styles,
				texts,
				designStructure,
				galleryComponent,
				onExplanationChange: this.onExplanationChange,
				onDelete: this.onDelete,
				onChange: this.onChange,
			};
		}
	);

	render() {
		const styles = this.getStyles(this.props.styles);
		const texts = this.getTexts(this.props.texts);
		const components = this.getComponents(
			this.props.components,
			texts,
			styles
		);
		const content = {
			...this.props.content,
			items: this.state.items,
			ignoreOrderOfInputs: this.state.ignoreOrderOfInputs,
			explanation: this.state.explanation,
		};
		return (
			<div className={styles.container}>
				<FBEditContext.Provider
					value={this.getContextValue(
						styles,
						texts,
						this.props.galleryComponent,
						content.designStructure
					)}
				>
					<FBItemsContainer
						components={components}
						content={content}
						disableEditingAnswer={false}
						displayAnswer={true}
						onUserAnswerChange={this.onUserAnsChange}
						styles={styles}
						texts={texts}
						hideViewMode={true}
						displayItemAssessments={false}
					/>
				</FBEditContext.Provider>
			</div>
		);
	}
}

interface IFBItemInputsComponents {
	head?: AnyComponent<any>;
	tail?: AnyComponent<any>;
}

interface IFBItemInputTexts {
	placeholder?: string;
	severities?: {
		low?: string;
		medium?: string;
		high?: string;
		placeholder?: string;
	};
	sizes?: IFBInputSizeTexts;
}

const sizesDefaultTexts: IFBInputSizeTexts = {
	placeholder: "Size:",
	options: {
		[FBInputSize.Small]: "Small",
		[FBInputSize.Normal]: "Normal",
		[FBInputSize.WholeLine]: "Whole line",
		[FBInputSize.Large]: "Large",
		[FBInputSize.ExtraLarge]: "Extra Large",
	},
};

interface IFBItemInputsProps {
	item: IInputItem;
	components?: IFBItemInputsComponents;
	styles?: IItemEditClassNames;
	texts?: IFBItemInputTexts;
	onChange: (newItem: IInputItem) => void;
	onDelete: (id: number) => void;
	toEditorState: (html: string) => EditorText;
	generateUniqueIds: IContentEditorProps["generateUniqueIds"];
}

const itemContClassName = css`
	display: flex;
	align-items: center;
	margin: 5px 0;
`;

const addAlternativeButtonClassName = css`
	display: block;
	width: 100%;
	border-radius: 4px;
	border: 1px solid #ccc;
	background: white;
	padding: 7px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

const FBInputSelectClassName = css({
	marginBottom: 5,
});

class FBItemInputs extends React.PureComponent<IFBItemInputsProps> {
	defaultTexts = {
		placeholder: "Enter input",
		severities: {
			low: "Low",
			medium: "Medium",
			high: "High",
			placeholder: "Choose check strictness",
		},
		sizes: sizesDefaultTexts,
	} as NotUndefinedAtAll<IFBItemInputsProps["texts"]>;

	getTexts = memoizeOne((texts: IFBItemInputsProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	defaultStyles = {
		container: cx(itemEditContainerClassName, itemContClassName),
		text: cx(itemEditClassName, textClassName),
	} as NotUndefined<IFBItemInputsProps["styles"]>;

	getStyles = memoizeOne((styles: IFBItemInputsProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	defaultComponents = {
		tail: props => (
			<ItemAfterLabel
				{...props}
				id={props.stat.id}
				onDelete={this.onDelete}
				deleteText="Delete"
			/>
		),
	} as NotUndefined<IFBItemInputsProps["components"]>;

	getComponents = memoizeOne(
		(components: IFBItemInputsProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	getStrictnessOptions = memoizeOne(
		(
			severityTexts: NotUndefinedAtAll<
				IFBItemInputsProps["texts"]
			>["severities"]
		) => {
			const options = [
				{ value: CheckStrictness.LOW, label: severityTexts.low },
				{ value: CheckStrictness.MEDIUM, label: severityTexts.medium },
				{ value: CheckStrictness.HIGH, label: severityTexts.high },
			];
			return options;
		}
	);

	onDelete = (id: number) => {
		const correctInputs = this.props.item.correctInputs.filter(
			each => each.id !== id
		);
		if (correctInputs.length > 0) {
			this.props.onChange({ ...this.props.item, correctInputs });
		} else this.props.onDelete(this.props.item.id);
	};

	onInputChange = (stat: { id: number; text: any }) => {
		const correctInputs = this.props.item.correctInputs.map(inp => {
			return inp.id !== stat.id ? inp : { ...inp, ...stat };
		});
		this.props.onChange({ ...this.props.item, correctInputs });
	};

	addInput = () => {
		this.props.onChange({
			...this.props.item,
			correctInputs: [
				...this.props.item.correctInputs,
				{
					id: this.props.generateUniqueIds()[0],
					text: this.props.toEditorState(""),
				},
			],
		});
	};

	onStrictnessChange = (selected: {
		value: CheckStrictness;
		label: string;
	}) => {
		this.props.onChange({
			...this.props.item,
			checkStrictness: selected.value,
		});
	};

	onSizeChange = (value: FBInputSize) => {
		this.props.onChange({
			...this.props.item,
			size: value,
		});
	};

	render() {
		const texts = this.getTexts(this.props.texts);
		const styles = this.getStyles(this.props.styles);
		const components = this.getComponents(this.props.components);
		const strictnessOptions = this.getStrictnessOptions(texts.severities);
		const selectedStrictness = strictnessOptions.find(
			e => e.value === this.props.item.checkStrictness
		);
		return (
			<div className={this.props.styles && this.props.styles.container}>
				{this.props.item.correctInputs.map((inp, i) => (
					<ItemEdit
						key={inp.id}
						stat={inp}
						onChange={this.onInputChange}
						placeholder={texts.placeholder}
						components={components}
						styles={styles}
					/>
				))}
				<Select
					className={FBInputSelectClassName}
					value={selectedStrictness}
					onChange={this.onStrictnessChange}
					options={strictnessOptions}
					placeholder={texts.severities.placeholder}
				/>
				<button
					className={addAlternativeButtonClassName}
					onClick={this.addInput}
				>
					Add alternative answer
				</button>
				<SizeSelect
					value={this.props.item.size}
					texts={texts.sizes}
					onChange={this.onSizeChange}
				/>
			</div>
		);
	}
}

interface IFBInputSizeTexts {
	placeholder?: string;
	options?: { [key in FBInputSize]?: string };
}

interface IFBItemNonCheckableTexts {
	whoWillCheck?: string;
	NoOne?: string;
	OneSelf?: string;
	Editor?: string;
	sizes?: IFBInputSizeTexts;
}

interface IFBItemNonCheckableComponents {
	head: AnyComponent<any>;
	tail: AnyComponent<any>;
}

interface IFBItemNonCheckableProps {
	item: INonCheckableInputItem;
	onChange: (newItem: INonCheckableInputItem) => void;
	onDelete: (id: number) => void;
	texts?: IFBItemNonCheckableTexts;
	components?: IFBItemNonCheckableComponents;
}

const nonCheckableClassName = css`
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #ccc;
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
`;

const selectCont = css({
	position: "relative",
	zIndex: 2,
	display: "flex",
	alignItems: "center",
	minWidth: 200,
	"&>div:first-child": {
		width: "100%",
	},
});
class FBItemNonCheckable extends React.PureComponent<IFBItemNonCheckableProps> {
	defaultTexts = {
		NoOne: "No one",
		OneSelf: "Student",
		Editor: "Teacher",
		whoWillCheck: "Who will check?",
		sizes: sizesDefaultTexts,
	} as NotUndefinedAtAll<IFBItemNonCheckableProps["texts"]>;

	getTexts = memoizeOne((texts: IFBItemNonCheckableProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	getOptions = memoizeOne(
		(texts: NotUndefinedAtAll<IFBItemNonCheckableProps["texts"]>) => {
			const options = [
				{ value: CheckerType.NoOne, label: texts.NoOne },
				{ value: CheckerType.OneSelf, label: texts.OneSelf },
				{ value: CheckerType.Editor, label: texts.Editor },
			];
			return options;
		}
	);

	defaultComponents = {
		tail: props => (
			<ItemAfterLabel
				{...props}
				id={props.stat.id}
				onDelete={this.onDelete}
				deleteText="Delete"
			/>
		),
	} as NotUndefined<IFBItemNonCheckableProps["components"]>;

	getComponents = memoizeOne(
		(components: IFBItemNonCheckableProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	onDelete = () => {
		this.props.onDelete(this.props.item.id);
	};
	onChange = (checked: { value: CheckerType; label: string }) => {
		this.props.onChange({
			...this.props.item,
			whoWillCheck: checked.value,
		});
	};

	onSizeChange = (value: FBInputSize) => {
		this.props.onChange({
			...this.props.item,
			size: value,
		});
	};

	render() {
		const texts = this.getTexts(this.props.texts);
		const options = this.getOptions(texts);
		const selectedOption = options.find(
			e => e.value === this.props.item.whoWillCheck
		);
		const components = this.getComponents(this.props.components);
		return (
			<div className={nonCheckableClassName}>
				{texts.whoWillCheck}
				<div className={selectCont}>
					{components.head && (
						<components.head stat={this.props.item} />
					)}
					<Select
						value={selectedOption}
						onChange={this.onChange}
						options={options}
						placeholder={texts.whoWillCheck}
					/>
					{components.tail && (
						<components.tail stat={this.props.item} />
					)}
				</div>
				<SizeSelect
					value={this.props.item.size}
					texts={texts.sizes}
					onChange={this.onSizeChange}
				/>
			</div>
		);
	}
}

interface IChooseNewItemTexts {
	text?: string;
	input?: string;
	nonCheckableInput?: string;
	chooseItemType?: string;
}
interface IChooseNewItemProps {
	onChange: (value: FBItemType) => void;
	onCancel: () => void;
	texts?: IChooseNewItemTexts;
}

const chooseNewItemClassName = css`
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #ccc;
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
`;

class ChooseNewItem extends React.PureComponent<IChooseNewItemProps> {
	getOptions = memoizeOne((texts: IChooseNewItemTexts = {}) => {
		return [
			{ value: FBItemType.Text, label: texts.text || "text" },
			{ value: FBItemType.Input, label: texts.input || "input" },
			{
				value: FBItemType.NonCheckableInput,
				label: texts.nonCheckableInput || "non-checkable input",
			},
		];
	});

	getComponents = memoizeOne(() => {
		return {
			selectAfter: props => (
				<ItemAfterLabel
					{...props}
					id={null}
					onDelete={this.props.onCancel}
					deleteText="Delete"
				/>
			),
		};
	});

	onChange = (checked: { value: FBItemType; label: string }) => {
		this.props.onChange(checked.value);
	};

	render() {
		const options = this.getOptions(this.props.texts);
		const chooseItemsText =
			(this.props.texts && this.props.texts.chooseItemType) ||
			"Choose item type";
		const components = this.getComponents();
		return (
			<div className={chooseNewItemClassName}>
				<div className={selectCont}>
					<Select
						onChange={this.onChange}
						options={options}
						placeholder={chooseItemsText}
						autoFocus={true}
						defaultMenuIsOpen={true}
					/>
					<components.selectAfter />
				</div>
			</div>
		);
	}
}

interface FBSizeSelectProps {
	texts: IFBInputSizeTexts;
	value?: FBInputSize | null;
	onChange: (newValue: FBInputSize) => void;
}

const SizeSelect: React.FC<FBSizeSelectProps> = React.memo(
	({ texts, value, onChange }) => {
		const options = React.useMemo(() => {
			const enumValues = [
				FBInputSize.Small,
				FBInputSize.Normal,
				FBInputSize.WholeLine,
				FBInputSize.Large,
				FBInputSize.ExtraLarge,
			];
			return enumValues.map(v => ({
				value: v,
				label: texts.options?.[v] || v + "",
			}));
		}, [texts.options]);
		const selectedOption = options.find(e => e.value === value);

		const handleChange = React.useCallback(
			(sel: { value: FBInputSize; label: string }) => {
				onChange(sel.value);
			},
			[onChange]
		);

		return (
			<Select
				onChange={handleChange}
				options={options}
				value={selectedOption}
				placeholder={texts.placeholder}
			/>
		);
	}
);

export default FillingBalnksEditor;

export interface FBEditContextValue {
	texts: NonNullable<IProps["texts"]>;
	styles: NonNullable<IProps["styles"]>;
	onExplanationChange: (stat: EditorText) => void;
	galleryComponent: IProps["galleryComponent"];
	onDelete: (id: number) => void;
	designStructure: FBContentDesignStructure | undefined;
	onChange: (stat: EditorText) => void;
}

export const FBEditContext = React.createContext({} as FBEditContextValue);
