/* eslint-disable max-lines */
import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { CourseType } from "@app/api/courses-info/helper-schemas";
import { IRGETMyInfoAsStudent } from "@app/api/users/validators";
import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { onStudentOpenVideoLesson } from "@app/common-javascript";
import { ArchiveBoxes } from "@app/components/archive/boxes";
import { useArchivedSemesters } from "@app/components/archive/utils";
import { BulterAd, FooterBulterAd, IbsuAd } from "@app/components/dev/ads";
import {
	ClassTimesPreview,
	IClassTimesPreviewCoordinates,
} from "@app/components/teachers/main/tvschool/classtimes-preview";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { isCertificateServicesAvaliable } from "@app/consts";
import {
	getCurrentWebsite,
	isLocalhost,
	SubWebsiteOrigin,
	WebsiteOrigin,
} from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { useGoToUrl, useLocationQuery } from "@app/hooks/front";
import { useGroupsByStudentId } from "@app/hooks/groups";
import { useLocale } from "@app/hooks/intl";
import {
	useChildInfoAsParent,
	useMyInfoAdStudentOrChild,
} from "@app/hooks/student";
import { useSubjectsByIds } from "@app/hooks/subjects";
import { useClassroomsUser, useUserShortInfo } from "@app/hooks/users";
import { useVideoLesson, useVideoLessonStatus } from "@app/hooks/video-lessons";
import { Classroom } from "@app/models/classroom";
import { Group } from "@app/models/group";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { useChildLink } from "@app/routes/app-link-utils";
import { studentLinks } from "@app/routes/student/links";
import { disabledTimetableSchools } from "@app/services/video-lessons/utils";
import { arrayToObject, isNonNullable } from "@app/utils/common";
import { getFormattedMessage } from "@app/utils/locale";
import DuoIcon from "@material-ui/icons/Duo";
import { classNamed } from "@pckgs/classed-components";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import TimeTableBC from "../../teachers/main/tvschool/styles/imgs/bc-timetable.jpg";
import { HalfMoon } from "../../widgets/half-moon";
import { StudentBox } from "../boxes";
import { KingsOlympiadBox } from "../boxes/kings";
import { CognitiveBox } from "../cognitive-tests/cognitive-box";
import { LupiOffersBox, OffersBox } from "../offers/box";
import { isUserEligibleForTryingWeeklyTest } from "../popups/weekly-test/utils";
import { InvitedRegistrationPopupWrapper } from "../register-invitation/invited-popup";
import {
	StudentCertificateBox,
	StudentCertificateBoxMarkup,
} from "./certificate";
import { EnlistInClassroomBox } from "./enlist-classroom";
import { LupiAd } from "./lupiAd";
import OfferWeeklies from "./OfferWeeklies";
import styles from "./styles/user-subject-list.module.css";
import { useBoolean } from "../../../hooks/general";
import { ObjectId } from "@app/utils/generics";

const finalsDisabledSchools: Set<number> = new Set([]);

export const TVSchoolStudentMainPage = React.memo(() => {
	const studentInfo = useMyInfoAdStudentOrChild();
	return <TVSchoolStudentMainPageContent studentInfo={studentInfo} />;
});

export const TVSchoolParentChildMainPage: React.FC<{
	childId: string;
}> = React.memo(({ childId }) => {
	const studentId = parseInt(childId);
	const studentInfo = useChildInfoAsParent(studentId);

	if (studentInfo?.userId !== studentId) {
		return <FancyLoadingCenter />;
	}

	return <TVSchoolStudentMainPageContent studentInfo={studentInfo} />;
});

const TVSchoolStudentMainPageContent: React.FC<{
	studentInfo: IRGETMyInfoAsStudent | null;
	// eslint-disable-next-line max-lines-per-function
}> = React.memo(({ studentInfo }) => {
	const query = useLocationQuery(true);
	const locale = useLocale();
	const classroomsUser = useClassroomsUser()!;
	const gotoWeeklyCertificates = useGoToUrl(
		studentLinks.weeklies.certificates
	);
	const classroomsById = useMemo(() => {
		if (!studentInfo) return null;
		return arrayToObject(studentInfo.classrooms, "_id");
	}, [studentInfo]);

	const studentId = studentInfo?.userId;

	const groups = useGroupsByStudentId(studentId);

	const isWeeklyCertificateDisabled = false;

	const gotoClassroom = useGoToUrl(
		useChildLink(link => link.classrooms.assignments.list)
	);

	const archiveLink = useChildLink(link => link.archived.classrooms);

	const subWebsite = useSubWebsite();
	const isBritishCenter = subWebsite === SubWebsiteOrigin.britishCenter;

	const archivedSemesters = useArchivedSemesters({
		classrooms: studentInfo?.classrooms,
		groups: null,
	});

	if (!studentInfo || !classroomsById || !studentId) {
		return <FancyLoadingCenter />;
	}

	const ClassroomModel = inject("ClassroomModel");

	const hasDisabledSchool = studentInfo.classrooms.some(
		e => e.schoolId && finalsDisabledSchools.has(e.schoolId)
	);
	const isEligibleForWeeklyTests =
		!hasDisabledSchool &&
		isUserEligibleForTryingWeeklyTest(classroomsUser) &&
		subWebsite === SubWebsiteOrigin.main;

	const isEligibleForAd = !isBritishCenter;

	const forcefullyShowAddInClassroom =
		!!localStorage.getItem("forcefullyShowAddInClassroom") || "jg" in query;

	const showEnlistingInClassroom =
		forcefullyShowAddInClassroom ||
		(classroomsUser.isStudent() && !isBritishCenter) ||
		(classroomsUser.isStudent() &&
			isBritishCenter &&
			studentInfo.classroomIds
				.map(id => classroomsById[id])
				.filter(isNonNullable)
				.filter(cl => !cl.isArchived).length === 0);

	return (
		<div className={styles.userSubjectList_page}>
			<HalfMoon
				title={
					studentInfo.classroomIds.length === 0
						? ""
						: getFormattedMessage("myClassrooms")
				}
			/>
			{isEligibleForAd && (
				<React.Fragment>
					<BulterAd
						desktopStyle={{
							// width: "745px",
							// height: "108px",
							marginTop: "-178px",
							marginBottom: "142px",
							position: "relative",
						}}
						mobileStyle={{
							// width: "314px",
							// height: "270px",
							marginTop: "-50px",
							marginBottom: "89px",
						}}
					/>
				</React.Fragment>
			)}
			<InvitedRegistrationPopupWrapper />
			<div className={styles.userSubjectListBoxContainer}>
				{isEligibleForWeeklyTests && <OfferWeeklies />}
				{locale === "ka" &&
					studentInfo.classrooms.some(
						e => e.grade <= 9 && e.grade >= 2
					) &&
					subWebsite === SubWebsiteOrigin.main && <LupiOffersBox />}
				{!isWeeklyCertificateDisabled && isEligibleForWeeklyTests && (
					<StudentCertificateBoxMarkup
						onClick={gotoWeeklyCertificates}
						title="სერტიფიკატები"
					/>
				)}
				{subWebsite === SubWebsiteOrigin.main &&
					classroomsUser.isStudent() &&
					locale === "ka" &&
					studentInfo.classrooms.some(e => e.grade >= 2) && (
						<KingsOlympiadBox />
					)}
				{studentInfo.classroomIds.length === 0 &&
					subWebsite !== SubWebsiteOrigin.britishCenter &&
					!isEligibleForWeeklyTests && (
						<ClassroomlessStudentWelcomeText />
					)}

				{!!classroomsUser?.isStudent() &&
					groups.map(
						group =>
							group.isVisible() && (
								<GroupBox key={group._id} group={group} />
							)
					)}

				<TimeTableBox
					classrooms={studentInfo.classrooms}
					studentId={studentInfo.userId}
				/>

				{studentInfo.classroomIds.map(classroomId => {
					const classroom = classroomsById[classroomId];
					if (!classroom) return null;
					const classroomDoc = new ClassroomModel(classroom);
					if (!classroomDoc.isVisible()) return null;

					if (classroom.course.type === CourseType.cognitive) {
						return null;
					}
					return (
						<ClassroomBox
							key={classroomId}
							classroom={classroom}
							onClick={() =>
								gotoClassroom({ classroomId: classroom._id })
							}
						/>
					);
				})}

				{showEnlistingInClassroom && <EnlistInClassroomBox />}

				{locale === "ka" &&
					studentInfo.classroomIds.length > 0 &&
					showOffersForStudent(studentId) &&
					subWebsite === SubWebsiteOrigin.main && <OffersBox />}
				{locale === "ka" &&
					studentInfo.classroomIds.length > 0 &&
					subWebsite === SubWebsiteOrigin.main && <CognitiveBox />}
				{locale === "ka" &&
					isCertificateServicesAvaliable &&
					studentInfo.classrooms.length > 0 && (
						<StudentCertificateBox />
					)}
				{locale === "ka" &&
					studentInfo.classrooms.some(
						e => e.grade <= 9 && e.grade >= 2
					) &&
					subWebsite === SubWebsiteOrigin.main && (
						<LupiAd
							title="დამატებითი მეცადინეობისთვის გამოიყენე"
							link="https://lupi.ge/?a=001#/"
						/>
					)}
				<ArchiveBoxes
					route={archiveLink}
					semesters={archivedSemesters}
				/>
			</div>
		</div>
	);
});

const GroupBox: React.FC<{
	group: Group;
}> = React.memo(function GroupBox({ group }) {
	const videoLesson = useVideoLesson(
		group._id,
		VideoLessonResourceTypes.GROUP
	);

	const handleOpenVideolink = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		onStudentOpenVideoLesson(videoLesson.doc);
	};

	const lessonStatus = useMemo(
		() =>
			videoLesson.doc?.getGroupStatus() ??
			VideoLessonStatus.NOT_AVAILABLE,
		[videoLesson.doc]
	);

	return (
		<StudentBox.Container style={{ paddingTop: 50 }}>
			<img
				alt="buld"
				src="/imgs/classhead_illustration.svg"
				style={{ width: "38%" }}
			/>
			<StudentBox.NewsLabel
				style={{ background: "#edba41", color: "black" }}
			>
				{group.name}
			</StudentBox.NewsLabel>
			<RawVideoLessonButton
				onClick={
					lessonStatus !== VideoLessonStatus.NOT_AVAILABLE
						? handleOpenVideolink
						: undefined
				}
				className={styles.groupVideoLink}
				lessonStatus={lessonStatus}
			>
				სადამრიგებლო საათი
			</RawVideoLessonButton>
		</StudentBox.Container>
	);
});

const RawVideoLessonButton = classNamed("button")<{
	lessonStatus: VideoLessonStatus | null;
}>(({ lessonStatus }) => {
	return useMemo(() => {
		switch (lessonStatus) {
			case VideoLessonStatus.NOT_AVAILABLE:
				return styles.nolink;
			case VideoLessonStatus.AVAILABLE:
				return styles.notActiveLesson;
			default:
				return "";
		}
	}, [lessonStatus]);
});

const TimeTableBox: React.FC<{
	classrooms: IClassroom[];
	studentId: number;
}> = React.memo(({ classrooms, studentId }) => {
	const hasToDisplayTimelineBox =
		classrooms
			.map(e => new Classroom(e))
			.filter(e => e.isVisible)
			.some(e => e.classTimes.length > 0) &&
		!classrooms.some(c => disabledTimetableSchools.includes(c.schoolId!));

	const classroomsUser = useClassroomsUser();
	const redirect = useGoToUrl(
		useChildLink(studentId, links => links.timetable)
	);
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	const britishStyles = { width: 250, height: "auto", marginRight: 5 };
	const tvschoolTimetableImageDir = "/imgs/schedule_Illustration.svg";
	if (!hasToDisplayTimelineBox) return null;
	return (
		<div className={styles.userSubjectListBox} onClick={redirect}>
			<img
				alt="buld"
				src={isBritishCenter ? TimeTableBC : tvschoolTimetableImageDir}
				className={styles.illustration}
				style={isBritishCenter ? britishStyles : undefined}
			/>

			<div className={styles.userSubjectListBox_btn}>
				{classroomsUser?.isStudent() && "ჩემი "}ცხრილი
			</div>
		</div>
	);
});

export const ClassroomBox: React.FC<{
	classroom: IRGETMyInfoAsStudent["classrooms"][number];
	onClick?: () => void;
	// eslint-disable-next-line max-lines-per-function
}> = React.memo(function ClassroomBox({ classroom, onClick }) {
	const classroomsUser = useClassroomsUser();
	const videoLesson = useVideoLesson(
		classroom._id,
		VideoLessonResourceTypes.CLASSROOM
	);
	const subjects = useSubjectsByIds(classroom.subjects || []);
	const teacherId = classroom.teacherId;
	const isDutch =
		useLocale() !== "ka" && getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const teacherInfo = useUserShortInfo(teacherId || null);
	const showTextFirst = isDutch;

	const isPremium = new Classroom(classroom).isPremium();

	const [classTimesPrev, setClassTimesPrev] = useState<
		IClassTimesPreviewCoordinates
	>();
	const handleOpenVideolink = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		onStudentOpenVideoLesson(videoLesson.doc);
	};

	const hasNoteAlert = useHasNoteAlert(classroom._id);

	const enableVideoLesson = !!classroomsUser?.isStudent();

	const lessonStatus =
		useVideoLessonStatus(videoLesson.doc, classroom.classTimes) ??
		VideoLessonStatus.NOT_AVAILABLE;
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	if (
		!subjects.doc ||
		(teacherInfo.isIdentificationKnown && !teacherInfo.isSuccessfullyLoaded)
	) {
		return null;
	}

	const firstSubject = subjects.doc[0];
	const numNewHomeworks = classroom.numNewHomeworks;
	const numIncompleteHomeworks = classroom.numIncompleteHomeworks;

	return (
		<div
			className={styles.userSubjectListBox}
			style={{
				justifyContent: !!classroomsUser?.isParent()
					? "space-evenly"
					: "space-between",
			}}
			onClick={onClick}
		>
			{(numNewHomeworks > 0 || hasNoteAlert) && (
				<span className={styles.task}>
					{getFormattedMessage("teacher:Assignment")}!
				</span>
			)}

			<div className={styles.userSubjectListBox_subjectInfo}>
				{firstSubject && firstSubject.photo && (
					<div
						className={styles.userSubjectListBox_subjectInfo__icon}
						style={{
							backgroundImage: `url(${firstSubject.photo})`,
						}}
					/>
				)}
				<div className={styles.userSubjectListBox_subjectInfo__box}>
					<p className={styles.userSubjectListBox_subjectInfo__title}>
						{subjects.doc.map(subject => subject.name).join(", ")}
					</p>
					{teacherInfo.isSuccessfullyLoaded && (
						<p
							className={
								styles.userSubjectListBox_subjectInfo__teacher
							}
						>
							{teacherInfo.doc.getFullName()}
						</p>
					)}
				</div>
			</div>
			{classTimesPrev && (
				<ClassTimesPreview
					{...classTimesPrev}
					classTimes={classroom.classTimes}
					displacement={15}
				/>
			)}
			{/* <section className={styles.taskBox_wrapper}> */}
			{!isPremium ? (
				<div className={styles.taskBox}>
					<div
						className={classNames(
							styles.taskBox__newTask,
							styles.taskBox__num,
							!numNewHomeworks && styles.faded
						)}
					>
						{showTextFirst && (
							<span>
								<FormattedMessage id="newAssignmentForStudent" />
							</span>
						)}
						<span>{numNewHomeworks}</span>
						{!showTextFirst && (
							<FormattedMessage id="newAssignmentForStudent" />
						)}
					</div>

					<div
						className={classNames(
							styles.taskBox__unDoneTask,
							styles.taskBox__num,
							!numIncompleteHomeworks && styles.faded
						)}
					>
						{showTextFirst && (
							<span>
								<FormattedMessage id="isnotFinishedAssignment" />
							</span>
						)}
						<span>{numIncompleteHomeworks}</span>
						{!showTextFirst && (
							<FormattedMessage id="isnotFinishedAssignment" />
						)}
					</div>
				</div>
			) : (
				enableVideoLesson && (
					<RawVideoLessonButton
						onClick={
							lessonStatus !== VideoLessonStatus.NOT_AVAILABLE
								? handleOpenVideolink
								: undefined
						}
						onMouseOver={(
							e: React.MouseEvent<HTMLButtonElement>
						) => {
							if (classTimesPrev) return;
							setClassTimesPrev({
								top: e.clientY,
								left: e.clientX,
							});
						}}
						onMouseOut={() => setClassTimesPrev(undefined)}
						className={styles.videolink}
						lessonStatus={lessonStatus}
					>
						ვიდეოგაკვეთილი
						<DuoIcon className={styles.icon} />
					</RawVideoLessonButton>
				)
			)}
			<div className={styles.classroom_enter}>
				<div className={styles.line}></div>
				<div className={styles.userSubjectListBox_btn}>
					<FormattedMessage id="enter" />
				</div>
			</div>
			{/* </section> */}
			{isBritishCenter && (
				<span
					style={{
						color: "#626a84",
						fontSize: "14px",
					}}
				>
					Zoom passcode: britanuli
				</span>
			)}
		</div>
	);
});

const useHasNoteAlert = (classroomId: ObjectId | null) => {
	const { value: noteAlert, setTrue, setFalse } = useBoolean(false);

	useEffect(() => {
		if (!classroomId) {
			setFalse();
			return;
		}
		inject("AssignmentsController")
			.getNoteAssignments({
				classroomId,
				last: true,
			})
			.then(x => {
				if (Array.isArray(x) && x.length > 0) {
					const difference =
						Date.now() - new Date(x[0].createdAt).getTime();
					const hours = difference / (3600 * 1000);
					if (hours <= 24) {
						setTrue();
					}
				} else {
					setFalse();
				}
			});
	}, [classroomId, setFalse, setTrue]);
	return noteAlert;
};

const ClassroomlessStudentWelcomeText = React.memo(() => {
	return (
		<div className="main main2" style={{ marginBottom: 20 }}>
			<div style={{ textAlign: "center" }}>
				<div className={styles.classroomlessBox}>
					<span style={{ fontSize: 20 }}>
						<FormattedMessage id="studentSuccessfullyFinishesRegistration" />
					</span>
					<br />
					<br />

					<FormattedMessage
						id="suggestionAfterRegistration"
						values={{ br: <br /> }}
					/>
					<br />
					<br />
					<img
						style={{ width: 800, maxWidth: "90%" }}
						src="/imgs/students-chill.svg"
						alt="students chill"
					/>
				</div>
			</div>
			<FooterBulterAd />
		</div>
	);
});

const showOffersForStudent = (userId: number): boolean => {
	if (1 < 2) return true; // TODO: always return true
	if (isLocalhost()) return true;
	return [211].indexOf(userId) > -1;
};
