import React, { useRef } from "react";
import { kingsSummonsKey } from "@app/components/teachers/popups/kings";
import { useMyCustomSummonByKey } from "@app/hooks/summonses";
import { StudentBox } from "../";
import { FancyPopup } from "@app/components/widgets/popup";
import { ModalMarkups } from "@app/components/teachers/popups/kings/modal.markup";
import { CenterContainer } from "@app/components/widgets/tools";
import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { wrapInLoading } from "@app/utils/promise";
import { inject } from "@app/modules";
import { useUserShortInfo, useClassroomsUser } from "@app/hooks/users";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { toGeorgianVocativeName } from "@app/utils/geo-names";
import { useSubWebsite } from "@app/hooks/bc";
import { SubWebsiteOrigin } from "@app/globals";
import triggerEvent from "@app/utils/events";

export const StudentKingsBox = React.memo<{}>(function StudentKingsBox({}) {
	const redirectToKings = () => {
		triggerEvent({
			action: "Kings Liga",
			label: "landing box",
			category: "Box",
		});
		window.open(
			"https://kings.ge/news/1080",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<>
			<StudentBox.Container onClick={redirectToKings}>
				<StudentBox.NewsLabel>სიახლე</StudentBox.NewsLabel>
				<div>
					<img src="/imgs/kings/liga.png" alt="logo" width="160" />
				</div>
				<StudentBox.Title>ჩაერთე ლიგაში</StudentBox.Title>
			</StudentBox.Container>
		</>
	);
});

export const KingsOlympiadBox = React.memo<{}>(function StudentCupBox({}) {
	const redirectToKings = () => {
		triggerEvent({
			action: "Kings 2021 Fall Olympiad",
			label: "landing box",
			category: "Box",
		});
		window.open(
			"https://kings.ge/news/1103",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<>
			<StudentBox.Container onClick={redirectToKings}>
				<StudentBox.NewsLabel>სიახლე</StudentBox.NewsLabel>
				<div>
					<img src="/imgs/kings/illustration.svg" alt="logo" />
				</div>
				<StudentBox.Title>გახდი მედალოსანი</StudentBox.Title>
			</StudentBox.Container>
			<StudentKingsPopupDistributor />
		</>
	);
});

const STUDNET_KINGS_POPUP_SEE_ACTION = "st-kngs-2";

export const StudentKingsPopupDistributor = React.memo<{
	displayEvenIfHasSeen?: boolean;
}>(function StudentKingsPopupDistributor({ displayEvenIfHasSeen }) {
	const displayEvenIfHasSeenRef = useRef(displayEvenIfHasSeen);
	const summon = useMyCustomSummonByKey(kingsSummonsKey);
	const userActions = useUserActions();
	const subWebsite = useSubWebsite();
	const user = useClassroomsUser();

	const { value: isClosedManually, setTrue: closeManually } = useBoolean(
		false
	);

	const hasSeenBefore = !!userActions.doc?.actions[
		STUDNET_KINGS_POPUP_SEE_ACTION
	];
	const hasApprovedBefore = !!userActions.doc?.actions[
		STUDNET_KINGS_POPUP_SEE_ACTION
	]?.approved;

	const enabled =
		!!user?.isStudent() &&
		userActions.isSuccessfullyLoaded &&
		!isClosedManually &&
		(displayEvenIfHasSeenRef.current || !hasSeenBefore) &&
		subWebsite === SubWebsiteOrigin.main;

	if (!summon.isSuccessfullyLoaded) return null;

	const hasBeenInvited = !!summon.isFound && summon.doc.isInAudience;
	if (!hasBeenInvited) return null;
	return (
		<DefaultPopupPrioritizer priority={98} enabled={enabled}>
			<StudentKingsPopup
				hasBeenInvited={hasBeenInvited}
				hasApprovedBefore={hasApprovedBefore}
				onClose={closeManually}
				teacherId={summon.isFound ? summon.doc.author : null}
			/>
		</DefaultPopupPrioritizer>
	);
});

export const StudentKingsPopup = React.memo<{
	hasBeenInvited: boolean;
	hasApprovedBefore?: boolean;
	teacherId: number | null;
	onClose: () => void;
}>(function StudentKingsPopup({
	hasBeenInvited,
	teacherId,
	hasApprovedBefore,
	onClose,
}) {
	const performAction = (approved: boolean) => {
		return inject("UserActionsController").performUserAction({
			name: STUDNET_KINGS_POPUP_SEE_ACTION,
			data: { approved, hasBeenInvited },
		});
	};
	const handleSend = () => {
		const win = window.open(undefined);
		wrapInLoading(
			performAction(true)
				.then(() => onClose())
				.then(() => {
					if (!win) return;
					win.location.replace(
						hasBeenInvited
							? "https://www.kings.ge/?=InvitedByTeacher"
							: "https://www.kings.ge/?=justPopup"
					);
				})
		);
	};
	const handleClose = () => {
		if (!hasApprovedBefore) performAction(false);
		onClose();
	};

	return (
		<FancyPopup
			onClose={handleClose}
			containerClassName={ModalMarkups.containerClassName}
			direction="left"
		>
			{hasBeenInvited && (
				<HasBeenInvitedMarkup
					onConfirm={handleSend}
					teacherId={teacherId!}
				/>
			)}
			{!hasBeenInvited && (
				<HasNotBeenInvitedMarkup onConfirm={handleSend} />
			)}
		</FancyPopup>
	);
});

const HasBeenInvitedMarkup = React.memo<{
	onConfirm: () => void;
	teacherId: number;
}>(function HasBeenInvitedMarkup({ onConfirm, teacherId }) {
	const teacher = useUserShortInfo(teacherId);
	const user = useClassroomsUser()!;
	const name = toGeorgianVocativeName(user.firstname);
	const teacherName = teacher.isSuccessfullyLoaded
		? toGeorgianVocativeName(teacher.doc.firstname || "")
		: null;
	if (!teacher.isSuccessfullyLoaded) return <FancyLoading />;

	return (
		<>
			<div style={{ maxWidth: 600 }}>
				<div className="font-roboto-geo-mt-bold text-lg text-gray-700">
					ძვირფასო {name},
				</div>
				<div className="h-14" />
				<p>
					<b>{teacherName}</b> მასწავლებელმა მოგიწვია{" "}
					<b>„კინგსის“ ოლიმპიადებზე.</b>
					<br />
					დააწკაპუნე ღილაკზე და დარეგისტრირდი
				</p>
			</div>
			<CenterContainer onClick={onConfirm}>
				<PrimaryButton>რეგისტრაცია</PrimaryButton>
			</CenterContainer>
		</>
	);
});

const HasNotBeenInvitedMarkup = React.memo<{ onConfirm: () => void }>(
	function HasNotBeenInvitedMarkup({ onConfirm }) {
		return (
			<>
				<div style={{ maxWidth: 600 }}>
					<div className="font-roboto-geo-mt-bold text-lg text-gray-700">
						ძვირფასო tvschool.ge-ს აქტიურო მოსწავლე,
					</div>
					<div className="h-8" />
					<p>
						დიდი სიხარულით გიწვევთ ჩვენი მეგობარი “კინგსის“
						ოლიმპიადის, პირველ, უფასო ტურზე.
					</p>
					<div className="h-6" />
					<p>
						ჩაერთე ახლა, მოიპოვე საჩუქარი და გაზიარებადი
						სერტიფიკატი. გვჯერა, კარგად წარმოაჩენ ოლიმპიადაზე თავს.
					</p>
					<img
						className="my-2 mt-5 max-w-full"
						alt="illustrations"
						src="/imgs/kings/ol-stuffs.svg"
					/>
				</div>
				<CenterContainer onClick={onConfirm}>
					<PrimaryButton>ოლიმპიადის წერა</PrimaryButton>
				</CenterContainer>
			</>
		);
	}
);
