import { IGroup } from "@app/api/groups/helper-schemas";
import { useArchivedSemesters } from "@app/components/archive/utils";
import { subWebsiteDistributor } from "@app/components/subwebsites";
import { useSelectedSchoolLabelId } from "@app/components/teachers/contexts/teacher-school";
import Loading from "@app/components/widgets/raw-loading";
import { useFetch } from "@app/hooks/fetch";
import { useGoToUrl } from "@app/hooks/front";
import { useGroupsBySchoolId } from "@app/hooks/groups";
import { useClassroomsUser } from "@app/hooks/users";
import { useFilteredConferences } from "@app/hooks/video-lessons";
import { headmasterLinks } from "@app/routes/headmaster/link";
import { classNamed } from "@pckgs/classed-components";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SubWebsiteOrigin } from "../../../globals";
import { useSubWebsite } from "../../../hooks/bc";
import { inject } from "../../../modules";
import TimeTableBC from "../../teachers/main/tvschool/styles/imgs/bc-timetable.jpg";
import timeTableImage from "../../teachers/main/tvschool/styles/imgs/Schedule_Illustration.svg";
import styles from "../styles/index.module.scss";
import { HeadmasterArchiveBoxes, HeadmasterBox } from "./box";
import { HeadmasterGroupBox } from "./directorate-box";
import { ConferenceBox } from "./directorate-box/conference-box";
import { HeadmasterWithClassroomsMainPage } from "./with-classrooms";

export const HeadmasterFrame = React.memo<{
	children: React.ReactNode;
	belowTitle?: React.ReactNode;
	hideTimetable?: boolean;
	topTitle?: React.ReactNode;
	afterChildrenContainer?: React.ReactNode;
}>(function HeadmasterFrame({
	children,
	belowTitle,
	topTitle,
	hideTimetable,
	afterChildrenContainer,
}) {
	return (
		<div className={styles.mainContainer}>
			<div className={styles.heading}>
				<span>
					{topTitle || (
						<FormattedMessage id="headmasterGroupsTitle" />
					)}
				</span>
			</div>
			{belowTitle}
			<BoxesGrid>
				<GridItems hideTimetable={hideTimetable} />
				{children}
			</BoxesGrid>
			{afterChildrenContainer}
		</div>
	);
});

const GridItems = React.memo<{ hideTimetable?: boolean }>(function GridItems({
	hideTimetable,
}) {
	const user = useClassroomsUser()!;
	const selectedLabelId = useSelectedSchoolLabelId();
	const conferences = useFilteredConferences({
		schoolId: user.school,
		schoolLabelId: selectedLabelId,
	});
	console.log(conferences);
	return (
		<>
			{conferences &&
				conferences.map(conf => (
					<ConferenceBox key={conf._id} conference={conf} />
				))}
			{!hideTimetable && <Timetable />}
		</>
	);
});

export const BoxesGrid = classNamed("div")(styles.boxesWrapperGrid);

const HeadmasterMain: React.FC = props => {
	const user = useClassroomsUser()!;
	const schoolGroups = useGroupsBySchoolId(user.school, true);
	const sortedGroups = useMemo(
		() =>
			schoolGroups.isSuccessfullyLoaded
				? schoolGroups.doc
						.filter(
							x =>
								x.isArchived === false ||
								x.isArchived === undefined
						)
						.sort(compareGroups)
				: null,
		[schoolGroups.doc, schoolGroups.isSuccessfullyLoaded]
	);
	const archivedGroups = useFetch(
		() =>
			inject("GroupController").getArchivedGroupsForHeadmaster({
				schoolId: user.school!,
				isArchived: true,
			}),
		[user.school]
	);

	const archiveSemesters = useArchivedSemesters({
		groups: archivedGroups.doc,
		classrooms: null,
	});

	if (!sortedGroups) {
		return <Loading />;
	}
	return (
		<HeadmasterFrame>
			{sortedGroups.map(e => (
				<HeadmasterGroupBox key={e._id} group={e} />
			))}
			{archivedGroups && (
				<HeadmasterArchiveBoxes
					route={headmasterLinks.archived.classrooms}
					semesters={archiveSemesters}
				/>
			)}
		</HeadmasterFrame>
	);
};

export const HeadmasterMainPage = subWebsiteDistributor({
	main: HeadmasterMain,
	britishCenter: HeadmasterWithClassroomsMainPage,
});

export const compareGroups = (a: IGroup, b: IGroup): number => {
	if (a.grade === b.grade && a.name && b.name) {
		return a.name > b.name ? 1 : -1;
	}
	return a.grade - b.grade;
};

export const Timetable = () => {
	const onClick = useGoToUrl(headmasterLinks.timetable);
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	return (
		<HeadmasterBox
			onClick={onClick}
			title={<FormattedMessage id="schoolTimetable" />}
			img={
				<img
					alt="timetable"
					src={isBritishCenter ? TimeTableBC : timeTableImage}
					style={{ height: 145 }}
				/>
			}
		/>
	);
};
