import { useFetch } from "./fetch";
import { inject } from "@app/modules";
import { IAGETMySummonses } from "@app/api/summonses/validators";
import { SummonsInfoType } from "@app/api/summonses/helper-schemas";
import { useMemo } from "react";
import { Summons } from "@app/models/summons";
import { getFoundNotFound, FoundNotFound } from "@app/utils/object";
import { getResourceLoadingInfo } from "react-fetch-data-hooks/lib/data";

export const useMySummonses = (query: IAGETMySummonses) => {
	return useFetch(() => inject("SummonsesController").getMySummonses(query), [
		query,
	]);
};

export const useMyCustomSummonByKey = (key: string) => {
	const summonses = useMySummonses({
		includeCreatedByMe: true,
		includeIfIAmInAudience: true,
	});

	const summons = useMemo((): FoundNotFound<Summons> | null => {
		if (!summonses.doc) return null;
		const doc = summonses.doc?.find(
			e => e.info.type === SummonsInfoType.custom && e.info.key === key
		);
		return getFoundNotFound(doc, summonses.isSuccessfullyLoaded);
	}, [summonses.doc, summonses.isSuccessfullyLoaded, key]);

	return useMemo(
		() =>
			getResourceLoadingInfo({
				isIdentificationKnown: summonses.isIdentificationKnown,
				resource: summons,
				error: summonses.error,
				loadAgain: summonses.loadAgain,
			}),
		[
			summons,
			summonses.error,
			summonses.isIdentificationKnown,
			summonses.loadAgain,
		]
	);
};
