import { UserType } from "@app/api/helper-schemas";
import { getFormattedMessage } from "@app/utils/locale";
import React, { CSSProperties, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { registrationClassNames } from ".";
import { StudentRegisterFirstStep } from "../auth/student-register-page";
import styles from "./styles/registration.module.css";

interface IRegistrationForLandingProps {
	userType?: UserType;
	onShowSingin: () => void;
	isToTest?: boolean;
}

export const RegistrationForLanding: React.FC<IRegistrationForLandingProps> = props => {
	const [isClickedOnContinue, setClickedOnContinue] = useState<boolean>(
		false
	);

	const [registerType, setRegisterType] = useState<UserType>(
		props.userType || UserType.teacher
	);

	const history = useHistory();
	const redirectToRegistration = useCallback(() => {
		history.push(`/`);
	}, [history]);

	const onSelectionClick = (userType: UserType) => {
		setRegisterType(userType);
	};

	const onRedirectionClick = (val: boolean) => {
		setClickedOnContinue(val);
	};

	const dataTypes: {
		type: UserType.student | UserType.teacher | UserType.parent;
		label: string;
	}[] = [
		{
			type: UserType.teacher,
			label: getFormattedMessage("teacher"),
		},
		{
			type: UserType.student,
			label: getFormattedMessage("student"),
		},
		{
			type: UserType.parent,
			label: getFormattedMessage("parent"),
		},
	];

	return (
		<div className={styles.landingRegistrationContainer}>
			{!isClickedOnContinue && (
				<UserTypeSelection
					onSelectionClick={onSelectionClick}
					registerType={registerType}
					data={dataTypes}
				/>
			)}

			{(registerType === UserType.student ||
				registerType === UserType.teacher ||
				registerType === UserType.parent ||
				!registerType) && (
				<StudentRegisterFirstStep
					userType={registerType}
					classNames={registrationClassNames}
					onSuccess={redirectToRegistration}
					isIconVissible={false}
					componentName={" "}
					hasInputPlaceholder={true}
					isNameAndSurnameTogether={true}
					inputClassname={styles.input}
					mobileInputClassname={styles.mobileInput}
					nameInputClassname={styles.nameInput}
					isSpecialCheckbox={true}
					registrationButtonClassname={
						registerType === UserType.parent && isClickedOnContinue
							? styles.registrationButtonSmall
							: styles.registrationButton
					}
					isClickedOnContinue={isClickedOnContinue}
					setClickedOnContinue={onRedirectionClick}
				/>
			)}
			<button
				className={styles.signInButton}
				onClick={props.onShowSingin}
			>
				<FormattedMessage id="signInTitle" />
			</button>
		</div>
	);
};

const UserTypeSelection: React.FC<{
	onSelectionClick: (userType: UserType) => void;
	registerType: UserType;
	data: {
		type: UserType.student | UserType.teacher | UserType.parent;
		label: string;
	}[];
}> = ({ onSelectionClick, registerType, data }) => {
	return (
		<div className={styles.usertypeSelectionContainer}>
			{data.map((d, index) => (
				<SelectionType
					isActive={d.type === registerType}
					onClick={() => onSelectionClick(d.type)}
					label={d.label}
					key={index}
					style={
						d.type === UserType.teacher
							? { marginLeft: -2 }
							: d.type === UserType.parent
							? { marginRight: -2 }
							: d.type === UserType.student
							? d.type === registerType
								? {
										borderLeft: "none",
										borderRight: "none",
										borderRadius: 15,
								  }
								: registerType === UserType.teacher
								? {
										borderRight: "1px solid",
										borderRadius: 0,
								  }
								: registerType === UserType.parent
								? {
										borderLeft: "1px solid",
										borderRadius: 0,
								  }
								: { borderRadius: 0 }
							: { background: "white" }
					}
				/>
			))}
		</div>
	);
};

const SelectionType: React.FC<{
	isActive: boolean;
	onClick: () => void;
	label: string;
	style: CSSProperties;
}> = ({ isActive, onClick, label, style }) => {
	return (
		<div
			className={
				isActive ? styles.activeUserTypeButton : styles.userTypeButton
			}
			style={style}
			onClick={onClick}
		>
			{label}
		</div>
	);
};
