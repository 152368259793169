/* eslint-disable max-lines-per-function */
import { WeekType } from "@app/api/weekly-tests/helper-schemas";
import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { InlineSelect } from "@app/components/widgets/inline-select/inline-select";
import { FancyPopup } from "@app/components/widgets/popup";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { CenterContainer } from "@app/components/widgets/tools";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { WeeklyTestReservationStatus } from "@app/hooks/weekly-test-reservations";
import { inject } from "@app/modules";
import { indexArray } from "@app/utils/array";
import { wrapInLoading } from "@app/utils/promise";
import { classNamed } from "@pckgs/classed-components";
import React, { useEffect, useRef, useState } from "react";
import { wrapInReservation } from "./wrapper";

export const sh20v2Key = "sh20v2";
export const sh20v2Key2 = "sh20v2Subjects";

export const Sh20V2Popup = React.memo<{ onClose: () => void }>(
	function Sh20V2Popup({ onClose }) {
		const [subjectsSelected, setSubjectsSelected] = useState<number | null>(
			null
		);
		const options = indexArray(3).map(x => ({
			value: x + 1,
			label: `${x + 1}`,
		}));
		const markAsSeen = async () => {
			await inject("UserActionsController").performUserAction({
				name: sh20v2Key,
				data: true,
			});
		};

		const hasHandlesRef = useRef(false);

		useEffect(() => {
			if (hasHandlesRef.current) return;
			inject("UserActionsController")
				.getUserAction()
				.then(a => {
					hasHandlesRef.current = true;
					if (subjectsSelected) return;
					const subjects = a.actions[sh20v2Key2];
					if (!subjects || !subjects.subjCount) return;
					setSubjectsSelected(+subjects.subjCount);
				});
		}, [subjectsSelected]);

		const handleClose = () => {
			markAsSeen();
			onClose();
		};
		const submit = async () => {
			if (!subjectsSelected) {
				openConfirmationPopup({
					text: "აირჩიეთ რაოდენობა",
				});
				return;
			}
			wrapInLoading(
				inject("UserActionsController")
					.performUserAction({
						name: sh20v2Key2,
						data: { subjCount: subjectsSelected },
					})
					.then(handleClose)
			);
		};
		return (
			<FancyPopup
				onClose={handleClose}
				title="ძვრიფასო მშობელო და მოსწავლე,"
				containerStyles={{ width: 900 }}
				containerClassName="bg-light"
			>
				<P>
					მშობელთა დადებითი გამოხმაურების ფონზე, გადავწყვიტეთ 2021
					წელს მოსწავლეთა <b>უნარების განმავითარებელი,</b>{" "}
					ყოველთვიური, <b>კვირეულები</b> ჩავატაროთ ხოლმე.
				</P>

				<P>
					კვირეული მოსწავლეს დაეხმარება იმ უნარების შეფასებასა და
					დახვეწაში, რომლებიც უჭირს და წააახალისებს მის ნაბიჯებს
					პატარ-პატარა გაზიარებადი მიღწევის ბეჯებითა და{" "}
					<b>სერტიფიკატით.</b> ამ გზით, ჩვენ შევძლებთ{" "}
					<b>თვალი ვადევნოთ მოსწავლის ზრდას თვიდან თვემდე.</b>
				</P>

				<P>
					განმავითარებელი კვირეულის ერთი თვის ერთი საგნის საფასური
					იქნება 13 ლარი, ხოლო 3 საგნის შემთხვევაში იმოქმედებს{" "}
					<b>სპეციალური ფასდაკლება:</b> „3=30 ლარი“.
				</P>

				<P>
					გთხოვთ, დააფიქსიროთ თუ აპირებთ განმავითარებელ კვირეულში
					ჩართვას და მონიშნოთ საგანთა რაოდენობა.
				</P>

				<InlineSelect<number>
					options={options}
					value={subjectsSelected}
					onChange={setSubjectsSelected}
					multiple={false}
				/>
				<CenterContainer className="mt-4">
					<PrimaryButton onClick={submit}>ჩართვა</PrimaryButton>
				</CenterContainer>
			</FancyPopup>
		);
	}
);
const P = classNamed("p")("my-2");

const RawSh20V2PopupDistributor = React.memo<{
	reservation?: WeeklyTestReservationStatus;
}>(function Sh20V2PopupDistributor({ reservation }) {
	const { value: isClosedManually, setTrue: closeManually } = useBoolean(
		false
	);
	const userActions = useUserActions();
	const amountPaid = reservation?.doc?.getAmountPaid();
	const hasBought = typeof amountPaid === "number" && amountPaid > 0;
	const isLoaded = !!reservation?.doc && !!userActions.doc;
	const hasSeen =
		!!userActions.doc?.actions[sh20v2Key] ||
		!!userActions.doc?.actions[sh20v2Key2];
	const enabled = isLoaded && !hasSeen && hasBought && !isClosedManually;

	return (
		<DefaultPopupPrioritizer priority={50} enabled={enabled}>
			<Sh20V2Popup onClose={closeManually} />
		</DefaultPopupPrioritizer>
	);
});

export const Sh20V2PopupDistributor = wrapInReservation(
	RawSh20V2PopupDistributor,
	WeekType.finals
);
