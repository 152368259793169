import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { css, cx } from "emotion";
import React from "react";
import { IStudentRegisterFirstStepProps } from "../auth/student-register-page";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { TVSchoolLanding } from "../tv-school-landing";
import { BCLanding } from "../tv-school-landing/bc_landing/index";

export type DivReference = React.MutableRefObject<HTMLDivElement | null>;

const LandingMainPage: React.FC<{}> = () => {
	const subWebsite = useSubWebsite();
	return (
		<>
			{subWebsite === SubWebsiteOrigin.main ? (
				<TVSchoolLanding />
			) : (
				<BCLanding />
			)}
		</>
	);
};

export default LandingMainPage;

export const registrationClassNames: NonNullable<IStudentRegisterFirstStepProps["classNames"]> = {
	button: css`
		background: #e84c89;
	`,
	container: css``,
	input: cx(
		StudentRegisterStyles.input,
		css`
			background: #e7e7e7;
		`
	),
};
