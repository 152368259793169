export const getMaxPriority = (
	availabilities: { priority?: number | null }[] | null,
	defaultPriority = 0
): number => {
	if (!availabilities) return defaultPriority;
	let maxPriority = -Infinity;
	for (const availability of availabilities) {
		if (typeof availability.priority === "number") {
			maxPriority = Math.max(maxPriority, availability.priority);
		}
	}
	return maxPriority === -Infinity ? defaultPriority : maxPriority;
};
