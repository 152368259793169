import React, { useMemo, useState, useRef, useEffect } from "react";
import styles from "./styles/classtimesPreview.module.css";
import { useWindowSize } from "@app/hooks/front";
import { IClassTime } from "@app/api/classrooms/helper-schemas-2";
import { getWeekDayWithNumber, getTimeWithZeros } from "@app/utils/dates";

export interface IClassTimesPreviewCoordinates {
	top: number;
	left: number;
}
type ClassTimesPreviewProps = IClassTimesPreviewCoordinates & {
	closeOnScroll?: boolean;
	// onClose: () => void;
	classTimes: IClassTime[];
	displacement: number;
};

export const ClassTimesPreview: React.FC<ClassTimesPreviewProps> = ({
	left,
	top,
	classTimes,
	closeOnScroll,
	displacement = 0,
}) => {
	const { width, height } = useWindowSize();
	const [modalHeight, setModalHeight] = useState<number>(20);
	const ref = useRef<HTMLDivElement>(null);
	const modalWidth = 262;

	useEffect(() => {
		if (!ref.current) return;
		setModalHeight(ref.current.clientHeight);
	}, []);

	if (top && left) {
		top += displacement;
		left += displacement;
	}

	const placements = useMemo((): Record<string, string> | undefined => {
		const res = {};
		if (!top || !left) {
			res["top"] = height / 2 - modalHeight / 2 + displacement;
			res["left"] = width / 2 - modalWidth / 2 + displacement;
			return res;
		}

		if (top + modalHeight >= height) {
			res["bottom"] = `${height - top + displacement}px`;
			res["top"] = undefined;
		} else {
			res["top"] = `${top + displacement}px`;
		}

		if (left + modalWidth + displacement >= width) {
			res["right"] = `${Math.max(0, 10 - displacement)}px`;
		} else {
			res["left"] = `${left + displacement}px`;
		}

		return res;
	}, [displacement, height, left, modalHeight, top, width]);

	if (classTimes.length === 0) return null;

	return (
		<div ref={ref} className={styles.modal_container} style={placements}>
			<div className={styles.modal_content}>
				{classTimes.map((e, i) => {
					return (
						<div key={i} className={styles.preview_row}>
							<span>{getWeekDayWithNumber(e.day)}</span>
							<section
								className={styles.preview_row__date_wrapper}
							>
								<span className={styles.preview_row__hours}>
									{getTimeWithZeros(e.start)}
								</span>
								{" - "}
								<span className={styles.preview_row__hours}>
									{getTimeWithZeros(e.end)}
								</span>
							</section>
						</div>
					);
				})}
			</div>
		</div>
	);
};
