import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const ConferenceSchema = Joi.object().keys({
	_id: Joi.objectId().required(),
	name: Joi.string().allow(""),
	schoolId: Joi.number().required(),
	schoolLabelId: Joi.objectId()
		.allow(null)
		.required(),
	audienceId: Joi.objectId()
		.allow(null)
		.required(),
	hostIds: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IConference {
	_id: ObjectId;
	name?: string;
	schoolId: number;
	schoolLabelId: ObjectId | null;
	audienceId: ObjectId | null;
	hostIds: number[] | null;
	createdAt: Date;
	updatedAt: Date;
}
