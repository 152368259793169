import IntlMessageFormat from "intl-messageformat";
import { store } from "index";

export function getFormattedMessage(id: string, values?: any): string {
	const { messages, locale } = store.getState().intl;
	const message: string = messages[id] || "";
	const messageFormat = new IntlMessageFormat(message, locale);
	return messageFormat.format(values);
}

export function getIntlValue(id: string): any {
	return store.getState().intl.messages[id];
}
