import React, { useState } from "react";
import styles from "./styles/buttons-widget.module.css";
import { useWindowSize } from "@app/hooks/front";
import classNames from "classnames";
import { classNamed } from "@pckgs/classed-components";

interface IButtonsWidget {
	options: { value: string | number; label: string }[];
	defaultValue?: { value: string | number; label: string };
	onChange: (selectedOption: {
		value: string | number;
		label: string;
	}) => void;
}

const ButtonsWidget: React.FC<IButtonsWidget> = props => {
	const [clicked, setClicked] = useState(props.defaultValue);
	const { width } = useWindowSize();
	const buttonsContainerWidth = 150 * props.options.length;

	const buttonClick = i => {
		setClicked(props.options[i]);
		props.onChange(props.options[i]);
	};

	return (
		<div
			className={styles.mainButtonsWidgetDiv}
			style={width > 700 ? { width: buttonsContainerWidth } : {}}
		>
			{props.options.map((option, i) => {
				return (
					<button
						key={option.value}
						onClick={() => buttonClick(i)}
						className={classNames(
							styles.buttons,
							clicked === option && styles.activeButtons
						)}
					>
						{option.label}
					</button>
				);
			})}
		</div>
	);
};

export default ButtonsWidget;

export const AdminButton = classNamed("button")(
	"bg-primary text-white p-2 rounded-md"
);

const Button = classNamed("button")(
	"p-3 rounded-xl font-roboto-geo-caps max-w-full sm:text-lg focus:outline-none"
).extendStyled({
	width: "18em",
});

export const PrimaryButton = Button.extendClassNamed(
	"bg-primary hover:bg-primary-700 focus:bg-primary-900 text-white "
);

export const GreenButton = Button.extendClassNamed(
	"bg-success hover:bg-success-700 focus:bg-success-900 text-white"
);

export const WhiteButton = Button.extendClassNamed("bg-white text-black");
