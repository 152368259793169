import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export interface CourseCommonResource {
	_id: ObjectId;
	courseId: ObjectId;
	author: number;
	createdAt: Date;
	updatedAt: Date;
}

export const BasicDocumentSchema = Joi.object({
	_id: Joi.objectId().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface BasicDocument {
	_id: ObjectId;
	createdAt: Date;
	updatedAt: Date;
}
