/* eslint-disable */
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { readFile } from "@app/utils/file-reader";
import { mergeRecursive } from "@app/utils/common";
import { Schema, ValidationOptions } from "joi";
// import jwt from "jsonwebtoken";

export interface ICredentials {
	userId: number;
	accessToken: string;
	refreshToken: string;
}
interface IValidators {
	requestSchema?: Schema;
	responseSchema?: Schema;
}

function validate(data: any, schema?: Schema, options?: ValidationOptions) {
	if (!schema) {
		return data;
	}
	const validatorResult = schema.validate(data, {
		stripUnknown: true,
		abortEarly: false,
		...options,
	});
	if (validatorResult.error || validatorResult.value === undefined) {
		console.log(data, "data");
		console.error(validatorResult.error);
		console.error(JSON.parse(JSON.stringify(validatorResult.error)));
		throw validatorResult.error;
	}
	return validatorResult.value;
}

type MethodType = "GET" | "POST" | "PUT" | "DELETE";

function getPromise(method: MethodType, url: string, data?: {}, config?: {}) {
	if (method === "GET") {
		return axios.get(url, config);
	}
	if (method === "POST") {
		return axios.post(url, data, config);
	}
	if (method === "DELETE") {
		return axios.delete(url, config);
	}
	return axios.put(url, data, config);
}

function toURLElement(str: any): string {
	if (Array.isArray(str)) {
		return encodeURIComponent(JSON.stringify(str));
	} else if (str instanceof Date) {
		return encodeURIComponent(str.toJSON());
	} else if (str !== null && typeof str === "object") {
		return encodeURIComponent(JSON.stringify(str));
	}
	return encodeURIComponent(str);
}

interface R {
	method: MethodType;
	url: string;
	bodyOrQuery: any;
	config: {
		headers: Record<any, any>;
	} & AxiosRequestConfig;
}

interface IRequestsConfig {
	urlPrefix: string;
	initialConfig: AxiosRequestConfig;
	accessTokenKey: string;
	childTokenKey?: string;
	updateAccessToken: () => Promise<void | any>;
	updateChildAccessToken?: () => Promise<void>;
	logoutUser: () => void;
	requireLoginForActionPromise: () => Promise<void | any>;
	onResponse?: (response: AxiosResponse) => void | any;
	onReject?: (error: any, request: R) => void;
	numOfSeccondsToRenewTokenBeforeExpiration: number;
	JoiOptions?: ValidationOptions;
	preRequestHook?: (arg: R) => R;
}

interface AdditionalRequestProperties {
	avoidAuthentification: boolean;
}

const defaultOnReject = (e: any, request: R) => {
	throw e;
};

export function createRequests(requestConfig: IRequestsConfig) {
	return class Requests {
		public static defaultConfig = mergeRecursive(
			{
				headers: {},
			},
			requestConfig.initialConfig
		);

		public static async send<ReturnData = any, Obj extends {} = {}, Obj2 extends {} = AxiosRequestConfig>(
			method: MethodType,
			baseUrl: string,
			data?: FormData | Obj | undefined,
			customConfig?: null | Obj2,
			validators?: IValidators,
			props?: AdditionalRequestProperties
		): Promise<ReturnData> {
			let bodyOrQuery = Array.isArray(data)
				? [...data]
				: { ...(data || {}) };
			if (data instanceof FormData) {
				data.forEach((val, key) => {
					bodyOrQuery[key] = val;
				});
			}
			if (validators && validators.requestSchema) {
				bodyOrQuery = validate(
					bodyOrQuery,
					validators.requestSchema,
					requestConfig.JoiOptions
				);
			}

			// example: api/unis/:uni_id/ => api/unis/7/
			baseUrl = baseUrl.replace(/:([^/\s]+)/g, (str, match) => {
				if (bodyOrQuery[match] !== undefined) {
					const val = bodyOrQuery[match];
					delete bodyOrQuery[match];
					return val;
				}
				return str;
			});
			baseUrl = (requestConfig.urlPrefix || "") + baseUrl;
			let url = baseUrl;
			if (method === "GET" || method === "DELETE") {
				let queryString = "";
				if (typeof bodyOrQuery === "object" && bodyOrQuery !== null) {
					queryString =
						"?" +
						Object.keys(bodyOrQuery)
							.filter(key => bodyOrQuery[key] !== undefined)
							.map(
								key =>
									key + "=" + toURLElement(bodyOrQuery[key])
							)
							.join("&");
					if (queryString.length === 1) queryString = "";
				}
				url = baseUrl + queryString;
			}
			if (data instanceof FormData) bodyOrQuery = data;

			const { defaultConfig } = Requests;
			const config = mergeRecursive(defaultConfig, customConfig || {});

			const startPromise = Requests.getStartPromise(props);

			const generateRequestPromise = () => {
				let {
					method: methodFinal,
					url: urlFinal,
					bodyOrQuery: bodyOrQueryFinal,
					config: configFinal,
				} = {
					method,
					url,
					bodyOrQuery,
					config,
				};
				if (requestConfig.preRequestHook) {
					({
						method: methodFinal,
						url: urlFinal,
						bodyOrQuery: bodyOrQueryFinal,
						config: configFinal,
					} = requestConfig.preRequestHook({
						method: methodFinal,
						url: urlFinal,
						bodyOrQuery: bodyOrQueryFinal,
						config: configFinal,
					}));
				}
				return getPromise(
					methodFinal,
					urlFinal,
					bodyOrQueryFinal,
					configFinal
				)
					.then(res => {
						if (requestConfig.onResponse) {
							requestConfig.onResponse(res);
						}
						return res.data;
					})
					.then(d =>
						validate(
							d,
							validators && validators.responseSchema,
							requestConfig.JoiOptions
						)
					);
			};

			const mainPromise = startPromise.then(() =>
				generateRequestPromise()
			);
			return mainPromise
				.catch(err => Requests.error(err, generateRequestPromise))
				.catch(err => {
					const fn = requestConfig.onReject || defaultOnReject;
					return fn(err, {
						method,
						url,
						bodyOrQuery,
						config, // TODO: replace with transformed queries
					});
				});
		}

		private static getStartPromise(props?: AdditionalRequestProperties) {
			const blockingPromise = (Requests.sendingResponse || Promise.resolve()).then(
				() => Requests.childAuthPromise
			);
			if (!blockingPromise || (props && props.avoidAuthentification)) {
				return Promise.resolve(null);
			}
			return blockingPromise;
		}

		public static sendNewChildTokenRequest(callback?: () => Promise<any>) {
			if (!requestConfig.updateChildAccessToken) {
				return;
			}
			return requestConfig.updateChildAccessToken().then(() => {
				delete Requests.childAuthPromise;
				return callback?.();
			})
			.catch(e => {
				delete Requests.childAuthPromise;
				throw e;
			});
		}


		public static sendNewAccessTokenRequest(
			callback?: () => any
		) {
			try {
				const promise = Requests.sendingResponse || requestConfig.updateAccessToken();
				Requests.sendingResponse = promise;
				return promise
					.then(() => {
						delete Requests.sendingResponse;
						return callback?.();
					})
					.catch(e => {
						delete Requests.sendingResponse;
						throw e;
					});
			} catch (e) {
				throw e;
			}
		}

		public static getAccessToken(): string | undefined {
			if (typeof Requests.defaultConfig.headers !== "undefined") {
				return Requests.defaultConfig.headers[
					requestConfig.accessTokenKey
				];
			}
			return undefined;
		}
		public static renewConfigByCredentials(credentials: ICredentials) {
			if (typeof credentials.accessToken !== "undefined") {
				Requests.defaultConfig.headers[requestConfig.accessTokenKey] =
					credentials.accessToken;
			}
		}

		public static clearAccessToken() {
			delete Requests.defaultConfig.headers[requestConfig.accessTokenKey];
		}

		public static renewAccessToken(accessToken: string | undefined) {
			Requests.defaultConfig.headers[
				requestConfig.accessTokenKey
			] = accessToken;
		}

		public static clearChildAccessToken() {
			if (typeof requestConfig.childTokenKey !== "undefined") {
				delete Requests.defaultConfig.headers[requestConfig.childTokenKey];
			}
		}

		public static renewChildAccessToken(accessToken: string | undefined) {
			if (typeof requestConfig.childTokenKey === "undefined") {
				throw new Error("child token key not provided");
			}
			if (typeof accessToken !== "undefined") {
				Requests.defaultConfig.headers[
					requestConfig.childTokenKey
				] = accessToken;
			}
		}

		public static async requestChildTokenAndRenew() {
			await requestConfig.updateChildAccessToken?.();
		}

		public static async error(
			err: any,
			callback: () => Promise<any>
		): Promise<any> {
			let data = err.response ? err.response.data : undefined;
			if (data instanceof Blob) {
				data = await readFile(data);
			}
			if (err.response && err.response.status === 401 && data) {
				if (data === "access token expired") {
					return Requests.sendNewAccessTokenRequest(callback);
				}
				if (data === "child access token expired") {
					return Requests.sendNewChildTokenRequest(callback);
				}
				if (data === "access and child tokens expired") {
					return Requests.sendNewAccessTokenRequest(() =>
						Requests.sendNewChildTokenRequest(callback) || callback()
					);
				}
				if (
					data === "invalid refresh token" ||
					data.indexOf("authentication failed") === 0
				) {
					requestConfig.logoutUser();
					Requests.sendingResponse = requestConfig
						.requireLoginForActionPromise()
						.then(() => {
							delete Requests.sendingResponse;
							return callback();
						})
						.catch(e => {
							delete Requests.sendingResponse;
							throw e;
						});
					return Requests.sendingResponse;
				}
			}
			throw err;
		}
		private static sendingResponse?: Promise<any>;

		private static childAuthPromise?: Promise<any>;

		public static addChildTokenPromise(promise: Promise<any>) {
			if (Requests.sendingResponse) {
				promise = Requests.sendingResponse.then(
					() => Requests.childAuthPromise || promise,
				);
			} else {
				promise = Requests.childAuthPromise || promise;
			}
		}
	};
}

export type IRequest = ReturnType<typeof createRequests>;
