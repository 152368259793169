import { ITime, IClassTime } from "@app/api/classrooms/helper-schemas-2";
import { IDatePickerFormat } from "@app/components/teachers/gradebook/date-picker/date-picker";

export const getWeekDayWithNumber = (day: number, short = false) => {
	const days = [
		"კვირა",
		"ორშაბათი",
		"სამშაბათი",
		"ოთხშაბათი",
		"ხუთშაბათი",
		"პარასკევი",
		"შაბათი",
	];
	const dayText = days[day] || "never";
	return !short ? dayText : `${dayText.slice(0, 3)}.`;
};

export const datePickerFormatToDate = (date: IDatePickerFormat) =>
	new Date(`${date.month}/${date.day}/${date.year}`);

export const dateToDatePickerFormat = (date: Date): IDatePickerFormat => ({
	day: date.getDate(),
	month: date.getMonth() + 1,
	year: date.getFullYear(),
});

export const getDayAndMothWitZeros = (date: IDatePickerFormat) =>
	`${withZero(date.day)}.${withZero(date.month)}`;

export const getTimeWithZeros = (tm: ITime) =>
	`${withZero(tm.hour)}:${withZero(tm.minute)}`;

export const iTimeToDate = (tm: ITime) => {
	const date = new Date();
	date.setHours(tm.hour);
	date.setHours(tm.minute);
	date.setSeconds(0);
	date.setMilliseconds(0);
	return date;
};

export const getMonthNameWithNumber = (month: number, short = false) => {
	const months = [
		"იანვარი",
		"თებერვალი",
		"მარტი",
		"აპრილი",
		"მაისი",
		"ივნისი",
		"ივლისი",
		"აგვისტო",
		"სექტემბერი",
		"ოქტომბერი",
		"ნოემბერი",
		"დეკემბერი",
	];
	const monthName = months[month] || "never";
	return !short ? monthName : `${monthName.slice(0, 3)}.`;
};

export const dayAndMonthWitZeros = (date: Date) =>
	`${withZero(date.getDate())}.${withZero(date.getMonth() + 1)}`;

export const withZero = (number: number | string) => `0${number}`.slice(-2);

export const getTimeWithZerosAndColon = (date: Date) =>
	`${withZero(date.getHours())}:${withZero(date.getMinutes())}`;

export const getITimeWithZerosAndColon = (time: ITime, withSpaces = false) =>
	`${withZero(time.hour)}${withSpaces ? " " : ""}:${
		withSpaces ? " " : ""
	}${withZero(time.minute)}`;

export const isWithinMinutesOffset = (time: ITime, minutesOffset: number) => {
	const curDate = new Date();
	const hour = curDate.getHours();
	const minute = curDate.getMinutes();
	return hour * 60 + minute - time.hour * 60 + time.minute <= minutesOffset;
};

export function isAnyClasstimeTakingPlaceNow({
	classTimes,
	minuteOffset,
}: {
	classTimes: IClassTime[];
	minuteOffset?: number;
}) {
	const curDate = new Date();
	return isWithinClassTimes({ date: curDate, classTimes, minuteOffset });
}

export const isWithinClassTimes = ({
	date,
	classTimes,
	minuteOffset = 0,
	classTimesTimezoneOffsetMinutes = -240,
}: {
	date: Date;
	classTimes: IClassTime[];
	minuteOffset?: number;
	classTimesTimezoneOffsetMinutes?: number;
}) => {
	date = setTimeToTimezone({
		date,
		targetTimezoneOffsetMinutes: classTimesTimezoneOffsetMinutes,
	});
	const hour = date.getHours();
	const minute = date.getMinutes();
	const curTotalMinutes = hour * 60 + minute;

	const todaysLessons = classTimes.filter(e => e.day === date.getDay());
	for (let i = 0; i < todaysLessons.length; i++) {
		const { start, end } = todaysLessons[i];
		const sMinutes = start.hour * 60 + start.minute - minuteOffset;
		const eMinutes = end.hour * 60 + end.minute;

		if (curTotalMinutes >= sMinutes && curTotalMinutes <= eMinutes) {
			return true;
		}
	}
	return false;
};

export const getFormattedtDate = (date = new Date()) => {
	return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
};

const setTimeToTimezone = ({
	date,
	targetTimezoneOffsetMinutes,
}: {
	date: Date;
	targetTimezoneOffsetMinutes: number;
}): Date => {
	date = new Date(date);
	const currentTimezoneOffsetMinutes = new Date().getTimezoneOffset();
	const MillisecondsInMinutes = 1000 * 60;
	return new Date(
		date.getTime() +
			(currentTimezoneOffsetMinutes - targetTimezoneOffsetMinutes) *
				MillisecondsInMinutes
	);
};
