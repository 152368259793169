import { IRequest } from "../requests";
import { IAPOSTCreateRequest } from "./validators";

export class TeacherRegistrationController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = (args: IAPOSTCreateRequest): Promise<void> => {
		return this.Request.send(
			"POST",
			"/api/teacher-registration-requests",
			args
		);
	};
}
