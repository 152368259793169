import { addLoader } from "@app/common-javascript";
import { AuthInput, AuthSelect } from "@app/components/auth/input-forms";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { useCitiesAndSchools } from "@app/hooks/cities-and-schools";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StylesConfig } from "react-select";
import genStyles from "../general_components/styles/general_styles.module.css";
import { ChangePassword } from "../general_components/change-password";
import { Mobile } from "../general_components/mobile";
import { useWindowSize } from "@app/hooks/front";
import { UserProfileAvatar } from "../general_components/profile-avatar";

// eslint-disable-next-line max-lines-per-function
export const TeacherLeftSide: React.FC<{}> = props => {
	const isGeo = useLocale() === "ka";
	const user = useClassroomsUser()!;
	const hideSchoolAndCity = user.isHeadmaster()!;
	const { cities, schools } = useCitiesAndSchools(user ? user.country : null);
	const [name, setName] = useState(user.firstname);
	const [surname, setSurname] = useState(user.lastname);
	const [newPassword, setNewPassword] = useState<string>("");
	const [repeatedPassword, setRepeatedPassword] = useState<string>("");
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const { width } = useWindowSize();

	const onNewPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setNewPassword(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onRepeatedNewPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setRepeatedPassword(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onSurnameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSurname(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const [selectedCityId, setSelectedCityId] = useState<null | number>(
		user.city
	);
	const [selectedSchoolId, setSelectedSchoolId] = useState<null | number>(
		user.school
	);

	let schoolsOfSelectedCity: {
		value: number;
		cityId: number;
		label: string;
	}[];

	if (selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		if (schools) {
			schoolsOfSelectedCity = schools.filter(
				s => s.cityId === selectedCityId
			);
		}
	}

	const onCityChange = useCallback(
		(selection: { value: number; label: string }) => {
			setSelectedSchoolId(null);
			setSelectedCityId(selection.value);
			setIsChanged(true);
		},
		[]
	);
	const onSchoolChange = useCallback(
		(selection: { value: number; label: string; cityId: number }) => {
			setSelectedSchoolId(selection.value);
			setIsChanged(true);
		},
		[]
	);

	const onSave = useCallback(() => {
		const UsersController = inject("UsersController");
		const sendRequest = newPassword && newPassword === repeatedPassword;
		if (newPassword && !sendRequest) {
			openConfirmationPopup({
				text: getFormattedMessage("passwordDontMatch"),
			});
			return;
		}
		if (sendRequest && newPassword.length < 5) {
			openConfirmationPopup({
				text: getFormattedMessage("passwordLength", {
					requiredLength: 5,
				}),
			});
			return;
		}
		const removeLoader = addLoader();
		UsersController.saveDetailedInfo({
			firstname: name,
			lastname: surname,
			city: selectedCityId || undefined,
			school: selectedSchoolId || undefined,
			newPassword: sendRequest ? newPassword : undefined,
		})
			.then(() => {
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("refreshInfo"),
				});
				setIsChanged(false);
			})
			.catch(() => {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
				removeLoader();
			});
	}, [
		name,
		surname,
		selectedCityId,
		selectedSchoolId,
		newPassword,
		repeatedPassword,
	]);

	if (selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		schoolsOfSelectedCity =
			(schools && schools.filter(s => s.cityId === selectedCityId)) || [];
	}

	return (
		<section className={genStyles.profile_left__sidebar_container}>
			<section className={genStyles.profile_left__sidebar}>
				<UserProfileAvatar />

				<form className={genStyles.personal_info_form}>
					<h2 className={genStyles.personal_info_form__title}>
						<FormattedMessage id="personalInformation" />
					</h2>
					<section
						className={genStyles.personal_info_form__input_wrapper}
					>
						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.firstname" />
							</label>

							<AuthInput
								defaultValue={name}
								onChange={onNameChange}
								type={"text"}
								className={genStyles.input}
								fullWidthInput={{ width: "100%" }}
								isDisabled={true}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.lastname" />
							</label>
							<AuthInput
								defaultValue={surname}
								onChange={onSurnameChange}
								type={"text"}
								className={genStyles.input}
								fullWidthInput={{ width: "100%" }}
								isDisabled={true}
							/>
						</section>

						{!hideSchoolAndCity && (
							<section
								className={genStyles.personal_info_form__input}
							>
								<label>
									<FormattedMessage id="inputs.city" />
								</label>
								<AuthSelect
									onChange={onCityChange}
									options={cities}
									value={
										(cities &&
											cities.find(
												c => c.value === selectedCityId
											)) ||
										null
									}
									containerClassName={genStyles.input}
									styles={
										width <= 1000
											? selectorMobStyles
											: width > 1500
											? selectorStyles
											: selectorResStyles
									}
									isSearchable={false}
									isDisabled={true}
								/>
							</section>
						)}

						{!hideSchoolAndCity && (
							<section
								className={genStyles.personal_info_form__input}
							>
								<label>
									<FormattedMessage id="inputs.school" />
								</label>
								<AuthSelect
									onChange={onSchoolChange}
									options={schoolsOfSelectedCity}
									value={
										schoolsOfSelectedCity.find(
											s => s.value === selectedSchoolId
										) || null
									}
									containerClassName={genStyles.input}
									styles={
										width <= 1000
											? selectorMobStyles
											: width > 1500
											? selectorStyles
											: selectorResStyles
									}
									isIconVissible={true}
									isSearchable={false}
									isDisabled={true}
								/>
							</section>
						)}
					</section>
				</form>

				{isGeo && <Mobile />}

				<ChangePassword
					onNewPasswordChange={onNewPasswordChange}
					onRepeatedNewPasswordChange={onRepeatedNewPasswordChange}
				/>
				<div className={genStyles.saveButtonContainer}>
					{isChanged ? (
						<button
							onClick={onSave}
							className={genStyles.saveButtonAfterConfirmed}
						>
							<FormattedMessage id="save" />
						</button>
					) : (
						<button className={genStyles.saveButtonBeforeConfirmed}>
							<FormattedMessage id="save" />
						</button>
					)}
				</div>
			</section>
		</section>
	);
};

const selectorStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "0",
		display: "flex",
		alignItems: "center",
		height: "56px",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "20px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},

	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},

	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};

const selectorResStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "0",
		display: "flex",
		alignItems: "center",
		height: "36px",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "16px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},

	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};

const selectorMobStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "0",
		display: "flex",
		alignItems: "center",
		height: "46px",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "16px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};
