import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import { FancyPopup } from "@app/components/widgets/popup";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { CenterContainer } from "@app/components/widgets/tools";
import { useGoToUrl } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { studentLinks } from "@app/routes/student/links";
import { toGeorgianVocativeName } from "@app/utils/geo-names";
import { classNamed } from "@pckgs/classed-components";
import React from "react";
import { sh20v2Key2 } from "./v2popup";

export const sh20v2NewKey3 = "sh20v2p3";

const Sh20V2Popup3 = React.memo<{ onClose: () => void }>(function Sh20V2Popup3({
	onClose,
}) {
	const user = useClassroomsUser()!;
	const name = toGeorgianVocativeName(user.firstname);

	const markAsSeen = async () => {
		await inject("UserActionsController").performUserAction({
			name: sh20v2NewKey3,
			data: true,
		});
	};

	const handleClose = () => {
		markAsSeen();
		onClose();
	};

	const gotoProgressWeekLanding = useGoToUrl(
		studentLinks.weeklies.progress.landing
	);

	const handleSubmit = () => {
		markAsSeen();
		gotoProgressWeekLanding();
	};

	return (
		<FancyPopup
			onClose={handleClose}
			title={"გამარჯობა, " + name + ", "}
			containerStyles={{ width: 900 }}
			containerClassName="bg-light"
		>
			<P>
				გვიხარია, რომ გადაწყვიტე კვირეულებში ჩართვა. გთხოვ, აირჩიო
				საგნები და დაადასტურო, რომ ერთვები კვირეულში.
			</P>

			<CenterContainer className="mt-4">
				<PrimaryButton onClick={handleSubmit}>
					კვირეულში ჩართვა
				</PrimaryButton>
			</CenterContainer>
		</FancyPopup>
	);
});
const P = classNamed("p")("my-2");

export const Sh20V2NewPopupDistributorForReserved = React.memo(
	function Sh20V2NewPopupDistributorForReserved() {
		const { value: isClosedManually, setTrue: closeManually } = useBoolean(
			false
		);
		const userActions = useUserActions();
		const isLoaded = !!userActions.doc;
		const hasReserved = hasReservedSubjects(
			userActions.doc?.actions[sh20v2Key2]?.subjCount
		);
		const hasSeen = !!userActions.doc?.actions[sh20v2NewKey3];
		const enabled =
			isLoaded && !hasSeen && hasReserved && !isClosedManually;

		return (
			<DefaultPopupPrioritizer
				priority={97}
				enabled={enabled}
				preemptive={false}
			>
				<Sh20V2Popup3 onClose={closeManually} />
			</DefaultPopupPrioritizer>
		);
	}
);

const hasReservedSubjects = (num: unknown) => {
	return typeof num === "number" && num < 3;
};
