import { UserType } from "@app/api/helper-schemas";
import { getCurrentWebsite, WebsiteOrigin, fbSubscription } from "@app/globals";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { CloseIcon, LikeIcon } from "@app/icons";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useEffect, useState, useCallback } from "react";
import SvgLoading from "../styles/img/loading";
import { openConfirmationPopup } from "./confirmation-popup";
import Popup, { PopupContentWithClose } from "./popup";
import { DefaultPopupPrioritizer } from "./prioritizer";
import styles from "./styles/buttons-widget.module.css";
import triggerEvent from "@app/utils/events";

export const FacebookLike: React.FC<{}> = React.memo(() => {
	useEffect(() => {
		setTimeout(() => (window as any)?.FB?.XFBML?.parse(), 20);
		// if (!fbSubscription.getMetaData().loaded) {
		// 	return fbSubscription.subscribe(onLoad);
		// }
	}, []);

	return (
		<div
			className="fb-like"
			data-href="https://www.facebook.com/TVskola/"
			data-width=""
			data-layout="button"
			data-action="like"
			data-size="large"
			data-share="false"
		/>
	);
});

const closeIcon = <CloseIcon className={styles.closeIcon} />;

export const FacebookLikePopup: React.FC<{}> = React.memo(() => {
	const isGeo = useLocale() === "ka";
	const isTvSchool = getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const user = useClassroomsUser();
	const myPathName = window.location.pathname.slice(0, 6);
	const [isLoading, setIsLoading] = useState(true);

	const {
		value: isPopupVissible,
		setTrue: setPopupVissible,
		setFalse: hidePopup,
	} = useBoolean(false);

	const isLogged = !!user;

	useEffect(() => {
		if (localStorage.seenFbLikePopup) {
			setIsLoading(false);
			return;
		}
		if (!isLogged) {
			setPopupVissible();
			setIsLoading(false);
			return;
		}
		const UserActionsController = inject("UserActionsController");
		const loadData = async () => {
			const actions = (await UserActionsController.getUserAction())
				.actions;
			setIsLoading(false);
			if (!!actions["facebookPageLike"]) hidePopup();
			else {
				setPopupVissible();
				UserActionsController.performUserAction({
					name: "facebookPageLike",
					data: {
						requestedAt: new Date(),
					},
				});
			}
		};

		loadData().catch(e =>
			openConfirmationPopup({ text: getFormattedMessage("errorAlert") })
		);
	}, []);

	const isStudent = (user && user.isStudent()) || myPathName === "/s/hw/";

	const shouldRender = isGeo && isTvSchool && isStudent && isPopupVissible;

	const onShow = () => {
		localStorage.setItem("seenFbLikePopup", "true");
		triggerEvent(
			{
				category: "pop up",
				action: "viewed facebook like button popup",
				label: "facebook like button popup",
			},
			{}
		);
	};

	const onClickRedirectToFb = useCallback(() => {
		triggerEvent(
			{
				category: "Button",
				action: "redirect to fb",
				label: "click",
			},
			{}
		);
		window.open("https://www.facebook.com/TVskola/", "_blank");
	}, []);

	return (
		<DefaultPopupPrioritizer
			enabled={shouldRender}
			priority={10000}
			onShow={onShow}
		>
			<Popup onClose={hidePopup} className={styles.popup}>
				<PopupContentWithClose
					onClose={hidePopup}
					icon={closeIcon}
					className={styles.PopupContentWithClose}
				>
					<div className={styles.facebookLikePopupMainText}>
						მოიწონეთ ჩვენი FACEBOOK გვერდი
					</div>
					<LikeIcon className={styles.likeIcon} />
					<div className={styles.facebookLikePopupSecondaryText}>
						გილოცავთ სასწავლო წლის დასრულებას! ჩვენ კვლავ
						შემოგთავაზებთ საინტერესო პროექტებს! იმისთვის, რომ არ
						გამოგრჩეს სიახლეები, თვალი ადევნეთ ჩვენს FACEBOOK
						გვერდს.
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{isLoading ? (
							<SvgLoading style={{ width: 40, height: 40 }} />
						) : (
							<FacebookLike />
						)}

						<a
							onClick={onClickRedirectToFb}
							style={{
								color: " #5273e6",
								cursor: "pointer",
								marginTop: 8,
							}}
						>
							FACEBOOK-ზე გადასვლა
						</a>
					</div>
				</PopupContentWithClose>
			</Popup>
		</DefaultPopupPrioritizer>
	);
});
