import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const UserSpecialTestSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	testId: Joi.number()
		.integer()
		.required(),
	testGroup: Joi.string(),
	answers: Joi.array()
		.items(
			Joi.object({
				qId: Joi.number()
					.integer()
					.required(),
				answerId: Joi.number()
					.integer()
					.required(),
				answeredAt: Joi.date().required(),
			})
		)
		.required(),
	isFinished: Joi.boolean().required(),
	results: Joi.any(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IUserSpecialTest {
	_id: ObjectId;
	userId: number;
	testId: number;
	answers: {
		qId: number;
		answerId: number;
		answeredAt: Date;
	}[];
	isFinished: boolean;
	createdAt: Date;
	updatedAt: Date;
	testGroup?: string;
	results?: any;
}

const ProfOrientationMainTestResultSchema = Joi.array().items(
	Joi.object({
		fieldId: Joi.number()
			.integer()
			.required(),
		probability: Joi.number().required(),
	})
);
export type IProfOrientationMainTestResult = {
	fieldId: number;
	probability: number;
}[];

const ProfOrientationSecondaryTestResultSchema = Joi.array().items(
	Joi.object({
		facultyId: Joi.number()
			.integer()
			.required(),
		probability: Joi.number().required(),
	})
);
export type IProfOrientationSecondaryTestResult = {
	facultyId: number;
	probability: number;
}[];
