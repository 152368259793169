import React from "react";
import memoizeOne from "memoize-one";
import { OptionalKeys } from "@app/utils/generics";
import { crateSchedulerComponent } from "@app/utils/scheduler-react";

interface IState {
	currentPopupName: string;
	shouldRender: boolean;
}

type Popup<P = {}> = {
	priority: number;
	uniqueName: string;
	render: React.ComponentType<P>;
	shouldRender: boolean;
	delayInMilliseconds?: number;
	preemptive: boolean;
} & OptionalKeys<
	{
		props: P;
	},
	keyof P extends never ? "props" : never
>;

export const PrioritizerContext = React.createContext<
	<P extends {}>(popup: Popup<P>) => () => void
>(null as any);

export class Prioritizer extends React.Component<{}, IState> {
	popups: Record<string, Popup | undefined> = {}; // { [uniqueName: string]: Popup | undefined }
	removePopupCallbacks: Record<string, () => void | undefined> = {}; // { [uniqueName: string]: () => void | undefined }

	state: IState = {
		currentPopupName: "",
		shouldRender: false,
	};

	pushPopup = <P extends {}>(popup: Popup<P>) => {
		this.popups[popup.uniqueName] = popup;
		this.reprioritize();
		if (this.removePopupCallbacks[popup.uniqueName]) {
			return this.removePopupCallbacks[popup.uniqueName]!;
		}
		this.removePopupCallbacks[popup.uniqueName] = () =>
			this.removePopup(popup.uniqueName);
		return this.removePopupCallbacks[popup.uniqueName];
	};

	removePopup = (uniqueName: string) => {
		delete this.popups[uniqueName];
		this.reprioritize();
	};

	currentPopupRef = { current: "" };

	reprioritize = () => {
		const popupUniqueNames = Object.keys(this.popups);
		const bestChoice: {
			priority: number;
			uniqueName: string;
			delayInMilliseconds?: number;
		} = {
			priority: -Infinity,
			uniqueName: "",
		};
		for (const uniqueName of popupUniqueNames) {
			const popup = this.popups[uniqueName]!;
			if (popup.shouldRender && popup.priority > bestChoice.priority) {
				bestChoice.priority = popup.priority;
				bestChoice.uniqueName = popup.uniqueName;
			}
		}
		if (bestChoice.priority === -Infinity) {
			this.currentPopupRef.current = "";
			setTimeout(() => {
				this.setState(state => ({
					shouldRender:
						state.currentPopupName === bestChoice.uniqueName
							? false
							: state.shouldRender,
				}));
			}, 1);
			return;
		}
		const currentPopup = this.popups[this.currentPopupRef.current];
		if (
			currentPopup &&
			this.state.shouldRender &&
			!currentPopup.preemptive
		) {
			return;
		}
		this.currentPopupRef.current = bestChoice.uniqueName;
		setTimeout(() => {
			this.setState(state => {
				if (this.currentPopupRef.current !== bestChoice.uniqueName) {
					return state;
				}
				return {
					currentPopupName: this.currentPopupRef.current,
					shouldRender:
						bestChoice.delayInMilliseconds === undefined ||
						bestChoice.delayInMilliseconds <= 1 ||
						state.currentPopupName === this.currentPopupRef.current,
				};
			});
		}, 1);
		if (
			bestChoice.delayInMilliseconds &&
			bestChoice.delayInMilliseconds > 1
		) {
			setTimeout(() => {
				this.setState(state => ({
					shouldRender:
						state.currentPopupName === bestChoice.uniqueName
							? true
							: state.shouldRender,
				}));
			}, bestChoice.delayInMilliseconds);
		}
	};

	getRenderedPopup = memoizeOne(
		(popup: Popup | undefined, shouldRender: boolean) => {
			if (!popup || !popup.shouldRender || !shouldRender) {
				return null;
			}
			return <popup.render key={popup.uniqueName} {...popup.props} />;
		}
	);

	render() {
		const popup = this.popups[this.currentPopupRef.current];
		return (
			<PrioritizerContext.Provider value={this.pushPopup}>
				{this.getRenderedPopup(popup, this.state.shouldRender)}
				{this.props.children}
			</PrioritizerContext.Provider>
		);
	}
}

export const DefaultPopupPrioritizer = crateSchedulerComponent({
	defaultPreemptive: false,
	treatHigherNumbersWithHigherPriority: true,
	defaultPriority: 0,
	defaultDelayInMilliseconds: 600,
});

export const PopupsNullifier: React.FC<{
	priority?: number;
	preemptive?: boolean;
}> = ({ preemptive, priority }) => {
	return (
		<DefaultPopupPrioritizer
			enabled={true}
			priority={priority ?? 40000}
			preemptive={preemptive}
			delayInMilliseconds={0}
		/>
	);
};
