import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import {
	ICardTemplate,
	CardTemplateSchema,
} from "@tests-core/schemas/cards/templates";

export const APOSTCreateCardTemplateSchema = CardTemplateSchema.keys(
	insertStripKeys
);

export type IAPOSTCreateCardTemplate = ToInsertKeys<ICardTemplate>;

export const RPOSTCreateCardTemplateSchema = CardTemplateSchema;
export type IRPOSTCreateCardTemplate = ICardTemplate;

///

export const APUTCardTemplateSchema = CardTemplateSchema.keys(updateStripKeys);
export type IAPUTCardTemplate = ToUpdateKeys<ICardTemplate>;

///

export const AGETCardTemplateSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETCardTemplate {
	_id: ObjectId;
}

export const RGETCardTemplateSchema = CardTemplateSchema;
export type IRGETCardTemplate = ICardTemplate;

///

export const ADELETECardTemplateSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETECardTemplate {
	_id: ObjectId;
}

///

export const RGETAllCardTemplatesSchema = Joi.array()
	.items(CardTemplateSchema)
	.required();
export type IRGETAllCardTemplates = ICardTemplate[];
