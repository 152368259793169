import { IClassTime } from "@app/api/classrooms/helper-schemas-2";
import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { Classroom } from "@app/models/classroom";
import { VideoLesson, VideoLessonStatus } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById, useModelDocsByIds } from "m-model-react";
import { useLayoutEffect, useMemo, useState } from "react";
import { useFetch, useResourceLoading } from "./fetch";
import { useDynamicRef } from "./general";
import { useSchoolAndLabelFiltererFn } from "@app/hooks/school-structure";

export function useVideoLesson(
	resourceId: ObjectId | null,
	resourceType: VideoLessonResourceTypes
) {
	const resource = useModelDocById(inject("VideoLessonModel"), resourceId);

	return useResourceLoading(
		resource,
		() =>
			resourceId
				? inject("VideoLessonsController").get({
						resourceId,
						resourceType,
				  })
				: undefined,
		[resourceId]
	);
}

export function useVideoLessons(
	resourceIds: ObjectId[] | null,
	resourceType: VideoLessonResourceTypes
) {
	const resources = useModelDocsByIds(
		inject("VideoLessonModel"),
		resourceIds
	);

	return useResourceLoading(
		resources,
		() =>
			resourceIds
				? inject("VideoLessonsController").getMany({
						resourceIds,
						resourceType,
				  })
				: undefined,
		[resourceIds]
	);
}

const getVideoLessonState = (
	videoLesson: VideoLesson | null | undefined,
	classTimes: IClassTime[] | null | undefined
) => {
	return !videoLesson || !classTimes
		? null
		: videoLesson.getClassroomStatus(classTimes);
};

const getGroupVideoLessonState = (
	videoLesson: VideoLesson | null | undefined
) => {
	return !videoLesson ? null : videoLesson.getGroupStatus();
};

export const useVideoLessonStatus = (
	videoLesson: VideoLesson | null | undefined,
	classTimes: IClassTime[] | null | undefined
) => {
	const [counter, setCounter] = useState(0);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const status = useMemo(() => getVideoLessonState(videoLesson, classTimes), [
		videoLesson,
		classTimes,
		counter,
	]);

	const statusRef = useDynamicRef(status);
	const videoLessonRef = useDynamicRef(videoLesson);
	const classTimesRef = useDynamicRef(classTimes);

	useLayoutEffect(() => {
		const handleStateUpdate = () => {
			const videoLesson = videoLessonRef.current;
			const classTimes = classTimesRef.current;
			const newStatus = getVideoLessonState(videoLesson, classTimes);
			if (statusRef.current !== newStatus) {
				setCounter(x => x + 1);
			}
		};
		const planned = setInterval(handleStateUpdate, 10 * 1000);
		return () => clearInterval(planned);
	}, [videoLessonRef, classTimesRef, statusRef]);

	return status;
};

export const useGroupVideoLessonStatus = (
	videoLesson: VideoLesson | null | undefined
) => {
	const [counter, setCounter] = useState(0);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const status = useMemo(() => getGroupVideoLessonState(videoLesson), [
		videoLesson,
		counter,
	]);

	const statusRef = useDynamicRef(VideoLessonStatus.NOT_AVAILABLE);
	const videoLessonRef = useDynamicRef(videoLesson);

	useLayoutEffect(() => {
		const handleStateUpdate = () => {
			const videoLesson = videoLessonRef.current;
			const newStatus = getGroupVideoLessonState(videoLesson);
			if (statusRef.current !== newStatus) {
				setCounter(x => x + 1);
			}
		};
		const planned = setInterval(handleStateUpdate, 10 * 1000);
		return () => clearInterval(planned);
	}, [videoLessonRef, statusRef]);

	return status;
};

export const useVideoItemsByClassrooms = (classrooms: Classroom[] | null) => {
	const visibleClassroomIds = useMemo(
		() => (!classrooms ? classrooms : classrooms.map(e => e._id)),
		[classrooms]
	);
	const videoLessons = useVideoLessons(
		visibleClassroomIds,
		VideoLessonResourceTypes.CLASSROOM
	);

	const items = useMemo(() => {
		if (!classrooms || !videoLessons.doc) return null;
		return classrooms.map(classroom => ({
			classroom,
			videoLesson: videoLessons.doc.find(
				e => e.resourceId === classroom._id
			),
		}));
	}, [videoLessons.doc, classrooms]);
	return { items, videoLessons };
};

export const useConferences = ({
	schoolId,
	schoolLabelId,
	includeLabelless,
}: {
	schoolId: number | null;
	schoolLabelId: ObjectId | null;
	includeLabelless?: boolean;
}) => {
	const conferences = useFetch(
		() => {
			return inject("ConferencesController").getBySchool({
				schoolId: schoolId!,
				schoolLabelId,
				includeLabelless,
			});
		},
		!!schoolId,
		[schoolId, schoolLabelId, includeLabelless]
	);
	return conferences.doc ?? null;
};

export const useFilteredConferences = ({
	schoolId,
	schoolLabelId,
}: {
	schoolId: number | null;
	schoolLabelId: ObjectId | null;
}) => {
	const classroomsFiltererFn = useSchoolAndLabelFiltererFn({
		schoolId,
		schoolLabelId,
	});

	const allConferences = useConferences({
		schoolId,
		schoolLabelId: null,
	});
	return useMemo(
		() =>
			!allConferences
				? null
				: allConferences.filter(classroomsFiltererFn),
		[allConferences, classroomsFiltererFn]
	);
};
