import { ISchool, SchoolSchema } from "@app/api/schools/helper-schemas";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "id";
type IdKey = typeof keyOfId;
type DOC = ISchool;
export type IStateSchools = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(SchoolSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("school");
const storageSettings = getDefaultStorageSettings("schools");
const metaInformationName = "schoolsMetaInformation";

const MAX_LOAD_TIME_DIFF = 5 * 60 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().schools) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", SchoolSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class School extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, School.meta);

export const schoolsReducer = getDefaultReducer(storageSettings, () => School);
