import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { ClassTimeSchema, IClassTime } from "../classrooms/helper-schemas-2";
import { ItemType } from "../folders/helper-schemas";

export enum CourseType {
	ordinary = "ORD",
	cognitive = "COG",
}

export const CourseTypes = [CourseType.cognitive, CourseType.ordinary];

export const CustomFolderIdsSchema = Joi.object({
	lessons: Joi.objectId().required(),
	tests: Joi.objectId().required(),
	questions: Joi.objectId().required(),
});
export interface ICustomFolderIds {
	lessons: ObjectId;
	tests: ObjectId;
	questions: ObjectId;
}

const CourseAvailabilitySchema = Joi.object({
	grades: Joi.array()
		.items(Joi.number().integer())
		.allow(null),
	subjects: Joi.array()
		.items(Joi.objectId())
		.allow(null),
	languages: Joi.array()
		.items(Joi.string())
		.allow(null),
	countries: Joi.array()
		.items(Joi.string())
		.allow(null),
	websites: Joi.array()
		.items(Joi.string())
		.allow(null),
	priority: Joi.number().allow(null),
	optOutForStudents: Joi.boolean(),
});
export interface ICourseAvailability {
	grades?: number[] | null;
	subjects?: ObjectId[] | null;
	languages?: string[] | null;
	countries?: string[] | null;
	websites?: string[] | null;
	priority?: number | null;
	optOutForStudents?: boolean;
}

export const CourseInfoSchema = Joi.object({
	courseId: Joi.objectId().required(),
	name: Joi.string().required(),
	rootFolder: Joi.objectId().required(),
	rootTopic: Joi.objectId().required(),
	rootTaskType: Joi.objectId().required(),
	availableIn: Joi.array()
		.items(CourseAvailabilitySchema)
		.allow(null)
		.required(),
	type: Joi.string()
		.valid(...CourseTypes)
		.optional(),
});
export interface ICourseInfo {
	courseId: ObjectId;
	name: string;
	rootFolder: ObjectId;
	rootTopic: ObjectId;
	rootTaskType: ObjectId;
	availableIn: ICourseAvailability[] | null;
	type?: CourseType;
}

export const ExtendedCourseInfoSchema = CourseInfoSchema.keys({
	availableIn: Joi.any().strip(),
	priority: Joi.any().strip(),
	customFolderIds: CustomFolderIdsSchema.required(),
	relatedCourses: Joi.array().items(Joi.objectId().required()),
	originalCourseId: Joi.objectId(),
});

export type IExtendedCourseInfo = Omit<
	ICourseInfo,
	"priority" | "availableIn"
> & {
	customFolderIds: ICustomFolderIds;
	relatedCourses?: ObjectId[];
	originalCourseId?: ObjectId;
};

///

export const CourseSettingsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	defaultClassTimes: Joi.array()
		.items(ClassTimeSchema)
		.allow(null),
	hideTaskTypes: Joi.boolean(),
	hideClassroom: Joi.boolean(),
	hideLessons: Joi.boolean(),
	itemDisplayNames: Joi.object()
		.pattern(
			/\d+/,
			Joi.object({
				folderUpLevel: Joi.number().required(),
				includeItemName: Joi.boolean().required(),
				separator: Joi.string()
					.allow("")
					.required(),
			})
		)
		.allow(null),
});

export interface ItemDisplayName {
	folderUpLevel: number;
	includeItemName: boolean;
	separator: string;
}

export interface ICourseSettings {
	courseId: ObjectId;
	defaultClassTimes?: IClassTime[] | null;
	hideTaskTypes?: boolean;
	hideClassroom?: boolean;
	hideLessons?: boolean;
	itemDisplayNames?:
		| {
				[itemType in number]?: ItemDisplayName;
		  }
		| null;
}
