import {
	TargetConceptSchema,
	ITargetConcept,
	ComplexAssignmentTopicSchema,
	IComplexAssignmentTopic,
	ComplexAssignmentResultsSchema,
	IComplexAssignmentResults,
	ComplexAssignmentSchema,
	IComplexAssignment,
	RComplexAssignmentSchema,
	IRComplexAssignment,
	IResult,
	ResultSchema,
} from "./helper-schemas";
import {
	ToInsertKeys,
	insertStripKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "@app/api/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const APOSTTargetConceptSchema = TargetConceptSchema.keys(
	insertStripKeys
);
export type IAPOSTTargetConcept = ToInsertKeys<ITargetConcept>;

export const RPOSTTargetConceptSchema = TargetConceptSchema;
export type IRPOSTTargetConcept = ITargetConcept;

///

export const APOSTComplexAssignmentTopicSchema = ComplexAssignmentTopicSchema.keys(
	insertStripKeys
);
export type IAPOSTComplexAssignmentTopic = ToInsertKeys<
	IComplexAssignmentTopic
>;

export const RPOSTComplexAssignmentTopicSchema = ComplexAssignmentTopicSchema;
export type IRPOSTComplexAssignmentTopic = IComplexAssignmentTopic;

///

export const APOSTComplexAssignmentResultsSchema = ComplexAssignmentResultsSchema.keys(
	insertStripKeys
);
export type IAPOSTComplexAssignmentResults = ToInsertKeys<
	IComplexAssignmentResults
>;

export const RPOSTComplexAssignmentResultsSchema = ComplexAssignmentResultsSchema;
export type IRPOSTComplexAssignmentResults = IComplexAssignmentResults;

///

export const APOSTComplexAssignmentSchema = ComplexAssignmentSchema.keys(
	insertStripKeys
);
export type IAPOSTComplexAssignment = ToInsertKeys<IComplexAssignment>;

export const RPOSTComplexAssignmentSchema = ComplexAssignmentSchema;
export type IRPOSTComplexAssignment = IComplexAssignment;

///

export const AGETTargetConceptsSchema = Joi.object({
	originalCourseId: Joi.objectId().optional(),
});
export interface IAGETTargetConcepts {
	originalCourseId?: ObjectId;
}

export const RGETTargetConceptsSchema = Joi.array().items(TargetConceptSchema);
export type IRGETTargetConcepts = ITargetConcept[];

///

export const AGETComplexAssignmentTopicsSchema = Joi.object({
	originalCourseId: Joi.objectId().optional(),
	targetConcepts: Joi.array()
		.items(Joi.objectId())
		.optional(),
});
export interface IAGETComplexAssignmentTopics {
	originalCourseId?: ObjectId;
	targetConcepts?: ObjectId[];
}

export const RGETComplexAssignmentTopicsSchema = Joi.array().items(
	ComplexAssignmentTopicSchema
);
export type IRGETComplexAssignmentTopics = IComplexAssignmentTopic[];

///

export const AGETComplexAssignmentsSchema = Joi.object({
	originalCourseId: Joi.objectId().optional(),
	author: Joi.number()
		.integer()
		.min(1)
		.optional(),
});
export interface IAGETComplexAssignments {
	originalCourseId?: ObjectId;
	author?: number;
}

export const RGETComplexAssignmentsSchema = Joi.array().items(
	RComplexAssignmentSchema
);
export type IRGETComplexAssignments = IRComplexAssignment[];

///

export const AGETComplexAssignmentByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETComplexAssignmentById {
	_id: ObjectId;
}

export const RGETComplexAssignmentByIdSchema = ComplexAssignmentSchema;
export type IRGETComplexAssignmentById = IComplexAssignment;

////

export const AGETTargetConceptByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETTargetConceptById {
	_id: ObjectId;
}

export const RGETTargetConceptByIdSchema = TargetConceptSchema;
export type IRGETTargetConceptById = ITargetConcept;

///

export const ADELETETargetConceptByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETETargetConceptById {
	_id: ObjectId;
}

export type IRDELETETargetConceptById = void;

///

export const APUTTargetConceptSchema = TargetConceptSchema.keys(
	updateStripKeys
);
export type IAPUTTargetConcept = ToUpdateKeys<ITargetConcept>;

export const RPUTTargetConceptSchema = TargetConceptSchema;
export type IRPUTTargetConcept = ITargetConcept;

///

export const APUTComplexAssignmentTopicSchema = ComplexAssignmentTopicSchema.keys(
	updateStripKeys
);
export type IAPUTComplexAssignmentTopic = ToUpdateKeys<IComplexAssignmentTopic>;

export const RPUTComplexAssignmentTopicSchema = ComplexAssignmentTopicSchema;
export type IRPUTComplexAssignmentTopic = IComplexAssignmentTopic;

///

export const AGETComplexAssignmentTopicByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETComplexAssignmentTopicById {
	_id: ObjectId;
}

export const RGETComplexAssignmentTopicByIdSchema = ComplexAssignmentTopicSchema;
export type IRGETComplexAssignmentTopicById = IComplexAssignmentTopic;

///

export const ADELETEComplexAssignmentTopicByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETEComplexAssignmentTopicById {
	_id: ObjectId;
}

export type IRDELETEComplexAssignmentTopicById = void;

///

export const RGETResultGroupsSchema = Joi.array().items(
	ComplexAssignmentResultsSchema
);
export type IRGETResultGroups = IComplexAssignmentResults[];

///

export const AGETResultGroupByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETResultGroupById {
	_id: ObjectId;
}

export const RGETResultGroupByIdSchema = ComplexAssignmentResultsSchema;
export type IRGETResultGroupById = IComplexAssignmentResults;

///

export const APUTResultGroupByIdSchema = ComplexAssignmentResultsSchema.keys(
	updateStripKeys
);
export type IAPUTResultGroupById = ToUpdateKeys<IComplexAssignmentResults>;

export const RPUTResultGroupByIdSchema = ComplexAssignmentResultsSchema;
export type IRPUTResultGroupById = IComplexAssignmentResults;

///

export const ADELETEResultGroupByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETEResultGroupById {
	_id: ObjectId;
}

export type IRDELETEResultGroupById = void;

///

export const AGETComplexAssignmentResultsSchema = Joi.object({
	originalCourseIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	resultIds: Joi.array()
		.items(Joi.objectId())
		.optional(),
});
export interface IAGETComplexAssignmentResults {
	originalCourseIds: ObjectId[];
	resultIds?: ObjectId[];
}

export const RGETComplexAssignmentResultsSchema = Joi.array().items(
	ResultSchema
);
export type IRGETComplexAssignmentResults = IResult[];
