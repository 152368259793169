import { useSetIsSidebarOpen } from "@app/components/general-context";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { SidebarBorder, SidebarBurgerIcon, SidebarUpIcon } from "@app/icons";
import classnames from "classnames";
import { WrapInCustHookChangeError } from "custo";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { matchPath } from "react-router";
import {
	generatePath,
	useHistory,
	useLocation,
	useRouteMatch,
} from "react-router-dom";
import { getFormattedMessage } from "../../../utils/locale";
import { ISidebarItem, ISideBarProps } from "./helpers/interfaces";
import styles from "./styles/index.module.scss";
import { SidebarTree } from "./tree";

const useSidebarSessionAndLocalStorage = (
	width: number
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
	const storageKey = "tvschoolsidebarstate";
	const sessionData = sessionStorage.getItem(storageKey);
	const localData = localStorage.getItem(storageKey);

	let initialBool = false; // expanded (true), collapsed (false)
	if (width > 600) {
		if (sessionData) {
			initialBool = sessionData === "true";
		} else if (localData) {
			initialBool = localData === "true";
		} else initialBool = true;
	}

	const [value, setValue] = React.useState(initialBool);

	React.useEffect(() => {
		sessionStorage.setItem(storageKey, String(value));
		localStorage.setItem(storageKey, String(value));
	}, [value]);

	return [value, setValue];
};

const SidebarRaw: React.FC<ISideBarProps> = function SideBar({
	children,
	whazz,
}) {
	const items = SidebarTree.items.use(whazz);
	const match = useRouteMatch<any>();
	const history = useHistory();
	const { width } = useWindowSize();
	const location = useLocation();
	const [isExpanded, setIsExpanded] = useSidebarSessionAndLocalStorage(width);
	useSetIsSidebarOpen(isExpanded);
	const switchExtendness = useCallback(() => setIsExpanded(cur => !cur), [
		setIsExpanded,
	]);

	const goto = (item: ISidebarItem) => {
		history.push(generatePath(item.href, match.params));
	};

	const isSelected = (item: ISidebarItem): boolean => {
		return !!matchPath(location.pathname, { path: item.href, exact: true });
	};

	if (!items || items.length === 0) return null;

	if (width > 800) {
		return (
			<SidebarDesktop
				switchExtendness={switchExtendness}
				isExpanded={isExpanded}
				goto={goto}
				isSelected={isSelected}
				items={items}
				children={children}
			/>
		);
	}
	return (
		<SidebarMobile
			switchExtendness={switchExtendness}
			isExpanded={isExpanded}
			goto={goto}
			isSelected={isSelected}
			items={items}
			children={children}
		/>
	);
};

export const SidebarDesktop = React.memo<{
	isExpanded: boolean;
	switchExtendness: () => void;
	items: ISidebarItem[];
	isSelected: (item: ISidebarItem) => boolean;
	children: React.ReactNode;
	goto: (item: ISidebarItem) => void;
}>(function SidebarDesktop({
	isExpanded,
	switchExtendness,
	items,
	isSelected,
	children,
	goto,
}) {
	return (
		<div
			className={classnames(
				styles["sidebar-placeholder"],
				!isExpanded && styles["collapsed"],
				isExpanded && styles["expanded"]
			)}
		>
			{fixStyles()}
			<Extend isExpanded={isExpanded} switchState={switchExtendness} />
			<div
				className={classnames(
					styles.miniSidebar,
					!isExpanded && styles.open_expanded_sidebar
				)}
			>
				{items.map((item, index) => (
					<CollapsedItem
						item={item}
						key={index}
						goto={goto}
						isSelected={isSelected(item)}
					/>
				))}
			</div>

			<div
				className={classnames(
					styles["teacher-sidebar-container"],
					!isExpanded && styles["collapsed"],
					isExpanded && styles["expanded"]
				)}
				id={styles["sidebar"]}
			>
				{items.map((item, index) => (
					<div
						key={index}
						onClick={() => goto(item)}
						className={classnames(
							styles["sidebar-item"],
							isSelected(item) && styles["currentPath"]
						)}
					>
						{getFormattedMessage(item.name)}
					</div>
				))}
				{children}
			</div>
		</div>
	);
});

export const SidebarMobile = React.memo<{
	isExpanded: boolean;
	switchExtendness: () => void;
	items: ISidebarItem[];
	isSelected: (item: ISidebarItem) => boolean;
	children: React.ReactNode;
	goto: (item: ISidebarItem) => void;
}>(function SidebarMobile({
	isExpanded,
	switchExtendness,
	items,
	isSelected,
	children,
	goto,
}) {
	return (
		<>
			<section className={styles.responsive_sidebar_placeholder} />
			<section
				className={classnames(
					styles.responsive_sidebar,
					isExpanded && styles.toggle
				)}
			>
				{fixStyles()}
				<SidebarBorder className={styles.responsive_sidebar__border} />
				<SidebarBurgerIcon
					className={styles.responsive_sidebar__burger}
					onClick={switchExtendness}
				/>

				<section
					className={classnames(
						styles.icons_bar,
						isExpanded && styles.hide_icons_bar
					)}
				>
					{items.map((item, index) => {
						const Icon = item.icon;
						if (!Icon) return null;
						return item.icon ? (
							<section
								key={index}
								className={styles.icons_bar__item}
								onClick={() => goto(item)}
							>
								<Icon
									className={classnames(
										styles.icons_bar__item_svg,
										isSelected(item) &&
											styles.current_miniSidebar__icon
									)}
								/>
							</section>
						) : null;
					})}
				</section>

				<section
					className={classnames(
						styles.menu_bar,
						isExpanded && styles.show_menu_bar
					)}
				>
					{items.map((item, index) => (
						<div
							key={index}
							onClick={() => goto(item)}
							className={classnames(
								styles.menu_bar__item,
								isSelected(item) && styles.currentPath
							)}
						>
							{getFormattedMessage(item.name)}
						</div>
					))}
					{children}
				</section>
			</section>
		</>
	);
});

const fixStyles = () => {
	return (
		<Helmet>
			<style type="text/css">
				{`
	.user-page-without-header { display: flex; flex-direction: row; }
	.user-page-without-header>*:last-child { flex: 1; }
	`}
			</style>
		</Helmet>
	);
};

export const Extend = React.memo<{
	isExpanded: boolean;
	switchState: () => void;
}>(function Extend({ isExpanded, switchState }) {
	return (
		<div
			className={classnames(
				styles["collapserExpander"],
				!isExpanded && styles["collapsed"],
				isExpanded && styles["expanded"]
			)}
			onClick={switchState}
		>
			<span>
				{isExpanded ? "ჩაკეცვა" : "გახსნა"}&nbsp;
				<SidebarUpIcon className={styles["arrow"]} />
			</span>
		</div>
	);
});

export const CollapsedItem = React.memo<{
	item: ISidebarItem;
	isSelected: boolean;
	goto: (item: ISidebarItem) => void;
}>(function CollapsedItem({ item, isSelected, goto }) {
	const {
		value: isTitleVisible,
		setTrue: showTitle,
		setFalse: hideTitle,
	} = useBoolean();

	const Icon = item.icon;
	if (!Icon) return null;

	return (
		<div
			className={styles.miniSidebar__icon_container}
			onClick={() => goto(item)}
			onMouseEnter={showTitle}
			onMouseLeave={hideTitle}
		>
			{
				<Icon
					className={classnames(
						styles.miniSidebar__icon,
						isSelected && styles.current_miniSidebar__icon
					)}
				/>
			}

			<div
				className={classnames(
					styles.mSHover,
					isTitleVisible && styles.visible
				)}
			>
				{getFormattedMessage(item.name)}
			</div>
		</div>
	);
});

export const Sidebar = React.memo(WrapInCustHookChangeError(SidebarRaw));
