/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from "react";
import { UserType } from "@app/api/helper-schemas";
import { MainAdminHeader } from "@app/components/admin/header";
import { GuestHeader } from "@app/components/tv-school-landing/header";
import { SubWebsiteOrigin } from "@app/globals";
import { useClassroomsUser } from "@app/hooks/users";
import { TeacherHeader } from "@app/components/teachers/header/teacher-header";
import {
	useGeneralContext,
	useSetIsHeaderHidden,
} from "@app/components/general-context";
import { StudentHeader } from "@app/components/users/header";
import { HeadmasterHeader } from "@app/components/headmaster/header";
import { ParentHeader } from "@app/components/parents/parent-header";

import { CometChat } from "@cometchat-pro/chat";

import { ChatmeIcon } from "@app/icons";
import styles from "./common-horizon.module.scss";
import classnames from "classnames";
import { CometChatUI } from "cometchat-pro-react-ui-kit-master/CometChatWorkspace/src/components";
import { inject } from "@app/modules";
import { useSubWebsite } from "@app/hooks/bc";
import { DynamicContext } from "react-flexible-contexts";
import {
	HeaderProviders,
	HeaderValueType,
	useHeaderColorsByTheme,
} from "@app/components/layouts/header/tree";
import { useLocale } from "@app/hooks/intl";
import Translator from "cometchat-pro-react-ui-kit-master/CometChatWorkspace/src/resources/localization/translator";
import { useUserCurrentOfficialSchools } from "@app/hooks/schools";
import { haveCommonValue } from "@app/utils/array";
import { CreateCusto } from "custo";
import { NewDesignBackground } from "@app/components/backgrounds";
import { HeaderTheme } from "@app/components/layouts/header/interfaces";
import { EduFairLine } from "@app/components/users/header/edu-fair";

const appID = process.env.REACT_APP_COMETCHAT_APPID || "";
const region = process.env.REACT_APP_COMETCHAT_REGION || "";

// eslint-disable-next-line
// const chatAvailableSchoolIds: number[] = [598, 4437, 596, 600, 4428, 4431, 620, 4432, 614, 2664, 536, 670, 4434];
const chatAvailableSchoolIds: number[] = [4437, 4432, 598];

console.log("::-:appID", appID);
console.log("::-:region", region);
const appSetting = new CometChat.AppSettingsBuilder()
	.subscribePresenceForAllUsers()
	.setRegion(region)
	.build();
const initializeCometChat = () =>
	CometChat.init(appID, appSetting).then(
		() => {
			console.log("Initialization completed successfully");
			// You can now call login function.
		},
		error => {
			console.log("Initialization failed with error:", error);
			// Check the reason for error and take appropriate action.
		}
	);

export const HeaderDistributorWrapper = React.memo(() => {
	const thisUser = useClassroomsUser();
	const isAdmin = thisUser?.isMainAdmin();
	const idOfThisUser = thisUser?.id; // This is tvSchool user Id, not cometChat user id

	const [cometChatUser, setCometChatUser] = useState<CometChat.User>();
	const isCometchatLoggedIn = useRef(false);

	const firstClickDoneRef = useRef(false);
	const loginCometChatTimerRef = useRef(null as null | NodeJS.Timeout); // NodeJS.Timeout
	const loginCometChat = () => {
		if (!firstClickDoneRef.current) {
			// return; // this approach is not curently used here
		}
		if (loginCometChatTimerRef.current) {
			clearTimeout(loginCometChatTimerRef.current);
		}

		const loginMaker = () => {
			console.log("c-login--------------");
			const chatController = inject("ChatController");
			initializeCometChat().then(() =>
				chatController.getChatToken().then(
					({ token }) => {
						// CometChat.login(uid, authKey).then( // insecure
						// CometChat.login(
						// 	// "superhero1",
						// 	"cometboy7",
						// 	"0db8617322ada201114d7b46f06ea044e1fa9634" // it's safe, not prod
						// ).then(

						CometChat.login(token).then(
							(user: CometChat.User) => {
								isCometchatLoggedIn.current = true;
								setCometChatUser(user);

								console.log("Login Successful>>>>:", { user });
							},
							error => {
								console.log("errr11111");
								console.log("Login failed with exception:", {
									error,
								});
							}
						);
					},
					error => {
						console.log("errr222");
						console.log("error", error);
					}
				)
			);
		};

		loginCometChatTimerRef.current = setTimeout(() => {
			loginMaker();
		}, 250);
	};

	// console.log("cometChatUser:;;;;;;;;;;;;;", cometChatUser, idOfThisUser);

	const subWebsite = useSubWebsite();
	const userSchools = useUserCurrentOfficialSchools();
	const showChat =
		subWebsite === SubWebsiteOrigin.britishCenter ||
		(!!userSchools && haveCommonValue(userSchools, chatAvailableSchoolIds));
	// const showChat = true;

	const currLocale = useLocale();
	Translator.setLanguage(currLocale);

	useEffect(() => {
		if (!idOfThisUser) {
			isCometchatLoggedIn.current = false;
			setCometChatUser(undefined);
		} else if (
			showChat &&
			!isAdmin &&
			idOfThisUser &&
			!cometChatUser &&
			!isCometchatLoggedIn.current
		) {
			loginCometChat();
		}
	}, [idOfThisUser, isAdmin, showChat]); // cometChatUser must be excluded

	const [cometChatVisib, setCometChatVisib] = useState(false); // for css display: none
	const [cometChatRender, setCometChatRender] = useState(false); // for fully unrender

	const unrenderCometChatTimerRef = useRef(null as null | NodeJS.Timeout); // NodeJS.Timeout
	const unrenderCometChat = () => {
		if (unrenderCometChatTimerRef.current) {
			clearTimeout(unrenderCometChatTimerRef.current);
		}

		unrenderCometChatTimerRef.current = setTimeout(() => {
			setCometChatRender(false);
		}, 5000);
	};

	const isHeaderHidden = useGeneralContext("isHeaderHidden");
	if (isHeaderHidden) return null;

	// console.log("cometChatUser", cometChatUser);

	return (
		<ChatContext.Provider
			onClick={() => {
				if (!firstClickDoneRef.current) {
					firstClickDoneRef.current = true;
				}

				if (!cometChatVisib) {
					if (unrenderCometChatTimerRef.current) {
						clearTimeout(unrenderCometChatTimerRef.current);
					}
					setCometChatVisib(true);
					setCometChatRender(true);
				} else if (cometChatVisib) {
					unrenderCometChat();
					setCometChatVisib(false);
				}
			}}
		>
			<HeaderProviders.PartialMergingProvider
				value={{
					components: {
						Chat:
							idOfThisUser && cometChatUser ? ChatWrapper : Empty,
					},
				}}
			>
				<HeaderDistributor />
				{cometChatRender && idOfThisUser && cometChatUser && (
					<div
						className={classnames(
							styles.cometChatParent,
							!cometChatVisib ? styles.hideme : ""
						)}
						onClick={e => {
							if (e.target === e.currentTarget) {
								setCometChatVisib(false);
								unrenderCometChat(); // not even display: none, fully unrender
							}
						}}
					>
						<div
							className={styles.cometChatBox}
							// style={{ width: "600px", height: "300px" }}
						>
							<CometChatUI />
						</div>
					</div>
				)}
			</HeaderProviders.PartialMergingProvider>
		</ChatContext.Provider>
	);
});

const Empty = () => null;

const ChatContext = DynamicContext.createDestructured<{
	onClick: () => void;
}>();

const ChatWrapper = React.memo<{}>(function ChatWrapper({}) {
	const onClick = ChatContext.useProperty("onClick");

	const [unreadCount, setUnreadCount] = useState(0);

	const fetchAndSetUnreadCount = (
		setCount: React.Dispatch<React.SetStateAction<number>>
	) => {
		CometChat.getUnreadMessageCount().then(
			(array: {
				groups: { [key: string]: number };
				users: { [key: string]: number };
			}) => {
				// console.log("Message count all unread", array);
				let countFromGroups = 0;
				let countFromUsers = 0;

				for (const key in array.groups) {
					const currCount = array.groups[key];
					// console.log("currCountttt:", currCount);
					countFromGroups += currCount;
				}

				for (const key in array.users) {
					const currCount = array.users[key];
					countFromUsers += currCount;
				}

				console.log(
					"countFrom Groups and users:",
					countFromGroups,
					countFromUsers
				);

				const newAllCount = countFromGroups + countFromUsers;
				setCount(newAllCount);
			},
			error => {
				console.log("Error in getting all message count", error);
			}
		);
	};

	useEffect(() => {
		const fetchTimer = setTimeout(() => {
			fetchAndSetUnreadCount(setUnreadCount);
		}, 500);

		return () => {
			clearTimeout(fetchTimer);
		};
	}, []);

	useEffect(() => {
		const inter = setInterval(() => {
			fetchAndSetUnreadCount(setUnreadCount);
		}, 30000); // cometchat 30 seconds to fetch unread message count

		return () => {
			clearInterval(inter);
		};
	}, []);

	return (
		<div className={styles.chatIconAndNotif}>
			<div className={styles.chatIconBox} onClick={onClick}>
				<ChatmeIcon className={styles.chatmeIcon} />
			</div>
			{unreadCount ? (
				<div className={styles.unreadNotif}>{unreadCount}</div>
			) : (
				""
			)}
		</div>
	);
});

export const HeaderDistributor: React.FC = React.memo(() => {
	const user = useClassroomsUser();
	const $$userType = user?.getRandomType() ?? null;

	const headerTheme = useGeneralContext("headerTheme");
	const defaultTheme = useDefaultThemeByType($$userType);

	const theme = headerTheme || defaultTheme;
	const colors = useHeaderColorsByTheme(theme);
	const priorityContextValue = useHeaderSubWebsiteValues($$userType);

	let mainComp: JSX.Element;

	if ($$userType === UserType.student) {
		mainComp = <StudentHeader />;
	} else if ($$userType === UserType.teacher) {
		mainComp = <TeacherHeader />;
	} else if ($$userType === UserType.headmaster) {
		mainComp = <HeadmasterHeader />;
	} else if ($$userType === UserType.mainAdmin) {
		mainComp = <MainAdminHeader />;
	} else if ($$userType === UserType.parent) {
		mainComp = <ParentHeader />;
	} else {
		mainComp = <GuestHeader />;
	}

	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				data: {
					colors: CreateCusto.functionOf.Data(() => colors),
				},
			}}
		>
			<HeaderProviders.PartialMergingProvider
				value={priorityContextValue}
				__order={10}
			>
				{theme === "light" && <NewDesignBackground />}
				{mainComp}
				<EduFairLine />
			</HeaderProviders.PartialMergingProvider>
		</HeaderProviders.PartialMergingProvider>
	);
});

const useDefaultThemeByType = (userType: UserType | null): HeaderTheme => {
	if (userType === UserType.teacher) return "light";
	if (userType === UserType.headmaster) return "light";
	if (!userType) return "white";
	return "dark";
};

export const HeaderHider = React.memo<{ hideHeader: boolean | undefined }>(
	({ hideHeader }) => {
		useSetIsHeaderHidden(hideHeader, typeof hideHeader !== "boolean");
		return null;
	}
);

export const useHeaderSubWebsiteValues = (
	userType: UserType | null
): HeaderValueType => {
	const subwebsite = useSubWebsite();
	if (subwebsite === SubWebsiteOrigin.britishCenter) {
		return {
			elements: {
				ProfileImg: CreateCusto.Component("img", {
					style: {
						margin: 0,
						transform: "scale(1.4)",
					},
				}),
			},
			data: {
				profileImg: "/imgs/BC-logo.png",
				showWarningIcon: false,
			},
		};
	}
	return {};
};
