import React from "react";
import {
	useUserWeeklyTestReservation,
	WeeklyTestReservationStatus,
} from "@app/hooks/weekly-test-reservations";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { useClassroomsUserId } from "@app/hooks/users";
import { WeekType } from "@app/api/weekly-tests/helper-schemas";

export const wrapInReservation = <
	Props extends {
		reservation: WeeklyTestReservationStatus;
	}
>(
	Component: React.ComponentType<Props>,
	weekType: WeekType | ((props: Props) => WeekType),
	{
		allowUnauhtorized,
		requireDoc,
	}: { allowUnauhtorized?: boolean; requireDoc?: boolean } = {}
): React.ComponentType<Omit<Props, "reservation">> =>
	React.memo<any>(function(props) {
		const userId = useClassroomsUserId();
		const reservation = useUserWeeklyTestReservation(
			typeof weekType === "function" ? weekType(props) : weekType
		);
		if (!allowUnauhtorized && !userId) {
			return null;
		}
		if (!reservation && userId) {
			return <FancyLoadingCenter />;
		}
		if (!reservation?.doc && requireDoc) {
			return <FancyLoadingCenter />;
		}
		return <Component {...props} reservation={reservation} />;
	});
