import { IRequest } from "../requests";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import {
	RGETSingleUserStatsSchema,
	IAGETSingleUserStats,
	IRGETSingleUserStats,
	IRGETUserStats,
	RGETUserStatsSchema,
	IAGETUserStats,
} from "./validators";

export class UserStatsController {
	private readonly Request: IRequest;
	private readonly CoursesRequest: IRequest;

	private readonly CourseRedirectionController = inject(
		"CourseRedirectionController"
	);

	constructor(request: IRequest, coursesRequest: IRequest) {
		this.Request = request;
		this.CoursesRequest = coursesRequest;
	}

	getMyStats = async (args: {
		courseId: ObjectId;
	}): Promise<IRGETSingleUserStats> => {
		return this.CourseRedirectionController.redirect(
			"POST",
			"/classrooms/get-student-stats",
			args,
			null,
			{
				responseSchema: RGETSingleUserStatsSchema,
			}
		);
	};

	getSingleStudentStats = async (
		args: IAGETSingleUserStats
	): Promise<IRGETSingleUserStats> => {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/students/:userId/stats",
			args,
			{
				headers: {
					"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
				},
			},
			{
				responseSchema: RGETSingleUserStatsSchema,
			}
		);
	};

	getClassroomStudentsStats = async (
		args: IAGETUserStats
	): Promise<IRGETUserStats> => {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/students-stats",
			args,
			{
				headers: {
					"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
				},
			},
			{
				responseSchema: RGETUserStatsSchema,
			}
		);
	};
}
