import { IRequest } from "../requests";

type sendParameters = Parameters<IRequest["send"]>;

export class CourseRedirectionController {
	private readonly Request: IRequest;
	private readonly CoursesRequest: IRequest;

	constructor(request: IRequest, coursesRequest: IRequest) {
		this.Request = request;
		this.CoursesRequest = coursesRequest;
	}

	redirect = (...sendParameters: sendParameters) => {
		const [method, url, data, customConfig, ...rest] = sendParameters;
		return this.Request.send(
			method,
			"/api/courses-redirect" + url,
			data,
			{
				...(customConfig || {}),
				headers: {
					"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
					...(((customConfig || {}) as any).headers || {}),
				},
			},
			...rest
		);
	};
}
