import { addLoader } from "@app/common-javascript";
import { AuthInput } from "@app/components/auth/input-forms";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ChangePassword } from "../general_components/change-password";
import { Mobile } from "../general_components/mobile";
import { UserProfileAvatar } from "../general_components/profile-avatar";
import genStyles from "../general_components/styles/general_styles.module.css";

// eslint-disable-next-line max-lines-per-function
export const ParentProfileContent: React.FC<{}> = props => {
	const isGeo = useLocale() === "ka";
	const user = useClassroomsUser()!;
	const [name, setName] = useState(user.firstname);
	const [surname, setSurname] = useState(user.lastname);
	const [newPassword, setNewPassword] = useState<string>("");
	const [repeatedPassword, setRepeatedPassword] = useState<string>("");
	const [isChanged, setIsChanged] = useState<boolean>(false);

	const onNewPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setNewPassword(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onRepeatedNewPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setRepeatedPassword(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onSurnameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSurname(e.target.value);
			setIsChanged(true);
		},
		[]
	);

	const onSave = useCallback(() => {
		const UsersController = inject("UsersController");
		const sendRequest = newPassword && newPassword === repeatedPassword;
		if (newPassword && !sendRequest) {
			openConfirmationPopup({
				text: getFormattedMessage("passwordDontMatch"),
			});
			return;
		}
		if (sendRequest && newPassword.length < 5) {
			openConfirmationPopup({
				text: getFormattedMessage("passwordLength", {
					requiredLength: 5,
				}),
			});
			return;
		}
		const removeLoader = addLoader();
		UsersController.saveDetailedInfo({
			firstname: name,
			lastname: surname,
			newPassword: sendRequest ? newPassword : undefined,
		})
			.then(() => {
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("refreshInfo"),
				});
				setIsChanged(false);
			})
			.catch(() => {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
				removeLoader();
			});
	}, [name, surname, newPassword, repeatedPassword]);

	return (
		<section
			className={genStyles.profile_left__sidebar_container}
			style={{ width: "100%", marginTop: 10 }}
		>
			<section className={genStyles.profile_left__sidebar}>
				<UserProfileAvatar />

				<form className={genStyles.personal_info_form}>
					<h2 className={genStyles.personal_info_form__title}>
						<FormattedMessage id="personalInformation" />
					</h2>
					<section
						className={genStyles.personal_info_form__input_wrapper}
					>
						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.firstname" />
							</label>

							<AuthInput
								defaultValue={name}
								onChange={onNameChange}
								type={"text"}
								className={genStyles.input}
								fullWidthInput={{ width: "100%" }}
								isDisabled={true}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.lastname" />
							</label>
							<AuthInput
								defaultValue={surname}
								onChange={onSurnameChange}
								type={"text"}
								className={genStyles.input}
								fullWidthInput={{ width: "100%" }}
								isDisabled={true}
							/>
						</section>
					</section>
				</form>

				{isGeo && <Mobile />}

				<ChangePassword
					onNewPasswordChange={onNewPasswordChange}
					onRepeatedNewPasswordChange={onRepeatedNewPasswordChange}
				/>
				<div className={genStyles.saveButtonContainer}>
					{isChanged && (
						<button
							onClick={onSave}
							className={genStyles.saveButtonAfterConfirmed}
						>
							<FormattedMessage id="save" />
						</button>
					)}
				</div>
			</section>
		</section>
	);
};
