import { VideoLesson } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { groupArray } from "@app/utils/array";
import validateSchema from "@app/utils/validate-schema";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import {
	ITemporaryVideoLink,
	IVideoLesson,
	TemporaryVideoLinkSchema,
	VideoLessonSchema,
} from "./helper-schemas";
import {
	IAGETExportTeacherUrls,
	IAGETPopTemporaryVideoLink,
	IAGETVideoLesson,
	IAGETVideoLessonsMany,
	IAPOSTAddTemporaryVideoLinks,
	IAPUTSaveTeacherVisitTime,
	IAPUTSaveVideoLesson,
	IRGETAllTemporaryLinkRequests,
	IRGETVideoLesson,
	IRGETVideoLessonsMany,
	IRPUTSaveVideoLesson,
	IRRGETPopTemporaryVideoLink,
	RGETAllTemporaryLinkRequestsSchema,
	RGETVideoLessonSchema,
	RGETVideoLessonsManySchema,
} from "./validators";

export class VideoLessonsController {
	private readonly Request: IRequest;

	private readonly _VideoLessonModel = inject("VideoLessonModel");
	constructor(request: IRequest) {
		this.Request = request;
	}

	private videoCollmise = collmise<
		IAGETVideoLesson,
		IRGETVideoLesson,
		VideoLesson
	>({
		findCachedData: query =>
			this._VideoLessonModel.findByResourceId(query.resourceId),
		dataTransformer: data => this._VideoLessonModel.loadOneSync(data),
		collectingTimeoutMS: 15,
	}).addCollector<
		"many",
		IAGETVideoLessonsMany,
		IRGETVideoLessonsMany,
		VideoLesson[]
	>({
		name: "many",
		onRequest: args =>
			this.Request.send<IRGETVideoLessonsMany, IAGETVideoLessonsMany>(
				"GET",
				"/api/video-lessons",
				args,
				null,
				{ responseSchema: RGETVideoLessonsManySchema }
			),
		multiDataTransformer: data => this._VideoLessonModel.loadManySync(data),
		findOne: (single, videoLessons) =>
			videoLessons.find(
				e =>
					e.resourceId === single.resourceId &&
					e.resourceType === single.resourceType
			),
		splitInIdClusters: ids =>
			groupArray(
				ids,
				e => e.resourceType,
				(grouped, resourceType) => ({
					cluster: {
						resourceIds: grouped.map(e => e.resourceId),
						resourceType,
					},
					ids: grouped,
				})
			),
		clusterToIds: cluster =>
			cluster.resourceIds.map(resourceId => ({
				resourceId,
				resourceType: cluster.resourceType,
			})),
	});

	add = async (args: IAPUTSaveVideoLesson): Promise<VideoLesson> =>
		this.Request.send("PUT", "/api/video-lessons/", args, null, {
			responseSchema: VideoLessonSchema,
		}).then((data: IRPUTSaveVideoLesson) => {
			return this._VideoLessonModel.loadOneSync(data);
		});

	saveForAll = async (args: IAPUTSaveVideoLesson): Promise<VideoLesson> =>
		this.Request.send("PUT", "/api/video-lessons/all-classrooms", args);
	requestTemporaryLink = async (
		args: IAGETPopTemporaryVideoLink
	): Promise<IRRGETPopTemporaryVideoLink> =>
		this.Request.send(
			"GET",
			"/api/temporary-video-links/",
			args,
			null
		).then((data: IRRGETPopTemporaryVideoLink) => {
			return this._VideoLessonModel.loadManySync([data], "replaceAll")[0];
		});

	getMany = async (args: IAGETVideoLessonsMany): Promise<VideoLesson[]> =>
		this.videoCollmise.collectors.many(args).request();

	get = async (
		args: IAGETVideoLesson,
		loadFresh?: boolean
	): Promise<VideoLesson> => {
		return this.videoCollmise
			.on(args)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send<IRGETVideoLesson | null>(
					"GET",
					"/api/video-lessons/:resourceId",
					args
				).then(data => {
					if (!data) {
						return this._VideoLessonModel.loadEmpty(
							args.resourceId,
							args.resourceType
						);
					}
					return validateSchema(data, RGETVideoLessonSchema);
				})
			);
	};

	saveTemporaryLinks = async (
		args: IAPOSTAddTemporaryVideoLinks
	): Promise<void> =>
		this.Request.send("POST", "/api/temporary-video-links/", args, null);

	getAllLinkRequests = async (): Promise<IRGETAllTemporaryLinkRequests> =>
		this.Request.send("GET", "/api/temporary-link-requests/", {}, null, {
			responseSchema: RGETAllTemporaryLinkRequestsSchema,
		});

	getAllTemporaryLinks = async (): Promise<ITemporaryVideoLink> =>
		this.Request.send("GET", "/api/temporary-video-links/all", {}, null, {
			responseSchema: TemporaryVideoLinkSchema,
		});

	saveAuthorVisitTime = async (
		args: IAPUTSaveTeacherVisitTime
	): Promise<VideoLesson> => {
		return this.Request.send(
			"PUT",
			"/api/video-lessons/teacher-visited",
			args,
			null,
			{ responseSchema: VideoLessonSchema }
		).then((data: IVideoLesson) => {
			return this._VideoLessonModel.loadOneSync(data);
		});
	};

	exportTeacherVideoLectureLinks = async (args: IAGETExportTeacherUrls) => {
		console.log(args);
		return this.Request.send(
			"GET",
			"/api/video-lessons/export-teacher-links",
			args,
			{ responseType: "blob" }
		);
	};
}
