import { useHistory } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useTeacherClassroomsSchools } from "@app/hooks/teacher";
import classnames from "classnames";
import React, { useMemo, useState } from "react";
import {
	SELECTED_SCHOOL_ID_QUERY_KEY,
	useTeacherSchoolSetterFn,
	useTeacherSelectedSchoolId,
} from "../contexts/teacher-school";
import closeIcon from "./styles/imgs/close-cross-icon.svg";
import TeacherHeaderStyles from "./styles/teacher-header.module.css";
import { getReplacedQuery } from "@app/utils/urls";

export const TeacherSchoolsSelector = React.memo<{
	hideIfOnlyOneSchool?: boolean;
}>(function TeacherSchoolsSelector({ hideIfOnlyOneSchool }) {
	const {
		value: isSelectorPopupOpen,
		setFalse: closeSelectorPopup,
		setTrue: openSelectorPopup,
	} = useBoolean();
	const history = useHistory();

	const teacherSchoolsInfo = useTeacherClassroomsSchools();
	const teacherSchools = teacherSchoolsInfo?.schools?.doc;
	const hasSchoollessClassroom = !!teacherSchoolsInfo?.hasSchoollessClassroom;

	const setSchoolId = useTeacherSchoolSetterFn();
	const selectedSchoolId = useTeacherSelectedSchoolId();

	const handleSchoolChange = (schoolId: number | null) => {
		setSchoolId(schoolId);
		const query = getReplacedQuery(SELECTED_SCHOOL_ID_QUERY_KEY, schoolId);
		history.push(`/?${query}`);
	};

	const schoolOptions = useMemo(() => {
		if (!teacherSchools) return null;
		return (teacherSchools as { id: number | null; name: string }[]).concat(
			!hasSchoollessClassroom ? [] : [{ id: null, name: "უსკოლო" }]
		);
	}, [hasSchoollessClassroom, teacherSchools]);

	const selectedSchool = useMemo(() => {
		if (!schoolOptions) return undefined;
		return schoolOptions.find(school => school.id === selectedSchoolId);
	}, [selectedSchoolId, schoolOptions]);

	if (!schoolOptions) return null;

	let display =
		selectedSchoolId === null && hasSchoollessClassroom
			? schoolOptions.length > 1
			: !!selectedSchool;

	if (schoolOptions.length <= 1 && hideIfOnlyOneSchool) display = false;

	if (!display) return null;

	return (
		<React.Fragment>
			<span className={TeacherHeaderStyles.singleSchIndicator}>
				{schoolOptions.length === 1 ? "|" : ""}
			</span>
			<div
				onClick={openSelectorPopup}
				className={classnames(
					TeacherHeaderStyles.schoolselector,
					schoolOptions.length > 1 && TeacherHeaderStyles.available
				)}
			>
				{selectedSchool?.name.replace(/№/g, "N")}
			</div>
			{isSelectorPopupOpen && schoolOptions.length > 1 && (
				<SchoolSelectorPopup
					options={schoolOptions}
					onClose={closeSelectorPopup}
					defaultSelectedSchoolId={selectedSchoolId}
					onChoose={handleSchoolChange}
					hasSchoollessClassroom={hasSchoollessClassroom}
				/>
			)}
		</React.Fragment>
	);
});

const SchoolSelectorPopup = React.memo<{
	onClose: () => void;
	options: { id: number | null; name: string }[];
	defaultSelectedSchoolId: number | null;
	onChoose: (selectedSchoolId: number | null) => void;
	hasSchoollessClassroom: boolean;
}>(
	({
		onClose,
		onChoose,
		options,
		defaultSelectedSchoolId,
		hasSchoollessClassroom,
	}) => {
		const [markedSchoolId, setMarkedSchoolId] = useState(
			defaultSelectedSchoolId
		);

		const handleSubmit = () => {
			onChoose(markedSchoolId);
			onClose();
		};

		return (
			<div className={TeacherHeaderStyles.schoolselectorpopupcontainer}>
				<div className={TeacherHeaderStyles.popupbox}>
					<div className={TeacherHeaderStyles.topribbon}>
						<div
							className={TeacherHeaderStyles.closebutton}
							onClick={onClose}
						>
							<img
								className={TeacherHeaderStyles.closeicon}
								src={closeIcon}
								alt="close popup"
								width="15"
							/>
						</div>
					</div>
					<p className={TeacherHeaderStyles.popuptitle}>
						პროფილის შეცვლა
					</p>
					<div className={TeacherHeaderStyles.schlist}>
						{options.map(option => (
							<span
								key={option.id || "null"}
								className={
									TeacherHeaderStyles.sch +
									" " +
									(option.id === markedSchoolId
										? TeacherHeaderStyles.candidatesch
										: "")
								}
								onClick={() => setMarkedSchoolId(option.id)}
							>
								<span>{option.name.replace(/№/g, "N")}</span>
							</span>
						))}
					</div>
					<div
						className={TeacherHeaderStyles.schconfirmbutton}
						onClick={handleSubmit}
					>
						არჩევა
					</div>
				</div>
			</div>
		);
	}
);
