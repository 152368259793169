import { requireLoginForActionPromise } from "@app/components/login-request";
import { createRequests, ICredentials } from "./requests";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import Jwt from "jsonwebtoken";
import { User } from "@app/user";
import { IRUser } from "./users/helper-schemas";
import { inject } from "@app/modules";
import { deepEqual } from "@app/utils/common";
import {
	getCurrentChild,
	getChildAccessTokenSync,
	updateChildAccessToken,
} from "@app/user/children";
import { subscribeToLocalStorageKeyValueChange } from "@app/extra";
import { removeNullableValues } from "@app/utils/object";
import Compr from "lzutf8";
import { CHILD_TOKEN_KEY } from "@app/consts";

(window as any).Compr = Compr;

const getAccessToken = (): string | undefined => {
	try {
		const credentials = JSON.parse(localStorage.credentials);
		return "" + credentials.accessToken;
	} catch (e) {
		return undefined;
	}
};
const getCredentials = (): ICredentials => {
	if (!localStorage.credentials) {
		throw new Error("credentials not set");
	}
	return JSON.parse(localStorage.credentials);
};

const logoutUser = () => {
	const AuthController = inject("AuthController");
	AuthController.logout().then();
};

const decodeClassroomsAccessToken = (newAccessToken: string): User => {
	let decodedObj = Jwt.decode(newAccessToken);
	if (decodedObj.j) {
		decodedObj = Compr.decompress(decodedObj.j, {
			inputEncoding: "BinaryString",
		});
		decodedObj = JSON.parse(decodedObj);
	}
	return User.createUserInstance(decodedObj as IRUser);
};

const coursesTokensHaveToBeUpdated = (newUser: User, oldUser: User | null) => {
	if (!oldUser) return true;
	const newCourses = newUser.getAccessibleCourseIds();
	const oldCourses = oldUser.getAccessibleCourseIds();
	if (newCourses.isKnown && deepEqual(newCourses, oldCourses)) {
		return false;
	}
	return true;
};

const Requests = createRequests({
	accessTokenKey: "access-token",
	childTokenKey: CHILD_TOKEN_KEY,
	urlPrefix: process.env.REACT_APP_BACKEND_SERVER
		? process.env.REACT_APP_BACKEND_SERVER
		: "",
	numOfSeccondsToRenewTokenBeforeExpiration: 0,
	requireLoginForActionPromise: requireLoginForActionPromise,
	updateAccessToken: () => {
		const AuthController = inject("AuthController");
		const credentials = getCredentials();
		return AuthController.updateAccessToken({
			userId: credentials.userId as number,
			refreshToken: credentials.refreshToken,
		});
	},
	updateChildAccessToken: async () => {
		const userId = getCurrentChild();
		if (typeof userId === "undefined") {
			// TODO: choose child id
			throw new Error();
		}
		return updateChildAccessToken({ userId, forceFetch: true });
	},
	initialConfig: {
		headers: {
			"access-token": getAccessToken(),
		},
	},
	onResponse: (response: AxiosResponse) => {
		const newAccessToken = response.headers["access-token"];
		const refreshToken = response.headers["refresh-token"];
		if (newAccessToken || refreshToken) {
			const credentialsStr = localStorage.getItem("credentials");
			if (!credentialsStr) return;
			const AuthController = inject("AuthController");
			const credentials: ICredentials = JSON.parse(credentialsStr);
			if (newAccessToken) {
				try {
					const user = decodeClassroomsAccessToken(newAccessToken);
					const oldUser = decodeClassroomsAccessToken(
						credentials.accessToken
					);
					credentials.userId = user.id;
					AuthController.updateUserDataAction(user.getRawUser());
					const shouldCoursesTokenBeUpdated = coursesTokensHaveToBeUpdated(
						user,
						oldUser
					);
					if (shouldCoursesTokenBeUpdated) {
						CoursesRequests.sendNewAccessTokenRequest().catch();
					}
				} catch (e) {
					console.error(e);
				}
			}
			AuthController.renewCredentials({
				accessToken: newAccessToken || credentials.accessToken,
				refreshToken: refreshToken || credentials.refreshToken,
				userId: credentials.userId,
			});
		}
	},
	logoutUser,
	preRequestHook: args => {
		const loc = inject("getLoc")();

		return {
			...args,
			config: {
				...args.config,
				headers: removeNullableValues({
					...args.config.headers,
					[CHILD_TOKEN_KEY]: (args.config as any).stripChildToken
						? undefined
						: args.config.headers[CHILD_TOKEN_KEY],
					"loc-w": loc.website,
					"loc-c": loc.country,
					"loc-l": loc.language,
				}),
			},
		};
	},
});

const getCoursesToken = (): string | undefined => {
	try {
		const credentials = JSON.parse(localStorage.coursesCredentials);
		return "" + credentials.accessToken;
	} catch (e) {
		return undefined;
	}
};
const getCoursesCredentials = (): ICredentials => {
	if (!localStorage.coursesCredentials) {
		throw new Error("credentials not set");
	}
	return JSON.parse(localStorage.coursesCredentials);
};

export const CoursesRequests = createRequests({
	accessTokenKey: "access_token",
	urlPrefix: process.env.REACT_APP_COURSES_URL
		? process.env.REACT_APP_COURSES_URL
		: "",
	numOfSeccondsToRenewTokenBeforeExpiration: 0,
	requireLoginForActionPromise: requireLoginForActionPromise,
	updateAccessToken: () => {
		const credentials = getCoursesCredentials();
		const AuthController = inject("AuthController");
		return AuthController.updateCoursesAccessToken({
			userId: credentials.userId,
			refreshToken: credentials.refreshToken,
		});
	},
	initialConfig: {
		headers: {
			access_token: getCoursesToken(),
		},
	},
	logoutUser,
});

const coursesTokenExpirationWatcher = () => {
	const EXPIRATION_MIN_TIME_DIFF = 90 * 1000;
	try {
		let shouldRequireNewToken = false;
		const credentialsStr = localStorage.getItem("coursesCredentials");
		if (!credentialsStr) return;
		const credentials: ICredentials = JSON.parse(credentialsStr);
		const jsonData = Jwt.decode(credentials.accessToken);
		if (typeof jsonData === "object" && jsonData) {
			const exspiresAt = new Date(jsonData.exp * 1000);
			const diff = exspiresAt.getTime() - Date.now();
			if (diff < EXPIRATION_MIN_TIME_DIFF) {
				shouldRequireNewToken = true;
			}
		}
		if (shouldRequireNewToken) {
			CoursesRequests.sendNewAccessTokenRequest(() => {
				console.log("renewed courses token");
			}).catch();
		}
	} catch (e) {
		console.log(e, "error while renewing courses token");
	}
};
setInterval(coursesTokenExpirationWatcher, 20 * 1000);
setTimeout(coursesTokenExpirationWatcher, 100);

window.addEventListener("storage", (e: StorageEvent) => {
	if (e.key === "credentials") {
		handleCredentialsChange();
	}
});

subscribeToLocalStorageKeyValueChange(
	"currentChild",
	handleChildCredentialsChange
);
subscribeToLocalStorageKeyValueChange(
	"childCredentials",
	handleChildCredentialsChange
);

function handleChildCredentialsChange() {
	Requests.renewChildAccessToken(getChildAccessTokenSync());
}

function handleCredentialsChange() {
	const accessToken = getAccessToken();
	if (typeof accessToken === "string") {
		Requests.renewAccessToken(accessToken);
	} else {
		Requests.renewAccessToken(undefined);
	}
}

export { Requests };

export const omitSecondaryUsers = (config: AxiosRequestConfig = {}) => {
	return {
		...config,
		headers: { ...config.headers, [CHILD_TOKEN_KEY]: undefined },
	};
};
