import {
	ToInsertKeys,
	ToUpdateKeys,
	insertStripKeys,
	markKeysStripped,
	updateStripKeys,
} from "../helper-schemas";
import { IAudience, AudienceSchema } from "./helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const APOSTAudienceSchema = AudienceSchema.keys(insertStripKeys).fork(
	"addressees",
	markKeysStripped
);

export type IAPOSTAudience = ToInsertKeys<IAudience, never, "addressees">;

export const RPOSTAudienceSchema = AudienceSchema;
export type IRPOSTAudience = IAudience;

///

export const APUTAudienceSchema = AudienceSchema.keys(updateStripKeys).fork(
	"addressees",
	markKeysStripped
);

export type IAPUTAudience = ToUpdateKeys<IAudience, never, "addressees">;

export const RPUTAudienceSchema = AudienceSchema;
export type IRPUTAudience = IAudience;

///

export const AGETAudienceSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETAudience {
	_id: ObjectId;
}

export const RGETAudienceSchema = AudienceSchema;
export type IRGETAudience = IAudience;

///

export const APUTRefreshSchoolConferences = Joi.object({
	schoolId: Joi.number(),
});
export interface IAPUTRefreshSchoolConferences {
	schoolId?: number;
}
