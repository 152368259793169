import Joi from "@app/utils/joi";

export const ClassroomEnrollmentCodeSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	author: Joi.number()
		.integer()
		.required(),
	code: Joi.string().required(),
	metadata: Joi.any(),
	createdAt: Joi.date().required(),
});
export interface IClassroomEnrollmentCode {
	id: number;
	author: number;
	code: string;
	metadata: any;
	createdAt: Date;
}

export const StudentClassroomEnrollmentCodeSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	studentId: Joi.number()
		.integer()
		.required(),
	codeId: Joi.number()
		.integer()
		.required(),
	isUsed: Joi.boolean().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IStudentClassroomEnrollmentCode {
	id: number;
	studentId: number;
	codeId: number;
	isUsed: boolean;
	createdAt: Date;
	updatedAt: Date;
}
