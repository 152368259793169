import { itemTypes, ItemType } from "../folders/helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const AppealSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	itemType: Joi.number().valid(...itemTypes),
	itemId: Joi.objectId().required(),
	objected: Joi.boolean().required(),
	message: Joi.string()
		.allow("", null)
		.required(),
	author: Joi.number()
		.allow(null)
		.required(),
	ip: Joi.string(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IAppeal {
	_id: ObjectId;
	courseId: ObjectId;
	itemType: ItemType;
	itemId: ObjectId;
	objected: boolean;
	message: string | null;
	author: number | null;
	ip?: string;
	createdAt: Date;
	updatedAt: Date;
}
