/* eslint-disable max-lines-per-function */
import { UserType } from "@app/api/helper-schemas";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useHistory, useLocationQuery, useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import UserIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import { default as classnames, default as classNames } from "classnames";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { registrationClassNames } from ".";
import { SchoolRegisterPage } from "../auth/school-registration-page";
import { StudentRegisterFirstStep } from "../auth/student-register-page";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import Popup, { PopupContent } from "../widgets/popup";
import { LoginButton } from "./login";
import { ReactComponent as TeacherIcon } from "./styles/img/professor.svg";
import { ReactComponent as RegisterIllustrationEngVersion } from "./styles/img/Registration-eng.svg";
import { ReactComponent as RegisterIllustration } from "./styles/img/Registration.svg";
import headerStyles from "./styles/landing-header.module.css";
import styles from "./styles/landing-main-page.module.css";

const responsiveWidthForRegisterTypesContainer = 460;

export interface IRegisterPopupProps {
	onClose?: () => void;
	isOnlyPopup?: boolean;
	isLoginPopupOpen: boolean;
	openLogin: () => void;
	hideLogin: () => void;
	inputClassname?: string;
	registrationButtonClassname?: string;
	isCustomStylesInvissible?: boolean;
	isRegisterIllustrationVissible?: boolean;
	userType?: UserType;
	isCodeInputVissible?: boolean;
	isCountryInputInvissible?: boolean;
	componentName?: string;
	isToTest?: boolean;
	hideUserTypesSelect?: boolean;
}

export const RegisterPopup: React.FC<IRegisterPopupProps> = props => {
	const isTvSchool = getCurrentWebsite() === WebsiteOrigin.tvSchool;

	const [registerType, setRegisterType] = useState<UserType | null>(
		props.userType || (isTvSchool ? null : UserType.student)
	);

	const { width } = useWindowSize();
	const history = useHistory();
	const locationQuery = useLocationQuery(true);
	let redirectTo =
		locationQuery && typeof locationQuery.red === "string"
			? decodeURI(locationQuery.red)
			: null;
	if (!redirectTo) {
		redirectTo =
			locationQuery && typeof locationQuery.redirect === "string"
				? decodeURI(locationQuery.redirect)
				: null;
	}
	const redirectToRegistration = useCallback(() => {
		history.push(redirectTo ?? "/");
	}, [history, redirectTo]);

	const onSelectionClick = (userType: UserType) => {
		setRegisterType(userType);
	};

	const local = useLocale();
	const isNLD = isTvSchool && local !== "ka";

	const isIconVisible = width > responsiveWidthForRegisterTypesContainer;
	return (
		<Popup onClose={props.onClose}>
			<PopupContent style={{ marginTop: "4vh" }}>
				<div
					className={
						props.isRegisterIllustrationVissible
							? StudentRegisterStyles.registerInnerBodyAndIllustrationContainer
							: StudentRegisterStyles.innerBody
					}
				>
					{width > 700 &&
						props.isRegisterIllustrationVissible &&
						local === "ka" && (
							<RegisterIllustration
								className={
									StudentRegisterStyles.registerIllustartion
								}
							/>
						)}
					{width > 700 &&
						props.isRegisterIllustrationVissible &&
						local === "en" && (
							<RegisterIllustrationEngVersion
								className={
									StudentRegisterStyles.registerIllustartion
								}
							/>
						)}
					<div
						className={
							props.isOnlyPopup
								? styles.registerPopupWrapper
								: styles.a
						}
					>
						<div
							className={
								isNLD
									? styles.areYouRegisteredQuestionsNLD
									: styles.areYouRegisteredQuestions
							}
						>
							<FormattedMessage id="landing:areYouRegistered" />
							<LoginButton
								openLoginPopup={props.openLogin}
								buttonClassname={styles.loginButtonClassname}
							/>
						</div>

						<div className={styles.mainQuestionTitle}>
							{registerType && !props.hideUserTypesSelect && (
								<>
									<FormattedMessage id="landing:registeredAs" />
									<div className={styles.userTypeInTitle}>
										{registerType === UserType.student && (
											<FormattedMessage id="student" />
										)}
										{registerType === UserType.parent && (
											<FormattedMessage id="parent" />
										)}
										{registerType === UserType.teacher && (
											<FormattedMessage id="teacher" />
										)}
										{registerType === UserType.school && (
											<FormattedMessage id="school" />
										)}
									</div>
								</>
							)}
						</div>

						{!props.hideUserTypesSelect && (
							<div
								className={
									props.isOnlyPopup
										? classNames(
												styles.popupRegisterButtonsContainer,
												styles.registerTypeSelection
										  )
										: styles.registerTypeSelection
								}
								style={
									isTvSchool
										? { justifyContent: "space-around" }
										: { justifyContent: "space-between" }
								}
							>
								<button
									className={classnames(
										props.isOnlyPopup
											? width > 700
												? classnames(
														styles.popupwhichAreUButton,
														styles.whichAreYouButtonInRegisterPopUp
												  )
												: styles.studentButtonInPopup
											: styles.whichAreYouButtonInRegisterPopUp,
										styles.whichAreYouButtonInRegisterPopUp,
										registerType === UserType.student &&
											styles.activeWhichAreYouButton
									)}
									onClick={() =>
										onSelectionClick(UserType.student)
									}
								>
									<FormattedMessage id="student" />
									{isIconVisible && !props.isOnlyPopup && (
										<UserIcon style={{ width: 22 }} />
									)}
								</button>

								<div
									className={
										styles.teacherAndSchoolButtonContainer
									}
								>
									<button
										className={classnames(
											props.isOnlyPopup
												? classnames(
														styles.popupwhichAreUButton,
														styles.whichAreYouButtonInRegisterPopUp
												  )
												: styles.whichAreYouButtonInRegisterPopUp,
											styles.whichAreYouButtonInRegisterPopUp,
											registerType === UserType.teacher &&
												styles.activeWhichAreYouButton
										)}
										onClick={() =>
											onSelectionClick(UserType.teacher)
										}
									>
										<FormattedMessage id="teacher" />
										{isIconVisible &&
											!props.isOnlyPopup && (
												<TeacherIcon
													className={
														styles.teacherIcon
													}
												/>
											)}
									</button>
									{props.isToTest && (
										<button
											className={classnames(
												props.isOnlyPopup
													? width > 700
														? classnames(
																styles.popupwhichAreUButton,
																styles.whichAreYouButtonInRegisterPopUp
														  )
														: styles.studentButtonInPopup
													: styles.whichAreYouButtonInRegisterPopUp,
												styles.whichAreYouButtonInRegisterPopUp,
												registerType ===
													UserType.parent &&
													styles.activeWhichAreYouButton
											)}
											onClick={() =>
												onSelectionClick(
													UserType.parent
												)
											}
										>
											<FormattedMessage id="parent" />
										</button>
									)}
								</div>
							</div>
						)}
						{(registerType === UserType.student ||
							registerType === UserType.teacher ||
							registerType === UserType.parent ||
							!registerType) && (
							<StudentRegisterFirstStep
								userType={registerType}
								classNames={registrationClassNames}
								onSuccess={redirectToRegistration}
								componentName={props.componentName || " "}
								isIconVissible={false}
								placeholder={" "}
								isUpperTextVissible={true}
								inputClassname={props.inputClassname}
								registrationButtonClassname={
									props.registrationButtonClassname
								}
								isRegisterIllustrationVissible={
									props.isRegisterIllustrationVissible
								}
								isCodeInputVissible={props.isCodeInputVissible}
								isCountryInputInvissible={
									props.isCountryInputInvissible
								}
								nameInputClassname={styles.nameInput}
							/>
						)}
						{registerType === UserType.school && (
							<SchoolRegisterPage
								userType={registerType}
								classNames={registrationClassNames}
								onSuccess={redirectToRegistration}
								componentName={" "}
								isIconVissible={false}
								placeholder={" "}
								isUpperTextVissible={true}
								inputClassname={props.inputClassname}
								registrationButtonClassname={
									props.registrationButtonClassname
								}
								isCustomSylesInVissible={
									props.isCustomStylesInvissible
								}
								isRegisterIllustrationVissible={
									props.isRegisterIllustrationVissible
								}
							/>
						)}

						<LoginButton
							openLoginPopup={props.openLogin}
							buttonClassname={styles.logInButtonInResponsive}
							text={getFormattedMessage("signInNow")}
						/>
					</div>
				</div>
			</PopupContent>
		</Popup>
	);
};

export const RegisterButton: React.FC<{
	openRegistrationPopup: () => void;
	buttonClassname?: string;
	text?: string;
}> = props => {
	const { openRegistrationPopup } = props;
	const onRegister = useCallback(() => {
		openRegistrationPopup();
		triggerEvent(
			{
				category: "Popup",
				action: "open registration popup",
				label: "landing page",
			},
			{
				from: "header",
			}
		);
	}, [openRegistrationPopup]);
	return (
		<button
			onClick={onRegister}
			className={
				props.buttonClassname
					? props.buttonClassname
					: headerStyles.registerButton
			}
		>
			{props.text ? props.text : <FormattedMessage id="registration" />}
		</button>
	);
};
