import { IRequest } from "@app/api/requests";
import { ObjectId } from "@app/utils/generics";
import {
	IAPOSTWeeklyTestSubjectMeta,
	IAPUTWeeklyTestSubjectMeta,
	IRGETWeeklyTestSubjects,
	IRPOSTWeeklyTestSubjectMeta,
	IRPUTWeeklyTestSubjectMeta,
	RGETWeeklyTestSubjectsSchema,
	RPOSTWeeklyTestSubjectMetaSchema,
	RPUTWeeklyTestSubjectMetaSchema,
} from "./validators";

export class WeeklyTestSubjectController {
	constructor(private readonly _Request: IRequest) {}

	getAll = (): Promise<IRGETWeeklyTestSubjects> => {
		return this._Request
			.send("GET", "/api/weekly-tests/subjects", {}, null, {
				responseSchema: RGETWeeklyTestSubjectsSchema,
			})
			.then((data: IRGETWeeklyTestSubjects) => {
				return [...data].sort((a, b) => {
					return (
						a.meta.createdAt.getTime() - b.meta.createdAt.getTime()
					);
				});
			});
	};

	createMeta = (
		args: IAPOSTWeeklyTestSubjectMeta
	): Promise<IRPOSTWeeklyTestSubjectMeta> => {
		return this._Request.send(
			"POST",
			"/api/weekly-tests/subjects/:subjectId/meta",
			args,
			null,
			{ responseSchema: RPOSTWeeklyTestSubjectMetaSchema }
		);
	};

	updateMeta = (
		args: IAPUTWeeklyTestSubjectMeta
	): Promise<IRPUTWeeklyTestSubjectMeta> => {
		return this._Request.send(
			"PUT",
			"/api/weekly-tests/subjects/:subjectId/meta",
			args,
			null,
			{ responseSchema: RPUTWeeklyTestSubjectMetaSchema }
		);
	};

	deleteMeta = (subjectId: ObjectId): Promise<void> => {
		return this._Request.send(
			"DELETE",
			`/api/weekly-tests/subjects/${subjectId}/meta`
		);
	};
}
