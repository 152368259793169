import { Group } from "@app/models/group";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { IRequest } from "../requests";
import { IAGETGroupsByStudentId } from "../video-lessons/validators";
import {
	AGETGroupClassroomsInfoSchema,
	IAGETGroupById,
	IAGETGroupClassroomsInfo,
	IAGETManyGroupsByIds,
	IRGETGroupById,
	IRGETGroupClassroomsInfo,
	IRGETManyGroupsByIds,
	RGETGroupByIdSchema,
	RGETGroupClassroomsInfoSchema,
	RGETManyGroupsByIdsSchema,
	IAGETGroupsBySchoolId,
	IRGETGroupsBySchoolId,
	RGETGroupsBySchoolIdSchema,
	IAGETArchivedGroupsForHeadmaster,
	IRGETArchivedGroupsForHeadmaster,
} from "./validators";
import {
	IRGETTransformedGroupClassroomsInfo,
	ITransformedGroupClassroomInfo,
} from "./validators-2";

export class GroupController {
	private readonly Request: IRequest;

	private _GroupModel = inject("GroupModel");
	private _ClassroomModel = inject("ClassroomModel");
	private _VideoLessonModel = inject("VideoLessonModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	getManyByIds = async (
		args: IAGETManyGroupsByIds,
		loadFresh?: boolean
	): Promise<Group[]> => {
		if (!loadFresh) {
			const groups = this._GroupModel.findManyByIdsSync(args.ids);
			if (groups.length === args.ids.length) {
				return groups;
			}
		}
		return this.Request.send("POST", "/api/groups/get-by-ids", args, null, {
			responseSchema: RGETManyGroupsByIdsSchema,
		}).then((groups: IRGETManyGroupsByIds) => {
			return this._GroupModel.loadManySync(groups);
		});
	};

	getById = async (_id: ObjectId, loadFresh?: boolean): Promise<Group> => {
		if (!loadFresh) {
			const group = this._GroupModel.findByIdSync(_id);
			if (group) return group;
		}
		return this.Request.send(
			"GET",
			"/api/groups/:_id",
			{ _id } as IAGETGroupById,
			null,
			{
				responseSchema: RGETGroupByIdSchema,
			}
		).then((group: IRGETGroupById) => {
			return this._GroupModel.loadOneSync(group);
		});
	};

	getBySchool = async (
		args: IAGETGroupsBySchoolId,
		loadFresh?: boolean
	): Promise<Group[]> => {
		if (!loadFresh) {
			const groups = this._GroupModel.findManySync({
				schoolId: args.schoolId,
			});
			if (groups) return groups;
		}
		return this.Request.send("GET", "/api/groups/by-schoolId", args, null, {
			responseSchema: RGETGroupsBySchoolIdSchema,
		}).then((groups: IRGETGroupsBySchoolId) => {
			return this._GroupModel.loadManySync(groups);
		});
	};

	getGroupsByStudentId = async (
		args: IAGETGroupsByStudentId,
		loadFresh?: boolean
	): Promise<Group[]> => {
		if (!args.studentId) return [];
		if (!loadFresh) {
			const groups = this._GroupModel.findByStudentId(args.studentId);
			if (groups.length) return groups;
		}
		return this.Request.send(
			"GET",
			"/api/groups/by-studentId",
			args,
			null,
			{
				responseSchema: RGETManyGroupsByIdsSchema,
			}
		).then((groups: IRGETManyGroupsByIds) => {
			return this._GroupModel.loadManySync(groups);
		});
	};

	getClassrooms = async (
		args: IAGETGroupClassroomsInfo
	): Promise<IRGETTransformedGroupClassroomsInfo> =>
		this.Request.send(
			"GET",
			"/api/groups/:groupId/classrooms",
			args,
			null,
			{
				requestSchema: AGETGroupClassroomsInfoSchema,
				responseSchema: RGETGroupClassroomsInfoSchema,
			}
		).then((data: IRGETGroupClassroomsInfo) => {
			return data.map(
				(each): ITransformedGroupClassroomInfo => {
					return {
						...each,
						classroom: new this._ClassroomModel(each.classroom),
						videoLesson:
							each.videoLesson &&
							new this._VideoLessonModel(each.videoLesson),
					};
				}
			);
		});
	getArchivedGroupsForHeadmaster = async (
		args: IAGETArchivedGroupsForHeadmaster
	): Promise<Group[]> => {
		return this.Request.send(
			"GET",
			"/api/groups/get-by-ids/get-archived-groups-headmaster",
			args
		).then((data: IRGETArchivedGroupsForHeadmaster) => {
			return this._GroupModel.loadManySync(data);
		});
	};
}
