import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { useGoToUrl } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import React from "react";
import { ReactComponent as LupiAILogo } from "@app/components/landing/styles/img/lupi-ai.svg";
import { ReactComponent as MijntakenLogo } from "@app/components/teachers/header/styles/imgs/Logo.svg";
import styles from "./styles.module.scss";

export const HeaderDefaultLogo = React.memo(function HeaderDefaultLogo() {
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const isEng = locale === "en";

	const gotoMainPage = useGoToUrl("/");

	return (
		<div onClick={gotoMainPage}>
			{isGeo && <TVSchoolLogo className={styles.logo} />}
			{isNl && <MijntakenLogo className={styles.logo} />}
			{isEng && <LupiAILogo className={styles.logo} />}
		</div>
	);
});
