import { ITopicModel } from "@app/models/topic";
import {
	IPrerequisiteInfo,
	ITopicHierarchyModel,
} from "@app/models/topic-hierarchy";
import { ObjectId } from "@app/utils/generics";
import { MError } from "../../../utils/errors";
import HierarchyInfoService from "../base";
import { inject } from "@app/modules";
import { HierarchyItemType } from "../interfaces";
import { IMultipleSelectItem, IItemsHierarchy } from "../clonnable";

export default class TopicHierarchyService extends HierarchyInfoService {
	private readonly _Topic: ITopicModel;

	private readonly _TopicHierarchy: ITopicHierarchyModel;

	constructor() {
		const TopicModel = inject("TopicModel");
		const TopicHierarchyModel = inject("TopicHierarchyModel");
		super(
			TopicHierarchyModel as any,
			TopicModel as any,
			inject("CourseModel"),
			HierarchyItemType.taskType
		);
		this._Topic = TopicModel;
	}

	public setItemParentSync(
		courseId: ObjectId,
		topicId: ObjectId,
		parentId: ObjectId
	): void {
		return super.setItemParentSync(courseId, topicId, parentId, {});
	}

	public getPrerequisiteInfoObjectSync(courseId: ObjectId) {
		const document = this._TopicHierarchy.findOneByCourseSync(courseId);
		if (!document) {
			throw new MError(404, "topics hierarchy document not found");
		}

		return document.prerequisiteInfo;
	}

	public getPrerequisitesSync(
		courseId: ObjectId,
		topicId: ObjectId,
		prerequisiteInfo?: IPrerequisiteInfo
	) {
		if (!prerequisiteInfo) {
			prerequisiteInfo = this.getPrerequisiteInfoObjectSync(courseId);
		}

		return prerequisiteInfo![topicId] || [];
	}

	public getAllItemsAndHierarchyObj(
		courseId: ObjectId
	): {
		items: IMultipleSelectItem<string>[];
		itemsHierarchy: IItemsHierarchy;
		rootId: string;
	} {
		return super.getAllItemsAndHierarchyObj(courseId, -1);
	}
}
