import { User } from "@app/user";
import { isLocalhost } from "@app/globals";

export const isTestUser = (
	user: User | undefined,
	considerLocalhostAsTest = true
): boolean => {
	if (!user) return false;
	if (considerLocalhostAsTest && isLocalhost()) {
		return true;
	}
	return isOfType(user.testAccountType, TestUserBits.test);
};

export const isSuperTestUser = (
	user: User | undefined,
	considerLocalhostAsTest = false
): boolean => {
	if (!user) return false;
	if (considerLocalhostAsTest && isLocalhost()) {
		return true;
	}
	return isOfType(user.testAccountType, TestUserBits.superTest);
};

export const isModerateTestUser = (
	user: User | undefined,
	considerLocalhostAsTest = false
): boolean => {
	if (!user) return false;
	if (considerLocalhostAsTest && isLocalhost()) {
		return true;
	}
	return isOfType(user.testAccountType, TestUserBits.moderateTest);
};

enum TestUserBits {
	test = 1 << 0,
	superTest = 1 << 1,
	moderateTest = 1 << 2,
}

const isOfType = (
	testAccountType: User["testAccountType"],
	type: TestUserBits
) => {
	if (!testAccountType) return false;
	return (testAccountType & type) > 0;
};
