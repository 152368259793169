import { useMemo } from "react";
import { useLocale } from "./intl";
import { getFormattedMessage } from "@app/utils/locale";
import { arrayRotateLeft } from "@app/utils/array";

export const useDays = () => {
	const locale = useLocale();
	return useMemo(
		() => [
			getFormattedMessage("days.sunday"),
			getFormattedMessage("days.monday"),
			getFormattedMessage("days.tuesday"),
			getFormattedMessage("days.wednesday"),
			getFormattedMessage("days.thursday"),
			getFormattedMessage("days.friday"),
			getFormattedMessage("days.saturday"),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const useDaysStartFromMonday = () => {
	const locale = useLocale();
	return useMemo(
		() => [
			getFormattedMessage("days.monday"),
			getFormattedMessage("days.tuesday"),
			getFormattedMessage("days.wednesday"),
			getFormattedMessage("days.thursday"),
			getFormattedMessage("days.friday"),
			getFormattedMessage("days.saturday"),
			getFormattedMessage("days.sunday"),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const useDayOptionsStartFromMonday = () => {
	const weekDays = useDays();
	return useMemo(() => {
		return arrayRotateLeft(
			weekDays.map((e, i) => ({ label: e, value: i })),
			1
		);
	}, [weekDays]);
};

export const useDaysShort = () => {
	const locale = useLocale();
	return useMemo(
		() => [
			getFormattedMessage("daysShort.sunday"),
			getFormattedMessage("daysShort.monday"),
			getFormattedMessage("daysShort.tuesday"),
			getFormattedMessage("daysShort.wednesday"),
			getFormattedMessage("daysShort.thursday"),
			getFormattedMessage("daysShort.friday"),
			getFormattedMessage("daysShort.saturday"),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const useMonths = () => {
	const locale = useLocale();
	return useMemo(
		() => [
			getFormattedMessage("months.january"),
			getFormattedMessage("months.fabruary"),
			getFormattedMessage("months.march"),
			getFormattedMessage("months.april"),
			getFormattedMessage("months.may"),
			getFormattedMessage("months.june"),
			getFormattedMessage("months.july"),
			getFormattedMessage("months.august"),
			getFormattedMessage("months.september"),
			getFormattedMessage("months.october"),
			getFormattedMessage("months.november"),
			getFormattedMessage("months.december"),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const useMonthsShort = () => {
	const locale = useLocale();
	return useMemo(
		() => [
			getFormattedMessage("monthsShort.january"),
			getFormattedMessage("monthsShort.fabruary"),
			getFormattedMessage("monthsShort.march"),
			getFormattedMessage("monthsShort.april"),
			getFormattedMessage("monthsShort.may"),
			getFormattedMessage("monthsShort.june"),
			getFormattedMessage("monthsShort.july"),
			getFormattedMessage("monthsShort.august"),
			getFormattedMessage("monthsShort.september"),
			getFormattedMessage("monthsShort.october"),
			getFormattedMessage("monthsShort.november"),
			getFormattedMessage("monthsShort.december"),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};
