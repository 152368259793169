import { ContentType } from "../../../schemas/questions/contnets/common-schemas";
import {
	IRawQuestionContent,
	IRQuestionContent,
} from "@tests-core/schemas/questions/contnets/schemas";
import {
	FBItemType,
	CheckerType,
} from "@tests-core/schemas/questions/contnets/filling-blanks/schema";

export interface IGradableItemInfo {
	itemId: number;
	whoWillCheck: CheckerType;
}

// not a pure function, unless second argument is not passed
export const getGradableItemsByEditor = (
	content: IRawQuestionContent | IRQuestionContent,
	settings?: { justByEditor: boolean },
	gradableItems: IGradableItemInfo[] = []
): IGradableItemInfo[] => {
	if (content.type === ContentType.FillingBlanks) {
		for (const item of content.items) {
			if (item.type === FBItemType.NonCheckableInput) {
				if (
					settings?.justByEditor &&
					item.whoWillCheck !== CheckerType.Editor
				) {
					continue;
				}
				gradableItems.push({
					itemId: item.id,
					whoWillCheck: item.whoWillCheck,
				});
			}
		}
	}
	if (content.type === ContentType.MultipleContents) {
		for (const item of content.items) {
			if (item.content.type === ContentType.Text) {
				continue;
			}
			getGradableItemsByEditor(item.content, settings, gradableItems);
		}
	}
	return gradableItems;
};
