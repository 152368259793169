import { ObjectId } from "@app/utils/generics";
import {
	ITargetConcept,
	IComplexAssignmentTopic,
} from "@app/api/assignments/complex/helper-schemas";

interface TargetConceptsForAssignment {
	name: string;
}

export const getTargetConceptsForComplexAssignment = (
	targetConceptsId: ObjectId[],
	targetConcepts: ITargetConcept[]
): string => {
	let result = "";
	for (let i = 0; i < targetConceptsId.length; i++) {
		const element = targetConceptsId[i];
		const concept = targetConcepts.find(e => e._id === element);
		if (!concept) continue;
		result =
			result +
			`${concept.name}${i === targetConceptsId.length - 1 ? "" : ", "}`;
	}
	return result;
};

export const getTopicForComplexAssignment = (
	topicId: ObjectId,
	topics: IComplexAssignmentTopic[]
) => {
	const topic = topics.find(e => e._id === topicId);
	return topic ? topic.name : "";
};
