import { IRequest } from "../requests";
import {
	IAPOSTMobileConfirmationCode,
	IAPOSTMobileConfirmationToken,
	IRPOSTMobileConfirmationToken,
	RPOSTMobileConfirmationTokenSchema,
} from "./validators";

export class MobileConfirmationController {
	constructor(private readonly _Request: IRequest) {}

	requestCode = (args: IAPOSTMobileConfirmationCode): Promise<void> => {
		return this._Request.send(
			"POST",
			"/api/mobile-confirmation/code",
			args
		);
	};

	requestToken = (
		args: IAPOSTMobileConfirmationToken
	): Promise<IRPOSTMobileConfirmationToken> => {
		return this._Request.send(
			"POST",
			"/api/mobile-confirmation/token",
			args,
			null,
			{ responseSchema: RPOSTMobileConfirmationTokenSchema }
		);
	};
}
