import React from "react";
import classnames from "classnames";
import genStyles from "./styles/general_styles.module.css";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { NameInput } from "@app/components/auth/input-forms";
import { useBoolean } from "@app/hooks/general";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { useWindowSize } from "@app/hooks/front";

export const ChangePassword: React.FC<{
	onNewPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onRepeatedNewPasswordChange: (
		e: React.ChangeEvent<HTMLInputElement>
	) => void;
}> = ({ onNewPasswordChange, onRepeatedNewPasswordChange }) => {
	const {
		value: isInputsVissible,
		switchValue: switchInputVissibility,
	} = useBoolean(false);
	const { width } = useWindowSize();

	return (
		<>
			<h2
				className={classnames(
					genStyles.personal_info_form__title,
					genStyles.arrowSvg
				)}
				style={{ cursor: "pointer" }}
				onClick={switchInputVissibility}
			>
				<FormattedMessage id="changePassword" />
				{isInputsVissible ? (
					<ArrowUpIcon className={genStyles.arrow} />
				) : (
					<ArrowDownIcon className={genStyles.arrow} />
				)}
			</h2>
			{isInputsVissible ? (
				<form className={genStyles.personal_info_form}>
					<section
						className={genStyles.personal_info_form__input_wrapper}
					>
						<section
							className={genStyles.personal_info_form__input}
							style={
								width > 1000
									? { width: "49%" }
									: { width: "100%" }
							}
						>
							<NameInput
								onChange={onNewPasswordChange}
								placeholder={getFormattedMessage(
									"inputs.newPassword"
								)}
								type="text"
								className={genStyles.inputPassw}
								fullWidthInput={{ width: "100%" }}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__input}
							style={
								width > 1000
									? { width: "49%" }
									: { width: "100%", marginBottom: "0" }
							}
						>
							<NameInput
								onChange={onRepeatedNewPasswordChange}
								placeholder={getFormattedMessage(
									"inputs.repeatPassword"
								)}
								type="text"
								className={genStyles.inputPassw}
								fullWidthInput={{ width: "100%" }}
							/>
						</section>
					</section>
				</form>
			) : (
				<section className={genStyles.emptyContainer}></section>
			)}
		</>
	);
};
