import { CourseType } from "@app/api/courses-info/helper-schemas";
import { Classroom } from "@app/models/classroom";
import { StudentInfo } from "@app/models/student-info";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import {
	useModelDocById,
	useModelDocsByIds,
	useModelDocsByQuery,
} from "m-model-react";
import { useEffect, useMemo } from "react";
import { ResourceLoading } from "react-fetch-data-hooks/lib/data";
import { useResourceLoading } from "./fetch";
import { useStoreProperty } from "./store";
import { useClassroomsUserId, useStudentInfo } from "./users";

export function useClassroom(classroomId: ObjectId | null) {
	const classroom = useModelDocById(inject("ClassroomModel"), classroomId);

	return useResourceLoading(
		classroom,
		() => inject("ClassroomsController").getById({ _id: classroomId! }),
		[classroomId]
	);
}

export const useClassroomByCourseId = (
	courseId: ObjectId | null,
	studentInfo?: StudentInfo
): ResourceLoading<{ doc: Classroom }, undefined> => {
	let userInfo = useStudentInfo();
	userInfo = studentInfo || userInfo;
	const classroomsObj = useStoreProperty("classrooms");
	const classroomId = useMemo((): ObjectId | null | undefined => {
		if (!userInfo) return undefined;
		const ClassroomModel = inject("ClassroomModel");
		const classrooms = ClassroomModel.findManyByIdsSync(
			userInfo.classroomIds
		);
		for (const classroom of classrooms) {
			if (classroom.course.courseId === courseId) {
				return classroom._id;
			}
		}
		if (classrooms.length !== userInfo.classroomIds.length) {
			// has not found all
			return null;
		}
		if (classroomsObj) {
		}
		return undefined;
	}, [courseId, userInfo, classroomsObj]);
	const classroom = useClassroom(
		classroomId === undefined ? null : classroomId || ""
	);
	if (classroomId === undefined) {
		return {
			doc: undefined,
			isSuccessfullyLoaded: false,
			hasFoundError: false,
			isIdentificationKnown: false,
			loadAgain: undefined,
		};
	}
	return classroom as ResourceLoading<{ doc: Classroom }, any>;
};

export const useMyClassroomsAsTeacher = (onlyOrdinaryClassrooms?: boolean) => {
	const userId = useClassroomsUserId();
	const query = useMemo(
		() =>
			userId
				? {
						teacherId: userId,
				  }
				: null,
		[userId]
	);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return onlyOrdinaryClassrooms
		? docs?.filter(e => e.course.type !== CourseType.cognitive)
		: docs;
};

export const useMyClassroomsAsStudent = (onlyOrdinaryClassrooms?: boolean) => {
	const userId = useClassroomsUserId();
	const query = useMemo(() => (userId ? {} : null), [userId]);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return onlyOrdinaryClassrooms
		? docs?.filter(e => e.course.type !== CourseType.cognitive)
		: docs;
};

export const useMyCognitiveClassroomsAsStudent = () => {
	const userId = useClassroomsUserId();
	const query = useMemo(() => (userId ? {} : null), [userId]);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return docs?.filter(e => e.course.type === CourseType.cognitive);
};

export function useClassroomsByIds(classroomIds: ObjectId[] | null) {
	const classrooms = useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds
	);

	return useResourceLoading(
		classrooms,
		() =>
			inject("ClassroomsController").getManyByIds({ ids: classroomIds! }),
		[classroomIds]
	);
}

/**
 * endpoint which is used by useClassroomsByIds hook filters classrooms if user making request can not access it
 * this hook returns all classrooms without filtering
 */
export function useClassroomsByIdsWithourFiltering(
	classroomIds: ObjectId[] | null
) {
	const classrooms = useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds
	);

	return useResourceLoading(
		classrooms,
		() =>
			inject("ClassroomsController").getManyByIdsWithoutFiltering({
				ids: classroomIds!,
			}),
		[classroomIds]
	);
}

export const useGeneratedClassroomSlug = (
	classroomId: ObjectId
): undefined | string => {
	const classroom = useClassroom(classroomId);

	const isClassroomLoaded = classroom.isSuccessfullyLoaded;
	const hasSlug = !!classroom.doc?.slug;

	useEffect(() => {
		if (!isClassroomLoaded || hasSlug) return;
		inject("ClassroomsController").getClassroomSlug({
			classroomId,
		});
	}, [classroomId, hasSlug, isClassroomLoaded]);

	return classroom.doc?.slug;
};
