import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "../cognitive-tests/styles/cognitive-assignments.module.css";
import { studentLinks } from "@app/routes/student/links";
import triggerEvent from "@app/utils/events";
import LupiAdLogo from "../subjects/img/LupiLogoHorizontalGEO.svg";
import { StudentBox } from "../boxes";

export const OffersBox = () => {
	return (
		<Link to={studentLinks.offsers()} className={styles.boxContainer}>
			<img
				alt="buld"
				src="/imgs/offerings.svg"
				className={styles.sendCognitiveAssignmentBuld}
			/>
			<div className={styles.boxContainer_title}>შეთავაზებები</div>
		</Link>
	);
};

export const LupiOffersBox = () => {
	const onClick = useCallback(() => {
		const link = "https://bit.ly/3CaburT";
		triggerEvent({
			category: "Offer",
			action: "Lupi 150GEL",
			label: "offer",
		});
		window.open(link, "__blank");
	}, []);

	return (
		<div className={styles.boxContainer} onClick={onClick}>
			{/* <StudentBox.NewsLabel>იჩქარე</StudentBox.NewsLabel> */}
			<img
				alt="Lupi"
				src={LupiAdLogo}
				className={styles.sendCognitiveAssignmentBuld}
				style={{ width: 200 }}
			/>
			<div className={styles.boxContainer_title}>150₾ დაფინანსება</div>
		</div>
	);
};
