import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { css } from "emotion";

export interface IItemAfterLabelProps {
	id: number;
	onDelete: (id: number) => void;
	deleteText: string;
}

const itemAfterLableClassName = css`
	display: inline-flex;
`;

export const ItemAfterLabel: React.FC<IItemAfterLabelProps> = React.memo(
	props => (
		<div className={itemAfterLableClassName}>
			<Tooltip title={props.deleteText}>
				<CloseIcon
					onClick={() => props.onDelete(props.id)}
					style={{ cursor: "pointer" }}
				/>
			</Tooltip>
		</div>
	)
);
