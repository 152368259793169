import Joi from "@tests-core/utils/joi";

export const RPOSTUploadMediaSchema = Joi.object().keys({
	files: Joi.array()
		.items({
			fileName: Joi.string().required(),
			fileFullUrl: Joi.string().required(),
			mimetype: Joi.string().required(),
			fileOriginalName: Joi.string(),
		})
		.required(),
});
export interface IRPOSTUploadMedia {
	files: {
		fileName: string;
		fileOriginalName?: string;
		fileFullUrl: string;
		mimetype: string;
	}[];
}
export const RPOSTGetMediaSchema = Joi.object().keys({
	files: Joi.array()
		.items(
			Joi.object({
				path: Joi.string().required(),
				mimetype: Joi.string()
					.allow(null)
					.required(),
			})
		)
		.required(),
});
export interface IRPOSTGetMedia {
	files: {
		path: string;
		mimetype: string | null;
	}[];
}
