import { useWindowSize } from "@app/hooks/front";
import { classNamed } from "@pckgs/classed-components";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

export const FinalsTitle = classNamed("h2")(
	"text-primary text-center sm:text-2xl text-elg font-bold font-roboto-geo-mt-bold mb-4 mt-4 sm:mt-8 sm:mb-8"
);

export const UppercaseTitleGray = classNamed("h2")(
	"text-primary-dark sm:text-2xl text-elg font-roboto-geo-mt-bold mb-4 mt-4 sm:mt-8 sm:mb-8"
);
export const UppercaseTitle = classNamed("h3")(
	"sm:text-xl text-lg font-roboto-geo-mt-bold mb-4 mt-4 sm:mt-8 sm:mb-8"
);
export const PrimaryUppercaseText = classNamed("p")(
	"sm:text-xl text-lg font-roboto-geo-caps mb-4 mt-4 sm:mt-8 sm:mb-8"
);
export const SecondaryUppercaseText = classNamed("p")(
	"md:text-base text-sm font-roboto-geo-caps mt-2"
);

export const Description = classNamed("p")(
	"font-roboto-geo-nus my-e5 text-gray-800 text-elg"
);

export const InfoContainer = classNamed("section")(
	"flex items-center bg-transparent w-full my-8 mx-auto"
);

export const GreenBlock = classNamed("span")(
	"w-8 h-16 bg-success rounded-xl mr-4 flex items-center justify-center"
);

export const HeightLessGreenBlock = GreenBlock.extendClassNamed.delete("h-16");

export const WeeklyTestGoBack = React.memo<{ onClick?: () => void }>(
	function WeeklyTestGoBack({ onClick }) {
		const history = useHistory();

		const onGotoBack = useCallback(() => {
			if (onClick) onClick();
			else {
				history.goBack();
			}
		}, [history, onClick]);

		const { width } = useWindowSize();

		if (width < 600) {
			return null;
		}

		return (
			<div
				className="flex fixed mb-10 ml-7-5 left-none bottom-none items-center outline-none cursor-pointer justify-between font-roboto-geo-nus text-gray-700"
				onClick={onGotoBack}
			>
				<button className="VM w-10 h-10 flex bg-transparent duration-200 border-solid border border-gray-700 cursor-pointer font-roboto-geo-nus mr-2 rounded-2xl focus:outline-none hover:bg-gray-700 hover:text-white">
					<ArrowLeftIcon style={{ fontSize: 30 }} />
				</button>
				<div>უკან</div>
			</div>
		);
	}
);
