import Joi from "@app/utils/joi";
import { RHierarchySchema, IRHierarchy } from "../courses/helper-schemas";
import {
	QuestionStatsSchema,
	CardStatsSchema,
	ICardStats,
} from "../folders/user-folder-information/helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const TopicSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseId: Joi.objectId().required(),
	name: Joi.string().required(),
	author: Joi.number().required(),
});

export interface ITopic {
	_id: ObjectId;
	originalCourseId: ObjectId;
	name: string;
	author: number;
}

///

export const TopicHierarchySchema = RHierarchySchema.keys({
	prerequisiteInfo: Joi.object()
		.pattern(/^[a-f\d]{24}$/i, Joi.array().items(Joi.objectId()))
		.required(),
});
export type ITopicHierarchy = IRHierarchy & {
	prerequisiteInfo: Record<string, ObjectId[] | undefined>;
};

///

export const TopicItemsSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	topicId: Joi.objectId().required(),
	questions: Joi.array().items(Joi.objectId()),
	cards: Joi.array().items(Joi.objectId()),
	texts: Joi.array().items(Joi.objectId()),
});
export interface ITopicItems {
	_id: ObjectId;
	courseId: ObjectId;
	topicId: ObjectId;
	questions?: ObjectId[];
	cards?: ObjectId[];
	texts?: ObjectId[];
}

///

export const TopicLevelsSchema = Joi.object().pattern(/^[a-f\d]{24}$/i, {
	questionsLevel: Joi.number(),
	cardsLevel: Joi.number(),
	totalLevel: Joi.number().required(),
});
export interface ITopicLevels {
	[x: string]:
		| {
				totalLevel: number;
				questionsLevel?: number;
				cardsLevel?: number;
		  }
		| undefined;
}

export const UserTopicLevelSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	courseId: Joi.objectId().required(),
	levelsByTopicId: TopicLevelsSchema.required(),
});
export interface IUserTopicLevel {
	_id: ObjectId;
	userId: number;
	courseId: ObjectId;
	levelsByTopicId: ITopicLevels;
}

///

export const ContentStatsSchema = Joi.object({
	questions: QuestionStatsSchema.required(),
	cards: CardStatsSchema.required(),
});
export interface IContentStats {
	questions: {} & {};
	cards: ICardStats;
}
