import { isHTTPNotFoundException } from "./errors";

export const deepRemoveNullableValues = <T extends Record<any, any>>(
	obj: T
): T => {
	const newObj: Record<any, any> = {};
	Object.keys(obj).forEach(key => {
		if (typeof obj[key] === "object" && obj[key] !== null) {
			newObj[key] = deepRemoveNullableValues(obj[key]);
		} else if (obj[key] !== undefined && obj[key] !== null) {
			newObj[key] = obj[key];
		}
	});
	return newObj as T;
};

export const removeNullableValues = <T extends Record<any, any>>(obj: T): T => {
	const newObj: Record<any, any> = {};
	Object.keys(obj).forEach(key => {
		if (obj[key] !== undefined && obj[key] !== null) {
			newObj[key] = obj[key];
		}
	});
	return newObj as T;
};

export function getFoundNotFound<D extends any>(
	doc: D | null | undefined,
	isLoaded: true
): FoundNotFound<D>;
export function getFoundNotFound<D extends any>(
	doc: D | null | undefined,
	isLoaded: boolean
): FoundNotFound<D> | null;
export function getFoundNotFound<D extends any>(
	doc: D | null | undefined,
	isLoaded: boolean
): FoundNotFound<D> | null {
	if (!isLoaded) return null;
	if (doc !== null && doc !== undefined) {
		return { isFound: true as true, doc: doc };
	}
	return { isFound: false as false, doc: undefined };
}

export function wrapPromiseInFoundNotFound<T>(
	promise: Promise<T>
): Promise<FoundNotFound<T>> {
	return promise
		.then(doc => getFoundNotFound(doc, true))
		.catch(e => {
			if (isHTTPNotFoundException(e)) {
				return getFoundNotFound(undefined, true) as FoundNotFound<T>;
			}
			throw e;
		});
}

export type FoundNotFound<D> =
	| { isFound: true; doc: D }
	| { isFound: false; doc: undefined };
