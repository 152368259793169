import React, { useState, useEffect, useMemo } from "react";
import { ITime } from "@app/api/classrooms/helper-schemas-2";
import styles from "./styles/index.module.css";
import { withZero } from "@app/utils/dates";
import color from "@material-ui/core/colors/amber";

const KICK_IN_SECONDS_AFTER = 5;
const SECOND = 1000;
const MINUTE = SECOND * 60;

interface ICountDownProps {
	time: ITime;
	// called when countdown reaches 0:0:0
	onTimeRunOut: () => void;
	containerStyles?: React.CSSProperties;
	fontSize?: number;
	color?: string;
}

type CountDownState = ITime & { seconds: number };

export const CountDown: React.FC<ICountDownProps> = ({
	time,
	onTimeRunOut,
	fontSize,
	color,
	containerStyles,
}) => {
	const [value, setValue] = useState<CountDownState>(() =>
		time.hour === 0 && time.minute <= KICK_IN_SECONDS_AFTER
			? { hour: 0, minute: time.minute - 1, seconds: 60 }
			: {
					...time,
					seconds: 0,
			  }
	);
	const [finished, setFinished] = useState(false);
	const [secondsMode, setSecondMode] = useState(
		time.hour === 0 && time.minute <= KICK_IN_SECONDS_AFTER
	);

	useEffect(() => {
		if (!secondsMode) {
			if (value.hour === 0 && value.minute === KICK_IN_SECONDS_AFTER) {
				setValue({ hour: value.hour, minute: 4, seconds: 60 });
				setSecondMode(true);
			}
		}
		if (!finished) {
			if (value.hour === 0 && value.minute === 0 && value.seconds === 0) {
				setValue({ hour: 0, minute: 0, seconds: 0 });
				setFinished(true);
				onTimeRunOut();
			}
		}
	}, [
		finished,
		onTimeRunOut,
		secondsMode,
		value.hour,
		value.minute,
		value.seconds,
	]);

	useEffect(() => {
		if (secondsMode) return;
		const inetval = setInterval(() => {
			if (finished || secondsMode) clearInterval(inetval);
			setValue(tm => {
				if (tm.minute === 1 && tm.hour === 0) {
					return { ...tm, minute: 0, hour: 0 };
				}
				if (tm.minute === 1 && tm.hour === 1) {
					return { ...tm, minute: 60, hour: 0 };
				}
				if (tm.minute === 0) {
					return { ...tm, hour: tm.hour - 1, minute: 60 };
				}
				return { ...tm, minute: tm.minute - 1 };
			});
		}, MINUTE);
		return () => clearInterval(inetval);
	}, [finished, secondsMode]);

	useEffect(() => {
		if (!secondsMode) return;
		const inetval = setInterval(() => {
			if (finished) {
				clearInterval(inetval);
				return;
			}
			setValue(tm => {
				const { minute, seconds, hour } = tm;
				if (minute === 0 && hour === 0 && seconds === 1) {
					return { seconds: 0, minute: 0, hour: 0 };
				}
				if (seconds === 1 && minute === 0) {
					return { hour: hour - 1, minute: 59, seconds: 60 };
				}
				if (seconds === 1) {
					return { hour, minute: minute - 1, seconds: 60 };
				}

				return { ...tm, seconds: seconds - 1 };
			});
		}, SECOND);
		return () => clearInterval(inetval);
	}, [finished, secondsMode]);

	const customStyles = useMemo(() => {
		const res = {};
		if (fontSize) {
			res["fontSize"] = fontSize;
		}
		if (color) {
			res["color"] = color;
		}
		return res;
	}, [color, fontSize]);

	return (
		<div className={styles.countDown} style={containerStyles}>
			<SingleValue value={value.hour} customStyles={customStyles} />
			<Separator customStyles={customStyles} />
			<SingleValue value={value.minute} customStyles={customStyles} />
			{secondsMode && (
				<React.Fragment>
					<Separator customStyles={customStyles} />
					<SingleValue
						value={value.seconds}
						customStyles={customStyles}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

const Separator: React.FC<{ customStyles?: React.CSSProperties }> = React.memo(
	({ customStyles }) => (
		<div className={styles.separator} style={customStyles}>
			:
		</div>
	)
);

const SingleValue: React.FC<{
	value: number;
	customStyles?: React.CSSProperties;
}> = React.memo(({ value, customStyles }) => (
	<div className={styles.group}>
		<div className={styles.number} style={customStyles}>
			{withZero(value)}
		</div>
	</div>
));
