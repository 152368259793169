import { SidebarWhazz } from "@app/components/layouts/sidebar/helpers/interfaces";
import {
	SidebarProviders,
	SidebarTree,
} from "@app/components/layouts/sidebar/tree";
import { getVideoLessonComponent } from "@app/components/layouts/sidebar/video-link";
import { CreateCusto } from "custo";
import React from "react";
import { getStudentSidebarItems } from "./items";

export const StudentSidebar = React.memo<{
	whazz: SidebarWhazz;
}>(({ whazz }) => {
	return (
		<SidebarProviders.PartialMergingProvider
			value={{
				items: CreateCusto.hookOf.Data(getStudentSidebarItems),
			}}
		>
			<SidebarTree.Container whazz={whazz}>
				{getVideoLessonComponent(whazz)}
			</SidebarTree.Container>
		</SidebarProviders.PartialMergingProvider>
	);
});
