import React from "react";
import termStyles from "./styles/terms.module.css";
import { useLocale } from "@app/hooks/intl";
import { DutchTerms } from "./dutch-terms";
import { EngTerms } from "./eng-terms";
import { GeoTermsContent } from "./geo-terms";

export const Terms = () => {
	const isGeo = useLocale() === "ka";
	const isDutch = useLocale() === "nl";
	return (
		<>{isGeo ? <GeoTerms /> : isDutch ? <DutchTerms /> : <EngTerms />}</>
	);
};

export const GeoTerms = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.title}>
					ვებგვერდის გამოყენების წესები და პირობები
				</h1>
				<GeoTermsContent />
			</div>
		</>
	);
};
