import { ITeacherPermissions } from "@app/api/users/helper-schemas";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds } from "./interfaces";
import { UserType } from "@app/api/helper-schemas";
import { isNonNullable } from "@app/utils/common";

export class TeacherPermissions extends UserPermissions {
	constructor(private readonly permissions: ITeacherPermissions) {
		super(UserType.teacher);
	}

	canAccessGroup(groupId: ObjectId): boolean {
		return this.permissions.accessibleGroups.some(id => id === groupId);
	}

	getOwnClassrooms(): ObjectId[] {
		return this.permissions.accessibleClassrooms;
	}

	getAvailableClassrooms(): ObjectId[] {
		return this.getOwnClassrooms();
	}

	getOwnGroups(): ObjectId[] {
		return this.permissions.accessibleGroups;
	}

	getOwnLabelIds = () => {
		return [];
	};
	getViewableLabelIds = () => {
		const ClassroomModel = inject("ClassroomModel");
		const classrooms = this.getOwnClassrooms().map(id =>
			ClassroomModel.findByIdSync(id)
		);
		const foundClassrooms = classrooms.filter(isNonNullable);
		const isReady = foundClassrooms.length > 0 || classrooms.length === 0;
		if (!isReady) return null;
		return foundClassrooms
			.map(cl => cl.schoolLabelId)
			.filter(isNonNullable);
	};

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(classroomId: ObjectId): boolean {
		return this.permissions.accessibleClassrooms.some(
			id => id === classroomId
		);
	}

	canStudyInClassroom(classroomId: ObjectId): boolean {
		return this.canAccessClassroom(classroomId);
	}

	hasConfirmedChild(): boolean {
		return false;
	}

	getConfirmedChildrenIds(): number[] {
		return [];
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		const classroomIds = this.permissions.accessibleClassrooms;
		const ClassroomModel = inject("ClassroomModel");
		const classrooms = ClassroomModel.findManyByIdsSync(classroomIds);
		if (classrooms.length !== classroomIds.length) {
			return {
				isKnown: false,
			};
		}
		return {
			isKnown: true,
			hasAll: false,
			ids: classrooms.map(classroom => classroom.course.courseId),
		};
	}
}
