/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import { UserType } from "@app/api/helper-schemas";
import { WeekType } from "@app/api/weekly-tests/helper-schemas";
import { WebsiteOrigin } from "@app/globals";
import UserRoute from "@app/routes/containers/user-route";
import { createRouteList } from "../config";
import { CommonRouteProps, defaultRouteMapper } from "../route-props-map-utils";
import { defaultComponentWrapper } from "../wrapper";
import { studentLinks } from "./links";

const routes = createRouteList<CommonRouteProps>({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.student,
		availableWebsite: WebsiteOrigin.tvSchool,
	},
	componentWrapper: defaultComponentWrapper,
	routeMapFn: defaultRouteMapper,
});

///

const Weeklies = () => import("../../components/users/subjects/Weeklies");

routes.add({
	path: studentLinks.weeklies.weeksList.routePath,
	component: Weeklies,
});

///

const MyAssignments = () =>
	import("../../components/users/assignments/tv-school/assignments").then(
		module => ({
			default: module.MyAssignmentsAsStudent,
		})
	);

routes.add({
	path: studentLinks.classrooms.assignments.list.routePath,
	component: MyAssignments,
	headerTheme: "light",
	showSidebar: true,
});

///
const FetchStudentAssignment = () =>
	import("../../components/users/tests/assignment").then(module => ({
		default: module.FetchStudentAssignment,
	}));

routes.add({
	path: studentLinks.classrooms.assignments.oldView.routePath,
	component: FetchStudentAssignment,
});

///
const StudentGradebook = () =>
	import("../../components/users/gardebook").then(module => ({
		default: module.StudentGradebookAsStudent,
	}));

routes.add({
	path: studentLinks.classrooms.gradebook.routePath,
	component: StudentGradebook,
	headerTheme: "light",
	showSidebar: true,
});

///

const StudentSemesterbook = () =>
	import("../../components/teachers/semesterbook/viewer-types/index").then(
		module => ({
			default: module.SemesterbookAsStudent,
		})
	);

routes.add({
	path: studentLinks.classrooms.semesterbook.routePath,
	component: StudentSemesterbook,
	headerTheme: "light",
	showSidebar: true,
});

///
const MyCognitiveTestsContainer = () =>
	import("../../components/users/cognitive-tests/my-tests-container").then(
		module => ({
			default: module.MyCognitiveTestsContainer,
		})
	);

routes.add({
	path: studentLinks.cognitiveTests.routePath,
	component: MyCognitiveTestsContainer,
});

///

const OffersPage = () => import("../../components/users/offers");

routes.add({
	path: studentLinks.offsers.routePath,
	component: OffersPage,
});

///

const StudentPersonalTimetablePage = () =>
	import("../../components/users/timetables").then(module => ({
		default: module.StudentPersonalTimetablePage,
	}));

routes.add({
	path: studentLinks.timetable.routePath,
	component: StudentPersonalTimetablePage,
	headerTheme: "light",
});

///

const FinalsChooseSubject = () =>
	import("../../components/users/finals/choose-subject").then(module => ({
		default: module.FinalsChooseSubject,
	}));

routes.add({
	path: studentLinks.weeklies.finals.chooseSubjects.routePath,
	component: FinalsChooseSubject,
	routeProps: {
		allowUnauthenticated: true,
		redirectIfNotAuthorizedUserType: false,
	},
});

/// Test Routes
// const Awards = () => import("../../components/users/Awards/Awards");

// tvSchoolStudentRoutes.add({
// 	path: "/testroute",
// 	component: Awards,
// 	routeProps: {
// 		allowUnauthenticated: true,
// 		redirectIfNotAuthorizedUserType: false,
// 	},
// });

/// End Test Routes

///

const FinalsNewChooseSubject = () =>
	import("../../components/users/finals/choose-subject-v2").then(module => ({
		default: module.FinalsNewChooseSubject,
	}));

routes.add({
	path: studentLinks.weeklies.finals.newChooseSubjects.routePath,
	component: FinalsNewChooseSubject,
});

///

const YearConclusiveTest = () =>
	import("../../components/users/finals/parent").then(module => ({
		default: module.FinalsFillParentInfo,
	}));

routes.add({
	path: studentLinks.weeklies.finals.parentInfo.routePath,
	component: YearConclusiveTest,
});

///

const FinalsRservationFinishPage = () =>
	import("../../components/users/finals/reservation-finish").then(module => ({
		default: module.FinalsRservationFinishPage,
	}));

routes.add({
	path: studentLinks.weeklies.finals.reservationFinish.routePath,
	component: FinalsRservationFinishPage,
});

///

const FinalsLandingPage = () =>
	import("../../components/users/finals").then(module => ({
		default: module.FinalsLandingPage,
	}));

const FinalsLandingPageOfOtherUsers = () =>
	import("../../components/users/finals/landing-of-other-user").then(
		module => ({
			default: module.FinalsLandingPageOfOtherUsers,
		})
	);

routes.add({
	path: studentLinks.weeklies.finals.landing.routePath,
	component: FinalsLandingPage,
	routeProps: {
		allowUnauthenticated: true,
		redirectIfNotAuthorizedUserType: false,
		availableUserTypes: [UserType.student],
		ComponentForNonAvailableUserTypes: FinalsLandingPageOfOtherUsers,
	},
	headerTheme: "light",
});

///

const NewFinalsLandingPage = () =>
	import("../../components/users/finals/new-landing").then(module => ({
		default: module.NewFinalsLandingPage,
	}));

routes.add({
	path: studentLinks.weeklies.finals.newLanding.routePath,
	component: NewFinalsLandingPage,
	routeProps: {
		allowUnauthenticated: true,
		redirectIfNotAuthorizedUserType: false,
		availableUserTypes: [UserType.student],
		ComponentForNonAvailableUserTypes: FinalsLandingPageOfOtherUsers,
	},
});

///

const WeeklyTestsCoursesList = () =>
	import("../../components/users/finals/courses/list").then(module => ({
		default: module.WeeklyTestsCoursesList,
	}));

routes.add({
	path: studentLinks.weeklies.finals.courses.list.routePath,
	component: WeeklyTestsCoursesList,
	componentProps: {
		weekType: WeekType.finals,
	},
});

routes.add({
	path: studentLinks.weeklies.progress.courses.list.routePath,
	component: WeeklyTestsCoursesList,
	componentProps: {
		weekType: WeekType.progress,
	},
});

///

const PaymentInstruction = () =>
	import("../../components/users/finals/payment-instruction").then(
		module => ({
			default: module.PaymentInstruction,
		})
	);

routes.add({
	path: studentLinks.weeklies.payment.info.routePath,
	component: PaymentInstruction,
});

///

const WeeklyTestsList = () =>
	import("../../components/users/finals/courses/tests-list").then(module => ({
		default: module.WeeklyTestsList,
	}));

routes.add({
	path: studentLinks.weeklies.finals.courses.testsList.routePath,
	component: WeeklyTestsList,
	componentProps: {
		weekType: WeekType.finals,
	},
});

routes.add({
	path: studentLinks.weeklies.progress.courses.testsList.routePath,
	component: WeeklyTestsList,
	componentProps: {
		weekType: WeekType.progress,
	},
});

///

const WeeklyTestsReport = () =>
	import("../../components/users/finals/courses/analysis/report").then(
		module => ({
			default: module.WeeklyTestsReport,
		})
	);

routes.add({
	path: studentLinks.weeklies.finals.courses.report.routePath,
	component: WeeklyTestsReport,
	componentProps: {
		weekType: WeekType.finals,
	},
	headerTheme: "light",
});

routes.add({
	path: studentLinks.weeklies.progress.courses.report.routePath,
	component: WeeklyTestsReport,
	componentProps: {
		weekType: WeekType.progress,
	},
	headerTheme: "light",
});

///

const WeeklyTestsProgress = () =>
	import("../../components/users/finals/progress-week/progress-bar").then(
		module => ({
			default: module.ProgresBarPage,
		})
	);

routes.add({
	path: studentLinks.weeklies.progress.courses.progress.routePath,
	component: WeeklyTestsProgress,
	componentProps: {
		weekType: WeekType.progress,
		previousWeekType: WeekType.finals,
	},
	headerTheme: "light",
});

///

const WeeklyTestsDetailedReport = () =>
	import(
		"../../components/users/finals/courses/analysis/detailed-report"
	).then(module => ({
		default: module.WeeklyTestsDetailedReport,
	}));

routes.add({
	path: studentLinks.weeklies.finals.courses.detailedReport.routePath,
	component: WeeklyTestsDetailedReport,
});

///

const WeeklyTestsRecommendations = () =>
	import(
		"../../components/users/finals/courses/analysis/recommendations"
	).then(module => ({
		default: module.WeeklyTestsRecommendations,
	}));

routes.add({
	path: studentLinks.weeklies.finals.courses.recommendations.routePath,
	component: WeeklyTestsRecommendations,
});

///

const WeeklyTestPage = () =>
	import("../../components/users/finals/courses/test").then(module => ({
		default: module.WeeklyTestPage,
	}));

routes.add({
	path: studentLinks.weeklies.finals.courses.test.routePath,
	component: WeeklyTestPage,
	componentProps: {
		weekType: WeekType.finals,
	},
});

routes.add({
	path: studentLinks.weeklies.progress.courses.test.routePath,
	component: WeeklyTestPage,
	componentProps: {
		weekType: WeekType.progress,
	},
});

///

const PaymentSuccess = () =>
	import("../../components/users/payment/popups").then(module => ({
		default: module.PaymentSuccess,
	}));

routes.add({
	path: studentLinks.weeklies.payment.success.routePath,
	component: PaymentSuccess,
});

///

const PaymentFailure = () =>
	import("../../components/users/payment/popups").then(module => ({
		default: module.PaymentFailure,
	}));

routes.add({
	path: studentLinks.weeklies.payment.failure.routePath,
	component: PaymentFailure,
});

///

const WeeklyTestCerticficates = () =>
	import("../../components/users/finals/certificate").then(module => ({
		default: module.CertificatesPage,
	}));

routes.add({
	path: studentLinks.weeklies.certificates.routePath,
	component: WeeklyTestCerticficates,
});

///

const ProgressWeekLanding = () =>
	import("../../components/users/finals/progress-week/landing").then(
		module => ({
			default: module.LandingPage,
		})
	);

routes.add({
	path: studentLinks.weeklies.progress.landing.routePath,
	component: ProgressWeekLanding,
	routeProps: {
		allowUnauthenticated: true,
		redirectIfNotAuthorizedUserType: false,
		availableUserTypes: [UserType.student],
		ComponentForNonAvailableUserTypes: FinalsLandingPageOfOtherUsers,
	},
	headerTheme: "light",
});

///

const ProgressWeekRegistrationPage = () =>
	import("../../components/users/finals/progress-week/registration").then(
		module => ({
			default: module.RegistrationPage,
		})
	);

routes.add({
	path: studentLinks.weeklies.progress.reservation.routePath,
	component: ProgressWeekRegistrationPage,
});

///

const FinishReservationPage = () =>
	import("../../components/users/finals/progress-week/finish").then(
		module => ({
			default: module.FinishReservation,
		})
	);

routes.add({
	path: studentLinks.weeklies.progress.reservationFinish.routePath,
	component: FinishReservationPage,
});

///

const AwardsPage = () =>
	import("../../components/users/finals/progress-week/awards").then(
		module => ({
			default: module.AwardsPage,
		})
	);

routes.add({
	path: studentLinks.weeklies.progress.awards.routePath,
	component: AwardsPage,
});

///

const V2PopupPage = () =>
	import("../../components/users/finals/v2").then(module => ({
		default: module.V2PopupPage,
	}));

routes.add({
	path: studentLinks.v2Popup.routePath,
	component: V2PopupPage,
});

///

const NewArchivedClassroomsContainer = () =>
	import("../../components/teachers/main/tvschool/classrooms").then(
		module => ({
			default: module.StudentsClassroomsArchivePage,
		})
	);

routes.add({
	path: studentLinks.archived.classrooms.routePath,
	component: NewArchivedClassroomsContainer,
});

///

export default routes;
