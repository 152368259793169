import { classNamed } from "@pckgs/classed-components";
import styles from "../subjects/styles/user-subject-list.module.css";

const Container = classNamed("div")(
	styles.userSubjectListBox,
	styles.noPaddings
);

const NewsLabel = classNamed("span")(
	"absolute bg-green-400 font-normal font-roboto-geo-nus text-xs py-1 px-4 rounded-2xl text-white"
).extendStyled({ top: "1rem", right: "1rem", letterSpacing: "1px" });

const Link = classNamed("h2")(styles.adTitle);

const Title = classNamed("p")(styles.awardText);

export const StudentBox = {
	Container,
	Link,
	NewsLabel,
	Title,
};
