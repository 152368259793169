import Joi from "@app/utils/joi";
import {
	CourseInfoSchema,
	ICourseInfo,
	CourseSettingsSchema,
	ICourseSettings,
	CourseTypes,
	CourseType,
} from "./helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
	markKeysOptional,
} from "../helper-schemas";

export const AGETCourseInfoSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETCourseInfo {
	courseId: ObjectId;
}

export const RGETCourseInfoSchema = CourseInfoSchema;
export type IRGETCourseInfo = ICourseInfo;

///

export const AGETCourseSettingsSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETCourseSettings {
	courseId: ObjectId;
}

export const RGETCourseSettingsSchema = CourseSettingsSchema;
export type IRGETCourseSettings = ICourseSettings;

///

export const AGETAllCoursesInfoSchema = Joi.object({
	grade: Joi.number()
		.integer()
		.allow(null),
	subject: Joi.objectId()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	language: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	country: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	website: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
});

export interface IAGETAllCoursesInfo {
	grade?: number | null;
	subject?: ObjectId | null;
	language?: string | null;
	country?: string | null;
	website?: string | null;
}

export const RGETAllCoursesInfoSchema = Joi.array().items(CourseInfoSchema);
export type IRGETAllCoursesInfo = ICourseInfo[];

///

export const APOSTCourseInfoAndSettingsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	courseInfo: CourseInfoSchema.keys(insertStripKeys)
		.fork("courseId", markKeysOptional)
		.required(),
	settings: CourseSettingsSchema.keys(insertStripKeys)
		.fork("courseId", markKeysOptional)
		.required(),
	type: Joi.string()
		.valid(...CourseTypes)
		.optional(),
});
export interface IAPOSTCourseInfoAndSettings {
	courseId: ObjectId;
	courseInfo: ToInsertKeys<ICourseInfo, never, "courseId">;
	settings: ToInsertKeys<ICourseSettings, never, "courseId">;
	type?: CourseType;
}

export const RPOSTCourseInfoAndSettingsSchema = Joi.object({
	courseInfo: CourseInfoSchema.required(),
	settings: CourseSettingsSchema.required(),
});
export interface IRPOSTCourseInfoAndSettings {
	courseInfo: ICourseInfo;
	settings: ICourseSettings;
}

///

export const APUTCourseInfoAndSettingsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	courseInfo: CourseInfoSchema.keys(updateStripKeys)
		.fork("courseId", markKeysOptional)
		.required(),
	settings: CourseSettingsSchema.keys(updateStripKeys)
		.fork("courseId", markKeysOptional)
		.required(),
	type: Joi.string()
		.valid(...CourseTypes)
		.optional(),
});

export interface IAPUTCourseInfoAndSettings {
	courseId: ObjectId;
	courseInfo: ToUpdateKeys<ICourseInfo, never, "_id" | "courseId">;
	settings: ToUpdateKeys<ICourseSettings, never, "_id" | "courseId">;
	type?: CourseType;
}

export const RPUTCourseInfoAndSettingsSchema = RPOSTCourseInfoAndSettingsSchema;
export type IRPUTCourseInfoAndSettings = IRPOSTCourseInfoAndSettings;
