import Joi from "@app/utils/joi";

export const TimeSchema = Joi.object({
	hour: Joi.number()
		.integer()
		.greater(-1)
		.less(24)
		.required(),
	minute: Joi.number()
		.integer()
		.greater(-1)
		.less(59)
		.required(),
});
export interface ITime {
	hour: number;
	minute: number;
}

export const ClassTimeSchema = Joi.object({
	day: Joi.number()
		.integer()
		.greater(-1)
		.less(7)
		.required(),
	start: TimeSchema.required(),
	end: TimeSchema.required(),
});
export interface IClassTime {
	day: number;
	start: ITime;
	end: ITime;
}
