import Joi from "@app/utils/joi";
import { itemTypes, ItemType } from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APUTFolderlessItemSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.string()
		.valid(...itemTypes)
		.required(),
});
export interface IAPUTFolderlessItem {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}

export const RPUTFolderlessItemSchema = Joi.object({
	foundInHierarchy: Joi.boolean().required(),
	inserted: Joi.boolean().required(),
});
export interface IRPUTFolderlessItem {
	foundInHierarchy: boolean;
	inserted: boolean;
}
