import { ItemType } from "@app/api/folders/helper-schemas";
import {
	ITestSettings,
	ITestSettingContentId,
} from "@app/api/tests/helper-schemas";
import { arrayToObject } from "@app/utils/common";
import { IText } from "@tests-core/schemas/texts/helper-schemas";

export const getDecomposedContentIds = (
	contentIds: ITestSettingContentId[],
	texts: IText[]
): ITestSettingContentId[] => {
	const newContentIds: ITestSettings["contentIds"] = [];
	const textWithIds = arrayToObject(texts, "_id");
	for (const content of contentIds) {
		if (
			content.type === ItemType.question ||
			content.type === ItemType.card
		) {
			newContentIds.push(content);
		} else if (content.type === ItemType.text) {
			const text = textWithIds[content.id];
			if (!text) continue;
			for (const qId of text.questions) {
				newContentIds.push({
					id: qId,
					type: ItemType.question,
				});
			}
		}
	}
	return newContentIds;
};
