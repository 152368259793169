type IsReservedFn = (key: string) => boolean;

export const clearAllKeysExcept = (
	storage: Storage,
	/** array of (keys that must not be deleted, or functions which return true if key must not be deleted) */
	keys: (string | IsReservedFn)[]
) => {
	const reservedKeys = new Set(
		keys.filter((key): key is string => typeof key === "string")
	);
	const reservedKeyFns = keys.filter(
		(key): key is (key: string) => boolean => typeof key === "function"
	);
	const keysThatShouldBeDeleted = new Set<string>();
	for (let i = 0; i < storage.length; i++) {
		const key = storage.key(i);
		if (key === null) continue;
		if (
			!reservedKeys.has(key) &&
			!reservedKeyFns.some(shouldLeave => shouldLeave(key))
		) {
			keysThatShouldBeDeleted.add(key);
		}
	}
	for (const key of keysThatShouldBeDeleted) {
		storage.removeItem(key);
	}
};

export class StorageDataSaver<Data> {
	private storageKey: string;
	private validationFn: (data: any) => Data;
	private timeoutMs: number;
	private storage: Storage;
	constructor(settings: {
		key: string;
		validationFn?: (data: any) => Data;
		timeoutMs?: number;
		storage?: Storage;
	}) {
		this.storageKey = settings.key;
		this.validationFn = settings.validationFn || ((data: any) => data);
		this.timeoutMs = settings.timeoutMs ?? Infinity;
		this.storage = settings.storage ?? localStorage;
	}

	get(): Data | null {
		const rawDataStr = this.storage.getItem(this.storageKey);
		if (!rawDataStr) return null;
		try {
			const rawData = JSON.parse(rawDataStr) as {
				loadTime: string;
				data: Data;
			};
			const time = new Date(rawData.loadTime);
			const isFreshData = Date.now() - time.getTime() <= this.timeoutMs;
			if (!isFreshData) return null;
			return this.validationFn(rawData.data);
		} catch (e) {
			return null;
		}
	}

	set(data: Data): Data {
		const validatedData = this.validationFn(data);
		const stringifiedValue = JSON.stringify({
			loadTime: new Date(),
			data: validatedData,
		});
		this.storage.setItem(this.storageKey, stringifiedValue);
		return validatedData;
	}

	clear() {
		this.storage.removeItem(this.storageKey);
	}
}
