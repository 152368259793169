/* eslint-disable max-lines-per-function */
import { UserType } from "@app/api/helper-schemas";
import { useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StudentLogin } from "../auth/login";
import { RegistrationForLanding } from "../landing/registration";
import IllustrationEN from "./styles/imgs/illustration-en.png";
import IllustrationNL from "./styles/imgs/illustration-nl.png";
import Illustration from "./styles/imgs/illustration.png";
import styles from "./styles/tv-school-landing.module.css";

export const Role: React.FC<{
	isForgetPassword?: boolean;
	isRegistrationField: boolean;
	isLoginFieldVissible: boolean;
	openLoginPage: React.Dispatch<React.SetStateAction<boolean>>;
	openRegistarionPage: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	isForgetPassword,
	isRegistrationField,
	isLoginFieldVissible,
	openLoginPage,
	openRegistarionPage,
}) => {
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isDutch = locale === "nl";
	const isEng = locale === "en";
	const isDifferentTitle = locale === "nl" || locale === "en";

	const openLogin = useCallback(() => {
		openLoginPage(true);
		openRegistarionPage(false);
	}, [openRegistarionPage, openLoginPage]);

	const openRegisterPage = useCallback(
		(userType?: UserType) => {
			setDefaultSelectedUserType(userType);
			openLoginPage(false);
			openRegistarionPage(true);
		},
		[openRegistarionPage, openLoginPage]
	);

	const [defaultSelectedUserType, setDefaultSelectedUserType] = useState<
		UserType
	>();

	const [isForgotPassword, setForgotPassword] = useState(false);

	const switchForgotPassword = useCallback(e => {
		setForgotPassword(!e);
	}, []);
	const { width } = useWindowSize();
	const isResponsive = width <= 800;

	return (
		<div
			className={
				isGeo
					? styles.roleContainer
					: isDutch
					? styles.roleContainerNL
					: styles.roleContainerEN
			}
		>
			<div className={styles.roleTexts}>
				{isEng && <div className={styles.welcomeText}>स्वागत हे</div>}

				<div className={styles.landingTitle}>
					<h1
						className={styles.landingTitle__h1}
						style={{
							fontFamily: isDifferentTitle
								? "Dosis"
								: "Roboto Geo Caps",
						}}
					>
						<FormattedMessage
							id="signInTitle"
							values={{ br: <br /> }}
						/>
					</h1>
				</div>
				{isLoginFieldVissible && (
					<StudentLogin
						hideRegisterPage={openLogin}
						isRegisterPageOpen={isRegistrationField}
						openRegisterPage={openRegisterPage}
						isRegisterButtonVissible={true}
						isDivTitleHidden={true}
						inputClassname={styles.loginInput}
						forgetPasswordQuestionContainerClassname={
							styles.forgetPassword
						}
						signInButtonClassname={styles.signInButton}
						registerButtonClassname={styles.registerButtonClassname}
						isIconVissible={false}
						isForgotPassword={isForgotPassword}
						switchForgotPassword={switchForgotPassword}
						isFromTvSchoolLanding={isGeo}
					/>
				)}

				{isRegistrationField && (
					<RegistrationForLanding
						userType={defaultSelectedUserType}
						onShowSingin={openLogin}
					/>
				)}
			</div>

			{isResponsive && (
				<img
					src={
						isGeo
							? Illustration
							: isDutch
							? IllustrationNL
							: IllustrationEN
					}
					alt={
						isGeo
							? "Illustration"
							: isDutch
							? "IllustrationNL"
							: "IllustrationEN"
					}
					className={styles.illustration}
				/>
			)}
		</div>
	);
};
