import { User } from "@app/user";

export const isUserEligibleForTryingWeeklyTest = (
	user: User | null | undefined
) => {
	if (!user) return false;
	return isEligibleGradeForWeeklyTests(user.grade);
};

export const isEligibleGradeForWeeklyTests = (
	grade: number | null | undefined
) => {
	if (!grade) return false;
	return grade >= 2 && grade <= 6;
};
