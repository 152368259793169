import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { inject } from "@app/modules";
import { ICertificate } from "@app/api/certificates/helper-schemas";
import { useClassroomsUser } from "@app/hooks/users";
import styles from "./styles/index.module.scss";
import { useWindowSize } from "@app/hooks/front";
import Loading from "@app/components/widgets/raw-loading";
import { ReactComponent as DownloadIcon } from "../../styles/img/icons/Download.svg";
import { ReactComponent as FacebookIcon } from "@app/icons/Facebook.svg";
import styled from "@emotion/styled";
import { useUnsafeDynamicRef } from "@app/hooks/general";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { useTeacherAssignmentsCount } from "@app/hooks/assignments";
import { Link } from "react-router-dom";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";

export const CertificatePopup: React.FC<{
	onClose?: () => void;
	isOpenByDefault?: boolean;
}> = React.memo(({ isOpenByDefault, onClose }) => {
	const [isOpen, setIsOpen] = useState(isOpenByDefault);
	let [isLoading, setIsLoading] = useState(true);
	const [isQualified, setIsQualified] = useState<boolean>();
	const [newName, setNewName] = useState("");
	const [certName, setCertName] = useState("");
	const { width } = useWindowSize();
	const [
		onPopupSuccessfullViewCallback,
		setOnPopupSuccessfullViewCallback,
	] = useState<() => void>();
	const assignmentsCount = useTeacherAssignmentsCount();

	const user = useClassroomsUser();
	const [certificate, setCertificate] = useState<ICertificate | null>(null);

	const downloadRef = useRef<HTMLAnchorElement | null>(null);

	const handleDownload = useCallback(() => {
		downloadRef.current?.click();
	}, []);

	const showSaveButton = useMemo(() => {
		return newName !== certName;
	}, [newName, certName]);

	const isUserLoaded = !!user;
	const userRef = useUnsafeDynamicRef(user);

	useEffect(() => {
		if (!isUserLoaded || !assignmentsCount.doc) {
			return;
		}
		const user = userRef.current!;
		if (
			showTeacherCertificateOnlyForUs &&
			testingTeacherIds.indexOf(user.id) === -1
		) {
			return;
		}

		const loadCertificate = async () => {
			const actionsController = inject("UserActionsController");
			const certificatesController = inject("CertificatesController");
			const certAction = (await actionsController.getUserAction()).actions
				?.certificate;
			const userFullName = user.firstname + " " + user.lastname;

			const isQualified =
				certAction?.isQualified || assignmentsCount.doc.count >= 3;
			setIsQualified(isQualified);

			let seenCountAsQualified =
				certAction?.meta?.seenCountAsQualified || 0;
			const fbShares = certAction?.meta?.fbShares || 0;
			let mustShowPopup = false;
			let changedAction = false;
			if (certAction?.isQualified !== isQualified) {
				changedAction = true;
			}
			if (
				isQualified &&
				((!fbShares && seenCountAsQualified < 3) || isOpenByDefault)
			) {
				mustShowPopup = true;
			} else if (!isQualified && !certAction) {
				mustShowPopup = true;
			}
			if (testingTeacherIds.indexOf(user.id) > -1) {
				mustShowPopup = true;
			}

			if (isQualified && mustShowPopup) {
				seenCountAsQualified++;
				changedAction = true;
			}

			if (mustShowPopup) {
				setIsOpen(true);
			}

			const fnToCallAfterSeeingPopup = async () => {
				console.log("calllled");
				try {
					if (
						certAction?.meta &&
						typeof certAction?.fullName !== "undefined"
					) {
						if (changedAction) {
							await actionsController.performUserAction({
								data: {
									...certAction,
									isQualified,
									meta: {
										...certAction.meta,
										lastPopupOpenDate: new Date().toString(),
										seenCountAsQualified,
									},
								},
								name: "certificate",
							});
						}
						setNewName(certAction.fullName || userFullName);
						setCertName(certAction.fullName || userFullName);
						setCertificate(certAction.certificate);
					} else {
						const certificate = await certificatesController.generateCerificate(
							{
								receiversFullName: userFullName,
							}
						);

						await actionsController.performUserAction({
							data: {
								certificate,
								isQualified,
								fullName: userFullName,
								meta: {
									lastPopupOpenDate: new Date().toString(),
									fbShares: 0,
									seenCountAsQualified,
								},
							},
							name: "certificate",
						});
						setCertificate(certificate);
						setNewName(userFullName);
						setCertName(userFullName);
					}
				} catch (e) {
					onError();
				}
			};

			setOnPopupSuccessfullViewCallback(() => fnToCallAfterSeeingPopup);
		};

		const onError = () => {
			if (!isOpenByDefault) {
				setIsOpen(false);
			} else {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			}
		};

		loadCertificate().catch(onError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLoaded, userRef, assignmentsCount.doc]);

	const handleSave = useCallback(() => {
		const updateCertificate = async () => {
			setIsLoading(true);
			const certificate = await inject(
				"CertificatesController"
			).generateCerificate({
				receiversFullName: newName,
			});

			const userActionsController = inject("UserActionsController");
			const certAction = (await userActionsController.getUserAction())
				.actions?.certificate;

			await userActionsController.performUserAction({
				data: {
					...certAction,
					fullName: newName,
					certificate,
				},
				name: "certificate",
			});
			setCertificate(certificate);
			setCertName(newName);
		};
		updateCertificate();
	}, [newName]);

	const closePopup = useCallback(() => {
		if (onClose) onClose();
		setIsOpen(false);
	}, [onClose]);

	const handleShare = useCallback(() => {
		(window as any).FB.ui(
			{
				display: "popup",
				method: "share",
				href: `${process.env.REACT_APP_BACKEND_SERVER}/api/certificates/?imgPath=${certificate?.fullUrl}`,
			},
			function(response) {
				//
			}
		);
		const updateCertificateMetaInfo = async () => {
			const userActionsController = inject("UserActionsController");
			const certAction = (await userActionsController.getUserAction())
				.actions?.certificate;

			await userActionsController.performUserAction({
				data: {
					...certAction,
					meta: {
						...certAction.meta,
						fbShares: certAction.meta.fbShares + 1,
					},
				},
				name: "certificate",
			});
		};
		updateCertificateMetaInfo();
	}, [certificate]);

	if (!isOpen) return null;

	const isMobile = width < 600;
	if (isQualified === undefined || !certificate) {
		isLoading = true;
	}

	return (
		<DefaultPopupPrioritizer
			priority={320}
			enabled={isOpen}
			onShow={onPopupSuccessfullViewCallback}
		>
			<Popup>
				<PopupContentWithClose onClose={closePopup}>
					{isLoading && <Loading />}
					<div
						style={{
							width: isMobile
								? `${(width * 9) / 10}px`
								: `${(width * 3) / 4}px`,
							height: "100%",
							display: isLoading ? "none" : undefined,
						}}
						className={
							isQualified
								? styles.isQualified
								: styles.notQualified
						}
					>
						{isQualified && (
							<div className={styles.congrats}>გილოცავთ!</div>
						)}
						<div className={styles.text}>
							{isQualified && (
								<>
									თქვენ წარმატებით დაძლიეთ განათლების
									გლობალური გამოწვევა და ნაყოფიერად წარმართეთ
									დისტანციური საგაკვეთილო პროცესი!
									<br />
									<br />
									გაჯილდოვებთ ელექტრონული სერტიფიკატით
									ინოვაციური საგანმანათლებლო პლატფორმა
									tvschool.ge-ს ეფექტიანად გამოყენებისთვის.
									<br />
								</>
							)}
							{!isQualified && (
								<>
									<span
										style={{
											fontSize: 18,
											color: "#5273e6",
											fontWeight: "bold",
										}}
									>
										დისტანციურ სასწავლო პროცესში ჩართული
										პედაგოგებისთვის ელექტრონული
										სერტიფიკატების გაცემა დაიწყო!
									</span>{" "}
									<br />
									<br /> თქვენი სერტიფიკატი უკვე მზად არის!
									<br />
									სერტიფიკატის აქტივაციისთვის თქვენ მინიმუმ
									3-ჯერ უნდა გქონდეთ ჩვენი პლატფორმით
									გაგზავნილი დავალებები მოსწავლეებისთვის.
									<br />
									<Link
										to="/assignments/new"
										style={{ color: "blue" }}
									>
										გააგზავნეთ დავალება
									</Link>{" "}
									ახლავე!
								</>
							)}
						</div>

						{isQualified && (
							<div>
								<div className={styles.inputTitle}>
									გთხოვთ, გადაამოწმეთ რომ სერტიფიკატზე თქვენი
									სახელი და გვარი სწორად არის მითითებული.
									<br />
									ამის შემდეგ გააზიარეთ ან/და გადმოტვირთეთ!
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<input
										className={styles.nameInput}
										value={newName}
										style={{
											width: isMobile
												? "250px"
												: undefined,
											fontSize: isMobile
												? "16px"
												: undefined,
										}}
										onChange={(
											e: React.ChangeEvent<
												HTMLInputElement
											>
										) => setNewName(e.target.value)}
									/>
									{showSaveButton && (
										<div
											className={styles.saveButton}
											onClick={() => handleSave()}
											style={{
												fontSize: isMobile
													? "16px"
													: undefined,
											}}
										>
											დასტური
										</div>
									)}
								</div>
							</div>
						)}

						{certificate && (
							<div className={styles.certificateContainer}>
								<div className={styles.certificateBox}>
									{!isQualified && (
										<div className={styles.lock} />
									)}
									<img
										src={certificate.fullUrl}
										alt="certificate"
										onLoad={() => setIsLoading(false)}
										className={styles.certificate}
										style={{
											width: "100%",
											height: "100%",
										}}
									/>
								</div>
							</div>
						)}
						<div
							className={styles.buttonsContainer}
							style={{
								fontSize: isMobile ? "14px" : undefined,
							}}
						>
							<a
								style={{ display: "none" }}
								href={certificate?.fullUrl}
								download={certificate?.certificateOriginalName}
								ref={downloadRef}
							>
								D
							</a>
							<div
								className={styles.downloadButton}
								onClick={
									!isQualified ? undefined : handleDownload
								}
								style={{
									width: isMobile ? "200px" : undefined,
									borderRadius: isMobile ? " 8px" : undefined,
								}}
							>
								<DownloadIconWrapper
									style={{
										marginRight: isMobile
											? "6px"
											: undefined,
									}}
								>
									<DownloadIcon width={isMobile ? 22 : 27} />
								</DownloadIconWrapper>
								ჩამოტვირთვა
							</div>
							<div
								className={styles.shareButton}
								onClick={!isQualified ? undefined : handleShare}
								style={{
									width: isMobile ? "200px" : undefined,
									borderRadius: isMobile ? " 8px" : undefined,
								}}
							>
								<FacebookIconWrapper
									style={{
										marginRight: isMobile
											? "7px"
											: undefined,
									}}
								>
									<FacebookIcon width={isMobile ? 28 : 35} />
								</FacebookIconWrapper>
								გაზიარება
							</div>
						</div>
					</div>
				</PopupContentWithClose>
			</Popup>
		</DefaultPopupPrioritizer>
	);
});

const DownloadIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: #5273e6;
	}
`;
const FacebookIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: white;
	}
`;

export const testingTeacherIds: number[] = [];
export const showTeacherCertificateOnlyForUs = false;
