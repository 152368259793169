import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { onAuthorOpenVideoLesson } from "@app/common-javascript";
import {
	getResourceIdFromWhazz,
	getResourceTypeFromWhazz,
} from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarWhazz } from "@app/components/layouts/sidebar/helpers/interfaces";
import {
	SidebarProviders,
	SidebarTree,
} from "@app/components/layouts/sidebar/tree";
import { getVideoLessonComponent } from "@app/components/layouts/sidebar/video-link";
import { VideoLinkButton } from "@app/components/widgets/video-link-button";
import { useVideoLesson } from "@app/hooks/video-lessons";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { ObjectId } from "@app/utils/generics";
import { CreateCusto } from "custo";
import React, { useCallback } from "react";
import { useTeacherSidebarItems } from "./items";

export const TeacherSideBarWrapper = React.memo<{
	whazz: SidebarWhazz;
}>(({ whazz }) => {
	return (
		<SidebarProviders.PartialMergingProvider
			value={{
				items: CreateCusto.hookOf.Data(useTeacherSidebarItems),
			}}
		>
			<SidebarTree.Container whazz={whazz}>
				{getStartVideoLessonComponent(whazz)}
				{getVideoLessonComponent(whazz)}
			</SidebarTree.Container>
		</SidebarProviders.PartialMergingProvider>
	);
});

const StartVideoLessonForSidebar: React.FC<{
	classroomId: ObjectId;
}> = React.memo(({ classroomId }) => {
	const videoLesson = useVideoLesson(
		classroomId,
		VideoLessonResourceTypes.CLASSROOM
	);
	const videoLink = videoLesson.doc?.url;

	const handleVideoLessonClick = useCallback(() => {
		if (classroomId && videoLink) {
			onAuthorOpenVideoLesson(
				classroomId,
				VideoLessonResourceTypes.CLASSROOM
			);
		}
	}, [classroomId, videoLink]);

	return (
		<VideoLinkButton
			style={{ width: "92%", marginTop: "30px", marginRight: 5 }}
			lessonStatus={
				videoLink
					? VideoLessonStatus.ACTIVE
					: VideoLessonStatus.NOT_AVAILABLE
			}
			onClick={handleVideoLessonClick}
		/>
	);
});

export const getStartVideoLessonComponent = (whazz: SidebarWhazz) => {
	const resourceId = getResourceIdFromWhazz(whazz);
	const resourceType = getResourceTypeFromWhazz(whazz);

	if (resourceType === VideoLessonResourceTypes.CLASSROOM && !!resourceId) {
		return <StartVideoLessonForSidebar classroomId={resourceId} />;
	}
	return null;
};
