import { WeekType } from "@app/api/weekly-tests/helper-schemas";
import { WeeklyTestReservation } from "@app/models/weekly-test-reservation";
import { inject } from "@app/modules";
import { useModelDocByQuery } from "m-model-react";
import { useMemo } from "react";
import { ResourceLoading } from "react-fetch-data-hooks/lib/data";
import { useFetch, useResourceLoading } from "./fetch";
import { useClassroomsUserId } from "./users";
import { getFoundNotFound } from "@app/utils/object";

export type WeeklyTestReservationStatus =
	| { isFound: true; doc: WeeklyTestReservation }
	| { isFound: false; doc?: undefined };

export function useUserWeeklyTestReservation(weekType: WeekType | null) {
	console.log("useUserWeeklyTestReservationweekType", weekType);
	const userId = useClassroomsUserId() ?? null;
	const query =
		userId === null || weekType === null
			? null
			: { studentId: userId, weekType };
	const resource = useModelDocByQuery(
		inject("WeeklyTestReservationModel"),
		query
	);

	const reservation = useResourceLoading(
		resource,
		() => inject("WeeklyTestController").getReservation(weekType!),
		[userId, weekType]
	);

	return useMemo((): WeeklyTestReservationStatus | null => {
		return getFoundNotFoundDoc(reservation);
	}, [reservation]);
}

export function useReservationForAnotherUser(
	userId: number | null,
	weekType: WeekType | null
) {
	const reservation = useFetch(
		() =>
			inject("WeeklyTestController").getReservationByUserId({
				id: userId!,
				weekType: weekType!,
			}),
		[userId, weekType]
	);

	return useMemo((): WeeklyTestReservationStatus | null => {
		return getFoundNotFoundDoc(reservation);
	}, [reservation]);
}

const getFoundNotFoundDoc = <D extends any>(
	resource: ResourceLoading<{ doc: D }>
) => {
	return getFoundNotFound(
		resource.doc,
		!!resource.doc || !!resource.hasFoundError
	);
};
