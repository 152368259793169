import { UserType } from "@app/api/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds } from "./interfaces";
import { ISchoolHeadmaster } from "@app/api/headmasters/helper-schemas";
import { uniquelize } from "@app/utils/array";
import { isNonNullable } from "@app/utils/common";

export class HeadmasterPermissions extends UserPermissions {
	constructor(
		private readonly schoolId: number | null,
		private readonly permissionsObj: ISchoolHeadmaster | null
	) {
		super(UserType.headmaster);
	}

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(): boolean {
		return false;
	}

	canStudyInClassroom(): boolean {
		return false;
	}

	canAccessGroup(): boolean {
		return false;
	}

	getAvailableClassrooms(): ObjectId[] {
		return [];
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	getOwnLabelIds = () => {
		return this.permissionsObj?.schoolLabelIds ?? null;
	};
	getViewableLabelIds = () => {
		return uniquelize(
			(this.getOwnLabelIds() || []).concat(
				(
					this.permissionsObj?.classrooms?.map(e => e.labelId) || []
				).filter(isNonNullable)
			)
		);
	};

	hasConfirmedChild(): boolean {
		return false;
	}

	getConfirmedChildrenIds(): number[] {
		return [];
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return { isKnown: true, hasAll: false, ids: [] };
	}

	canViewSchool(schoolId: number): boolean {
		return this.schoolId === schoolId;
	}
}
