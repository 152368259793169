/* eslint-disable max-lines-per-function */
import { wrapInSidebarItemsFilter } from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarType } from "@app/components/layouts/sidebar/helpers/interfaces";
import { SubWebsiteOrigin } from "@app/globals";
import {
	ApprovedMinimalIcon,
	CalendarIcon,
	SidebarGradebookIcon,
	SidebarSentHomeworkIcon,
	SidebarStudentListIcon,
} from "@app/icons";
import { headmasterLinks } from "@app/routes/headmaster/link";

export const getHeadmasterSidebarItems = wrapInSidebarItemsFilter(
	({ whazz }) => {
		if (whazz.type === SidebarType.classroom) {
			return [
				{
					name: "classSchedule",
					href: headmasterLinks.classrooms.timetable.routePath,
					icon: CalendarIcon,
				},
				{
					name: "student:assignments",
					href: headmasterLinks.classrooms.assignments.routePath,
					icon: SidebarSentHomeworkIcon,
				},
				{
					name: "classStudentsList",
					href: headmasterLinks.classrooms.listOfStudents.routePath,
					icon: SidebarStudentListIcon,
				},
			];
		}
		if (whazz.type === SidebarType.group) {
			return [
				{
					name: "classSchedule",
					href: headmasterLinks.groups.timetable.routePath,
					icon: CalendarIcon,
				},
				{
					name: "gradeList",
					href: headmasterLinks.groups.gradebook.routePath,
					icon: SidebarGradebookIcon,
					subWesbites: SubWebsiteOrigin.main,
				},
				{
					name: "semesterGrades",
					href: headmasterLinks.groups.semesterbook.routePath,
					icon: ApprovedMinimalIcon,
					subWesbites: SubWebsiteOrigin.main,
				},
				{
					name: "classStudentsList",
					href: headmasterLinks.groups.listOfStudents.routePath,
					icon: SidebarStudentListIcon,
				},
				{
					name: "student:assignments",
					href: headmasterLinks.groups.assignments.routePath,
					icon: SidebarSentHomeworkIcon,
				},
			];
		}
		return null;
	}
);
