import { IRequest } from "../requests";
import {
	IAPOSTStudentGrades,
	IRPOSTStudentGrades,
	IAGETClassroomStudentGradebooks,
	AGETClassroomStudentGradebooksSchema,
	IRGETClassroomStudentGradebooks,
	APOSTStudentGradesSchema,
	IAGETStudentGradebook,
	IRGETStudentGradebook,
	AGETStudentGradebookSchema,
	IADELETEStudentGrades,
	ADELETEStudentGradesSchema,
	IRDELETEStudentGrades,
	RGETClassroomStudentGradebooksSchema,
	IAPUTGradesDisability,
	IRPUTGradesDisability,
	APUTGradesDisabilitySchema,
	IAPOSTSavePassedSubject,
} from "./classrooms/validators";

export class GradebookController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	saveStudentGrades = async (
		args: IAPOSTStudentGrades
	): Promise<IRPOSTStudentGrades> =>
		this.Request.send(
			"POST",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{ requestSchema: APOSTStudentGradesSchema }
		);

	saveStudentPassedSubject = async (args: IAPOSTSavePassedSubject) =>
		this.Request.send("POST", "/api/save-passed-subject", args, null);

	getStudentGradebook = async (
		args: IAGETStudentGradebook
	): Promise<IRGETStudentGradebook> =>
		this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/students/:userId/grades",
			args,
			null,
			{
				requestSchema: AGETStudentGradebookSchema,
			}
		);

	getClassroomGradebooks = async (
		args: IAGETClassroomStudentGradebooks
	): Promise<IRGETClassroomStudentGradebooks> =>
		this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{
				requestSchema: AGETClassroomStudentGradebooksSchema,
				responseSchema: RGETClassroomStudentGradebooksSchema,
			}
		);

	deleteStudentGrades = async (
		args: IADELETEStudentGrades
	): Promise<IRDELETEStudentGrades> =>
		this.Request.send(
			"DELETE",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{ requestSchema: ADELETEStudentGradesSchema }
		);

	updateGradesDisability = async (
		args: IAPUTGradesDisability
	): Promise<IRPUTGradesDisability> =>
		this.Request.send(
			"PUT",
			"/api/classrooms/:classroomId/grades/disablity",
			args,
			null,
			{ requestSchema: APUTGradesDisabilitySchema }
		);
}
