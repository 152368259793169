import { CometChat } from "@cometchat-pro/chat";
import qs from "querystring";

const SINGLE_REQ_LIMIT = 30;
const API_BASE_URL = process.env.REACT_APP_BACKEND_SERVER;

export class AccessibleUserListRequest {
	/** @type {number} */
	offset = 0;

	constructor(
		/** @type {{ userId: number | string; searchKey?: string; }} */
		{ userId, searchKey }
	) {
		this.userId = userId;
		this.searchKey = searchKey;
	}

	/** @type {() => Promise<CometChat.User[]>} */
	async fetchNext() {
		const { accessibleUserIds } = await this.findAccessibleUserIds({
			userId: this.userId,
			offset: this.offset,
			limit: SINGLE_REQ_LIMIT,
			searchKey: this.searchKey,
		});
		if (accessibleUserIds.length === 0) {
			return [];
		}
		this.offset += SINGLE_REQ_LIMIT;
		return this.fetchUsers(accessibleUserIds);
	}

	/** @type {() => Promise<number[]>} */
	async findAccessibleUserIds(
		/** @type {{ userId: number, offset: number, limit: number, searchKey?: string }} */
		{ userId, offset, limit, searchKey }
	) {
		const queryObj = {
			offset,
			limit,
		};
		if (searchKey) {
			queryObj.query = searchKey;
		}
		const queryStr = qs.stringify(queryObj);
		const url = `${API_BASE_URL}/api/chats/users/${userId}/accessible-users?${queryStr}`;
		const res = await fetch(url);
		return res.json();
	}

	async fetchUsers(
		/** @type {number[]} */
		userIds
	) {
		const tags = userIds.map(userId => `uid@${userId}`);
		return new CometChat.UsersRequestBuilder()
			.setLimit(SINGLE_REQ_LIMIT)
			.setTags(tags)
			.withTags(true)
			.build()
			.fetchNext();
	}
}
