import {
	getCurrentWebsite,
	SubWebsiteOrigin,
	WebsiteOrigin,
} from "@app/globals";
import { useClassroomsUser } from "@app/hooks/users";
import React, { useEffect, useState } from "react";
import styles from "./styles/calls.module.css";
import { ReactComponent as Phone } from "./styles/imgs/phone.svg";
import { FancyPopup } from "./popup";
import { useLocale } from "@app/hooks/intl";
import { useUnsafeDynamicRef } from "@app/hooks/general";
import { useSubWebsite } from "../../hooks/bc";

const onMobileClick = (number1: string) => {
	if (!number1) return;
	window.open(`tel:${number1.replace(/ /g, "")}`);
};

const isHiddenGlobally = false;

export const Calls: React.FC<{ containerClassname?: string }> = props => {
	const user = useClassroomsUser();
	const isStudent = user?.isStudent();
	const currentWebsite = getCurrentWebsite();
	const isGeo = useLocale() === "ka";
	const mobiles = useCallCenterPhones();
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	if (currentWebsite !== WebsiteOrigin.tvSchool || !isGeo) return null;
	if (isStudent && !isBritishCenter) return null;
	if (isHiddenGlobally) return null;

	return (
		<div className={props.containerClassname || styles.callsContainer}>
			<Phone className={styles.phoneIcon} />
			{mobiles.map(mobile => (
				<div
					className={styles.numberContainer}
					key={mobile}
					onClick={() => onMobileClick(mobile)}
				>
					{mobile}
				</div>
			))}
		</div>
	);
};

export const CallsPopup: React.FC<{ onClose: () => void }> = props => {
	const user = useClassroomsUser();
	const mobiles = useCallCenterPhones();
	const isStudent = user?.isStudent();
	const currentWebsite = getCurrentWebsite();
	const isGeo = useLocale() === "ka";
	if (!isGeo) return null;
	if (currentWebsite !== WebsiteOrigin.tvSchool && isStudent) return null;

	return (
		<>
			<FancyPopup onClose={props.onClose}>
				<div className={styles.popupContainer}>
					<div className={styles.title}>ცხელი ხაზი</div>
					{mobiles.map((mobile, i, arr) => (
						<React.Fragment key={mobile}>
							<div
								onClick={() => onMobileClick(mobile)}
								className={styles.numberInPopup}
							>
								{mobile}
							</div>
							{i < arr.length - 1 && (
								<div className={styles.line} />
							)}
						</React.Fragment>
					))}
					<div
						onClick={() => onMobileClick(mobiles[0])}
						className={styles.callButton}
					>
						<Phone
							className={styles.phoneIcon}
							style={{ marginRight: 5 }}
						/>
						დარეკვა
					</div>
				</div>
			</FancyPopup>
		</>
	);
};

const useCallCenterPhones = () => {
	const [mobiles, setMobiles] = useState<string[]>([]);
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	const numberArr = isBritishCenter
		? ["032 2 98 99 99", "032 2 94 11 11"]
		: [""];
	useByTime(date => {
		setMobiles(numberArr);
	}, 10000);

	return mobiles;
};

export const useByTime = (fn: (date: Date) => void, interval: number) => {
	const fnRef = useUnsafeDynamicRef(fn);
	useEffect(() => {
		fnRef.current(new Date());
		const planned = setInterval(() => fnRef.current(new Date()), interval);
		return () => {
			clearTimeout(planned);
		};
	}, [fnRef, interval]);
};
