import { StudentGradeSchema, IStudentGrade } from "../grades/helper-schemas";
import { BasicDocument, BasicDocumentSchema } from "../../interface";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const StudentGradebookSchema = BasicDocumentSchema.keys({
	userId: Joi.number()
		.integer()
		.min(0)
		.required(),
	gradebookId: Joi.objectId().required(),
	grades: Joi.array()
		.items(StudentGradeSchema)
		.required(),
	isPassed: Joi.boolean(),
});
export interface IStudentGradebook extends BasicDocument {
	userId: number;
	gradebookId: ObjectId;
	grades: IStudentGrade[];
	isPassed?: boolean;
}
