import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Requests } from "@app/api";
import { updateChildAccessToken } from "@app/user/children";

export const ParentChildObserver = React.memo(() => {
	const location = useLocation();

	const match = matchPath<{ childId?: string }>(location.pathname, {
		path: "/children/:childId",
	});
	const childId = match?.params.childId && +match?.params.childId;
	useEffect(() => {
		if (!childId) {
			Requests.clearChildAccessToken();
		} else {
			updateChildAccessToken({ userId: childId });
		}
	}, [childId]);
	return null;
});
