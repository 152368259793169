import { useFetch, useSettabeResource } from "./fetch";
import { inject } from "@app/modules";
import { ObjectId, NullPartial } from "@app/utils/generics";
import { UserType } from "@app/api/helper-schemas";
import { IAGETTeachersBySchoolsIds } from "@app/api/schools/validators";
import user from "../redux/reducers/user";

export const useTeachersByScholId = (
	query: NullPartial<IAGETTeachersBySchoolsIds> | null
) => {
	const school = query?.schoolId;
	const schoolLabelId = query?.schoolLabelId;
	return useFetch(
		() =>
			inject("SchoolsController").getTeachersBySchoolId(
				query as IAGETTeachersBySchoolsIds
			),
		!!query && !!query.schoolId,
		[school, schoolLabelId]
	);
};

export const useSettableTeachersByScholId = (
	query: NullPartial<IAGETTeachersBySchoolsIds> | null
) => {
	const a = useTeachersByScholId(query);
	return useSettabeResource(a, "doc");
};

export const useSchoolUsers = ({
	schoolId,
	userTypes,
	schoolLabelId,
}: {
	schoolId: number | null;
	userTypes: UserType | UserType[] | null;
	schoolLabelId: ObjectId | null;
}) => {
	return useFetch(
		() =>
			inject("SchoolsController").getUsersOfSchool({
				schoolId: schoolId!,
				userTypes: !userTypes
					? undefined
					: Array.isArray(userTypes!)
					? userTypes!
					: [userTypes!],
				schoolLabelId,
			}),
		!!schoolId,
		[schoolId, userTypes, schoolLabelId]
	);
};

export const useUsersOfSchoolRel = ({
	schoolId,
	userType,
}: {
	schoolId: number;
	userType: UserType;
}) => {
	return useFetch(
		() =>
			inject("SchoolsController").getUsersOfSchoolRel({
				schoolId: schoolId,
				userType: userType,
			}),
		!!schoolId,
		[schoolId, userType]
	);
};

export const useAllPassableSubjects = () => {
	return useFetch(
		() => inject("PassableSubjectsController").getAllPassableSubjects(),
		[]
	);
};
