import { ObjectId } from "@app/utils/generics";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { BasicDocumentSchema, BasicDocument } from "../interface";

export enum WeekType {
	finals = 1,
	progress = 2,
}

export const weekTypeValues = [WeekType.finals, WeekType.progress];

export const WeekTypeValuesSchema = Joi.number().valid(...weekTypeValues);

export enum WeeklyTestPaymentType {
	Premium = "PRM",
	Regular = "REG",
}

export const WeeklyTestPaymentTypes = [
	WeeklyTestPaymentType.Premium,
	WeeklyTestPaymentType.Regular,
];

export enum WeeklyTestPaymentScheduleType {
	LATER = "LATER",
	NOW = "NOW",
}
export const WeeklyTestPaymentScheduleTypes = [
	WeeklyTestPaymentScheduleType.LATER,
	WeeklyTestPaymentScheduleType.NOW,
];

export enum WeeklyTestPaymentRate {
	Initial = "V1",
	Updated = "V2",
	SALE1 = "V3",
}
export const WeeklyTestPaymentRates = [
	WeeklyTestPaymentRate.Initial,
	WeeklyTestPaymentRate.Updated,
	WeeklyTestPaymentRate.SALE1,
];

export const WeeklyTestRegularPaymentSchema = Joi.object({
	type: Joi.string().valid(WeeklyTestPaymentType.Regular),
	amountPaid: Joi.number()
		.min(0)
		.required(),
	availableSubjectIds: Joi.array()
		.items(Joi.objectId())
		.optional(),
});
export interface IWeeklyTestRegularPayment {
	type: WeeklyTestPaymentType.Regular;
	amountPaid: number;
	availableSubjectIds?: ObjectId[];
}

export const WeeklyTestPremiumPaymentSchema = Joi.object({
	type: Joi.string().valid(WeeklyTestPaymentType.Premium),
});
export interface IWeeklyTestPremiumPayment {
	type: WeeklyTestPaymentType.Premium;
}

export const WeeklyTestPaymentSchema = Joi.alternatives([
	WeeklyTestRegularPaymentSchema,
	WeeklyTestPremiumPaymentSchema,
]).byKey("type");
export type IWeeklyTestPayment =
	| IWeeklyTestRegularPayment
	| IWeeklyTestPremiumPayment;

export const WeeklyTestParentSchema = Joi.object({
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	identityNumber: Joi.string(),
	mobile: Joi.string().required(),
});
export interface IWeeklyTestParent {
	firstname: string;
	lastname: string;
	identityNumber?: string;
	mobile: string;
}

export const WeeklyTestReservationSchema = BasicDocumentSchema.keys({
	studentId: UnsignedIntegerSchema.required(),
	parent: WeeklyTestParentSchema.optional(),
	subjectIds: Joi.array().items(Joi.objectId().required()),
	payment: WeeklyTestPaymentSchema.optional(),
	paymentSchedule: Joi.any().valid(...WeeklyTestPaymentScheduleTypes),
	paymentRate: Joi.any().valid(...WeeklyTestPaymentRates),
	courses: Joi.object().pattern(/^[a-f\d]{24}$/i, Joi.objectId()),
	weekType: WeekTypeValuesSchema.required(),
});
export interface IWeeklyTestReservation extends BasicDocument {
	studentId: number;
	parent?: IWeeklyTestParent;
	subjectIds?: ObjectId[];
	payment?: IWeeklyTestPayment;
	paymentSchedule?: WeeklyTestPaymentScheduleType;
	paymentRate?: WeeklyTestPaymentRate;
	courses?: Record<string, ObjectId | undefined>;
	weekType: WeekType;
}
