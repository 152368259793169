import { CardTemplate } from "@app/models/card-template";
import { inject } from "@app/modules";
import { createModelSingleCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import {
	IADELETECardTemplate,
	IAGETCardTemplate,
	IAPOSTCreateCardTemplate,
	IAPUTCardTemplate,
	IRGETAllCardTemplates,
	IRPOSTCreateCardTemplate,
	RGETAllCardTemplatesSchema,
	RGETCardTemplateSchema,
	RPOSTCreateCardTemplateSchema,
} from "./validators";

export class CardTemplatesController {
	private readonly Request: IRequest;

	private readonly _CardTemplateModel = inject("CardTemplateModel");

	private cardTemplatePromises = createModelSingleCollmise({
		model: this._CardTemplateModel,
		name: "Card Template",
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCardTemplate): Promise<CardTemplate> =>
		this.Request.send("POST", "/api/card-templates", args, null, {
			responseSchema: RPOSTCreateCardTemplateSchema,
		}).then((data: IRPOSTCreateCardTemplate) => {
			return this._CardTemplateModel.loadOneSync(data);
		});

	update = async (args: IAPUTCardTemplate): Promise<CardTemplate | null> =>
		this.Request.send("PUT", "/api/card-templates/:_id", args).then(() =>
			this._CardTemplateModel.updateOneSync({ _id: args._id }, args)
		);

	getById = async (
		args: IAGETCardTemplate,
		loadFresh?: boolean
	): Promise<CardTemplate> => {
		return this.cardTemplatePromises
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/card-templates/:_id",
					args,
					null,
					{ responseSchema: RGETCardTemplateSchema }
				)
			);
	};

	getAll = async (): Promise<CardTemplate[]> =>
		this.Request.send("GET", "/api/card-templates/", undefined, null, {
			responseSchema: RGETAllCardTemplatesSchema,
		}).then((data: IRGETAllCardTemplates) => {
			this._CardTemplateModel.meta.updateLoadTime();
			return this._CardTemplateModel.loadManySync(data, "replaceAll");
		});

	deleteById = async (args: IADELETECardTemplate): Promise<void> =>
		this.Request.send("DELETE", "/api/card-templates/:_id", args).then(() =>
			this._CardTemplateModel.deleteByIdSync(args._id)
		);
}
