/* eslint-disable max-lines-per-function */
import React, { useState, useCallback } from "react";
import * as ReactDOM from "react-dom";
import { FancyPopup, FancyPopupRef } from "./popup";
import SvgloadingWhite from "../styles/img/loading-white";
import styles from "./styles/confirmation-popup.module.css";
import classNames from "classnames";
import { getFormattedMessage } from "@app/utils/locale";

export const openErrorAlertPopup = () =>
	openConfirmationPopupWithFormattedMessage("errorAlert");

export const openConfirmationPopupWithFormattedMessage = (
	formatedMessageId: string
) => openConfirmationPopup({ text: getFormattedMessage(formatedMessageId) });

export const openConfirmationPopup = (
	args: ConfirmPopupProps & { onClose?: () => void }
) => {
	const popupDiv = document.createElement("div");
	const popupRef = React.createRef<FancyPopupRef>();
	const onClose = () => {
		if (
			typeof popupDiv.parentNode !== "undefined" &&
			popupDiv.parentNode !== null
		) {
			if (popupRef.current) {
				popupRef.current.onClose();
			}
			popupDiv.parentNode.removeChild(popupDiv);
			args.onClose?.();
		}
	};
	ReactDOM.render(
		<ConfirmationPopup {...args} onClose={onClose} popupRef={popupRef} />,
		document.body.appendChild(popupDiv)
	);
};

type ConfirmPopupProps = {
	approveTitle?: string;
	approveButtonClassName?: string;

	text: string | number | JSX.Element;

	getErrorText?: (error: any) => string | undefined;
	defaultErrorText?: string;
	popupRef?: React.RefObject<FancyPopupRef>;
} & (
	| {
			onApprove: () => void | Promise<any>;
			rejectTitle: string;
			onReject?: () => void | Promise<any>;
			rejectButtonClassName?: string;
			displayRejectButtonAsPrimary?: boolean;
	  }
	| {
			onApprove?: () => void | Promise<any>;
			rejectTitle?: undefined;
			onReject?: undefined;
			rejectButtonClassName?: undefined;
			displayRejectButtonAsPrimary?: undefined;
	  }
);

export const ConfirmationPopup: React.FC<ConfirmPopupProps & {
	onClose: () => void;
}> = React.memo(props => {
	const [loading, setLoading] = useState<"approve" | "reject">();
	const [error, setError] = useState<string | null>(null);

	const { onApprove, onReject, onClose, getErrorText } = props;

	const approve = useCallback(() => {
		setLoading("approve");
		setError(null);
		if (!onApprove) {
			onClose();
			return;
		}
		new Promise(resolve => {
			resolve(onApprove());
		})
			.then(() => {
				setLoading(undefined);
				onClose();
			})
			.catch(e => {
				setLoading(undefined);
				const errorText =
					(getErrorText && getErrorText(e)) ||
					props.defaultErrorText ||
					"";
				setError(errorText);
			});
	}, [getErrorText, onApprove, onClose, props.defaultErrorText]);

	const reject = useCallback(() => {
		setLoading("reject");
		setError(null);
		if (!onReject) {
			onClose();
			return;
		}
		new Promise(resolve => {
			resolve(onReject());
		})
			.then(() => {
				setLoading(undefined);
				onClose();
			})
			.catch(e => {
				setLoading(undefined);
				const errorText =
					(getErrorText && getErrorText(e)) ||
					props.defaultErrorText ||
					"";
				setError(errorText);
			});
	}, [getErrorText, onClose, onReject, props.defaultErrorText]);

	const showOnlyApproveButton = typeof props.rejectTitle === "undefined";

	return (
		<div>
			<FancyPopup onClose={props.onClose} ref={props.popupRef}>
				<div className={styles.mainText}>{props.text}</div>
				{(props.approveTitle !== undefined ||
					!showOnlyApproveButton) && (
					<div className={styles.buttonsContainer}>
						{props.approveTitle !== undefined && (
							<Button
								text={props.approveTitle}
								onClick={approve}
								isLoading={loading === "approve"}
								className={
									props.approveButtonClassName ||
									(!props.displayRejectButtonAsPrimary
										? styles.primaryButton
										: styles.secondaryButton)
								}
							/>
						)}
						{!showOnlyApproveButton && (
							<Button
								text={props.rejectTitle!}
								onClick={reject}
								isLoading={loading === "reject"}
								className={
									props.rejectButtonClassName ||
									props.displayRejectButtonAsPrimary
										? styles.primaryButton
										: styles.secondaryButton
								}
							/>
						)}
					</div>
				)}
				{error !== null && (
					<div className={styles.errorContainer}>{error}</div>
				)}
			</FancyPopup>
		</div>
	);
});

export interface IButtonProps {
	onClick?: () => void;
	isLoading?: boolean;
	text: string;
	className?: string;
}

export const Button: React.FC<IButtonProps> = React.memo(props => {
	return (
		<button
			className={classNames(props.className, styles.button)}
			onClick={!props.isLoading ? props.onClick : undefined}
			style={{ opacity: props.isLoading ? 0.8 : 1 }}
		>
			<span>{props.text}</span>
			{props.isLoading && (
				<span className={styles.loadingIconContainer}>
					<SvgloadingWhite />
				</span>
			)}
		</button>
	);
});
