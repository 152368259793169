import Joi from "@app/utils/joi";
import { FileSchema, IFile } from "../files/helper-schemas";
import {
	CardItemSchema,
	RFolderSchema,
	IRFolder,
	ItemType,
	QuestionItemSchema,
	TestItemSchema,
	UserFolderProgressSchema,
	itemTypes,
	UserFolderLevelSchema,
	IUserFolderProgress,
	ITestItem,
	IQuestionItem,
	ICardItem,
	IUserFolderLevel,
} from "./helper-schemas";
import { IRTest, RTestSchema } from "../tests/helper-schemas";
import { RHierarchySchema, IRHierarchy } from "../courses/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	insertStripKeys,
	markKeysForbidden,
	ToInsertKeys,
	markKeysOptional,
	ToUpdateKeys,
} from "../helper-schemas";
import { ICard } from "@tests-core/schemas/cards";
import {
	IShortQuestion,
	IFullQuestion,
} from "@tests-core/schemas/questions/helper-schemas";

export const AGETFoldersByIdsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderIds: Joi.array()
		.items(Joi.objectId().required())
		.required(),
});
export interface IAGETFoldersByIds {
	courseId: ObjectId;
	folderIds: ObjectId[];
}

export const RGETFoldersByIdsSchema = Joi.array().items(RFolderSchema);
export type IRGETFoldersByIds = IRFolder[];

///

export const APOSTCreateFolderSchema = RFolderSchema.keys(insertStripKeys)
	.fork("items", markKeysForbidden)
	.keys({
		parentId: Joi.objectId(),
	});

export type IAPOSTCreateFolder = ToInsertKeys<IRFolder, never, "items"> & {
	parentId?: ObjectId;
};

export const RPOSTCreateFolderSchema = RFolderSchema;
export type IRPOSTCreateFolder = IRFolder;

///

const folderEditOptionalKeys = ["description", "items", "public"] as const;
const folderEditForbiddenKeys = ["author", "originalCourseId"] as const;

export const APUTFolderSchema = RFolderSchema.fork(
	folderEditOptionalKeys,
	markKeysOptional
)
	.fork(folderEditForbiddenKeys, markKeysForbidden)
	.keys({
		courseId: Joi.objectId().required(),
	});

export type IAPUTFolder = ToUpdateKeys<
	IRFolder,
	typeof folderEditOptionalKeys[number],
	typeof folderEditForbiddenKeys[number]
> & {
	courseId: ObjectId;
};

///

export const AGETFolderSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETFolder {
	_id: ObjectId;
	courseId: ObjectId;
}

export const RGETFolderSchema = RFolderSchema;
export type IRGETFolder = IRFolder;

///

export const ADELETEFolderSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IADELETEFolder {
	_id: ObjectId;
	courseId: ObjectId;
}

///

export const RGETAllFoldersSchema = Joi.array()
	.items(RFolderSchema)
	.required();
export type IRGETAllFolders = IRFolder[];

///

export const APUTReorderItemsSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	sourceIndex: Joi.number()
		.integer()
		.required(),
	destinationIndex: Joi.number()
		.integer()
		.required(),
});
export interface IAPUTReorderItems {
	_id: ObjectId;
	courseId: ObjectId;
	sourceIndex: number;
	destinationIndex: number;
}

///

export const AGETFolderItemsRecursivelySchema = Joi.object({
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	itemTypes: Joi.array().items(
		Joi.string()
			.valid(...itemTypes)
			.required()
	),
	testTypeIds: Joi.array().items(Joi.objectId().required()),
	depth: Joi.number().integer(),
	getAnswers: Joi.boolean(),
});
export interface IAGETFolderItemsRecursively {
	folderId: ObjectId;
	courseId: ObjectId;
	itemTypes?: ItemType[];
	testTypeIds?: ObjectId[];
	depth?: number;
	getAnswers?: boolean;
}

export const RGETFolderItemsRecursivelySchema = Joi.object({
	folders: Joi.object().pattern(/[\da-f]+/, RFolderSchema),
	files: Joi.object().pattern(/[\da-f]+/, FileSchema),
	cards: Joi.object().pattern(/[\da-f]+/, Joi.object()),
	questions: Joi.object().pattern(/[\da-f]+/, Joi.object()),
	tests: Joi.object().pattern(/[\da-f]+/, RTestSchema),
});

export interface IRGETFolderItemsRecursively {
	folders?: {
		[folderId: string]: IRFolder | undefined;
	};
	files?: {
		[fileId: string]: IFile | undefined;
	};
	cards?: {
		[cardId: string]: ICard | undefined;
	};
	questions?: {
		[questionId: string]: (IShortQuestion | IFullQuestion) | undefined;
	};
	tests?: {
		[testId: string]: IRTest | undefined;
	};
}

export const AGETAllSubFoldersProgressesSchema = Joi.object({
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	depth: Joi.number().integer(),
});
export interface IAGETAllSubFoldersProgresses {
	folderId: ObjectId;
	courseId: ObjectId;
	depth?: number;
}

export const RGETAllSubFoldersProgressesSchema = Joi.object().pattern(
	/[\da-f]+/,
	UserFolderProgressSchema
);
export type IRGETAllSubFoldersProgresses = Record<
	string,
	IUserFolderProgress | undefined
>;

///

export const APUTMoveFolderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	currentFolderId: Joi.objectId().required(),
	newFolderId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(...itemTypes)
		.required(),
});
export interface IAPUTMoveFolder {
	courseId: ObjectId;
	currentFolderId: ObjectId;
	newFolderId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}

export type IRPUTMoveFolder = void;

///

const EditingItemSchema = Joi.alternatives([
	TestItemSchema,
	QuestionItemSchema,
	CardItemSchema,
]);

type IEditingItem = ITestItem | IQuestionItem | ICardItem;

export const APOSTAddItemInFolderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	item: EditingItemSchema.required(),
});
export interface IAPOSTAddItemInFolder {
	courseId: ObjectId;
	folderId: ObjectId;
	item: IEditingItem;
}

///

export const APUTEditItemInFolderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	item: EditingItemSchema.required(),
	testItems: Joi.array().items(
		Joi.alternatives([
			Joi.object({ questionId: Joi.objectId().required() }),
			Joi.object({ cardId: Joi.objectId().required() }),
		])
	),
});
export interface IAPUTEditItemInFolder {
	courseId: ObjectId;
	folderId: ObjectId;
	item: IEditingItem;
	testItems?: (
		| {
				questionId: ObjectId;
		  }
		| {
				cardId: ObjectId;
		  })[];
}
///

export const ADELETERemoveItemFromFolderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(ItemType.test, ItemType.question, ItemType.card)
		.required(),
});
export interface IADELETERemoveItemFromFolder {
	courseId: ObjectId;
	folderId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}

///

export const APUTResetProgressSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderIds: Joi.array()
		.items(Joi.objectId())
		.required(),
});

export interface IAPUTResetProgress {
	courseId: ObjectId;
	folderIds: ObjectId[];
}

///

export const APUTResetItemProgressSchema = ADELETERemoveItemFromFolderSchema.fork(
	"folderId",
	markKeysForbidden
).keys({
	parentFolderId: Joi.objectId().required(),
});
export type IAPUTResetItemProgress = Omit<
	IADELETERemoveItemFromFolder,
	"folderId"
> & {
	parentFolderId: ObjectId;
};

///

export const AGETFolderLevelsSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETFolderLevels {
	courseId: ObjectId;
}

export const RGETFolderLevelsSchema = UserFolderLevelSchema;
export type IRGETFolderLevels = IUserFolderLevel;

///

export const AGETFolderHierarchySchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETFolderHierarchy {
	courseId: ObjectId;
}

export const RGETFolderHierarchySchema = RHierarchySchema;
export type IRGETFolderHierarchy = IRHierarchy;

///

export const AGETFoldersByItemSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(...itemTypes)
		.required(),
});
export interface IAGETFoldersByItem {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}

export const RGETFoldersByItemSchema = Joi.array()
	.items(Joi.objectId())
	.required();
export type IRGETFoldersByItem = ObjectId[];

///

export const APOSTCopyFolderItemsSchema = Joi.object({
	source: Joi.object({
		courseId: Joi.objectId().required(),
		folderId: Joi.objectId().required(),
	}).required(),
	destination: Joi.object({
		courseId: Joi.objectId().required(),
		folderId: Joi.objectId().required(),
		createNewFolder: Joi.boolean().required(),
	}).required(),
	items: Joi.array()
		.items({
			type: Joi.number()
				.valid(...itemTypes)
				.required(),
			id: Joi.objectId().required(),
		})
		.required(),
});
export interface IAPOSTCopyFolderItems {
	source: {
		courseId: ObjectId;
		folderId: ObjectId;
	};
	destination: {
		courseId: ObjectId;
		folderId: ObjectId;
		createNewFolder: boolean;
	};
	items: {
		type: ItemType;
		id: ObjectId;
	}[];
}
