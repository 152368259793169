import { Test } from "@app/models/test";
import { inject } from "@app/modules";
import { mergeRecursive } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import { ItemType } from "../folders/helper-schemas";
import { IRequest } from "../requests";
import { ITestType, ITestTypeSettings } from "../test-types/helper-schemas";
import { IRTest } from "./helper-schemas";
import {
	IADELETETest,
	IAGETManyTestsByIds,
	IAGETQuestionsByTaskTypes,
	IAGETTestById,
	IAGETTestContents,
	IAGETTestsByItem,
	IAGETUsersByTest,
	IAGETUserTest,
	IAGETUserTestForAdmin,
	IAPOSTCreateTest,
	IAPOSTFinishPracticeTest,
	IAPOSTFinishTest,
	IAPOSTSaveAdminCredits,
	IAPUTTest,
	IRGETAllTests,
	IRGETManyTestsByIds,
	IRGETQuestionsByTaskTypes,
	IRGETTestById,
	IRGETTestContents,
	IRGETTestsByItem,
	IRGETUsersByTest,
	IRGETUserTest,
	IRPOSTCreateTest,
	IRPOSTFinishPracticeTest,
	IRPOSTFinishTest,
	RGETAllTestsSchema,
	RGETManyTestsByIdsSchema,
	RGETQuestionsByTaskTypesSchema,
	RGETTestByIdSchema,
	RPOSTCreateTestSchema,
} from "./validators";

export class TestsController {
	private readonly Request: IRequest;

	private readonly _TestModel = inject("TestModel");
	private readonly _UserFolderLevelModel = inject("UserFolderLevelModel");
	private readonly _UserTopicLevelModel = inject("UserTopicLevelModel");
	private readonly _UserTaskTypeLevelModel = inject("UserTaskTypeLevelModel");
	private readonly _FolderItemsService = inject("FolderItemsService");
	private readonly _FolderItemProgressService = inject(
		"FolderItemProgressService"
	);
	private readonly _TestTypesController = inject("TestTypesController");

	private testPromises = collmise({
		collectingTimeoutMS: 15,
	});

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateTest): Promise<IRPOSTCreateTest> =>
		this.Request.send("POST", "/api/tests", args, null, {
			responseSchema: RPOSTCreateTestSchema,
		}).then((data: IRPOSTCreateTest) => {
			if (args.folderId) {
				try {
					this._FolderItemsService.addItemInParentSync({
						parentFolderId: args.folderId,
						item: {
							id: data._id,
							name: data.name,
							type: ItemType.test,
						},
						courseId: args.courseId,
					});
				} catch (e) {}
			}
			return data;
		});

	finish = async (args: IAPOSTFinishTest): Promise<IRPOSTFinishTest> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		return this.Request.send("POST", "/api/tests/onFinish", args).then(
			(data: IRPOSTFinishTest) => {
				try {
					this._FolderItemProgressService.addFinishedTestSync(
						{
							courseId: args.courseId,
							folderId: args.folderId,
							testId: args.testId,
							score: args.score,
							progress: args.progress,
							attempt: args.attempt,
						},
						userId
					);
					this.dirtenLevelDocs(userId, args.courseId);
				} catch (e) {}
				return data;
			}
		);
	};

	private dirtenLevelDocs(userId: number, courseId: ObjectId) {
		const arg1 = {
			userId,
			courseId,
		};
		const arg2 = {
			needsRecalculation: true,
		};
		this._UserTopicLevelModel.updateOneSync(arg1, arg2);
		this._UserFolderLevelModel.updateOneSync(arg1, arg2);
		this._UserTaskTypeLevelModel.updateOneSync(arg1, arg2);
	}

	finishPracticeTest = async (
		args: IAPOSTFinishPracticeTest
	): Promise<IRPOSTFinishPracticeTest> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		return this.Request.send(
			"POST",
			"/api/tests/finish-practice-test",
			args
		).then((data: IRPOSTFinishPracticeTest) => {
			this.dirtenLevelDocs(userId, args.courseId);
			return data;
		});
	};

	getUserTest = async (args: IAGETUserTest): Promise<IRGETUserTest> =>
		this.Request.send("GET", "/api/tests/user-test", args).then(
			(data: IRGETUserTest) => {
				return data || null;
			}
		);

	getUserTestById = async (args: { _id: ObjectId }): Promise<IRGETUserTest> =>
		this.Request.send("GET", "/api/tests/user-tests/:_id", args).then(
			(data: IRGETUserTest) => {
				return data;
			}
		);

	getUserTestForAdmin = (
		args: IAGETUserTestForAdmin
	): Promise<IRGETUserTest> =>
		this.Request.send("GET", "/api/tests/user-test-admin", args);

	getUsersByTest = async (
		args: IAGETUsersByTest
	): Promise<IRGETUsersByTest> =>
		this.Request.send("GET", "/api/tests/users-by-test", args).then(
			(data: IRGETUsersByTest) => {
				return data;
			}
		);

	getTestContents = async (
		args: IAGETTestContents
	): Promise<IRGETTestContents> =>
		this.Request.send("GET", "/api/tests/contents", args).then(
			(data: IRGETTestContents) => {
				return data;
			}
		);

	getTestsByItem = async (
		args: IAGETTestsByItem
	): Promise<IRGETTestsByItem> =>
		this.Request.send("GET", "/api/tests/get-tests-by-item", args).then(
			(data: IRGETTestsByItem) => {
				return data;
			}
		);

	update = async (args: IAPUTTest): Promise<void> =>
		this.Request.send("PUT", "/api/tests/:_id", args).then((data: void) => {
			this._FolderItemsService.updateItemSync({
				item: { id: args._id, type: ItemType.test, name: args.name },
				courseId: args.courseId,
			});
			return data;
		});

	saveAdminCredits = async (args: IAPOSTSaveAdminCredits): Promise<void> =>
		this.Request.send("POST", "/api/tests/admin-credits", args).then(
			(data: void) => {
				return data;
			}
		);

	getById = async (
		args: IAGETTestById,
		loadFresh?: boolean
	): Promise<Test> => {
		if (!loadFresh) {
			const test = this._TestModel.findByIdSync(args._id);
			if (test && test.isInGoodCondition()) {
				return test;
			}
		}
		return this.testPromises.on(args._id).requestAs(() =>
			this.Request.send("GET", "/api/tests/:_id", args, null, {
				responseSchema: RGETTestByIdSchema,
			}).then((data: IRGETTestById) => {
				return this._TestModel.loadOneSync(data);
			})
		);
	};

	getAll = async (): Promise<Test[]> =>
		this.Request.send("GET", "/api/tests/", undefined, null, {
			responseSchema: RGETAllTestsSchema,
		}).then((data: IRGETAllTests) => {
			return this._TestModel.loadManySync(data, "replaceAll");
		});

	deleteById = async (args: IADELETETest): Promise<void> =>
		this.Request.send("DELETE", "/api/tests/:_id", args).then(
			(data: void) => {
				this._FolderItemsService.deleteItemSync({
					itemId: args._id,
					type: ItemType.test,
					courseId: args.courseId,
				});
				this._TestModel.deleteByIdSync(args._id);
				return data;
			}
		);

	getPracticeTestQuestions = async (
		args: IAGETQuestionsByTaskTypes
	): Promise<IRGETQuestionsByTaskTypes> => {
		return this.Request.send(
			"POST",
			"/api/tests/practice-test",
			args,
			null,
			{
				responseSchema: RGETQuestionsByTaskTypesSchema,
			}
		);
	};

	getManyByIds = async (args: IAGETManyTestsByIds): Promise<Test[]> =>
		this.Request.send("POST", "/api/tests/get-many-by-ids", args, null, {
			responseSchema: RGETManyTestsByIdsSchema,
		}).then((data: IRGETManyTestsByIds) => {
			return this._TestModel.loadManySync(data, args.testIds);
		});

	async loadTest(args: {
		testId: ObjectId;
		loadContent?: false;
	}): Promise<{
		test: IRTest;
		testType: ITestType | undefined;
		testTypeSettings: ITestTypeSettings;
	}>;
	async loadTest(args: {
		testId: ObjectId;
		loadContent: true;
		folderId: ObjectId | null;
		courseId: ObjectId;
		getAnswers: boolean;
	}): Promise<{
		test: IRTest;
		testType: ITestType | undefined;
		testTypeSettings: ITestTypeSettings;
		content: IRGETTestContents;
	}>;
	async loadTest(
		args: { testId: ObjectId } & (
			| { loadContent?: false }
			| {
					loadContent: true;
					folderId: ObjectId | null;
					courseId: ObjectId;
					getAnswers: boolean;
			  }
		)
	): Promise<{
		test: IRTest;
		testType: ITestType | undefined;
		testTypeSettings: ITestTypeSettings;
		content?: IRGETTestContents;
	}> {
		const test = await this.getById({ _id: args.testId });
		const testType: ITestType | undefined =
			test && test.testTypeId
				? await this._TestTypesController.getById({
						_id: test.testTypeId,
				  })
				: undefined;
		let content: IRGETTestContents | undefined;
		if (args.loadContent) {
			content = await this.getTestContents({
				courseId: args.courseId,
				folderId: args.folderId,
				testId: args.testId,
				getAnswers: args.getAnswers,
			});
		}
		return {
			test,
			testType,
			testTypeSettings: mergeRecursive(
				test.testTypeSettings! || {},
				(testType! && testType!.settings!) || {}
			),
			content,
		};
	}
}
