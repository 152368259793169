import React, { useRef, useEffect, useMemo } from "react";
import { useStoreProperty } from "./hooks/store";
import { IntlProvider, updateIntl } from "react-intl-redux";
import { useFetch } from "./hooks/fetch";
import { loadLocale } from "../locale";
import { store } from "../index";
import { useSubWebsite } from "./hooks/bc";
import { SubWebsiteOrigin } from "./globals";

export const IntlWrapper = React.memo<{
	children: React.ReactNode | React.ReactNode[];
}>(function IntlWrapper({ children }) {
	const wasMounted = useRef(false);
	const specialFlags = useSpecialFlag();
	const intl = useStoreProperty("intl")!;
	const locale = intl.locale;

	useEffect(() => {
		wasMounted.current = true;
	}, []);

	const fetchedInfo = useFetch(
		() => loadAndUpdateIntlMessages(locale, specialFlags),
		true,
		[locale, specialFlags]
	);

	if (!fetchedInfo.isSuccessfullyLoaded && !wasMounted.current) {
		return null;
	}

	return <IntlProvider {...intl}>{children}</IntlProvider>;
});

const updateIntlMessages = (locale: string, messages: any) => {
	store.dispatch(updateIntl({ locale, messages }));
	localStorage.locale = locale;
};

export const loadAndUpdateIntlMessages = async (
	locale: string,
	specialFlags: string[] | null
) => {
	const { messages } = await loadLocale(locale, specialFlags);
	updateIntlMessages(locale, messages);
};

(window as any).loadAndUpdateIntlMessages = loadAndUpdateIntlMessages;

const useSpecialFlag = (): string[] | null => {
	const subWeb = useSubWebsite();
	return useMemo(() => {
		if (subWeb === SubWebsiteOrigin.britishCenter) {
			return ["bc"];
		}
		return null;
	}, [subWeb]);
};
