import { WeeklyTestPaymentRate } from "@app/api/weekly-tests/helper-schemas";

export function getSubjectsTotalPrice(
	subjectsCount: number,
	paymentRate: WeeklyTestPaymentRate | undefined
) {
	if (paymentRate === WeeklyTestPaymentRate.Initial) {
		return subjectsCount * 7;
	}
	if (paymentRate === WeeklyTestPaymentRate.Updated) {
		if (subjectsCount >= 3) return subjectsCount * 7;
		return subjectsCount * 9;
	}
	if (paymentRate === WeeklyTestPaymentRate.SALE1) {
		return subjectsCount * 7;
	}
	if (subjectsCount >= 3) return subjectsCount * 10;
	return subjectsCount * 13;
}
