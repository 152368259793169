import Joi from "@app/utils/joi";
import { UserFullInfoSchema, IUserFullInfo } from "../users/helper-schemas";

export const AGETFindUsersSchema = Joi.object({
	firstname: Joi.string(),
	lastname: Joi.string(),
	mobile: Joi.string(),
	school: Joi.number(),
	grade: Joi.number(),
	type: Joi.string(),
	id: Joi.array(),
});

export interface IAGETFindUsers {
	firstname?: string;
	lastname?: string;
	mobile?: string;
	school?: number | null;
	grade?: number;
	type?: string;
	id?: number[];
}

export const RGETFindUsersSchema = Joi.array().items(UserFullInfoSchema);
export type IRGETFindUsers = IUserFullInfo[];
