import { useGoToUrl } from "@app/hooks/front";
import { useLogoutFn } from "@app/hooks/users";
import { getFormattedMessage } from "@app/utils/locale";
import { useMemo } from "react";

enum UserOption {
	profile,
	logout,
}

export const useHeaderOptions = () => {
	const goto = useGoToUrl((str: string) => str);

	const onLogout = useLogoutFn();

	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
			onSelect: () => void;
		}[] => [
			{
				value: UserOption.profile,
				label: getFormattedMessage("profile"),
				onSelect: () => goto("/profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
				onSelect: onLogout,
			},
		],
		[]
	);
};
