import { inject } from "@app/modules";
import Joi, { getSchemaValidationFn } from "@app/utils/joi";
import { StorageDataSaver } from "@app/utils/storage";
import { omitSecondaryUsers } from "..";
import { IRequest } from "../requests";
import { IRGETChatToken, RGETChatTokenSchema } from "./validators";

export class ChatController {
	private readonly Request: IRequest;

	private gerUser = inject("assertAndGetClassroomsUser");

	private cachedToken = new StorageDataSaver({
		key: "cachedChatToken",
		validationFn: getSchemaValidationFn<IChatTokenInStorage>(
			ChatTokenInStorageSchema
		),
		timeoutMs: 60 * 60 * 5 * 1000, // TODO: change to 4 * 60 * 60 * 24 * 1000 after 9/21/2021
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	getChatToken = async (): Promise<IRGETChatToken> => {
		const user = this.gerUser();
		const cachedData = this.cachedToken.get();
		if (cachedData && cachedData.userId === user.id) return cachedData;

		return this.Request.send(
			"GET",
			"/api/chats/token",
			{},
			omitSecondaryUsers(),
			{
				responseSchema: RGETChatTokenSchema,
			}
		).then((data: IRGETChatToken) => {
			return this.cachedToken.set({ ...data, userId: user.id });
		});
	};
}

const ChatTokenInStorageSchema = RGETChatTokenSchema.keys({
	userId: Joi.number().required(),
});

interface IChatTokenInStorage extends IRGETChatToken {
	userId: number;
}
