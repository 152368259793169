import { EditorState, AtomicBlockUtils, ContentState } from "draft-js";
import { IMAGE_ENTITY_TYPE } from "./html/image";
import { AUDIO_ENTITY_TYPE } from "./html/audio";

export const getStateAfterMediaInsertion = ({
	source,
	editorState,
	mimetype,
}: {
	source: string;
	mimetype: string | null;
	editorState: EditorState;
}): EditorState => {
	const contentState = editorState.getCurrentContent();
	const contentStateWithEntity = createEntityFromMedia(
		contentState,
		source,
		mimetype
	);

	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
	const newEditorState = EditorState.set(editorState, {
		currentContent: contentStateWithEntity,
	});
	const newState = AtomicBlockUtils.insertAtomicBlock(
		newEditorState,
		entityKey,
		"a"
	);
	return newState;
};

const createEntityFromMedia = (
	contentState: ContentState,
	source: string,
	mimetype: string | null
): ContentState => {
	if (isAudio(mimetype)) {
		return createAudioEntity(contentState, source);
	}
	return createImageEntity(contentState, source);
};

const createImageEntity = (contentState: ContentState, source: string) => {
	return contentState.createEntity(IMAGE_ENTITY_TYPE, "IMMUTABLE", {
		src: source,
	});
};

const createAudioEntity = (contentState: ContentState, source: string) => {
	return contentState.createEntity(AUDIO_ENTITY_TYPE, "IMMUTABLE", {
		src: source,
	});
};

const isAudio = (mimetype: string | null) => {
	return mimetype === "audio/mp3" || mimetype === "audio/mpeg";
};
