import { JoiMetaInfo } from "m-model-joi";
import { IStorage } from "m-model-common";
import Joi from "@app/utils/joi";
import stringify from "json-stable-stringify";

export class QueryMetaInfo extends JoiMetaInfo<IQueryMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super({ ...emptyMetaData }, storage, storageKey, QueryMetaInfoSchema);
	}

	clear() {
		this.replaceData(emptyMetaData);
	}

	updateLoadTime(query: Record<any, any>) {
		const key = this.queryToKey(query);
		this.setItem(key, new Date());
	}

	isLoaded(query: Record<any, any>) {
		const key = this.queryToKey(query);
		return !!this.data[key];
	}

	private queryToKey = (query: Record<any, any>): string => {
		return stringify(query);
	};
}

const QueryMetaInfoSchema = Joi.object().pattern(/.+/, Joi.date());

interface IQueryMetaInfo {
	[query: string]: Date | undefined;
}
const emptyMetaData: IQueryMetaInfo = {};
