import { UserType } from "@app/api/helper-schemas";
import { useGeneralContext } from "@app/components/general-context";
import { HeadmasterSidebar } from "@app/components/headmaster/sidebar";
import { ParentSidebar } from "@app/components/parents/sidebar";
import { TeacherSideBarWrapper } from "@app/components/teachers/sidebar";
import { StudentSidebar } from "@app/components/users/sidebar";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { SidebarType, SidebarWhazz } from "./helpers/interfaces";

export const SidebarDistributor = React.memo<{
	userType: UserType;
}>(({ userType }) => {
	const whazz = useWhazz();

	const isNewSidebarVisible = useGeneralContext("isNewSidebarVisible");

	if (!isNewSidebarVisible) return null;

	switch (userType) {
		case UserType.teacher:
			return <TeacherSideBarWrapper whazz={whazz} />;
		case UserType.student:
			return <StudentSidebar whazz={whazz} />;
		case UserType.parent:
			return <ParentSidebar whazz={whazz} />;
		case UserType.headmaster:
			return <HeadmasterSidebar whazz={whazz} />;
		default:
			return null;
	}
});

const useWhazz = () => {
	const match = useRouteMatch<any>();
	const params = match.params;
	return useMemo((): SidebarWhazz => {
		if (params.groupId) {
			return { type: SidebarType.group, groupId: params.groupId };
		}
		if (params.classroomId) {
			return {
				type: SidebarType.classroom,
				classroomId: params.classroomId,
			};
		}
		return { type: SidebarType.other };
	}, [params]);
};
