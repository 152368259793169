import React, { useEffect, useState, useMemo } from "react";
import { inject } from "@app/modules";
import {
	ICertificateCandidateStudent,
	CertificateCandidateStudentType,
	IRGETCertificateCandidateStudents,
} from "@app/api/certificates/validators";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import styles from "./styles/index.module.scss";
import listStyles from "./styles/student-list-old.module.css";
import { romanize } from "@app/utils/common";
import RollInNotification from "./roll-in-notification";
import { FormattedMessage } from "react-intl";
import CopyIcon from "../../assignments-2/img/copy-white.svg";
import AttentionIcon from "../../assignments-2/img/speaker.svg";
import classnames from "classnames";
import { copyTextToClipboard } from "@app/utils/clipboard";
import { IClassroomEnrollmentCode } from "@app/api/classrooms/enrollments/helper-schemas";
import { parseUrl } from "@app/utils/common";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useShouldShowNewVersionForTeacher } from ".";

type CandidatesListStateList = IRGETCertificateCandidateStudents;

enum FilterCandidatesTypes {
	all = "all",
	registred = "1",
	notRegistered = "0",
}

export const CertificateCandidatesList: React.FC = props => {
	const [candidates, setCandidates] = useState<CandidatesListStateList>();
	const {
		value: linkCopied,
		setTrue: setCopied,
		setFalse: unsetCopied,
	} = useBoolean();
	const [code, setCode] = useState<IClassroomEnrollmentCode>();
	const [filter, setFilter] = useState<FilterCandidatesTypes>(
		FilterCandidatesTypes.all
	);

	const { width } = useWindowSize();

	useEffect(() => {
		const loadData = async () => {
			await inject("CertificatesController")
				.getStudentCanditates({})
				.then(e => setCandidates(e));
			await inject("ClassroomEnrollmentCodesController")
				.create()
				.then(e => setCode(e));
		};

		loadData().catch(e =>
			openConfirmationPopup({
				text: getFormattedMessage("errorAlert"),
			})
		);
	}, []);

	const tabs = useMemo(
		(): TabValues => [
			{ label: "ყველა", value: FilterCandidatesTypes.all },
			{ label: "რეგისტრირებული", value: FilterCandidatesTypes.registred },
			{
				label: "არარეგისტრირებული",
				value: FilterCandidatesTypes.notRegistered,
			},
		],
		[]
	);

	const generateCandidatesList = useMemo(() => {
		if (!candidates) return null;
		const grades = Object.keys(candidates);
		return grades.map((e, i) => {
			const students = candidates[e] as ICertificateCandidateStudent[];
			if (!students) return null;
			const filteredStudents = students.filter(x =>
				filter === FilterCandidatesTypes.all
					? true
					: x.type === Number(filter)
			);
			if (filteredStudents.length === 0) return null;
			const registred = filteredStudents.filter(
				e => e.type === CertificateCandidateStudentType.registered
			);
			const notRegistred = filteredStudents.filter(
				e => e.type === CertificateCandidateStudentType.notRegistered
			);
			let globalIndex = 0;
			return (
				<div key={i}>
					<h3 className={listStyles.filtered_students__grade}>
						{`${romanize(Number(e))} კლასი`}
					</h3>

					{registred.length !== 0 && (
						<div
							className={listStyles.registered_students_container}
						>
							{registred.map((e, i) => {
								if (
									e.type !==
									CertificateCandidateStudentType.registered
								) {
									return null;
								}
								globalIndex++;
								return (
									<div
										key={i}
										className={
											listStyles.registered_students_box
										}
									>
										<div
											className={
												listStyles.students_box__student
											}
										>
											<span>{globalIndex}</span>
											<p>
												{e.user.firstname +
													" " +
													e.user.lastname}
											</p>
										</div>
										<div
											className={
												listStyles.students_box__registered
											}
										>
											{width <= 500 ? (
												<p>რეგისტრ.</p>
											) : (
												<p>რეგისტრირებული</p>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
					{notRegistred.length !== 0 && (
						<div
							className={
								listStyles.not_registered_students_container
							}
						>
							{notRegistred.map((e, indx) => {
								if (
									e.type !==
									CertificateCandidateStudentType.notRegistered
								) {
									return null;
								}
								globalIndex++;
								return (
									<div
										key={e.name + e.grade + indx}
										className={
											listStyles.not_registered_students_box
										}
									>
										<div
											className={
												listStyles.students_box__student
											}
										>
											<span>{globalIndex}</span>
											<p>{e.name}</p>
										</div>
										<div
											className={
												listStyles.students_box__not_registered
											}
										>
											{width <= 500 ? (
												<p>არარეგისტრ.</p>
											) : (
												<p>არარეგისტრირებული</p>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			);
		});
	}, [candidates, filter, width]);

	const showNewVersion = useShouldShowNewVersionForTeacher();

	if (!code) return null;

	return (
		<div className={styles.candidatesListContainer}>
			<CopyLink code={code.code} onCopy={setCopied} />
			<div className={listStyles.link_section}>
				<div className={listStyles.text_content}>
					<img
						src={AttentionIcon}
						alt=""
						className={listStyles.text_content__img}
					/>
					<div className={listStyles.text_content__text}>
						<div
							className={styles.firstStepText}
							style={{ textAlign: "justify" }}
						>
							• იმისათვის, რომ მოსწავლემ მიიღოს სერტიფიკატი,
							საჭიროა, მხოლოდ რეგისტრირებული იყოს პლატფორმაზე
							tvschool.ge.
						</div>
						<div
							className={styles.firstStepText}
							style={{ textAlign: "justify" }}
						>
							• დარეგისტრირებული მოსწავლეებისთვის სერტიფიკატების
							გაგზავნა შეგეძლებათ 6 ივნისიდან.
						</div>
					</div>
				</div>
			</div>

			<CustomTabs
				tabs={tabs}
				value={filter}
				onChange={(newFilter: FilterCandidatesTypes) =>
					setFilter(newFilter)
				}
			/>

			<div className={listStyles.filtered_students}>
				{generateCandidatesList}
			</div>

			<RollInNotification
				show={linkCopied}
				onClose={unsetCopied}
				title="სარეგისტრაციო ლინკი კოპირებულია"
				text="ლინკის გაგზავნის შემდეგ მოსწავლეები დარეგისტრირდებიან პლატფორმაზე და
				ცვლილება თქვენს გვერდზე ავტომატურად აისახება."
			/>
		</div>
	);
};

const CopyLink: React.FC<{ onCopy?: () => void; code: string }> = React.memo(
	({ code, onCopy }) => {
		const { width } = useWindowSize();
		return (
			<div className={listStyles.link_section__container}>
				<h2 className={listStyles.link_section__title}>
					გაუგზავნეთ სარეგისტრაციო ბმული არარეგისტრირებულ მოსწავლეებს
				</h2>

				<div className={listStyles.copyToReg}>
					<div className={listStyles.copyToReg_code}>
						{getFullRegistrationUrl(code)}
					</div>
					<button
						className={listStyles.copyToReg_copy}
						onClick={() => {
							copyTextToClipboard(getFullRegistrationUrl(code));
							onCopy?.();
						}}
					>
						{width <= 700 && (
							<img
								src={CopyIcon}
								alt=""
								className={listStyles.copyToReg_copyIcon}
							/>
						)}

						{width > 700 && (
							<FormattedMessage id="copyLinkForReg" />
						)}
					</button>
				</div>
			</div>
		);
	}
);

type TabValues = {
	label: string;
	value: string;
}[];

const CustomTabs: React.FC<{
	tabs: TabValues;
	value: string | null;
	onChange: (newValue: string) => void;
}> = React.memo(({ onChange, tabs, value }) => {
	const { width } = useWindowSize();
	return (
		<div className={listStyles.filter_students}>
			{tabs.map((e, i) => {
				const isSelected = value === null ? i === 0 : value === e.value;
				return (
					<button
						key={i}
						className={
							isSelected
								? classnames(
										listStyles.filter_button,
										listStyles.active
								  )
								: listStyles.filter_button
						}
						onClick={() => onChange(e.value)}
					>
						{width <= 500 && e.label !== "ყველა"
							? e.label === "რეგისტრირებული"
								? "რეგისტრ."
								: "არარეგისტ."
							: e.label}
					</button>
				);
			})}
		</div>
	);
});

const getFullRegistrationUrl = (code: string) => {
	const { origin } = parseUrl(window.location.href);
	return `${origin}/rs/${code}`;
};
