import Joi from "@app/utils/joi";
import { BasicDocumentSchema, BasicDocument } from "@app/api/interface";

export enum StudentGradeCategoryType {
	Numerical = "NUMR",
	Attendance = "ATND",
	Categorical = "CTGR",
	Textual = "TEXT",
}
export const StudentGradeCategoryTypes = [
	StudentGradeCategoryType.Attendance,
	StudentGradeCategoryType.Numerical,
	StudentGradeCategoryType.Categorical,
	StudentGradeCategoryType.Textual,
];

///

const commonMetadataSchema = Joi.object({
	displayColor: Joi.string().allow("", null),
	saveLastGrades: Joi.boolean(),
});

export interface IGradeCommonMetadata {
	displayColor?: string | null;
	saveLastGrades?: boolean;
}

///

export const AttendanceGradeCategoryMetadataSchema = commonMetadataSchema.keys({
	type: Joi.string()
		.valid(StudentGradeCategoryType.Attendance)
		.required(),
});
export interface IAttendanceGradeCategoryMetadata extends IGradeCommonMetadata {
	type: StudentGradeCategoryType.Attendance;
}

export const StudentGardeAttendanceSchema = Joi.boolean();
export type IStudentGardeAttendance = boolean;

///

export const NumericalGradeCategoryMetadataSchema = commonMetadataSchema.keys({
	type: Joi.string()
		.valid(StudentGradeCategoryType.Numerical)
		.required(),
	maxValue: Joi.number().required(),
});
export interface INumericalGradeCategoryMetadata extends IGradeCommonMetadata {
	type: StudentGradeCategoryType.Numerical;
	maxValue: number;
}

export const StudentGardeNumericalSchema = Joi.number();
export type IStudentGardeNumerical = number;

///

export const CategoricalGradeCategoryMetadataSchema = commonMetadataSchema.keys(
	{
		type: Joi.string()
			.valid(StudentGradeCategoryType.Categorical)
			.required(),
		categories: Joi.array()
			.items(
				Joi.object({
					id: Joi.number().required(),
					name: Joi.string().required(),
					shortName: Joi.string().required(),
				})
			)
			.required(),
	}
);
export interface ICategoricalGradeCategoryMetadata
	extends IGradeCommonMetadata {
	type: StudentGradeCategoryType.Categorical;
	categories: {
		id: number;
		name: string;
		shortName: string;
	}[];
}

export const StudentGardeCategoricalSchema = Joi.number();
export type IStudentGardeCategorical = number;

///

export const TextualGradeCategoryMetadataSchema = commonMetadataSchema.keys({
	type: Joi.string()
		.valid(StudentGradeCategoryType.Textual)
		.required(),
	maxLength: Joi.number()
		.integer()
		.allow(null)
		.required(),
});
export interface ITextualGradeCategoryMetadata extends IGradeCommonMetadata {
	type: StudentGradeCategoryType.Textual;
	maxLength: number | null;
}

export const StudentGardeTextualSchema = Joi.string();
export type IStudentGardeTextual = string;

///

export const GradeCategoryMetadataSchema = Joi.alternatives(
	AttendanceGradeCategoryMetadataSchema,
	NumericalGradeCategoryMetadataSchema,
	CategoricalGradeCategoryMetadataSchema,
	TextualGradeCategoryMetadataSchema
).byKey("type");
export type IStudentGradeCategoryMetadata =
	| IAttendanceGradeCategoryMetadata
	| INumericalGradeCategoryMetadata
	| ICategoricalGradeCategoryMetadata
	| ITextualGradeCategoryMetadata;

export const StudentGradeCategorySchema = BasicDocumentSchema.keys({
	metadata: GradeCategoryMetadataSchema.required(),
	name: Joi.string().required(),
	grades: Joi.array()
		.items(Joi.number().integer())
		.allow(null),
	isHidden: Joi.boolean(),
});
export interface IStudentGradeCategory extends BasicDocument {
	metadata: IStudentGradeCategoryMetadata;
	name: string;
	grades?: number[] | null;
	isHidden: boolean | undefined;
}
