import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import {
	StudentGradeCategoryType,
	StudentGradeCategoryTypes,
} from "../categories/helper-schemas";

export const TeacherCachedGradesSchema = Joi.object().keys({
	_id: Joi.objectId().required(),
	teacherId: Joi.number()
		.integer()
		.required(),
	gradeCategoryType: Joi.string()
		.valid(...StudentGradeCategoryTypes)
		.required(),
	gradeValues: Joi.array()
		.items(Joi.string().required())
		.required(),
});

export interface ITeacherCachedGrades {
	_id: ObjectId;
	teacherId: number;
	gradeCategoryType: StudentGradeCategoryType;
	gradeValues: string[];
}

export const IRGETTeacherCachedGradesManySchema = Joi.array().items(
	TeacherCachedGradesSchema
);
export type IRGETTeacherCachedGradesMany = ITeacherCachedGrades[];
