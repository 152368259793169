import { inject } from "@app/modules";
import { useModelDocs } from "m-model-react";
import { useResourceLoading } from "./fetch";

export const useGradeCategories = () => {
	const gradeCategories = useModelDocs(inject("GradeCategoryModel"));

	return useResourceLoading(
		gradeCategories,
		() => inject("GradesController").getCategories(),
		[]
	);
};
