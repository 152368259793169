import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds } from "./interfaces";
import { UserType } from "@app/api/helper-schemas";

export class MainAdminPermissions extends UserPermissions {
	constructor() {
		super(UserType.mainAdmin);
	}
	getAvailableClassrooms(): ObjectId[] {
		return [];
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	hasConfirmedChild(): boolean {
		return false;
	}

	getConfirmedChildrenIds(): number[] {
		return [];
	}

	getOwnLabelIds = () => {
		return [];
	};
	getViewableLabelIds = () => {
		return [];
	};

	canAccessAllClassrooms(): boolean {
		return true;
	}

	canAccessClassroom(): boolean {
		return true;
	}

	canStudyInClassroom(): boolean {
		return true;
	}

	canAccessGroup(): boolean {
		return true;
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return {
			isKnown: true,
			hasAll: true,
		};
	}

	canViewSchool() {
		return true;
	}
}
