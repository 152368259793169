import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	StatementSchema,
	IStatement,
	ICommonQuestionParts,
} from "../common-schemas";
import { markKeysForbidden } from "../../../../schemas/helper";

export enum FBContentDesignStructure {
	essay = "essay",
	essayWithFiles = "essayWithFiles",
}

const FBContentDesignStructures = [
	FBContentDesignStructure.essay,
	FBContentDesignStructure.essayWithFiles,
];

///

export enum FBItemType {
	Text = 0,
	Input = 1,
	NonCheckableInput = 2,
}

export enum CheckerType {
	NoOne = 0,
	OneSelf = 1,
	Editor = 2,
}

const CheckerTypeFields = [
	CheckerType.NoOne,
	CheckerType.OneSelf,
	CheckerType.Editor,
];

export enum CheckStrictness {
	LOW = 0,
	MEDIUM = 1,
	HIGH = 2,
}

export const StrictnessCoefficients = {
	[CheckStrictness.LOW]: 0.35,
	[CheckStrictness.MEDIUM]: 0.2,
	[CheckStrictness.HIGH]: 0,
};

export function getCoefficient(strictness: CheckStrictness): number {
	const coefficient = StrictnessCoefficients[strictness];
	if (coefficient === undefined) {
		throw new Error(`coefficient for strictness ${strictness} not found`);
	}
	return coefficient;
}

const CheckStrictnessFields = [
	CheckStrictness.LOW,
	CheckStrictness.MEDIUM,
	CheckStrictness.HIGH,
];

///

export const FBFileSchema = Joi.object({
	path: Joi.string().required(),
	name: Joi.string().required(),
	extra: Joi.any(),
});

export interface IFBFile {
	path: string;
	name: string;
	extra?: any;
}

///

export const FBTextItemSchema = StatementSchema.keys({
	type: Joi.number()
		.valid(FBItemType.Text)
		.required(),
	files: Joi.array().items(FBFileSchema),
});
export interface IFBTextItem extends IStatement {
	type: FBItemType.Text;
	files?: IFBFile[];
}

///

export enum FBInputSize {
	Small = "SMALL",
	Normal = "NORMAL",
	WholeLine = "WHOLE-LINE",
	Large = "LARGE",
	ExtraLarge = "XL",
}

const FBInputSizes = [
	FBInputSize.Small,
	FBInputSize.Normal,
	FBInputSize.WholeLine,
	FBInputSize.Large,
	FBInputSize.ExtraLarge,
];

export const IRInputItemSchema = Joi.object({
	id: Joi.number().required(),
	type: Joi.number()
		.valid(FBItemType.Input)
		.required(),
	size: Joi.string().valid(...FBInputSizes),
});

export const InputItemSchema = IRInputItemSchema.keys({
	score: Joi.number().required(),
	correctInputs: Joi.array()
		.items(StatementSchema)
		.required(),
	checkStrictness: Joi.number()
		.valid(...CheckStrictnessFields)
		.required(),
});

interface IRInputItem {
	id: number;
	type: FBItemType.Input;
	size?: FBInputSize;
}
export interface IInputItem extends IRInputItem {
	score: number;
	correctInputs: IStatement[];
	checkStrictness: CheckStrictness;
}

///

export const NonCheckableInputItemSchema = Joi.object({
	id: Joi.number().required(),
	type: Joi.number()
		.valid(FBItemType.NonCheckableInput)
		.required(),
	whoWillCheck: Joi.number()
		.valid(...CheckerTypeFields)
		.required(),
	size: Joi.string().valid(...FBInputSizes),
	uploadFiles: Joi.boolean(),
});

export interface INonCheckableInputItem {
	id: number;
	type: FBItemType.NonCheckableInput;
	whoWillCheck: CheckerType;
	size?: FBInputSize;
	uploadFiles?: boolean;
}

///

export const FillingBlanksContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.FillingBlanks)
		.required(),
	items: Joi.array()
		.items(
			Joi.alternatives([
				FBTextItemSchema,
				InputItemSchema,
				NonCheckableInputItemSchema,
			])
		)
		.required(),
	ignoreOrderOfInputs: Joi.boolean(),
	designStructure: Joi.string().valid(...FBContentDesignStructures),
});
export interface IFillingBlanksContent extends ICommonQuestionParts {
	type: ContentType.FillingBlanks;
	items: (IFBTextItem | IInputItem | INonCheckableInputItem)[];
	ignoreOrderOfInputs?: boolean;
	designStructure?: FBContentDesignStructure;
}

export const RFillingBlanksContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.FillingBlanks)
		.required(),
	items: Joi.array()
		.items(
			Joi.alternatives([
				FBTextItemSchema,
				IRInputItemSchema,
				NonCheckableInputItemSchema,
			])
		)
		.required(),
	ignoreOrderOfInputs: Joi.boolean(),
	designStructure: Joi.string().valid(...FBContentDesignStructures),
}).fork("explanation", markKeysForbidden);

export interface IRFillingBlanksContent extends ICommonQuestionParts {
	type: ContentType.FillingBlanks;
	items: (IFBTextItem | IInputItem | INonCheckableInputItem)[];
	ignoreOrderOfInputs?: boolean;
	designStructure?: FBContentDesignStructure;
}

///

const FBItemUserAnswerSchema = StatementSchema.keys({
	text: Joi.string().allow(""),
	files: Joi.array().items(FBFileSchema),
});

interface IFBItemUserAnswer extends IStatement {
	files?: IFBFile[];
}

export const FillingBlanksUserAnsSchema = Joi.object()
	.pattern(/\d+/, FBItemUserAnswerSchema)
	.allow(null);
export interface IFillingBlanksUserAns {
	[x: string]: IFBItemUserAnswer | undefined;
}
