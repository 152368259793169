import { classNamed } from "@pckgs/classed-components";
import React from "react";

export const Divider: React.FC<{
	size?: number | string;
	vertical?: boolean;
}> = React.memo(function Divider({ vertical = false, size = 20 }) {
	return (
		<div
			style={
				vertical
					? {
							display: "inline-block",
							width: size,
					  }
					: {
							height: size,
					  }
			}
		/>
	);
});

export const CenterContainer = classNamed("div")(
	"my-2 flex align-middle justify-center"
);
