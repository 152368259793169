import { inject } from "@app/modules";
import { IRequest } from "../requests";
import {
	APOSTSummonsSchema,
	IAGETCustomSummonses,
	IAPOSTSummons,
	IRPOSTSummons,
	RPOSTSummonsSchema,
	IAGETMySummonses,
	IRGETMySummonses,
	AGETMySummonsesSchema,
	RGETMySummonsesSchema,
} from "./validators";
import { Summons } from "@app/models/summons";

export class SummonsesController {
	private readonly Request: IRequest;

	private readonly _SummonsModel = inject("SummonsModel");
	private readonly getUser = inject("assertAndGetClassroomsUser");

	constructor(request: IRequest) {
		this.Request = request;
	}

	getMySummonses = async (args: IAGETMySummonses): Promise<Summons[]> => {
		const user = this.getUser();
		if (this._SummonsModel.meta.isLoaded(args)) {
			return this._SummonsModel.getMySummonses(args, user.id);
		}
		return this.Request.send("GET", "/api/summonses", args, null, {
			requestSchema: AGETMySummonsesSchema,
			responseSchema: RGETMySummonsesSchema,
		}).then((data: IRGETMySummonses) => {
			this._SummonsModel.meta.updateLoadTime(args);
			return this._SummonsModel.loadManySync(data, "replaceAll");
		});
	};

	create = (args: IAPOSTSummons): Promise<IRPOSTSummons> =>
		this.Request.send("POST", "/api/summonses", args, null, {
			requestSchema: APOSTSummonsSchema,
			responseSchema: RPOSTSummonsSchema,
		}).then((data: IRPOSTSummons) => {
			return {
				...data,
				summons: this._SummonsModel.loadOneSync({
					...data.summons,
					isInAudience: false, // TODO: get from backend
				}),
			};
		});

	downloadCustomSummonses = async (
		args: IAGETCustomSummonses
	): Promise<any> => {
		return this.Request.send("GET", "/api/summonses/db/custom", args, {
			responseType: "blob",
		});
	};
}
