import { IRequest } from "../requests";

import {
	IAGETManyStudentComments,
	IAPUTComments,
	IAGETSingleStudentComment,
	RGETManyStudentCommentsSchema,
	RGETSingleStudentCommentSchema,
	IRGETManyStudentComments,
} from "./validators";
import { IComment } from "./helper-schemas";
import { collmise } from "collmise";

export class CommentsController {
	constructor(private readonly Request: IRequest) {}

	private commentPromises = collmise<number, IComment>({
		collectingTimeoutMS: 15,
	}).addCollector<"many", IRGETManyStudentComments>({
		name: "many",
		findOne: (studentId, many) => many.find(e => e.studentId === studentId),
		onRequest: studentIds =>
			this.Request.send<
				IRGETManyStudentComments,
				IAGETManyStudentComments
			>("GET", "/api/comments/student-comments", { studentIds }, null, {
				responseSchema: RGETManyStudentCommentsSchema,
			}),
	});

	async getManyStudentComments(
		args: IAGETManyStudentComments
	): Promise<IRGETManyStudentComments> {
		return this.commentPromises.collectors.many(args.studentIds).request();
	}

	async getSingleStudentComment(
		args: IAGETSingleStudentComment
	): Promise<IComment> {
		return this.commentPromises
			.on(args.studentId)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/comments/student-comment",
					args,
					null,
					{ responseSchema: RGETSingleStudentCommentSchema }
				)
			);
	}

	async updateComments(args: IAPUTComments): Promise<void> {
		return this.Request.send("PUT", "/api/comments/save-comments", args);
	}
}
