import createFocusPlugin from "draft-js-focus-plugin";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import createBlockDndPlugin from "draft-js-drag-n-drop-plugin";
import { composeDecorators } from "draft-js-plugins-editor";
import createImagePlugin from "draft-js-image-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";

export const focusPlugin = createFocusPlugin();
export const resizeablePlugin = (createResizeablePlugin as any)({
	horizontal: "absolute",
	// initialWidth: "auto",
});
export const blockDndPlugin = createBlockDndPlugin();
export const linkifyPlugin = createLinkifyPlugin({
	target: "_blank", // default is '_self'
});

const imageDecorator = composeDecorators(
	resizeablePlugin.decorator,
	blockDndPlugin.decorator
);
export const imagePlugin = createImagePlugin({ decorator: imageDecorator });
