import { ClassroomSchema, IClassroom, ISemester } from "./helper-schemas";
import Joi from "@app/utils/joi";
import {
	insertStripKeys,
	markKeysForbidden,
	ToInsertKeys,
	updateStripKeys,
	markKeysOptional,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";
import { OptionalKeys, LooseEither } from "@app/utils/generics";
import { ClassTimeSchema, IClassTime } from "./helper-schemas-2";

///

export const AGETManyClassroomsByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IAGETManyClassroomsByIds {
	ids: ObjectId[];
}

export const RGETManyClassroomsByIdsSchema = Joi.array().items(ClassroomSchema);
export type IRGETManyClassroomsByIds = IClassroom[];

export const AGETManyClassroomsByIdsWithoutFilteringSchema = AGETManyClassroomsByIdsSchema;
export type IAGETManyClassroomsByIdsWithoutFiltering = IAGETManyClassroomsByIds;
export const RGETManyClassroomsByIdsWithoutFilteringSchema = RGETManyClassroomsByIdsSchema;
export type IRGETManyClassroomsByIdsWithoutFiltering = IClassroom[];

/* create classroom */
const classroomPostForbiddenKeys = ["studentIds", "course"] as const;
export const APOSTCreateClassroomSchema = ClassroomSchema.keys(insertStripKeys)
	.fork(classroomPostForbiddenKeys, markKeysForbidden)
	.keys({
		originalCourseId: Joi.objectId().required(),
		isExtendable: Joi.boolean(),
		isProgrammaticallyHidden: Joi.any().strip(),
	});
export type IAPOSTCreateClassroom = ToInsertKeys<
	IClassroom,
	never,
	typeof classroomPostForbiddenKeys[number] | "isProgrammaticallyHidden"
> & {
	originalCourseId: ObjectId;
	isExtendable?: boolean;
};

export const RPOSTCreateClassroomSchema = ClassroomSchema;
export type IRPOSTCreateClassroom = IClassroom;

/* update classroom */
const classroomEditForbiddenKeys = ["studentIds", "course"] as const;
const classroomEditOptionalKeys = [
	"name",
	"grade",
	"subjects",
	"classTimes",
	"teacherId",
] as const;
export const APUTUpdateClassroomSchema = ClassroomSchema.keys({
	isExtendable: Joi.boolean(),
	isProgrammaticallyHidden: Joi.any().strip(),
	packet: Joi.any().strip(),
})
	.keys(updateStripKeys)
	.fork(classroomEditForbiddenKeys, markKeysForbidden)
	.fork(classroomEditOptionalKeys, markKeysOptional);
export type IAPUTUpdateClassroom = ToUpdateKeys<
	IClassroom,
	typeof classroomEditOptionalKeys[number],
	| typeof classroomEditForbiddenKeys[number]
	| "isProgrammaticallyHidden"
	| "packet"
> & {
	isExtendable?: boolean;
};

export const RPUTUpdateClassroomSchema = ClassroomSchema;
export type IRPUTUpdateClassroom = IClassroom;

/* get classroom */
export type IRGETGetClassroom = OptionalKeys<IClassroom, "studentIds">;

/* get all classrooms */
export const AGETAllClassroomsSchema = Joi.object({
	teacherId: Joi.alternatives(
		Joi.number(),
		Joi.array().items(Joi.number().required())
	).required(),
	isArchived: Joi.boolean(),
});
export interface IAGETAllClassrooms {
	teacherId: number | number[];
	isArchived?: boolean;
}

export const RGETAllClassroomsSchema = Joi.array().items(ClassroomSchema);
export type IRGETGetAllClassrooms = IClassroom[];

/* get classroom students */
export const AGETClassroomStudentsSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETClassroomStudents {
	_id: ObjectId;
}

export const RGETClassroomStudentsSchema = Joi.object().pattern(
	/[\d]+/,
	Joi.object({
		id: Joi.number()
			.integer()
			.required(),
		username: Joi.string().allow(null),
		firstname: Joi.string().required(),
		lastname: Joi.string().required(),
	})
);

export type IRGETClassroomStudents = Record<
	string,
	| {
			id: number;
			username?: string | null;
			firstname: string;
			lastname: string;
	  }
	| undefined
>;

/* remove students */
export const APUTRemoveStudentsFromClassroomSchema = Joi.object({
	_id: Joi.objectId().required(),
	studentIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
});
export interface IAPUTRemoveStudentsFromClassroom {
	_id: ObjectId;
	studentIds: number[];
}

export type IRPUTRemoveStudentsFromClassroom = IRPUTUpdateClassroom;

///

export const APOSTEnrollByAssignmentCodeSchema = Joi.object({
	code: Joi.string().required(),
});

export interface IAPOSTEnrollByAssignmentCode {
	code: string;
}

///

const AffectingCourseByClassroomsSchema = Joi.object({
	classroomIds: Joi.array()
		.items(Joi.objectId().required())
		.required(),
	includeAllRelatedClassrooms: Joi.boolean().required(),
});

export interface IAffectingCourseByClassrooms {
	classroomIds: ObjectId[];
	includeAllRelatedClassrooms: boolean;
}

const AffectingCoursesByCourseIdSchema = Joi.object({
	originalCourseId: Joi.objectId().required(),
	grade: Joi.number().required(),
	subject: Joi.objectId().required(),
	name: Joi.string()
		.allow("")
		.required(),
	schoolId: Joi.number().required(),
	createHiddenClassroomIfNotFound: Joi.boolean().required(),
	includeArchivedClassrooms: Joi.boolean(),
});

export interface IAffectingCoursesByCourseId {
	originalCourseId: ObjectId;
	grade: number;
	subject: ObjectId;
	name: string;
	schoolId: number;
	createHiddenClassroomIfNotFound: boolean;
	includeArchivedClassrooms?: boolean;
}
export const APOSTAffectingCoursesExistanceSchema = Joi.alternatives(
	AffectingCourseByClassroomsSchema,
	AffectingCoursesByCourseIdSchema
);

export type IAPOSTAffectingCoursesExistance = LooseEither<
	IAffectingCourseByClassrooms,
	IAffectingCoursesByCourseId
>;

export const RPOSTAffectingCoursesExistanceSchema = Joi.object({
	classroomCourseIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	classroomIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	newHiddenClassroom: ClassroomSchema,
});

export interface IRPOSTAffectingCoursesExistance {
	classroomCourseIds: ObjectId[];
	classroomIds: ObjectId[];
	newHiddenClassroom?: IClassroom;
}

///

export const AGETClassroomByCourseSchema = Joi.object({
	classroomCourseId: Joi.objectId().required(),
});

export interface IAGETClassroomByCourse {
	classroomCourseId: ObjectId;
}

export const RGETClassroomByCourseSchema = ClassroomSchema;

export type IRGETClassroomByCourse = IClassroom;

///

export const APUTUpdateClassroomClassTimesSchema = Joi.array().items(
	Joi.object({
		classroomId: Joi.objectId(),
		classTimes: Joi.array()
			.items(ClassTimeSchema)
			.required(),
	})
);
export type IAPUTUpdateClassroomClassTimes = {
	classroomId: ObjectId;
	classTimes: IClassTime[];
}[];

export const RPUTUpdateClassroomClassTimesSchema = Joi.array().items(
	ClassroomSchema
);
export type IRPUTUpdateClassroomClassTimes = IClassroom[];

///

export const AGETClassroomGradebookIdSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});
export interface IAGETClassroomGradebookId {
	classroomId: ObjectId;
}

export const RGETClassroomGradebookIdSchema = Joi.object({
	gradebookId: Joi.objectId().required(),
});
export interface IRGETClassroomGradebookId {
	gradebookId: ObjectId;
}

///

export interface IPUTArchiveBySchoolId {
	schoolId: number[];
	semester: ISemester;
}

///

export const AGETClassroomsSlugSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});
export interface IAGETClassroomsSlug {
	classroomId: ObjectId;
}
export const RGETClassroomsSlugSchema = Joi.object({
	slug: Joi.string().required(),
});
export interface IRGETClassroomsSlug {
	slug: string;
}

///

export const AGETHeadmastersOfClassroomSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});

export interface IAGETHeadmastersOfClassroom {
	classroomId: ObjectId;
}

export const RGETHeadmastersOfClassroomSchema = Joi.object({
	headmasterIds: Joi.array()
		.items(Joi.number())
		.required(),
});
export interface IRGETHeadmastersOfClassroom {
	headmasterIds: number[];
}

///

export const APUTHeadmastersToClassroomSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	headmasterIds: Joi.array()
		.items(Joi.number())
		.required(),
});

export interface IAPUTHeadmastersToClassroom {
	classroomId: ObjectId;
	headmasterIds: number[];
}

