import * as React from "react";
import FillingBlanks, { IUserAnswerCorrectness } from "./class";
import memoizeOne from "memoize-one";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	AnyComponentOptionalProps,
} from "../../../../utils/generics";
import { css, cx } from "emotion";
import {
	Explanation,
	IExplanationProps,
	IExplanationStyles,
	IExplanationTexts,
} from "../common/explanation";
import { IContentProps } from "../schemas";
import {
	IFillingBlanksContent,
	IFillingBlanksUserAns,
	IInputItem,
	IRFillingBlanksContent,
	FBItemType,
	FBContentDesignStructure,
	FBInputSize,
	INonCheckableInputItem,
} from "../../../../schemas/questions/contnets/filling-blanks/schema";
import {
	IItemContainerProps,
	IItemTextProps,
	ItemContainer,
} from "../common/stats";
import { IItemEditEditProps } from "../common/items-edit";
import {
	IViewModeChangeComponentProps,
	IViewModeStyles,
	IViewModeTexts,
	ViewMode,
	ViewModeChangeComponent,
} from "../common/view-mode";
import { mergeComponentObjects, mergeStylesObjects } from "../../../../utils";
import {
	IQuestionItemsAssessment,
	IQuestionItemAssessment,
} from "@tests-core/schemas/questions/helper-schemas";

export interface IFBPassableProps {
	styles?: IFillintBlanksStyles;
	components?: IFillingBlanksComponents;
	texts?: ITexts;
	hideViewMode?: boolean;
	defaultViewMode?: ViewMode;
}

type IFillingBlanksComponents = IFBItemsContainerComponents & {
	container?: AnyComponent<IFBItemsContainerProps>;
};

type IFillintBlanksStyles = IFBItemsContainerStyles & {
	container?: string;
};

interface ITexts {
	input?: IFBInputContainerText;
	explanation?: IExplanationTexts;
	nonCheckableInput?: IFBNonCeckableInputContainerText;
	viewMode?: IViewModeTexts;
}

export interface IProps extends IContentProps<any> {
	content: IRFillingBlanksContent | IFillingBlanksContent;
	shuffleKey?: number;
	userAnswer?: IFillingBlanksUserAns;
	onUserAnswerChange: (userAnswer: IFillingBlanksUserAns) => void;
	styles?: IFillintBlanksStyles;
	components?: IFillingBlanksComponents;
	texts?: ITexts;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	hideViewMode?: boolean;
	itemsAssessments?: IQuestionItemsAssessment;
	displayItemAssessments?: boolean;
	defaultViewMode?: ViewMode;
}

class FillingBlanksContainer extends React.PureComponent<IProps> {
	render() {
		const QContainer: AnyComponent<IFBItemsContainerProps> =
			this.props.components?.container || FBItemsContainer;
		return (
			<div className={this.props.styles?.container}>
				<QContainer {...this.props} />
			</div>
		);
	}
}

type IItem =
	| IRFillingBlanksContent["items"][number]
	| IFillingBlanksContent["items"][number];

interface IFBItemsContainerStyles {
	statement?: {
		container?: string;
		text?: string;
	};
	input?: IFBInputContainerStyles;
	nonCheckableInput?: IFBNonCeckableInputContainerStyles;
	explanation?: IExplanationStyles;
	viewMode?: IViewModeStyles;
}

interface IFBItemsContainerComponents {
	statement?: {
		container?: AnyComponent<IItemContainerProps>;
		text?: AnyComponent<IItemTextProps>;
		files?: {
			container?: AnyComponent<IFBFilesContainerProps>;
		};
	};
	input?: {
		container?: AnyComponent<IFBInputContainerProps>;
	} & IFBInputContainerComponents;
	nonCheckable?: {
		container?: AnyComponent<IFBNonCheckableInputContainerProps>;
		inner?: IFBNonCheckableInputContainerProps["components"];
	};

	explanation?: AnyComponent<IExplanationProps>;
	items?: {
		after?: AnyComponentOptionalProps<{
			content: IFBItemsContainerProps["content"];
			viewMode: ViewMode;
		}>;
	};
	viewMode?: AnyComponent<IViewModeChangeComponentProps>;
}

interface IFBItemsContainerProps {
	content: IRFillingBlanksContent | IFillingBlanksContent;
	userAnswer?: IFillingBlanksUserAns;
	onUserAnswerChange: (userAnswer: IFillingBlanksUserAns) => void;
	styles?: IFBItemsContainerStyles;
	components?: IFBItemsContainerComponents;
	texts?: ITexts;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	hideViewMode?: boolean;
	itemsAssessments?: IQuestionItemsAssessment;
	displayItemAssessments?: boolean;
	defaultViewMode?: ViewMode;
}

const statementContainerClassName = css({
	display: "inline",
});

const statementClassName = css({
	display: "inline",
});

interface IFBItemsContainerState {
	viewMode: ViewMode;
}

export class FBItemsContainer extends React.PureComponent<
	IFBItemsContainerProps,
	IFBItemsContainerState
> {
	state: IFBItemsContainerState = {
		viewMode:
			this.props.defaultViewMode === undefined
				? "userAnswer"
				: this.props.defaultViewMode,
	};

	defaultComponents = {
		statement: {
			container: ItemContainer,
		},
		input: {
			container: FBInputContainer,
		},
		nonCheckable: {
			container: FBNonCheckableInputContainer,
		},
		explanation: Explanation,
		viewMode: ViewModeChangeComponent,
	} as NotUndefined<IFBItemsContainerProps["components"]>;

	defaultStyles = {
		statement: {
			container: statementContainerClassName,
			text: statementClassName,
		},
		explanation: {},
	} as NotUndefined<IFBItemsContainerProps["styles"]>;

	getStyles = memoizeOne((styles: IFBItemsContainerProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	getComponents = memoizeOne(
		(components: IFBItemsContainerProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	getItems = memoizeOne(
		(
			content: IRFillingBlanksContent | IFillingBlanksContent,
			userAnswer: IFBItemsContainerProps["userAnswer"],
			displayAnswer: boolean
		): { items: IItem[]; extraInfo?: IFBExtraInfo } => {
			if (displayAnswer) {
				const cont = new FillingBlanks(
					content as IFillingBlanksContent
				);
				const correctness = cont.getEachAnswerCorrectness(
					userAnswer || null
				);
				return { items: cont.items, extraInfo: correctness };
			}
			return { items: content.items };
		}
	);

	onViewModeChange = (viewMode: ViewMode) => {
		this.setState({
			viewMode,
		});
	};

	render() {
		const styles = this.getStyles(this.props.styles);
		const components = this.getComponents(this.props.components);
		const StatementContainerComponent = components.statement!.container!;
		const InputItemContainerComponent = components.input!.container!;
		const NonCheckableItemContainerComponent = components!.nonCheckable!
			.container!;
		const ViewModeComponent = components.viewMode!;
		const ExpContainer = components.explanation!;
		let { viewMode } = this.state;
		if (!this.props.displayAnswer && viewMode !== "userAnswer") {
			setTimeout(() => this.onViewModeChange("userAnswer"), 0);
			viewMode = "userAnswer";
		}
		const { items, extraInfo } = this.getItems(
			this.props.content,
			this.props.userAnswer,
			this.props.displayAnswer
		);
		const hasAtLeastOneCheckableInput = (items as IItem[]).some(
			item => item.type === FBItemType.Input
		);
		const ChoicesAfter = components.items?.after;
		const FilesContainer = components.statement?.files?.container;
		const designStructure = this.props.content.designStructure || null;
		return (
			<div>
				{(items as IItem[]).map((item, i) => {
					if (item.type === FBItemType.Text) {
						return (
							<React.Fragment key={`${item.id}-${viewMode}`}>
								<StatementContainerComponent
									index={i}
									components={components.statement!}
									styles={styles.statement!}
									statement={item}
								/>
								{FilesContainer && (
									<FilesContainer
										item={item}
										viewMode={viewMode}
										extraInfo={extraInfo}
										displayItemAssessment={
											this.props.displayItemAssessments
										}
										designStructure={designStructure}
									/>
								)}
							</React.Fragment>
						);
					}
					if (item.type === FBItemType.Input) {
						return (
							<InputItemContainerComponent
								key={`${item.id}-${viewMode}`}
								item={item}
								onUserAnswerChange={
									this.props.onUserAnswerChange
								}
								disableEditingAnswer={
									this.props.disableEditingAnswer
								}
								userAnswer={this.props.userAnswer}
								viewMode={viewMode}
								extraInfo={extraInfo}
								styles={styles.input}
								texts={
									this.props.texts && this.props.texts.input
								}
								designStructure={designStructure}
							/>
						);
					}
					if (item.type === FBItemType.NonCheckableInput) {
						return (
							<NonCheckableItemContainerComponent
								key={`${item.id}-${viewMode}`}
								item={item}
								onUserAnswerChange={
									this.props.onUserAnswerChange
								}
								disableEditingAnswer={
									this.props.disableEditingAnswer
								}
								userAnswer={this.props.userAnswer}
								viewMode={viewMode}
								extraInfo={extraInfo}
								styles={styles.nonCheckableInput}
								components={components.nonCheckable?.inner}
								texts={this.props.texts?.nonCheckableInput}
								displayItemAssessment={
									this.props.displayItemAssessments
								}
								itemsAssessment={
									this.props.itemsAssessments?.[item.id]
								}
								designStructure={designStructure}
							/>
						);
					}
					return (
						<div key={`${(item as any).id}-${viewMode}`}>
							Unsupported item type {(item as any).type}
						</div>
					);
				})}
				{ChoicesAfter && (
					<ChoicesAfter
						content={this.props.content}
						viewMode={viewMode}
					/>
				)}
				{this.props.displayAnswer &&
					!this.props.hideViewMode &&
					hasAtLeastOneCheckableInput && (
						<ViewModeComponent
							mode={viewMode}
							onChange={this.onViewModeChange}
							styles={styles.viewMode}
							texts={
								this.props.texts && this.props.texts.viewMode
							}
						/>
					)}
				<ExpContainer
					explanation={
						(this.props.content as IFillingBlanksContent)
							.explanation
					}
					show={!!this.props.displayAnswer}
					styles={styles.explanation}
					texts={this.props.texts && this.props.texts.explanation}
				/>
			</div>
		);
	}
}

type IFBExtraInfo = IUserAnswerCorrectness;

interface IFBInputContainerComponents {
	innerInput?: AnyComponent<
		IItemEditEditProps & { extraInfo?: IFBExtraInfo } & {
			styles?: IFBInputContainerStyles;
		}
	>;
}

interface IFBInputContainerStyles {
	container?: string;
	text?: string;
	correct?: string;
	incorrect?: string;
}

interface IFBInputContainerText {
	placeholder?: string;
}

export interface IFBInputContainerProps {
	item: IItem;
	userAnswer?: IFillingBlanksUserAns;
	onUserAnswerChange: (userAnswer: IFillingBlanksUserAns) => void;
	components?: IFBInputContainerComponents;
	extraInfo?: IFBExtraInfo;
	styles?: IFBInputContainerStyles;
	texts?: IFBInputContainerText;
	disableEditingAnswer: boolean;
	viewMode: ViewMode;
	designStructure: FBContentDesignStructure | null;
}

interface IFBInputContainerState {
	stat: { id: number; text: string };
}

const FBInputContainerClassName = css({
	display: "inline-block",
	border: "1px solid #ccc",
	borderRadius: 4,
	padding: "5px 6px",
	verticalAlign: "middle",
});

const getFBInputContainerCSS = (size: FBInputSize = FBInputSize.Normal) =>
	css({
		width:
			size === FBInputSize.WholeLine ||
			size === FBInputSize.Large ||
			size === FBInputSize.ExtraLarge
				? "100%"
				: undefined,
		display:
			size === FBInputSize.WholeLine ||
			size === FBInputSize.Large ||
			size === FBInputSize.ExtraLarge
				? "block"
				: undefined,
		margin:
			size === FBInputSize.Normal || size === FBInputSize.Small
				? 5
				: "5px 0",
		input: {
			width: size === FBInputSize.WholeLine ? "100%" : undefined,
		},
		textarea: {
			width: "100%",
			resize: "vertical",
			minHeight:
				size === FBInputSize.Large
					? 80
					: size === FBInputSize.ExtraLarge
					? 120
					: undefined,
		},
	});

const FBInputClassName = css({
	minWidth: 200,
});

export class FBInputContainer extends React.PureComponent<
	IFBInputContainerProps,
	IFBInputContainerState
> {
	defaultTexts = {
		placeholder: "Enter Answer",
	} as NotUndefinedAtAll<IFBInputContainerProps["texts"]>;

	getTexts = memoizeOne((texts: IFBInputContainerProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	defaultComponents = (size: FBInputSize | undefined) =>
		({
			innerInput: props => {
				let containerCSS = cx(props.styles!.container);
				if (props.extraInfo) {
					const myExtraInfo = props.extraInfo[this.props.item.id];
					if (myExtraInfo) {
						if (myExtraInfo.totalScore === myExtraInfo.userScore) {
							containerCSS = cx(
								containerCSS,
								props.styles!.correct
							);
						} else {
							containerCSS = cx(
								containerCSS,
								props.styles!.incorrect
							);
						}
					}
				}
				const inputProps = {
					value: props.stat.text,
					onChange: this.onInputChangeHelper,
					className: props.styles!.text,
					placeholder: props.placeholder,
					readOnly: !!props.disableEditing,
				};
				return (
					<div className={containerCSS}>
						{size === FBInputSize.Large ||
						size === FBInputSize.ExtraLarge ? (
							<textarea {...inputProps} />
						) : (
							<input {...inputProps} />
						)}
					</div>
				);
			},
		} as NotUndefined<IFBInputContainerProps["components"]>);

	getComponents = memoizeOne(
		(
			components: IFBInputContainerProps["components"],
			size: FBInputSize | undefined
		) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents(size)
			);
		}
	);

	getDefaultStyles = (size: FBInputSize | undefined) =>
		({
			text: cx(
				FBInputClassName,
				css`
					border: none;
					outline: none;
					background: transparent;
				`
			),
			container: cx(
				FBInputContainerClassName,
				getFBInputContainerCSS(size)
			),
			correct: css`
				border-color: green;
			`,
			incorrect: css`
				border-color: red;
			`,
		} as NotUndefined<IFBInputContainerProps["styles"]>);

	getStyles = memoizeOne(
		(
			styles: IFBInputContainerProps["styles"],
			size: FBInputSize | undefined
		) => {
			return mergeStylesObjects(
				styles || {},
				this.getDefaultStyles(size)
			);
		}
	);

	constructor(props: IFBInputContainerProps) {
		super(props);
		let text = "";
		if (props.userAnswer) {
			const ans = props.userAnswer[props.item.id];
			if (ans) text = ans.text;
		}
		this.state = {
			stat: { id: props.item.id, text },
		};
	}

	onInputChangeHelper = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		this.onChange({
			id: this.state.stat.id,
			text: e.target.value,
		});
	};

	onChange = (stat: IFBInputContainerState["stat"]) => {
		this.setState(
			{
				stat,
			},
			() => {
				const statement = this.state.stat;
				const userAns = { ...this.props.userAnswer };
				userAns[statement.id] = stat;
				this.props.onUserAnswerChange(userAns);
			}
		);
	};

	render() {
		if (
			this.props.item.type !== FBItemType.Input &&
			this.props.item.type !== FBItemType.NonCheckableInput
		) {
			return null;
		}
		const styles = this.getStyles(this.props.styles, this.props.item.size);
		const components = this.getComponents(
			this.props.components,
			this.props.item.size
		);
		const texts = this.getTexts(this.props.texts);
		const ItemInput = components.innerInput!;
		if (
			this.props.viewMode === "correctAnswer" &&
			this.props.item.type === FBItemType.Input
		) {
			const correctInputs = (this.props.item as IInputItem).correctInputs;
			if (correctInputs.length === 1) {
				return (
					<div
						key={correctInputs[0].id}
						dangerouslySetInnerHTML={{
							__html: correctInputs[0].text,
						}}
						className={itemCorrectAnsCSS}
					/>
				);
			}
			return (
				<div className={itemCorrectAnsCSS}>
					{(this.props.item as IInputItem).correctInputs.map(e => (
						<div
							key={e.id}
							dangerouslySetInnerHTML={{ __html: e.text }}
							className={itemAlternativeCorrectAnsCSS}
						/>
					))}
				</div>
			);
		}
		return (
			<ItemInput
				stat={this.state.stat}
				onChange={this.onChange}
				styles={styles}
				placeholder={texts.placeholder}
				disableEditing={this.props.disableEditingAnswer}
				extraInfo={this.props.extraInfo}
			/>
		);
	}
}

interface IFBNonCheckableInputContainerComponents {
	answerInput?: {
		Container?: AnyComponent<IFBInputContainerProps>;
		components?: IFBInputContainerComponents;
	};
	Assessment?: AnyComponent<IFBNonCheckableInputAssessmentProps>;
	uploads?: {
		container?: AnyComponent<IFBUploadsContainerProps>;
	};
}

interface IFBNonCeckableInputContainerStyles {
	Container?: string;
	CheckedContainer?: string;
	answerInput?: IFBInputContainerStyles;
	assessment?: IFBNonCeckableInputAssessmentStyles;
}

interface IFBNonCeckableInputContainerText {
	answerInput?: IFBInputContainerText;
	assessment?: {
		before?: string;
		after?: string;
	};
}

export interface IFBNonCheckableInputContainerProps {
	item: IItem;
	userAnswer?: IFillingBlanksUserAns;
	onUserAnswerChange: (userAnswer: IFillingBlanksUserAns) => void;
	extraInfo?: IFBExtraInfo;
	disableEditingAnswer: boolean;
	viewMode: ViewMode;
	itemsAssessment?: IQuestionItemAssessment;
	displayItemAssessment?: boolean;
	components?: IFBNonCheckableInputContainerComponents;
	styles?: IFBNonCeckableInputContainerStyles;
	texts?: IFBNonCeckableInputContainerText;
	designStructure: FBContentDesignStructure | null;
}

export const FBNonCheckableInputContainer: React.FC<IFBNonCheckableInputContainerProps> = props => {
	const size = (props.item as INonCheckableInputItem).size;
	const defaultStyles = React.useMemo(
		() =>
			({
				Container: getNonCheckableInputContainerCSS(size),
				CheckedContainer: nonCheckableInputContainerCheckedCSS,
			} as NotUndefinedAtAll<IFBNonCeckableInputContainerStyles>),
		[size]
	);

	const defaultComponents: NotUndefined<IFBNonCheckableInputContainerComponents> = React.useRef(
		{
			answerInput: {
				Container: FBInputContainer,
			},
			Assessment: FBNonCeckableInputAssessment,
		}
	).current;

	const getStyles = React.useCallback(
		(styles: IFBNonCheckableInputContainerProps["styles"]) => {
			return mergeStylesObjects(styles || {}, defaultStyles);
		},
		[defaultStyles]
	);
	const getComponents = React.useCallback(
		(components: IFBNonCheckableInputContainerProps["components"]) => {
			return mergeComponentObjects(components || {}, defaultComponents);
		},
		[defaultComponents]
	);
	const styles = getStyles(props.styles);
	const components = getComponents(props.components);
	const InputContainer = components.answerInput!.Container as AnyComponent<
		IFBInputContainerProps
	>;
	const UploadsContainer = components.uploads?.container;
	console.log("UploadsContainer", UploadsContainer);
	const AssessmentContainer = components.Assessment!;
	return (
		<div
			className={cx(
				styles.Container,
				props.displayItemAssessment && props.itemsAssessment
					? styles.CheckedContainer
					: ""
			)}
		>
			<InputContainer
				item={props.item}
				userAnswer={props.userAnswer}
				onUserAnswerChange={props.onUserAnswerChange}
				extraInfo={props.extraInfo}
				components={
					components.answerInput!
						.components as IFBInputContainerComponents
				}
				styles={styles.answerInput}
				texts={props.texts && props.texts.answerInput}
				disableEditingAnswer={props.disableEditingAnswer}
				viewMode={props.viewMode}
				designStructure={props.designStructure}
			/>
			{UploadsContainer && (
				<UploadsContainer
					item={props.item}
					onUserAnswerChange={props.onUserAnswerChange}
					disableEditingAnswer={props.disableEditingAnswer}
					userAnswer={props.userAnswer}
					viewMode={props.viewMode}
					extraInfo={props.extraInfo}
					designStructure={props.designStructure}
				/>
			)}
			<AssessmentContainer
				itemAssessment={props.itemsAssessment}
				display={!!props.displayItemAssessment}
				styles={styles.assessment}
				texts={props.texts && props.texts.assessment}
				itemId={props.item.id}
			/>
		</div>
	);
};

const getNonCheckableInputContainerCSS = (size: FBInputSize | undefined) =>
	!size || size === FBInputSize.Small || size === FBInputSize.Normal
		? css`
				display: inline-block;
				vertical-align: middle;
		  `
		: css`
				display: block;
		  `;

const nonCheckableInputContainerCheckedCSS = css`
	border: 1px solid #ccc;
	padding: 3px;
	margin: 5px;
	border-radius: 3px;
`;

interface IFBNonCeckableInputAssessmentStyles {
	container?: string;
}

interface IFBNonCeckableInputAssessmentText {
	before?: string;
	after?: string;
}

export interface IFBNonCheckableInputAssessmentProps {
	itemId: number;
	styles?: IFBNonCeckableInputAssessmentStyles;
	texts?: IFBNonCeckableInputAssessmentText;
	itemAssessment?: IQuestionItemAssessment;
	display: boolean;
}

const FBNonCeckableInputAssessment: React.FC<IFBNonCheckableInputAssessmentProps> = props => {
	const defaultStyles = React.useRef({
		container: assessmentContainerCSS,
	} as NotUndefinedAtAll<IFBNonCeckableInputAssessmentStyles>).current;

	const defaultTexts = React.useRef({
		before: "Score: ",
	} as NotUndefinedAtAll<IFBNonCeckableInputAssessmentText>).current;

	const getStyles = React.useCallback(
		(styles: IFBNonCheckableInputAssessmentProps["styles"]) => {
			return mergeStylesObjects(styles || {}, defaultStyles);
		},
		[defaultStyles]
	);
	const getTexts = React.useCallback(
		(texts: IFBNonCheckableInputAssessmentProps["texts"]) => {
			return mergeComponentObjects(texts || {}, defaultTexts);
		},
		[defaultTexts]
	);
	if (!props.display) return null;
	if (props.itemAssessment === undefined) return null;
	const styles = getStyles(props.styles);
	const texts = getTexts(props.texts);
	return (
		<div className={styles.container}>
			{texts.before}
			{props.itemAssessment.credit}
			{texts.after}
		</div>
	);
};

const assessmentContainerCSS = css`
	display: inline-block;
	vertical-align: middle;
`;

export default FillingBlanksContainer;

const itemCorrectAnsCSS = css`
	display: inline-block;
	border: 1px solid green;
	padding: 3px 5px;
	border-radius: 4px;
	margin: 5px;
`;

const itemAlternativeCorrectAnsCSS = css`
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 3px;
	margin: 2px;
	display: inline-block;
`;

export interface IFBFilesContainerProps {
	item: IItem;
	extraInfo?: IFBExtraInfo;
	viewMode: ViewMode;
	displayItemAssessment?: boolean;
	designStructure: FBContentDesignStructure | null;
}

export interface IFBUploadsContainerProps {
	item: IItem;
	userAnswer?: IFillingBlanksUserAns;
	onUserAnswerChange: (userAnswer: IFillingBlanksUserAns) => void;
	extraInfo?: IFBExtraInfo;
	disableEditingAnswer: boolean;
	viewMode: ViewMode;
	designStructure: FBContentDesignStructure | null;
}
