import {
	GradeCategorySchema,
	IGradeCategory,
} from "@app/api/grades/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "id";
type IdKey = typeof keyOfId;
type DOC = IGradeCategory;
export type IStateGradeCategories = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(GradeCategorySchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("gradeCategory");
const storageSettings = getDefaultStorageSettings("gradeCategories");
const metaInformationName = "gradeCategoriesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().gradeCategories) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", GradeCategorySchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class GradeCategory extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);

	static getCategoriesWithAncestorsSync(
		categoryIds: number[]
	): GradeCategory[] {
		const categories = this.findManyByIdsSync(categoryIds);

		const fetchedCategoryIds = new Set(
			categories.filter(e => e.id).map(e => e.id)
		);
		let count = 0;
		const maxDepth = 10;
		let lastCategories = categories;
		do {
			count++;
			const parentIds = [
				...new Set(
					lastCategories
						.filter(
							e =>
								e.parentId &&
								!fetchedCategoryIds.has(e.parentId)
						)
						.map(e => e.parentId!)
				),
			];
			if (parentIds.length === 0) break;
			const parentCategories = this.getCategoriesWithAncestorsSync(
				parentIds
			);
			lastCategories = parentCategories;
			categories.push(...parentCategories);
			for (const pCat of parentCategories) {
				fetchedCategoryIds.add(pCat.id);
			}
		} while (count < maxDepth);

		return categories;
	}
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, GradeCategory.meta);

export const gradeCategoriesReducer = getDefaultReducer(
	storageSettings,
	() => GradeCategory
);
