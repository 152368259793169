import { addMergingArgumentPrefix, withParams, addPrefix } from "link-builders";
import { ObjectId } from "@app/utils/generics";
import { ISemester } from "@app/api/classrooms/helper-schemas";

const HEADMASTER_BASE_PATH = "/headmaster";

const commonFunctionality = {
	timetable: "timetable",
	gradebook: "gradebook",
	semesterbook: "semesterbook",
	assignments: "assignments",
};

export const headmasterLinks = addPrefix(HEADMASTER_BASE_PATH)({
	mainPage: "/",

	groups: addMergingArgumentPrefix(
		({ groupId }: { groupId: string }) => `groups/${groupId}`,
		`groups/:groupId`
	)({
		...commonFunctionality,
		assignments: withParams(
			({ classroomId }: { classroomId?: ObjectId }) =>
				`assignments${
					classroomId ? `?classroomId=${classroomId}` : ""
				}`,
			"assignments"
		),
		listOfStudents: withParams(
			({ classroomId }: { classroomId?: ObjectId }) =>
				`student-list${
					classroomId ? `?classroomId=${classroomId}` : ""
				}`,
			"student-list"
		),
	}),
	timetable: "/timetable",

	classrooms: {
		create: "/new-classroom",
		...addMergingArgumentPrefix(
			({ classroomId }: { classroomId: string }) =>
				`classrooms/${classroomId}`,
			`classrooms/:classroomId`
		)({
			...commonFunctionality,
			listOfStudents: withParams(
				({
					openStudentsInvitationDefault,
				}: {
					openStudentsInvitationDefault?: boolean;
				}) =>
					`student-list${
						openStudentsInvitationDefault ? "?st-inv" : ""
					}`,
				"student-list"
			),
			edit: "edit",
		}),
	},
	users: {
		list: "users-list",
		teachers: {
			add: "add-teachers",
		},
	},

	archived: addPrefix("archive")({
		classrooms: withParams(
			(semester: ISemester | null) =>
				!semester
					? "classrooms"
					: `classrooms?startYear=${semester.startYear}&ordinal=${semester.ordinal}`,
			"classrooms"
		),
	}),
});
