import Joi from "@app/utils/joi";
import { UserViewSchema, IUserView } from "./helper-schemas";
import {
	updateStripKeys,
	markKeysStripped,
	ToUpdateKeys,
} from "../helper-schemas";

export const RGETUserViewSchema = Joi.alternatives(
	Joi.object({
		hasFound: Joi.boolean()
			.valid(false)
			.required(),
	}),
	Joi.object({
		hasFound: Joi.boolean()
			.valid(true)
			.required(),
		userView: UserViewSchema.required(),
	})
);
export type IRGETUserView =
	| {
			hasFound: false;
	  }
	| {
			hasFound: true;
			userView: IUserView;
	  };

///

export const APUTViewSchema = UserViewSchema.keys(updateStripKeys).fork(
	"userId",
	markKeysStripped
);
export type IAPUTView = ToUpdateKeys<IUserView, never, "userId">;
