import { IRequest } from "../requests";
import {
	IAPOSTApprovedProposal,
	IAPUTApprovedProposals,
	IRGETProposal,
} from "./validators";
import { IApprovedProposal } from "./helper-schemas";

export class ProposalsController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	approve = async (args: IAPOSTApprovedProposal) =>
		this.Request.send("POST", "/api/proposals/", args);

	editApprovedProposals = async (args: IAPUTApprovedProposals) =>
		this.Request.send("PUT", "/api/proposals/", args);

	getProposal = async (): Promise<IRGETProposal> => {
		if (sessionStorage.teacherProposalState) {
			try {
				return JSON.parse(sessionStorage.teacherProposalState);
			} catch (e) {}
		}
		return this.Request.send("GET", "/api/proposals/").then(
			(data: IRGETProposal) => {
				sessionStorage.setItem(
					"teacherProposalState",
					JSON.stringify(data)
				);
				return data;
			}
		);
	};

	getAllApproved = async (): Promise<IApprovedProposal[]> =>
		this.Request.send("GET", "/api/proposals/approved");
}
