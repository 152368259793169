import OrigianlJoi, { AnySchema } from "joi";
import { ObjectSchema } from "joi";

type ObjectIdSchema = OrigianlJoi.AnySchema;

interface ExtendedAlternativesSchema extends OrigianlJoi.AlternativesSchema {
	byKey: (key: string) => OrigianlJoi.AlternativesSchema;
}

export interface ExtendedJoi extends OrigianlJoi.Root {
	objectId(): ObjectIdSchema;

	alternatives(types: OrigianlJoi.SchemaLike[]): ExtendedAlternativesSchema;
	alternatives(
		...types: OrigianlJoi.SchemaLike[]
	): ExtendedAlternativesSchema;
}

const Joi: ExtendedJoi = OrigianlJoi.extend({
	type: "objectId",
	messages: {
		"objectId.base":
			"needs to be a string of 12 bytes or a string of 24 hex characters",
	},
	validate(value, helpers) {
		return {
			value,
			errors: null,
		};
	},
})
	.extend({
		type: "object",
		base: OrigianlJoi.object(),
		coerce: {
			from: "string",
			method(value, helpers) {
				if (value[0] !== "{" && !/^\s*\{/.test(value)) {
					return;
				}

				try {
					return { value: JSON.parse(value) };
				} catch (ignoreErr) {}
			},
		},
	})
	.extend({
		type: "array",
		base: OrigianlJoi.array(),
		coerce: {
			from: "string",
			method(value, helpers) {
				if (
					typeof value !== "string" ||
					(value[0] !== "[" && !/^\s*\[/.test(value))
				) {
					return;
				}

				try {
					return { value: JSON.parse(value) };
				} catch (ignoreErr) {}
			},
		},
	})
	.extend({
		type: "alternatives",
		base: OrigianlJoi.alternatives(),
		rules: {
			byKey: {
				args: [
					{
						name: "key",
						ref: true,
						assert: value => typeof value === "string" && !!value,
						message: "must be a string",
					},
				],
				method(this: OrigianlJoi.AnySchema, key: string) {
					const flags = this._flags;
					return JoiAlternatives(key, { flags })(
						...this.$_terms.matches.map(e => e.schema)
					);
				},
			},
		},
	});

export const JoiAlternatives = (
	key: string,
	{ flags }: { flags: Record<any, any> }
) => (...schemas: OrigianlJoi.AnySchema[]) => {
	if (schemas.length < 2) {
		return schemas[0];
	}
	const switches: OrigianlJoi.SwitchCases[] = [];
	const propSchemas: OrigianlJoi.AnySchema[] = [];
	for (let i = 0; i < schemas.length; i++) {
		const schema = schemas[i];
		const propSchema = getPropertySchema(schema, key);
		propSchemas.push(propSchema);
		const casea: OrigianlJoi.SwitchCases = {
			is: propSchema,
			then: schema,
		};
		switches.push(casea);
		const isLast = i === schemas.length - 1;
		if (isLast) {
			(casea as any).otherwise = Joi.object({
				[key]: Joi.alternatives(...propSchemas).required(),
			});
		}
	}
	let finalSchema = Joi.alternatives().conditional("." + key, {
		switch: switches,
	});
	if (flags?.presence) {
		finalSchema = finalSchema.presence(flags.presence);
	}
	return finalSchema;
};

const getPropertySchema = (schema: OrigianlJoi.AnySchema, key: string) => {
	return schema.$_reach([key]);
};

export type JoiSchema = OrigianlJoi.Schema;

export default Joi;

export function getJoiObjectKeys<T extends ObjectSchema>(schema: T): string[] {
	if (
		typeof schema.$_terms === "object" &&
		typeof schema.$_terms.keys === "object"
	) {
		return schema.$_terms.keys.map(e => e.key); // for Joi >= 16
	}
	return (schema as any)._inner.children.map(e => e.key); // For Joi <= 15
}

export const UnsignedIntegerSchema = Joi.number()
	.integer()
	.min(0);

export const getSchemaValidationFn = <
	Data extends any = any,
	InputData extends any = Data
>(
	schema: AnySchema
) => {
	return (data: InputData): Data => {
		const validated = schema.validate(data);
		if (validated.error || validated.errors) {
			throw validated.error || validated.errors;
		}
		return validated.value;
	};
};
