import { Reservation } from "@app/models/reservation";
import { inject } from "@app/modules";
import { unitCollmise } from "collmise";
import { IRequest } from "../requests";
import { IReservation, ReservationSchema } from "./helper-schemas";
import {
	IAGETProductReservationStats,
	IAPOSTReserveProduct,
	IAPOSTView,
	IRGETProductReservationStats,
	IRPOSTReserveProduct,
	IRPOSTView,
	RPOSTReserveProductSchema,
	RPOSTViewSchema,
} from "./validators";

export class ReservationController {
	private readonly Request: IRequest;

	private readonly _ReservationModel = inject("ReservationModel");
	private reservationPromises = unitCollmise<IReservation, Reservation>({
		findCachedData: () => {
			const userId = this.assertAndGetClassroomsUser().id;
			return this._ReservationModel.findOneSync({ userId });
		},
		dataTransformer: doc => this._ReservationModel.loadOneSync(doc),
	});

	private readonly assertAndGetClassroomsUser = inject(
		"assertAndGetClassroomsUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	async getReservations(loadFresh?: boolean): Promise<Reservation> {
		return this.reservationPromises.fresh(loadFresh).requestAs(() => {
			return this.Request.send(
				"GET",
				"/api/reservations",
				undefined,
				null,
				{
					responseSchema: ReservationSchema,
				}
			);
		});
	}

	public async action(args: IAPOSTReserveProduct): Promise<Reservation> {
		return this.Request.send(
			"POST",
			"/api/reservations/action",
			args,
			null,
			{
				responseSchema: RPOSTReserveProductSchema,
			}
		).then((data: IRPOSTReserveProduct) => {
			return this._ReservationModel.loadOneSync(data);
		});
	}

	async postView(args: IAPOSTView): Promise<Reservation> {
		return this.Request.send("POST", "/api/reservations/view", args, null, {
			responseSchema: RPOSTViewSchema,
		}).then((data: IRPOSTView) => {
			return this._ReservationModel.loadOneSync(data);
		});
	}

	async atomicView(args: IAPOSTView): Promise<void> {
		return this.Request.send("POST", "/api/reservations/atomic-view", args);
	}

	async atomicAction(args: IAPOSTReserveProduct): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/reservations/atomic-action",
			args
		);
	}

	async getStats(
		args: IAGETProductReservationStats
	): Promise<IRGETProductReservationStats> {
		return this.Request.send("GET", "/api/reservations/stats", args);
	}
}
