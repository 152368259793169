import { useBoolean } from "@app/hooks/general";
import { useClassroomsUser } from "@app/hooks/users";
import React from "react";
import {
	CertificatePopup,
	testingTeacherIds,
	showTeacherCertificateOnlyForUs,
} from "../teachers/certificates/certificate-info-popup";
import { StudentCertificatePopup } from "../teachers/certificates/students-popup";
import profileStyles from "./styles/certificate.module.css";

export const CertificateBox: React.FC<{
	containerClassname?: string;
	textContaier?: string;
	imgSize?: number | string;
	imgClassname?: string;
}> = ({ containerClassname, textContaier, imgSize, imgClassname }) => {
	const user = useClassroomsUser()!;

	const {
		value: isPopupOpen,
		setFalse: closePopup,
		setTrue: openPopup,
	} = useBoolean();

	if (!user || (!user.isTeacher() && !user.isStudent())) return null;
	if (
		showTeacherCertificateOnlyForUs &&
		user.isTeacher() &&
		testingTeacherIds.indexOf(user.id) === -1
	) {
		return null;
	}

	return (
		<>
			{isPopupOpen &&
				user &&
				(user.isTeacher() ? (
					<CertificatePopup
						isOpenByDefault={true}
						onClose={closePopup}
					/>
				) : (
					<StudentCertificatePopup
						open={isPopupOpen}
						onClose={closePopup}
					/>
				))}
			{/* <div
				className={profileStyles.certificateContainer}
				onClick={openPopup}
			> */}
			<div
				className={containerClassname || profileStyles.certificateBox}
				onClick={openPopup}
			>
				<img
					src="/imgs/award.png"
					alt="box"
					style={{ width: imgSize }}
					className={imgClassname}
				/>
				<p className={textContaier || profileStyles.openCertificate}>
					ჩემი სერტიფიკატი
				</p>
			</div>
			{/* </div> */}
		</>
	);
};
