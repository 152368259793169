// import SaveIcon from "@material-ui/icons/SaveOutlined";
import * as React from "react";
import { useEffect, useState } from "react";
import "./styles/input-with-save-button-styles.css";
import { ReactComponent as SaveIcon } from "./styles/imgs/saveLink.svg";
import { useBoolean } from "@app/hooks/general";

interface IProps {
	placeholder?: string;
	defaultValue?: string;
	onSave: (value: string) => void;
}

export const InputWithSaveButton: React.FC<IProps> = React.memo(props => {
	const [value, setValue] = useState(props.defaultValue || "");
	useEffect(() => {
		if (props.defaultValue) setValue(props.defaultValue);
	}, [props.defaultValue]);
	const { value: isFocused, switchValue: onInputFocus } = useBoolean();
	const { value: isSaveHovered, switchValue: onSaveHover } = useBoolean();
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const styleObj = {
		color: props.defaultValue || value || isFocused ? "#aeb5c9" : "#eff1f7",
	};
	return (
		<div className={"input-container"}>
			<input
				value={value}
				onChange={onChange}
				style={styleObj}
				onFocus={onInputFocus}
				onBlur={onInputFocus}
				placeholder="ჩააკოპირეთ ბმული"
			/>
			<SaveIcon
				onMouseEnter={onSaveHover}
				onMouseLeave={onSaveHover}
				style={{
					...styleObj,
					cursor: value || isFocused ? "pointer" : "auto",
				}}
				className={"icon"}
				onClick={() => props.onSave(value || "")}
			/>
			<div
				className={"hover-text"}
				style={
					isSaveHovered && (value || isFocused)
						? {}
						: { display: "none" }
				}
			>
				შენახვა
			</div>
		</div>
	);
});
