import { VideoLessonStatus } from "@app/models/video-lesson";
import React, { useMemo, CSSProperties } from "react";
import styles from "./styles/buttons-widget.module.css";

interface IProps {
	onClick?: () => void;
	lessonStatus: VideoLessonStatus;
	title?: string;
	style?: CSSProperties;
}

export const VideoLinkButton: React.FC<IProps> = React.memo(
	({ onClick, lessonStatus, title, style }) => {
		const mapStatusToStyles = useMemo(() => {
			switch (lessonStatus) {
				case VideoLessonStatus.NOT_AVAILABLE:
					return styles.startVideoLessonButtonNotAvailable;
				case VideoLessonStatus.AVAILABLE:
					return styles.startVideoLessonButtonNotActive;
				case VideoLessonStatus.ACTIVE:
					return styles.startVideoLessonButtonAvailable;
				default:
					return "";
			}
		}, [lessonStatus]);
		return (
			<button
				style={style}
				className={mapStatusToStyles}
				onClick={onClick}
			>
				{title || "ვიდეოგაკვეთილის დაწყება"}
			</button>
		);
	}
);
