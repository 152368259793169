import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { useForceUpdate } from "@app/hooks/general";
import { useMyInfoAdStudentOrChild } from "@app/hooks/student";
import { useClassroomsUser } from "@app/hooks/users";
import styled from "@emotion/styled";
import React, { useCallback } from "react";

export const InvitedRegistrationPopupWrapper = () => {
	const forceUpdate = useForceUpdate();

	const user = useClassroomsUser();
	const shouldRender =
		!!user?.isStudent() &&
		!!window.localStorage.getItem("invitedStudentPopup");

	const handleClose = useCallback(() => {
		window.localStorage.removeItem("invitedStudentPopup");
		forceUpdate();
	}, [forceUpdate]);

	return (
		<DefaultPopupPrioritizer enabled={shouldRender} priority={999.5}>
			<InvitedRegistrationPopup onClose={handleClose} />
		</DefaultPopupPrioritizer>
	);
};

const InvitedRegistrationPopup: React.FC<{ onClose: () => void }> = ({
	onClose,
}) => {
	useMyInfoAdStudentOrChild(); // this is important to reload student info

	return (
		<div>
			<Popup>
				<PopupContentWithClose onClose={onClose}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{ fontFamily: "Roboto Geo Nus" }}>
							შენ წარმატებით გაიარე ავტორიზაცია პლატფორმაზე, რაც
							იმას ნიშნავს, რომ მასწავლებელს შეეძლება სერტიფიკატის
							გამოგზავნა.
							<br />
							<br />
							გისურვებთ წარმატებას!
						</div>
						<MainButton onClick={onClose}>გასაგებია</MainButton>
					</div>
				</PopupContentWithClose>
			</Popup>
		</div>
	);
};

const MainButton = styled.div`
	margin-top: 30px;
	width: 300px;
	height: 55px;
	border-radius: 15px;
	background-color: #5273e6;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	color: white;
`;
