import React from "react";
import BG3 from "./users/styles/img/bg3.png";
import BG2 from "./users/styles/img/bg2.png";
import Helmet from "react-helmet";
import BG1 from "./users/styles/img/bg1.png";
import BG from "./users/styles/img/Blue-With-Pattern.png";
import { useWindowSize } from "@app/hooks/front";

export const BigBackground = React.memo(({ bgColor }: { bgColor?: string }) => {
	const { width } = useWindowSize();
	return (
		<Helmet
			bodyAttributes={{
				style: `background: url(${BG3}) no-repeat;  background-size: ${
					width >= 1100 ? "100% 530px" : "auto 500px"
				}; background-position:0px -60px; ${
					bgColor ? `background-color: ${bgColor}` : ""
				}` as any,
			}}
		/>
	);
});

export const WaveWithPatterns = React.memo(
	({ bgColor }: { bgColor?: string }) => {
		const { width } = useWindowSize();
		return (
			<Helmet
				bodyAttributes={{
					style: `background: url(${BG}) no-repeat;  background-size: ${
						width >= 1100 ? "100% 530px" : "auto 500px"
					}; background-position:0px -60px; ${
						bgColor ? `background-color: ${bgColor}` : ""
					}` as any,
				}}
			/>
		);
	}
);

export const BackgroundTwoWaves: React.FC<{
	additionalStyles?: string;
}> = React.memo(({ additionalStyles = "" }) => {
	const { width } = useWindowSize();
	return (
		<Helmet
			bodyAttributes={{
				style: `background: url(${BG2}) no-repeat; background-size: ${
					width >= 1100 ? "100% 530px" : "auto 500px"
				}; ${additionalStyles}` as any,
			}}
		/>
	);
});

export const BackgroundOneWave = React.memo(() => (
	<Helmet
		bodyAttributes={{
			style: `background: url(${BG1}) no-repeat; background-size: 100% 270px;` as any,
		}}
	/>
));

export const BigBackgroundUp = React.memo(() => (
	<Helmet
		bodyAttributes={{
			style: `background: url(${BG3}) no-repeat; background-size: auto; background-position: 0px -548px;` as any,
		}}
	/>
));

export const BigBackgroundLittleUp = React.memo(() => {
	const { width } = useWindowSize();
	return (
		<Helmet
			bodyAttributes={{
				style: `background: url(${BG3}) no-repeat; background-size: ${
					width >= 1100 ? "100% 530px" : "auto 500px"
				}; background-position:0px -113px;` as any,
			}}
		/>
	);
});

export const NewDesignBackground = React.memo(() => (
	<Helmet
		bodyAttributes={{
			style: `background-color: rgba(239, 241, 247);` as any,
		}}
	/>
));
