import React, { useRef, useEffect, useState } from "react";
import { DivReference } from "./header";
import { Role } from "./role";
import { ReactComponent as ScrollDown } from "./styles/imgs/scroll-down.svg";
import styles from "./styles/tv-school-landing.module.css";
import { FaqContainer } from "../widgets/faq";
import {
	getHTMLElementCoords,
	animateWindowScroll,
} from "@tests-core/utils/dom";
import { useLocale } from "@app/hooks/intl";
import LandingFooter from "@app/components/landing/landingFooter";
import { FormattedMessage } from "react-intl";

import statsStyles from "./styles/big-stats.module.css";
import CountUp from "react-countup";
import classNames from "classnames";
import { BogBulterAd } from "../dev/ads";

export const TVSchoolLanding: React.FC<{}> = props => {
	// TODO: attach faqRef to header faq
	const faqRef = useRef<HTMLDivElement | null>(null);
	const locale = useLocale();
	const isGeo = locale === "ka";

	useEffect(() => {
		let hasScrolled = false;
		const scrollTofaq = (e: WheelEvent, ref: DivReference) => {
			if (e.deltaY <= 0) {
				// scrolling up
				return;
			}
			if (!ref) return;
			if (!ref.current) return;
			const coordinates = getHTMLElementCoords(ref.current);

			if (
				Math.floor(window.pageYOffset) > 0 &&
				Math.floor(window.pageYOffset) < 80
			) {
				hasScrolled = false;

				window.addEventListener("mouseup", e => {
					hasScrolled = true;
					window.removeEventListener("mousedown", () => {
						hasScrolled = false;
					});
				});
				window.addEventListener("mousedown", e => {
					hasScrolled = false;
					window.removeEventListener("mouseup", () => {
						hasScrolled = true;
					});
				});
			}

			if (hasScrolled) {
				return;
			} else {
				setTimeout(() => {
					animateWindowScroll(coordinates.top - 90, 300);
					hasScrolled = true;
				}, 300);
			}
		};

		const fn = (e: WheelEvent) => scrollTofaq(e, faqRef);

		window.addEventListener("wheel", fn);

		return () => window.removeEventListener("wheel", fn);
	}, []);

	return (
		<div>
			<BogBulterAd />
			<div style={{ position: "relative" }} className="withBulterAd">
				<section className={styles.titlesContainer}>
					<div className={styles.newTitle}>
						<div style={{ color: "#FCCC5A" }}>
							<FormattedMessage id="tvSchoolLanding.newTitlePart1" />
						</div>
						<div style={{ color: "rgba(98, 106, 132, 1)" }}>
							<FormattedMessage id="tvSchoolLanding.newTitlePart2" />
						</div>
					</div>
				</section>

				<BigStats uniqueClassname={"upperStats"} />
				<Role />
				<div className={styles.scroollArrowContainer}>
					<ScrollDown style={{ width: "20px" }} />
				</div>
				<BigStats uniqueClassname={"belowStats"} />
				<div ref={faqRef}>
					<FaqContainer isOnlyTitleWithoutHalfMoon={true} />
				</div>
				<LandingFooter />
			</div>
		</div>
	);
};

export interface IBigStats {
	end: number;
	start?: number;
	duration?: number;
	delay?: number;
}

export const BigStats: React.FC<{
	uniqueClassname?: string;
}> = ({ uniqueClassname }) => {
	let thisUniqueClass = "";
	if (uniqueClassname) {
		thisUniqueClass = uniqueClassname;
	}
	return (
		<div
			className={classNames(
				statsStyles.statsContainer,
				statsStyles[thisUniqueClass]
			)}
		>
			<div
				className={classNames(
					statsStyles.statBox,
					statsStyles.sentAssignmentsCount
				)}
			>
				<div className={statsStyles.statNumber}>
					<SingleCountUp end={380723} delay={0.3} />
				</div>
				<div className={statsStyles.statText}>გაგზავნილი დავალება</div>
			</div>
			<div
				className={classNames(
					statsStyles.statBox,
					statsStyles.studentsCount
				)}
			>
				<div className={statsStyles.statNumber}>
					<SingleCountUp end={320671} delay={1.2} />
				</div>
				<div className={statsStyles.statText}>მოსწავლე</div>
			</div>
			<div
				className={classNames(
					statsStyles.statBox,
					statsStyles.teachersCount
				)}
			>
				<div className={statsStyles.statNumber}>
					<SingleCountUp end={47447} delay={1.7} />
				</div>
				<div className={statsStyles.statText}>მასწავლებელი</div>
			</div>
			<div
				className={classNames(
					statsStyles.statBox,
					statsStyles.schoolsCount
				)}
			>
				<div className={statsStyles.statNumber}>
					<SingleCountUp end={2490} delay={2.2} />
				</div>
				<div className={statsStyles.statText}>სკოლიდან</div>
			</div>
		</div>
	);
};

export const SingleCountUp: React.FC<IBigStats> = ({
	end,
	delay = 0.5,
	start = 0,
	duration = 13,
}) => {
	const lastSteps = 10;
	const lastStepsDuration = 10;
	const secondStart = end - lastSteps;
	const [currOps, setCurrOps] = useState({
		currStart: start,
		currEnd: secondStart,
		currDuration: duration - lastStepsDuration,
		currUseEasing: false,
	});
	const [isStarted, setIsStarted] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setIsStarted(true);
		}, delay * 1000);
	}, [delay]);
	return isStarted ? (
		<CountUp
			formattingFn={(value: number) => insertSpaces(String(value))}
			onEnd={({ update }) => {
				// console.log("now upppp");
				if (currOps.currEnd < end) {
					setCurrOps({
						currStart: secondStart,
						currEnd: end,
						currDuration: lastStepsDuration,
						currUseEasing: true,
					});
				}

				// update();
			}}
			duration={currOps.currDuration}
			start={currOps.currStart}
			end={currOps.currEnd}
			delay={0}
			useEasing={currOps.currUseEasing}
		>
			{({ countUpRef }) => {
				// console.log("countUpRef:", countUpRef);
				return (
					<div>
						<span ref={countUpRef} />
					</div>
				);
			}}
		</CountUp>
	) : (
		<span>{start}</span>
	);
};

const insertSpaces = (nStr: string) => {
	nStr += "";
	const x = nStr.split(".");
	let x1 = x[0];
	const x2 = x.length > 1 ? "." + x[1] : "";
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, "$1" + " " + "$2");
	}
	return x1 + x2;
};
