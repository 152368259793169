import { HalfMoon } from "@app/components/widgets/half-moon";
import { useManyUsersShortInfo } from "@app/hooks/users";
import { inject } from "@app/modules";
import { setCurrentChild } from "@app/user/children/set-current";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cert_styles from "@app/components/users/finals/certificate/styles/Certificate.module.css";
import styles from "@app/components/users/finals/styles/styles.module.scss";
import { getCurrentChild } from "@app/user/children";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { parentLinks } from "@app/routes/parent/links";

const ChooseChild = React.memo(function ChooseChild() {
	const history = useHistory();

	const currentChild = getCurrentChild();
	if (currentChild) {
		history.replace(parentLinks.childMainPage({ childId: currentChild }));
	}

	const [childrenIds, setChildrenIds] = useState<number[] | null>(null);
	useEffect(() => {
		const ParentChildrenController = inject("ParentChildrenController");
		ParentChildrenController.getChildren().then(data => {
			const ids = (data.confirmedChildrenIds || []).concat(
				data.unConfirmedChildrenIds || []
			);
			if (ids.length === 1) {
				const currentChildId = ids[0];
				setCurrentChild(currentChildId);
				history.push(
					parentLinks.childMainPage({ childId: currentChildId })
				);
			}
			setChildrenIds(ids);
		});
	}, [history]);

	const onChildClick = useCallback(
		id => () => {
			setCurrentChild(id).then(() => {
				history.push(parentLinks.childMainPage({ childId: id }));
			});
		},
		[history]
	);
	const usersInfo = useManyUsersShortInfo(childrenIds);

	if (!usersInfo.doc) return <FancyLoadingCenter />;

	return (
		<section>
			<HalfMoon title="აირჩიეთ მოსწავლე" />
			<section className={cert_styles.box_container}>
				{usersInfo &&
					usersInfo.doc &&
					usersInfo.doc.map(userInfo => (
						<section
							className={styles.box_item}
							key={userInfo.id}
							onClick={onChildClick(userInfo.id)}
						>
							<img
								className={styles.box_item__img}
								src="/imgs/StudentAvatar.svg"
								alt=""
							/>
							<p className={styles.box_item__title}>
								{userInfo.getFullName()}
							</p>
						</section>
					))}
			</section>
		</section>
	);
});

export default ChooseChild;
