import {
	ICardTemplate,
	CardTemplateSchema,
} from "@tests-core/schemas/cards/templates";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ICardTemplate;
export type IStateCardTemplates = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(CardTemplateSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("cardTemplate");
const storageSettings = getDefaultStorageSettings("cardTemplates");
const metaInformationName = "cardTemplatesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().cardTemplates) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", CardTemplateSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class CardTemplate extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, CardTemplate.meta);

export const cardTemplatesReducer = getDefaultReducer(
	storageSettings,
	() => CardTemplate
);
