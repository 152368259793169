import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const SubjectAvailabilitySchema = Joi.object({
	grades: Joi.array()
		.items(Joi.number().integer())
		.allow(null),
	languages: Joi.array()
		.items(Joi.string())
		.allow(null),
	countries: Joi.array()
		.items(Joi.string())
		.allow(null),
	websites: Joi.array()
		.items(Joi.string())
		.allow(null),
	priority: Joi.number().allow(null),
});
export interface ISubjectAvailability {
	grades?: number[] | null;
	languages?: string[] | null;
	countries?: string[] | null;
	websites?: string[] | null;
	priority?: number | null;
}

export const SubjectSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	oldId: Joi.number().integer(),
	photo: Joi.string().allow(null),
	availableIn: Joi.array()
		.items(SubjectAvailabilitySchema)
		.allow(null),
});

export interface ISubject {
	_id: ObjectId;
	name: string;
	description?: string;
	oldId?: number | undefined;
	photo?: string | null;
	availableIn?: ISubjectAvailability[] | null;
}
