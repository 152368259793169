import Joi from "@app/utils/joi";
import {
	UserFolderLevelSchema,
	IUserFolderLevel,
} from "../folders/helper-schemas";
import {
	UserTopicLevelSchema,
	IUserTopicLevel,
} from "../topics/helper-schemas";
import {
	UserTaskTypeLevelSchema,
	IUserTaskTypeLevel,
} from "../task-types/helper-schemas";

export const SingleUserStatsSchema = Joi.object({
	folderProgresses: Joi.object()
		.pattern(
			/^[a-f\d]{24}$/,
			Joi.object({
				progress: Joi.number().required(),
			})
		)
		.required(),
	folderLevels: UserFolderLevelSchema.allow(null).required(),
	topicLevels: UserTopicLevelSchema.allow(null).required(),
	taskTypeLevels: UserTaskTypeLevelSchema.allow(null).required(),
});

export interface SingleUserStats {
	folderProgresses: Record<
		number | string,
		{
			progress: number;
		}
	>;
	folderLevels: IUserFolderLevel | null;
	topicLevels: IUserTopicLevel | null;
	taskTypeLevels: IUserTaskTypeLevel | null;
}
