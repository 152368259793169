import { IRequest } from "../requests";
import { IAPOSTSavePassableSubjects, IPassableSubject } from "./helper-schemas";

export class PassableSubjectsController {
	constructor(private readonly Request: IRequest) {}

	createOrUpdate = async (args: IAPOSTSavePassableSubjects): Promise<any> => {
		return await this.Request.send("POST", "/api/passable-subjects", args);
	};

	getAllPassableSubjects = async (): Promise<IPassableSubject[]> => {
		return await this.Request.send("GET", "/api/passable-subjects");
	};
}
