import {
	ICertificateCandidateStudent,
	CertificateCandidateStudentType,
	IRGETCertificateCandidateStudents,
	IRegisteredCertificateCandidateStudent,
	IUnregisteredCertificateCandidateStudent,
	IAPUTCertificateCandidateStudentsStatus,
} from "@app/api/certificates/validators";
import { FilterStudentCandidatesTypes } from "./candidate-list-v2";

export const getAllSentCandidates = (
	candidates: IRGETCertificateCandidateStudents
): IAPUTCertificateCandidateStudentsStatus => {
	const res: IAPUTCertificateCandidateStudentsStatus = [];
	const keys = Object.keys(candidates);
	for (const grade of keys) {
		const students = candidates[grade] as ICertificateCandidateStudent[];
		if (students) {
			students.forEach(e => {
				if (e.type === CertificateCandidateStudentType.registered) {
					res.push({ approved: true, studentId: e.user.id });
				}
			});
		}
	}
	return res;
};

export const getFilteredStudents = (
	tab: FilterStudentCandidatesTypes,
	students: ICertificateCandidateStudent[]
): {
	registered: IRegisteredCertificateCandidateStudent[];
	unregistred: IUnregisteredCertificateCandidateStudent[];
} => {
	if (tab === FilterStudentCandidatesTypes.all) {
		return {
			registered: students.filter(
				e => e.type !== CertificateCandidateStudentType.notRegistered
			) as any,
			unregistred: students.filter(
				e => e.type === CertificateCandidateStudentType.notRegistered
			) as any,
		};
	} else if (tab === FilterStudentCandidatesTypes.notRegistered) {
		return {
			registered: [],
			unregistred: students.filter(
				e => e.type === CertificateCandidateStudentType.notRegistered
			) as any,
		};
	} else if (tab === FilterStudentCandidatesTypes.readyCandidates) {
		return {
			unregistred: [],
			registered: students.filter(
				e => e.type === CertificateCandidateStudentType.registered
			) as any,
		};
	} else {
		return {
			unregistred: [],
			registered: students.filter(
				e => e.type === CertificateCandidateStudentType.alreadySent
			) as any,
		};
	}
};

export const getCertificateCandidateStatus = (
	studentType: CertificateCandidateStudentType,
	responsive: boolean
): string => {
	switch (studentType) {
		case CertificateCandidateStudentType.alreadySent:
			return responsive ? "გაგზავნილია" : "სერტიფიკატი გაგზავნილია";
		case CertificateCandidateStudentType.registered:
			return responsive ? "მზადაა" : "მზადაა გასაგზავნად";
		case CertificateCandidateStudentType.deletedFromCandidates:
			return responsive ? "დამატება" : "გასაგზავნებში დამატება";
		case CertificateCandidateStudentType.notRegistered:
			return responsive ? "არარეგისტრ." : "არარეგისტრირებული";
		default:
			return "";
	}
};

export const getTabName = (
	tab: FilterStudentCandidatesTypes,
	responsive: boolean
): string => {
	switch (tab) {
		case FilterStudentCandidatesTypes.all:
			return "ყველა";
		case FilterStudentCandidatesTypes.notRegistered:
			return responsive ? "არარეგისტრ." : "არარეგისტრირებული";
		case FilterStudentCandidatesTypes.sent:
			return "გაგზავნილები";
		case FilterStudentCandidatesTypes.readyCandidates:
			return responsive ? "მზადაა." : "მზა სერტიფიკატები";
		default:
			return "";
	}
};

export const updateCertificateCandidateStatus = (
	studentId: number,
	candidates: IRGETCertificateCandidateStudents,
	status:
		| CertificateCandidateStudentType.alreadySent
		| CertificateCandidateStudentType.deletedFromCandidates
		| CertificateCandidateStudentType.registered
): IRGETCertificateCandidateStudents => {
	for (const grade of Object.keys(candidates)) {
		const students = candidates[grade] as ICertificateCandidateStudent[];
		if (students) {
			const student = students.find(
				e =>
					e.type !== CertificateCandidateStudentType.notRegistered &&
					e.user.id === studentId
			);
			if (student) {
				candidates[grade] = [
					...students.filter(e =>
						e.type !== CertificateCandidateStudentType.notRegistered
							? e.user.id !== studentId
							: true
					),
					{
						type: status,
						user: (student as IRegisteredCertificateCandidateStudent)
							.user,
					},
				];
				return candidates;
			}
		}
	}
	return candidates;
};

export const hasSendCandidate = (
	candidates: IRGETCertificateCandidateStudents
): boolean => {
	for (const grade of Object.keys(candidates)) {
		const students = candidates[grade] as ICertificateCandidateStudent[];
		if (students) {
			if (
				getFilteredStudents(
					FilterStudentCandidatesTypes.readyCandidates,
					students
				).registered.length !== 0
			) {
				return true;
			}
		}
	}
	return false;
};

export const getUnregistredCount = (
	candidates: IRGETCertificateCandidateStudents | undefined
): number => {
	if (!candidates) return 0;
	let res = 0;
	const incrementRes = () => (res = res + 1);
	Object.keys(candidates).forEach(e => {
		const students = candidates[e] as ICertificateCandidateStudent[];
		if (students) {
			students.forEach(e => {
				if (e.type === CertificateCandidateStudentType.notRegistered) {
					incrementRes();
				}
			});
		}
	});
	return res;
};
