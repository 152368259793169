import Joi from "@app/utils/joi";
import { BlogSchema, IBlog } from "./helper-schemas";
import { insertStripKeys, ToInsertKeys } from "../helper-schemas";

export const RGETManyBlogsSchema = Joi.array().items(BlogSchema);
export type IRGETManyBlogs = IBlog[];

///

export const APOSTBlogSchema = BlogSchema.keys(insertStripKeys);
export type IAPOSTBlog = ToInsertKeys<IBlog>;

export const RPOSTBlogSchema = BlogSchema;
export type IRPOSTBlog = IBlog;
