import { IRequest } from "../requests";
import {
	AGETAudienceSchema,
	APOSTAudienceSchema,
	APUTAudienceSchema, APUTRefreshSchoolConferences,
	IAGETAudience,
	IAPOSTAudience,
	IAPUTAudience, IAPUTRefreshSchoolConferences,
	IRGETAudience,
	IRPOSTAudience,
	IRPUTAudience,
	RGETAudienceSchema,
	RPOSTAudienceSchema,
	RPUTAudienceSchema,
} from "./validators";

export class AudeincesController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (args: IAPOSTAudience): Promise<IRPOSTAudience> =>
		this.Request.send("POST", "/api/audiences", args, null, {
			requestSchema: APOSTAudienceSchema,
			responseSchema: RPOSTAudienceSchema,
		});

	refresh = async (args: IAPUTRefreshSchoolConferences) =>
		this.Request.send(
			"PUT",
			"/api/audiences/refresh-conferences",
			args,
			null,
			{
				requestSchema: APUTRefreshSchoolConferences,
			}
		);

	update = async (args: IAPUTAudience): Promise<IRPUTAudience> =>
		this.Request.send("PUT", "/api/audiences/:_id", args, null, {
			requestSchema: APUTAudienceSchema,
			responseSchema: RPUTAudienceSchema,
		});

	getById = async (args: IAGETAudience): Promise<IRGETAudience> =>
		this.Request.send("GET", "/api/audiences/:_id", args, null, {
			requestSchema: AGETAudienceSchema,
			responseSchema: RGETAudienceSchema,
		});
}
