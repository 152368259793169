import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import {
	onStudentOpenVideoLesson,
	onAuthorOpenVideoLesson,
} from "@app/common-javascript";
import { CommonButtonStyles } from "@app/common-styles";
import { useVideoLesson } from "@app/hooks/video-lessons";
import React, { useCallback, useMemo } from "react";
import { ReactComponent as ConferenceSVG } from "./styles/imgs/conference.svg";
import styles from "./styles/tvschool-classroom.module.css";
import { FormattedMessage } from "react-intl";
import { Conference } from "@app/models/conference";

const TIME_OFFSET_MS = 40 * 60 * 1000;

const TeacherConferenceBox: React.FC<{ conference: Conference }> = ({
	conference,
}) => {
	const videoLesson = useVideoLesson(
		conference._id,
		VideoLessonResourceTypes.CONFERENCE
	);
	const isActive: boolean = useMemo<boolean>(() => {
		if (!videoLesson.doc?.url || !videoLesson.doc.lastVisited) return false;
		return (
			Date.now() - Number(videoLesson.doc.lastVisited) < TIME_OFFSET_MS
		);
	}, [videoLesson.doc]);
	const isDisabled = !videoLesson.doc?.url;

	const handelVideoLinkClick = useCallback(() => {
		if (!videoLesson.doc) return;
		onAuthorOpenVideoLesson(
			videoLesson.doc.resourceId,
			videoLesson.doc.resourceType
		);
	}, [videoLesson.doc]);

	return (
		<div className={styles.conferenceBox}>
			<div className="text-center">
				{conference.name || <FormattedMessage id={"teachersRoom"} />}
			</div>
			<ConferenceSVG height={120} />
			<button
				style={{
					...CommonButtonStyles,
					...(() => {
						if (isActive) {
							return {
								backgroundColor: "#37d7a0",
								cursor: "pointer",
							};
						}
						if (!isDisabled) {
							return {
								backgroundColor: "#5273e6",
								cursor: "pointer",
							};
						}
						return {
							backgroundColor: "#d1ccd1",
							cursor: "not-allowed",
						};
					})(),
				}}
				onClick={handelVideoLinkClick}
			>
				ვიდეოზარი
			</button>
		</div>
	);
};

export default TeacherConferenceBox;
