import React, { useState, useCallback, useEffect, useMemo } from "react";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { inject } from "@app/modules";
import { ICertificate } from "@app/api/certificates/helper-schemas";
import { useClassroomsUser } from "@app/hooks/users";
import styles from "./styles/index.module.scss";
import { useWindowSize } from "@app/hooks/front";
import Loading from "@app/components/widgets/raw-loading";
import { ReactComponent as DownloadIcon } from "../../styles/img/icons/Download.svg";
import { ReactComponent as FacebookIcon } from "@app/icons/Facebook.svg";
import styled from "@emotion/styled";
import { dateDifferenceInDay } from "@app/utils/common";

export const StudentCertificatePopup: React.FC<{
	open?: boolean;
	onClose?: () => void;
}> = React.memo(({ open, onClose }) => {
	const [isOpen, setIsOpen] = useState<boolean>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [newName, setNewName] = useState<string>("");
	const { width } = useWindowSize();

	const user = useClassroomsUser();
	const [certificate, setCertificate] = useState<ICertificate | null>(null);

	const downloadRef = React.createRef<HTMLAnchorElement>();

	const handleDownload = () => {
		if (downloadRef.current) {
			downloadRef.current.click();
		}
	};

	const showSaveButton = useMemo(() => {
		if (!user) return false;
		return newName !== user.firstname + " " + user.lastname;
	}, [newName, user]);

	useEffect(() => {
		if (!user) {
			return;
		}
		if (!(user.id === 12029 || user.id === 211 || user.id === 12102)) {
			return;
		}

		const loadCertificate = async () => {
			const actionsController = inject("UserActionsController");
			const certificatesController = inject("CertificatesController");
			const actions = (await actionsController.getUserAction()).actions;

			if (actions.certificate) {
				if (
					(dateDifferenceInDay(
						new Date(),
						new Date(actions.certificate.meta.lastPopupOpenDate)
					) >= 1 &&
						actions.certificate.meta.popupSeenCount < 3 &&
						actions.certificate.meta.fbShares === 0) ||
					open
				) {
					if (!open) {
						actionsController.performUserAction({
							data: {
								certificate,
								meta: {
									lastPopupOpenDate: new Date().toString(),
									fbShares: 0,
									popupSeenCount:
										actions.certificate.meta
											.popupSeenCount + 1,
								},
							},
							name: "certificate",
						});
					}
					// setIsOpen(true); uncomment for production
				}
				setCertificate(actions.certificate.certificate); /// move inside if branch for production
			} else {
				///TODO moswavlistvis imave endpointze daviangarishot
				// const status = await certificatesController.getStatus();
				// if (!status.isQualified) return;

				const certificate = await certificatesController.generateCerificate(
					{
						receiversFullName: user.firstname + " " + user.lastname,
					}
				);

				actionsController.performUserAction({
					data: {
						certificate,
						meta: {
							lastPopupOpenDate: new Date().toString(),
							fbShares: 0,
							popupSeenCount: 1,
						},
					},
					name: "certificate",
				});
				setCertificate(certificate);
			}
			setNewName(user.firstname + " " + user.lastname);
		};

		loadCertificate();
		setIsOpen(true); /// move inside else branch for production
	}, [certificate, open, user]);

	const handleSave = useCallback(() => {
		if (!user) return;
		const updateCertificate = async () => {
			setIsLoading(true);
			const certificate = await inject(
				"CertificatesController"
			).generateCerificate({
				receiversFullName: newName,
			});

			const userActionsController = inject("UserActionsController");
			const actions = (await userActionsController.getUserAction())
				.actions;

			await userActionsController.performUserAction({
				data: {
					certificate,
					meta: actions.certificate.meta,
				},
				name: "certificate",
			});
			setCertificate(certificate);
		};
		updateCertificate();
	}, [newName, user]);

	const closePopup = useCallback(() => {
		if (onClose) onClose();
		setIsOpen(false);
	}, [onClose]);

	const handleShare = useCallback(() => {
		(window as any).FB.ui(
			{
				display: "popup",
				method: "share",
				href: `${process.env.REACT_APP_BACKEND_SERVER}/api/certificates/?imgPath=${certificate?.fullUrl}`,
			},
			function(response) {
				//
			}
		);
		const updateCertificateMetaInfo = async () => {
			const userActionsController = inject("UserActionsController");
			const actions = (await userActionsController.getUserAction())
				.actions;

			await userActionsController.performUserAction({
				data: {
					certificate: actions.certificate.certificate,
					meta: {
						lastPopupOpenDate:
							actions.certificate.meta.lastPopupOpenDate,
						fbShares: actions.certificate.meta.fbShares + 1,
						popupSeenCount: actions.certificate.meta.popupSeenCount,
					},
				},
				name: "certificate",
			});
		};
		updateCertificateMetaInfo();
	}, [certificate]);

	const responsive = width < 600;
	if (!certificate) return null;

	return isOpen ? (
		<Popup>
			<PopupContentWithClose onClose={() => closePopup()}>
				{isLoading && <Loading />}
				<div
					style={{
						width: responsive
							? `${(width * 9) / 10}px`
							: `${(width * 3) / 4}px`,
						height: "100%",
						display: isLoading ? "none" : undefined,
					}}
				>
					<div className={styles.congrats}>ყოჩაღ!</div>
					<div className={styles.text}>
						შენ დაიმსახურე ელექტრონული სერტიფიკატი დისტანციურ
						საგაკვეთილო პროცესში აქტიური ჩართულობისთვის. შეგიძლია,
						გაუზიარო სერტიფიკატი მშობელს ან მეგობრებს.
					</div>

					<div>
						<div className={styles.inputTitle}>
							გთხოვთ, გადაამოწმოთ სახელი და გვარი
							სერტიფიკატისთვის.
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<input
								className={styles.nameInput}
								value={newName}
								style={{
									width: responsive ? "250px" : undefined,
									fontSize: responsive ? "16px" : undefined,
								}}
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) => setNewName(e.target.value)}
							/>
							{showSaveButton && (
								<div
									className={styles.saveButton}
									onClick={() => handleSave()}
									style={{
										fontSize: responsive
											? "16px"
											: undefined,
									}}
								>
									დასტური
								</div>
							)}
						</div>
					</div>

					<div className={styles.certificateContainer}>
						<div className={styles.certificateBox}>
							<img
								src={certificate?.fullUrl}
								alt="certificate"
								onLoad={() => setIsLoading(false)}
								className={styles.certificate}
								style={{
									width: "100%",
									height: "100%",
								}}
							/>
						</div>
					</div>
					<div
						className={styles.buttonsContainer}
						style={{
							fontSize: responsive ? "14px" : undefined,
						}}
					>
						<a
							style={{ display: "none" }}
							href={certificate?.fullUrl}
							download={certificate?.certificateOriginalName}
							ref={downloadRef}
						>
							D
						</a>
						<div
							className={styles.downloadButton}
							onClick={() => handleDownload()}
							style={{
								width: responsive ? "200px" : undefined,
								borderRadius: responsive ? " 8px" : undefined,
							}}
						>
							<DownloadIconWrapper
								style={{
									marginRight: responsive ? "6px" : undefined,
								}}
							>
								<DownloadIcon width={responsive ? 22 : 27} />
							</DownloadIconWrapper>
							ჩამოტვირთვა
						</div>
						<div
							className={styles.shareButton}
							onClick={() => handleShare()}
							style={{
								width: responsive ? "200px" : undefined,
								borderRadius: responsive ? " 8px" : undefined,
							}}
						>
							<FacebookIconWrapper
								style={{
									marginRight: responsive ? "7px" : undefined,
								}}
							>
								<FacebookIcon width={responsive ? 28 : 35} />
							</FacebookIconWrapper>
							გაზიარება
						</div>
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	) : null;
});

const DownloadIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: #5273e6;
	}
`;
const FacebookIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: white;
	}
`;
