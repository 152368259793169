import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	StatementSchema,
	ICommonQuestionParts,
	IStatement,
	forbiddenProperties,
	forbiddenProperty,
} from "../common-schemas";
import { markKeysForbidden } from "../../../../schemas/helper";

export const TextCorrectionContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.CorrectionOfText)
		.required(),
	items: Joi.array()
		.items(
			Joi.object({
				statement: StatementSchema.required(),
				correctAnswers: Joi.array()
					.items(StatementSchema)
					.required(),
			})
		)
		.required(),
});
export interface ITextCorrectionContent extends ICommonQuestionParts {
	type: ContentType.CorrectionOfText;
	items: {
		statement: IStatement;
		correctAnswers: IStatement[];
	}[];
}

export const RTextCorrectionContentSchema = CommonQuestionPartsSchema.fork(
	forbiddenProperties,
	markKeysForbidden
).keys({
	type: Joi.number()
		.valid(ContentType.CorrectionOfText)
		.required(),
	items: Joi.array().items(StatementSchema.required()),
});
export type IRTextCorrectionContent = Omit<
	ICommonQuestionParts,
	forbiddenProperty
> & {
	type: ContentType.CorrectionOfText;
	items: IStatement[];
};

///
