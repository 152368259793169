import { IAnyObj } from "./generics";
import { ValidationError, ValidationErrorItem } from "joi";

export type JoiValidationDetail = ValidationErrorItem & { mainKey: string };

export const getJoiErrorItems = <ErrorType extends IAnyObj>(
	error: ErrorType
): null | JoiValidationDetail[] => {
	try {
		const joiError = getJoiError(error);
		if (!joiError) return null;
		return joiError.details.map(appendMainKeyToError);
	} catch (e) {
		return null;
	}
};

const getJoiError = (error: IAnyObj): ValidationError | undefined => {
	if (!error || typeof error !== "object") return undefined;
	if (error.response && error.response.data) {
		error = error.response.data;
	}
	if (
		typeof error.details === "undefined" ||
		typeof error._original === "undefined"
	) {
		return undefined;
	}
	return error as ValidationError;
};

const appendMainKeyToError = (
	item: ValidationErrorItem
): JoiValidationDetail => {
	return {
		...item,
		mainKey: item.path.length > 0 ? item.path[0] + "" : "",
	};
};
