import {
	VideoLessonSchema,
	IVideoLesson,
	VideoLessonResourceTypes,
} from "@app/api/video-lessons/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";
import { generateFakeObjectId } from "@app/utils/common";
import { IClassTime } from "@app/api/classrooms/helper-schemas-2";
import {
	isAnyClasstimeTakingPlaceNow,
	isWithinClassTimes,
} from "@app/utils/dates";
import { avoidScheduledLessons } from "@app/services/video-lessons/utils";
import { getCurrentSubWebsite, SubWebsiteOrigin } from "@app/globals";

export enum VideoLessonStatus {
	NOT_AVAILABLE = 1,
	AVAILABLE = 2,
	ACTIVE = 3,
}

const keyOfId = "resourceId";
type IdKey = typeof keyOfId;
type DOC = IVideoLesson;
export type IStateVideoLessons = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(VideoLessonSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("videoLesson");
const storageSettings = getDefaultStorageSettings("videoLessons");
const metaInformationName = "videoLessonsMetaInformation";

const getClassStartTimeOffsetMin = () => {
	if (getCurrentSubWebsite() === SubWebsiteOrigin.britishCenter) return 2;
	return 4;
};
const LESSON_OFFSET_MS = 40 * 60 * 1000;

const MAX_LOAD_TIME_DIFF = 2 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().videoLessons) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", VideoLessonSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class VideoLesson extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByResourceId(resourceId: ObjectId): VideoLesson | null {
		return this.findByIdSync(resourceId);
	}

	static loadEmpty(
		resourceId: ObjectId,
		resourceType: VideoLessonResourceTypes
	): VideoLesson {
		return this.loadOneSync({
			_id: generateFakeObjectId(),
			resourceId,
			resourceType,
			url: "",
			createdAt: new Date(),
			updatedAt: new Date(),
		});
	}

	getClassroomStatus = (classTimes: IClassTime[]): VideoLessonStatus => {
		if (!this.url) return VideoLessonStatus.NOT_AVAILABLE;
		const inScheduleRange =
			!avoidScheduledLessons() &&
			isAnyClasstimeTakingPlaceNow({
				classTimes,
				minuteOffset: getClassStartTimeOffsetMin(),
			});
		if (inScheduleRange) return VideoLessonStatus.ACTIVE;
		if (
			this.lastVisited &&
			!isWithinClassTimes({
				date: new Date(this.lastVisited),
				classTimes,
			}) &&
			Date.now() - Date.parse(String(this.lastVisited)) < LESSON_OFFSET_MS
		) {
			return VideoLessonStatus.AVAILABLE;
		}
		return VideoLessonStatus.NOT_AVAILABLE;
	};

	getGroupStatus = () => {
		if (
			this.lastVisited &&
			Date.now() - Date.parse(String(this.lastVisited)) < LESSON_OFFSET_MS
		) {
			return VideoLessonStatus.ACTIVE;
		}
		return VideoLessonStatus.NOT_AVAILABLE;
	};

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, VideoLesson.meta);

export const videoLessonsReducer = getDefaultReducer(
	storageSettings,
	() => VideoLesson
);
