import React from "react";
import { Group } from "@app/models/group";
import styles from "../../styles/index.module.scss";
import { useUserShortInfo } from "@app/hooks/users";
import {
	HeadmasterGroupInfoContainer,
	HeadmasterGroupBoxInfoType,
} from "./info-container";
import { useGoToUrl } from "@app/hooks/front";
import { headmasterLinks } from "@app/routes/headmaster/link";
import classNames from "classnames";

export const HeadmasterGroupBox: React.FC<{ group: Group }> = ({ group }) => {
	const mentorId = group.mentorIds.length >= 1 ? group.mentorIds[0] : null;
	const mentor = useUserShortInfo(mentorId);
	const goToGroup = useGoToUrl(
		headmasterLinks.groups.timetable({ groupId: group._id })
	);

	return (
		<HeadmasterGroupBoxMarkup
			mentorName={mentor.doc?.getFullName() || ""}
			onClick={goToGroup}
			studentsCount={group.studentIds.length}
			title={group.name}
		/>
	);
};

const EnterButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	return (
		<div className={styles.enterButton} onClick={onClick}>
			შესვლა
		</div>
	);
};

export const HeadmasterGroupBoxMarkup: React.FC<{
	title: string | undefined;
	mentorName: string;
	studentsCount: number;
	onClick: () => void;
	topPart?: JSX.Element;
	subject?: { name: string; photo: string | null };
	studentsCountRight?: JSX.Element | boolean | null;
}> = ({
	title,
	mentorName,
	studentsCount,
	onClick,
	topPart,
	subject,
	studentsCountRight,
}) => {
	return (
		<div
			className={classNames(
				styles.boxContainer,
				!!subject && styles.box3Elements
			)}
		>
			{topPart}
			<div className={styles.groupName}>{title}</div>
			<HeadmasterGroupInfoContainer
				info={{
					type: HeadmasterGroupBoxInfoType.mentor,
					name: mentorName,
				}}
			/>
			<HeadmasterGroupInfoContainer
				info={{
					type: HeadmasterGroupBoxInfoType.students,
					quantity: studentsCount,
				}}
				right={studentsCountRight}
			/>
			{subject && (
				<HeadmasterGroupInfoContainer
					info={{
						type: HeadmasterGroupBoxInfoType.subject,
						name: subject.name,
						photo: subject.photo,
					}}
				/>
			)}
			<EnterButton onClick={onClick} />
		</div>
	);
};
