import { ISubject } from "@app/api/subjects/helper-schemas";
import { IRGETProfileInfoAsTeacher } from "@app/api/users/validators";
import { CertificateBox } from "@app/components/profile/certificate";
import { StudentCertificatesBox } from "@app/components/teachers/certificates/certificate-candidates/student-certificates-box";
import { useLocale } from "@app/hooks/intl";
import React from "react";
import { FormattedMessage } from "react-intl";
import SubjectIcon from "../general_components/general_imgs/georgian.svg";
import genStyles from "../general_components/styles/general_styles.module.css";
import { useSubjectsForProfileWithPagnation } from "../general_components/hooks";
import classnames from "classnames";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { isCertificateServicesAvaliable } from "@app/consts";

export const TeacherSubject: React.FC<{
	subjects: ISubject[];
	teacherInfo: IRGETProfileInfoAsTeacher;
}> = ({ subjects, teacherInfo }) => {
	const isGeo = useLocale() === "ka";
	const {
		canShiftLeft,
		canShiftRight,
		currentChunk,
		shiftLeft,
		shiftRigth,
	} = useSubjectsForProfileWithPagnation(subjects, 2);

	return (
		<>
			{currentChunk && (
				<>
					<div className={genStyles.title}>
						<FormattedMessage id="mySubjects" />
						{(canShiftLeft || canShiftRight) && (
							<div
								style={{
									display: "flex",
									height: "20px",
									alignItems: "center",
								}}
							>
								<ChevronLeftIcon
									className={classnames(
										genStyles.shiftIcon,
										canShiftLeft
											? undefined
											: genStyles.light
									)}
									onClick={(
										e: React.MouseEvent<
											SVGSVGElement,
											MouseEvent
										>
									) => {
										e.stopPropagation();
										if (canShiftLeft) shiftLeft();
									}}
								/>
								<ChevronRightIcon
									className={classnames(
										genStyles.shiftIcon,
										canShiftRight
											? undefined
											: genStyles.light
									)}
									onClick={(
										e: React.MouseEvent<
											SVGSVGElement,
											MouseEvent
										>
									) => {
										e.stopPropagation();
										if (canShiftRight) shiftRigth();
									}}
								/>
							</div>
						)}
					</div>
					<section className={genStyles.right__sidebar_box}>
						{currentChunk.map(subj => (
							<section
								className={genStyles.right__sidebar_box__item}
								key={subj._id}
							>
								<img
									src={subj.photo ? subj.photo : SubjectIcon}
									alt=""
								/>
								<p style={{ textAlign: "center" }}>
									{subj.name}
								</p>
							</section>
						))}
					</section>
				</>
			)}
			<h3>
				<FormattedMessage id="myAssignments" />
			</h3>
			<section
				className={genStyles.right__sidebar_box}
				style={{ backgroundColor: "#fff" }}
			>
				<section className={genStyles.right__sidebar_box__item}>
					<p className={genStyles.secondary_text}>
						<FormattedMessage id="allSentAssignments" />
					</p>
					<span>{teacherInfo.sentAssignments}</span>
					<p className={genStyles.blue_text}></p>
				</section>
				<section className={genStyles.vertical_line}></section>
				<section className={genStyles.right__sidebar_box__item}>
					<p className={genStyles.secondary_text}>
						<FormattedMessage id="myCreatedAssignments" />
					</p>
					<span>{teacherInfo.createAssignments}</span>
					<p className={genStyles.blue_text}> </p>
				</section>
			</section>

			{isGeo && isCertificateServicesAvaliable && (
				<>
					<h3>სერტიფიკატები</h3>
					<section className={genStyles.right__sidebar_box}>
						<CertificateBox
							containerClassname={classnames(
								genStyles.sertificate_box__item,
								genStyles.box_height
							)}
							textContaier={genStyles.sertification}
							imgClassname={genStyles.sertification_img}
						/>
						<StudentCertificatesBox
							text={"სერტიფიკატი მოსწავლეებისთვის"}
							certificateBoxClassname={classnames(
								genStyles.sertificate_box__item,
								genStyles.box_height
							)}
							textWrapperClassname={genStyles.sertification}
							imgClassname={genStyles.sertification_img}
						/>
					</section>
				</>
			)}
		</>
	);
};
