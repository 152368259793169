import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { Diff, StrictOmit, IAnyObj, ObjectId, ReplaceKeys } from "./generics";
import { getFormattedMessage } from "./locale";

export function removeKeys<T, K extends keyof T>(
	obj: T,
	...keys: K[]
): StrictOmit<T, K> {
	const obj2 = { ...obj };
	for (let i = 0; i < keys.length; ++i) {
		delete obj2[keys[i]];
	}
	return obj2;
}

export function pickKeys<T extends {}, K extends keyof T>(
	obj: T,
	...keys: K[]
): Pick<T, K> {
	const obj2 = {} as Pick<T, K>;
	for (let i = 0; i < keys.length; ++i) {
		if (obj.hasOwnProperty(keys[i]) || obj[keys[i]] !== undefined) {
			obj2[keys[i]] = obj[keys[i]];
		}
	}
	return obj2;
}

export function removeUndefinedValues<T>(obj: T): T {
	const obj2 = { ...obj } as T;
	const keys = Object.keys(obj);
	for (const key of keys) {
		if (obj2[key] === undefined) {
			delete obj2[key];
		}
	}
	return obj2;
}

export function getUniqueId(currentIds: number[], numOfIds = 1): number[] {
	const ids = [...currentIds];
	const newIds: number[] = [];
	for (let i = 0; i < numOfIds; ++i) {
		let uid = 0;
		do {
			uid = Math.floor(Math.random() * 1e9);
		} while (ids.indexOf(uid) !== -1);
		newIds.push(uid);
		ids.push(uid);
	}
	return newIds;
}

export function objectValues<T extends {}>(
	obj: T
): Diff<T[keyof T], undefined>[] {
	const keys = Object.keys(obj);
	const arr: Diff<T[keyof T], undefined>[] = [];
	for (let i = 0; i < keys.length; ++i) {
		const val = obj[keys[i]];
		if (val === undefined) continue;
		arr.push(val);
	}
	return arr;
}

export function getUniqueValues<T>(...args: (T[] | Set<T>)[]): T[] {
	const values: T[] = [];
	for (const arg of args) {
		values.push(...arg);
	}
	return [...new Set(values)];
}

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple?: false
): { [key: string]: T | undefined };
export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple: true
): { [key: string]: T[] | undefined };

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: readonly T[],
	mainKey: K,
	allowMultiple: true
): { [key: string]: T[] | undefined };

export function arrayToObject<T extends {}, V>(
	arr: readonly T[],
	fn: (
		item: T,
		index: number,
		orgininalArr: T[]
	) => { key: string | number; value: V } | null,
	allowMultiple?: false
): { [key: string]: V | undefined };
export function arrayToObject<T extends {}, V>(
	arr: readonly T[],
	fn: (item: T) => { key: string | number; value: V } | null,
	allowMultiple: true
): { [key: string]: V[] | undefined };

export function arrayToObject(
	arr: readonly any[],
	mainKey: any,
	allowMultiple = false
): { [key: string]: any } {
	const obj: { [key: string]: any } = {};
	if (!allowMultiple) {
		for (let i = 0; i < arr.length; ++i) {
			let key = arr[i][mainKey as string];
			let value = arr[i];
			if (typeof mainKey === "function") {
				const temp = mainKey(arr[i], i, arr);
				if (!temp) continue;
				key = temp.key;
				value = temp.value;
			}
			obj[key] = value;
		}
		return obj;
	}

	for (let i = 0; i < arr.length; ++i) {
		let key = arr[i][mainKey as string];
		let value = arr[i];
		if (typeof mainKey === "function") {
			const temp = mainKey(arr[i], i, arr);
			if (!temp) continue;
			key = temp.key;
			value = temp.value;
		}
		if (!obj[key]) {
			obj[key] = [];
		}
		obj[key].push(value);
	}
	return obj;
}

export const uniqueObjs = <T extends {}>(arr: T[], key: keyof T) => {
	const obj = arrayToObject(arr, key);
	return objectValues(obj);
};

// tslint:disable-next-line:cognitive-complexity
export function mergeRecursive<T1 extends IAnyObj, T2 extends IAnyObj>(
	object1: T1,
	object2: T2
): T1 & T2 {
	const obj1 = { ...object1 };
	const obj2 = { ...object2 };
	for (const p in obj2) {
		if (!obj2.hasOwnProperty(p)) continue;
		try {
			// Property in destination object set; update its value.
			if (obj2[p].constructor === Object) {
				obj1[p] = mergeRecursive(obj1[p], obj2[p]);
			} else {
				obj1[p] = obj2[p] as any;
				if (obj1[p] === undefined) delete obj1[p];
			}
		} catch (e) {
			// Property in destination object not set; create it and set its value.
			obj1[p] = obj2[p] as any;
			if (obj1[p] === undefined) delete obj1[p];
		}
	}

	return obj1 as any;
}

export function timeDiff(date1: Date | undefined, date2?: Date): number {
	if (!date1) return Infinity;
	try {
		const time2 = date2 ? date2.getTime() : Date.now();
		return time2 - date1.getTime();
	} catch (e) {
		console.log(e);
		return Infinity;
	}
}

export const reflectPromise = <T>(p): Promise<IReflectPromiseRes<T>> =>
	p
		.then(v => ({ v, status: "resolved" }))
		.catch(e => ({ e, status: "rejected" }));
export type IReflectPromiseRes<T> =
	| { v: T; status: "resolved" }
	| { e: Error; status: "rejected" };

export const delayPromise = (milliseconds: number) =>
	new Promise(resolve => setTimeout(resolve, milliseconds));

export function roundDecimalPlaces(num: number, decimalPlaces = 0) {
	const p = Math.pow(10, decimalPlaces);
	const m = num * p * (1 + Number.EPSILON);
	return Math.round(m) / p;
}

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
export function jsonDateParser(key: any, value: any) {
	if (typeof value === "string") {
		const a = reISO.exec(value);
		if (a) return new Date(value);
	}
	return value;
}

export function getOldIfUnchanged<T>(newObj: T, oldObj: T): T {
	if (
		newObj === oldObj ||
		JSON.stringify(newObj) === JSON.stringify(oldObj)
	) {
		return oldObj;
	} else {
		return newObj;
	}
}

export function isValidDateString(str: string): boolean {
	return !Number.isNaN(Date.parse(str));
}

export function deepEqual(x: any, y: any) {
	if (x === y) {
		return true;
	}

	if (
		typeof x !== "object" ||
		x === null ||
		typeof y !== "object" ||
		y === null
	) {
		return false;
	}

	if (x instanceof Date && y instanceof Date) {
		return x.getTime() === y.getTime();
	}

	if (Object.keys(x).length !== Object.keys(y).length) {
		return false;
	}

	for (const prop in x) {
		if (!x.hasOwnProperty(prop)) continue;
		if (y.hasOwnProperty(prop)) {
			if (!deepEqual(x[prop], y[prop])) {
				return false;
			}
		} else {
			return false;
		}
	}

	return true;
}

export const generateFakeObjectId = (
	m = Math,
	d = Date,
	h = 16,
	s = s => m.floor(s).toString(h)
) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

export function mapValues<
	KeyT extends string | number | symbol,
	ValueT,
	NewValueT
>(
	obj: Record<KeyT, ValueT>,
	mapFn: (key: ValueT) => NewValueT
): Record<KeyT, NewValueT> {
	const res = {} as Record<KeyT, NewValueT>;
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			res[key] = mapFn(obj[key]);
		}
	}
	return res;
}

export function filterKeys<ValueT>(
	obj: Record<string, ValueT>,
	filterFn: (value: ValueT) => boolean
): string[] {
	const result: string[] = [];
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const val = obj[key];
			if (filterFn(val)) {
				result.push(key);
			}
		}
	}
	return result;
}

export function getValues<KeyT extends string | number | symbol, ValueT>(
	obj: Record<KeyT, ValueT>,
	keys: KeyT[]
): ValueT[] {
	const values: ValueT[] = [];
	for (const key of keys) {
		if (obj.hasOwnProperty(key)) {
			values.push(obj[key]);
		}
	}
	return values;
}

export function subtractSet<T extends string | number>(
	this: Set<T>,
	set2: Iterable<T>
) {
	for (const x of set2) {
		this.delete(x);
	}
	return this;
}

const dateFromObjectId = (objectId: string) => {
	return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export const compareObjectIds = (a: ObjectId, b: ObjectId): number => {
	const date1 = dateFromObjectId(a);
	const date2 = dateFromObjectId(b);
	if (date1 < date2) return -1;
	if (date1 > date2) return 1;
	return 0;
};

export const isGeorgianChar = (str: string) => {
	if (str.length > 1) {
		return false;
	}
	return str >= "ა" && str <= "ჰ";
};

export const isRomanianNumberChar = (str: string) => {
	if (str.length > 1) {
		return false;
	}
	return !!RomanianUniqueSymbols.find(e => e === str);
};

export const RomanianUniqueSymbols = ["M", "C", "D", "X", "L", "V", "I"];

export const isRomanianNumber = (str: string) => {
	if (str.length === 0) return false;
	for (let i = 0; i < str.length; i++) {
		const element = str[i];
		if (!RomanianUniqueSymbols.find(e => e === element)) {
			return false;
		}
	}
	return true;
};

const romanianSymbolsObject = {
	M: 1000,
	CM: 900,
	D: 500,
	CD: 400,
	C: 100,
	XC: 90,
	L: 50,
	XL: 40,
	X: 10,
	IX: 9,
	V: 5,
	IV: 4,
	I: 1,
};

export function romanize(num: number) {
	let roman = "";
	for (const i in romanianSymbolsObject) {
		while (num >= romanianSymbolsObject[i]) {
			roman += i;
			num -= romanianSymbolsObject[i];
		}
	}
	return roman;
}

export const unromanize = (str1: string): number => {
	if (str1 == null) return -1;
	let num = romanianSymbolsObject[str1.charAt(0)];
	let pre, curr;

	for (let i = 1; i < str1.length; i++) {
		curr = romanianSymbolsObject[str1.charAt(i)];
		pre = romanianSymbolsObject[str1.charAt(i - 1)];
		if (curr <= pre) {
			num += curr;
		} else {
			num = num - pre * 2 + curr;
		}
	}

	return num;
};

export function unnescapeHtml(text: string): string {
	return text
		.replace(/&amp;/g, "&")
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">")
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'")
		.replace(/<br\/>/g, "\n")
		.replace(/<br>/g, "\n");
}

export function escapeHtml(text: string): string {
	return text
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

export const parseUrl = (string: string) => {
	const a = document.createElement("a");
	a.setAttribute("href", string);
	const { host, hostname, pathname, port, protocol, search, hash } = a;
	const origin = `${protocol}//${hostname}${port.length ? `:${port}` : ""}`;
	return { origin, host, hostname, pathname, port, protocol, search, hash };
};

export const withEllipsis = (
	text: string,
	maxNumOfLetters: number,
	ellipsis = "..."
) => {
	if (text.length <= maxNumOfLetters || maxNumOfLetters === 0) return text;
	return text.slice(0, maxNumOfLetters - ellipsis.length) + ellipsis;
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export function chunkArray<T>(myArray: T[], chunk_size: number): T[][] {
	let index = 0;
	const arrayLength = myArray.length;
	const tempArray: T[][] = [];

	for (index = 0; index < arrayLength; index += chunk_size) {
		const myChunk = myArray.slice(index, index + chunk_size);
		// Do something if you want with the group
		tempArray.push(myChunk);
	}

	return tempArray;
}

export function replaceKeys<
	T,
	K extends { [key in keyof Partial<T>]: PropertyKey }
>(obj: T, keys: K): ReplaceKeys<T, K> {
	const newObj = {} as any;
	for (const key in obj) {
		if (key in keys) {
			newObj[keys[key]] = obj[key];
		} else {
			newObj[key] = obj[key];
		}
	}
	return newObj;
}

export function replaceKeysInArr<
	T,
	K extends { [key in keyof Partial<T>]: PropertyKey }
>(arr: T[], keys: K): ReplaceKeys<T, K>[] {
	return arr.map(e => replaceKeys(e, keys));
}

export function sortObjKeys<T extends {}>(obj: T): T {
	const newObj = {} as T;
	const keys = Object.keys(obj).sort();
	for (const key of keys) {
		newObj[key] = obj[key];
	}
	return newObj;
}

export const flatten = <T extends Array<unknown> = any>(
	arr: T,
	unique?: "unique"
): T extends Array<infer R> ? R : never => {
	const flattened = (arr as any).reduce(
		(acc, val) =>
			Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
		[]
	);
	if (unique) {
		return [...new Set(flattened)] as any;
	}
	return flattened;
};

export function sum(arr: number[]): number;
export function sum<T extends {}, K extends keyof T>(arr: T[], key: K): number;
export function sum(arr: any[], key?: string): number {
	let totalSum = 0;
	for (const elem of arr) {
		if (key) totalSum += elem[key];
		else totalSum += elem;
	}
	return totalSum;
}

export const getItemFromStorage = (storage: Storage, key: string) => {
	const value = storage.getItem(key);
	if (value === null) return null;
	return JSON.parse(value, jsonDateParser);
};

export const dateDifferenceInDay = (a: Date, b: Date) => {
	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const diffTime = Math.abs((a as any) - (b as any));
	return Math.ceil(diffTime / millisecondsPerDay) - 1;
};

export const isNonNullable = <T extends any>(val: T): val is NonNullable<T> => {
	return val !== undefined && val !== null;
};

export function as<T>(x: any): asserts x is T {
	//
}

export const combinePaths = (
	...paths: (string | undefined | null)[]
): string => {
	return paths
		.map((part, i) => {
			const p = part || "";
			if (i === 0) {
				return p.trim().replace(/[\/]*$/g, "");
			} else {
				return p.trim().replace(/(^[\/]*|[\/]*$)/g, "");
			}
		})
		.filter(x => x.length)
		.join("/");
};

/**
 * Returns an array with integer elements,
 * starting from `start` until `end` (not inclusive).
 * @param start start of the range
 * @param end end of the range (not inclusive)
 */
export function intRange(start: number, end: number) {
	const result: number[] = [];
	for (let i = start; i < end; i++) {
		result.push(i);
	}
	return result;
}

export const alertThatThisUserIsNotTeacherOfThisClassroom = () => {
	// alert(getFormattedMessage("isNotTeacherOfThisClassroom"));
	openConfirmationPopup({
		text: getFormattedMessage("isNotTeacherOfThisClassroom"),
	});
};

export const isBlankString = (str: string | undefined | null) => {
	if (!str) return true;
	return str.replace(/\s/g, "").length === 0;
};
