import { Subscription } from "./subscription";

export interface SchedulerOptions {
	treatHigherNumbersWithHigherPriority: boolean;
	defaultPreemptive: boolean;
	defaultPriority: number;
	defaultDelayInMilliseconds: number;
	defaultRestartDelayEachTime?: boolean;
}

export interface SchedulerTaskOption {
	priority?: number;
	enabled: boolean;
	preemptive?: boolean;
	delayInMilliseconds?: number;
	restartDelayEachTime?: boolean;
}

export interface SchedulerTask {
	readonly enabled: boolean;
	readonly priority: number;
	readonly preemptive: boolean;
	readonly delayInMilliseconds: number;
	readonly restartDelayEachTime: boolean;
	readonly isRunning: boolean;

	markDisabled(): void;

	setOptions(options: SchedulerTaskOption): void;

	markEnabled(): void;

	setPriority(priority: number): void;

	subscribe(fn: (isRunning: boolean) => void): Unsubscribe;

	destroy(): void;
}

type IdType = number;

export class SingleThreadScheduler {
	private readonly priorityCoeff: number;
	private readonly defaultPriority: number;
	private readonly defaultDelayInMilliseconds: number;
	private readonly defaultRestartDelay: boolean;
	private counter: number;
	private readonly defaultPreemptive: boolean;
	private readonly tasks: Record<
		IdType,
		InstanceType<SingleThreadScheduler["Task"]> | undefined
	>;
	private topTask: InstanceType<SingleThreadScheduler["Task"]> | null;
	private plannedTopTaskRunning: NodeJS.Timeout | null;

	constructor(options: SchedulerOptions) {
		this.priorityCoeff = options.treatHigherNumbersWithHigherPriority
			? -1
			: 1;
		this.tasks = {};
		this.counter = 0;
		this.topTask = null;
		this.defaultPreemptive = options.defaultPreemptive;
		this.defaultPriority = options.defaultPriority;
		this.defaultDelayInMilliseconds =
			options.defaultDelayInMilliseconds || 0;
		this.defaultRestartDelay = !!options.defaultRestartDelayEachTime;
	}

	private readonly Task = class Task implements SchedulerTask {
		readonly enabled: boolean;
		readonly priority: number;
		readonly preemptive: boolean;
		readonly delayInMilliseconds: number;
		readonly restartDelayEachTime: boolean;
		readonly isRunning: boolean;
		private subscriptions = new Subscription<boolean>();
		readonly createdAt: number;

		constructor(
			private scheduler: SingleThreadScheduler,
			public readonly id: IdType,
			options: SchedulerTaskOption
		) {
			this.isRunning = false;
			this.createdAt = Date.now();
			this.setOptions(options, true);
		}

		setOptions(options: SchedulerTaskOption, initialCall?: boolean) {
			const modifiableThis = this as Mutable<this>;
			modifiableThis.enabled = !!options.enabled;
			modifiableThis.priority =
				options.priority === undefined
					? this.scheduler.defaultPriority
					: options.priority;
			modifiableThis.preemptive =
				options.preemptive === undefined
					? this.scheduler.defaultPreemptive
					: options.preemptive;
			modifiableThis.delayInMilliseconds =
				options.delayInMilliseconds === undefined
					? this.scheduler.defaultDelayInMilliseconds
					: options.delayInMilliseconds;
			modifiableThis.restartDelayEachTime =
				options.restartDelayEachTime === undefined
					? this.scheduler.defaultRestartDelay
					: options.restartDelayEachTime;
			if (!initialCall) {
				this.scheduler.reprioritize();
			}
		}

		getDelay() {
			if (this.restartDelayEachTime) return this.delayInMilliseconds;
			const offset = Date.now() - this.createdAt;
			return Math.max(this.delayInMilliseconds - offset, 0);
		}

		markDisabled() {
			const modifiableThis = this as Mutable<this>;
			modifiableThis.enabled = false;
			this.scheduler.reprioritize();
		}

		markEnabled() {
			const modifiableThis = this as Mutable<this>;
			modifiableThis.enabled = true;
			this.scheduler.reprioritize();
		}

		setPriority(priority: number) {
			const modifiableThis = this as Mutable<this>;
			modifiableThis.priority = priority;
			this.scheduler.reprioritize();
		}

		destroy() {
			this.subscriptions.clearSubscribers();
			delete this.scheduler.tasks[this.id];
			this.scheduler.reprioritize();
		}

		isDeleted() {
			return !this.scheduler[this.id];
		}

		subscribe(fn: (isRunning: boolean) => void) {
			return this.subscriptions.subscribe(fn);
		}

		setRunningState(isRunning: boolean) {
			const modifiableThis = this as Mutable<this>;
			if (this.isRunning === isRunning) return;
			modifiableThis.isRunning = isRunning;
			this.subscriptions.broadcast(isRunning);
		}

		getRealPriority() {
			return this.priority * this.scheduler.priorityCoeff;
		}
	};

	private generateId(): IdType {
		return ++this.counter;
	}

	addTask(taskOptions: SchedulerTaskOption): SchedulerTask {
		const id = this.generateId();
		const task = new this.Task(this, id, taskOptions);
		this.tasks[id] = task;
		this.reprioritize();
		return task;
	}

	private reprioritize() {
		if (
			this.topTask &&
			(this.topTask.isDeleted() || !this.topTask.enabled)
		) {
			this.topTask = null;
		}
		const bestChoice = this.getBestTaskByPriority();

		if (!bestChoice) {
			this.setWinner(null);
			return;
		}

		if (!this.topTask) {
			this.setWinner(bestChoice);
			return;
		}

		if (
			bestChoice.id === this.topTask.id ||
			bestChoice.getRealPriority() === this.topTask.getRealPriority()
		) {
			return;
		}

		if (this.topTask.isRunning && !this.topTask.preemptive) {
			return;
		}

		this.setWinner(bestChoice);
	}

	private getBestTaskByPriority() {
		const tasks = this.getTasks();

		let bestChoice: InstanceType<
			SingleThreadScheduler["Task"]
		> | null = null;

		for (const task of tasks) {
			if (!task.enabled) continue;
			if (!bestChoice) {
				bestChoice = task;
				continue;
			}
			if (task.getRealPriority() < bestChoice.getRealPriority()) {
				bestChoice = task;
			} else if (
				task.getRealPriority() === bestChoice.getRealPriority() &&
				task.getDelay() < bestChoice.getDelay()
			) {
				bestChoice = task;
			}
		}
		return bestChoice;
	}

	private setWinner(
		task: InstanceType<SingleThreadScheduler["Task"]> | null
	) {
		if (!task) {
			return this.forcefullySetWinner(task);
		}

		this.topTask = task;
		const delay = task.getDelay();

		if (this.plannedTopTaskRunning !== null) {
			clearTimeout(this.plannedTopTaskRunning);
		}

		if (delay > 0) {
			this.plannedTopTaskRunning = setTimeout(() => {
				this.forcefullySetWinner(task);
			}, delay);
		} else {
			this.forcefullySetWinner(task);
		}
	}

	private forcefullySetWinner(
		task: InstanceType<SingleThreadScheduler["Task"]> | null
	) {
		this.topTask = task;
		this.plannedTopTaskRunning = null;
		const id = task?.id;

		const tasks = this.getTasks();
		for (const task of tasks) {
			task.setRunningState(task.id === id);
		}
	}

	private getTasks(): InstanceType<SingleThreadScheduler["Task"]>[] {
		const tasts: any = [];
		const ids = Object.keys(this.tasks);
		for (const id of ids) {
			const task = this.tasks[(id as any) as IdType]!;
			tasts.push(task);
		}
		return tasts;
	}
}

type Mutable<T> = {
	-readonly [k in keyof T]: T[k];
};

type Unsubscribe = () => void;
