import { wrapInSidebarItemsFilter } from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarType } from "@app/components/layouts/sidebar/helpers/interfaces";
import { SubWebsiteOrigin } from "@app/globals";
import {
	SidebarGradebookIcon,
	SidebarSemesterGradesIcon,
	SidebarSentHomeworkIcon,
} from "@app/icons";
import { studentLinks } from "@app/routes/student/links";

// disable sidebar for the schools
export const gradebookHiddenSchools: number[] = [4428];

export const getStudentSidebarItems = wrapInSidebarItemsFilter(({ whazz }) => {
	if (whazz.type === SidebarType.classroom) {
		return [
			{
				name: "student:assignments",
				href: studentLinks.classrooms.assignments.list.routePath,
				icon: SidebarSentHomeworkIcon,
			},
			{
				name: "studentMyGrades",
				href: studentLinks.classrooms.gradebook.routePath,
				icon: SidebarGradebookIcon,
				notAvailableSchoolIds: gradebookHiddenSchools,
				subWesbites: SubWebsiteOrigin.main,
			},
			{
				name: "semesterGrades",
				href: studentLinks.classrooms.semesterbook.routePath,
				icon: SidebarSemesterGradesIcon,
				notAvailableSchoolIds: gradebookHiddenSchools,
				subWesbites: SubWebsiteOrigin.main,
			},
		];
	}
	return null;
});
