import { IRequest } from "../requests";
import {
	IAPOSTUserRequest,
	IRPOSTUserRequest,
	RPOSTUserRequestSchema,
} from "./validators";

export class UserRequestsController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (args: IAPOSTUserRequest): Promise<IRPOSTUserRequest> =>
		this.Request.send("POST", "/api/user-requests", args, null, {
			responseSchema: RPOSTUserRequestSchema,
		}).then((data: IRPOSTUserRequest) => data);
}
