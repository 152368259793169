import {
	createPropHistoryContent,
	getPropHistoryChangeHook,
} from "./properties-context";
import { IAuthMetaData } from "@app/api/auth/helper-schemas";
import React, { useContext } from "react";
import { UWeeklyTestReservations } from "./users/assignments/tv-school/helpers/utils";
import { HeaderTheme } from "./layouts/header/interfaces";

export interface GeneralContextData {
	isHeaderHidden?: boolean;
	headerTheme?: HeaderTheme;
	isFAQHidden?: boolean;
	isNewSidebarVisible?: boolean;
	authMetaData?: IAuthMetaData;
	isSidebarOpen?: boolean;
	hideAds?: boolean;
	hideVideoInstructions?: boolean;
}

const {
	context: GeneralContext,
	provider: GeneralContextProvider,
} = createPropHistoryContent<GeneralContextData>(
	{
		authMetaData: {
			weeklyTestInfo: UWeeklyTestReservations.get(),
		},
	},
	"GeneralContextData"
);

export { GeneralContext, GeneralContextProvider };

export function useGeneralContext(): GeneralContextData;
export function useGeneralContext<K extends keyof GeneralContextData>(
	key: K
): GeneralContextData[K];
export function useGeneralContext(key?: any): any {
	const value = useContext(GeneralContext).data;
	if (key === undefined) return value;
	return value[key];
}

export const useSetIsHeaderHidden = getPropHistoryChangeHook(
	GeneralContext,
	"isHeaderHidden"
);

export const useSetIsNewSidebarVisible = getPropHistoryChangeHook(
	GeneralContext,
	"isNewSidebarVisible"
);

export const useSetVideoInstructionHidden = getPropHistoryChangeHook(
	GeneralContext,
	"hideVideoInstructions"
);

export const useSetIsSidebarOpen = getPropHistoryChangeHook(
	GeneralContext,
	"isSidebarOpen"
);

export const useHeaderThemeSetter = getPropHistoryChangeHook(
	GeneralContext,
	"headerTheme"
);

export const MathEquationContext = React.createContext(false);

export const useShowMathEquation = () => useContext(MathEquationContext);
