import React, { useCallback, useState, useRef } from "react";
import { getFormattedMessage } from "@app/utils/locale";
import StudentRegisterStyles from "./styles/student-register-page.module.css";
import { InputLabel } from "./student-register-page";
import { MobileInput } from "./input-forms";
import { inject } from "@app/modules";
import ClearIcon from "@material-ui/icons/Clear";
import { UserType } from "@app/api/helper-schemas";

export const ParentRegistrationSecondStep: React.FC<{
	isUpperTextVissible?: boolean;
	inputClassname?: string;
	childInfo: {
		mobile: React.MutableRefObject<string>;
		id: React.MutableRefObject<number | undefined>;
	};
	childrenInfos: {
		mobile: React.MutableRefObject<string>;
		id: React.MutableRefObject<number | undefined>;
	}[];
	setChildrenInfos: React.Dispatch<
		React.SetStateAction<
			{
				mobile: React.MutableRefObject<string>;
				id: React.MutableRefObject<number | undefined>;
			}[]
		>
	>;
	defaultValue?: string;
	hasInputPlaceholder?: boolean;
	placeholder?: string;
}> = props => {
	const newMob = useRef(""); //for creating new empty field
	const newId: React.MutableRefObject<number | undefined> = useRef(); //for creating new empty field
	const [studentName, setStudentName] = useState<string | null>();
	const [isDeleted, setIsDeleted] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState("");

	const onStudentMobileChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setErrorMessage("");

			props.childInfo.mobile.current = e.target.value;

			const UsersController = inject("UsersController");
			if (e.target.value.length === 9) {
				UsersController.getUserByMobileOrId({
					mobile: e.target.value,
				})
					.then(data => {
						if (data.type === UserType.student) {
							setStudentName(
								`${data.firstname}  ${data.lastname &&
									data.lastname![0] &&
									data.lastname![0]!}. `
							);
							props.childInfo.id.current = data.id;
							props.setChildrenInfos(x => {
								return [...x, { mobile: newMob, id: newId }];
							});
							setErrorMessage("");
						} else {
							setErrorMessage(
								"მომხმარებელი არ მოიძებნა. გადაამოწმეთ 9-ნიშნა ნომრის სისწორე."
							);
						}
					})
					.catch(e => {
						setStudentName("");
						setErrorMessage(
							"მომხმარებელი არ მოიძებნა. გადაამოწმეთ 9-ნიშნა ნომრის სისწორე."
						);
					});
			}
		},
		[props.childrenInfos]
	);

	const onDeleteStudent = useCallback(() => {
		props.childInfo.mobile.current = "";
		props.childInfo.id.current = undefined;
		setStudentName("");
		setIsDeleted(true);
	}, [props.childrenInfos]);

	const onAddNewMobile = useCallback((mobile?: string) => {
		if (mobile) {
			newMob.current = mobile;
		}
		props.setChildrenInfos(x => {
			return [...x, { mobile: newMob, id: newId }];
		});
	}, []);

	return (
		<div className={StudentRegisterStyles.psecondstepChildrenInfo}>
			<InputLabel
				label={"მოსწავლის მობილური ნომერი"}
				isLabelHidden={!props.isUpperTextVissible}
			>
				{ref => (
					<div
						className={
							StudentRegisterStyles.plusButtonWithInputContainer
						}
					>
						{studentName && !isDeleted && (
							<>
								<div
									className={
										StudentRegisterStyles.studentName
									}
								>
									{studentName}
								</div>
								<div
									className={
										StudentRegisterStyles.cancelButton
									}
									onClick={onDeleteStudent}
								>
									<ClearIcon style={{ fontSize: 37 }} />
								</div>
							</>
						)}
						{!studentName && !isDeleted && (
							<>
								<MobileInput
									onChange={onStudentMobileChange}
									// errorText={errors && errors.mobile}
									className={
										props.inputClassname ||
										StudentRegisterStyles.input
									}
									defaultValue={props.defaultValue || ""}
									placeholder={
										props.hasInputPlaceholder
											? getFormattedMessage(
													"inputs.mobile"
											  )
											: props.placeholder
									}
									mainContainerWrapperClassname={
										StudentRegisterStyles.mainContainerWrapperClassnameForMobile
									}
									inputRef={ref}
									errorText={errorMessage}
								/>
								<div
									className={StudentRegisterStyles.plusButton}
									onClick={() => onAddNewMobile()}
								>
									+
								</div>
							</>
						)}
					</div>
				)}
			</InputLabel>
		</div>
	);
};
