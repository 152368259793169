import { useClassroomsUser } from "@app/hooks/users";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CheckCode } from "./check-code";
import { CheckMobile } from "./check-mobile";
import { Confirmed } from "./confirmed";
import genStyles from "../general_components/styles/general_styles.module.css";

export const Mobile: React.FC<{}> = props => {
	const user = useClassroomsUser()!;
	const [isInputMobileClicked, setIsInputMobileClicked] = useState(false);
	const [didPasswordUpdate, setDidPasswordUpdate] = useState(false);
	const mobileRef = useRef(user.mobile || "");
	const [token, setToken] = useState("");
	const [isChangingMobile, setIsChangingMobile] = useState<boolean>(false);

	const codeRef = useRef("");

	if (!isChangingMobile && (user.isValidMobile || didPasswordUpdate)) {
		return (
			<Confirmed
				handleChangeNumber={() => {
					mobileRef.current = "";
					setIsChangingMobile(true);
				}}
			/>
		);
	}

	if (!isInputMobileClicked || isChangingMobile) {
		return (
			<CheckMobile
				setIsInputMobileClicked={setIsInputMobileClicked}
				mobileRef={mobileRef}
				setToken={setToken}
			/>
		);
	}

	if (!didPasswordUpdate) {
		return (
			<CheckCode
				setDidPasswordUpdate={setDidPasswordUpdate}
				mobileRef={mobileRef || ""}
				codeRef={codeRef}
				token={token}
				setToken={setToken}
			/>
		);
	}

	return (
		<h2 className={genStyles.successMsg}>
			<FormattedMessage id="refreshPassword" />
		</h2>
	);
};
