import * as React from "react";
import {
	IMultipleSelectHierarchy,
	IMultipleSelectItem,
} from "./widgets/deep-multiple-select";
import { DeepMultipleSelectWithSearch } from "./widgets/deep-multiple-select-wth-search";

const h: IMultipleSelectHierarchy<string> = {
	parentInfo: {
		a: "e",
		e: "d",
		b: "d",
		f: "c",
	},
	childrenInfo: {
		e: ["a"],
		d: ["b", "e"],
		c: ["f"],
	},
};

const items: IMultipleSelectItem<string>[] = [
	{
		id: "a",
		name:
			"aaaa c ddf dfs dsf dfs  sdf fds dsf dfs sdf  dsf fds dsf dfs dfs  fds fds fds fds fds",
	},
	{
		id: "b",
		name:
			"bbbb dfsf dsfhdfs fdsgf sdjfdg fg fudsg jfdfds gdfsj fsdf sdfjdsfdfsg sdfjgf",
	},
	{ id: "c", name: "cccc sda ads ads dsa das" },
	{ id: "d", name: "dddd asd dsa das dsa" },
	{ id: "e", name: "eeee saduihudsha dsam" },
	{ id: "f", name: "me var f" },
	{ id: "q", name: "rgregsdgesdgtdsgsdg" },
	{ id: "w", name: "drgtgftwerfes" },
	{ id: "e", name: "erw3e243w" },
	{ id: "r", name: "dsfewa" },
	{ id: "t", name: "yuiuilokik" },
	{ id: "y", name: "bcvbcbvcbcbc" },
	{ id: "u", name: "cxvcvcvcvbvnbvn" },
	{ id: "i", name: "bnbnvghf" },
	{ id: "o", name: "ioiouukujikjkj" },
	{ id: "p", name: "klioioyuiyuuyut" },
	{ id: "s", name: "ioyutyr5ewedwqw" },
	{ id: "g", name: "sawdqwsqwzwaaxxxxxxxxxx" },
	{ id: "h", name: "qqqqqqqqqqqqqqqqqqqqq" },
	{ id: "j", name: "sssssssssssssd ds  sdsdsdsdsdsdsdsd  sx" },
	{ id: "k", name: "qqqqqqqqqqqqqqqqxxxxxxxxxxxx fdg df fdc ds df" },
	{ id: "l", name: "rytu tyh rd dfr rdgf " },
	{ id: "z", name: "e rw rw r er f v d d" },
	{ id: "x", name: "f drter ewrweredc" },
	{ id: "v", name: "dfg rfgredgesferrft" },
	{ id: "n", name: "redsr434sfrs" },
	{ id: "m", name: "dfgrtfyr yr r" },
];

export const MC = props => (
	<div>
		<div style={{ background: "#5c5de8", padding: 25 }}>
			<div style={{ width: 800, maxWidth: "100%", margin: "0 auto" }}>
				<DeepMultipleSelectWithSearch items={items} hierarchyInfo={h} />
			</div>
			{/* <br />
			<br />
			<br />
			<br />
			<br />
			<div style={{ width: 800, margin: "20px auto" }}>
				<DeepMultipleSelectWithSearch items={items} hierarchy={h} />
			</div> */}
		</div>
		{/* 
		<p>dffd</p>
		<p>dffd</p>
		<p>dffd</p>
		<p>dffd</p>
		<p>dffd</p>
		<p>dffd</p>
		<p>dffd</p> */}
	</div>
);
