import { IFeedback } from "@app/api/feedback/helper-schemas";
import {
	FeedbackType,
	FeedbackSingleInterval,
} from "@app/api/feedback/helper-schemas";

export const getFeedBackForTest = (
	feedBackInfo: IFeedback,
	totalCredit: number,
	maxCredit: number
): string => {
	if (feedBackInfo.info.type === FeedbackType.static) {
		return feedBackInfo.info.text;
	}
	const normalizedScore = Math.floor((totalCredit / maxCredit) * 100) / 100;
	const exact = getExactFeedBack(
		feedBackInfo.info.intervals,
		normalizedScore
	);
	return exact
		? exact
		: getApproximateFeedBack(feedBackInfo.info.intervals, normalizedScore);
};

const getExactFeedBack = (
	intervals: FeedbackSingleInterval[],
	normalizedScore: number
): string | undefined => {
	for (const interval of intervals) {
		const { from, leftInclusive, rightInclusive, text, to } = interval;
		const passesLeftBorder = leftInclusive
			? from <= normalizedScore
			: from < normalizedScore;
		const insideRigthBorder = rightInclusive
			? normalizedScore <= to
			: normalizedScore < to;
		if (passesLeftBorder && insideRigthBorder) return text;
	}
	return;
};

const getApproximateFeedBack = (
	intervals: FeedbackSingleInterval[],
	normalizedScore: number
): string => {
	let closestIndex = 0;
	let closestOffset = normalizedScore;
	console.log(normalizedScore);
	const roundDownTwo = x => Math.floor(x * 100) / 100;
	for (let i = 0; i < intervals.length; i++) {
		const { from, leftInclusive, rightInclusive, text, to } = intervals[i];
		if (from >= normalizedScore) {
			if (closestOffset > from - normalizedScore) {
				console.log("asdsd", from);
				closestOffset = roundDownTwo(from - normalizedScore);
				closestIndex = i;
			}
		} else if (to >= normalizedScore) {
			if (closestOffset > to - normalizedScore) {
				closestOffset = roundDownTwo(to - normalizedScore);
				closestIndex = i;
			}
		} else {
			if (closestOffset > normalizedScore - to) {
				closestOffset = roundDownTwo(normalizedScore - to);
				closestIndex = i;
			}
		}
		console.log(closestOffset);
	}
	return intervals[closestIndex].text;
};
