import { GeneralContext } from "@app/components/general-context";
import HeaderStyles from "@app/components/users/header/styles/header.module.css";
import { ReactComponent as GraduationSVG } from "@app/components/users/header/styles/img/graduation.svg";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { useBoolean } from "@app/hooks/general";
import { useClassroomsUser, useManyUsersShortInfo } from "@app/hooks/users";
import { UserInfo } from "@app/models/user-info";
import { parentLinks } from "@app/routes/parent/links";
import {
	setCurrentChild,
	unsetCurrentChild,
} from "@app/user/children/set-current";
import { CreateCusto } from "custo";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGoToUrl } from "../../hooks/front";
import { useHeaderOptions } from "../layouts/header/options";
import { HeaderProviders, HeaderTree } from "../layouts/header/tree";

export const ParentHeader: React.FC<any> = props => {
	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				components: {
					NextToLogo: TitleNextToLogo,
				},
				data: {
					profileImg: "/imgs/ParentAvatar.svg",
					menuOptions: CreateCusto.hookOf.Data(useHeaderOptions),
				},
			}}
		>
			<HeaderTree.components.OuterContainer />
		</HeaderProviders.PartialMergingProvider>
	);
};

const TitleNextToLogo: React.FC = props => {
	const [childId, setChildId] = useState<number | undefined>();
	const {
		value: displayPopup,
		switchValue: switchDisplayStatus,
	} = useBoolean(false);

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const user = useClassroomsUser();

	const confirmedChildren = user?.getConfirmedChildrenIds();

	const path = useLocation().pathname;

	useEffect(() => {
		const key = "children";
		let left = path.indexOf(key);
		if (left < 0) setChildId(undefined);
		left = left + key.length + 1;
		const right = path.indexOf("/", left);
		if (right > 0) {
			setChildId(+path.substring(left, right));
		} else {
			setChildId(+path.substring(left));
		}
	}, [path]);

	const childrenInfo: UserInfo[] | undefined = useManyUsersShortInfo(
		confirmedChildren || null
	).doc;

	const moreThanOneChild = !!(childrenInfo && childrenInfo.length > 1);

	const childInfo =
		childrenInfo && childrenInfo.find(el => el.id === childId);

	if (isHeaderHidden) {
		return null;
	}

	return (
		<div>
			{!!childId && !!childInfo && moreThanOneChild && (
				<button
					className={HeaderStyles.childSwitchButton}
					onClick={switchDisplayStatus}
				>
					{!!childId && !!childInfo && childInfo.getFullName()}
				</button>
			)}
			{displayPopup && !!childId && !!childrenInfo && (
				<SwitchChildPopup
					onClose={switchDisplayStatus}
					childrenInfo={childrenInfo}
					currentChild={childId}
				/>
			)}
		</div>
	);
};

const SwitchChildPopup: React.FC<{
	onClose: () => void;
	childrenInfo: UserInfo[];
	currentChild: number;
}> = ({ onClose, childrenInfo, currentChild }) => {
	const [childId, setChildId] = useState<number>(currentChild);
	const switchChild = useCallback(
		(id: number) => () => {
			setChildId(id);
		},
		[]
	);
	const gotoChildPage = useGoToUrl(parentLinks.childMainPage({ childId }));
	const onConfirm = useCallback(() => {
		unsetCurrentChild();
		setCurrentChild(childId);
		gotoChildPage();
		onClose();
	}, [childId, gotoChildPage, onClose]);

	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose
				onClose={onClose}
				className={HeaderStyles.popup}
			>
				<h1>აირჩიეთ პროფილი</h1>
				<div className={HeaderStyles.childrenContainer}>
					{childrenInfo.map(el => (
						<div
							key={el.id}
							className={HeaderStyles.childDiv}
							style={
								el.id === childId
									? { border: "1px solid #626A84" }
									: {}
							}
							onClick={switchChild(el.id)}
						>
							<GraduationSVG className={HeaderStyles.icon} />
							<span>{el.getFullName()}</span>
						</div>
					))}
					<button onClick={onConfirm}>პროფილის შეცვლა</button>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
};
