export class ValueHistory<T> {
	private history: { id: string; value: T }[];
	private counter = 0;
	constructor(initialValue?: T) {
		if (initialValue !== undefined) {
			this.history = [
				{ id: this.generateUniqueId(), value: initialValue },
			];
		} else {
			this.history = [];
		}
	}

	private generateUniqueId(): string {
		this.counter++;
		return this.counter + "-" + Math.random();
	}

	push(value: T): string {
		const id = this.generateUniqueId();
		this.history.push({ id, value });
		return id;
	}

	getValue(id: string): T | null {
		const el = this.history.find(e => e.id === id);
		if (el) return el.value;
		return null;
	}

	getLatestValue(): T | null {
		if (this.history.length === 0) return null;
		return this.history[this.history.length - 1]!.value;
	}

	delete(id: string): T | null {
		const el = this.getValue(id);
		this.history = this.history.filter(e => e.id !== id);
		return el;
	}
}
