import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	StatementSchema,
	ICommonQuestionParts,
	IStatement,
} from "../common-schemas";
import { markKeysForbidden } from "../../../../schemas/helper";

export enum RelationType {
	OneToOne = 0,
	ManyToOne = 1,
	ManyToMany = 2,
}

export const RelationTypes = [
	RelationType.OneToOne,
	RelationType.ManyToOne,
	RelationType.ManyToMany,
];

export const GroupingItemsContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.GroupingItems)
		.required(),
	statement: StatementSchema,
	groups: Joi.array()
		.items(StatementSchema)
		.required(),
	items: Joi.array()
		.items(StatementSchema)
		.required(),
	itemsToGroups: Joi.array()
		.items(
			Joi.object({
				itemId: Joi.number().required(),
				groupId: Joi.number().required(),
			})
		)
		.required(),
	relationType: Joi.number()
		.integer()
		.valid(...RelationTypes)
		.required(),
});
export interface IGroupingItemsContent extends ICommonQuestionParts {
	type: ContentType.GroupingItems;
	statement?: IStatement;
	groups: IStatement[];
	items: IStatement[];
	itemsToGroups: {
		itemId: number;
		groupId: number;
	}[];
	relationType: RelationType;
}

export const RGroupingItemsContentSchema = GroupingItemsContentSchema.fork(
	["explanation", "itemsToGroups"],
	markKeysForbidden
);
export type IRGroupingItemsContent = Omit<
	IGroupingItemsContent,
	"explanation" | "itemsToGroups"
>;

///
export const GroupingItemsUserAnsSchema = Joi.array().items(
	Joi.object({
		itemId: Joi.number().required(),
		groupId: Joi.number().required(),
	})
);
export type IGroupingItemsUserAns = {
	itemId: number;
	groupId: number;
}[];
