import {
	UserFolderProgressSchema,
	IUserFolderProgress,
	ItemType,
} from "@app/api/folders/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
	IStorage,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage, JoiMetaInfo } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { ObjectId } from "@app/utils/generics";
import Joi from "@tests-core/utils/joi";
import { removeKeys } from "@app/utils/common";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IUserFolderProgress;
export type IStateUserFolderProgresses = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(UserFolderProgressSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("userFolderProgress");
const storageSettings = getDefaultStorageSettings("userFolderProgresses");
const metaInformationName = "userFolderProgressesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

// ============META INFO============

interface MainArgs {
	coursesUserId: number;
	folderId: ObjectId;
	courseId: ObjectId;
}

class UserFolderProgressMetaInfo extends JoiMetaInfo<IUFMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super(emptyMetaData, storage, storageKey, UFMetaInfoSchema);
	}

	clear() {
		this.replaceData(emptyMetaData);
	}

	getKey = ({ coursesUserId, folderId, courseId }: MainArgs): string => {
		return `${coursesUserId}-${courseId}-${folderId}`;
	};

	markNotFound = ({ coursesUserId, folderId, courseId }: MainArgs) => {
		const key = this.getKey({ coursesUserId, folderId, courseId });
		this.setItem("notFoundFolders", {
			...this.data.notFoundFolders,
			[key]: 1,
		});
	};

	markMany = ({
		coursesUserId,
		notFoundFolderIds,
		courseId,
		foundFolderIds,
	}: {
		coursesUserId: number;
		notFoundFolderIds?: Iterable<ObjectId>;
		courseId: ObjectId;
		foundFolderIds?: Iterable<ObjectId>;
	}) => {
		let hasBeenAffected = false;
		const newNotFounds = { ...this.data.notFoundFolders };
		if (notFoundFolderIds) {
			for (const folderId of notFoundFolderIds) {
				const key = this.getKey({ coursesUserId, folderId, courseId });
				if (newNotFounds[key] !== 1) {
					hasBeenAffected = true;
				}
				newNotFounds[key] = 1;
			}
		}
		if (foundFolderIds) {
			for (const folderId of foundFolderIds) {
				const key = this.getKey({ coursesUserId, folderId, courseId });
				if (newNotFounds[key]) {
					hasBeenAffected = true;
				}
				delete newNotFounds[key];
			}
		}
		if (!hasBeenAffected) return;
		this.setItem("notFoundFolders", newNotFounds);
	};

	markFound = ({ coursesUserId, folderId, courseId }: MainArgs) => {
		const key = this.getKey({ coursesUserId, folderId, courseId });
		this.setItem(
			"notFoundFolders",
			removeKeys(this.data.notFoundFolders, key)
		);
	};

	markManyFound = (args: MainArgs[]) => {
		let hasBeenAffected = false;
		const newNotFounds = { ...this.data.notFoundFolders };
		for (const { coursesUserId, folderId, courseId } of args) {
			const key = this.getKey({ coursesUserId, folderId, courseId });
			if (newNotFounds[key]) {
				hasBeenAffected = true;
			}
			delete newNotFounds[key];
		}
		if (!hasBeenAffected) return;
		this.setItem("notFoundFolders", newNotFounds);
	};

	cannotBeFound = ({
		coursesUserId,
		folderId,
		courseId,
	}: MainArgs): boolean => {
		const key = this.getKey({ coursesUserId, folderId, courseId });
		return this.data.notFoundFolders[key] === 1;
	};
}

const UFMetaInfoSchema = Joi.object({
	notFoundFolders: Joi.object()
		.pattern(/[\da-f-]+/, Joi.any().valid(1))
		.required(),
});

interface IUFMetaInfo {
	notFoundFolders: Record<string, 1 | undefined>;
}
const emptyMetaData: IUFMetaInfo = {
	notFoundFolders: {},
};

// ============BASE MODEL============

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().userFolderProgresses) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				UserFolderProgressSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
	indices: [{ fields: ["courseId", "folderId", "userId"], unique: true }],
});

// ==============Main Model=================

export class UserFolderProgress extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findUserDocSync(args: {
		userId: number;
		courseId: ObjectId;
		folderId: ObjectId;
	}): UserFolderProgress | undefined {
		const doc = this.findOneSync({
			userId: args.userId,
			courseId: args.courseId,
			folderId: args.folderId,
		});
		if (
			!doc ||
			doc.courseId !== args.courseId ||
			doc.userId !== args.userId
		) {
			return undefined;
		}
		return doc;
	}

	static findUserDocsByFoldersSync(args: {
		userId: number;
		courseId: ObjectId;
		folderIds: ObjectId[];
	}): UserFolderProgress[] {
		const docs: UserFolderProgress[] = [];
		for (const folderId of args.folderIds) {
			const doc = this.findOneSync({
				userId: args.userId,
				courseId: args.courseId,
				folderId,
			});
			if (doc) {
				docs.push(doc);
			}
		}
		return docs;
	}

	findItemProgress({
		itemId,
		itemType,
	}: {
		itemId: ObjectId;
		itemType: ItemType;
	}) {
		return (
			this.itemsProgress.find(
				e => e.id === itemId && e.type === itemType
			) || null
		);
	}

	static findItemProgress({
		userId,
		courseId,
		parentFolderId,
		itemId,
		itemType,
	}: {
		userId: number;
		courseId: ObjectId;
		parentFolderId: ObjectId;
		itemId: ObjectId;
		itemType: ItemType;
	}) {
		const folderProgress = this.findUserDocSync({
			userId,
			courseId,
			folderId: parentFolderId,
		});
		if (!folderProgress) return null;
		return folderProgress.findItemProgress({ itemId, itemType });
	}

	static async polluteForUserSync(args: {
		userId: number;
		courseId: ObjectId;
		folderIds: ObjectId[];
	}) {
		const docs = this.findUserDocsByFoldersSync(args);
		for (const doc of docs) {
			this.updateOneSync(
				{
					_id: doc._id,
				},
				{
					needsRecalculation: true,
				}
			);
		}
	}

	static async polluteForAllUsersSync(args: {
		courseId: ObjectId;
		folderIds: ObjectId[];
	}) {
		for (const folderId of args.folderIds) {
			const docs = this.findManySync({
				courseId: args.courseId,
				folderId,
			});
			for (const doc of docs) {
				doc.needsRecalculation = true;
				doc.saveSync();
			}
		}
	}

	static async removeItemsFromFoldersSync(args: {
		courseId: ObjectId;
		folderId: ObjectId;
		itemId: ObjectId;
		itemType: ItemType;
	}) {
		const docs = this.findManySync({
			courseId: args.courseId,
			folderId: args.folderId,
		});
		for (const doc of docs) {
			const index = doc.itemsProgress.findIndex(
				item => item.id === args.itemId && item.type === args.itemType
			);
			if (index > -1) {
				doc.itemsProgress = doc.itemsProgress.filter(
					item =>
						item.id !== args.itemId || item.type !== args.itemType
				);
				doc.needsRecalculation = true;
				doc.saveSync();
			}
		}
	}

	static subscribeUserDocChange(
		args: {
			userId: number;
			courseId: ObjectId;
			folderId: ObjectId;
		},
		cb: (doc: UserFolderProgress | undefined) => void
	): () => void {
		let lastDoc = this.findOneSync({
			userId: args.userId,
			courseId: args.courseId,
			folderId: args.folderId,
		});
		return this.subscribeChange(() => {
			const currentDoc = this.findOneSync({
				userId: args.userId,
				courseId: args.courseId,
				folderId: args.folderId,
			});
			if (currentDoc === lastDoc) return;
			lastDoc = currentDoc;
			cb(this.findUserDocSync(args));
		});
	}

	static synchronizeNotFoundFoldersWithMetadata() {
		return this.subscribeChange(() => {
			const docs = this.getAllSync("raw");
			this.meta.markManyFound(
				docs.map(
					(doc): MainArgs => ({
						coursesUserId: doc.userId,
						courseId: doc.courseId,
						folderId: doc.folderId,
					})
				)
			);
		});
	}

	static meta = new UserFolderProgressMetaInfo(storage, metaInformationName);
}

export type IUserFolderProgressInstance = UserFolderProgress;
export type IUserFolderProgressModel = typeof UserFolderProgress;

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	UserFolderProgress.meta
);

export const userFolderProgressesReducer = getDefaultReducer(
	storageSettings,
	() => UserFolderProgress
);
