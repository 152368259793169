import { Location } from "history";
import * as queryString from "query-string";

export function extractHostname(url: string): string {
	let hostname: string;
	//find & remove protocol (http, ftp, etc.) and get hostname

	if (url.indexOf("//") > -1) {
		hostname = url.split("/")[2];
	} else {
		hostname = url.split("/")[0];
	}

	//find & remove port number
	hostname = hostname.split(":")[0];
	//find & remove "?"
	hostname = hostname.split("?")[0];

	return hostname;
}

(window as any).extractHostname = extractHostname;

export function extractRootDomain(url: string): string {
	let domain = extractHostname(url);
	const splitArr = domain.split(".");
	const arrLen = splitArr.length;

	//extracting the root domain here
	//if there is a subdomain
	if (arrLen > 2) {
		domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
		//check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
		if (
			splitArr[arrLen - 2].length === 2 &&
			splitArr[arrLen - 1].length === 2
		) {
			//this is using a ccTLD
			domain = splitArr[arrLen - 3] + "." + domain;
		}
	}
	return domain;
}

(window as any).extractRootDomain = extractRootDomain;

export const getReplacedQuery = (key: string, value: any) => {
	const query = queryString.parse(window.location.search);
	return queryString.stringify({ ...query, [key]: value });
};

export const getReplacedQueryLocation = (
	location: Pick<Location, "search" | "pathname">,
	key: string,
	value: any
) => {
	const query = queryString.parse(location.search);
	const queryStr = queryString.stringify({ ...query, [key]: value });
	if (!queryStr) return location.pathname;
	return location.pathname + "?" + queryStr;
};
