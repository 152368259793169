import Joi from "@app/utils/joi";
import { CardSchema, ICard } from "@tests-core/schemas/cards";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateCardSchema = Joi.object({
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	card: CardSchema.keys(insertStripKeys).required(),
});

export interface IAPOSTCreateCard {
	folderId: ObjectId;
	courseId: ObjectId;
	card: ToInsertKeys<ICard>;
}

export const RPOSTCreateCardSchema = CardSchema.required();
export type IRPOSTCreateCard = ICard;

///

export const APUTCardSchema = CardSchema.keys(updateStripKeys).keys({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
});
export type IAPUTCard = ToUpdateKeys<ICard> & {
	courseId: ObjectId;
	folderId: ObjectId;
};

///

export const AGETCardSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETCard {
	_id: ObjectId;
	courseId: ObjectId;
}

export const RGETCardSchema = CardSchema;
export type IRGETCard = ICard;

///

export const ADELETECardSchema = Joi.object({
	_id: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IADELETECard {
	_id: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
}

///

export const AGETManyByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETManyByIds {
	ids: ObjectId[];
	courseId: ObjectId;
}

export const RGETManyByIdsSchema = Joi.array().items(CardSchema);
export type IRGETManyByIds = ICard[];

///

export const AGETNamesByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETNamesByIds {
	ids: ObjectId[];
	courseId: ObjectId;
}

export const RGETNamesByIdsSchema = Joi.array().items(
	Joi.object({
		id: Joi.objectId().required(),
		name: Joi.string().required(),
	})
);
export type IRGETNamesByIds = {
	id: ObjectId;
	name: string;
}[];
