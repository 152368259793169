import {
	ISchoolLessonSchedule,
	SchoolLessonScheduleSchema,
	ILessonTime,
} from "@app/api/schools/lesson-schedules/helper-schemas";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ISchoolLessonSchedule;
export type IStateSchoolLessonSchedules = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(SchoolLessonScheduleSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("schoolLessonSchedule");
const storageSettings = getDefaultStorageSettings("schoolLessonSchedules");
const metaInformationName = "schoolLessonSchedulesMetaInformation";

const MAX_LOAD_TIME_DIFF = 5 * 60 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	indices: [{ fields: ["schoolId"], unique: true }],
	getInstances: (() => store.getState().schoolLessonSchedules) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"number",
				SchoolLessonScheduleSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class SchoolLessonSchedule extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);

	static findBySchool(schoolId: number) {
		return this.findOneSync({ schoolId });
	}

	getHighestOrdinal = () => {
		return Math.max(...this.times.map(e => e.ordinal));
	};

	searchTime(query: {
		ordinal: number;
		grade: number;
		schoolLabelId: ObjectId | undefined;
	}) {
		return this.times.find(time => {
			if (time.ordinal !== query.ordinal) return false;
			return areMatching(time, query);
		});
	}

	getTimesForGrade(query: {
		grade: number;
		schoolLabelId: ObjectId | undefined;
	}) {
		return this.times.filter(time => areMatching(time, query));
	}
}

/// utils

function areMatching(
	time: ILessonTime,
	{
		grade,
		schoolLabelId,
	}: {
		grade: number;
		schoolLabelId: ObjectId | undefined;
	}
) {
	if (time.grades && !time.grades.includes(grade)) {
		return false;
	}
	if (
		time.schoolLabelIds &&
		(!schoolLabelId || !time.schoolLabelIds.includes(schoolLabelId))
	) {
		return false;
	}
	return true;
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	SchoolLessonSchedule.meta
);

export const schoolLessonSchedulesReducer = getDefaultReducer(
	storageSettings,
	() => SchoolLessonSchedule
);
