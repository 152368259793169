import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import React, { useRef } from "react";
import { kingsSummonsActionName, TeacherKingsOffer } from ".";

export const TeacherKingsOfferWrapper = React.memo<{
	displayEvenIfHasSeen: boolean;
}>(function TeacherKingsOfferWrapper({ displayEvenIfHasSeen }) {
	const displayEvenIfHasSeenRef = useRef(displayEvenIfHasSeen);
	const { value: isManuallyClosed, setTrue: closeManyally } = useBoolean(
		false
	);

	const userActions = useUserActions();
	const hasSeenBefore = !!userActions.doc?.actions[kingsSummonsActionName];
	const hasApprovedBefore = !!userActions.doc?.actions[kingsSummonsActionName]
		?.approved;

	const enabled =
		userActions.isSuccessfullyLoaded &&
		!isManuallyClosed &&
		(displayEvenIfHasSeenRef.current || !hasSeenBefore);

	return (
		<DefaultPopupPrioritizer priority={60} enabled={enabled}>
			<TeacherKingsOffer
				onClose={closeManyally}
				hasApprovedBefore={hasApprovedBefore}
			/>
		</DefaultPopupPrioritizer>
	);
});
