import { ObjectId } from "@app/utils/generics";

type Icon = (className?: string) => JSX.Element;
export interface ISidebarItem {
	name: string;
	href: string;
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export interface ISideBarProps {
	whazz: SidebarWhazz;
}

export enum SidebarType {
	classroom,
	group,
	other,
}

export type SidebarWhazz =
	| { type: SidebarType.classroom; classroomId: ObjectId }
	| { type: SidebarType.group; groupId: ObjectId }
	| ({ type: SidebarType.other } & Record<any, any>);
