import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const BlogSchema = Joi.object({
	_id: Joi.objectId().required(),
	title: Joi.string().required(),
	mainText: Joi.string().required(),
	author: Joi.number().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
	isPublic: Joi.boolean().required(),
	photoAddress: Joi.string().required(),
});

export interface IBlog {
	_id: ObjectId;
	title: string;
	mainText: string;
	author: number;
	createdAt: Date;
	updatedAt: Date;
	isPublic: boolean;
	photoAddress: string;
}
