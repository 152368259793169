import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef,
} from "react";
import { inject } from "@app/modules";
import {
	ICertificateCandidateStudent,
	CertificateCandidateStudentType,
	IRGETCertificateCandidateStudents,
	IAPUTCertificateCandidateStudentsStatus,
} from "@app/api/certificates/validators";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import styles from "./styles/index.module.scss";
import listStyles from "./styles/student-list.module.css";
import { romanize } from "@app/utils/common";
import RollInNotification from "./roll-in-notification";
import { FormattedMessage } from "react-intl";
import CopyIcon from "../../assignments-2/img/copy-white.svg";
import AttentionIcon from "../../assignments-2/img/speaker.svg";
import classnames from "classnames";
import { copyTextToClipboard } from "@app/utils/clipboard";
import { IClassroomEnrollmentCode } from "@app/api/classrooms/enrollments/helper-schemas";
import { parseUrl } from "@app/utils/common";
import { useWindowSize } from "@app/hooks/front";
import {
	getFilteredStudents,
	getCertificateCandidateStatus,
	getAllSentCandidates,
	hasSendCandidate,
	getUnregistredCount,
	getTabName,
} from "./helper-functions";
import { DeleteIcon, EyeIcon, EditOutlinedIcon } from "@app/icons";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import Loading from "@app/components/widgets/raw-loading";
import { addLoader } from "@app/common-javascript";
import { useBoolean } from "@app/hooks/general";

type CandidatesListStateList = IRGETCertificateCandidateStudents;

export enum FilterStudentCandidatesTypes {
	all = "all",
	readyCandidates = "1",
	notRegistered = "0",
	sent = "3",
}

export const CertificateCandidatesListUpdated: React.FC = props => {
	const [candidates, setCandidates] = useState<CandidatesListStateList>();
	const [notification, setNotification] = useState<{
		isVisable: boolean;
		title: string;
		text: string;
	}>({ isVisable: false, text: "", title: "" });
	const [code, setCode] = useState<IClassroomEnrollmentCode>();
	const [tab, setTab] = useState<FilterStudentCandidatesTypes>(
		FilterStudentCandidatesTypes.all
	);

	const [popup, setPopup] = useState<{
		preview: {
			isOpen: boolean;
			link: string;
			studentId: number | null;
		};
		sendPreviewIsOpen: boolean;
	}>({
		sendPreviewIsOpen: false,
		preview: { isOpen: false, link: "", studentId: null },
	});

	const updateStudentCertificateStatus = useCallback(
		(args: IAPUTCertificateCandidateStudentsStatus) => {
			if (!args.length) return;
			if (!candidates) return;
			const updateStatus = async () => {
				const updatedCandidates = await inject(
					"CertificatesController"
				).updateStudentCandidateStatus(args);
				setCandidates(updatedCandidates);
				if (args[0].approved) {
					setNotification({
						isVisable: true,
						title: `სერტიფიკატი გაგზავნილია ${args.length} მოსწავლესთან`,
						text: "",
					});
				}
			};
			updateStatus().catch(e => {
				console.log(e);
				openConfirmationPopup({
					text: "დაფიქსირდა შეცდომა, ცადეთ თავიდან",
				});
			});
		},
		[candidates]
	);

	useEffect(() => {
		const loadData = async () => {
			const currentCandidates = await inject(
				"CertificatesController"
			).getStudentCanditates({});

			await inject("ClassroomEnrollmentCodesController")
				.create()
				.then(e => setCode(e));

			setTab(
				hasSendCandidate(currentCandidates)
					? FilterStudentCandidatesTypes.readyCandidates
					: FilterStudentCandidatesTypes.notRegistered
			);
			setCandidates(currentCandidates);
		};

		loadData().catch(e => {
			console.log(e);
			openConfirmationPopup({
				text: getFormattedMessage("errorAlert"),
			});
		});
	}, []);

	const handleremoveFromDeletedList = useCallback((studentId: number) => {
		inject("CertificatesController")
			.deleteCandidateDecision({ studentId })
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			)
			.then(e => setCandidates(e as any));
	}, []);

	const handleSendCertificates = useCallback(() => {
		if (!candidates) return;
		setPopup({ ...popup, sendPreviewIsOpen: false });
		updateStudentCertificateStatus(getAllSentCandidates(candidates));
	}, [candidates, popup, updateStudentCertificateStatus]);

	const handleDeleteCandidateDecision = useCallback(
		(studentId: number) => {
			const deleteStudent = () =>
				updateStudentCertificateStatus([
					{ approved: false, studentId },
				]);

			openConfirmationPopup({
				onApprove: deleteStudent,
				approveTitle: "წაშლა",
				text: "ნამდვილად გსურთ მოსწავლის წაშლა?",
				rejectTitle: "გაუქმება",
			});
		},
		[updateStudentCertificateStatus]
	);

	const handlePreviewCertificate = useCallback(
		(studentId: number, fullname?: string) => {
			return inject("CertificatesController")
				.previewForTeacher({ studentId, fullname })
				.then(e =>
					setPopup({
						...popup,
						preview: {
							isOpen: true,
							link: e.certificatePath,
							studentId,
						},
					})
				)
				.catch(e =>
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					})
				);
		},
		[popup]
	);

	const openSendPopup = useCallback(() => {
		setPopup({ ...popup, sendPreviewIsOpen: true });
	}, [popup]);

	const tabs = useMemo(
		(): TabValues => [
			{
				label: "მზა სერტიფიკატები",
				value: FilterStudentCandidatesTypes.readyCandidates,
			},
			{
				label: "გაგზავნილები",
				value: FilterStudentCandidatesTypes.sent,
			},
			{
				label: "არარეგისტრირებულები",
				value: FilterStudentCandidatesTypes.notRegistered,
			},
			{ label: "ყველა", value: FilterStudentCandidatesTypes.all },
		],
		[]
	);

	const generateCandidatesList = useMemo(() => {
		if (!candidates) return null;
		const grades = Object.keys(candidates);
		return grades.map((e, i) => {
			if (!candidates[e]) return null;
			const students = getFilteredStudents(
				tab,
				candidates[e] as ICertificateCandidateStudent[]
			);
			if (
				students.registered.length === 0 &&
				students.unregistred.length === 0
			) {
				return null;
			}
			let globalIndex = 0;

			return (
				<div key={i}>
					<h3 className={listStyles.filtered_students__grade}>
						{`${romanize(Number(e))} კლასი`}
					</h3>

					{students.registered.length !== 0 && (
						<div
							className={listStyles.registered_students_container}
						>
							{students.registered.map(e => {
								globalIndex++;
								return (
									<div
										key={globalIndex}
										className={
											listStyles.student_box_w_icon_container
										}
									>
										<RowWrapper
											type={e.type}
											tab={tab}
											isRegistred
											onShow={handlePreviewCertificate}
											onDelete={
												handleremoveFromDeletedList
											}
											studentId={e.user.id}
											name={
												e.user.firstname +
												" " +
												e.user.lastname
											}
											index={globalIndex}
										/>
										{tab ===
											FilterStudentCandidatesTypes.readyCandidates && (
											<DeleteIcon
												stroke="#ff8ea4"
												onClick={() =>
													handleDeleteCandidateDecision(
														e.user.id
													)
												}
												width={25}
												className={listStyles.eye_icon}
											/>
										)}
									</div>
								);
							})}
						</div>
					)}
					{students.unregistred.length !== 0 && (
						<div
							className={
								listStyles.not_registered_students_container
							}
						>
							{students.unregistred.map(e => {
								globalIndex++;
								return (
									<RowWrapper
										tab={tab}
										isRegistred={false}
										key={globalIndex}
										index={globalIndex}
										{...e}
									/>
								);
							})}
						</div>
					)}
				</div>
			);
		});
	}, [
		candidates,
		handleDeleteCandidateDecision,
		handlePreviewCertificate,
		handleremoveFromDeletedList,
		tab,
	]);

	const getEmptyMessage = useMemo(() => {
		switch (tab) {
			case FilterStudentCandidatesTypes.all:
				return "მოსწავლეთა სია ცარიელია";
			case FilterStudentCandidatesTypes.sent:
				return " თქვენ ჯერ არ გაგიგზავნიათ სერტიფიკატები";
			case FilterStudentCandidatesTypes.notRegistered:
				return "თქვენ არ გყავთ დაურეგისტრირებული მოსწავლეები.";
			case FilterStudentCandidatesTypes.readyCandidates:
				return (
					<React.Fragment>
						{`შეგახსენებთ არარეგისტრირებული მოსწავლეების სიაში კიდევ ${getUnregistredCount(
							candidates
						)} მოსწავლეა! იმისთვის, რომ მოსწავლეებს გაუგზავნოთ სერტიფიკატები საჭიროა ისინი რეგისტრირებული იყვნენ tvschool.ge-ზე.`}
						<br />
						გახსენით სია{" "}
						<span
							style={{
								fontWeight: "bold",
								textDecoration: "underline",
								color: "#5273e6",
								cursor: "pointer",
							}}
							onClick={() =>
								setTab(
									FilterStudentCandidatesTypes.notRegistered
								)
							}
						>
							“არარეგისტრირებულები”
						</span>{" "}
						და გაუგზავნეთ მათ სარეგისტრაციო ლინკი, რათა შეძლოთ
						მათთვის სერტიფიკატების გაგზავნა!
					</React.Fragment>
				);
			default:
				return "";
		}
	}, [candidates, tab]);

	const handleStudentNameChange = useCallback(
		(studentId: number, name: string) => {
			const removeLoader = addLoader();
			return handlePreviewCertificate(studentId, name)
				.then(() => {
					removeLoader();
				})
				.catch(() => {
					removeLoader();
				});
		},
		[handlePreviewCertificate]
	);

	if (!code) return null;

	return (
		<div className={styles.candidatesListContainer}>
			{(popup.preview.isOpen || popup.sendPreviewIsOpen) && (
				<CertificatePreviewPopup
					isSendPreview={popup.sendPreviewIsOpen}
					onNameChange={
						popup.preview.studentId
							? name =>
									handleStudentNameChange(
										popup.preview.studentId!,
										name
									)
							: undefined
					}
					onClose={() =>
						setPopup(
							popup.sendPreviewIsOpen
								? { ...popup, sendPreviewIsOpen: false }
								: {
										...popup,
										preview: {
											isOpen: false,
											link: "",
											studentId: null,
										},
								  }
						)
					}
					onSend={handleSendCertificates}
					link={popup.preview.link}
				/>
			)}
			{(tab === FilterStudentCandidatesTypes.all ||
				tab === FilterStudentCandidatesTypes.notRegistered) && (
				<CopyLink
					code={code.code}
					onCopy={() =>
						setNotification({
							isVisable: true,
							title: "სარეგისტრაციო ლინკი კოპირებულია",
							text:
								"ლინკის გაგზავნის შემდეგ მოსწავლეები დარეგისტრირდებიან პლატფორმაზე და ცვლილება თქვენს გვერდზე ავტომატურად აისახება.",
						})
					}
				/>
			)}
			{tab !== FilterStudentCandidatesTypes.sent && (
				<div className={listStyles.link_section}>
					<div className={listStyles.text_content}>
						<img
							src={AttentionIcon}
							alt=""
							className={listStyles.text_content__img}
						/>
						<div className={listStyles.text_content__text}>
							<div>
								<div className={styles.firstStepText}>
									გაუგზავნეთ ბმული მოსწავლეს, თუ:
								</div>
								<div
									className={styles.firstStepText}
									style={{
										paddingLeft: 12,
									}}
								>
									• არ არის რეგისტრირებული პლატფორმაზე
								</div>
								<div
									className={styles.firstStepText}
									style={{
										paddingLeft: 12,
									}}
								>
									• რეგისტრირებულია, მაგრამ რეგისტრაციის
									შემდეგ თქვენთან არ შეუსრულებია დავალება და,
									შესაბამისად, საკუთარ გვერდზე კლასების
									ჩამონათვალში არ აქვს თქვენი კლასი
								</div>
							</div>
							<div
								className={styles.firstStepText}
								style={{ textAlign: "justify" }}
							>
								{tab ===
								FilterStudentCandidatesTypes.readyCandidates
									? "მზა სერტიფიკატების გაგზავნა უკვე შესაძლებელია ღილაკით “სერტიფიკატების გაგზავნა”"
									: "გთხოვთ, არარეგისტრირებულ მოსწავლეებს გაუგზავნოთ სარეგისტრაციო ბმული, რათა შეძლონ tvschool.ge - ზე რეგისტრაცია."}
							</div>
						</div>
					</div>
				</div>
			)}

			<CustomTabs
				tabs={tabs}
				value={tab}
				onChange={(newFilter: FilterStudentCandidatesTypes) =>
					setTab(newFilter)
				}
				onSendCertificate={openSendPopup}
			/>

			<div className={listStyles.filtered_students}>
				{!generateCandidatesList ||
				generateCandidatesList.filter(e => e !== null).length === 0 ? (
					<h2
						className={listStyles.link_section__title}
						style={{ textAlign: "center", color: "#58607c" }}
					>
						{getEmptyMessage}
					</h2>
				) : (
					generateCandidatesList
				)}
			</div>

			<RollInNotification
				show={notification.isVisable}
				onClose={() =>
					setNotification({ ...notification, isVisable: false })
				}
				{...notification}
			/>
		</div>
	);
};

const CertificatePreviewPopup: React.FC<{
	link: string;
	onClose: () => void;
	onSend: () => void;
	isSendPreview: boolean;
	onNameChange?: (newName: string) => Promise<void>;
}> = React.memo(({ link, onClose, isSendPreview, onSend, onNameChange }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { width, height } = useWindowSize();
	const isMobile = width < 600;

	const isHd = height < 800;

	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose
				onClose={onClose}
				popupBodyClassname={listStyles.popup_body}
			>
				<div
					style={{
						width: isMobile
							? `${(width * 7) / 10}px`
							: `${(width * 3) / 5}px`,
						maxWidth: "555px",
						minWidth: "272px",
						height: "100%",
					}}
				>
					{isLoading && <Loading />}
					<div style={{ display: isLoading ? "none" : undefined }}>
						{onNameChange && <EditName onSubmit={onNameChange} />}
						{isSendPreview && (
							<div
								className={listStyles.popup_text}
								style={{ fontSize: isHd ? "18px" : undefined }}
							>
								თუ უკვე გადახედეთ მზა სერტიფიკატებს და
								დარწმუნდით, რომ მონაცემები სწორია, შეგიძლიათ
								მოსწავლეებს გაუგზავნოთ სერტიფიკატები!
							</div>
						)}
						<div className={listStyles.certificateContainer}>
							<div className={listStyles.certificateBox}>
								<img
									src={
										isSendPreview
											? "imgs/default-student-certificate.png"
											: link
									}
									alt="certificate"
									onLoad={() => setIsLoading(false)}
									className={listStyles.certificate}
									style={{
										width: "100%",
										height: "100%",
									}}
								/>
							</div>
						</div>
						{isSendPreview && (
							<div
								className={
									listStyles.popup_send_button_container
								}
							>
								<div
									className={listStyles.popup_send_button}
									onClick={onSend}
								>
									სერტიფიკატების გაგზავნა
								</div>
							</div>
						)}
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});

type RowWrapperProps = {
	name: string;
	index: number;
	tab: FilterStudentCandidatesTypes;
	type: CertificateCandidateStudentType;
	onShow?: (studenId: number) => void;
	onDelete?: (studenId: number) => void;
} & (
	| {
			isRegistred: true;
			studentId: number;
	  }
	| {
			isRegistred: false;
			studentId?: undefined;
	  }
);

const RowWrapper: React.FC<RowWrapperProps> = React.memo(
	({ isRegistred, name, index, onShow, onDelete, studentId, tab, type }) => {
		const { width } = useWindowSize();

		const getStudentStatus = useCallback(
			(
				studentType: CertificateCandidateStudentType,
				responsive: boolean
			) => getCertificateCandidateStatus(studentType, responsive),
			[]
		);

		const onClick = useCallback(() => {
			if (!studentId) return;
			if (
				type === CertificateCandidateStudentType.deletedFromCandidates
			) {
				onDelete?.(studentId);
			} else if (
				tab === FilterStudentCandidatesTypes.readyCandidates ||
				tab === FilterStudentCandidatesTypes.sent
			) {
				onShow?.(studentId);
			}
		}, [onDelete, onShow, studentId, tab, type]);

		return (
			<div
				className={
					isRegistred
						? listStyles.registered_students_box
						: listStyles.not_registered_students_box
				}
			>
				<div className={listStyles.students_box__student}>
					<span>{index}</span>
					<p>{name}</p>
				</div>
				<div
					className={
						isRegistred
							? tab ===
							  FilterStudentCandidatesTypes.readyCandidates
								? classnames(
										listStyles.students_box_registered,
										listStyles.delete_icon
								  )
								: listStyles.students_box_registered
							: listStyles.students_box_not_registered
					}
					style={
						type ===
							CertificateCandidateStudentType.deletedFromCandidates ||
						tab === FilterStudentCandidatesTypes.readyCandidates
							? { cursor: "pointer" }
							: undefined
					}
					onClick={onClick}
				>
					<p
						style={{
							color:
								tab ===
								FilterStudentCandidatesTypes.readyCandidates
									? " #ef6b85"
									: type ===
											CertificateCandidateStudentType.deletedFromCandidates ||
									  type ===
											CertificateCandidateStudentType.registered
									? "#626a84"
									: undefined,
						}}
					>
						{tab !== FilterStudentCandidatesTypes.readyCandidates &&
							getStudentStatus(type, width <= 500)}
						{(tab ===
							FilterStudentCandidatesTypes.readyCandidates ||
							tab === FilterStudentCandidatesTypes.sent) && (
							<span style={{ cursor: "pointer" }}>
								<EyeIcon
									width={30}
									style={{
										marginLeft:
											tab !==
											FilterStudentCandidatesTypes.readyCandidates
												? 10
												: 0,
									}}
								/>
								<EditOutlinedIcon
									width={20}
									style={{
										marginLeft: 10,
										marginRight: 5,
										color: "#5273e6",
									}}
								/>
							</span>
						)}
					</p>
				</div>
			</div>
		);
	}
);

const CopyLink: React.FC<{ onCopy?: () => void; code: string }> = React.memo(
	({ code, onCopy }) => {
		const { width } = useWindowSize();
		return (
			<div className={listStyles.link_section__container}>
				<h2 className={listStyles.link_section__title}>
					გაუგზავნეთ სარეგისტრაციო ბმული არარეგისტრირებულ მოსწავლეებს
				</h2>

				<div className={listStyles.copyToReg}>
					<div className={listStyles.copyToReg_code}>
						{getFullRegistrationUrl(code)}
					</div>
					<button
						className={listStyles.copyToReg_copy}
						onClick={() => {
							copyTextToClipboard(getFullRegistrationUrl(code));
							onCopy?.();
						}}
					>
						{width <= 700 && (
							<img
								src={CopyIcon}
								alt=""
								className={listStyles.copyToReg_copyIcon}
							/>
						)}

						{width > 700 && (
							<FormattedMessage id="copyLinkForReg" />
						)}
					</button>
				</div>
			</div>
		);
	}
);

type TabValues = {
	label: string;
	value: FilterStudentCandidatesTypes;
}[];

const CustomTabs: React.FC<{
	tabs: TabValues;
	value: FilterStudentCandidatesTypes | null;
	onChange: (newValue: FilterStudentCandidatesTypes) => void;
	onSendCertificate: () => void;
}> = React.memo(({ onChange, tabs, value, onSendCertificate }) => {
	const { width } = useWindowSize();
	return (
		<div className={listStyles.filter_students}>
			<div
				style={{
					display: "flex",
					flexFlow: "inherit",
					justifyContent: "space-around",
				}}
			>
				{tabs.map((e, i) => {
					const isSelected =
						value === null ? i === 0 : value === e.value;
					return (
						<button
							key={i}
							className={
								isSelected
									? classnames(
											listStyles.filter_button,
											listStyles.active
									  )
									: listStyles.filter_button
							}
							onClick={() => onChange(e.value)}
						>
							{getTabName(e.value, width <= 500)}
						</button>
					);
				})}
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{value !== FilterStudentCandidatesTypes.notRegistered &&
					value !== FilterStudentCandidatesTypes.sent && (
						<button
							className={
								value !== FilterStudentCandidatesTypes.all
									? listStyles.send_certficate_button
									: classnames(
											listStyles.send_certficate_button,
											listStyles.on_all_tab
									  )
							}
							onClick={() =>
								value === FilterStudentCandidatesTypes.all
									? onChange(
											FilterStudentCandidatesTypes.readyCandidates
									  )
									: onSendCertificate()
							}
						>
							სერტიფიკატების გაგზავნა
						</button>
					)}
			</div>
		</div>
	);
});

const getFullRegistrationUrl = (code: string) => {
	const { origin } = parseUrl(window.location.href);
	return `${origin}/rs/${code}`;
};

const EditName: React.FC<{
	onSubmit: (name: string) => Promise<void>;
}> = React.memo(({ onSubmit }) => {
	const [version, setVersion] = useState(0);
	const {
		value: isEditing,
		setFalse: stopEditing,
		setTrue: startEditing,
	} = useBoolean();
	const [name, setName] = useState("");
	const nameRef = useRef(name);
	nameRef.current = name;

	const onNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);
		},
		[]
	);

	const handleAccept = useCallback(() => {
		onSubmit(nameRef.current).then(() => {
			stopEditing();
			setVersion(x => x + 1);
		});
	}, [onSubmit, stopEditing]);

	if (!isEditing) {
		return (
			<div>
				{version === 0 &&
					"თუკი მოსწავლის სახელი არასწორადაა სერტიფიკატში მოცემული, დააკლიკეთ სახელის რედაქტირებას და ჩაასწორეთ"}
				{version > 0 &&
					"მოსწავლის სახელი და გვარი წარმატებით შეიცვალა!"}
				<br />
				<button
					className={listStyles.send_certficate_button}
					onClick={startEditing}
					style={{ margin: "10px auto 10px auto" }}
				>
					სახელის რედაქტირება
				</button>
			</div>
		);
	}

	return (
		<div>
			ჩაწერეთ მოსწავლის სახელი და გვარი
			<input
				className={listStyles.input}
				value={name}
				onChange={onNameChange}
				placeholder=""
			/>
			<button
				className={listStyles.nameEditSaveButton}
				onClick={handleAccept}
			>
				დასტური
			</button>
		</div>
	);
});
