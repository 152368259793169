import { useCallback } from "react";

export const useInputChangeHandler = (fn: (text: string) => void) => {
	return useCallback(
		(e: { target: { value: string } }) => {
			fn(e.target.value);
		},
		[fn]
	);
};

export const useNumberInputChangeHandler = (fn: (value: number) => void) => {
	return useCallback(
		(e: { target: { value: string } }) => {
			fn(+e.target.value);
		},
		[fn]
	);
};
