import { AudienceLogicType } from "@app/api/audiences/helper-schemas";
import { SummonsInfoType } from "@app/api/summonses/helper-schemas";
import { FancyPopup } from "@app/components/widgets/popup";
import { useClassroomsUserId } from "@app/hooks/users";
import { inject } from "@app/modules";
import { wrapInLoading } from "@app/utils/promise";
import React, { useEffect } from "react";
import { ModalMarkups } from "./modal.markup";
import { useBoolean, useDynamicRef } from "@app/hooks/general";

export const kingsSummonsKey = "t-kings3";
export const kingsSummonsActionName = "i-" + kingsSummonsKey;

interface TeacherKingsOfferProps {
	onClose?: () => void;
	hasApprovedBefore?: boolean;
}

export const TeacherKingsOffer = React.memo<TeacherKingsOfferProps>(
	function TeacherKingsOffer({ onClose, hasApprovedBefore }) {
		const onCloseRef = useDynamicRef(onClose);
		const userId = useClassroomsUserId()!;
		const {
			value: isSuccessVersionShown,
			setTrue: showSuccess,
		} = useBoolean();

		useEffect(() => {
			if (!isSuccessVersionShown) return;
			setTimeout(() => {
				performAction(true);
				onCloseRef.current?.();
			}, 4000);
		}, [isSuccessVersionShown, onCloseRef]);

		const summon = () => {
			return inject("SummonsesController").create({
				summons: {
					info: {
						type: SummonsInfoType.custom,
						key: kingsSummonsKey,
					},
				},
				audience: {
					logic: {
						type: AudienceLogicType.studentsOfTeacher,
						gradesInterval: [2, 12],
						teacherId: userId,
					},
					lockAddressees: true,
				},
			});
		};
		const performAction = (approved: boolean) => {
			return inject("UserActionsController").performUserAction({
				name: kingsSummonsActionName,
				data: { approved },
			});
		};
		const handleSend = () => {
			wrapInLoading(summon().then(showSuccess));
		};
		const handleClose = () => {
			if (!hasApprovedBefore) performAction(false);
			onClose?.();
		};
		const TeacherKingsOfferModalMarkup = ModalMarkups.Component;
		const TeacherKingsOfferSuccessModalMarkup =
			ModalMarkups.SuccessComponent;
		if (isSuccessVersionShown) {
			return (
				<FancyPopup onClose={onClose}>
					<TeacherKingsOfferSuccessModalMarkup />
				</FancyPopup>
			);
		}
		return (
			<FancyPopup
				onClose={handleClose}
				containerClassName={ModalMarkups.containerClassName}
				direction="left"
			>
				<TeacherKingsOfferModalMarkup onConfirm={handleSend} />
			</FancyPopup>
		);
	}
);
