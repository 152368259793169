import Joi from "@app/utils/joi";
import { BasicDocument } from "../interface";
import { ObjectId } from "@app/utils/generics";

export enum CourseVisibility {
	public = "PUBLIC",
	private = "PRIVATE",
	unlisted = "UNLISTED",
}

export const CourseSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string(),
	isExtendable: Joi.boolean(),
	imageUrl: Joi.string(),
	rootFolder: Joi.objectId().required(),
	rootTopic: Joi.objectId().required(),
	rootTaskType: Joi.objectId().required(),
	slug: Joi.string().required(),
	price: Joi.number().required(),
	visibility: Joi.string()
		.valid(
			CourseVisibility.public,
			CourseVisibility.private,
			CourseVisibility.unlisted
		)
		.required(),
	author: Joi.number()
		.integer()
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface ICourse extends BasicDocument {
	name: string;
	rootFolder: ObjectId;
	rootTopic: ObjectId;
	rootTaskType: ObjectId;
	description?: string | undefined;
	isExtendable?: boolean | undefined;
	imageUrl?: string | undefined;
	slug: string;
	price: number;
	visibility: CourseVisibility;
	author: number;
}

export const CourseReminderSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	messagePattern: Joi.string().required(),
	lateMessagePattern: Joi.string().required(),
	hour: Joi.number()
		.integer()
		.required(),
	minute: Joi.number()
		.integer()
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface ICourseReminder extends BasicDocument {
	courseId: ObjectId;
	messagePattern: string;
	lateMessagePattern: string;
	hour: number;
	minute: number;
}

export const RHierarchySchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	rootId: Joi.objectId().required(),
	parentInfo: Joi.object()
		.pattern(/[\da-f]+/, Joi.objectId())
		.required(),
});
export interface IRHierarchy {
	_id: ObjectId;
	courseId: ObjectId;
	rootId: ObjectId;
	parentInfo: {} & {
		[x in string]?: ObjectId;
	};
}
