import {
	ConferenceSchema,
	IConference,
} from "@app/api/conferences/helper-schemas";
import { IAGETSchoolConferences } from "@app/api/conferences/validators";
import { inject } from "@app/modules";
import { removeKeys } from "@app/utils/common";
import { OptionalKeys } from "@app/utils/generics";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { QueryMetaInfo } from "./utils/query-meta";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IConference;
export type IStateConferences = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(ConferenceSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("conference");
const storageSettings = getDefaultStorageSettings("conferences");
const metaInformationName = "conferencesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().conferences) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", ConferenceSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Conference extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}
	static findBySchool(schoolId: number) {
		return this.findOneSync({ schoolId });
	}

	static findByQuery(
		args: OptionalKeys<IAGETSchoolConferences, "schoolLabelId">
	): Conference[] | null {
		if (args.schoolLabelId) return null; // TODO: normalize labelId. consider includeLabelless property
		return this.findManySync(
			removeKeys(args, "schoolLabelId", "includeLabelless")
		);
	}

	static meta = new QueryMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Conference.meta);

export const conferencesReducer = getDefaultReducer(
	storageSettings,
	() => Conference
);
