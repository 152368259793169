import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./styles/tvschool-classroom.module.css";

export const TVSchoolClassroomBoxLoader: React.FC<{}> = () => {
	return (
		<div className={styles.classroomBox}>
			<ContentLoader
				height={150}
				style={{ width: "220", height: 150 }}
				width={220}
			>
				<rect x="0" y="118" rx="9" ry="9" width="220" height="32" />
				<rect x="10" y="74" rx="6" ry="6" width="200" height="22" />
			</ContentLoader>
		</div>
	);
};
