import { useGoToUrl } from "@app/hooks/front";
import { studentLinks } from "@app/routes/student/links";
import React from "react";
import WeekliesIcon from "../../../icons/weekliesIcon.svg";
import styles from "../cognitive-tests/styles/cognitive-assignments.module.css";
import { StudentBox } from "../boxes";

const OfferWeeklies = () => {
	const gotoWeeklies = useGoToUrl(studentLinks.weeklies.weeksList);
	return (
		<section className={styles.boxContainer} onClick={gotoWeeklies}>
			<StudentBox.NewsLabel>სიახლე</StudentBox.NewsLabel>
			<img
				src={WeekliesIcon}
				alt=""
				className={styles.sendCognitiveAssignmentBuld}
				style={{ height: "150px", width: "auto" }}
			/>
			<h2 className="font-roboto-geo-caps">კვირეულები</h2>
		</section>
	);
};

export default OfferWeeklies;
