import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum VideoLessonResourceTypes {
	CLASSROOM = "CLS",
	GROUP = "GRP",
	CONFERENCE = "CNF",
}

export const ValidVideoLessonResourceTypes = [
	VideoLessonResourceTypes.CLASSROOM,
	VideoLessonResourceTypes.GROUP,
	VideoLessonResourceTypes.CONFERENCE,
];

export const VideoLessonSchema = Joi.object({
	_id: Joi.objectId().required(),
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
	url: Joi.string()
		.allow("")
		.required(),
	lastVisited: Joi.date().optional(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IVideoLesson {
	_id: ObjectId;
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
	url: string;
	lastVisited?: Date;
	createdAt: Date;
	updatedAt: Date;
}

export const TemporaryVideoLinkSchema = Joi.object({
	_id: Joi.objectId().required(),
	links: Joi.array()
		.items(Joi.string().allow(""))
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface ITemporaryVideoLink {
	_id: ObjectId;
	links: string[];
	createdAt: Date;
	updatedAt: Date;
}

export const TemporaryLinkRequestsSchema = Joi.object({
	_id: Joi.objectId().required(),
	resources: Joi.object()
		.pattern(
			Joi.string().valid(...ValidVideoLessonResourceTypes),
			Joi.array().items(Joi.objectId())
		)
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface ITemporaryLinkRequests {
	_id: ObjectId;
	resources: {
		[key in VideoLessonResourceTypes]?: ObjectId[];
	};
	createdAt: Date;
	updatedAt: Date;
}
