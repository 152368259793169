import { AppealSchema, IAppeal } from "./helper-schemas";
import { insertStripKeys, ToInsertKeys } from "../helper-schemas";
import Joi from "@app/utils/joi";

export const APOSTAppealSchema = AppealSchema.keys(insertStripKeys).keys({
	ip: Joi.any().strip(),
});
export type IAPOSTAppeal = ToInsertKeys<IAppeal, never, "ip">;

export const RPOSTAppealSchema = AppealSchema;
export type IRPOSTAppeal = IAppeal;
