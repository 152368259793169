/* eslint-disable max-lines-per-function */
import { wrapInSidebarItemsFilter } from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarType } from "@app/components/layouts/sidebar/helpers/interfaces";
import { SubWebsiteOrigin } from "@app/globals";
import {
	CalendarIcon,
	SidebarGradebookIcon,
	SidebarSemesterGradesIcon,
	SidebarSentHomeworkIcon,
	SidebarStudentListIcon,
	StatsIcon,
} from "@app/icons";
import { teacherLinks } from "@app/routes/teacher/links";
import { useTeacherSelectedSchoolId } from "../contexts/teacher-school";

export const useTeacherSidebarItems = wrapInSidebarItemsFilter(({ whazz }) => {
	const schoolId = useTeacherSelectedSchoolId();
	if (whazz.type === SidebarType.classroom) {
		const items = [
			{
				name: "sentAssignments",
				href: teacherLinks.classrooms.assignments.routePath,
				icon: SidebarSentHomeworkIcon,
			},
			{
				name: "classStudentsList",
				href: teacherLinks.classrooms.students.routePath,
				icon: SidebarStudentListIcon,
			},
			{
				name: "gradeList",
				href: teacherLinks.classrooms.gradebook.routePath,
				icon: SidebarGradebookIcon,
				subWesbites: SubWebsiteOrigin.main,
			},
			{
				name: "semesterGrades",
				href: teacherLinks.classrooms.semesterbook.routePath,
				icon: SidebarSemesterGradesIcon,
				subWesbites: SubWebsiteOrigin.main,
			},
		];
		if (schoolId === 4434) {
			items.push({
				name: "finalAnalytics",
				href: teacherLinks.classrooms.finalStats.routePath,
				icon: StatsIcon,
				subWesbites: SubWebsiteOrigin.main,
			});
		}
		return items;
	}
	if (whazz.type === SidebarType.group) {
		return [
			{
				name: "classSchedule",
				href: "/group/:groupId/timetable",
				icon: CalendarIcon,
			},
			{
				name: "student:assignments",
				href: "/group/:groupId/assignments",
				icon: SidebarSentHomeworkIcon,
			},
			{
				name: "classStudentsList",
				href: "/group/:groupId/student-list",
				icon: SidebarStudentListIcon,
			},
			{
				name: "gradeList",
				href: "/group/:groupId/gradebook",
				icon: SidebarGradebookIcon,
				subWesbites: SubWebsiteOrigin.main,
			},
			{
				name: "semesterGrades",
				href: "/group/:groupId/semesterbook",
				icon: SidebarSemesterGradesIcon,
				subWesbites: SubWebsiteOrigin.main,
			},
		];
	}
	return null;
});
