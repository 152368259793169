import React, { useCallback } from "react";
import styles from "./styles/user-subject-list.module.css";
import LupiAdLogo from "./img/LupiLogoHorizontalGEO.svg";
import LupiAdImage from "./img/LupiAdIllustration.svg";
import classnames from "classnames";
import { inject } from "@app/modules";

export const LupiAd: React.FC<{ title: string; link: string }> = React.memo(
	({ title, link }) => {
		const onClick = useCallback(() => {
			const logUserClick = async () => {
				const name = "lupiAdClicks";
				const controller = inject("UserActionsController");
				const userActions = await controller.getUserAction();
				controller.performUserAction({
					data: {
						count: userActions.actions[name]
							? userActions.actions[name].count + 1
							: 1,
						lastClickDate: new Date().toString(),
					},
					name,
				});
			};
			logUserClick();
			window.open(link, "__blank");
		}, [link]);

		return (
			<div
				onClick={() => onClick()}
				className={classnames(
					styles.userSubjectListBox,
					styles.noPaddings
				)}
			>
				<h2 className={styles.adTitle}>{title}</h2>
				<img src={LupiAdLogo} className={styles.adLogo} alt="Lupi.ge" />
				<img
					src={LupiAdImage}
					className={styles.adImage}
					alt="Lupi.ge"
				/>
				<p className={styles.adRecomendation}>LUPI.GE-ზე გადასვლა</p>
			</div>
		);
	}
);
