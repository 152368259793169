import { useInput, useTextArea } from "@app/hooks/front";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import InvisibleEyeIcon from "@material-ui/icons/VisibilityOff";
import React, {
	useCallback,
	useState,
	ChangeEvent,
	CSSProperties,
} from "react";
import StudentRegisterStyles from "./styles/student-register-page.module.css";
import SmartPhoneIcon from "@material-ui/icons/Smartphone";
import classNames from "classnames";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
import PersonIcon from "@material-ui/icons/Person";
import Select from "react-select";
import { StylesConfig } from "react-select";
import MailIcon from "@material-ui/icons/Mail";
import { getFormattedMessage } from "@app/utils/locale";
import { useLocale } from "@app/hooks/intl";

export const PasswordInput: React.FC<IAuthInputProps> = props => {
	const [showPassword, setShowPassword] = useState(props.type === "text");
	const handleVisibilityChange = useCallback(() => {
		setShowPassword(visible => !visible);
	}, []);

	return (
		<AuthInput
			{...props}
			type={showPassword ? "text" : "password"}
			placeholder={
				props.placeholder || getFormattedMessage("inputs.password")
			}
			icon={
				!showPassword ? (
					<EyeIcon className={StudentRegisterStyles.eyeIcon} />
				) : (
					<InvisibleEyeIcon
						className={StudentRegisterStyles.eyeIcon}
					/>
				)
			}
			onIconClick={handleVisibilityChange}
			isIconVissible={props.isIconVissible}
			mainContainerWrapperClassname={props.mainContainerWrapperClassname}
		/>
	);
};

export const MobileInput: React.FC<IAuthInputProps> = props => {
	const icon = props.icon || <SmartPhoneIcon />;
	return (
		<AuthInput
			{...props}
			type={props.type}
			placeholder={
				props.placeholder || getFormattedMessage("inputs.mobile")
			}
			icon={icon}
			isIconVissible={props.isIconVissible}
			mainContainerWrapperClassname={props.mainContainerWrapperClassname}
		/>
	);
};

export const MailInput: React.FC<IAuthInputProps> = props => {
	const icon = props.icon || <MailIcon />;
	return (
		<AuthInput
			{...props}
			type="text"
			placeholder={
				props.placeholder || getFormattedMessage("inputs.mail")
			}
			icon={icon}
			isIconVissible={props.isIconVissible}
			mainContainerWrapperClassname={props.mainContainerWrapperClassname}
		/>
	);
};

export const CodeInput: React.FC<IAuthInputProps> = props => {
	return (
		<AuthInput
			{...props}
			type="text"
			placeholder={
				props.placeholder ||
				getFormattedMessage("inputs.verificationCode")
			}
		/>
	);
};

export const NameInput: React.FC<IAuthInputProps> = props => {
	const icon = <PersonIcon />;
	return (
		<AuthInput
			{...props}
			type="text"
			placeholder={
				props.placeholder || getFormattedMessage("inputs.firstname")
			}
			icon={icon}
			isIconVissible={props.isIconVissible}
			mainContainerWrapperClassname={props.mainContainerWrapperClassname}
		/>
	);
};

export const SurnameInput: React.FC<IAuthInputProps> = props => {
	const icon = <PersonIcon />;
	return (
		<AuthInput
			{...props}
			type="text"
			placeholder={
				props.placeholder || getFormattedMessage("inputs.lastname")
			}
			icon={icon}
			isIconVissible={props.isIconVissible}
			mainContainerWrapperClassname={props.mainContainerWrapperClassname}
		/>
	);
};

interface IAuthInputProps {
	isDisabled?: boolean;
	className?: string;
	textFontWeight?: CSSProperties;
	fullWidthInput?: CSSProperties;
	defaultValue?: string | number;
	placeholder?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	errorText?: string | null;
	type?: "text" | "password" | "number";
	onIconClick?: () => void;
	icon?: JSX.Element;
	isIconVissible?: boolean;
	mainContainerWrapperClassname?: string;
	inputRef?: React.RefObject<HTMLInputElement | null>;
}

export const AuthInput: React.FC<IAuthInputProps> = React.memo(props => {
	const inp = useInput(props.defaultValue, props.onChange);
	const isGeo = useLocale() === "ka";

	return (
		<div
			className={
				props.mainContainerWrapperClassname
					? props.mainContainerWrapperClassname
					: StudentRegisterStyles.containerWrapper
			}
		>
			<div
				className={classNames(
					StudentRegisterStyles.inputContainer,
					props.errorText !== undefined && props.errorText !== null
				)}
				style={props.fullWidthInput}
			>
				<input
					style={
						isGeo ? props.textFontWeight : { fontWeight: "lighter" }
					}
					className={
						props.className
							? props.className
							: StudentRegisterStyles.input
					}
					placeholder={props.placeholder}
					type={props.type || "text"}
					disabled={props.isDisabled}
					ref={props.inputRef as React.RefObject<HTMLInputElement>}
					{...inp}
				/>
				{props.isIconVissible && (
					<div
						className={StudentRegisterStyles.icon}
						onClick={props.onIconClick}
					>
						{props.icon}
					</div>
				)}
			</div>
			<div>
				{props.errorText && (
					<div className={StudentRegisterStyles.errorTextContainer}>
						<div className={StudentRegisterStyles.triangle}> </div>
						<div className={StudentRegisterStyles.errorText}>
							{props.errorText}
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

export const Textarea: React.FC<{
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	defaultValue?: string;
	mainContainerWrapperClassname?: string;
	errorText?: string;
	className?: string;
}> = React.memo(props => {
	const inp = useTextArea(props.defaultValue, props.onChange);

	return (
		<div
			className={
				props.mainContainerWrapperClassname
					? props.mainContainerWrapperClassname
					: StudentRegisterStyles.containerWrapper
			}
		>
			<div
				className={classNames(
					StudentRegisterStyles.inputContainer,
					props.errorText !== undefined && props.errorText !== null
				)}
			>
				<textarea
					className={
						props.className
							? props.className
							: StudentRegisterStyles.input
					}
					onChange={props.onChange}
					{...inp}
				/>
			</div>
			<div>
				{props.errorText && (
					<div className={StudentRegisterStyles.errorTextContainer}>
						<div className={StudentRegisterStyles.triangle}> </div>
						<div className={StudentRegisterStyles.errorText}>
							{props.errorText}
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

interface IAuthSelectProps<
	OptionType extends { label: string; value: any } = {
		label: string;
		value: string;
	}
> {
	isDisabled?: boolean;
	value?: OptionType | undefined | null;
	onChange?: (value: OptionType) => void;
	placeholder?: string;
	errorText?: string | null;
	onIconClick?: () => void;
	icon?: JSX.Element;
	options?: OptionType[] | undefined;
	isSearchable?: boolean;
	className?: string;
	containerClassName?: string;
	styles?: StylesConfig;
	isIconVissible?: boolean;
}

export const AuthSelect = <
	OptionType extends { label: string; value: any } = {
		label: string;
		value: string;
	}
>(
	props: IAuthSelectProps<OptionType>
) => {
	return (
		<div className={props.containerClassName}>
			<div
				className={
					props.className
						? props.className
						: classNames(
								StudentRegisterStyles.inputContainer,
								props.errorText !== undefined &&
									props.errorText !== null &&
									StudentRegisterStyles.errorSelectContainer
						  )
				}
				style={{ padding: "1px 0" }}
			>
				<Select
					styles={props.styles ? props.styles : customSelectStyles}
					options={props.options}
					placeholder={props.placeholder ? props.placeholder : ""}
					onChange={props.onChange}
					value={props.value}
					isDisabled={props.isDisabled}
					isSearchable={
						props.isSearchable !== undefined
							? props.isSearchable
							: true
					}
				/>
				{props.isIconVissible && (
					<div
						className={StudentRegisterStyles.icon}
						onClick={props.onIconClick}
					>
						{props.icon}
					</div>
				)}
			</div>
			<ErrorTextWithTriangle errorText={props.errorText} />
		</div>
	);
};

export const ErrorTextWithTriangle: React.FC<{
	errorText: string | null | undefined;
}> = props => {
	return (
		<div>
			{props.errorText && (
				<div className={StudentRegisterStyles.errorTextContainer}>
					<div className={StudentRegisterStyles.triangle}> </div>
					<div className={StudentRegisterStyles.errorText}>
						{props.errorText}
					</div>
				</div>
			)}
		</div>
	);
};

const customSelectStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "7px 10px",
	}),
	control: () => ({
		padding: "7px 10px",
		border: "none",
		backgroundColor: "#cccccc",
		borderRadius: 5,
		margin: "5px 0",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition };
	},
	indicatorsContainer: (provided, state) => {
		return { display: "none" };
	},
};

export interface IButtonProps {
	onClick?: () => void;
	isLoading?: boolean;
	text: string;
	className?: string;
	wrapperDivClassName?: string;
	loaderClassName?: string;
	disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = props => {
	return (
		<div className={props.wrapperDivClassName}>
			<button
				className={
					props.className
						? props.className
						: StudentRegisterStyles.actionButton
				}
				onClick={!props.isLoading ? props.onClick : undefined}
				style={{ opacity: props.isLoading ? 0.8 : 1 }}
				disabled={props.disabled}
			>
				<div style={{ marginRight: 5 }}>{props.text}</div>
				{props.isLoading && (
					<div>
						<SvgloadingWhite
							className={
								props.loaderClassName ||
								StudentRegisterStyles.loader
							}
						/>
					</div>
				)}
			</button>
		</div>
	);
};
