import { inject } from "@app/modules";

const disabledSchoolIds: number[] = [];

export const avoidScheduledLessons = () => {
	try {
		const user = inject("assertAndGetClassroomsUser")();
		if (disabledSchoolIds.includes(user.school!)) return true;
	} catch (e) {}
	return false;
};

export const disabledTimetableSchools = disabledSchoolIds;
