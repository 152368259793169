const transformations = {
	მარიამი: "მარიამ",
	mari: "მარი",
	giorgi: "გიორგი",
	ana: "ანა",
	mariami: "მარიამ",
	ლევანი: "ლევან",
	levani: "ლევან",
	გურამი: "გურამ",
	თემური: "თემურ",
	თამარი: "თამარ",
	დავითი: "დავით",
	ნოდარი: "ნოდარ",
	ომარი: "ომარ",
	მერაბი: "მერაბ",
	ნუგზარი: "ნუგზარ",
};

export function toGeorgianVocativeName(firstname: string): string {
	if (typeof transformations[firstname] == "undefined") return firstname;
	return transformations[firstname];
}
