import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, ReduxStoreContext } from "index";
import { StudentLogin } from "./auth/login";
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router";
import { HistoryContext } from "@app/app";
import Popup from "./widgets/popup";
import { IntlProvider } from "react-intl-redux";

const successCallbacks: Function[] = [];
const rejectionCallbacks: Function[] = [];

export function requireLoginForActionPromise(): Promise<void> {
	return Promise.reject();
	/* return new Promise((resolve, reject) => {
		requireLoginForAction(resolve, reject);
	}); */
}

export default function requireLoginForAction(
	callback: Function,
	rejectionCallback?: Function
) {
	if (
		localStorage.credentials &&
		localStorage.user &&
		localStorage.coursesCredentials
	) {
		// already logged in
		callback();
	} else {
		successCallbacks.push(callback);
		if (rejectionCallback) rejectionCallbacks.push(rejectionCallback);
		if (successCallbacks.length > 1) {
			// login popup is already displayed
			return;
		}
		const theParent = document.getElementById("playground");
		const popupContainer = document.createElement("div");
		theParent!.appendChild(popupContainer);
		ReactDOM.render(
			<BrowserRouter>
				<ReduxStoreContext.Provider value={store}>
					<Provider store={store}>
						<IntlProvider {...store.getState().intl}>
							<Route
								component={props =>
									React.useMemo(
										() => (
											<HistoryContext.Provider
												value={props.history}
											>
												<Popup
													onClose={() => {
														rejectCallbacks();
														popupContainer.remove();
														ReactDOM.unmountComponentAtNode(
															popupContainer
														);
													}}
												>
													<StudentLogin
														onSuccess={() => {
															resolveCallbacks();
															popupContainer.remove();
															ReactDOM.unmountComponentAtNode(
																popupContainer
															);
														}}
														hideRegisterPage={() => {}}
														isRegisterPageOpen={
															false
														}
														openRegisterPage={() => {}}
													/>
												</Popup>
											</HistoryContext.Provider>
										),
										[props.history]
									)
								}
							/>
						</IntlProvider>
					</Provider>
				</ReduxStoreContext.Provider>
			</BrowserRouter>,
			popupContainer
		);
	}
}

function resolveCallbacks() {
	cleanArray(rejectionCallbacks);
	successCallbacks.forEach((callback, i) => {
		delete successCallbacks[i];
		callback();
	});
	cleanArray(successCallbacks);
}
function rejectCallbacks() {
	cleanArray(successCallbacks);
	rejectionCallbacks.forEach((callback, i) => {
		delete rejectionCallbacks[i];
		callback();
	});
	cleanArray(rejectionCallbacks);
}

function cleanArray(arr: any[]) {
	while (arr.length) arr.pop();
}
