import { arrayToObject } from "./common";

export const sortArrayByPriorities = <T>(
	arr: T[],
	priorities: number[]
): T[] => {
	if (priorities.length !== arr.length) {
		throw new Error("length of arr and priorities must be same");
	}
	const newArr = arr.map((e, i) => ({ item: e, priority: priorities[i] }));

	return newArr.sort((a, b) => a.priority - b.priority).map(e => e.item);
};

export function sortArrayByKeys<
	T,
	K extends string | number | boolean,
	MT = T,
	UMT = T
>({
	array,
	fn,
	keys,
	matchedMapFn,
	unmatchedMapFn,
}: {
	array: T[];
	fn: (item: T) => K | undefined | null;
	keys: K[];
	matchedMapFn?: (items: T[]) => MT[];
	unmatchedMapFn?: (items: T[]) => UMT[];
}): (MT | UMT)[];
export function sortArrayByKeys<T, MT = T, UMT = T>({
	array,
	keys,
	matchedMapFn,
	unmatchedMapFn,
}: {
	array: T[];
	keys: T[];
	matchedMapFn?: (items: T[]) => MT[];
	unmatchedMapFn?: (items: T[]) => UMT[];
}): (MT | UMT)[];
export function sortArrayByKeys<
	T,
	K extends string | number | boolean,
	MT = T,
	UMT = T
>({
	array,
	fn = item => item as any,
	keys,
	matchedMapFn,
	unmatchedMapFn,
}: {
	array: T[];
	fn: (item: T) => K | undefined | null;
	keys: K[];
	matchedMapFn?: (items: T[]) => MT[];
	unmatchedMapFn?: (items: T[]) => UMT[];
}): (MT | UMT)[] {
	const indicesByKeys = arrayToObject(keys, (key, i) => ({
		key: key + "",
		value: i,
	}));

	let matchedArr: T[] = [];
	const matchedItemIndices = new Set<number>();

	for (let i = 0; i < array.length; ++i) {
		const item = array[i];
		const key = fn(item);
		if (key === null || key === undefined) {
			continue;
		}
		keys.indexOf(key);
		const keyIndex = indicesByKeys[key + ""];
		if (keyIndex === undefined) {
			continue;
		}
		matchedArr[keyIndex] = item;
		matchedItemIndices.add(i);
	}
	matchedArr = matchedArr.filter(e => true); // filter out empty elements
	const unmatchedArr = array.filter((e, i) => !matchedItemIndices.has(i));

	const matchedMapped = matchedMapFn
		? matchedMapFn(matchedArr)
		: ((matchedArr as any[]) as MT[]);
	const unmatchedMapped = unmatchedMapFn
		? unmatchedMapFn(unmatchedArr)
		: ((unmatchedArr as any[]) as UMT[]);

	return [...matchedMapped, ...unmatchedMapped];
}
