import { IUserExtendedAnswer } from ".";

export class QuestionTiming {
	static getUserExtendedAnswerAfterSwitchingTo = <
		T extends IUserExtendedAnswer | undefined
	>(
		extendedAnswer: T
	): T => {
		if (!extendedAnswer) return undefined as T;
		const newDates = { ...extendedAnswer.dates };
		newDates.unceasingSwitchingDateToQuestion = new Date();
		return {
			...extendedAnswer,
			dates: newDates,
		};
	};

	static getUserExtendedAnswerAfterLeaving = <
		T extends IUserExtendedAnswer | undefined
	>(
		extendedAnswer: T
	): T => {
		if (!extendedAnswer) return undefined as T;
		const newDates = { ...extendedAnswer.dates };
		const oldSwitchinDate = newDates.unceasingSwitchingDateToQuestion;
		delete newDates.unceasingSwitchingDateToQuestion;
		if (oldSwitchinDate) {
			const currentTime = Date.now();
			const timeDiff = currentTime - oldSwitchinDate.getTime();
			newDates.millisecondsSpentTotally =
				(newDates.millisecondsSpentTotally ||
					newDates.millisecondsSpentBeforeSubmitting ||
					newDates.millisecondsSpentBeforeLastAnswer ||
					0) + timeDiff;
		}
		return {
			...extendedAnswer,
			dates: newDates,
		};
	};

	static getUserExtendedAnswerAfterUserAnswerChange = (
		extendedAnswer: IUserExtendedAnswer
	): IUserExtendedAnswer => {
		const newDates = { ...extendedAnswer.dates };
		const currentDate = new Date();
		const currentTime = currentDate.getTime();
		const oldSwitchingDate = newDates.unceasingSwitchingDateToQuestion;
		if (oldSwitchingDate) {
			const timeDiff = currentTime - oldSwitchingDate.getTime();
			newDates.millisecondsSpentTotally =
				(newDates.millisecondsSpentTotally || 0) + timeDiff;

			newDates.millisecondsSpentBeforeSubmitting =
				(newDates.millisecondsSpentBeforeSubmitting || 0) + timeDiff;
			newDates.millisecondsSpentBeforeLastAnswer =
				newDates.millisecondsSpentBeforeSubmitting;
		} else {
			console.error("unceasingSwitchingDateToQuestion cannot be empty");
		}
		newDates.unceasingSwitchingDateToQuestion = new Date();
		newDates.firstAnsweredAt = newDates.firstAnsweredAt || currentDate;
		newDates.lastAnsweredAt = currentDate;
		return {
			...extendedAnswer,
			dates: newDates,
		};
	};

	static getUserExtendedAnswerAfterSubmitting = (
		extendedAnswer: IUserExtendedAnswer
	): IUserExtendedAnswer => {
		const newDates = { ...extendedAnswer.dates };
		const oldSwitchingDate = newDates.unceasingSwitchingDateToQuestion;
		const currentDate = new Date();
		const currentTime = currentDate.getTime();
		if (oldSwitchingDate) {
			const timeDiff = currentTime - oldSwitchingDate.getTime();
			newDates.millisecondsSpentTotally =
				(newDates.millisecondsSpentTotally || 0) + timeDiff;

			newDates.millisecondsSpentBeforeSubmitting =
				(newDates.millisecondsSpentBeforeSubmitting || 0) + timeDiff;
		}
		newDates.unceasingSwitchingDateToQuestion = new Date();
		newDates.submittedAt = currentDate;
		return {
			...extendedAnswer,
			dates: newDates,
			submitted: true,
		};
	};
}
