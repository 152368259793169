import { IRequest } from "../requests";
import {
	RGETUserDocsByGroupSchema,
	IAGETUserDocsByGroup,
	IRGETUserDocsByGroup,
	IAGETUserDocByTest,
	IRGETUserDocByTest,
	RGETUserDocByTestSchema,
	IAPOSTSaveUsertest,
} from "./validators";

export class SpecialTestsController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	async getUserDocsByGroup(
		args: IAGETUserDocsByGroup
	): Promise<IRGETUserDocsByGroup> {
		return this.Request.send(
			"GET",
			"/api/special-tests/get-user-docs-by-group",
			args,
			null,
			{
				responseSchema: RGETUserDocsByGroupSchema,
			}
		);
	}

	async getUserDocsByTest(
		args: IAGETUserDocByTest
	): Promise<IRGETUserDocByTest> {
		return this.Request.send(
			"GET",
			"/api/special-tests/get-user-docs-by-test",
			args,
			null,
			{
				responseSchema: RGETUserDocByTestSchema,
			}
		);
	}

	async saveUserTest(args: IAPOSTSaveUsertest): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/special-tests/save-user-test",
			args
		);
	}
}
