import { SetState } from "react-prop-hooks";
import { useEffect, useLayoutEffect, useRef, useCallback } from "react";
import { useLocationQuery } from "@app/hooks/front";
import { inject } from "@app/modules";

// eslint-disable-next-line max-lines-per-function
export const useSavedValue = <T extends number | string | null>({
	isLoaded,
	isValidValue,
	setValue,
	currentValue,
	onAfterFirtChange,
	fetchValue,
	getLastPriorityValue,
	topPriorityValues,
}: {
	isLoaded: boolean;
	isValidValue: (value: T) => boolean;
	setValue: SetState<T>;
	currentValue: T;
	onAfterFirtChange: (value: T) => void;
	fetchValue?: () => Promise<T | undefined>;
	getLastPriorityValue: () => T | undefined;
	topPriorityValues: { value: T | undefined; putInDeps: boolean }[];
}) => {
	const hasFetchedLastValueAtLeastOnceRef = useRef(false);
	const hasMounted = useRef(false);

	const extraDeps = topPriorityValues
		.filter(e => e.putInDeps)
		.map(e => e.value);

	useLayoutEffect(() => {
		if (!isLoaded) return;

		for (const { value } of topPriorityValues) {
			if (value === undefined) continue;
			if (isValidValue(value)) {
				setValue(value);
				return;
			}
		}

		const checkLastPriorities = () => {
			const v = getLastPriorityValue();
			if (v !== undefined && isValidValue(v)) {
				setValue(v);
			}
		};

		let isCancelled = false;

		if (hasFetchedLastValueAtLeastOnceRef.current === true || !fetchValue) {
			checkLastPriorities();
		} else {
			fetchValue().then(id => {
				if (isCancelled) return;

				hasFetchedLastValueAtLeastOnceRef.current = true;
				if (id !== undefined && isValidValue(id)) {
					setValue(id);
					return;
				}

				checkLastPriorities();
			});
		}

		return () => {
			isCancelled = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, !!fetchValue, isValidValue, ...extraDeps]);

	useEffect(() => {
		if (hasMounted.current) {
			onAfterFirtChange(currentValue);
		}
		hasMounted.current = true;
	}, [currentValue, onAfterFirtChange]);

	return currentValue;
};

export const useSimplifiedSavedValue = <T extends number | string | null>({
	isLoaded,
	isValidValue,
	setValue,
	currentValue,
	getLastPriorityValue,
	keys: { query: queryKey, session: sessionKey, action: actionKey },
	parseValueFromStr,
	fetchFlag,
}: {
	isLoaded: boolean;
	isValidValue: (value: T) => boolean;
	setValue: SetState<T>;
	currentValue: T;
	getLastPriorityValue: () => T | undefined;
	keys: {
		query: string;
		session: string;
		action: string;
	};
	parseValueFromStr: (string: string | null | undefined) => T | undefined;
	fetchFlag?: "nullToUndefined";
}) => {
	const { [queryKey]: unparsedSchoolIdFromQuery } = useLocationQuery(true);

	const schoolIdFromQuery = parseValueFromStr(
		unparsedSchoolIdFromQuery as string | null | undefined
	);
	const schoolIdInStorage = parseValueFromStr(sessionStorage[sessionKey]);

	const onAfterFirtChange = useCallback(
		(value: T | null) => saveLastSchoolInDatabase(actionKey, value),
		[actionKey]
	);
	const fetchValue = useCallback(
		() => getActionValueFromDatabase(actionKey, fetchFlag),
		[actionKey, fetchFlag]
	);

	const value = useSavedValue({
		isLoaded,
		isValidValue,
		setValue,
		currentValue,
		getLastPriorityValue,
		onAfterFirtChange,
		fetchValue,
		topPriorityValues: [
			{ value: schoolIdFromQuery, putInDeps: true },
			{ value: schoolIdInStorage ?? undefined, putInDeps: false },
		],
	});

	useEffect(() => {
		if (!isLoaded) return;
		sessionStorage[sessionKey] = currentValue + "";
	}, [sessionKey, isLoaded, currentValue]);

	return value;
};

const saveLastSchoolInDatabase = (ACTION_KEY: string, value: any) => {
	inject("UserActionsController")
		.performUserAction(
			{
				name: ACTION_KEY,
				data: value,
			},
			true
		)
		.catch(() => {
			//
		});
};

const getActionValueFromDatabase = (
	ACTION_KEY: string,
	flag?: "nullToUndefined"
): Promise<any> => {
	return inject("UserActionsController")
		.getUserAction()
		.then(data => {
			const value = data.actions[ACTION_KEY];
			if (flag === "nullToUndefined") return value ?? undefined;
			return value;
		});
};
