import { UserType } from "@app/api/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { IRAccessibleIds, RCanAccess } from "./interfaces";

export abstract class UserPermissions {
	constructor(public type: UserType) {}

	abstract canAccessAllClassrooms(): boolean;

	abstract canAccessClassroom(classroomId: ObjectId): boolean;

	abstract canStudyInClassroom(classroomId: ObjectId): boolean;

	abstract canAccessGroup(groupId: ObjectId): boolean;

	abstract getAvailableClassrooms(): ObjectId[];

	abstract getOwnClassrooms(): ObjectId[];

	abstract getOwnGroups(): ObjectId[];

	abstract hasConfirmedChild(childId: number): boolean;

	abstract getConfirmedChildrenIds(): number[];

	abstract getOwnLabelIds?: () => ObjectId[] | null;
	abstract getViewableLabelIds?: () => ObjectId[] | null;

	// course permissions

	canAccessCourse(courseId: ObjectId): RCanAccess {
		const accessibleCourses = this.getAccessibleCourseIds();
		if (!accessibleCourses.isKnown) {
			return { isKnown: false };
		}
		return {
			isKnown: true,
			canAccess:
				accessibleCourses.hasAll ||
				accessibleCourses.ids.some(id => id === courseId),
		};
	}

	canAccessFolder(args: {
		courseId: ObjectId;
		folderId: ObjectId;
	}): RCanAccess {
		return this.canAccessCourse(args.courseId);
	}

	abstract getAccessibleCourseIds(): IRAccessibleIds;

	// school permissions

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	canViewSchool(schoolId: number): boolean {
		return false;
	}
}
