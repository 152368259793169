import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { CourseCommonResource } from "../interface";

export enum FileItemTypes {
	text = "TEXT",
	questions = "QUESTIONS",
	cards = "CARDS",
	tabs = "TABS",
}

export const FileTextSchema = Joi.object({
	type: Joi.string()
		.valid(FileItemTypes.text)
		.required(),
	text: Joi.string().required(),
});
export interface IFileText {
	type: FileItemTypes.text;
	text: string;
}

export const FileQuestionsSchema = Joi.object({
	type: Joi.string()
		.valid(FileItemTypes.questions)
		.required(),
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IFileQuestions {
	type: FileItemTypes.questions;
	ids: ObjectId[];
}

export const FileCardsSchema = Joi.object({
	type: Joi.string()
		.valid(FileItemTypes.cards)
		.required(),
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IFileCards {
	type: FileItemTypes.cards;
	ids: ObjectId[];
}

export const FileTabSingleItemSchema = Joi.object({
	_id: Joi.objectId().required(),
	title: Joi.string()
		.allow("")
		.required(),
	item: Joi.link()
		.ref("#FileSingleItemSchema")
		.required(),
});
export interface IFileTabSingleItem {
	_id: ObjectId;
	title: string;
	item: IFileSingleItem;
}

export const FileTabsSchema = Joi.object({
	type: Joi.string()
		.valid(FileItemTypes.tabs)
		.required(),
	items: Joi.array()
		.items(FileTabSingleItemSchema)
		.required(),
});
export interface IFileTabs {
	type: FileItemTypes.tabs;
	items: IFileTabSingleItem[];
}

const FileSingleItemSchema = Joi.alternatives(
	FileTextSchema,
	FileQuestionsSchema,
	FileCardsSchema,
	FileTabsSchema
).id("FileSingleItemSchema");
export type IFileSingleItem =
	| IFileText
	| IFileQuestions
	| IFileCards
	| IFileTabs;

export const FileSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	author: Joi.number()
		.integer()
		.required(),
	items: Joi.array()
		.items(FileSingleItemSchema)
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IFile extends CourseCommonResource {
	name: string;
	folderId: ObjectId;
	items: IFileSingleItem[];
}

export const FileWithFullContentSchema = Joi.object({
	file: FileSchema.required(),
	itemNames: Joi.object()
		.keys({
			questions: Joi.object().pattern(/[\da-f]+/, Joi.string()),
			cards: Joi.object().pattern(/[\da-f]+/, Joi.string()),
		})
		.required(),
});
export interface IFileWithFullContent {
	file: IFile;
	itemNames: {
		questions?: {
			[x: string]: string | undefined;
		};
		cards?: {
			[x: string]: string | undefined;
		};
	};
}
