import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { BasicDocument, BasicDocumentSchema } from "../interface";
import { UserTypes, UserType } from "../helper-schemas";

export enum AudienceLogicType {
	studentsOfTeacher = "ST_TCH",
	studentsOfClassroom = "ST_CLS",
	multiple = "MULTI",
	schoolUsers = "SCHL",
	certainUsers = "USRS",
}

const TeacherStudentsLogicSchema = Joi.object({
	type: Joi.any()
		.valid(AudienceLogicType.studentsOfTeacher)
		.required(),
	teacherId: Joi.number().required(),
	includeTeachers: Joi.boolean(),
	gradesInterval: Joi.array()
		.items(Joi.number(), Joi.number())
		.ordered(),
	grades: Joi.array().items(Joi.number()),
});

export interface TeacherStudentsLogic {
	type: AudienceLogicType.studentsOfTeacher;
	teacherId: number;
	includeTeachers?: boolean;
	gradesInterval?: [number, number];
	grades?: number[];
}

///

const ClassroomStudentLogicSchema = Joi.object({
	type: Joi.any()
		.valid(AudienceLogicType.studentsOfClassroom)
		.required(),
	classroomId: Joi.objectId().required(),
	includeTeachers: Joi.boolean(),
});

export interface ClassroomStudentLogic {
	type: AudienceLogicType.studentsOfClassroom;
	classroomId: ObjectId;
	includeTeachers?: boolean;
}

///

const SchoolUsersLogicSchema = Joi.object({
	type: Joi.any()
		.valid(AudienceLogicType.schoolUsers)
		.required(),
	schoolId: Joi.number().required(),
	schoolLabelId: Joi.objectId()
		.allow(null)
		.required(),
	userTypes: Joi.array()
		.items(Joi.any().valid(...UserTypes))
		.optional(),
});

export interface SchoolUsersLogic {
	type: AudienceLogicType.schoolUsers;
	schoolId: number;
	schoolLabelId: ObjectId | null;
	userTypes: UserType[];
}

///

const CertainUsersLogicSchema = Joi.object({
	type: Joi.any()
		.valid(AudienceLogicType.certainUsers)
		.required(),
	userIds: Joi.array()
		.items(Joi.number().integer())
		.optional(),
});

export interface CertainUsersLogic {
	type: AudienceLogicType.certainUsers;
	userIds: number[];
}

///

const AudienceLogicWithoutMultiSchema = Joi.alternatives(
	TeacherStudentsLogicSchema,
	ClassroomStudentLogicSchema,
	SchoolUsersLogicSchema,
	CertainUsersLogicSchema
);

export type AudienceLogicWithoutMulti =
	| TeacherStudentsLogic
	| ClassroomStudentLogic
	| SchoolUsersLogic
	| CertainUsersLogic;

///

const MultiLogicSchema = Joi.object({
	type: Joi.any()
		.valid(AudienceLogicType.multiple)
		.required(),
	logics: Joi.array()
		.items(AudienceLogicWithoutMultiSchema)
		.required(),
});

export interface MultiLogic {
	type: AudienceLogicType.multiple;
	logics: AudienceLogic[];
}

///

const AudienceLogicSchema = Joi.alternatives(
	AudienceLogicWithoutMultiSchema,
	MultiLogicSchema
);

export type AudienceLogic = AudienceLogicWithoutMulti | MultiLogic;

///

export const AudienceSchema = BasicDocumentSchema.keys({
	addressees: Joi.array()
		.items(Joi.number())
		.required(),
	logic: AudienceLogicSchema.required(),
	lockAddressees: Joi.boolean().default(true),
	isArchived: Joi.boolean(),
});

export interface IAudience extends BasicDocument {
	addressees: number[];
	logic: AudienceLogic;
	lockAddressees?: boolean;
	isArchived?: boolean;
}
