import {
	ReserveResourceType,
	IReservation,
	ReservationSchema,
} from "./helper-schemas";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const APOSTReserveProductSchema = Joi.object({
	resourceType: Joi.string()
		.valid(ReserveResourceType.standardOffer)
		.required(),
	resourceId: Joi.objectId().required(),
	productId: Joi.objectId().required(),
	action: Joi.string()
		.valid("reserve", "cancel-reserve")
		.required(),
});

export interface IAPOSTReserveProduct {
	resourceType: ReserveResourceType.standardOffer;
	resourceId: ObjectId;
	productId: ObjectId;
	action: "reserve" | "cancel-reserve";
}

export type IRPOSTReserveProduct = IReservation;
export const RPOSTReserveProductSchema = ReservationSchema;

///

export interface IAPOSTView {
	resourceType: ReserveResourceType.standardOffer;
	resourceId: ObjectId;
}

export const APOSTViewSchema = Joi.object({
	resourceType: Joi.string()
		.valid(ReserveResourceType.standardOffer)
		.required(),
	resourceId: Joi.objectId().required(),
});

export type IRPOSTView = IReservation;
export const RPOSTViewSchema = ReservationSchema;

///

export interface IAGETProductReservationStats {
	resourceId: ObjectId;
	resourceType: ReserveResourceType;
	productId: ObjectId;
	limit?: number;
	offset?: number;
}

export const AGETProductReservationStatsSchema = Joi.object({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(ReserveResourceType.standardOffer)
		.required(),
	productId: Joi.objectId().required(),
	limit: Joi.number(),
	offset: Joi.number(),
});

export type IRGETProductReservationStats = {
	userId: number;
	firstname: string;
	lastname: string;
	grade: number | null;
	mobile: string | null;
	reservationDate: Date;
}[];
