import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum UserPacketExpirationType {
	never = "never",
	untilSomeDate = "date",
}

export const UserPacketExpirationSchema = Joi.alternatives(
	Joi.object({
		type: Joi.string()
			.valid(UserPacketExpirationType.never)
			.required(),
	}),
	Joi.object({
		type: Joi.string()
			.valid(UserPacketExpirationType.untilSomeDate)
			.required(),
		date: Joi.date().required(),
	})
);
export type IUserPacketExpiration =
	| {
			type: UserPacketExpirationType.never;
	  }
	| {
			date: Date;
			type: UserPacketExpirationType.untilSomeDate;
	  };

export const UserPacketSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number().allow(null),
	mobile: Joi.string().allow(null),
	expiration: UserPacketExpirationSchema.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IUserPacket {
	_id: ObjectId;
	userId?: number | null;
	mobile?: string | null;
	expiration: IUserPacketExpiration;
	createdAt: Date;
	updatedAt: Date;
}
