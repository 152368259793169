import React from "react";
import popupStyles from "./styles/popup.module.css";
import ArrowRight from "../../assignments-2/img/ArrowRight.svg";
import classnames from "classnames";

const RollInNotification: React.FC<{
	show: boolean;
	onClose: () => void;
	title: string;
	text: string;
}> = React.memo(({ onClose, show, title, text }) => {
	return (
		<div
			className={classnames(
				popupStyles.popup_container,
				!show ? popupStyles.hide_popup_container : ""
			)}
			onClick={onClose}
		>
			<img
				src={ArrowRight}
				alt=""
				className={popupStyles.popup_arrow_icon}
			/>
			<h3 className={popupStyles.popup_title}>{title}</h3>
			<p className={popupStyles.popup_text}>{text}</p>
		</div>
	);
});

export default RollInNotification;
