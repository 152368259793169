import {
	TaskTypeSchema,
	ITaskTypeItems,
	UserTaskTypeLevelSchema,
	ITaskType,
	IUserTaskTypeLevel,
} from "./helper-schemas";
import Joi from "@app/utils/joi";
import { RHierarchySchema, IRHierarchy } from "../courses/helper-schemas";
import { ItemType } from "../folders/helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateTaskTypeSchema = TaskTypeSchema.keys(
	insertStripKeys
).keys({
	parentId: Joi.objectId(),
});

export type IAPOSTCreateTaskType = ToInsertKeys<ITaskType> & {
	parentId?: ObjectId;
};

export const RPOSTCreateTaskTypeSchema = TaskTypeSchema.required();
export type IRPOSTCreateTaskType = ITaskType;

///

export const APUTSetParentSchema = Joi.object({
	courseId: Joi.objectId().required(),
	_id: Joi.objectId().required(),
	parentId: Joi.objectId().required(),
});
export interface IAPUTSetParent {
	courseId: ObjectId;
	_id: ObjectId;
	parentId: ObjectId;
}

///

export const APUTTaskTypeSchema = TaskTypeSchema.keys(updateStripKeys);

export type IAPUTTaskType = ToUpdateKeys<ITaskType>;

///

export const AGETTaskTypeSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseId: Joi.objectId().required(),
});
export interface IAGETTaskType {
	_id: ObjectId;
	originalCourseId: ObjectId;
}

export const RGETTaskTypeSchema = TaskTypeSchema;
export type IRGETTaskType = ITaskType;

///

export const ADELETETaskTypeSchema = AGETTaskTypeSchema;
export type IADELETETaskType = IAGETTaskType;

///

export const RGETAllTaskTypesSchema = Joi.array().items(TaskTypeSchema);
export type IRGETAllTaskTypes = ITaskType[];

///

export const AGETALLCourseTaskTypesSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETALLCourseTaskTypes {
	courseId: ObjectId;
}

///

export const APUTEditTaskTypeItemsSchema = Joi.object({
	labelId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	questions: Joi.array().items(Joi.objectId()),
	texts: Joi.array().items(Joi.objectId()),
	cards: Joi.array().items(Joi.objectId()),
});
export interface IAPUTEditTaskTypeItems {
	labelId: ObjectId;
	courseId: ObjectId;
	questions?: ObjectId[];
	texts?: ObjectId[];
	cards?: ObjectId[];
}
export type IRPUTEditTaskTypeItems = ITaskTypeItems;

export const APUTAddItemsToTaskTypeSchema = APUTEditTaskTypeItemsSchema;
export type IAPUTAddItemsToTaskType = IAPUTEditTaskTypeItems;

export type IRPUTAddItemsToTaskType = ITaskTypeItems;

///

export const APUTRemoveItemsFromTaskTypeSchema = APUTEditTaskTypeItemsSchema;
export type IAPUTRemoveItemsFromTaskType = IAPUTEditTaskTypeItems;

export type IRPUTRemoveItemsFromTaskType = ITaskTypeItems;

///

export const AGETTaskTypeHierarchySchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETTaskTypeHierarchy {
	courseId: ObjectId;
}

export const RGETTaskTypeHierarchySchema = RHierarchySchema;
export type IRGETTaskTypeHierarchy = IRHierarchy;

///

export const AGETTaskTypesByQuestionSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(ItemType.question, ItemType.card, ItemType.text)
		.required(),
});
export interface IAGETTaskTypesByQuestion {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType.question | ItemType.card | ItemType.text;
}

export const RGETTaskTypesByQuestionSchema = Joi.array().items(
	Joi.objectId().required()
);
export type IRGETTaskTypesByQuestion = ObjectId[];

///

export const AGETTaskTypeLevelsSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETTaskTypeLevels {
	courseId: ObjectId;
}

export const RGETTaskTypeLevelsSchema = UserTaskTypeLevelSchema;
export type IRGETTaskTypeLevels = IUserTaskTypeLevel;

///

export const APUTReplaceOneWithTheOtherSchema = Joi.object({
	oldId: Joi.objectId().required(),
	newId: Joi.objectId().required(),
});
export interface IAPUTReplaceOneWithTheOther {
	oldId: ObjectId;
	newId: ObjectId;
}

///

export const APUTReplaceManyWithOthersSchema = Joi.array().items(
	APUTReplaceOneWithTheOtherSchema
);
export type IAPUTReplaceManyWithOthers = IAPUTReplaceOneWithTheOther[];

///
