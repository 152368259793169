import React, { useState } from "react";
import styles from "./styles/user-subject-list.module.css";
import EnlistClassroom from "./img/EnlistClassroom.svg";
import { FancyPopup } from "@app/components/widgets/popup";
import { useBoolean } from "@app/hooks/general";
import { getFormattedMessage } from "@app/utils/locale";
import { inject } from "@app/modules";
import { wrapInLoading } from "@app/utils/promise";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { isHTTPNotFoundException } from "@app/utils/errors";
import { UserType } from "@app/api/helper-schemas";

export const EnlistInClassroomBox: React.FC = React.memo(
	function EnlistInClassroomBox() {
		const {
			value: popup,
			setTrue: openPopup,
			setFalse: closePopup,
		} = useBoolean(false);

		return (
			<>
				<div
					className={`${styles.userSubjectListBox} ${styles.enlistInClassroomBox}`}
					onClick={openPopup}
				>
					<div className={styles.enlistInClassroomBoxContent}>
						<img
							src={EnlistClassroom}
							alt="Enlist in a classroom"
						/>
						<span>{getFormattedMessage("enlistInClassroom")}</span>
					</div>
				</div>
				{popup && <EnlistInClassroomPopup onClose={closePopup} />}
			</>
		);
	}
);

interface EnlistInClassroomPopup {
	onClose?: () => void;
}

const EnlistInClassroomPopup: React.FC<EnlistInClassroomPopup> = React.memo(
	function EnlistInClassroomPopup({ onClose }) {
		const [code, setCode] = useState("");

		const {
			value: isSuccessfullyEnrolled,
			setTrue: setEnrolled,
		} = useBoolean();

		const onEnlistClick = () => {
			wrapInLoading(
				inject("ClassroomEnrollmentCodesController")
					.enrollClassroomBySlug({
						slug: code,
					})
					.then(() => {
						setEnrolled();
						inject("UsersController").getMyInfo(
							UserType.student,
							true
						); // for live uptating classrooms list on student's main page
					})
					.catch(e => {
						if (isHTTPNotFoundException(e)) {
							openConfirmationPopup({
								text: "კოდი არასწორია. გთხოვთ, გადაამოწმოთ",
							});
						} else throw e;
					})
			);
		};

		if (isSuccessfullyEnrolled) {
			return <SuccessPopup onClose={onClose} />;
		}

		return (
			<FancyPopup
				title={getFormattedMessage("enlistInClassroom")}
				onClose={onClose}
			>
				<div className={styles.enlistInClassroomPopupContent}>
					<p>ჩაწერეთ კოდი და გაწევრიანდით თქვენს ჯგუფში.</p>
					<div className={styles.classroomCodeInput}>
						<input
							value={code}
							onChange={e => setCode(e.target.value)}
							placeholder={getFormattedMessage("classroomCode")}
						/>
					</div>
					<button
						className={styles.enlistButton}
						onClick={onEnlistClick}
					>
						{getFormattedMessage("enlistInClassroom")}
					</button>
				</div>
			</FancyPopup>
		);
	}
);

export const SuccessPopup = React.memo<{ onClose?: () => void }>(
	function SuccessPopup({ onClose }) {
		return (
			<FancyPopup onClose={onClose}>
				თქვენ წარმატებით გაწევრდით
			</FancyPopup>
		);
	}
);
