import React from "react";
import footerStyles from "./styles/landing-footer.module.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useLocale } from "@app/hooks/intl";
import { FormattedMessage } from "react-intl";

const LandingFooter = () => {
	const isGeo = useLocale() === "ka";

	return (
		<footer
			className={footerStyles.footer}
			style={{ paddingBottom: isGeo ? 0 : 40 }}
		>
			<div className={footerStyles.footer_container}>
				{isGeo && <FriendlyPlatformsContainer />}
				<div className={footerStyles.lineWrapper}></div>
				<div className={footerStyles.terms}>
					<Link to="/confidentiality" target="_blank">
						<FormattedMessage id="footer.confidentiality" />
					</Link>
					<span></span>

					<Link to="/terms" target="_blank">
						<FormattedMessage id="footer.terms" />
					</Link>
				</div>
				<p className={footerStyles.footer_date}>
					&copy; <FormattedMessage id="footer.copyRight" />
				</p>
			</div>
		</footer>
	);
};

const FriendlyPlatform: React.FC<{ href: string; logoClassNames: string }> = ({
	href,
	logoClassNames,
}) => {
	return (
		<a
			href={href}
			target="_blank"
			rel="noreferrer noopener"
			className={footerStyles.friendlyPlatforms_item}
		>
			<span className={logoClassNames}></span>
		</a>
	);
};

const FriendlyPlatformsContainer: React.FC<{}> = ({}) => {
	return (
		<>
			<h1 className={footerStyles.footer_title}>
				მეგობარი საგანმანათლებლო პლატფორმები
			</h1>
			<div className={footerStyles.friendlyPlatforms_container}>
				<FriendlyPlatform
					href={"https://lupi.ge/?a=002#/"}
					logoClassNames={classnames(
						footerStyles.brand_logo,
						footerStyles.brand_lupi
					)}
				/>
				<FriendlyPlatform
					href={"https://prep.ge/?a=002#/"}
					logoClassNames={classnames(
						footerStyles.brand_logo,
						footerStyles.brand_prep
					)}
				/>
				<FriendlyPlatform
					href={"https://murtsku.com/?a=002#/"}
					logoClassNames={classnames(
						footerStyles.brand_logo,
						footerStyles.brand_murtsku
					)}
				/>
				<FriendlyPlatform
					href={"https://hubu.ge/?a=002#/"}
					logoClassNames={classnames(
						footerStyles.brand_logo,
						footerStyles.brand_hubu
					)}
				/>
				<FriendlyPlatform
					href={"https://unicatalog.ge/?a=002#/"}
					logoClassNames={classnames(
						footerStyles.brand_logo,
						footerStyles.brand_unicatalog
					)}
				/>
			</div>
		</>
	);
};

export default LandingFooter;
