import { IRequest } from "../requests";
import { IRGETUserView, RGETUserViewSchema, IAPUTView } from "./validators";
import { inject } from "@app/modules";
import { IUserView } from "./helper-schemas";
import { UserView } from "@app/models/user-view";

export class UserViewsController {
	private readonly Request: IRequest;

	private readonly _UserViewModel = inject("UserViewModel");
	private readonly assertAndGetClassroomsUser = inject(
		"assertAndGetClassroomsUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	async getUserViews(): Promise<UserView> {
		const user = this.assertAndGetClassroomsUser();
		const userView = this._UserViewModel.findByIdSync(user.id);
		if (userView) {
			return userView;
		}
		return this.Request.send(
			"GET",
			"/api/user-views",
			undefined,
			undefined,
			{
				responseSchema: RGETUserViewSchema,
			}
		).then((data: IRGETUserView) => {
			if (data.hasFound) {
				return this._UserViewModel.loadOneSync(data.userView);
			}
			const doc: IUserView = {
				userId: user.id,
				createdAt: new Date(),
				updatedAt: new Date(),
			};
			return this._UserViewModel.loadOneSync(doc);
		});
	}

	async updateView(args: IAPUTView) {
		const user = this.assertAndGetClassroomsUser();
		return this.Request.send("PUT", "/api/user-views/", args).then(() => {
			this._UserViewModel.updateView(user.id, args);
		});
	}
}
