import React from "react";
import { HeaderTree } from "./tree";
import styles from "./styles.module.scss";

const OuterContainer = React.memo<{}>(function OuterContainer({}) {
	const colors = HeaderTree.data.colors.use();
	const color = colors.text;
	return (
		<>
			<div
				style={{
					background: colors.background,
					color,
				}}
				className={styles.header}
			>
				<HeaderTree.components.LeftContainer />
				<HeaderTree.components.RightContainer />
			</div>
			<div className={styles.containerPlaceholder} />
		</>
	);
});

const LeftContainer = React.memo<{}>(function LeftContainer({}) {
	return (
		<div className={styles.leftContainer}>
			<HeaderTree.components.Logo />
			<HeaderTree.components.NextToLogo />
		</div>
	);
});

const RightContainer = React.memo<{}>(function RightContainer({}) {
	const useBurgerMenu = HeaderTree.data.useBurgerMenu.use();
	return (
		<div className={styles.rightContainer}>
			{!useBurgerMenu && <HeaderTree.components.profile.Container />}
			<HeaderTree.components.Chat />
			{!useBurgerMenu && <HeaderTree.components.Logout />}
			{useBurgerMenu && <HeaderTree.components.BurgerMenu />}
		</div>
	);
});

export const Containers = {
	OuterContainer,
	LeftContainer,
	RightContainer,
};
