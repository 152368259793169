import React, { useCallback, useState, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
	VerticalLabelContainer,
	HorizontalLabelContainer,
} from "@app/components/ui/containers";
import styled from "@emotion/styled";
import scss from "./styles/Upload.module.scss";
import { useInputChangeHandler } from "@app/hooks/inputs";

type InputWithLabelProps = {
	label?: string | null;
	value?: string | null;
	placeholder?: string;
	type?: "text" | "password" | "number" | "textarea";
	error?: string | null;
	direction?: "vertical" | "horizontal";
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
} & (
	| {
			onChange: (value: string) => void;
			nullOnEmpty?: false;
	  }
	| {
			onChange: (value: string | null) => void;
			nullOnEmpty: true;
	  }
);

export const InputWithLabel = React.memo((props: InputWithLabelProps) => {
	const {
		onChange,
		nullOnEmpty,
		value,
		placeholder,
		disabled,
		type,
		label,
		error,
		...rest
	} = props;
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (nullOnEmpty) {
				onChange((e.target.value || null) as string);
			} else {
				onChange(e.target.value);
			}
		},
		[nullOnEmpty, onChange]
	);

	const hasError = typeof props.error === "string";

	const component =
		props.type === "textarea" ? (
			<RawTextarea
				value={value || ""}
				onChange={handleChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
		) : (
			<RawInput
				value={value || ""}
				hasError={hasError}
				type={type}
				onChange={handleChange}
				placeholder={placeholder}
				disabled={disabled}
				{...rest}
			/>
		);
	if (props.direction === "horizontal") {
		return (
			<HorizontalLabelContainer
				leftLabel={label}
				rightLabel={error}
				hasError={hasError}
			>
				{component}
			</HorizontalLabelContainer>
		);
	}
	return (
		<VerticalLabelContainer
			topLabel={label}
			bottomLabel={error}
			hasError={hasError}
		>
			{component}
		</VerticalLabelContainer>
	);
});

type NumberInputWithLabelProps = {
	label?: string | null;
	defaultValue?: number | null;
	value?: number | null | undefined;
	placeholder?: string;
	inputProps?: { min?: number; max?: number };
	error?: string | null;
	direction?: "vertical" | "horizontal";
	disabled?: boolean;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
} & (
	| (
			| {
					zeroOnEmpty?: false;
					onChange: (value: number | null) => void;
					undefinedOnEmpty?: false;
			  }
			| {
					zeroOnEmpty?: false;
					onChange: (value: number | undefined) => void;
					undefinedOnEmpty: true;
			  }
	  )
	| {
			zeroOnEmpty: true;
			onChange: (value: number) => void;
			undefinedOnEmpty?: false;
	  }
);
export const NumberInputWithLabel = React.memo(
	(props: NumberInputWithLabelProps) => {
		const {
			onChange,
			zeroOnEmpty,
			label,
			defaultValue,
			value,
			inputProps,
			placeholder,
			undefinedOnEmpty,
			inputRef,
			disabled,
			...rest
		} = props;

		const handleChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				const emptyValue = undefinedOnEmpty ? undefined : null;
				onChange(
					!zeroOnEmpty && e.target.value === ""
						? (emptyValue as any)
						: +e.target.value
				);
			},
			[onChange, zeroOnEmpty, undefinedOnEmpty]
		);

		const hasError = typeof props.error === "string";
		const component = (
			<RawInput
				hasError={hasError}
				defaultValue={
					defaultValue === null ? "" : defaultValue?.toString()
				}
				onChange={handleChange}
				type="number"
				placeholder={placeholder}
				ref={inputRef}
				disabled={disabled}
				{...rest}
				{...inputProps}
				{...(value !== undefined
					? { value: typeof value === "number" ? value : "" }
					: {})}
			/>
		);

		if (props.direction === "horizontal") {
			return (
				<HorizontalLabelContainer
					leftLabel={label}
					rightLabel={props.error}
					hasError={hasError}
				>
					{component}
				</HorizontalLabelContainer>
			);
		}
		return (
			<VerticalLabelContainer
				topLabel={label}
				bottomLabel={props.error}
				hasError={hasError}
			>
				{component}
			</VerticalLabelContainer>
		);
	},
	(prevProps, nextProps) =>
		prevProps.label === nextProps.label &&
		prevProps.placeholder === nextProps.placeholder &&
		prevProps.onChange === nextProps.onChange &&
		prevProps.value === nextProps.value
);

export const CheckboxWithLabel = React.memo(
	(props: {
		label: string;
		value: boolean;
		onChange: (value: boolean) => void;
		isDisabled?: boolean;
	}) => {
		const { onChange } = props;
		const handleChange = useCallback(
			(e, checked: boolean) => {
				onChange(checked);
			},
			[onChange]
		);

		return (
			<FormControlLabel
				control={
					<Checkbox checked={props.value} onChange={handleChange} />
				}
				label={props.label}
				disabled={!!props.isDisabled}
			/>
		);
	}
);

interface InputProps {
	hasError?: boolean;
}

export const RawInput = styled.input`
	border: 1px solid
		${(props: InputProps) => (!props.hasError ? "#444" : "#ea2424")};
	height: 38px;
	${(props: InputProps) =>
		props.hasError ? "background-color: #FFD9D9; color: #ea2424;" : ""}
	border-radius: 4px;
	padding: 2px 8px;
	width: 100%;
	outline: none;
	&:focus {
		box-shadow: 0 0 1px
			${(props: InputProps) => (!props.hasError ? "#333" : "#ea2424")};
	}

	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const RawTextarea = styled.textarea`
	border: 1px solid
		${(props: InputProps) => (!props.hasError ? "#444" : "#ea2424")};
	height: 60px;
	resize: none;
	${(props: InputProps) =>
		props.hasError ? "background-color: #FFD9D9; color: #ea2424;" : ""}
	border-radius: 4px;
	padding: 2px 8px;
	width: 100%;
	outline: none;
	&:focus {
		box-shadow: 0 0 1px
			${(props: InputProps) => (!props.hasError ? "#333" : "#ea2424")};
	}

	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

export const EditingInput: React.FC<{
	defaultValue: string | number | null;
	placeholder?: string;
	onApprove: (newValue: string) => void;
	onReject: () => void;
	hasError?: boolean;
	autoFocus?: boolean;
	className?: string;
	displayErrorOnlyIfEmptyValue?: boolean;
	approveOnBlurOfNonEmptyValue?: boolean;
}> = React.memo(
	({
		defaultValue,
		onApprove,
		onReject,
		placeholder,
		hasError,
		autoFocus,
		className,
		displayErrorOnlyIfEmptyValue,
		approveOnBlurOfNonEmptyValue,
	}) => {
		const [value, setValue] = useState(
			defaultValue === null ? "" : defaultValue + ""
		);
		const valueRef = useRef(value);
		valueRef.current = value;

		const handleValueChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) =>
				setValue(e.target.value),
			[]
		);

		const handleKeydown = useCallback(
			(e: React.KeyboardEvent<HTMLInputElement>) => {
				if (e.key === "Enter") {
					onApprove(valueRef.current);
				} else if (e.key === "Esc" || e.key === "Escape") {
					onReject();
				}
			},
			[onApprove, onReject]
		);

		const handleBlur = useCallback(() => {
			if (!approveOnBlurOfNonEmptyValue) return;
			onApprove(valueRef.current);
		}, [approveOnBlurOfNonEmptyValue, onApprove]);

		return (
			<RawInput
				value={value}
				className={className}
				onChange={handleValueChange}
				placeholder={placeholder}
				onKeyDown={handleKeydown}
				hasError={
					displayErrorOnlyIfEmptyValue
						? !value
							? hasError
							: false
						: hasError
				}
				autoFocus={autoFocus}
				onBlur={handleBlur}
			/>
		);
	}
);

type SimpleInputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange"> & {
	onChange: (value: string) => void;
};

export const SimpleInput = React.memo<SimpleInputProps>(
	({ onChange, ...props }) => {
		const handleChange = useInputChangeHandler(onChange);
		return <input {...props} onChange={handleChange} />;
	}
);

type SimpleTextAreaProps = Omit<
	React.HTMLProps<HTMLTextAreaElement>,
	"onChange"
> & {
	onChange: (value: string) => void;
};

export const SimpleTextArea = React.memo<SimpleTextAreaProps>(
	({ onChange, ...props }) => {
		const handleChange = useInputChangeHandler(onChange);
		return <textarea {...props} onChange={handleChange} />;
	}
);
