import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import {
	LessonTimeSchema,
	ILessonTime,
	ISchoolLessonSchedule,
} from "./helper-schemas";

export const APUTSchoolLessonScheduleSchema = Joi.object({
	schoolId: UnsignedIntegerSchema.required(),
	times: Joi.array()
		.items(LessonTimeSchema)
		.required(),
});
export interface IAPUTSchoolLessonSchedule {
	schoolId: number;
	times: ILessonTime[];
}

export type IRPUTSchoolLessonSchedule = void;

export const AGETSchoolLessonScheduleSchema = Joi.object({
	schoolId: UnsignedIntegerSchema.required(),
});
export interface IAGETSchoolLessonSchedule {
	schoolId: number;
}

export type IRGETSchoolLessonSchedule = ISchoolLessonSchedule;
