import { useEffect } from "react";
import { useLocationQuery } from "@app/hooks/front";
import triggerEvent from "@app/utils/events";

export const Events = () => {
	const query = useLocationQuery(true);
	const userIdFromQuery = query ? query.u || null : null;
	const actionSuffix = query ? query.as || null : null;
	useEffect(() => {
		if (!userIdFromQuery) return;
		triggerEvent(
			{
				category: "Query",
				action: "View page",
				label: (actionSuffix as string) || "",
			},
			{
				userId: +userIdFromQuery,
				page: window.location.href,
			}
		);
	}, [actionSuffix, userIdFromQuery]);
	return null;
};
