import React from "react";
import styles from "./styles/cognitive-assignments.module.css";
import { useMyCognitiveClassroomsAsStudent } from "@app/hooks/classrooms";
import { useGoToUrl } from "@app/hooks/front";
import { studentLinks } from "@app/routes/student/links";

export const CognitiveBox: React.FC<{}> = () => {
	const myCognitiveClassrooms = useMyCognitiveClassroomsAsStudent();

	const redirectToTestsPage = useGoToUrl(studentLinks.cognitiveTests);

	if (!myCognitiveClassrooms) return null;

	return (
		<div className={styles.boxContainer} onClick={redirectToTestsPage}>
			<img
				alt="buld"
				src="/imgs/cognitive-bulb.svg"
				className={styles.sendCognitiveAssignmentBuld}
			/>
			<div style={{ marginTop: 10 }}>შემეცნებითი ტესტები</div>
		</div>
	);
};
