import { ObjectId } from "@app/utils/generics";
import { ITargetConcept } from "@app/api/assignments/complex/helper-schemas";
import { FormatedLink } from "./assignmentContent";

export const getChosenConceptsResults = (
	targetConcepts: ITargetConcept[],
	chosenConcepts: ObjectId[]
): ObjectId[] => {
	const res: ObjectId[] = [];
	targetConcepts.forEach(e => {
		const isChosen = chosenConcepts.find(x => x === e._id);
		if (isChosen) {
			e.resultIds.forEach(result => {
				if (!res.find(x => x === result)) {
					res.push(result);
				}
			});
		}
	});
	return res;
};

export const refactorValueAfterLinkChange = (
	formatedLinks: FormatedLink[],
	value: string
) => {
	let parsed = value.replace(/\n+/gi, "").replace(/\s+/gi, " ");
	let index = 0;

	while (parsed.substr(index).indexOf(`<a id="`) >= 0) {
		const start = parsed.substr(index).indexOf(`<a id="`) + index;
		const end = parsed.substr(start + index).indexOf("a>") + start + 2;
		const element = parsed.substring(start, end);
		const startOfId = start + 7;
		const lengthOfId = parsed.substr(startOfId).indexOf(`"`);
		const id = parsed.substr(startOfId, lengthOfId);
		const link = formatedLinks[Number(id)];
		if (link) {
			const href = CreateLink({ link, index: Number(id) });
			console.log(href);
			if (href !== element) {
				parsed = (
					parsed.substring(0, start) +
					CreateLink({ link, index: Number(id) }) +
					parsed.substr(
						parsed.substr(start).indexOf("</a>") + 4 + start
					)
				)
					.replace(/\n+/gi, "")
					.replace(/\s+/gi, " ");
			}
		}
		index = parsed.substr(start + index).indexOf("a>") + start + 2;
	}

	return parsed;
};

export const CreateLink = ({
	link,
	index,
}: {
	link: FormatedLink;
	index: number;
}) => {
	return `<a
			id="${index}"
			href={${link.link}}
			target="_blank"
			rel="noopener noreferrer"
		>${link.label}</a>`;
};
