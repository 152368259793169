/* eslint-disable max-lines */
import { UserType } from "@app/api/helper-schemas";
import UserRoute from "@app/routes/containers/user-route";
import { createRouteList } from "../config";
import { CommonRouteProps, defaultRouteMapper } from "../route-props-map-utils";
import { defaultComponentWrapper } from "../wrapper";
import { adminLinks } from "./links";
import { ReplaceBcAdmin } from "../../components/admin/classrooms/replace-bc-admin";

const routes = createRouteList<CommonRouteProps>({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		dontRedirectIfNotAuthenticated: true,
		availableUserTypes: UserType.mainAdmin,
	},
	componentWrapper: defaultComponentWrapper,
	routeMapFn: defaultRouteMapper,
});

///

const AdminMainPage = () =>
	import("@app/components/admin").then(module => ({
		default: module.AdminMainPage,
	}));

routes.add({
	path: adminLinks.mainPage.routePath,
	component: AdminMainPage,
	avoidSwitch: true,
});

///

const ClassroomsBulkDeletePage = () =>
	import("@app/components/admin/classrooms/bulk-delete").then(module => ({
		default: module.ClassroomsBulkDeletePage,
	}));

routes.add({
	path: adminLinks.classrooms.bulkDelete.routePath,
	component: ClassroomsBulkDeletePage,
});

///

const ChangeClassroomSchools = () =>
	import("@app/components/admin/classrooms/change-schools").then(module => ({
		default: module.ChangeClassroomSchools,
	}));

routes.add({
	path: "update-classroom-schools",
	component: ChangeClassroomSchools,
});

///

const ClassTimesImport = () =>
	import("@app/components/admin/classrooms/class-times").then(module => ({
		default: module.ClassTimesImport,
	}));

routes.add({
	path: adminLinks.classrooms.importClassTimes.routePath,
	component: ClassTimesImport,
});

///

const CreatePremiumClassrooms = () =>
	import(
		"@app/components/admin/classrooms/premium-classrooms"
	).then(module => ({ default: module.CreatePremiumClassrooms }));

routes.add({
	path: adminLinks.classrooms.premium.routePath,
	component: CreatePremiumClassrooms,
});

///

const AdminCognitiveAssignmentsList = () =>
	import("@app/components/admin/cognitive-assignments").then(module => ({
		default: module.AdminCognitiveAssignmentsList,
	}));

routes.add({
	path: adminLinks.cognitiveAssignments.list.routePath,
	component: AdminCognitiveAssignmentsList,
});

///

const CognitiveAssignmentStatsContainer = () =>
	import(
		"@app/components/admin/cognitive-assignments/stats/container"
	).then(module => ({ default: module.CognitiveAssignmentStatsContainer }));

routes.add({
	path: adminLinks.cognitiveAssignments.stats.routePath,
	component: CognitiveAssignmentStatsContainer,
});

///

const ComplexAssignmentUtils = () =>
	import("@app/components/admin/complex-assignments").then(module => ({
		default: module.ComplexAssignmentUtils,
	}));

routes.add({
	path: adminLinks.complexAssignments.utils.routePath,
	component: ComplexAssignmentUtils,
});

///

const CreateComplexAdmin = () =>
	import("@app/components/admin/complex-assignments/create").then(module => ({
		default: module.CreateComplexAdmin,
	}));

routes.add({
	path: adminLinks.complexAssignments.create.routePath,
	component: CreateComplexAdmin,
});

///

const TeacherCreatedComplexAssignments = () =>
	import("@app/components/admin/complex-assignments/review").then(module => ({
		default: module.TeacherCreatedComplexAssignments,
	}));

routes.add({
	path: adminLinks.complexAssignments.review.list.routePath,
	component: TeacherCreatedComplexAssignments,
});

///

const ReviewComplexAssignment = () =>
	import(
		"@app/components/admin/complex-assignments/review/review-complex-assignment"
	).then(module => ({ default: module.ReviewComplexAssignment }));

routes.add({
	path: adminLinks.complexAssignments.review.test.routePath,
	component: ReviewComplexAssignment,
});

///

const ReviewComplexAssignments = () =>
	import(
		"@app/components/admin/complex-assignments/review/review-complex-assignments"
	).then(module => ({ default: module.ReviewComplexAssignments }));

routes.add({
	path: adminLinks.complexAssignments.review.courseTests.routePath,
	component: ReviewComplexAssignments,
});

///

const VerifiedComplexAssignments = () =>
	import(
		"@app/components/admin/complex-assignments/review/verified-complex-assignments"
	).then(module => ({ default: module.VerifiedComplexAssignments }));

routes.add({
	path: adminLinks.complexAssignments.review.verifiedCourseTests.routePath,
	component: VerifiedComplexAssignments,
});

///
const AdminAssignmentResultsList = () =>
	import(
		"@app/components/admin/complex-assignments/utils/results/list"
	).then(module => ({ default: module.AdminAssignmentResultsList }));

routes.add({
	path: adminLinks.complexAssignments.results.list.routePath,
	component: AdminAssignmentResultsList,
});

///

const SaveComplexAssignmentResultGroups = () =>
	import(
		"@app/components/admin/complex-assignments/utils/results/save"
	).then(module => ({ default: module.SaveComplexAssignmentResultGroups }));

routes.add({
	path: adminLinks.complexAssignments.results.create.routePath,
	component: SaveComplexAssignmentResultGroups,
});

routes.add({
	path: adminLinks.complexAssignments.results.edit.routePath,
	component: SaveComplexAssignmentResultGroups,
});

///

const AdminTargetConceptsList = () =>
	import(
		"@app/components/admin/complex-assignments/utils/target-concepts/list"
	).then(module => ({ default: module.AdminTargetConceptsList }));

routes.add({
	path: adminLinks.complexAssignments.targetConcepts.list.routePath,
	component: AdminTargetConceptsList,
});

///

const SaveTargetConcept = () =>
	import(
		"@app/components/admin/complex-assignments/utils/target-concepts/save"
	).then(module => ({ default: module.SaveTargetConcept }));

routes.add({
	path: adminLinks.complexAssignments.targetConcepts.create.routePath,
	component: SaveTargetConcept,
});

routes.add({
	path: adminLinks.complexAssignments.targetConcepts.edit.routePath,
	component: SaveTargetConcept,
});

///
const AdminAssignmentTopicsList = () =>
	import(
		"@app/components/admin/complex-assignments/utils/topics/list"
	).then(module => ({ default: module.AdminAssignmentTopicsList }));

routes.add({
	path: adminLinks.complexAssignments.topics.list.routePath,
	component: AdminAssignmentTopicsList,
});

///

const SaveAssignmentTopic = () =>
	import(
		"@app/components/admin/complex-assignments/utils/topics/save"
	).then(module => ({ default: module.SaveAssignmentTopic }));

routes.add({
	path: adminLinks.complexAssignments.topics.create.routePath,
	component: SaveAssignmentTopic,
});

routes.add({
	path: adminLinks.complexAssignments.topics.edit.routePath,
	component: SaveAssignmentTopic,
});

///

const AdminCoursesList = () =>
	import("@app/components/admin/courses").then(module => ({
		default: module.AdminCoursesList,
	}));

routes.add({
	path: adminLinks.courses.list.routePath,
	component: AdminCoursesList,
});

///

const SaveCourse = () =>
	import("@app/components/admin/courses/save").then(module => ({
		default: module.SaveCourse,
	}));

routes.add({
	path: adminLinks.courses.create.routePath,
	component: SaveCourse,
});

routes.add({
	path: adminLinks.courses.edit.routePath,
	component: SaveCourse,
});

///

const FeedbackList = () =>
	import("@app/components/admin/feedbacks/list").then(module => ({
		default: module.FeedbackList,
	}));

routes.add({
	path: adminLinks.feedbacks.list.routePath,
	component: FeedbackList,
});

///

const CreateFeedback = () =>
	import("@app/components/admin/feedbacks/create").then(module => ({
		default: module.CreateFeedback,
	}));

routes.add({
	path: adminLinks.feedbacks.create.routePath,
	component: CreateFeedback,
});

///

const UpdateFeedback = () =>
	import("@app/components/admin/feedbacks/update").then(module => ({
		default: module.UpdateFeedback,
	}));

routes.add({
	path: adminLinks.feedbacks.edit.routePath,
	component: UpdateFeedback,
});

///

const AdminCategoriesList = () =>
	import(
		"@app/components/admin/grades-and-categories/categories/list"
	).then(module => ({ default: module.AdminCategoriesList }));

routes.add({
	path: adminLinks.gradeCategories.list.routePath,
	component: AdminCategoriesList,
});

///

const SaveCategory = () =>
	import(
		"@app/components/admin/grades-and-categories/categories/save"
	).then(module => ({ default: module.SaveCategory }));

routes.add({
	path: adminLinks.gradeCategories.crate.routePath,
	component: SaveCategory,
});

routes.add({
	path: adminLinks.gradeCategories.edit.routePath,
	component: SaveCategory,
});

///

const AdminGradesList = () =>
	import(
		"@app/components/admin/grades-and-categories/grades/list"
	).then(module => ({ default: module.AdminGradesList }));

routes.add({
	path: adminLinks.grades.list.routePath,
	component: AdminGradesList,
});

///

const SaveGrade = () =>
	import(
		"@app/components/admin/grades-and-categories/grades/save"
	).then(module => ({ default: module.SaveGrade }));

routes.add({
	path: adminLinks.grades.create.routePath,
	component: SaveGrade,
});

routes.add({
	path: adminLinks.grades.edit.routePath,
	component: SaveGrade,
});

///

const LinkRequestsList = () =>
	import("@app/components/admin/link-requests").then(module => ({
		default: module.LinkRequestsList,
	}));

routes.add({
	path: adminLinks.linkRequests.list.routePath,
	component: LinkRequestsList,
});

///

const ReservationsList = () =>
	import(
		"@app/components/admin/offer-reservations/reservations"
	).then(module => ({ default: module.ReservationsList }));

routes.add({
	path: adminLinks.offerReservations.routePath,
	component: ReservationsList,
});

///

const ProposalList = () =>
	import("@app/components/admin/proposals").then(module => ({
		default: module.ProposalList,
	}));

routes.add({
	path: adminLinks.proposals.routePath,
	component: ProposalList,
});

///

const ReviewCoursesList = () =>
	import("@app/components/admin/review").then(module => ({
		default: module.ReviewCoursesList,
	}));

routes.add({
	path: adminLinks.publicTests.courseList.routePath,
	component: ReviewCoursesList,
});

///

const RevievwTest = () =>
	import("@app/components/admin/review/review-test").then(module => ({
		default: module.RevievwTest,
	}));

routes.add({
	path: adminLinks.publicTests.review.test.routePath,
	component: RevievwTest,
});

///

const ReviewTests = () =>
	import("@app/components/admin/review/review-tests").then(module => ({
		default: module.ReviewTests,
	}));

routes.add({
	path: adminLinks.publicTests.review.course.routePath,
	component: ReviewTests,
});

///

const VerifiedTests = () =>
	import("@app/components/admin/review/verified-test").then(module => ({
		default: module.VerifiedTests,
	}));

routes.add({
	path: adminLinks.publicTests.review.courseVerifiedTests.routePath,
	component: VerifiedTests,
});

///

const ImportClassroomsPage = () =>
	import("@app/components/admin/schools/import-classrooms").then(module => ({
		default: module.ImportClassroomsPage,
	}));

routes.add({
	path: adminLinks.classrooms.import.routePath,
	component: ImportClassroomsPage,
});

///

const ImportClassroomLinksPage = () =>
	import("@app/components/admin/schools/import-links").then(module => ({
		default: module.ImportClassroomLinksPage,
	}));

routes.add({
	path: adminLinks.videoLessons.import.routePath,
	component: ImportClassroomLinksPage,
});

///

const AdminUserStats = () =>
	import("@app/components/admin/stats").then(module => ({
		default: module.AdminUserStats,
	}));

routes.add({
	path: adminLinks.users.stats.routePath,
	component: AdminUserStats,
});

///

const FixStudentsInfo = () =>
	import("@app/components/admin/students-info/fix").then(module => ({
		default: module.FixStudentsInfo,
	}));

routes.add({
	path: adminLinks.students.fixStudentsInfo.routePath,
	component: FixStudentsInfo,
});

///

const AdminSubjectsList = () =>
	import("@app/components/admin/subjects/list").then(module => ({
		default: module.AdminSubjectsList,
	}));

routes.add({
	path: adminLinks.subjects.list.routePath,
	component: AdminSubjectsList,
});

///

const SaveSubject = () =>
	import("@app/components/admin/subjects/save").then(module => ({
		default: module.SaveSubject,
	}));

routes.add({
	path: adminLinks.subjects.create.routePath,
	component: SaveSubject,
});

routes.add({
	path: adminLinks.subjects.edit.routePath,
	component: SaveSubject,
});

///

const UserBulkDeletePage = () =>
	import("@app/components/admin/users/bulk-delete").then(module => ({
		default: module.UserBulkDeletePage,
	}));

routes.add({
	path: adminLinks.users.bulkDelete.routePath,
	component: UserBulkDeletePage,
});

///

const UserGetBulkPage = () =>
	import("@app/components/admin/users/get-bulk").then(module => ({
		default: module.UserGetBulkPage,
	}));

routes.add({
	path: adminLinks.users.bulkGet.routePath,
	component: UserGetBulkPage,
});

///

const AdminCitiesList = () =>
	import("@app/components/admin/cities/list").then(module => ({
		default: module.AdminCitiesList,
	}));

routes.add({
	path: adminLinks.cities.list.routePath,
	component: AdminCitiesList,
});

///

const AdminSaveCity = () =>
	import("@app/components/admin/cities/save").then(module => ({
		default: module.SaveCity,
	}));

routes.add({
	path: adminLinks.cities.add.routePath,
	component: AdminSaveCity,
});

routes.add({
	path: adminLinks.cities.edit.routePath,
	component: AdminSaveCity,
});

///

const AdminSchoolsList = () =>
	import("@app/components/admin/schools/list").then(module => ({
		default: module.AdminSchoolsList,
	}));

routes.add({
	path: adminLinks.schools.list.routePath,
	component: AdminSchoolsList,
});

///

const AdminSaveSchool = () =>
	import("@app/components/admin/schools/save").then(module => ({
		default: module.SaveSchool,
	}));

routes.add({
	path: adminLinks.schools.add.routePath,
	component: AdminSaveSchool,
});

routes.add({
	path: adminLinks.schools.edit.routePath,
	component: AdminSaveSchool,
	hideHeader: true,
});

///

const AdminGradebookCategoryList = () =>
	import("@app/components/admin/gradebook/categories/list").then(module => ({
		default: module.AdminGradebookCategoryList,
	}));

routes.add({
	path: adminLinks.gradebookCategories.list.routePath,
	component: AdminGradebookCategoryList,
});

///

const SaveGradebookGradeCategory = () =>
	import("@app/components/admin/gradebook/categories/save").then(module => ({
		default: module.SaveGradebookGradeCategory,
	}));

routes.add(
	{
		path: adminLinks.gradebookCategories.create.routePath,
		component: SaveGradebookGradeCategory,
	},
	{
		path: adminLinks.gradebookCategories.edit.routePath,
		component: SaveGradebookGradeCategory,
	}
);

///
const SchoolRegisterPage = () =>
	import("../../components/auth/school-registration-page").then(module => ({
		default: module.SchoolRegisterPage,
	}));

routes.add({
	path: "/school-registration",
	component: SchoolRegisterPage,
});

///
const CreateBlog = () =>
	import("../../components/users/blog/create-blog").then(module => ({
		default: module.CreateBlog,
	}));

routes.add({
	path: "/create-blog",
	component: CreateBlog,
});

///

const AddHeadmaster = () =>
	import("../../components/admin/headmasters/add").then(module => ({
		default: module.AddHeadmaster,
	}));

routes.add({
	path: adminLinks.headmasters.add.routePath,
	component: AddHeadmaster,
});

///

const SaveLessonSchedule = () =>
	import("../../components/admin/lessons-schedule/save").then(module => ({
		default: module.SaveLessonsSchedule,
	}));

routes.add({
	path: adminLinks.lessonSchedule.add.routePath,
	component: SaveLessonSchedule,
});

///

const SatestoResponsive = () =>
	import("../../components/admin/satesto").then(module => ({
		default: module.SatestoResponsive,
	}));

routes.add({
	path: "st",
	component: SatestoResponsive,
});

///

const AdminFinalsBalance = () =>
	import("../../components/admin/finals/balance").then(module => ({
		default: module.AdminFinalsBalance,
	}));

routes.add({
	path: adminLinks.finals.balance.routePath,
	component: AdminFinalsBalance,
});

///

const ContentCountPage = () =>
	import("../../components/admin/content-count").then(module => ({
		default: module.ContentCountPage,
	}));

routes.add({
	path: adminLinks.contentCount.routePath,
	component: ContentCountPage,
});

///

const FindUser = () =>
	import("../../components/admin/find-user/index").then(module => ({
		default: module.searchUser,
	}));
routes.add({
	path: "findUser",
	component: FindUser,
});

///

const ParentsAndChildren = () =>
	import("../../components/admin/parents-and-children/index").then(
		module => ({
			default: module.ParentsAndChildrenAdmin,
		})
	);

routes.add({
	path: adminLinks.parents.childrenInfo.routePath,
	component: ParentsAndChildren,
});

///

const OnlinePaymentsExportPage = () =>
	import("../../components/admin/export-online-payments/index").then(
		module => ({
			default: module.onlinePaymentsExportPage,
		})
	);

routes.add({
	path: adminLinks.export.exportOnlinePayments.routePath,
	component: OnlinePaymentsExportPage,
});

///

const AdminSummonses = () =>
	import("../../components/admin/summonses/index").then(module => ({
		default: module.AdminSummonses,
	}));

routes.add({
	path: adminLinks.summonses.list.routePath,
	component: AdminSummonses,
});

///

const AdminConferencesList = () =>
	import("../../components/admin/conferences/list").then(module => ({
		default: module.AdminConferencesList,
	}));

routes.add({
	path: adminLinks.conferences.list.routePath,
	component: AdminConferencesList,
});

///

const AdminConferenceSave = () =>
	import("../../components/admin/conferences/save").then(module => ({
		default: module.AdminConferenceSave,
	}));

routes.add({
	path: adminLinks.conferences.add.routePath,
	component: AdminConferenceSave,
});

routes.add({
	path: adminLinks.conferences.edit.routePath,
	component: AdminConferenceSave,
});
const ImportWeeklyBalanceAdmin = () =>
	import("../../components/admin/import-weekly-balance/index").then(
		module => ({
			default: module.ImportWeeklyBalanceAdmin,
		})
	);
routes.add({
	path: adminLinks.finals.bulkPayment.routePath,
	component: ImportWeeklyBalanceAdmin,
});
///

const premiumSchoolStats = () =>
	import("../../components/admin/schools/premium-stats/premium-stats").then(
		module => ({
			default: module.premiumSchoolStats,
		})
	);

routes.add({
	path: adminLinks.schools.partners.stats.routePath,
	component: premiumSchoolStats,
});

const ReplaceTeachers = () =>
	import("@app/components/admin/classrooms/replace-teachers").then(
		module => ({
			default: module.ChangeOwnership,
		})
	);

routes.add({
	path: adminLinks.classrooms.replaceTeachers.routePath,
	component: ReplaceTeachers,
});

const AddPassableSubject = () =>
	import("@app/components/admin/classrooms/add-passable").then(module => ({
		default: module.AddPassableSubject,
	}));

routes.add({
	path: adminLinks.classrooms.addPassableSubject.routePath,
	component: AddPassableSubject,
});

const EnrollStudent = () =>
	import("@app/components/admin/classrooms/enroll-student").then(module => ({
		default: module.EnrollStudent,
	}));

routes.add({
	path: adminLinks.classrooms.enrollStudentToClassroom.routePath,
	component: EnrollStudent,
});

const teacherInfo = () =>
	import("@app/components/admin/users/teacher-info").then(module => ({
		default: module.TeacherInfo,
	}));

routes.add({
	path: adminLinks.users.teacherInfo.routePath,
	component: teacherInfo,
});

const studentAssignmentInfo = () =>
	import("@app/components/admin/users/student-assignments-info").then(
		module => ({
			default: module.StudentAssignmentInfo,
		})
	);

routes.add({
	path: adminLinks.users.studentAssignmentsInfo.routePath,
	component: studentAssignmentInfo,
});

const DeleteClassrooms = () =>
	import("@app/components/admin/classrooms/delete-classrooms").then(
		module => ({
			default: module.DeleteClassrooms,
		})
	);

routes.add({
	path: adminLinks.classrooms.deleteGroupClassrooms.routePath,
	component: DeleteClassrooms,
});

const CometChat = () =>
	import("@app/components/admin/comet-chat/index").then(module => ({
		default: module.CometChatSettings,
	}));

routes.add({
	path: adminLinks.cometChat.routePath,
	component: CometChat,
});

const ReplaceBCAdmin = () =>
	import("@app/components/admin/classrooms/replace-bc-admin").then(
		module => ({
			default: module.ReplaceBcAdmin,
		})
	);

routes.add({
	path: adminLinks.groups.bc.routePath,
	component: ReplaceBCAdmin,
});

const ChangeStructure = () =>
	import("@app/components/admin/classrooms/school-structures").then(
		module => ({
			default: module.ChangeStructure,
		})
	);

routes.add({
	path: adminLinks.groups.structure.routePath,
	component: ChangeStructure,
});

const ArchiveClassrooms = () =>
	import("@app/components/admin/classrooms/archive").then(module => ({
		default: module.Archive,
	}));

routes.add({
	path: adminLinks.schools.archive.routePath,
	component: ArchiveClassrooms,
});

export default routes;
