import { IRCoursesUser, IRUser } from "@app/api/users/helper-schemas";
import { IOption } from "@app/components/widgets/select";
import { inject } from "@app/modules";
import { User } from "@app/user";
import { CoursesUser } from "@app/user/courses-user";
import { isTestUser } from "@app/utils/testing";
import { useModelDocById, useModelDocsByIds } from "m-model-react";
import { useCallback, useMemo } from "react";
import { useResourceLoading } from "./fetch";
import { useGoToUrl } from "./front";
import { useStoreProperty } from "./store";

export const useClassroomsUser = (): User | undefined => {
	const user = useStoreProperty("user");
	const getUserInstance = useCallback((userObj: IRUser) => {
		try {
			return User.createUserInstance(userObj);
		} catch (e) {
			const AuthController = inject("AuthController");
			AuthController.logout();
			return undefined;
		}
	}, []);
	return useMemo(() => {
		if (!user || !user.userData) return undefined;
		return getUserInstance(user.userData);
	}, [getUserInstance, user]);
};

export const useClassroomsUserId = (): number | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.id;
};

export const useMurtskuUserId = (): number | null | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.murtskuId;
};

export const useCoursesUser = (): CoursesUser | undefined => {
	const user = useStoreProperty("user");
	const getUserInstance = useCallback((userObj: IRCoursesUser) => {
		return CoursesUser.createUserInstance(userObj);
	}, []);
	return useMemo(() => {
		if (!user || !user.coursesUserData) return undefined;
		return getUserInstance(user.coursesUserData);
	}, [getUserInstance, user]);
};

export const useCoursesUserId = (): number | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.coursesUserData) return undefined;
	return user.coursesUserData.id;
};

export const useStudentInfo = () => {
	const userId = useClassroomsUserId();
	return useModelDocById(inject("StudentInfoModel"), userId || null);
};

export const useStudentInfoAsParent = (childId: number) => {
	const studentInfo = useModelDocById(
		inject("StudentInfoModel"),
		childId || null
	);
	return useResourceLoading(
		studentInfo,
		() => inject("UsersController").getChildInfo(childId!),
		[childId]
	).doc;
};

export const useUserShortInfo = (userId: number | null) => {
	const userShortInfo = useModelDocById(
		inject("UserInfoModel"),
		userId || null
	);

	return useResourceLoading(
		userShortInfo,
		() =>
			inject("UsersController").getUserShortInfo({
				userId: userId!,
			}),
		[userId]
	);
};

export const useManyUsersShortInfo = (userIds: number[] | null) => {
	const usersShortInfo = useModelDocsByIds(
		inject("UserInfoModel"),
		userIds || null
	);

	return useResourceLoading(
		usersShortInfo,
		() =>
			inject("UsersController").getUsersShortInfo({ userIds: userIds! }),
		[userIds]
	);
};

export const useHasUserAgreedOnTerms = (): boolean | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return false;
	return user.userData.hasAgreedOnTerms;
};

export const useIsTestUser = () => {
	const user = useClassroomsUser();
	return isTestUser(user);
};

export const userToOption = <
	T extends Record<"id" | "firstname" | "lastname", any>
>(
	e: T
) => ({
	value: e.id,
	label: `${e.firstname} ${e.lastname}`,
});

export function useUserOptions(
	users: Record<"id" | "firstname" | "lastname", any>[]
): IOption<number>[];
export function useUserOptions(
	users: Record<"id" | "firstname" | "lastname", any>[] | null | undefined
): IOption<number>[] | null;
export function useUserOptions(
	users: Record<"id" | "firstname" | "lastname", any>[] | null | undefined
): IOption<number>[] | null {
	return useMemo(() => (!users ? null : users.map(userToOption)), [users]);
}

export const useLogoutFn = () => {
	const gotoMainPage = useGoToUrl("/");
	return useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				///
			});
		gotoMainPage();
	}, [gotoMainPage]);
};
