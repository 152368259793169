import { IInviteFriendRequest } from "./validators";
import { IRequest } from "../requests";
import { forEachTag } from "@tests-core/components/editor/html/dom-utils";

export default class FriendInvitationController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}
	sendInvitationToFriend = (args: IInviteFriendRequest) => {
		this.Request.send(
			"POST",
			"/api/invite-friend/send-invitation-to-friend",
			args
		);
	};
	sendInvitationToFriends = (args: IInviteFriendRequest[]) => {
		this.Request.send(
			"POST",
			"/api/invite-friend/send-invitation-to-friends",
			args
		);
	};
}
