import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	useRef,
} from "react";
import styles from "./styles/index.module.css";
import selectorStyles from "./styles/selectors-styles.module.css";
import Select, { components } from "react-select";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

import AddIcon from "@material-ui/icons/Add";
import { TargetIcon, SearchWQuestionIcon, CloseIcon } from "@app/icons";
import { ActionMeta } from "react-select";
import { ObjectId } from "@app/utils/generics";
import styled from "@emotion/styled";
import {
	useComplexAssignmentTargetConcepts,
	useComplexAssignmentResults,
} from "@app/hooks/complex-assignments";
import { useWindowSize } from "@app/hooks/front";
import { generateFakeObjectId } from "@app/utils/common";
import classnames from "classnames";
import { inject } from "@app/modules";
import { IRGETComplexAssignmentTopics } from "@app/api/assignments/complex/validators";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { getChosenConceptsResults } from "./helper-functions";
import Tooltip from "@material-ui/core/Tooltip";
import { containsNonSpaceSymbols } from "../utils";

interface OptionType {
	label: string;
	value: string;
}

enum SelectPlaceHolders {
	create = "ჩაწერეთ ახალი საკითხი",
	select = "აირჩიეთ საკითხი",
}

type OptionsType = OptionType[];

const customSelectStyles = ({
	width,
	height,
	hideTextCaret,
	hideIndicator,
	marginValueContainerLeft,
}: {
	width: number;
	height?: number;
	hideTextCaret?: boolean;
	hideIndicator?: boolean;
	marginValueContainerLeft?: boolean;
}): StylesConfig => {
	return {
		option: provided => ({
			...provided,
			padding: "7px 10px",
			borderRadius: "10px",
			cursor: "pointer",
		}),
		container: provided => ({
			...provided,
			width: `${width}px`,
		}),
		menu: provided => ({
			...provided,
			padding: "5px",
			borderRadius: "16px",
			fontSize: "16px",
			color: "#626a84",
			fontFamily: "Roboto Geo Nus",
		}),
		control: provided => ({
			...provided,
			borderRadius: "30px",
			height: height ? `${height}px` : "40px",
		}),
		valueContainer: provided => ({
			...provided,
			fontFamily: "Roboto Geo MT Bold",
			textAlign: "center",
			color: "#626a84",
			display: "flex",
			justifyContent: "center",
			minHeight: "20px",
			marginLeft: marginValueContainerLeft ? "36px" : undefined,
		}),
		input: provided => ({
			...provided,
			position: "absolute",
			fontFamily: "Roboto Geo Caps",
			color: hideTextCaret ? "transparent" : undefined,
			textShadow: hideTextCaret ? "0 0 0 gray" : undefined,
			"& input": {
				font: "inherit",
				color: hideTextCaret ? "transparent" : undefined,
				textShadow: hideTextCaret ? "0 0 0 gray" : undefined,
			},
		}),
		singleValue: provided => ({
			...provided,
			fontFamily: "Roboto Geo MT Bold",
			textAlign: "center",
		}),
		indicatorSeparator: () => ({ display: "none" }),
		placeholder: (provided, state) => ({
			color: state.isFocused ? "#9ea1ac" : " #626a84",
		}),
		dropdownIndicator: provided => ({
			...provided,
			display: hideIndicator ? "none" : "inherit",
		}),
	};
};

export const ComplexTopicSelect: React.FC<{
	value: string;
	onChange?: (newValue: string) => void;
	originalCourseId: ObjectId;
	targetConcepts: ObjectId[];
	classNames?: Partial<{
		chooserContainer: string;
		chooserHeader: string;
		chooserHeading: string;
		topicChooserContainer: string;
	}>;
	headingText?: string;
	targetIconOpts?: {
		ifShow: boolean;
		width?: number;
	};
	selectableStyles?: {
		width: number;
		height?: number;
		hideIndicator?: boolean;
	};
	disabled?: boolean;
	autoFocus?: boolean;
}> = React.memo(
	({
		onChange,
		value,
		originalCourseId,
		targetConcepts,
		selectableStyles,
		classNames,
		targetIconOpts,
		disabled,
		autoFocus = false,
	}) => {
		const { width } = useWindowSize();
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [topics, setTopics] = useState<IRGETComplexAssignmentTopics>([]);
		const [mode, setMode] = useState<SelectPlaceHolders>(
			SelectPlaceHolders.select
		);

		const selectRef = useRef(null);
		useEffect(() => {
			if (selectRef !== null && selectRef.current !== null) {
				console.log("ooooo");
				(selectRef.current as any).focus();
			}
			let isCancelled = false;

			const ComplexAssignmentsController = inject(
				"ComplexAssignmentController"
			);
			ComplexAssignmentsController.getTopics({
				originalCourseId,
				targetConcepts: targetConcepts.filter(e => e !== ""),
			}).then(data => {
				setTopics(data);
				if (!data.find(e => e._id === value) && onChange) {
					/// so concepts were changed and this topic is unreachable at this moment
					onChange("");
				}
			});
			return () => {
				isCancelled = true;
			};
		}, [onChange, originalCourseId, targetConcepts, value]);

		const options = useMemo(
			(): OptionsType =>
				topics && topics.length >= 1
					? topics.map(e => ({ label: e.name, value: e._id }))
					: [],
			[topics]
		);

		const handleChange = useCallback(
			(newValue: OptionType, actioMeta: ActionMeta<OptionType>) => {
				if (!onChange) return;
				setMode(SelectPlaceHolders.select);
				if (actioMeta.action === "clear") {
					onChange("");
				}
				if (!newValue) return;
				onChange(newValue.value);
			},
			[onChange]
		);

		const handleCreateTopic = (name: string) => {
			const onCreateNewTopic = async () => {
				if (!onChange) return;
				try {
					const newTopic = await inject(
						"ComplexAssignmentController"
					).createTopic({
						name,
						originalCourseIds: [originalCourseId],
						targetConcepts,
					});
					setTopics([...topics, newTopic]);
					onChange(newTopic._id);
				} catch (error) {
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					});
				}
			};
			setIsLoading(true);
			onCreateNewTopic();
			setIsLoading(false);
			setMode(SelectPlaceHolders.select);
		};

		const handleAddNewTopic = useCallback(() => {
			if (!onChange) return;
			onChange("");
			setMode(SelectPlaceHolders.create);
		}, [onChange]);

		const MenuList = props => {
			return (
				<components.MenuList {...props}>
					{mode === SelectPlaceHolders.select && (
						<React.Fragment>
							<div
								className={styles.menuHeaderStyle}
								onClick={() => handleAddNewTopic()}
							>
								ახალი საკითხი
							</div>
							<div className={styles.breakLine} />
						</React.Fragment>
					)}

					{props.children}
				</components.MenuList>
			);
		};

		const formatCreateLabel = (value: string) => `ახალი საკითხი "${value}"`;
		return (
			<div className={styles.flexCenterContainer}>
				<div
					className={
						classNames
							? classNames.topicChooserContainer
							: selectorStyles.topicChooserContainer
					}
				>
					<div
						className={
							classNames
								? classNames.chooserHeader
								: selectorStyles.targetConpetChooserHeader
						}
					>
						<div
							className={
								selectorStyles.targetConpetChooserHeading
							}
						>
							საკითხი
						</div>

						{!targetIconOpts || targetIconOpts.ifShow ? (
							<SearchWQuestionIcon
								width={targetIconOpts?.width || 60}
							/>
						) : null}
					</div>
					<div className={styles.topicSelectContainer}>
						<div className={styles.flexCenterContainer}>
							<CreatableSelect
								isClearable
								isLoading={isLoading}
								key={generateFakeObjectId()}
								placeholder={mode}
								styles={customSelectStyles(
									selectableStyles || {
										width: width < 600 ? width - 100 : 500,
										hideTextCaret:
											mode === SelectPlaceHolders.select,
										hideIndicator: disabled,
										marginValueContainerLeft: !disabled,
									}
								)}
								onChange={handleChange}
								options={options}
								components={{ MenuList }}
								formatCreateLabel={formatCreateLabel}
								onCreateOption={handleCreateTopic}
								value={options.find(e => e.value === value)}
								noOptionsMessage={() => "საკითხი არ მოიძებნა"}
								innerRef={selectRef}
								isDisabled={disabled || !onChange}
								autoFocus={autoFocus}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export const TargetConceptSelectsWrapper: React.FC<{
	chosenConcepts: string[];
	onChange?: (newTopics: ObjectId[], resultIds: ObjectId[]) => void;
	originalCourseId: ObjectId;
	conceptResults: ObjectId[];
	classNames?: Partial<{
		chooserContainer: string;
		chooserHeader: string;
		chooserHeading: string;
	}>;
	headingText?: string;
	targetIconOpts?: {
		ifShow: boolean;
		width?: number;
	};
	selectableStyles?: {
		width: number;
		height?: number;
		hideIndicator?: boolean;
	};
	addTargetConceptButtonClassNames?: string;
	showChosenConceptResults?: boolean;
	disabled?: boolean;
}> = React.memo(
	({
		chosenConcepts,
		onChange,
		originalCourseId,
		conceptResults,
		classNames,
		headingText,
		targetIconOpts,
		selectableStyles,
		addTargetConceptButtonClassNames,
		showChosenConceptResults = true,
		disabled = false,
	}) => {
		const targetConcepts = useComplexAssignmentTargetConcepts(
			originalCourseId
		);

		const handleValueChange = useCallback(
			(value: string, index: number) => {
				const copy = chosenConcepts;
				if (!onChange) return;
				if (!!chosenConcepts.find(e => e === value)) {
					openConfirmationPopup({
						text: "ეს ცნება უკვე არჩეული გაქვთ.",
					});
					copy[index] = "";
				} else {
					copy[index] = value;
				}

				onChange(
					[...copy],
					targetConcepts
						? getChosenConceptsResults(targetConcepts, copy)
						: []
				);
			},
			[chosenConcepts, onChange, targetConcepts]
		);

		const handleAddNewSelect = useCallback(() => {
			if (!onChange) return;
			if (chosenConcepts.find(e => e === "") !== undefined) return;
			onChange([...chosenConcepts, ""], conceptResults);
		}, [onChange, chosenConcepts, conceptResults]);

		const handleDelete = useCallback(
			(index: number) => {
				if (!onChange) return;
				const newConcepts = chosenConcepts.filter(
					(e, i) => i !== index
				);
				if (
					classNames &&
					newConcepts.reduce((p, c) => (p = p + c), "") === ""
				) {
					openConfirmationPopup({
						text: " მინიმუმ ერთი სამიზნე ცნება უნდა იყოს არჩეული ",
					});
					return;
				}
				onChange(
					newConcepts,
					targetConcepts
						? getChosenConceptsResults(targetConcepts, newConcepts)
						: []
				);
			},
			[chosenConcepts, onChange, targetConcepts]
		);

		if (!targetConcepts) return null;

		return (
			<div className={styles.flexCenterContainer}>
				<div
					className={
						classNames?.chooserContainer ||
						selectorStyles.targetConpetChooserContainer
					}
				>
					<div
						className={
							classNames?.chooserHeader ||
							selectorStyles.targetConpetChooserHeader
						}
					>
						<div
							className={
								classNames?.chooserHeading ||
								selectorStyles.targetConpetChooserHeading
							}
						>
							{headingText || "აირჩიეთ ცნება"}
						</div>
						{!targetIconOpts || targetIconOpts.ifShow ? (
							<TargetIcon width={targetIconOpts?.width || 60} />
						) : null}
					</div>
					{chosenConcepts.map((e, i) => {
						return (
							<TargetConceptSelect
								isSearcheble={
									chosenConcepts.length === 1 &&
									chosenConcepts[0] === ""
								}
								options={targetConcepts.map(e => ({
									label: e.name,
									value: e._id,
								}))}
								key={i}
								index={i}
								onChange={handleValueChange}
								onAddNewSelect={handleAddNewSelect}
								onDelete={handleDelete}
								isLast={i === chosenConcepts.length - 1}
								value={e}
								selectableStyles={selectableStyles}
								addTargetConceptButtonClassNames={
									addTargetConceptButtonClassNames
								}
								disabled={disabled || !onChange}
							/>
						);
					})}
					{showChosenConceptResults && (
						<ChosenConceptResults
							conceptResultIds={conceptResults}
							originalCourseId={originalCourseId}
						/>
					)}
				</div>
			</div>
		);
	}
);

export const ChosenConceptResults: React.FC<{
	conceptResultIds: ObjectId[];
	originalCourseId: ObjectId;
	classNames?: Partial<{
		container: string;
		text: string;
		resultsBox: string;
	}>;
}> = ({ conceptResultIds, originalCourseId, classNames }) => {
	const query = useMemo(() => [originalCourseId], [originalCourseId]);
	const results = useComplexAssignmentResults(query);

	const conceptResults = useMemo(() => {
		if (!results) return [];
		return results.filter(e => conceptResultIds.find(x => x === e._id));
	}, [conceptResultIds, results]);

	if (conceptResults.length === 0) return null;

	return (
		<div
			className={classnames(
				styles.resultsContainer,
				classNames?.container
			)}
		>
			<div className={classnames(styles.resultsText, classNames?.text)}>
				შედეგები:
			</div>
			<div className={styles.flexCenterContainer}>
				{conceptResults.map((e, i) => (
					<Tooltip key={e._id} title={e.name} enterTouchDelay={0}>
						<div
							className={classnames(
								styles.resultsBox,
								classNames?.resultsBox
							)}
						>
							{e.abbr}
						</div>
					</Tooltip>
				))}
			</div>
		</div>
	);
};

const TargetConceptSelect: React.FC<{
	value: string;
	index: number;
	options: OptionsType;
	onChange: (newValue: ObjectId, index: number) => void;
	onAddNewSelect: () => void;
	onDelete: (index: number) => void;
	isLast: boolean;
	selectableStyles?: { width: number; height?: number };
	addTargetConceptButtonClassNames?: string;
	disabled?: boolean;
	isSearcheble: boolean;
}> = ({
	onChange,
	value,
	onAddNewSelect,
	index,
	options,
	isLast,
	onDelete,
	selectableStyles,
	addTargetConceptButtonClassNames,
	disabled = false,
	isSearcheble,
}) => {
	const { width } = useWindowSize();
	const handleChange = (newValue: OptionType) => {
		onChange(newValue.value, index);
	};

	const handleCloseTopic = () => onDelete(index);
	return (
		<div className={styles.selectsContainer}>
			<div className={styles.flexCenterContainer}>
				<Select
					isSearchable={isSearcheble}
					key={index}
					placeholder={
						options.length === 0
							? "ცნება არ მოიძებნა"
							: "აირჩიეთ სამიზნე ცნება"
					}
					styles={customSelectStyles(
						selectableStyles || {
							width: width < 600 ? width - 100 : 400,
							hideIndicator: disabled,
							marginValueContainerLeft: !disabled,
						}
					)}
					onChange={handleChange}
					options={options}
					value={options.find(e => e.value === value) || null}
					noOptionsMessage={() => "ცნება არ მოიძებნა"}
					isDisabled={disabled}
				/>
				{!disabled &&
					options.length !== 0 &&
					(isLast ? (
						<AddRoundButton
							onClick={onAddNewSelect}
							classNames={addTargetConceptButtonClassNames}
							size={40}
						/>
					) : (
						<CloseRoundButton
							onClick={handleCloseTopic}
							classNames={addTargetConceptButtonClassNames}
							size={40}
						/>
					))}
			</div>
		</div>
	);
};

export const CloseRoundButton: React.FC<{
	iconWidth?: number;
	color?: string;
	backgroud?: boolean;
	classNames?: string;
	size?: number;
	onClick: () => void;
}> = ({
	onClick,
	backgroud = false,
	color,
	iconWidth = 18,
	classNames,
	size,
}) => {
	return (
		<div
			className={classnames(styles.deleteContainer, classNames)}
			onClick={() => onClick()}
			style={{
				backgroundColor: backgroud ? "white" : undefined,
				width: size && !classNames ? size : undefined,
				height: size && !classNames ? size : undefined,
			}}
		>
			<DeleteButtonWrapper color={color}>
				<CloseIcon width={iconWidth} />
			</DeleteButtonWrapper>
		</div>
	);
};

export const AddRoundButton: React.FC<{
	iconWidth?: number;
	color?: string;
	classNames?: string;
	size?: number;
	onClick: () => void;
}> = React.memo(({ onClick, iconWidth = 15, color, classNames, size }) => (
	<div
		className={classnames(styles.plusContainer, classNames)}
		onClick={() => onClick()}
		style={size && !classNames ? { width: size, height: size } : undefined}
	>
		<AddIconWrapper color={color}>
			<AddIcon width={iconWidth} />
		</AddIconWrapper>
	</div>
));

export const AddIconWrapper = styled.div`
	padding: 0;
	margin: 0;
	height: 24px;
	.MuiSvgIcon-root-1 {
		fill: ${props => (props.color ? props.color : "#5669a7")};
	}
`;

export const DeleteButtonWrapper = styled.span`
	padding: 0;
	margin: 0;
	height: 24px;
	.st0 {
		fill: ${props => (props.color ? props.color : "white")};
	}
`;
