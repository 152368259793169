import * as React from "react";
import memoizeOne from "memoize-one";
import { css } from "emotion";
import { ICommonQuestionParts } from "../../../../schemas/questions/contnets/common-schemas";
import { mergeStylesObjects } from "../../../../utils";
import { NotUndefinedAtAll } from "../../../../utils/generics";
import { ItemText } from "./stats";

export interface IExplanationStyles {
	container?: string;
	head?: string;
	body?: string;
}

export interface IExplanationTexts {
	title?: string;
}

export interface IExplanationProps {
	show: boolean;
	styles?: IExplanationStyles;
	texts?: IExplanationTexts;
	explanation: ICommonQuestionParts["explanation"];
	helperProps?: any;
}

export class Explanation extends React.PureComponent<IExplanationProps> {
	defaultStyles = {
		container: ExpContainerCSS,
		head: ExpHeadCSS,
		body: ExpBodyCSS,
	} as NotUndefinedAtAll<IExplanationProps["styles"]>;

	getStyles = memoizeOne((styles: IExplanationProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	render() {
		if (!this.props.explanation || !this.props.show) return null;
		const styles = this.getStyles(this.props.styles);
		const title =
			(this.props.texts && this.props.texts.title) || "Explanation:";
		return (
			<div className={styles.container}>
				<div className={styles.head}>{title}</div>
				<div className={styles.body}>
					<ItemText stat={this.props.explanation} />
				</div>
			</div>
		);
	}
}

const ExpContainerCSS = css`
	border: 1px solid #ccc;
	border-radius: 5px;
	margin: 10px 0;
`;

const ExpHeadCSS = css`
	border-bottom: 1px solid #ccc;
	padding: 10px;
`;

const ExpBodyCSS = css`
	padding: 10px;
`;
