import { IRequest } from "../requests";
import {
	IADELETEUserAttachment,
	IAPOSTUploadUserAttachment,
	IUserAttachment,
} from "./helper-schemas";

export class UserAttachmentsController {
	constructor(private readonly Request: IRequest) {}

	getUserAttachments = async (args: {
		author: number;
	}): Promise<IUserAttachment[]> => {
		return await this.Request.send("GET", "/api/user-attachments/", args);
	};

	uploadAttachment = async (args: IAPOSTUploadUserAttachment) => {
		return this.Request.send("POST", "/api/user-attachments/", args);
	};

	deleteAttachment = async (args: IADELETEUserAttachment) => {
		return this.Request.send("POST", "/api/user-attachments/delete", args);
	};
}
