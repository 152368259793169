import {
	IRTest,
	RTestSchema,
	ITestSettingContentId,
} from "@app/api/tests/helper-schemas";
import { store } from "index";
import { filterByLoadTime } from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys } from "m-model-joi";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { removeKeys } from "@app/utils/common";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IRTest;
export type IStateTests = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(RTestSchema);
const actionTypes = createCRUDActionTypes("test");

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().tests) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
});

// ==============Main Model=================

export class Test extends Model {
	isInGoodCondition() {
		const fullDoc = this.getFullDoc();
		if (!fullDoc) return false;
		return isLoadedRecentlyEnough(fullDoc);
	}

	addContentItem(item: ITestSettingContentId) {
		const oldContentIds = (this.settings && this.settings.contentIds) || [];
		if (oldContentIds.some(e => e.id === item.id && e.type === item.type)) {
			return;
		}
		this.settings = {
			...this.settings,
			contentIds: [...oldContentIds, item],
		};
	}

	removeContentItem(item: ITestSettingContentId) {
		if (!this.settings || !this.settings.contentIds) return;
		const newContentIds = this.settings.contentIds.filter(
			e => !(e.id === item.id && e.type === item.type)
		);
		if (newContentIds.length === 0) {
			this.settings = removeKeys(
				this.settings,
				"contentIds",
				"isContentSorted"
			);
		}
		this.settings = {
			...this.settings,
			contentIds: newContentIds,
		};
	}

	static findManyByItem(item: ITestSettingContentId) {
		const docs = this.getAllSync();
		return docs.filter(test => {
			if (!test.settings || !test.settings.contentIds) return false;
			return test.settings.contentIds.some(
				e => e.id === item.id && e.type === item.type
			);
		});
	}
}

export type ITestInstance = Test;
export type ITestModel = typeof Test;

// ==============ETC=================

export const testsReducer = Test.reducer;
