import { wrapInSidebarItemsFilter } from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarType } from "@app/components/layouts/sidebar/helpers/interfaces";
import { gradebookHiddenSchools } from "@app/components/users/sidebar/items";
import { SubWebsiteOrigin } from "@app/globals";
import {
	SidebarGradebookIcon,
	SidebarSemesterGradesIcon,
	SidebarSentHomeworkIcon,
} from "@app/icons";
import { parentLinks } from "@app/routes/parent/links";

export const getParentSidebarItems = wrapInSidebarItemsFilter(({ whazz }) => {
	if (whazz.type === SidebarType.classroom) {
		return [
			{
				name: "student:assignments",
				href: parentLinks.classrooms.assignments.list.routePath,
				icon: SidebarSentHomeworkIcon,
			},
			{
				name: "studentGrades",
				href: parentLinks.classrooms.gradebook.routePath,
				icon: SidebarGradebookIcon,
				notAvailableSchoolIds: gradebookHiddenSchools,
				subWesbites: SubWebsiteOrigin.main,
			},
			{
				name: "semesterGrades",
				href: parentLinks.classrooms.semesterbook.routePath,
				icon: SidebarSemesterGradesIcon,
				notAvailableSchoolIds: gradebookHiddenSchools,
				subWesbites: SubWebsiteOrigin.main,
			},
		];
	}
	return null;
});
