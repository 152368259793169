import { ValidationErrorItem } from "joi";

export enum ErrorType {
	noKnownErrorFound = "noKnownErrorFound",
	emptyValue = "emptyValue",
	stringMinLength = "stringMinLength",
	stringFixedLength = "stringFixedLength",
	incorrectEmail = "incorrectEmail",
}

export interface IErrorCommon<ExtraInfo = {}, Locale = string> {
	itemExtraInfo: ExtraInfo;
	locale: Locale;
}

export interface IErrorDidNotFound {
	type: ErrorType.noKnownErrorFound;
}

export interface IErrorEmptyValue {
	type: ErrorType.emptyValue;
}

export interface IErrorStringMinLength {
	type: ErrorType.stringMinLength;
	requiredLength: number;
}
export interface IErrorStringFixedLength {
	type: ErrorType.stringFixedLength;
	requiredLength: number;
}

export interface IErrorIncorrectEmail {
	type: ErrorType.incorrectEmail;
}

export type ErrorTypes =
	| IErrorEmptyValue
	| IErrorDidNotFound
	| IErrorStringMinLength
	| IErrorStringFixedLength
	| IErrorIncorrectEmail;

export type ErrorDetailedInfo<ExtraInfo = {}, Locale = string> = ErrorTypes &
	IErrorCommon<ExtraInfo, Locale>;

export const getErrorDetailInfo = <ExtraInfo = {}, Locale = string>({
	errorItem,
	locale,
	itemExtraInfo,
}: {
	errorItem: ValidationErrorItem;
	locale: Locale;
	itemExtraInfo: ExtraInfo;
}): ErrorDetailedInfo<ExtraInfo, Locale> => {
	if (
		errorItem.type.indexOf("empty") ===
		errorItem.type.length - "empty".length
	) {
		return {
			type: ErrorType.emptyValue,
			locale,
			itemExtraInfo,
		};
	} else if (errorItem.type === "string.min") {
		const requiredLength = errorItem.context!.limit as number;
		return {
			type: ErrorType.stringMinLength,
			requiredLength,
			locale,
			itemExtraInfo,
		};
	} else if (errorItem.type === "string.length") {
		const requiredLength = errorItem.context!.limit as number;
		return {
			type: ErrorType.stringFixedLength,
			requiredLength,
			locale,
			itemExtraInfo,
		};
	} else if (errorItem.type === "string.email") {
		return {
			type: ErrorType.incorrectEmail,
			locale,
			itemExtraInfo,
		};
	}
	return {
		type: ErrorType.noKnownErrorFound,
		itemExtraInfo,
		locale,
	};
};
