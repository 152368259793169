import { IRGETJoinRequests } from "@app/api/classrooms/join-requests/validators";
import { inject } from "@app/modules";
import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { User } from "@app/user";

export class ClassroomJoinRequestsService {
	private readonly _ClassroomJoinRequest = inject(
		"ClassroomJoinRequestModel"
	);
	private readonly _Classroom = inject("ClassroomModel");

	public getForUserSync(
		user: User
	): { data: IRGETJoinRequests; hasFoundAllClassrooms: boolean } {
		const documents = this._ClassroomJoinRequest.getUserRequestsSync(user);
		const classroomIds = documents
			.map(e => e.classroomId)
			.filter(e => !!e) as ObjectId[];

		const classrooms = arrayToObject(
			this._Classroom.findManyByIdsSync(classroomIds),
			"_id"
		);
		let hasFoundAllClassrooms = true;
		const info = documents.map(e => {
			const classroomId = e.classroomId;
			if (classroomId && !classrooms[classroomId]) {
				hasFoundAllClassrooms = false;
			}
			return {
				classroom: classroomId ? classrooms[classroomId] : undefined,
				request: e,
			};
		});
		return {
			data: info,
			hasFoundAllClassrooms,
		};
	}
}
