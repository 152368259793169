import { HistoryContext } from "@app/app";
import { History } from "history";
import * as queryString from "query-string";
import React, {
	ChangeEvent,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useForceUpdate } from "./general";
import { useHistory as useNativeRouterHistory } from "react-router-dom";
import { combinePaths } from "@app/utils/common";

export const useHistory = (): History => {
	return useContext(HistoryContext);
};

interface GotoUrlHook {
	(url: string): () => void;
	<Args extends readonly any[]>(fn: (...args: Args) => string): (
		...args: Args
	) => void;
}
const createGoToUrlHook = (basePath: string | (() => string)) => {
	return <Args extends readonly any[] = []>(
		url: string | ((...args: Args) => string)
	) => {
		const urlRef = useRef(url);
		useLayoutEffect(() => {
			urlRef.current = url;
		});
		const history = useContext(HistoryContext);
		return useCallback(
			(...args: Args) => {
				const urlOrFn = urlRef.current;
				let finalUrl = "";
				const base =
					typeof basePath === "string" ? basePath : basePath();
				if (typeof urlOrFn === "string") {
					finalUrl = combinePaths(base, urlOrFn);
				} else {
					const tempUrl = (urlOrFn as any)(...args);
					finalUrl = combinePaths(base, tempUrl);
				}
				if (finalUrl[0] !== "/") {
					finalUrl = "/" + finalUrl;
				}
				history.push(finalUrl);
			},
			[history]
		);
	};
};

export const useGoToUrl = createGoToUrlHook("/");

export const useGoToBack = () => {
	const history = useHistory();
	return useCallback(() => {
		history.goBack();
	}, [history]);
};

export const useInput = <
	T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement
>(
	defaultValue: string | number = "",
	cb?: (e: React.ChangeEvent<T>, value: string) => void
) => {
	const [value, setInputName] = useState(defaultValue + "");
	const onChange = useCallback(
		(e: React.ChangeEvent<T>) => {
			if (cb) {
				cb(e, e.target.value);
			}
			setInputName(e.target.value);
		},
		[cb]
	);
	return { value, onChange };
};

export const useTextArea = (
	defaultValue = "",
	cb?: (e: ChangeEvent<HTMLTextAreaElement>, value: string) => void
) => {
	const [value, setInputName] = useState(defaultValue + "");
	const onChange = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			if (cb) {
				cb(e, e.target.value);
			}
			setInputName(e.target.value);
		},
		[cb]
	);
	return { value, onChange };
};

export function useLocationQuery(
	parseQuery: true,
	options?: queryString.ParseOptions
): queryString.ParsedQuery;
export function useLocationQuery(parseQuery?: false): string;
export function useLocationQuery(
	parseQuery?: boolean,
	options?: queryString.ParseOptions
): queryString.ParsedQuery | string {
	const history = useNativeRouterHistory();
	const [searchQuery, setSearchQuery] = useState(history.location.search);
	const q: queryString.ParsedQuery = useMemo(
		() => (parseQuery ? queryString.parse(searchQuery, options) : {}),
		[options, parseQuery, searchQuery]
	);
	useEffect(() => {
		return history.listen(loc => {
			setSearchQuery(loc.search);
		});
	}, [history]);
	if (!parseQuery) return searchQuery;
	return q;
}

export const useWindowSize = () => {
	const [state, setState] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handler = () => {
			setState({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handler);
		return () => window.removeEventListener("resize", handler);
	}, []);

	return state;
};

export const useWindowHeight = () => {
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handler = () => {
			setHeight(window.innerHeight);
		};
		window.addEventListener("resize", handler);
		return () => window.removeEventListener("resize", handler);
	}, []);

	return height;
};

export const useBodySize = () => {
	const [state, setState] = useState({
		width: document.body.clientWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handler = () => {
			setState({
				width: document.body.clientWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handler);
		handler();
		return () => window.removeEventListener("resize", handler);
	}, []);

	return state;
};

export const useScrolledEnough = (treshold: number): boolean => {
	const isScrolled = useRef(window.scrollY > treshold);
	const forceUpdate = useForceUpdate();

	const onScroll = useCallback(
		(e: Event) => {
			const scrolled = window.scrollY > treshold;
			if (isScrolled.current !== scrolled) {
				isScrolled.current = scrolled;
				forceUpdate();
			}
		},
		[forceUpdate, treshold]
	);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, [onScroll]);
	return isScrolled.current;
};
