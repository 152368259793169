import { IRequest } from "../requests";
import {
	IAPOSTAddParentChildren,
	IAPUTConfirmedParent,
	IRGETParentChildren,
	RGETParentChildrenSchema,
	IRGETChildParents,
	RGETChildParentsSchema,
	IAGETChildToken,
	RGETChildTokenSchema,
	IRGETChildToken,
	IAGETParentChildren,
	IAGETChildParents,
	IAPOSTAddChildrenToParent,
	IADELETEChildrenFromParent,
} from "./validators";

export class ParentChildrenController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTAddParentChildren): Promise<void> =>
		this.Request.send("POST", "/api/parent-children/add", args, {
			stripChildToken: true,
		});

	addChildrenToParent = async (args: IAPOSTAddChildrenToParent) =>
		this.Request.send("POST", "/api/parent-children/add-to-parent", args);

	removeChildrenFromParent = async (args: IADELETEChildrenFromParent) =>
		this.Request.send(
			"DELETE",
			"/api/parent-children/remove-from-parent",
			args
		);

	confirmParent = async (args: IAPUTConfirmedParent): Promise<void> =>
		this.Request.send("POST", "/api/parent-children/confirm", args, null);

	getChildren = async (
		args: IAGETParentChildren = {}
	): Promise<IRGETParentChildren> =>
		this.Request.send(
			"GET",
			"/api/parent-children/children",
			args,
			{ stripChildToken: true },
			{
				responseSchema: RGETParentChildrenSchema,
			}
		);

	getParents = async (
		args: IAGETChildParents = {}
	): Promise<IRGETChildParents> =>
		this.Request.send("GET", "/api/parent-children/parents", args, null, {
			responseSchema: RGETChildParentsSchema,
		});

	getChildToken = (args: IAGETChildToken): Promise<IRGETChildToken> =>
		this.Request.send(
			"GET",
			"/api/parent-children/children/:childUserId/token",
			args,
			{ stripChildToken: true },
			{
				responseSchema: RGETChildTokenSchema,
			}
		);
}
