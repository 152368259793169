import * as React from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import { AnyComponent, NotUndefinedAtAll } from "../../../../utils/generics";
import { css, cx } from "emotion";
import { IItemTextProps, ItemText } from "../common/stats";
import {
	IMultipleChoiceContent,
	IRMultipleChoiceContent,
} from "../../../../schemas/questions/contnets/multiple-choice/schema";
import { mergeComponentObjects, mergeStylesObjects } from "../../../../utils";

export interface IChoiceComponents {
	text?: AnyComponent<IItemTextProps>;
	choiceContainerBefore?: AnyComponent<IChoiceMainProps>;
	choiceContainerAfter?: AnyComponent<IChoiceMainProps>;
	checkMark?: AnyComponent<ICheckMarkProps>;
}

export interface IChoiceStyles {
	label?: string;
	container?: string;
	text?: string;
	checkMark?: ICheckMarkClassNames;
}

export type MCChoice =
	| IRMultipleChoiceContent["choices"][number]
	| IMultipleChoiceContent["choices"][number];
export interface IChoiceProps {
	choice:
		| IRMultipleChoiceContent["choices"][number]
		| IMultipleChoiceContent["choices"][number];
	index: number;
	onCheck: (id: number) => void;
	checked: boolean;
	isCorrect?: boolean;
	styles?: IChoiceStyles;
	components?: IChoiceComponents;
	checkOnlyOnCheckmark?: boolean;
	canSelectMultiple?: boolean;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
}

const labelClassName = css({
	display: "table",
	cursor: "pointer",
	"&>*": {
		display: "table-cell",
		verticalAlign: "middle",
		padding: 4,
		position: "relative",
	},
});

const choiceContainerClassName = css({
	display: "flex",
	alignItems: "center",
});

export interface IChoiceMainProps {
	index: number;
	id: number;
	checked: boolean;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	isCorrect?: boolean;
}

export class Choice extends React.PureComponent<IChoiceProps> {
	getOnCheck = memoizeOne((id: number, onCheck: IChoiceProps["onCheck"]) => {
		return () => onCheck(id);
	});
	getCheckMark = memoizeOne(
		(
			checkMark?: AnyComponent<ICheckMarkProps>
		): AnyComponent<ICheckMarkProps> => {
			if (!checkMark) return CheckMark;
			return checkMark;
		}
	);
	// tslint:disable:bool-param-default
	getMainProps = memoizeOne(
		(
			index: number,
			checked: boolean,
			displayAnswer: boolean,
			disableEditingAnswer: boolean,
			isCorrect?: boolean
		): IChoiceMainProps => {
			return {
				index,
				id: this.props.choice.id,
				checked,
				displayAnswer,
				disableEditingAnswer,
				isCorrect,
			};
		}
	);

	defaultComponents = {
		checkMark: CheckMark as AnyComponent<ICheckMarkProps>,
		text: ItemText as AnyComponent<IItemTextProps>,
	};

	getComponents = memoizeOne((components: IChoiceProps["components"]) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	defaultStyles = {
		label: labelClassName,
		container: choiceContainerClassName,
	};

	getStyles = memoizeOne((styles: IChoiceProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	render() {
		const { index } = this.props;
		const components = this.getComponents(this.props.components);
		const styles = this.getStyles(this.props.styles);
		const labelClass = styles.label;
		const containerClass = styles.container;
		const choiceClass = styles.text;
		const onCheck = this.getOnCheck(
			this.props.choice.id,
			this.props.onCheck
		);
		const mainProps = this.getMainProps(
			index,
			this.props.checked,
			this.props.displayAnswer,
			this.props.disableEditingAnswer,
			this.props.isCorrect
		);
		const CMark = components.checkMark;
		const QChoiceStat = components.text;

		const onContainerCheck =
			!this.props.checkOnlyOnCheckmark && !this.props.disableEditingAnswer
				? onCheck
				: undefined;
		return (
			<div className={containerClass}>
				{components && components.choiceContainerBefore && (
					<components.choiceContainerBefore {...mainProps} />
				)}
				<div onClick={onContainerCheck}>
					<div className={labelClass}>
						<CMark
							{...mainProps}
							onCheck={onCheck}
							canSelectMultiple={this.props.canSelectMultiple}
							styles={
								this.props.styles && this.props.styles.checkMark
							}
						/>
						<QChoiceStat
							index={index}
							stat={this.props.choice}
							className={choiceClass}
						/>
					</div>
				</div>
				{components && components.choiceContainerAfter && (
					<components.choiceContainerAfter {...mainProps} />
				)}
			</div>
		);
	}
}

export interface ICheckMarkClassNames {
	innerCheckMark?: {
		container?: {
			general?: string;
			singleSelect?: string;
			multipleSelect?: string;
		};
		checked?: string;
		checkedAndUnknown?: string;
		correct?: string;
		checkedAndCorrect?: string;
		incorrect?: string;
		unansweredCorrectChoice?: string;
	};
	container?: {
		general?: string;
		singleSelect?: string;
		multipleSelect?: string;
	};
}

export interface ICheckMarkProps {
	index: number;
	checked: boolean;
	isCorrect?: boolean;
	styles?: ICheckMarkClassNames;
	onCheck?: () => void;
	canSelectMultiple?: boolean;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
}

const checkMarkClassName = css({
	width: 30,
	height: 30,
	border: "1px solid #ccc",
	cursor: "pointer",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	transition: "0.3s",
	"&:hover": {
		background: "rgba(204, 204, 204, 0.25)",
	},
});

const checkMarkMultipleSelectClassName = css({
	borderRadius: 3,
});
const checkMarkSingleSelectClassName = css({
	borderRadius: 22,
});

class CheckMark extends React.PureComponent<ICheckMarkProps> {
	defaultStyles = {
		innerCheckMark: {
			container: {
				general: checkMarkClassName,
				singleSelect: checkMarkSingleSelectClassName,
				multipleSelect: checkMarkMultipleSelectClassName,
			},
		},
		container: {},
	} as NotUndefinedAtAll<ICheckMarkClassNames>;

	getStyles = memoizeOne((styles: ICheckMarkProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	render() {
		const styles = this.getStyles(this.props.styles);
		const containerCSS = cx(
			styles.container.general,
			this.props.canSelectMultiple
				? styles.container.multipleSelect
				: styles.container.singleSelect
		);
		const innerCheckMarkCSSs: string[] = [
			styles.innerCheckMark.container.general,
			this.props.canSelectMultiple
				? styles.innerCheckMark.container.multipleSelect
				: styles.innerCheckMark.container.singleSelect,
		];
		if (this.props.checked) {
			innerCheckMarkCSSs.push(styles.innerCheckMark.checked);
		}
		let IconComponent: AnyComponent<any> | undefined;
		if (this.props.displayAnswer) {
			if (this.props.isCorrect && this.props.checked) {
				IconComponent = CheckIcon;
				innerCheckMarkCSSs.push(
					styles.innerCheckMark.checkedAndCorrect
				);
			} else if (this.props.isCorrect && !this.props.checked) {
				IconComponent = CheckIcon;
				innerCheckMarkCSSs.push(styles.innerCheckMark.correct);
			} else if (!this.props.isCorrect && this.props.checked) {
				IconComponent = CloseIcon;
				innerCheckMarkCSSs.push(styles.innerCheckMark.incorrect);
			}
		} else if (this.props.checked) {
			IconComponent = CheckIcon;
			innerCheckMarkCSSs.push(styles.innerCheckMark.checkedAndUnknown);
		}
		const innerCheckMarkCSS = cx(...innerCheckMarkCSSs);
		const onCheck = !this.props.disableEditingAnswer
			? this.props.onCheck
			: undefined;
		return (
			<div className={containerCSS}>
				<div className={innerCheckMarkCSS} onClick={onCheck}>
					{!!IconComponent && <IconComponent />}
				</div>
			</div>
		);
	}
}
