/* eslint-disable max-lines-per-function */
import { UserType } from "@app/api/helper-schemas";
import { useCitiesAndSchools } from "@app/hooks/cities-and-schools";
import { useHistory } from "@app/hooks/front";
import { useBoolean, useErrors, useMountingInfo } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getJoiErrorItems } from "@app/utils/joi-errors";
import { getFormattedMessage } from "@app/utils/locale";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import LocationIcon from "@material-ui/icons/LocationOn";
import SchoolIcon from "@material-ui/icons/School";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AuthSelect, Button } from "../auth/input-forms";
import { SecondPageLoader } from "../auth/student-register-page";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { getAuthErrors } from "../auth/validations";
import { createGrades } from "../widgets/grades";
import Popup, { FancyPopup, PopupContent, PopupTitle } from "../widgets/popup";
import { MobileVerification } from "./mobile-verification";
import { StylesConfig } from "react-select";
import {
	getCurrentWebsite,
	WebsiteOrigin,
	SubWebsiteOrigin,
} from "@app/globals";
import { DefaultPopupPrioritizer } from "../widgets/prioritizer";
import { CometChat } from "@cometchat-pro/chat";
import { useSubWebsite } from "@app/hooks/bc";

export const MissingProfilePopups: React.FC = props => {
	return (
		<>
			<MobileVerificationPopup />
			<MissingProfilePopup />
		</>
	);
};

const MobileVerificationPopup: React.FC = React.memo(() => {
	const user = useClassroomsUser();

	const locale = useLocale();
	const history = useHistory();

	const {
		value: isClickedOnLaterVerify,
		setTrue: setClickedOnLaterVerify,
	} = useBoolean(false);

	const {
		value: mobileVerficiationPopup,
		setTrue: setMobileVerficiationPopupVissible,
		setFalse: hideMobileVerficiation,
	} = useBoolean(true);

	const timeIntervalForMobileVerificationPupupVisibility = 12 * 3600 * 1000;

	const onClickOnLaterVerify = useCallback(() => {
		setClickedOnLaterVerify();
		hideMobileVerficiation();
		setTimeout(() => {
			setMobileVerficiationPopupVissible();
		}, timeIntervalForMobileVerificationPupupVisibility);
	}, [
		hideMobileVerficiation,
		setClickedOnLaterVerify,
		setMobileVerficiationPopupVissible,
		timeIntervalForMobileVerificationPupupVisibility,
	]);

	const onRegistrationFinish = useCallback(() => {}, [history]);

	const isNotValidMobile = !!user && !user.isValidMobile;
	const isMobileVerificationDisabled = true;
	const shouldRender =
		locale === "ka" &&
		!isMobileVerificationDisabled &&
		isNotValidMobile &&
		mobileVerficiationPopup &&
		user?.isStudent();

	const onSuccess = onRegistrationFinish;
	const laterVerify = onClickOnLaterVerify;
	const isLaterVerifyButtonVissible = !isClickedOnLaterVerify;
	return (
		<DefaultPopupPrioritizer enabled={shouldRender} priority={999}>
			<Popup>
				<PopupContent
					style={{
						backgroundColor: "#eaecf4",
					}}
				>
					<MobileVerification
						onSuccess={onSuccess}
						isLaterVerifyButtonVissible={
							isLaterVerifyButtonVissible
						}
						laterVerify={laterVerify}
					/>
				</PopupContent>
			</Popup>
		</DefaultPopupPrioritizer>
	);
});

const MissingProfilePopup = () => {
	const user = useClassroomsUser();
	const locale = useLocale();
	const isDutch =
		locale === "nl" && getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const isEng =
		locale === "en" && getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const missedCitiesInput = isDutch || isEng;

	const subWebsite = useSubWebsite();
	const isForbidden = subWebsite === SubWebsiteOrigin.britishCenter;

	if (!user) return null;

	const hasMissingProfile =
		(user.isStudent() || user.isTeacher()) &&
		!!user &&
		(!user.city || !user.school || (user.isStudent() && !user.grade));

	return (
		<DefaultPopupPrioritizer
			enabled={!missedCitiesInput && hasMissingProfile && !isForbidden}
			priority={1000}
		>
			<FancyPopup hideCloseIcon={true}>
				<MissingProfile
					userType={
						user.isStudent()
							? UserType.student
							: user.isTeacher()
							? UserType.teacher
							: UserType.parent
					}
				/>
			</FancyPopup>
		</DefaultPopupPrioritizer>
	);
};

const MissingProfile: React.FC<{
	userType: UserType.student | UserType.teacher | UserType.parent;
	onSuccess?: () => void;
}> = props => {
	const history = useHistory();
	const user = useClassroomsUser();
	const { value: isLoading, setValue: setIsLoading } = useBoolean(false);
	const [selectedCityId, setSelectedCityId] = useState<null | number>(
		user?.city || null
	);
	const [city, setCity] = useState<null | string>(null);
	const [province, setProvince] = useState<null | string>(null);
	const [school, setSchool] = useState<null | string>(null);
	const [selectedGrade, setSelectedGrade] = useState<null | number>(
		user?.grade || null
	);
	const [selectedSchoolId, setSelectedSchoolId] = useState<null | number>(
		user?.school || null
	);
	const mountinfInfo = useMountingInfo();
	const isGeo = useLocale() === "ka";

	const { cities, schools } = useCitiesAndSchools(user ? user.country : null);

	interface ErrorType {
		city?: string;
		school?: string;
		grade?: string;
		province?: string;
	}

	const { errors, removeErrorMessage, setErrors } = useErrors<ErrorType>(
		null
	);

	const gradeOptions = useMemo(
		() =>
			createGrades(
				user ? user.country : null,
				user ? user.registrationOrigin : null
			),
		[user]
	);

	useEffect(() => {
		if (cities) {
			removeErrorMessage("city");
		}
	}, [cities, removeErrorMessage]);
	useEffect(() => {
		if (schools) {
			removeErrorMessage("school");
		}
	}, [schools, removeErrorMessage]);

	let schoolsOfSelectedCity: {
		value: number;
		cityId: number;
		label: string;
	}[];
	if (selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		schoolsOfSelectedCity = (schools || []).filter(
			s => s.cityId === selectedCityId
		);
	}

	const onCityChange = useCallback(
		(selection: { value: number; label: string }) => {
			setSelectedSchoolId(null);
			setSelectedCityId(selection.value);
			removeErrorMessage("city");
		},
		[removeErrorMessage]
	);

	const onCityInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setCity(e.target.value);
			removeErrorMessage("city");
		},
		[removeErrorMessage]
	);

	const onSchoolInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSchool(e.target.value);
			removeErrorMessage("school");
		},
		[removeErrorMessage]
	);

	const onSchoolChange = useCallback(
		(selection: { value: number; label: string; cityId: number }) => {
			setSelectedSchoolId(selection.value);
			removeErrorMessage("school");
		},
		[removeErrorMessage]
	);

	const onGradeChange = useCallback(
		(val: { value: number; label: string }) => {
			setSelectedGrade(val.value);
			removeErrorMessage("grade");
		},
		[removeErrorMessage]
	);

	const logout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout().then(() => {
			history.push(`/`);
		});
	}, []);

	const { onSuccess } = props;
	const handleRegistrationFinish = useCallback(() => {
		const UsersController = inject("UsersController");
		setIsLoading(true);
		UsersController.saveDetailedInfo({
			city: !isGeo ? null : (selectedCityId as number),
			school: !isGeo ? null : (selectedSchoolId as number),
			grade: !isGeo
				? undefined
				: selectedGrade
				? selectedGrade
				: undefined,
		})
			.then(() => {
				if (onSuccess) onSuccess();
				if (!mountinfInfo.isMounted) return;
				setIsLoading(false);
			})
			.catch(e => {
				if (!mountinfInfo.isMounted) return;
				const joiErrors = getJoiErrorItems(e);
				if (joiErrors) {
					const errorMessages = getAuthErrors<{
						[key: string]: string | undefined;
					}>(joiErrors);
					setErrors(errorMessages);
				}
				setIsLoading(false);
			});
	}, [
		isGeo,
		mountinfInfo.isMounted,
		onSuccess,
		selectedCityId,
		selectedGrade,
		selectedSchoolId,
		setErrors,
		setIsLoading,
	]);

	return (
		<div className={StudentRegisterStyles.missinProfileInnerBody}>
			<PopupTitle>
				<FormattedMessage id="fillProfile" />
			</PopupTitle>
			{!schools ? (
				<div>
					<SecondPageLoader userType={props.userType} />
				</div>
			) : (
				<>
					<AuthSelect
						errorText={errors && errors.city}
						icon={<LocationIcon />}
						isDisabled={isLoading}
						onChange={onCityChange}
						options={cities}
						placeholder={getFormattedMessage("inputs.city")}
						value={
							(cities &&
								cities.find(c => c.value === selectedCityId)) ||
							null
						}
						styles={inputStyles}
						containerClassName={
							StudentRegisterStyles.missinProfileInputContainer
						}
					/>
					<AuthSelect
						errorText={errors && errors.school}
						icon={<SchoolIcon />}
						isDisabled={isLoading}
						onChange={onSchoolChange}
						options={schoolsOfSelectedCity}
						placeholder={getFormattedMessage("inputs.school")}
						value={
							schoolsOfSelectedCity.find(
								s => s.value === selectedSchoolId
							) || null
						}
						styles={inputStyles}
						containerClassName={
							StudentRegisterStyles.missinProfileInputContainer
						}
					/>
					{/*
							<NameInput
								onChange={onCityInputChange}
								placeholder={getFormattedMessage("inputs.city")}
								className={
									StudentRegisterStyles.missingProfileCityInput
								}
							/>
							<NameInput
								onChange={onSchoolInputChange}
								placeholder={getFormattedMessage(
									"inputs.school"
								)}
								className={
									StudentRegisterStyles.missingProfileCityInput
								}
							/> */}

					{props.userType === UserType.student && (
						<AuthSelect
							errorText={errors && errors.grade}
							icon={<InfoIcon />}
							isDisabled={isLoading}
							onChange={onGradeChange}
							options={gradeOptions}
							placeholder={getFormattedMessage("inputs.grade")}
							value={gradeOptions.find(
								e => e.value === selectedGrade
							)}
							isSearchable={false}
							styles={inputStyles}
							containerClassName={
								StudentRegisterStyles.missinProfileInputContainer
							}
						/>
					)}
					<Button
						onClick={handleRegistrationFinish}
						isLoading={isLoading}
						text={getFormattedMessage("formCompleteCTA")}
						className={
							StudentRegisterStyles.missinProfileActionButton
						}
					/>
					<span
						onClick={logout}
						style={{
							display: "block",
							textAlign: "right",
							width: "100%",
							marginTop: "10px",
							cursor: "pointer",
						}}
					>
						<FormattedMessage id="logout" />
					</span>
				</>
			)}
		</div>
	);
};

export const inputStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "7px 5px",
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#92959c",
	}),
	control: () => ({
		padding: "7px 6px",
		backgroundColor: "#cccccc00",
		borderRadius: 5,
		margin: "5px 0",
		border: "1px solid #cfc5c5",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition };
	},
	indicatorsContainer: (provided, state) => {
		return { display: "none" };
	},
};
