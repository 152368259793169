export const getFileExtension = (fileUrl: string) =>
	fileUrl.substr(fileUrl.lastIndexOf(".") + 1, fileUrl.length).toLowerCase();

export const VideoPlayerSupportedMediaTypes = ["mp4", "webm", "ogg"];

export const SuportedImageFormatsa = [
	"jpg",
	"jpeg",
	"jfif",
	"pjpeg",
	"pjp",
	"png",
	"svg",
	"gif",
];

export const GoogleDocsViewerSuportedFormats = [
	"txt",
	"doc",
	"docx",
	"pdf",
	"ppt",
	"pptx",
	"zip",
	"xls",
	"xlsx",
];

export const canBeOpenedWithGoogleDocsViewer = (fileExtension: string) =>
	!!GoogleDocsViewerSuportedFormats.find(e => e === fileExtension);

export const canBeOpenedWithImageViewer = (fileExtension: string) =>
	!!SuportedImageFormatsa.find(e => e === fileExtension);

export const canBeOpenedWithVideoPlayer = (fileExtension: string) =>
	!!VideoPlayerSupportedMediaTypes.find(e => e === fileExtension);
