import FillingBlanks from "../../../components/questions/contents/filling-blanks/class";
import GroupingItems from "../../../components/questions/contents/grouping-items/class";
import Joi from "../../../utils/joi";
import MultipleChoice from "../../../components/questions/contents/multiple-choice/class";
import MultipleContents from "../../../components/questions/contents/multiple-contents/class";
import SortItems from "../../../components/questions/contents/sort-items/class";
import {
	FillingBlanksContentSchema,
	IFillingBlanksContent,
	IRFillingBlanksContent,
	RFillingBlanksContentSchema,
} from "./filling-blanks/schema";
import {
	GroupingItemsContentSchema,
	IGroupingItemsContent,
	IRGroupingItemsContent,
	RGroupingItemsContentSchema,
} from "./grouping-items/schema";
import {
	IMultipleContents,
	IRMultipleContents,
	MultipleContentsSchema,
	RMultipleContentsSchema,
} from "./multiple-contents/schema";
import {
	IMultipleChoiceContent,
	IRMultipleChoiceContent,
	MultipleChoiceContentSchema,
	RMultipleChoiceContentSchema,
} from "./multiple-choice/schema";
import {
	IRSortItemsContent,
	ISortItemsContent,
	SortItemsContentSchema,
	RSortItemsContentSchema,
} from "./sort-items/schema";

export type IRawQuestionContent =
	| IMultipleChoiceContent
	| ISortItemsContent
	| IFillingBlanksContent
	| IGroupingItemsContent
	| IMultipleContents;

export type IRQuestionContent =
	| IRMultipleChoiceContent
	| IRSortItemsContent
	| IRFillingBlanksContent
	| IRGroupingItemsContent
	| IRMultipleContents;

export type IQuestionContent =
	| MultipleChoice
	| SortItems
	| FillingBlanks
	| GroupingItems
	| MultipleContents;

export const QuestionContentSchema = Joi.alternatives([
	MultipleChoiceContentSchema.required(),
	SortItemsContentSchema.required(),
	FillingBlanksContentSchema.required(),
	GroupingItemsContentSchema.required(),
	MultipleContentsSchema.required(),
]);

export const RQuestionContentSchema = Joi.alternatives([
	RMultipleChoiceContentSchema.required(),
	RSortItemsContentSchema.required(),
	RFillingBlanksContentSchema.required(),
	RGroupingItemsContentSchema.required(),
	RMultipleContentsSchema.required(),
]);
