import {
	IComplexAssignmentResults,
	IComplexAssignmentTopic,
	ITargetConcept,
	IResult,
	IComplexAssignment,
	IRComplexAssignment,
} from "@app/api/assignments/complex/helper-schemas";
import {
	IRGETComplexAssignmentResults,
	IRGETComplexAssignmentTopics,
	IRGETTargetConcepts,
	IRGETComplexAssignments,
} from "@app/api/assignments/complex/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useEffect, useState } from "react";

//// COMPLEX ASSIGNMENTS

export const useComplexAssignments = (
	originalCourseId?: ObjectId,
	author?: number
): IRComplexAssignment[] | undefined => {
	const [assignments, setAssignments] = useState<IRGETComplexAssignments>();
	useEffect(() => {
		let isCancelled = false;

		const ComplexAssignmentsController = inject(
			"ComplexAssignmentController"
		);
		ComplexAssignmentsController.getAssignments({
			originalCourseId,
			author,
		}).then(data => {
			if (isCancelled) return;
			setAssignments(data);
		});
		return () => {
			isCancelled = true;
		};
	}, [author, originalCourseId]);

	return assignments;
};

export const useComplexAssignmentTargetConcepts = (
	originalCourseId?: ObjectId
): ITargetConcept[] | undefined => {
	const [concepts, setConcepts] = useState<IRGETTargetConcepts>();
	useEffect(() => {
		let isCancelled = false;

		const ComplexAssignmentsController = inject(
			"ComplexAssignmentController"
		);
		ComplexAssignmentsController.getTargetConcepts({
			originalCourseId,
		}).then(data => {
			if (isCancelled) return;
			setConcepts(data);
		});
		return () => {
			isCancelled = true;
		};
	}, [originalCourseId]);
	return concepts;
};

export const useComplexAssignmentTopics = (
	originalCourseId?: ObjectId,
	targetConcepts?: ObjectId[]
): IComplexAssignmentTopic[] | undefined => {
	const [topics, setTopics] = useState<IRGETComplexAssignmentTopics>();
	useEffect(() => {
		let isCancelled = false;

		const ComplexAssignmentsController = inject(
			"ComplexAssignmentController"
		);
		ComplexAssignmentsController.getTopics({
			originalCourseId,
			targetConcepts,
		}).then(data => {
			if (isCancelled) return;
			setTopics(data);
		});
		return () => {
			isCancelled = true;
		};
	}, [originalCourseId, targetConcepts]);

	return topics;
};

export const useComplexAssignmentResults = (
	originalCourseIds: ObjectId[]
): IResult[] | undefined => {
	const [results, setRuselts] = useState<IRGETComplexAssignmentResults>();
	useEffect(() => {
		let isCancelled = false;

		const ComplexAssignmentsController = inject(
			"ComplexAssignmentController"
		);
		ComplexAssignmentsController.getResults({
			originalCourseIds,
		}).then(data => {
			if (isCancelled) return;
			setRuselts(data);
		});
		return () => {
			isCancelled = true;
		};
	}, [originalCourseIds]);
	return results;
};

export const useComplexAssignmentResultGroups = ():
	| IComplexAssignmentResults[]
	| undefined => {
	const [resultsGroups, setRuseltGroups] = useState<
		IComplexAssignmentResults[]
	>();
	useEffect(() => {
		let isCancelled = false;

		const ComplexAssignmentsController = inject(
			"ComplexAssignmentController"
		);
		ComplexAssignmentsController.getResultGroups().then(data => {
			if (isCancelled) return;
			setRuseltGroups(data);
		});
		return () => {
			isCancelled = true;
		};
	}, []);
	return resultsGroups;
};
