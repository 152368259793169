import { UserType } from "@app/api/helper-schemas";
import { useClassroomsUser } from "@app/hooks/users";
import {
	getActiveUserType,
	setActiveUserType,
} from "@app/user/active-user-type";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import FancyLoading from "../../widgets/fancy-loading";
import Popup from "../../widgets/popup";
import styles from "./styles/general_styles.module.css";

export const UserProfileAvatar: React.FC = () => {
	const [userType, setUserType] = useState(() => getActiveUserType());
	const accessibleTypes = useClassroomsUser()!.getAllAccessibleUserTypes();
	const [loaderStatus, setLoaderStatus] = useState(false);
	const avatarInfo = useMemo(
		(): AvatarInfo | null =>
			userAvatarInfosByType.find(e => e.type === userType) || null,
		[userType]
	);
	/**
	 * For now we only support max two roles together
	 */
	const isSwitchFunctionalityAvailable = useMemo(() => {
		if (accessibleTypes.length === 1) {
			return false;
		}
		return true;
	}, [accessibleTypes]);

	const nextRoles = useMemo(() => {
		if (!isSwitchFunctionalityAvailable) return null;
		const nextTypes = accessibleTypes.filter(e => e !== userType);
		return userAvatarInfosByType.filter(e =>
			nextTypes.some(t => t === e.type)
		);
	}, [accessibleTypes, isSwitchFunctionalityAvailable, userType]);

	const handleActiveTypeChange = useCallback(
		nextRole => () => {
			if (!nextRole) return;
			setLoaderStatus(true);
			setTimeout(() => {
				setLoaderStatus(false);
			}, 700);
			setActiveUserType(nextRole?.type);
			setUserType(nextRole?.type);
		},
		[]
	);
	if (!avatarInfo) {
		return null;
	}
	return (
		<section className={styles.profile_avatar__wrapper}>
			<section className={styles.secondaryAvatarsContainer}>
				{loaderStatus && (
					<Popup>
						<FancyLoading />
					</Popup>
				)}
				{nextRoles &&
					nextRoles.map((e, i) => {
						return (
							<div
								className={
									styles.singleSecondaryAvatarContainer
								}
								onClick={handleActiveTypeChange(e)}
								key={i}
							>
								<img
									src={e.imageSrc}
									className={styles.secondRole}
									alt="secondary-avatar"
								/>
							</div>
						);
					})}
			</section>
			<section className={styles.profile_avatar}>
				<img src={avatarInfo.imageSrc} alt="avatar" />
			</section>
			<p className={styles.profile_person}>
				<FormattedMessage id={avatarInfo.formatedMessageId} />
			</p>
		</section>
	);
};

interface AvatarInfo {
	type: UserType;
	imageSrc: string;
	unActiveImageSrc?: string;
	formatedMessageId: string;
}

const userAvatarInfosByType: AvatarInfo[] = [
	{
		type: UserType.teacher,
		imageSrc: "/imgs/TeacherAvatar.svg",
		unActiveImageSrc: "/imgs/TeacherAvatarGrey.svg",
		formatedMessageId: "teacher",
	},
	{
		type: UserType.student,
		imageSrc: "/imgs/StudentAvatar.svg",
		formatedMessageId: "student",
	},
	{
		type: UserType.headmaster,
		imageSrc: "/imgs/SchoolAvatar.svg",
		formatedMessageId: "headmaster",
		unActiveImageSrc: "/imgs/SchoolAvatarGrey.svg",
	},
	{
		type: UserType.parent,
		imageSrc: "/imgs/ParentAvatar.svg",
		formatedMessageId: "parent",
	},
];
