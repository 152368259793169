import Joi from "@app/utils/joi";

export const APOSTMobileConfirmationCodeSchema = Joi.object({
	mobile: Joi.string().required(),
});
export interface IAPOSTMobileConfirmationCode {
	mobile: string;
}

export type IRPOSTMobileConfirmationCode = void;

export const APOSTMobileConfirmationTokenSchema = Joi.object({
	mobile: Joi.string().required(),
	code: Joi.string().required(),
});
export interface IAPOSTMobileConfirmationToken {
	mobile: string;
	code: string;
}

export const RPOSTMobileConfirmationTokenSchema = Joi.object({
	token: Joi.string().required(),
});
export interface IRPOSTMobileConfirmationToken {
	token: string;
}
