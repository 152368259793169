import { IRequest } from "../requests";
import {
	IAPOSTBlog,
	IRPOSTBlog,
	RPOSTBlogSchema,
	RGETManyBlogsSchema,
} from "./validators";
import { inject } from "@app/modules";
import { Blog } from "@app/models/blog";
import { BlogSchema } from "./helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";

export class BlogController {
	private readonly Request: IRequest;

	private readonly _BlogModel = inject("BlogModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	postBlog = async (args: IAPOSTBlog): Promise<Blog> => {
		return this.Request.send("POST", "/api/blogs", args, undefined, {
			responseSchema: RPOSTBlogSchema,
		}).then((data: IRPOSTBlog) => {
			return this._BlogModel.loadOneSync(data);
		});
	};

	getAllBlogs = async (): Promise<Blog[]> => {
		return this.Request.send("GET", "/api/blogs", undefined, undefined, {
			responseSchema: RGETManyBlogsSchema,
		}).then((data: Blog[]) => {
			return this._BlogModel.loadManySync(data);
		});
	};

	getBlogById = async (args: { _id: ObjectId }): Promise<Blog> => {
		const blog = this._BlogModel.findByIdSync(args._id);
		if (blog) return blog;

		return this.Request.send("GET", "/api/blogs/:_id", args).then(
			(data: Blog) => {
				return this._BlogModel.loadOneSync(data);
			}
		);
	};
}
