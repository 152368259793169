import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { ICourse } from "./helper-schemas";
import {
	IRFolder,
	ItemType,
	IUserFolderProgress,
} from "../folders/helper-schemas";
import { arrayToObject } from "@app/utils/common";
import { IRequest } from "../requests";
import { IRGETCourseHierarchy } from "./validators";

export interface ICourseFullSkeleton {
	course: ICourse;
	folders: IRFolder[];
	folderProgresses: Record<string, IUserFolderProgress | undefined>;
	hierarchies: IRGETCourseHierarchy;
}

export default class CourseFetchingController {
	private readonly Request: IRequest;

	private readonly _Folder = inject("FolderModel");
	private readonly _UserFolderProgressModel = inject(
		"UserFolderProgressModel"
	);
	private readonly _FolderHierarchyService = inject("FolderHierarchyService");

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	private readonly _CoursesController = inject("CoursesController");
	private readonly _FoldersController = inject("FoldersController");
	private readonly _TopicsController = inject("TopicsController");
	private readonly _TaskTypesController = inject("TaskTypesController");

	constructor(request: IRequest) {
		this.Request = request;
	}

	loadWholeCourseSkeleton = async (args: {
		courseId: ObjectId;
		folderId?: ObjectId;
		depth?: number;
	}): Promise<ICourseFullSkeleton> => {
		const [course, hierarchies] = await Promise.all([
			this._CoursesController.getById({ _id: args.courseId }),
			this._CoursesController.getHierarchies({ _id: args.courseId }),
		]);
		await Promise.all([
			this._TopicsController.getAllByCourseId({
				courseId: args.courseId,
			}),
			this._TaskTypesController.getAllByCourseId({
				courseId: args.courseId,
			}),
		]);
		const [folders, folderProgresses] = await this.loadWholeFolder({
			folderId: args.folderId || course.rootFolder,
			courseId: args.courseId,
			depth: args.depth,
		});
		return { course, folders, folderProgresses, hierarchies };
	};

	loadWholeFolder = async (args: {
		folderId: ObjectId;
		courseId: ObjectId;
		depth?: number;
	}): Promise<
		[IRFolder[], Record<string, IUserFolderProgress | undefined>]
	> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		const allFolderIds = [
			args.folderId,
			...this._FolderHierarchyService.getDescendantIdsSync(
				args.courseId,
				args.folderId,
				args.depth
			),
		];
		let areAllFoldersLoaded = true;
		for (let i = 0; i < allFolderIds.length; ++i) {
			const folderId = allFolderIds[i];
			if (areAllFoldersLoaded && !this._Folder.findByIdSync(folderId)) {
				areAllFoldersLoaded = false;
				break;
			}
		}
		const areFolderProgressesLoaded =
			areAllFoldersLoaded &&
			this.areFolderProgressesLoaded({
				...args,
				userId,
			});
		return Promise.all([
			areAllFoldersLoaded
				? this._FoldersController.getManyByIds({
						folderIds: allFolderIds,
						courseId: args.courseId,
				  })
				: this._FoldersController.loadAllSubFolders(args),
			areFolderProgressesLoaded
				? arrayToObject(
						this._UserFolderProgressModel.findUserDocsByFoldersSync(
							{
								userId,
								courseId: args.courseId,
								folderIds: allFolderIds,
							}
						),
						"folderId"
				  )
				: this._FoldersController.getSubFoldersProgress({
						courseId: args.courseId,
						folderId: args.folderId,
				  }),
		]);
	};

	private areFolderProgressesLoaded = (args: {
		folderId: ObjectId;
		courseId: ObjectId;
		depth?: number;
		userId: number;
	}): boolean => {
		const hierarchy = this._FolderHierarchyService.getHierarchyInfoObjectSync(
			args.courseId
		);
		const progressDoc = this._UserFolderProgressModel.findUserDocSync(args);
		if (!progressDoc) return false;
		const depth = args.depth || Infinity;
		if (depth === 0) return true;
		const children = hierarchy.childrenInfo[args.folderId];
		if (!children) return false;
		for (let i = 0; i < children.length; ++i) {
			const childId = children[i];
			const childProgresss = progressDoc.itemsProgress.find(
				e => e.type === ItemType.folder && e.id === childId
			);
			if (
				childProgresss &&
				!this.areFolderProgressesLoaded({
					courseId: args.courseId,
					depth: depth - 1,
					folderId: childId,
					userId: args.userId,
				})
			) {
				return false;
			}
		}
		return true;
	};
}
