import React, {
	useCallback,
	useState,
	useRef,
	useEffect,
	useMemo,
	CSSProperties,
} from "react";
import styles from "./styles/user-subject-list.module.css";
import classnames from "classnames";
import { inject } from "@app/modules";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import Loading from "@app/components/widgets/raw-loading";
import certficiteStyles from "./styles/certificate.module.css";
import { link } from "fs";
import { useWindowSize } from "@app/hooks/front";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { ReactComponent as DownloadIcon } from "../../styles/img/icons/Download.svg";
import { ReactComponent as FacebookIcon } from "@app/icons/Facebook.svg";
import styled from "@emotion/styled";
import { useClassroomsUser } from "@app/hooks/users";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { UserType } from "@app/api/helper-schemas";
import { StudentBox } from "../boxes";

export const StudentCertificateBox: React.FC<{
	style?: CSSProperties;
	showStudentCertificatePopup?: boolean;
	onClose?: () => void;
}> = React.memo(props => {
	const [popup, setPopup] = useState<{
		certificatePopup: { isOpen: boolean; path: string };
		noCertificatePopup: { isOpen: boolean };
	}>({
		certificatePopup: {
			isOpen: false,
			path: "",
		},
		noCertificatePopup: { isOpen: false },
	});

	useEffect(() => {
		if (!props.showStudentCertificatePopup) return;
		inject("CertificatesController")
			.getCertificatesForStudent()
			.then(e =>
				setPopup(cur =>
					e.certificatePath
						? {
								...cur,
								certificatePopup: {
									isOpen: true,
									path: e.certificatePath,
								},
						  }
						: { ...cur, noCertificatePopup: { isOpen: true } }
				)
			)
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, [props.showStudentCertificatePopup]);

	useEffect(() => {
		const loadAndMarkSeen = async () => {
			const controller = inject("CertificatesController");
			const certificate = await controller.getCertificatesForStudent();
			if (certificate.seen === false && certificate.certificatePath) {
				setPopup(cur =>
					certificate.certificatePath
						? {
								...cur,
								certificatePopup: {
									isOpen: true,
									path: certificate.certificatePath,
								},
						  }
						: cur
				);
				controller.marStudentCertificateSeen();
			}
		};
		loadAndMarkSeen().catch(e => {
			console.log(e);
			openConfirmationPopup({ text: getFormattedMessage("errorAlert") });
		});
	}, []);

	const handleCertificateBoxClick = useCallback(() => {
		inject("CertificatesController")
			.getCertificatesForStudent()
			.then(e =>
				setPopup(cur =>
					e.certificatePath
						? {
								...cur,
								certificatePopup: {
									isOpen: true,
									path: e.certificatePath,
								},
						  }
						: { ...cur, noCertificatePopup: { isOpen: true } }
				)
			)
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, []);

	const renderPopups = useMemo(() => {
		if (popup.noCertificatePopup.isOpen || popup.certificatePopup.isOpen) {
			return popup.certificatePopup.isOpen ? (
				<DefaultPopupPrioritizer priority={999} enabled={true}>
					<StudentcCertificatePopup
						path={popup.certificatePopup.path}
						onClose={() => {
							setPopup({
								...popup,
								certificatePopup: {
									...popup.certificatePopup,
									isOpen: false,
								},
							});
							if (props.onClose) props.onClose();
						}}
					/>
				</DefaultPopupPrioritizer>
			) : (
				<NoCertificatePopup
					onClose={() => {
						setPopup({
							...popup,
							noCertificatePopup: { isOpen: false },
						});
						if (props.onClose) props.onClose();
					}}
				/>
			);
		}
		return null;
	}, [popup, props]);

	return (
		<React.Fragment>
			{renderPopups}
			<StudentCertificateBoxMarkup
				onClick={handleCertificateBoxClick}
				style={props.style}
			/>
		</React.Fragment>
	);
});

export const StudentCertificateBoxMarkup = React.memo<{
	onClick: () => void;
	style?: React.CSSProperties;
	title?: string;
}>(function StudentCertificateBoxMarkup({ onClick, style, title }) {
	return (
		<StudentBox.Container style={style} onClick={onClick}>
			<h2 className={styles.adTitle}>{}</h2>
			<img
				src={"/imgs/award.png"}
				className={styles.awardImage}
				alt="cb"
			/>
			<StudentBox.Title>{title ?? "ჩემი სერტიფიკატი"}</StudentBox.Title>
		</StudentBox.Container>
	);
});

const NoCertificatePopup: React.FC<{
	onClose: () => void;
}> = React.memo(({ onClose }) => {
	const user = useClassroomsUser();
	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose
				onClose={onClose}
				popupBodyClassname={certficiteStyles.no_certificate_body}
			>
				<div className={certficiteStyles.no_certificate_container}>
					<div
						className={certficiteStyles.no_certificate_text}
						style={{ fontFamily: "Roboto Geo Caps" }}
					>
						{user?.firstname},
					</div>
					<div className={certficiteStyles.no_certificate_text}>
						მასწავლებლების ინიციატივით tvschool.ge-მ
						მოსწავლეებისთვის ელექტრონული სერტიფიკატები მოამზადა!
					</div>
					<div className={certficiteStyles.no_certificate_text}>
						დაელოდე, როგორც კი მასწავლებელი დაადასტურებს
						სერტიფიკატის გაცემას, შეძლებ გააზიარო ან გადმოტვირთო
						შენი სერტიფიკატი!
					</div>
					<div
						className={certficiteStyles.no_certificate_undestood}
						onClick={onClose}
					>
						გასაგებია
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});

const StudentcCertificatePopup: React.FC<{
	onClose: () => void;
	path: string;
}> = React.memo(({ onClose, path }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { width } = useWindowSize();
	const isMobile = width < 600;
	const user = useClassroomsUser();

	const downloadRef = useRef<HTMLAnchorElement | null>(null);

	const handleDownload = useCallback(() => {
		downloadRef.current?.click();
	}, []);

	const handleShare = useCallback(() => {
		(window as any).FB.ui(
			{
				display: "popup",
				method: "share",
				href: `${process.env.REACT_APP_BACKEND_SERVER}/api/certificates/?imgPath=${path}&userType=${UserType.student}`,
			},
			function(response) {
				//
			}
		);
	}, [path]);

	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose
				onClose={onClose}
				popupBodyClassname={certficiteStyles.popup_body}
			>
				<div
					style={{
						width: isMobile
							? `${(width * 7) / 10}px`
							: `${(width * 3) / 5}px`,
						maxWidth: "870px",
						minWidth: "272px",
						height: "100%",
					}}
				>
					{isLoading && <Loading />}
					<div style={{ display: isLoading ? "none" : undefined }}>
						<div
							className={
								certficiteStyles.certificateTextContainer
							}
						>
							<div
								className={certficiteStyles.certificateText}
								style={{ fontFamily: "Roboto Geo Caps" }}
							>
								{`გილოცავ ${user?.firstname}`},
							</div>
							<div className={certficiteStyles.certificateText}>
								შენი მასწავლებლების ინიციატივით დისტანციურ
								სასწავლო პროცესში წარმატებული და აქტიური
								ჩართულობისთვის გაჯილდოვებთ ელექტრონული
								სერტიფიკატით!{" "}
							</div>
							<div className={certficiteStyles.certificateText}>
								Tvschool.ge კიდევ ერთხელ მადლობას გიხდის
								საგანმანათლებლო ტექნოლოგიების ეფექტიანად
								გამოყენებისთვის!
							</div>
							<div className={certficiteStyles.certificateText}>
								იმ შემთხვევაში, თუ სერტიფიკატის სახელი
								არასწორადაა მითითებული, მიმართე პედაგოგს და ის
								დაგეხმარება
							</div>
							<div
								className={certficiteStyles.certificateText}
								style={{ alignSelf: "flex-start" }}
							>
								გააზიარე და გადმოტვირთე შენი სერტიფიკატი!
							</div>
						</div>
						<div className={certficiteStyles.certificateContainer}>
							<div className={certficiteStyles.certificateBox}>
								<img
									src={path}
									alt="certificate"
									onLoad={() => setIsLoading(false)}
									className={certficiteStyles.certificate}
									style={{
										width: "100%",
										height: "100%",
									}}
								/>
							</div>
						</div>
						<div className={certficiteStyles.buttonsContainer}>
							<a
								style={{ display: "none" }}
								href={path}
								download={
									user?.firstname +
									" " +
									user?.lastname +
									".png"
								}
								ref={downloadRef}
							>
								D
							</a>
							<div
								className={certficiteStyles.downloadButton}
								onClick={handleDownload}
								style={{
									width: isMobile ? "200px" : undefined,
									borderRadius: isMobile ? " 8px" : undefined,
								}}
							>
								<DownloadIconWrapper
									style={{
										marginRight: isMobile
											? "6px"
											: undefined,
									}}
								>
									<DownloadIcon width={isMobile ? 22 : 27} />
								</DownloadIconWrapper>
								ჩამოტვირთვა
							</div>
							<div
								className={certficiteStyles.shareButton}
								onClick={handleShare}
								style={{
									width: isMobile ? "200px" : undefined,
									borderRadius: isMobile ? " 8px" : undefined,
								}}
							>
								<FacebookIconWrapper
									style={{
										marginRight: isMobile
											? "7px"
											: undefined,
									}}
								>
									<FacebookIcon width={isMobile ? 28 : 35} />
								</FacebookIconWrapper>
								გაზიარება
							</div>
						</div>
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});

const DownloadIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: #5273e6;
	}
`;
const FacebookIconWrapper = styled.div`
	margin-right: 10px;
	.st0 {
		fill: white;
	}
`;
