import { useGoToUrl } from "@app/hooks/front";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles/tvschool-classroom.module.css";
import { ReactComponent as CRT } from "./styles/imgs/CreateClassroom.svg";
import classNames from "classnames";

export const CreateClassroomBox: React.FC<{
	goto: string;
	style?: React.CSSProperties;
	theme?: "dark" | "light";
}> = ({ goto, theme, style }) => {
	const handleRedirection = useGoToUrl(goto);

	return (
		<BlueBoxMarkup
			onClick={handleRedirection}
			title={<FormattedMessage id="createClassroom" />}
			Icon={CRT}
			style={style}
			theme={theme}
		/>
	);
};

export const BlueBoxMarkup: React.FC<{
	onClick: () => void;
	title: React.ReactNode;
	Icon: typeof CRT;
	style?: React.CSSProperties;
	theme?: "dark" | "light";
}> = ({ onClick, title, Icon, style, theme }) => {
	return (
		<div
			className={classNames(
				styles.createClassroom,
				theme === "light" && styles.whiteTheme
			)}
			onClick={onClick}
			style={style}
		>
			<Icon className={styles.createClassroomBg} />
			<span className={styles.title}>{title}</span>
		</div>
	);
};
