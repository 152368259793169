import { intersectWith } from "./set";

export function uniquelize<T extends number | string>(arr: T[]): T[];
export function uniquelize<T extends any>(
	arr: T[],
	elementToKey: (el: T) => string | number | null | undefined
): T[];
export function uniquelize(
	arr: any[],
	elementToKey?: (el: any) => string | number | null | undefined
): any[] {
	const fixed = new Set<any>();
	const uniqueArr: any[] = [];
	for (const elem of arr) {
		const key = elementToKey ? elementToKey(elem) : elem;
		if (key !== undefined && key !== null) {
			if (fixed.has(key)) continue;
			fixed.add(key);
		}
		uniqueArr.push(elem);
	}
	return uniqueArr;
}

export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined
>(arr: T[], elementToKey: (el: T) => K): T[][];
export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined,
	R extends any
>(
	arr: T[],
	elementToKey: (el: T) => K,
	groupElements: (elements: T[], key: K) => R
): R[];
export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined
>(
	arr: T[],
	elementToKey: (el: T) => K,
	groupElements: (elements: T[], key: K) => any = e => e
): any[] {
	const keyToElements: Record<any, T[] | undefined> = {};
	for (const elem of arr) {
		const key = elementToKey(elem) as any;
		if (key !== undefined && key !== null) {
			if (!keyToElements[key]) keyToElements[key] = [];
			keyToElements[key]!.push(elem);
		}
	}
	const usedKeys = new Set<any>();
	const finalArr: any[] = [];
	for (const elem of arr) {
		const key = elementToKey(elem) as any;
		if (key !== undefined && key !== null) {
			if (usedKeys.has(key)) continue;
			usedKeys.add(key);
			const elements = keyToElements[key] || [elem];
			finalArr.push(groupElements(elements, key));
		} else {
			finalArr.push(groupElements([elem], key));
		}
	}
	return finalArr;
}

export function median(values: number[]): number {
	if (values.length === 0) return 0;

	values.sort(function(a, b) {
		return a - b;
	});

	const half = Math.floor(values.length / 2);

	if (values.length % 2) {
		return values[half];
	}

	return (values[half - 1] + values[half]) / 2.0;
}

export function indexArray(length: number) {
	return new Array(length).fill(0).map((e, index) => index);
}

export function mode<T extends number | string>(
	array: T[],
	defaultValue?: T
): T;
export function mode<T extends number | string>(array: T[], ...args: any[]): T {
	if (array.length === 0) {
		if (args.length > 0) {
			return args[0];
		}
		throw new Error("array Cannot be empty");
	}
	const modeMap: { [key in T]?: number } = {};
	let maxEl = array[0],
		maxCount = 1;
	for (let i = 0; i < array.length; i++) {
		const el = array[i];
		if (!modeMap[el]) {
			modeMap[el] = 1;
		} else modeMap[el]!++;
		if (modeMap[el] > maxCount) {
			maxEl = el;
			maxCount = modeMap[el]!;
		}
	}
	return maxEl;
}

export const arrayRotateLeft = <T extends any>(a: T[], n: number): T[] => {
	const newArr = [...a];
	while (n > 0) {
		newArr.push(newArr.shift()!);
		n--;
	}
	return newArr;
};

export const arrayRotateRight = <T extends any>(a: T[], n: number): T[] => {
	const newArr = [...a];
	while (n > 0) {
		newArr.unshift(newArr.pop()!);
		n--;
	}
	return newArr;
};

type allowedIdType = null | undefined | string | number;

export function haveCommonValue<T extends allowedIdType>(
	arr1: T[],
	arr2: T[]
): boolean;
export function haveCommonValue<T extends any>(
	arr1: T[],
	arr2: T[],
	mapper: (value: T) => allowedIdType
): boolean;
export function haveCommonValue<T extends any>(
	arr1: T[],
	arr2: T[],
	mapper?: (value: T) => allowedIdType
): boolean {
	const modifiedArr1 = (mapper ? arr1.map(mapper) : arr1) as allowedIdType[];
	const modifiedArr2 = (mapper ? arr2.map(mapper) : arr2) as allowedIdType[];
	const set1 = new Set(modifiedArr1);
	const set2 = new Set(modifiedArr2);
	intersectWith.call(set1 as Set<any>, set2 as Set<any>);
	return set1.size > 0;
}
