import { HeaderTree } from "@app/components/layouts/header/tree";
import {
	SELECTED_SCHOOL_LABEL_QUERY_KEY,
	useLabelNormalizerFn,
} from "@app/components/teachers/contexts/school-labels";
import {
	useSelectedSchoolLabelId,
	useSelectedSchoolLabelSetterFn,
} from "@app/components/teachers/contexts/teacher-school";
import { HoverableSelect } from "@app/components/widgets/hoverable-select";
import {
	useSchoolStructureOfCurrentUser,
	useTopLevelAccessibleSchoolLabels,
} from "@app/hooks/school-structure";
import { ObjectId } from "@app/utils/generics";
import { getReplacedQuery } from "@app/utils/urls";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { SimpleTitleNextToLogoMarkup } from "./title-next-to-logo";

export const LabelSelector = React.memo(function LabelSelector() {
	const schoolStructure = useSchoolStructureOfCurrentUser();

	const normFn = useLabelNormalizerFn();

	const selectedLabelId = useSelectedSchoolLabelId();
	const topSelectedLabelId = useMemo(
		() =>
			(selectedLabelId &&
				schoolStructure.doc?.getRootOfLabel(selectedLabelId)?._id) ??
			null,
		[schoolStructure.doc, selectedLabelId]
	);
	const setSelectedLabel = useSelectedSchoolLabelSetterFn();
	const availableLabels = useTopLevelAccessibleSchoolLabels();
	const history = useHistory();

	const handleLabelChange = (labelId: ObjectId | null) => {
		const finalLabelId = normFn(labelId, schoolStructure.doc);
		setSelectedLabel(finalLabelId);
		const query = getReplacedQuery(
			SELECTED_SCHOOL_LABEL_QUERY_KEY,
			finalLabelId
		);
		history.push(`/?${query}`);
	};
	const colors = HeaderTree.data.colors.use();
	const textColor = colors.text;

	if (!selectedLabelId || !availableLabels) {
		return null;
	}

	const labelOptions = availableLabels.map(each => ({
		value: each._id,
		label: each.name,
	}));
	const selectedLabel = labelOptions.find(
		e => e.value === topSelectedLabelId
	);

	if (labelOptions.length === 0 || !selectedLabel) return null;
	if (labelOptions.length === 1) {
		return <SimpleTitleNextToLogoMarkup title={selectedLabel.label} />;
	}

	return (
		<SimpleTitleNextToLogoMarkup
			title={
				<Selector
					title={selectedLabel.label}
					options={labelOptions}
					onChange={handleLabelChange}
					textColor={textColor}
				/>
			}
		/>
	);
});

const Selector = React.memo<{
	title: string;
	options: { value: ObjectId; label: string }[];
	onChange: (value: ObjectId) => void;
	textColor?: string;
}>(function Selector({ title, options, textColor, onChange }) {
	return (
		<HoverableSelect
			title={title}
			options={options}
			onChange={sel => onChange(sel.value)}
			actOnHover={true}
			actOnHeaderClick={true}
			containerstyles={{
				display: "inline-block",
				verticalAlign: "middle",
			}}
			styles={{
				color: textColor,
				padding: 0,
				margin: 0,
				fontFamily: "inherit",
			}}
		/>
	);
});
