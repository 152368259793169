import React, { useState, useMemo } from "react";
import { ReactComponent as DownloadIcon } from "../../styles/img/icons/Download.svg";
import { ReactComponent as CloseIcon } from "@app/components/styles/img/icons/Close.svg";
import styled from "@emotion/styled";
import { GoogleDocsViewerWrapper } from "./google-docs-viewer";
import { FilePreview } from "./file-preview";
import { WordFileIcon, FileIcon, PPTXIcon, ExcelFileIcon } from "@app/icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface FileBoxProps {
	fileName: string;
	fileUrl: string;
	canDownload: boolean;
	showPreviewOnClick: boolean;
	marginRight?: number;
	marginLeft?: number;
	onDelete?: () => void;
	onClick?: () => void;
}
export const FileBox: React.FC<FileBoxProps> = ({
	canDownload,
	fileName,
	fileUrl,
	showPreviewOnClick,
	marginLeft,
	marginRight,
	onDelete,
}) => {
	const [preview, setPreview] = useState<string | null>(null);
	const downloadRef = React.createRef<HTMLAnchorElement>();

	const handleDownload = () => {
		if (downloadRef.current) {
			downloadRef.current.click();
		}
	};
	return (
		<div style={{ marginLeft, marginRight }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<a
					style={{ display: "none", cursor: "pointer" }}
					href={fileUrl}
					download={fileName}
					ref={downloadRef}
				>
					D
				</a>
				<FileBoxContainer>
					<div
						onClick={() =>
							showPreviewOnClick ? setPreview(fileUrl) : undefined
						}
					>
						{fileName.length > 6
							? fileName.substr(0, 5).concat("...")
							: fileName}
					</div>
					{onDelete && (
						<CloseIcon
							style={{
								marginLeft: "4px",
								width: "12px",
								height: "12px",
								flexShrink: 0,
							}}
							onClick={onDelete}
						/>
					)}
				</FileBoxContainer>
				{canDownload && (
					<DownloadIcon
						style={{
							cursor: "pointer",
							marginLeft: "8px",
							height: "18px",
							width: "18px",
						}}
						onClick={() => handleDownload()}
					/>
				)}
			</div>
			{preview && (
				<FilePreview
					onClose={() => setPreview(null)}
					fileUrl={preview}
					fileName={fileName}
				/>
			)}
		</div>
	);
};

const FileBoxContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 8px;
	border-radius: 20px;
	cursor: pointer;
	font-size: 14px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: var(--white);
	font-family: "Roboto Geo Nus";
	.st0 {
		fill: red;
	}
`;

interface FancyFileBox {
	fileName: string;
	fileUrl: string;
	showPreviewOnClick: boolean;
	marginRight?: number;
	marginLeft?: number;
}

export const FancyFileBox: React.FC<FancyFileBox> = ({
	fileName,
	fileUrl,
	showPreviewOnClick,
	marginLeft,
	marginRight,
}) => {
	const [preview, setPreview] = useState<string | null>(null);

	const fileExtension = useMemo(
		() => fileUrl.substr(fileUrl.lastIndexOf("."), fileUrl.length),
		[fileUrl]
	);

	const iconsStyle: CSSProperties = {
		width: "55px",
		height: "55px",
	};

	const appropriateIcon = useMemo(() => {
		if (imageTypes.indexOf(fileExtension) !== -1) {
			return (
				<img
					alt="ext"
					src={fileUrl}
					style={{
						width: "70px",
						height: "70px",
						borderRadius: "20px",
					}}
				/>
			);
		}
		switch (fileExtension) {
			case ".docx":
				return (
					<WordFileIcon
						style={{
							...iconsStyle,
							fill: "#1d7044",
							stroke: "#1d7044",
							strokeWidth: "2px",
						}}
					/>
				);
			case ".pptx":
				return (
					<PPTXIcon
						style={{
							...iconsStyle,
							fill: "#ce4123",
							stroke: " #ce4022",
							strokeWidth: "3px",
						}}
					/>
				);
			case ".xlsx":
				return <ExcelFileIcon style={iconsStyle} />;
			default:
				return <FileIcon style={iconsStyle} />;
		}
	}, [fileExtension, fileUrl, iconsStyle]);

	console.log(fileExtension);
	return (
		<div style={{ marginLeft, marginRight }}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<FancyFileBoxContainer
					onClick={() =>
						showPreviewOnClick ? setPreview(fileUrl) : undefined
					}
				>
					{appropriateIcon}
				</FancyFileBoxContainer>
			</div>
			{preview && (
				<FilePreview
					onClose={() => setPreview(null)}
					fileUrl={preview}
				/>
			)}
		</div>
	);
};

const imageTypes = [
	".jpg",
	".jpeg",
	".jfif",
	".pjpeg",
	".pjp",
	".png",
	".svg",
	".gif",
];

const FancyFileBoxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	border-radius: 20px;
	background-color: white;
	cursor: pointer;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	.cls-1 {
	}
`;
