import { TestType } from "@app/models/test-type";
import { inject } from "@app/modules";
import { createModelSingleCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import { ITestType } from "./helper-schemas";
import {
	IADELETETestType,
	IAGETManyTestTypesByIds,
	IAGETTestType,
	IAPOSTCreateTestType,
	IAPUTTestType,
	IRGETAllTestTypes,
	IRGETManyTestTypesByIds,
	IRPOSTCreateTestType,
	RGETAllTestTypesSchema,
	RGETManyTestTypesByIdsSchema,
	RGETTestTypeSchema,
	RPOSTCreateTestTypeSchema,
} from "./validators";

export class TestTypesController {
	private readonly Request: IRequest;

	private readonly _TestTypeModel = inject("TestTypeModel");

	private testTypePromises = createModelSingleCollmise({
		model: this._TestTypeModel,
		name: "TestType",
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateTestType): Promise<TestType> =>
		this.Request.send("POST", "/api/test-types", args, null, {
			responseSchema: RPOSTCreateTestTypeSchema,
		}).then((data: IRPOSTCreateTestType) => {
			return this._TestTypeModel.loadOneSync(data as ITestType);
		});

	update = async (args: IAPUTTestType): Promise<void> =>
		this.Request.send("PUT", "/api/test-types/:_id", args).then(() => {
			this._TestTypeModel.updateOneSync({ _id: args._id }, args);
		});

	getById = async (
		args: IAGETTestType,
		loadFresh = false
	): Promise<TestType> => {
		return this.testTypePromises
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/test-types/:_id", args, null, {
					responseSchema: RGETTestTypeSchema,
				})
			);
	};

	getAll = async (loadFresh = false): Promise<TestType[]> => {
		if (!loadFresh && this._TestTypeModel.meta.data.lastFullLoadTime) {
			return this._TestTypeModel.getAllSync();
		}
		return this.Request.send("GET", "/api/test-types/", undefined, null, {
			responseSchema: RGETAllTestTypesSchema,
		}).then((data: IRGETAllTestTypes) => {
			this._TestTypeModel.meta.updateLoadTime();
			return this._TestTypeModel.loadManySync(data);
		});
	};

	deleteById = async (args: IADELETETestType): Promise<void> =>
		this.Request.send("DELETE", "/api/test-types/:_id", args).then(() => {
			this._TestTypeModel.deleteByIdSync(args._id);
		});

	getManyByIds = async (args: IAGETManyTestTypesByIds): Promise<TestType[]> =>
		this.Request.send(
			"POST",
			"/api/test-types/get-many-by-ids",
			args,
			null,
			{
				responseSchema: RGETManyTestTypesByIdsSchema,
			}
		).then((data: IRGETManyTestTypesByIds) => {
			return this._TestTypeModel.loadManySync(data, args.testTypeIds);
		});
}
