import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";

export const APOSTPopulateSchoolChatUsersSchema = Joi.object({
	school: UnsignedIntegerSchema,
});
export interface IAPOSTPopulateSchoolChatUsers {
	school: number;
}

export const RGETChatTokenSchema = Joi.object({
	token: Joi.string().required(),
});

export interface IRGETChatToken {
	token: string;
}
