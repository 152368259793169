import { IRequest } from "@app/api/requests";
import { SchoolLessonSchedule } from "@app/models/school-lesson-schedule";
import { inject } from "@app/modules";
import { collmise } from "collmise";
import {
	AGETSchoolLessonScheduleSchema,
	APUTSchoolLessonScheduleSchema,
	IAGETSchoolLessonSchedule,
	IAPUTSchoolLessonSchedule,
	IRGETSchoolLessonSchedule,
	IRPUTSchoolLessonSchedule,
} from "./validators";

export class SchoolLessonScheduleController {
	private readonly Request: IRequest;

	private readonly SchoolLessonScheduleModel = inject(
		"SchoolLessonScheduleModel"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	save = async (
		args: IAPUTSchoolLessonSchedule
	): Promise<IRPUTSchoolLessonSchedule> =>
		this.Request.send(
			"PUT",
			"/api/schools/:schoolId/lesson-schedules/",
			args,
			null,
			{ requestSchema: APUTSchoolLessonScheduleSchema }
		);

	private schedulePromises = collmise<
		number,
		IRGETSchoolLessonSchedule,
		SchoolLessonSchedule
	>({
		findCachedData: schoolId =>
			this.SchoolLessonScheduleModel.findBySchool(schoolId),
		dataTransformer: data =>
			this.SchoolLessonScheduleModel.loadOneSync(data),
	});

	getBySchool = async (
		args: IAGETSchoolLessonSchedule,
		loadFresh = false
	): Promise<SchoolLessonSchedule> => {
		return this.schedulePromises
			.on(args.schoolId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/schools/:schoolId/lesson-schedules/",
					args,
					null,
					{ requestSchema: AGETSchoolLessonScheduleSchema }
				)
			);
	};
}
