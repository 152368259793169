import { JoiValidationDetail } from "@app/utils/joi-errors";
import {
	getErrorDetailInfo,
	ErrorDetailedInfo,
	ErrorType,
} from "@app/validations";
import { getLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";

const errorKeysToLocaleInputKeys = {
	firstname: "inputs.firstname",
	lastname: "inputs.lastname",
	mobile: "inputs.mobile",
	password: "inputs.password",
	school: "inputs.school",
	country: "inputs.country",
	city: "inputs.city",
	mail: "inputs.mail",
	specialCode: "inputs.specialCode",
};

export const getAuthErrors = <
	Errors extends { [key: string]: string | undefined | null }
>(
	errors: JoiValidationDetail[],
	availableKeys?: (keyof Errors)[]
): { [key in keyof Errors]?: Errors[key] } | null => {
	const locale = getLocale();
	const ErrorsObj = {} as Errors;
	for (const error of errors) {
		const hasToCheckForThisKey = !availableKeys
			? true
			: availableKeys.indexOf(error.mainKey) > -1;
		if (!hasToCheckForThisKey) continue;
		const inputName = getFormattedMessage(
			errorKeysToLocaleInputKeys[error.mainKey as any] || ""
		);

		if (!inputName) continue;
		const errorDetailInfo = getErrorDetailInfo({
			errorItem: error,
			locale,
			itemExtraInfo: { inputName, itemOriginalName: error.mainKey },
		});
		const message = getErrorMessage(locale, errorDetailInfo);
		if (message === undefined) continue;
		ErrorsObj[error.mainKey as keyof Errors] = message as any;
	}
	if (Object.keys(ErrorsObj).length === 0) return null;
	return ErrorsObj;
};

const getErrorMessage = (
	locale: string,
	errorDetailInfo: ErrorDetailedInfo<
		{
			inputName: string;
			itemOriginalName: string;
		},
		string
	>
): string => {
	const {
		itemExtraInfo: { inputName, itemOriginalName },
	} = errorDetailInfo;
	if (errorDetailInfo.type === ErrorType.noKnownErrorFound) {
		return "";
	}
	if (errorDetailInfo.type === ErrorType.emptyValue) {
		return getFormattedMessage("validations.emptyValue", {
			field: inputName,
		});
	}
	if (errorDetailInfo.type === ErrorType.incorrectEmail) {
		return getFormattedMessage("validations.incorrectEmail", {
			field: inputName,
		});
	}
	if (errorDetailInfo.type === ErrorType.stringMinLength) {
		if (itemOriginalName === "mobile") {
			return getFormattedMessage("validations.mobileMinLength", {
				field: inputName,
				requiredLength: errorDetailInfo.requiredLength,
			});
		}
		if (itemOriginalName === "isChecked") {
			return getFormattedMessage("validations.teacherHasToBeChecked", {
				field: inputName,
				requiredLength: errorDetailInfo.requiredLength,
			});
		}

		if (itemOriginalName === "specialCode") {
			return getFormattedMessage("validations.specialCodeNotCorrect", {
				field: inputName,
			});
		}

		return getFormattedMessage("validations.stringMinLength", {
			field: inputName,
			requiredLength: errorDetailInfo.requiredLength,
		});
	}

	if (errorDetailInfo.type === ErrorType.stringFixedLength) {
		if (itemOriginalName === "mobile") {
			return getFormattedMessage("validations.mobileFixedLength", {
				field: inputName,
				requiredLength: errorDetailInfo.requiredLength,
			});
		}
		return getFormattedMessage("validations.stringFixedLength", {
			field: inputName,
			requiredLength: errorDetailInfo.requiredLength,
		});
	}
	return "";
};
