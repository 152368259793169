import styled from "react-emotion";

export const MainButton = styled("button")(
	{
		height: 40,
		border: "none",
		borderRadius: 20,
		color: "white",
		background: "#5c5de8",
		fontSize: 18,
		fontFamily: "Roboto Geo Nus",
		cursor: "pointer",
		outline: "none",
		padding: "0px 40px",
		marginTop: 30,
		boxShadow: "0 2px 0 #393aa7",
		transition: "0.3s",
		"&:hover": {
			background: "#4c4dce",
		},
	},
	(props: { isFaded?: boolean }) => {
		if (props.isFaded) return { opacity: 0.2 };
		return;
	}
);
