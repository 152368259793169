import {
	IFolderSingleItem,
	IRFolder,
	ItemType,
} from "../../api/folders/helper-schemas";
import {
	IAPUTMoveFolder,
	IAGETFolderItemsRecursively,
	IRGETFolderItemsRecursively,
} from "../../api/folders/validators";
import { MError } from "../../utils/errors";
import UserFolderProgressService from "../folder-progress";
import { IUpdatedIds } from "../hierarchy-info/clonnable";
import FolderHierarchyService from "../hierarchy-info/folders";
import { IHierarchyInfo } from "../hierarchy-info/interfaces";
import { CoursesUser } from "../../user/courses-user";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { IFolderModel } from "@app/models/folder";
import { IFileModel } from "@app/models/file";
import { IUserFolderProgressModel } from "@app/models/user-folder-progress";
import { ITestModel } from "@app/models/test";
import { ICardModel } from "@app/models/card";
import { arrayToObject, subtractSet } from "@app/utils/common";

export interface IFolderItemInfos {
	folders: {
		[folderId: string]: IRFolder | undefined;
	};
	files: {
		[fileId: string]: true | undefined;
	};
	cards: {
		[cardId: string]: true | undefined;
	};
	questions: {
		[questionId: string]: true | undefined;
	};
	tests: {
		[tests: string]: true | undefined;
	};
}

interface IAAddItemInParent {
	courseId: ObjectId;
	parentFolderId: ObjectId;
	item: IFolderSingleItem;
}

interface IAUpdateItemInParent {
	item: Partial<IFolderSingleItem> & Pick<IFolderSingleItem, "id" | "type">;
	courseId: ObjectId;
	parentFolderId?: ObjectId;
	upsert?: boolean;
}

interface IASetItemParentsInCourse {
	item: Exclude<
		Partial<IFolderSingleItem> &
			Pick<IFolderSingleItem, "id" | "type" | "name">,
		{ type: ItemType.folder }
	>;
	courseId: ObjectId;
	newParentFolderIds: ObjectId[];
	isNewlyCreatedItem: boolean;
}

interface IADeleteItemInParent {
	itemId: ObjectId;
	courseId: ObjectId;
	type: ItemType;
	parentFolderId: ObjectId;
	deleteOnlyInParentFolder?: boolean;
	bypassUniqueness?: boolean;
}
interface IADeleteItemInParent2 {
	itemId: ObjectId;
	courseId: ObjectId;
	type: ItemType;
	parentFolderId?: undefined;
	deleteOnlyInParentFolder?: boolean;
	bypassUniqueness?: boolean;
}

export default class FolderItemsService {
	// @inject(TYPES.MODELS.FolderItems)
	// private readonly _FolderItems: IFolderItemModel;

	private readonly _Folder: IFolderModel = inject("FolderModel");

	private readonly _File: IFileModel = inject("FileModel");

	private readonly _Test: ITestModel = inject("TestModel");

	private readonly _Card: ICardModel = inject("CardModel");

	_UserFolderProgressService: UserFolderProgressService = inject(
		"UserFolderProgressService"
	);

	private readonly _UserFolderProgress: IUserFolderProgressModel = inject(
		"UserFolderProgressModel"
	);

	private readonly _FolderHierarchyService: FolderHierarchyService = inject(
		"FolderHierarchyService"
	);

	/**
	 * Adds items to folder and returns id of the parent folder,
	 * in case cloning occured.
	 */
	addItemInParentSync = ({
		parentFolderId,
		item,
		courseId,
	}: IAAddItemInParent): ObjectId => {
		const needsCloning = this._FolderHierarchyService.needsCloningSync(
			courseId,
			parentFolderId
		);
		if (needsCloning) {
			const updatedIds = this._FolderHierarchyService.cloneHierarchySync(
				courseId,
				parentFolderId
			);
			parentFolderId = updatedIds[parentFolderId]!;
		}
		this._Folder.addItemInParentSync(parentFolderId, item);
		if (this.isProgressAffectingType(item.type)) {
			this._UserFolderProgressService.polluteParentFoldersForAllUsersSync(
				courseId,
				parentFolderId
			);
		}
		if (item.type === ItemType.folder) {
			this._FolderHierarchyService.setItemParentsForAllCoursesSync(
				item.id,
				parentFolderId
			);
		}

		if (!this.hasUniqueParent(item.type)) {
			//
		} else {
			this.updateItemParentSync(item.id, item.type, parentFolderId);
		}

		return parentFolderId;
	};

	/**
	 * Removes item from folder and returns id of the parent folder,
	 * in case cloning occured.
	 */
	deleteItemSync(args: IADeleteItemInParent2): ObjectId[];
	deleteItemSync(args: IADeleteItemInParent): ObjectId;
	// tslint:disable-next-line:cognitive-complexity
	deleteItemSync({
		courseId,
		deleteOnlyInParentFolder,
		itemId,
		parentFolderId,
		type,
		bypassUniqueness,
	}: IADeleteItemInParent | IADeleteItemInParent2): ObjectId | ObjectId[] {
		if (parentFolderId !== undefined) {
			// check if parent folder hierarchy needs to be cloned
			const needsCloning = this._FolderHierarchyService.needsCloningSync(
				courseId,
				parentFolderId
			);
			if (needsCloning) {
				parentFolderId = this._FolderHierarchyService.cloneHierarchySync(
					courseId,
					parentFolderId
				)[parentFolderId]!;
			}
			if (
				!bypassUniqueness &&
				!deleteOnlyInParentFolder &&
				!this.hasUniqueParent(type)
			) {
				throw new Error(
					"If parentFolderId is passed, the item's type must have a unique parent"
				);
			}
			this._Folder.deleteItemInParentSync(parentFolderId, itemId);
			if (!this.hasUniqueParent(type)) {
				if (this.isProgressAffectingType(type)) {
					this._UserFolderProgressService.polluteParentFoldersForAllUsersSync(
						courseId,
						parentFolderId
					);
					this._UserFolderProgress.removeItemsFromFoldersSync({
						courseId,
						folderId: parentFolderId,
						itemId,
						itemType: type,
					});
				}
			}

			if (type === ItemType.folder) {
				this._FolderHierarchyService.onItemDeleteSync(courseId, itemId);
			}

			return parentFolderId;
		} else {
			if (!bypassUniqueness && this.hasUniqueParent(type)) {
				throw new Error(
					"If parentFolderId isn't passed, the item's type must have multiple parents"
				);
			}

			// get ids of folders, which contain given item
			let parentFolderIds = this._Folder
				.findManyByItemsSync(itemId, type)
				.map(e => e._id);

			// check folders that need to be cloned
			const folderIdsToClone: ObjectId[] = this._FolderHierarchyService.filterItemsToCloneSync(
				courseId,
				parentFolderIds
			);

			// clone hierarchies and update ids
			const updatedIds = this._FolderHierarchyService.cloneHierarchyForSeveralItemsSync(
				courseId,
				folderIdsToClone
			);
			parentFolderIds = parentFolderIds.map(id => updatedIds[id] || id);

			if (parentFolderIds.length > 0) {
				parentFolderIds.forEach(folderId => {
					this._Folder.deleteItemInParentSync(folderId, itemId);
					this._UserFolderProgressService.polluteParentFoldersForAllUsersSync(
						courseId,
						folderId
					);
				});
			}
			return parentFolderIds;
		}
	}

	addManyItemsInParentSync = (
		items: IFolderSingleItem[],
		courseId: ObjectId,
		folderId: ObjectId
	): void => {
		for (let i = 0; i < items.length; i++) {
			this.addItemInParentSync({
				courseId,
				parentFolderId: folderId,
				item: items[i],
			});
		}
	};

	deleteManyItemsInFolderSync = (
		itemIds: ObjectId[],
		type: ItemType,
		courseId: ObjectId,
		folderId?: ObjectId
	): void => {
		for (let i = 0; i < itemIds.length; i++) {
			this.deleteItemSync({
				parentFolderId: folderId as undefined,
				itemId: itemIds[i],
				type,
				courseId,
			});
		}
	};

	getFolderIdsOfItemSync = (args: {
		courseId: ObjectId;
		itemId: ObjectId;
		itemType: ItemType;
	}): ObjectId[] => {
		try {
			const allFolderIds = this._FolderHierarchyService.getAllItemIdsSync(
				args.courseId
			);
			return this._Folder.getFolderIdsWithItemSync(
				{
					id: args.itemId,
					type: args.itemType,
				},
				allFolderIds
			);
		} catch (e) {
			return [];
		}
	};

	setItemParentsInCourseSync = ({
		courseId,
		item,
		newParentFolderIds,
		isNewlyCreatedItem,
	}: IASetItemParentsInCourse) => {
		const currentFolderIds = isNewlyCreatedItem
			? []
			: this.getFolderIdsOfItemSync({
					courseId: courseId,
					itemId: item.id,
					itemType: item.type,
			  });
		const oldIds = new Set(currentFolderIds);
		const newIds = new Set(newParentFolderIds);
		const addedIds = subtractSet.call(new Set(newIds), oldIds) as Set<
			string
		>;
		const removedIds = subtractSet.call(new Set(oldIds), newIds) as Set<
			string
		>;
		if (addedIds.size > 0) {
			this._Folder.addItemInParentSync([...addedIds], item); // potential bug if folders needs cloning
		}
		if (removedIds.size > 0) {
			this._Folder.deleteItemInParentSync([...removedIds], item.id);
		}
	};

	updateItemSync = ({
		item,
		parentFolderId,
		courseId,
		upsert,
	}: IAUpdateItemInParent) => {
		const folderIds: ObjectId[] = [];

		// single or multiple parent(s)
		if (parentFolderId !== undefined) {
			if (!this.hasUniqueParent(item.type)) {
				throw new Error(
					"If parentFolderId is passed, the item's type must have a unique parent"
				);
			}
			folderIds.push(parentFolderId);
		} else {
			if (this.hasUniqueParent(item.type)) {
				throw new Error(
					"If parentFolderId isn't passed, the item's type must have multiple parents"
				);
			}
			folderIds.push(
				...this._Folder
					.findManyByItemsSync(item.id, item.type)
					.map(e => e._id)
			);
		}

		const folderIdsToClone: ObjectId[] = this._FolderHierarchyService.filterItemsToCloneSync(
			courseId,
			folderIds
		);

		const updatedIds: IUpdatedIds = this._FolderHierarchyService.cloneHierarchyForSeveralItemsSync(
			courseId,
			folderIdsToClone
		);

		folderIds.forEach(currentId => {
			const id = updatedIds[currentId] || currentId;
			this._Folder.updateItemInParentSync(id, item, upsert);
		});
	};

	moveToAnotherFolderSync = (args: IAPUTMoveFolder, user: CoursesUser) => {
		const { courseId, itemId, itemType } = args;
		// values of folder ids may change as a result of cloning
		let { currentFolderId, newFolderId } = args;

		if (currentFolderId === newFolderId) {
			throw new MError(400, "cannot move to oneself");
		}

		if (!user.canAccessCourse(courseId)) {
			throw new MError(400, "cannot access the course");
		}

		const currentFolder = this._Folder.findByIdSync(currentFolderId);
		const newFolder = this._Folder.findByIdSync(newFolderId);
		if (!currentFolder || !newFolder) {
			throw new MError(404, "incorrect folder ids");
		}

		if (!currentFolder.items) {
			throw new MError(400, "current folder is empty");
		}

		if (
			newFolder.items &&
			newFolder.items.some(el => el.id === itemId && el.type === itemType)
		) {
			throw new MError(
				400,
				"item already exists in the destination folder"
			);
		}

		const item = currentFolder.items.find(
			e => e.id === itemId && e.type === itemType
		);
		if (!item) {
			throw new MError(404, `item ${itemId} not found`);
		}
		if (this.isProgressAffectingType(itemType)) {
			this._UserFolderProgressService.copyItemProgressToAnotherFolderSync(
				courseId,
				currentFolderId,
				newFolderId,
				itemId,
				itemType
			);
		}
		currentFolderId = (this.deleteItemSync({
			type: itemType,
			itemId,
			parentFolderId: currentFolderId,
			deleteOnlyInParentFolder: true,
			courseId,
		}) as any) as ObjectId;
		newFolderId = this.addItemInParentSync({
			item,
			courseId,
			parentFolderId: newFolder._id,
		});

		if (this.isProgressAffectingType(itemType)) {
			this._UserFolderProgressService.polluteParentFoldersForAllUsersSync(
				courseId,
				currentFolderId
			);
			this._UserFolderProgressService.polluteParentFoldersForAllUsersSync(
				courseId,
				newFolderId
			);
		}
	};

	updateItemParentSync = (
		itemId: ObjectId,
		itemType: ItemType,
		newParentId: ObjectId
	) => {
		if (!this.hasUniqueParent(itemType)) return;
		if (itemType === ItemType.file) {
			this._File.updateOneSync(
				{ _id: itemId },
				{ folderId: newParentId }
			);
		} else {
			throw new Error(
				`We have forgotten to change the parentId after moving to ` +
					`another folder for item: id - ${itemId}, type - ${itemType} `
			);
		}
	};

	getAllDescendentItemIdsSync = (args: {
		folderId: ObjectId;
		courseId: ObjectId;
		depth?: number;
	}) => {
		const items = this.itemsSearchSync(
			args.courseId,
			args.folderId,
			undefined,
			args.depth
		);
		return items;
	};

	searchForAllItemsRecursivelySync = (
		args: IAGETFolderItemsRecursively,
		userId?: number
	) => {
		const items = this.getAllDescendentItemIdsSync(args);
		const result: IRGETFolderItemsRecursively = {};
		if (
			args.itemTypes === undefined ||
			args.itemTypes.indexOf(ItemType.folder) >= 0
		) {
			result.folders = items.folders;
		}

		const cardIds = Object.keys(items.cards);

		const files =
			args.itemTypes === undefined ||
			args.itemTypes.indexOf(ItemType.file) >= 0
				? this._File.findManyByIdsSync(Object.keys(items.files))
				: undefined;
		const tests =
			args.itemTypes === undefined ||
			args.itemTypes.indexOf(ItemType.test) >= 0
				? this._Test.findManyByIdsSync(Object.keys(items.tests))
				: undefined;
		const cards =
			args.itemTypes === undefined ||
			args.itemTypes.indexOf(ItemType.card) >= 0
				? this._Card.findManyByIdsSync(cardIds)
				: undefined;
		const questions =
			args.itemTypes === undefined ||
			args.itemTypes.indexOf(ItemType.question) >= 0
				? {}
				: undefined;

		if (files) result.files = arrayToObject(files, "_id") || {};
		if (tests) result.tests = arrayToObject(tests, "_id") || {};
		if (questions) result.questions = questions;
		if (cards) result.cards = arrayToObject(cards, "_id") || {};

		return result;
	};

	itemsSearchSync = (
		courseId: ObjectId,
		startFolderId: ObjectId,
		hierarchy: IHierarchyInfo | undefined,
		depth = Infinity
	): IFolderItemInfos => {
		const result: IFolderItemInfos = {
			folders: {},
			cards: {},
			files: {},
			questions: {},
			tests: {},
		};
		const foundFolderIds = this._FolderHierarchyService.getDescendantIdsSync(
			courseId,
			startFolderId,
			depth,
			hierarchy
		);
		foundFolderIds.push(startFolderId);

		const folders = this._Folder.findManyByIdsSync(foundFolderIds);

		for (const folder of folders) {
			// insert current folder
			result.folders[folder._id] = folder as IRFolder;

			// check items of current folder
			if (folder.items && folder.items.length > 0) {
				for (const item of folder.items) {
					const itemId = item.id;
					const strigifiedItemId = itemId;

					switch (item.type) {
						case ItemType.card:
							result.cards[strigifiedItemId] = true;
							break;
						case ItemType.question:
							result.questions[strigifiedItemId] = true;
							break;
						case ItemType.file:
							result.files[strigifiedItemId] = true;
							break;
						case ItemType.test:
							result.tests[strigifiedItemId] = true;
							break;
					}
				}
			}
		}

		return result;
	};

	itemsSearchByMyltipleFoldersSync = (
		courseId: ObjectId,
		hierarchy: IHierarchyInfo | undefined,
		folderIds: ObjectId[],
		depth?: number
	): IFolderItemInfos => {
		const infos: IFolderItemInfos[] = [];
		for (const folderId of folderIds) {
			infos.push(
				this.itemsSearchSync(
					courseId,
					folderId,
					hierarchy,
					depth ? depth - 1 : undefined
				)
			);
		}

		const result: IFolderItemInfos = {
			cards: {},
			files: {},
			folders: {},
			questions: {},
			tests: {},
		};

		infos.forEach(info => {
			for (const key in info) {
				if (info.hasOwnProperty(key)) {
					for (const id in info[key]) {
						if (info[key].hasOwnProperty(id)) {
							result[key][id] = info[key][id];
						}
					}
				}
			}
		});

		return result;
	};

	hasUniqueParent = (type: ItemType) => {
		return type === ItemType.file;
	};

	cloneFolderWithDescendants = (
		source: {
			folderId: ObjectId;
			courseId: ObjectId;
		},
		destination: {
			folderId: ObjectId;
			courseId: ObjectId;
		},
		author: number
	): ObjectId => {
		throw new Error("cloneFolderWithDescendants is not supported");
		// eslint-disable-next-line no-unreachable
		return "" as ObjectId;
	};

	isProgressAffectingType = (type: ItemType): boolean => {
		switch (type) {
			case ItemType.folder:
			case ItemType.file:
			case ItemType.test:
				return true;
			default:
				return false;
		}
	};
}
