import React, { useCallback } from "react";
import { ReactComponent as BurgerIcon } from "@app/components/users/header/styles/img/burger-icon.svg";
import styles from "./styles.module.scss";
import { useBoolean } from "@app/hooks/general";
import { MenuOptions } from "@app/components/widgets/hoverable-select";
import { HeaderTree } from "./tree";

const BurgerMenu = React.memo<{}>(function BurgerMenu({}) {
	const {
		value: isOptionsVisible,
		switchValue: toggleOptions,
		setFalse: closeOptions,
	} = useBoolean(false);

	const options = HeaderTree.data.menuOptions.use();

	const onSelect = useCallback(
		(option: { value: any }) => {
			const selectedOption = options.find(e => e.value === option.value);
			if (!selectedOption) return;
			closeOptions();
			selectedOption.onSelect?.();
		},
		[closeOptions, options]
	);

	return (
		<>
			<BurgerIcon className={styles.burgerIcon} onClick={toggleOptions} />

			{isOptionsVisible && (
				<div className={styles.menu} style={{ color: "black" }}>
					<MenuOptions onSelect={onSelect} options={options} />
				</div>
			)}
		</>
	);
});

export const HeaderMenu = {
	BurgerMenu,
};
