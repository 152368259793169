import { ObjectId } from "@app/utils/generics";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import {
	WeeklyTestParentSchema,
	IWeeklyTestParent,
	IWeeklyTestReservation,
	WeeklyTestReservationSchema,
	WeeklyTestPaymentScheduleType,
	WeeklyTestPaymentScheduleTypes,
	WeekTypeValuesSchema,
	WeekType,
} from "./helper-schemas";

export const APOSTWeeklyTestReservationSchema = Joi.object({
	subjectIds: Joi.array().items(Joi.objectId().required()),
	paymentSchedule: Joi.any().valid(...WeeklyTestPaymentScheduleTypes),
	parent: WeeklyTestParentSchema,
	mobileConfirmationToken: Joi.string(),
	weekType: WeekTypeValuesSchema.required(),
});

export interface IAPOSTWeeklyTestReservation {
	subjectIds?: ObjectId[];
	paymentSchedule?: WeeklyTestPaymentScheduleType;
	parent?: IWeeklyTestParent;
	mobileConfirmationToken?: string;
	weekType: WeekType;
}

export const RPOSTWeeklyTestReservationSchema = WeeklyTestReservationSchema;
export type IRPOSTWeeklyTestReservation = IWeeklyTestReservation;

///

export const AGETWeeklyTestReservationSchema = Joi.object({
	weekType: WeekTypeValuesSchema.required(),
});
export interface IAGETWeeklyTestReservation {
	weekType: WeekType;
}

export const RGETWeeklyTestReservationSchema = WeeklyTestReservationSchema;
export type IRGETWeeklyTestReservation = IWeeklyTestReservation;

///

export const AGETWeeklyTestReservationByUserSchema = Joi.object({
	id: UnsignedIntegerSchema.required(),
	weekType: WeekTypeValuesSchema.required(),
});
export interface IAGETWeeklyTestReservationByUser {
	id: number;
	weekType: WeekType;
}
export const RGETWeeklyTestReservationByUserSchema = WeeklyTestReservationSchema;
export type IRGETWeeklyTestReservationByUser = IWeeklyTestReservation;

///

export const APUTWeeklyTestCourseSchema = Joi.object({
	subjectId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	weekType: WeekTypeValuesSchema.required(),
});

export interface IAPUTWeeklyTestCourse {
	subjectId: ObjectId;
	courseId: ObjectId;
	weekType: WeekType;
}

export const RPUTWeeklyTestCourseSchema = WeeklyTestReservationSchema;
export type IRPUTWeeklyTestCourse = IWeeklyTestReservation;

///

export const APOSTWeeklyCertificatesSchema = Joi.object({
	subjectId: Joi.objectId().required(),
	fullname: Joi.string().required(),
	weekType: WeekTypeValuesSchema.required(),
});
export interface IAPOSTWeeklyCertificates {
	subjectId: ObjectId;
	fullname: string;
	weekType: WeekType;
}

export const RPOSTWeeklyCertificatesSchema = Joi.object({
	url: Joi.string().required(),
	fbImgUrl: Joi.string().required(),
});
export interface IRPOSTWeeklyCertificates {
	url: string;
	fbImgUrl: string;
}

export interface IAGETOnlinePaymentPurpose {
	weekType: WeekType;
}
///
