import { IRequest } from "@app/api/requests";
import {
	IRGETTeacherCachedGradesManySchema,
	ITeacherCachedGrades,
	TeacherCachedGradesSchema,
} from "./helper-schemas";
import { IAGETCachedGrades } from "./validators";

export class TeacherCachedGradesController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	getOne = async (
		args: IAGETCachedGrades
	): Promise<ITeacherCachedGrades | null> => {
		return this.Request.send(
			"GET",
			"/api/gradebook/teacher-cached-grades",
			args as IAGETCachedGrades,
			null,
			{
				responseSchema: TeacherCachedGradesSchema,
			}
		);
	};

	getMany = async (): Promise<ITeacherCachedGrades[]> =>
		this.Request.send(
			"GET",
			"/api/gradebook/teacher-cached-grades/all",
			{},
			null,
			{
				responseSchema: IRGETTeacherCachedGradesManySchema,
			}
		);
}
