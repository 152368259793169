import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { BasicDocumentSchema, BasicDocument } from "../interface";

export enum SummonsInfoType {
	custom = "CUST",
	forFutureUse = "F",
}

const CustomSummonsInfoSchema = Joi.object({
	type: Joi.any()
		.valid(SummonsInfoType.custom)
		.required(),
	key: Joi.string().required(),
	payload: Joi.any(),
});

export interface CustomSummonsInfo {
	type: SummonsInfoType.custom;
	key: string;
	payload?: unknown;
}

///

const FutureUseSummonsInfoSchema = Joi.object({
	type: Joi.any()
		.valid(SummonsInfoType.forFutureUse)
		.required(),
});

interface FutureUseSummonsInfo {
	type: SummonsInfoType.forFutureUse;
}

///

const SummonsInfo = Joi.alternatives(
	CustomSummonsInfoSchema,
	FutureUseSummonsInfoSchema
);
export type SummonsInfo = CustomSummonsInfo | FutureUseSummonsInfo;

///

export const SummonsSchema = BasicDocumentSchema.keys({
	author: Joi.number()
		.allow(null)
		.required(),
	audienceId: Joi.objectId().required(),
	info: SummonsInfo.required(),
	isArchived: Joi.boolean(),
});

export interface ISummons extends BasicDocument {
	author: number | null;
	info: SummonsInfo;
	audienceId: ObjectId;
	isArchived?: boolean;
}

///

export const SummonsWithIsInAudienceSchema = SummonsSchema.keys({
	isInAudience: Joi.boolean().required(),
});

export interface ISummonsWithIsInAudience extends ISummons {
	isInAudience: boolean;
}
