import React from "react";
import styles from "./styles/blog.module.css";
import { FormattedMessage } from "react-intl";
import { useMonthsShort } from "@app/hooks/dates";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ObjectId } from "@tests-core/utils/joi";

export interface ILittleBlogBoxProps {
	blog: {
		title: string;
		photoAddress: string;
		date?: Date;
		id?: ObjectId;
	};
	onUploadPhoto?: () => void;
	IsAdmin: boolean;
	onViewBlog?: (blog: any) => void;
	isUploadPhotoButtonVissible?: boolean;
}

export const LittleBlogBox: React.FC<ILittleBlogBoxProps> = props => {
	const blog = props.blog;
	const months = useMonthsShort();

	return (
		<div
			className={styles.littleBlogFrame}
			onClick={props.onViewBlog && props.onViewBlog}
		>
			<img src={blog.photoAddress} className={styles.blogMainPhoto} />
			<div className={styles.title}>{blog.title}</div>
			<div className={styles.dateAndButton}>
				{props.isUploadPhotoButtonVissible ? (
					<div
						onClick={
							props.IsAdmin
								? props.onUploadPhoto
								: props.onViewBlog
						}
						className={styles.uploadBlogMainPhotoButton}
					>
						<FormattedMessage
							id={props.IsAdmin ? "uploadPhoto" : "enter"}
						/>
					</div>
				) : (
					<div
						onClick={props.onViewBlog}
						className={styles.uploadBlogMainPhotoButton}
					>
						<FormattedMessage id={"enter"} />
						<ArrowRightIcon />
					</div>
				)}
				{props.blog.date && (
					<div className={styles.date}>
						<div>{props.blog.date.getDate()}</div>
						<div style={{ margin: "0 3px" }}>
							{months[props.blog.date.getMonth()]},
						</div>
						<div>{props.blog.date.getFullYear()}</div>
					</div>
				)}
			</div>
		</div>
	);
};
