import React from "react";
import termStyles from "./styles/terms.module.css";
import { useLocale } from "@app/hooks/intl";

export const DutchConfidentiality = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					Bedankt voor uw bezoek aan Mijntaken.com{" "}
				</h1>
				<p>
					Voor de toepassing van dit Privacy Statement wordt het
					volgende verstaan onder : <br />
					Website : www.mijntaken.com en alle onderdelen ervan.
				</p>
				<p>
					‘Wij’, ‘we’, ‘onze’, ‘ons’: team van mijntaken.com <br />
					Gebruiker : iedere rechtspersoon of natuurlijk persoon, die
					de Website Gebruikt dan wel op een of andere wijze gebruik
					maakt van Mijntaken.com, zoals bijvoorbeeld het inzien van
					en plaatsen van Materiaal (wel of niet geregistreerd).
				</p>
				<p>
					De regels zijn een juridische overeenkomst tussen het
					bedrijf dat de site bezit enerzijds – ‘Lupi AI LTD’ (hierna
					– mijntaken.com) en anderzijds de Gebruikers van de Website.
				</p>
				<p>
					Door de website te gebruiken, bevestigt de Gebruiker dat
					hij/zij minstens 18 jaar oud is of indien minderjarig,
					toestemming heeft van ouder of voogd. De gebruiker moet
					aangeven dat hij/zij het Privacy Statement heeft gelezen,
					akkoord gaat en de volledige verantwoordelijkheid opneemt
					voor de regels in dit Privacy Statement.
				</p>
				<p>
					Onze team is toegewijd aan het beschermen van uw privacy en
					streeft ernaar om uw persoonlijke gegevens te respecteren in
					overeenstemming met de EU General Data Protection Regulation
					(GDPR). Hieronder leggen we precies uit wanneer en waarom we
					persoonlijke informatie verzamelen, hoe we deze informatie
					gebruiken en hoe zeer zorgvuldig we daarmee omgaan.
				</p>
				<p className={termStyles.secondary}>Wie zijn wij?</p>
				<p className={termStyles.marginTB}>
					De Website, mijntaken.com is het project van Lupi AI LTD,
					die als doel heeft om de leuke online leeromgeving te
					creëren en het maken en aanmaken van de huistaken te
					vergemakkelijken. Het hoofdkantoor van ons bedrijf is
					gevestigd in Tsotne Dadiani Straat N7a, Tbilisi,{" "}
					<span>
						Georgi<span style={{ fontFamily: "firago" }}>ë.</span>
					</span>
					<br /> U kan contact met ons opnemen via e-mail:
					info@mijntaken.com
				</p>
				<p className={termStyles.secondary}>Persoonsgegevens</p>
				<p className={termStyles.marginTB}>
					We verwerken uw persoonlijke informatie wanneer u zich
					registreert op de Website, die anderszins gebruikt of
					wanneer u contact met ons opneemt via e-mail. Tijdens de
					registratie verwerken we de volgende informatie: Uw voor- en
					achternaam Uw e-mailadres Bij het gebruik van de Website
					verwerken wij de informatie met behulp van de zogenaamde
					log’s. Wanneer de Gebruikers de Website bezoeken, worden er
					op de server aantal gegevens weergegeven. Deze gegevens
					zijn: de datum en methode van betreden van de Website,
					Internet Protocol-adres, referrer en het bezocht bronadres.
				</p>

				<p className={termStyles.secondary}>Google Analytics</p>
				<p>
					Voor het verzamelen van webstatistieken over het gebruik en
					bezoek van de website, maakt Mijntaken.com gebruik van
					Google Analytics. Zo kunnen we de websitestatistieken
					analyseren, bijvoorbeeld, aantal paginaweergaves, vanwaar de
					Gebruikers komen (Land/Stad), de gemiddelde tijd die de
					bezoeker op de Website doorbrengt, etc. Het doel van deze
					dienst is om de informatie statistisch te verwerken en beter
					inzicht te krijgen in onze website. Het technisch
					identificeren van de Gebruikers is onmogelijk.
				</p>

				<p className={termStyles.secondary}>Cookies</p>
				<p>
					We gebruiken cookies. Cookies zijn kleine tekstbestanden met
					informatie erop. Als een gebruiker een website bezoekt, kan
					deze cookies op zijn/haar computer, tablet of smartphone
					geplaatst worden. De belangrijke functie van cookies is om
					de ene gebruiker van de andere te onderscheiden. In deze
					cookies wordt informatie over jouw websitebezoek opgeslagen.
					Met cookies kunnen we de Gebruiker herkennen bij een volgend
					bezoek. Dat is handig als de Gebruiker niet telkens opnieuw
					wilt inloggen. Bij een bezoek van de Gebruiker aan onze
					website, zien we de volgende: de verwijzende URL, het
					tijdstip van de bezoek van de Gebruiker, door hem/haar
					gebruikte keywords, besturingssysteem, toestel, browser
					IP-adres en indien opgegeven, naam en emailadres van de
					Gebruiker. Met behulp van deze gegevens kunnen wij het
					gebruikersgemak van de website bevorderen. Wij gebruiken
					cookies om de Gebruiker effectiever tussen verschillende
					pagina’s op onze Website te laten navigeren, de algemene
					gebruikservaring te verbeteren en de informatie in het
					gewenste formaat aan te bieden.
				</p>

				<p className={termStyles.secondary}>
					Waarom verzamelen en gebruiken we persoonlijke gegevens?{" "}
				</p>
				<p>
					We verwerken persoonsgegevens van de Gebruikers om een
					dienst te verlenen die bij ons wordt aangevraagd. Om het
					Websitegebruik gemakkelijker te maken, voor marketing- en
					statistische doeleinden en contact op te nemen met onze
					Gebruikers.
				</p>
				<p className={termStyles.secondary}>
					Hoelang bewaren we uw persoonlijke gegevens?{" "}
				</p>
				<p>
					Persoonlijke gegevens worden bewaard zolang als nodig is en
					niet langer dan noodzakelijk voor het doel waarvoor de
					persoonlijke gegevens zijn verzameld. Als het opslaan van uw
					gegevens niet nodig is om de doelen te bereiken, zullen we
					deze verwijderen of wijzigen zodat het geen impact heeft op
					uw privacy.
				</p>
				<p className={termStyles.secondary}>
					Beveiliging en omgang met uw persoonlijke gegevens.{" "}
				</p>
				<p>
					We hebben alle redelijke technische beveiligingsmaatregelen
					ingevoerd, zodat uw persoonlijke gegevens die op de Website
					worden gezet beschermd worden tegen ongeoorloofde toegang,
					gebruik, verlies of vernietiging. Tijdens uw registratie op
					onze Website moet u een veilig wachtwoord selecteren en dit
					niet aan anderen doorgeven.
				</p>
				<p className={termStyles.secondary}>
					Delen we uw persoonsgegevens mee aan anderen?{" "}
				</p>
				<p>
					Uw persoonlijke gegevens kunnen we aan onze partners
					doorgeven voor de doeleinden die in deze Privacy Statement
					worden uiteengezet. We kunnen uw persoonlijke gegevens ook
					doorgeven aan de autoriteiten van de staat op basis van de
					toepasselijke wetgeving of een gerechtelijk bevel.
				</p>
				<p className={termStyles.secondary}>
					Uw rechten ten aanzien van uw persoonlijke gegevens
				</p>
				<p style={{ marginBottom: 40 }}>
					U hebt het recht om een kopie te verzoeken van de
					persoonlijke gegevens die Mijntaken.com van u heeft. <br />{" "}
					U hebt het recht Mijntaken.com te vragen uw persoonlijke
					gegevens te corrigeren indien deze verouderd of onjuist
					zijn. <br /> U kunt verzoeken om verwijdering van uw
					persoonlijke gegevens.
				</p>
			</div>
		</>
	);
};
