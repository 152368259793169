import { UserType } from "@app/api/helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum RequesteeInfoType {
	id = "ID",
	mobile = "MOB",
}
export const JoinRequesterTypes = [
	RequesteeInfoType.id,
	RequesteeInfoType.mobile,
];

const RequesterSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	type: Joi.string()
		.valid(UserType.teacher, UserType.student)
		.required(),
});
export interface IRequester {
	type: UserType.student | UserType.teacher;
	id: number;
}

export const RequesteeInfoSchema = Joi.alternatives(
	Joi.object({
		type: Joi.string()
			.valid(RequesteeInfoType.id)
			.required(),
		id: Joi.number().required(),
	}),
	Joi.object({
		type: Joi.string()
			.valid(RequesteeInfoType.mobile)
			.required(),
		mobile: Joi.string().required(),
	})
);

export type IRequesteeInfo =
	| {
			type: RequesteeInfoType.id;
			id: number;
	  }
	| {
			type: RequesteeInfoType.mobile;
			mobile: string;
	  };

export const ClassroomJoinRequestSchema = Joi.object({
	_id: Joi.objectId().required(),
	classroomId: Joi.objectId(),
	requester: RequesterSchema.required(),
	requesteeInfo: RequesteeInfoSchema.required(),
});
export interface IClassroomJoinRequest {
	_id: ObjectId;
	classroomId?: ObjectId;
	requester: IRequester;
	requesteeInfo: IRequesteeInfo;
}
