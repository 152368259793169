import { IFinalGETHomeworkListForStudent } from "@app/api/assignments/controller";
import {
	IRGETCountForTeacher,
	IStudentHomeworkListItem,
} from "@app/api/assignments/validators";
import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById } from "m-model-react";
import { useEffect, useMemo, useState } from "react";
import { useFetch, useResourceLoading } from "./fetch";
import { useForceUpdate, useMountingInfo } from "./general";
import { UserTestFetchInfo } from "./tests";

export const useAssignment = (assignmentId: ObjectId | null) => {
	const assignment = useModelDocById(inject("AssignmentModel"), assignmentId);

	return useResourceLoading(
		assignment,
		() => inject("AssignmentsController").getOneById(assignmentId!),
		[assignmentId]
	);
};

export const useStudentClassroomAssignmentsInfo = (
	classroomId: ObjectId | null
) => {
	const [studentAssignmentsInfo, setStudentAssignmentsInfo] = useState<
		IFinalGETHomeworkListForStudent
	>();
	const mountingInfo = useMountingInfo();

	useEffect(() => {
		let isCancelled = false;
		if (mountingInfo.hasFinishedFirstMountingCycle) {
			setStudentAssignmentsInfo(undefined);
		}
		if (!classroomId) return;
		const AssignmentsController = inject("AssignmentsController");
		AssignmentsController.getStudentHomeworks({
			classroomId,
		}).then(data => {
			if (isCancelled) return;
			setStudentAssignmentsInfo(getSortedAssignmentsForStudent(data));
		});
		return () => {
			isCancelled = true;
		};
	}, [mountingInfo, classroomId]);
	return studentAssignmentsInfo;
};

export const useStudentClassroomCognitiveAssignmentsInfo = (
	cognitiveClassroomIds: ObjectId[] | null
) => {
	const [studentAssignmentsInfo, setStudentAssignmentsInfo] = useState<
		IFinalGETHomeworkListForStudent[]
	>([] as IFinalGETHomeworkListForStudent[]);

	useEffect(() => {
		let isCancelled = false;

		if (!cognitiveClassroomIds) return;
		const AssignmentsController = inject("AssignmentsController");

		cognitiveClassroomIds.map((id, i) => {
			if (i === cognitiveClassroomIds.length) return null;
			AssignmentsController.getStudentHomeworks({
				classroomId: id,
			}).then(data => {
				if (isCancelled) return;
				setStudentAssignmentsInfo(x => [
					...x,
					getSortedAssignmentsForStudent(data),
				]);
			});
		});

		return () => {
			isCancelled = true;
		};
	}, []);

	return studentAssignmentsInfo;
};

export const getSortedAssignmentsForStudent = <
	T extends IStudentHomeworkListItem
>(
	data: T[]
): T[] => {
	// TODO: implement more sophistically
	return [...data].sort((a, b) => {
		return (
			b.assignment.createdAt.getTime() - a.assignment.createdAt.getTime()
		);
	});
};

export const getDescSortedAssignmentsForStudent = <
	T extends IStudentHomeworkListItem
>(
	data: T[]
): T[] => {
	// TODO: implement more sophistically
	return [...data].sort((a, b) => {
		return (
			a.assignment.createdAt.getTime() - b.assignment.createdAt.getTime()
		);
	});
};

export const useStudentTestForTeacher = ({
	classroom,
	testId,
	studentId,
}: {
	classroom: IClassroom | null;
	testId: ObjectId | null;
	studentId: number;
}) => {
	const courseId = classroom ? classroom.course.courseId : null;
	const folderId = classroom ? classroom.course.customFolderIds.tests : null;

	return useFetch(
		() =>
			getStudentTest({
				courseId: courseId!,
				folderId: folderId!,
				studentId: studentId!,
				testId: testId!,
			}),
		[courseId, folderId, testId, studentId]
	);
};

const getStudentTest = ({
	courseId,
	folderId,
	testId,
	studentId,
}: {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	studentId: number;
}): Promise<UserTestFetchInfo> => {
	const AssignmentsController = inject("AssignmentsController");
	return AssignmentsController.getUserTestForTeacher({
		attempt: 1,
		courseId,
		folderId,
		testId,
		studentId,
	}).then(data => {
		if (data) {
			return { hasFound: true as true, test: data };
		}
		return { hasFound: false as false };
	});
};

export const useTeacherAssignmentsCount = () => {
	const forceUpdate = useForceUpdate();
	const cnt = inject("AssignmentModel").meta.data.numOfCreatedAssignments;
	const assignmentCount = useMemo(
		(): IRGETCountForTeacher | null =>
			cnt === undefined || cnt === null ? null : { count: cnt },
		[cnt]
	);

	const assignmentsCountFetchInfo = useResourceLoading(
		assignmentCount,
		() => inject("AssignmentsController").getCountForTeacher(),
		[]
	);

	useEffect(() => {
		let isCancelled = false;
		const unsubscribe = inject("AssignmentModel").meta.subscribeDataChange(
			() => {
				if (isCancelled) return;
				forceUpdate();
			}
		);
		return () => {
			isCancelled = true;
			unsubscribe();
		};
	}, [forceUpdate]);

	return assignmentsCountFetchInfo;
};

export const useAssignemntsByStudentAndClassroom = (
	student?: number,
	classroom?: ObjectId
) => {
	return useFetch(
		() =>
			inject("AssignmentsController").getHomeworksByStudentId({
				classroomId: classroom!,
				studentId: student!,
			}),
		[classroom, student]
	);
};
