import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { IAudience } from "../audiences/helper-schemas";
import {
	APOSTAudienceSchema,
	IAPOSTAudience,
	RPOSTAudienceSchema,
} from "../audiences/validators";
import {
	insertStripKeys,
	markKeysStripped,
	ToInsertKeys,
} from "../helper-schemas";
import {
	SummonsSchema,
	ISummons,
	SummonsWithIsInAudienceSchema,
	ISummonsWithIsInAudience,
} from "./helper-schemas";

export const APOSTSummonsSchema = Joi.object({
	audience: Joi.alternatives(APOSTAudienceSchema, Joi.objectId()).required(),
	summons: SummonsSchema.keys(insertStripKeys).fork(
		"audienceId",
		markKeysStripped
	),
});

export interface IAPOSTSummons {
	audience: IAPOSTAudience | ObjectId;
	summons: ToInsertKeys<ISummons, never, "audienceId">;
}

export const RPOSTSummonsSchema = Joi.object({
	audience: RPOSTAudienceSchema.required(),
	summons: SummonsSchema.required(),
});

export interface IRPOSTSummons {
	audience: IAudience;
	summons: ISummons;
}

///

export const AGETCustomSummonsesSchema = Joi.object({
	key: Joi.string().required(),
});

export interface IAGETCustomSummonses {
	key: string;
}

///

export const AGETMySummonsesSchema = Joi.object({
	includeCreatedByMe: Joi.boolean().required(),
	includeIfIAmInAudience: Joi.boolean().required(),
});

export interface IAGETMySummonses {
	includeCreatedByMe: boolean;
	includeIfIAmInAudience: boolean;
}

export const RGETMySummonsesSchema = Joi.array()
	.items(SummonsWithIsInAudienceSchema)
	.required();

export type IRGETMySummonses = ISummonsWithIsInAudience[];

///
