import { Appeal } from "@app/models/appeal";
import { inject } from "@app/modules";
import { IRequest } from "../requests";
import { IAPOSTAppeal, IRPOSTAppeal, RPOSTAppealSchema } from "./validators";

export class AppealsController {
	private readonly Request: IRequest;

	private readonly _AppealModel = inject("AppealModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTAppeal): Promise<Appeal> =>
		this.Request.send("POST", "/api/appeals", args, null, {
			responseSchema: RPOSTAppealSchema,
		}).then((data: IRPOSTAppeal) => {
			return this._AppealModel.loadOneSync(data);
		});
}
