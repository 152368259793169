import { addPrefix, addMergingArgumentPrefix, withParams } from "link-builders";
import { ObjectId } from "@app/utils/generics";
import { studentClassroomInnerLinks } from "../student/links";
import { ISemester } from "@app/api/classrooms/helper-schemas";

export const parentLinks = addPrefix(
	({ childId }: { childId: number }) => `/children/${childId}`,
	`/children/:childId`
)({
	childMainPage: "",
	classrooms: addMergingArgumentPrefix(
		({ classroomId }: { classroomId: ObjectId }) =>
			`classrooms/${classroomId}`,
		`classrooms/:classroomId`
	)(studentClassroomInnerLinks),
	archived: addPrefix("archive")({
		classrooms: withParams(
			(semester: ISemester) =>
				`classrooms?startYear=${semester.startYear}&ordinal=${semester.ordinal}`,
			"classrooms"
		),
	}),
	timetable: "timetable",
});
