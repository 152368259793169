import Joi, { ObjectId } from "../../utils/joi";
import { DomainKeys, LanguageKeys, Language, Domain } from "./const";
import {
	IRawQuestionContent,
	QuestionContentSchema,
	RQuestionContentSchema,
	IRQuestionContent,
	IQuestionContent,
} from "./contnets/schemas";
import { Omit } from "../../utils/generics";
import { markKeysForbidden } from "../../schemas/helper";

export const OldContentDiffsSchema = Joi.object({
	author: Joi.number().required(),
	diffs: Joi.object().required(),
	createdAt: Joi.date().required(),
});
export interface IOldContentDiff {
	author: number;
	diffs: Record<any, any>;
	createdAt: Date;
}

export const QuestionSchema = Joi.object({
	_id: Joi.objectId().required(),
	lang: Joi.string()
		.valid(...LanguageKeys)
		.required(),
	content: QuestionContentSchema.required() as any,
	oldContentDiffs: Joi.array().items(OldContentDiffsSchema),
	visible: Joi.boolean().required(),
	reviewed: Joi.number().required(),
	textId: Joi.objectId().optional(),
	authorId: Joi.number().required(),
	domain: Joi.number().valid(...DomainKeys),
	oldDbMetadata: Joi.object().optional(),
	extraInfo: Joi.object().optional(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IQuestion {
	_id: ObjectId;
	lang: Language;
	content: IRawQuestionContent;
	oldContentDiffs?: IOldContentDiff[];
	visible: boolean;
	reviewed: number;
	authorId: number;
	textId?: string;
	domain?: Domain;
	oldDbMetadata?: Record<any, any>;
	extraInfo?: Record<any, any>;
	createdAt: Date;
	updatedAt: Date;
}

export const ShortQuestionSchema = QuestionSchema.fork(
	"content",
	markKeysForbidden
).keys({
	shortContent: RQuestionContentSchema.required(),
});
export type IShortQuestion = Omit<IQuestion, "content"> & {
	shortContent: IRQuestionContent;
};

export const FullQuestionSchema = QuestionSchema;
export type IFullQuestion = IQuestion;

export const FullQuestionWithNameSchema = FullQuestionSchema.keys({
	shortStat: Joi.string()
		.allow("")
		.required(),
});
export type IFullQuestionWithName = IQuestion & { shortStat: string };

export const RQuestionSchema = Joi.alternatives(
	FullQuestionSchema,
	ShortQuestionSchema
);
export type IRQuestion = IShortQuestion | IFullQuestion;

export const QuestionTemplateSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	cardTemplateId: Joi.objectId().required(),
	content: QuestionContentSchema.required(),
	authorId: Joi.objectId().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IQuestionTemplate {
	_id: string;
	name: string;
	cardTemplateId: string;
	content: IQuestionContent;
	authorId: string;
	createdAt: Date;
	updatedAt: Date;
}

const QuestionItemAssessmentSchema = Joi.object({
	credit: Joi.number().required(),
});
export interface IQuestionItemAssessment {
	credit: number;
}

export const QuestionItemsAssessmentSchema = Joi.object({
	items: Joi.object().pattern(
		/[\d]+/,
		QuestionItemAssessmentSchema.required()
	),
});

export interface IQuestionItemsAssessment {
	[itemId: number]:
		| {
				credit: number;
		  }
		| undefined;
}

export interface IQuestionAssessment {
	credit?: number;
	assessmentProgress: number;
	items: IQuestionItemsAssessment;
}
