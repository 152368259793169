/* eslint-disable react-hooks/rules-of-hooks */
import {
	useHeaderThemeSetter,
	useSetIsHeaderHidden,
	useSetIsNewSidebarVisible,
} from "@app/components/general-context";
import { HeaderTheme } from "@app/components/layouts/header/interfaces";
import { AnyRoute } from "./interfaces";

export interface CommonRouteProps {
	headerTheme?: HeaderTheme;
	showSidebar?: boolean;
	hideHeader?: boolean;
}

export const defaultRouteMapper = <R extends AnyRoute & CommonRouteProps>(
	r: R
): R => {
	const route = r as AnyRoute & CommonRouteProps;
	if (route.$switch) return route as R;
	return {
		...(route as R),
		routeProps: {
			...route.routeProps,
			renderHook: () => {
				route.routeProps?.renderHook?.();
				useHeaderThemeSetter(route.headerTheme, !route.headerTheme);
				useSetIsHeaderHidden(
					!!route.hideHeader,
					typeof route.hideHeader !== "boolean"
				);
				useSetIsNewSidebarVisible(
					route.showSidebar,
					typeof route.showSidebar !== "boolean"
				);
			},
		},
	};
};
