export const CommonButtonStyles: React.CSSProperties = {
	width: "85%",
	height: 40,
	backgroundColor: "#5273e6",
	color: "white",
	fontFamily: "Roboto Geo Caps",
	fontSize: 16,
	border: "none",
	borderRadius: 10,
	outline: "none",
	cursor: "pointer",
	margin: 8,
};
