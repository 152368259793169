export const builtInCategoryIds = {
	nl: {
		BASISSCHOOL: 1,
		MIDDELBARESCHOOL: 2,
	},
};

export const CHILD_TOKEN_KEY = "child-access-token";

export const isCertificateServicesAvaliable = false;
export const isFacebookPopupDisabled = true;

export const isStudentStatusVisible = true;
