import React, { useState, useRef, useEffect } from "react";
import { useWindowSize } from "@app/hooks/front";
import Loading from "../raw-loading";

/**
 * We cannot  detect  if Google Docs Viewer Iframe has loaded properly
 * so if there is no onLoad call from iframe in 10 seconds
 * interval changes hack value which deletes iframe and renders it back again in 1 second
 * so iframes tries to load document again
 * this proccess continues untill onLoad fires back
 */

export const GoogleDocsViewerWrapper: React.FC<{
	fileUrl: string;
	fileName?: string;
}> = React.memo(({ fileUrl, fileName }) => {
	const [hack, setHack] = useState<number>(0);
	const [loaded, setLoaded] = useState<boolean>(false);
	const { width, height } = useWindowSize();
	const interval = useRef<null | number>(null);

	useEffect(() => {
		interval.current = window.setInterval(() => {
			if (!loaded) {
				if (hack % 2 === 0) setTimeout(() => setHack(x => x + 1), 800);
				setHack(x => x + 1);
			}
		}, 10000);
		return () => {
			if (interval.current) window.clearInterval(interval.current);
		};
	}, [loaded, hack]);

	return (
		<React.Fragment>
			{!loaded && <Loading />}

			{hack % 2 === 0 || loaded ? (
				<iframe
					name="google_doc"
					style={{
						display: loaded ? undefined : "none",
						width: `${(width * 3) / 5}px`,
						height: `${(height * 4) / 5}px`,
					}}
					title="preview"
					src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
					onLoad={() => {
						setLoaded(true);
						if (interval.current) {
							window.clearInterval(interval.current);
						}
					}}
				/>
			) : null}
		</React.Fragment>
	);
});
