import React from "react";
import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import styles from "./styles.module.scss";
import { CenterContainer } from "@app/components/widgets/tools";

const TeacherKingsOfferModalMarkup = React.memo<{
	onConfirm: () => void;
}>(function TeacherKingsOfferModalMarkup({ onConfirm }) {
	return (
		<>
			<div className="font-roboto-geo-mt-bold text-lg text-gray-700">
				სიახლე!
			</div>
			<div className="h-10" />
			<div style={{ maxWidth: 600 }}>
				ძვირფასო მასწავლებელო, <div className="h-1" />
				მეგობარი კინგსის გრანდიოზული პროექტი "კინგსის ლიგა" იწყება.
				მოიწვიეთ მოსწავლეები, რათა შეძლონ:
			</div>
			<div className="h-7" />
			<br />
			<div>
				🥇ოქროს
				<div className="h-3" />
				🥈 ვერცხლის ან
				<div className="h-3" />
				🥉ბრინჯაოს მედლის მოპოვება.{" "}
			</div>
			<div className="h-8" />
			<div>
				დააჭირეთ მოწვევას, შეატყობინეთ მოსწავლეს ამ შესაძლებლობის
				შესახებ!
			</div>
			<div className="h-8" />
			<CenterContainer>
				<PrimaryButton onClick={onConfirm}>
					მოწვევის გაგზავნა
				</PrimaryButton>
			</CenterContainer>
		</>
	);
});

function Bullets() {
	return (
		<div className="font-roboto-geo-nus" style={{ maxWidth: 600 }}>
			<Bullet>
				ოლიმპიადების პირველი ტურები <b>8 მარტიდან</b> ჩატარდება.
			</Bullet>
			<Bullet>
				<b>სულ ცოტა დრო რჩება.</b> მოიწვიეთ თქვენი მოსწავლეები, რათა არ
				გამორჩეთ ეს სიახლე!
			</Bullet>
		</div>
	);
}

function Bullet({ children }: React.PropsWithChildren<{}>) {
	return (
		<div className="flex justify-items-start my-1">
			<div
				className="h-1 w-4 bg-primary rounded-xl mr-2"
				style={{ marginTop: `${10 / 16}em` }}
			/>
			<div className="flex-1">{children}</div>
		</div>
	);
}

function TeacherKingsOfferSuccessModalMarkup() {
	return <p>თქვენი მოწვევა წარმატებით გაიგზავნა</p>;
}

export const ModalMarkups = {
	Component: TeacherKingsOfferModalMarkup,
	containerClassName: styles.container,
	SuccessComponent: TeacherKingsOfferSuccessModalMarkup,
};
