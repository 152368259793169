import Joi from "@app/utils/joi";

export const QuestionStatsCoefficientsSchema = Joi.object({
	a: Joi.number().required(),
	b: Joi.number().required(),
	c: Joi.number().required(),
});
export interface IQuestionStatsCoefficients {
	a: number;
	b: number;
	c: number;
}

///
export const CorrectedQuestionSchema = Joi.object({
	id: Joi.objectId().required(),
	credit: Joi.number().required(),
	maxCredit: Joi.number().required(),
});
export interface ICorrectedQuestion {
	credit: number;
	maxCredit: number;
}
