import { ISchoolLabel } from "@app/api/schools/structure/helper-schemas";
import {
	useSelectedSchoolLabelId,
	useTeacherSelectedSchoolId,
} from "@app/components/teachers/contexts/teacher-school";
import { inject } from "@app/modules";
import { haveCommonValue } from "@app/utils/array";
import { deepEqual } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { wrapPromiseInFoundNotFound } from "@app/utils/object";
import { useCallback, useMemo } from "react";
import { createMemoHook } from "react-dependency-hooks";
import { useRawFetch } from "./fetch";
import { useClassroomsUser } from "./users";
import { useModelDocs } from "m-model-react";
import { Classroom } from "@app/models/classroom";
import { useUserCurrentSchool } from "./schools";
import { SchoolStructure } from "@app/models/school-structure";
import { IOption } from "@app/components/widgets/select";

export const useSchoolStructure = (schoolId: number | null) => {
	return useRawFetch(
		() =>
			wrapPromiseInFoundNotFound(
				inject("SchoolStructureController").getForSchool(schoolId!)
			),
		[schoolId]
	);
};

export const useSchoolStructureOfCurrentUser = () => {
	const schoolId = useUserCurrentSchool();
	return useSchoolStructure(schoolId);
};

const useDeepMemo = createMemoHook(deepEqual);

export const useViewableLabelIds = () => {
	const user = useClassroomsUser()!;
	useModelDocs(Classroom, "raw"); // required to get up to date viewableLabelIds
	const rawOwnViewableLabelIds = user.getViewableLabelIds();
	return useDeepMemo(() => rawOwnViewableLabelIds, [rawOwnViewableLabelIds]);
};

export const useTopLevelAccessibleSchoolLabels = (): ISchoolLabel[] | null => {
	const schoolStructure = useSchoolStructureOfCurrentUser();
	const ownViewableLabelIds = useViewableLabelIds();
	return useMemo(() => {
		if (!schoolStructure.isSuccessfullyLoaded) return null;
		if (!schoolStructure.isFound) return [];
		const filteredLabels = schoolStructure.doc.getRootLabels();
		if (ownViewableLabelIds === null) return filteredLabels;
		return filteredLabels.filter(
			e =>
				ownViewableLabelIds.includes(e._id) ||
				haveCommonValue(
					schoolStructure.doc
						.getDescendantLabels(e._id)
						.map(c => c._id),
					ownViewableLabelIds
				)
		);
	}, [ownViewableLabelIds, schoolStructure]);
};

export const useLowLevelAccessibleSchoolLabels = (): ISchoolLabel[] | null => {
	const schoolStructure = useSchoolStructureOfCurrentUser();
	const selectedLabelId = useSelectedSchoolLabelId();
	const ownViewableLabelIds = useViewableLabelIds();
	return useMemo(() => {
		if (!selectedLabelId) return [];
		if (!schoolStructure.isSuccessfullyLoaded) return null;
		if (!schoolStructure.isFound) return [];
		const parentId =
			schoolStructure.doc.getParentOfLabel(selectedLabelId)?._id ??
			selectedLabelId;
		if (!parentId) return [];
		const descendants = schoolStructure.doc.getDescendantLabels(parentId);
		if (ownViewableLabelIds === null) return descendants;
		return descendants.filter(
			e =>
				ownViewableLabelIds.includes(e._id) ||
				ownViewableLabelIds.includes(parentId)
		);
	}, [ownViewableLabelIds, schoolStructure, selectedLabelId]);
};

const useSchoolLabelMatchFn = ({
	schoolId,
	schoolLabelId,
}: {
	schoolId: number | null;
	schoolLabelId: ObjectId | null;
}) => {
	const schoolStructure = useSchoolStructure(schoolId);
	return useCallback(
		(aaa: ObjectId | null | undefined) => {
			if (schoolLabelId && aaa && aaa !== schoolLabelId) {
				if (!schoolStructure.isFound) return false;
				if (
					schoolStructure.doc
						.getDescendantLabels(schoolLabelId)
						.every(e => e._id !== aaa) &&
					schoolStructure.doc
						.getAncestorLabels(schoolLabelId)
						.every(e => e._id !== aaa)
				) {
					return false;
				}
			}
			return true;
		},
		[schoolLabelId, schoolStructure]
	);
};

export interface SchoolAndLaberFilterer {
	(ressource: {
		schoolId?: number | null | undefined;
		schoolLabelId?: string | null | undefined;
	}): boolean;
}

export const useSchoolAndLabelFiltererFn = ({
	schoolId,
	schoolLabelId,
}: {
	schoolId: number | null;
	schoolLabelId: ObjectId | null;
}): SchoolAndLaberFilterer => {
	const match = useSchoolLabelMatchFn({
		schoolId,
		schoolLabelId,
	});
	return useCallback(
		(ressource: {
			schoolId?: number | null;
			schoolLabelId?: ObjectId | null;
		}) => {
			if (
				schoolId &&
				ressource.schoolId &&
				ressource.schoolId !== schoolId
			) {
				return false;
			}
			if (!match(ressource.schoolLabelId)) {
				return false;
			}
			return true;
		},
		[match, schoolId]
	);
};

export const useTeacheruseSchoolAndLabelFiltererFn = () => {
	const teacherSchoolId = useTeacherSelectedSchoolId();
	const schoolLabelId = useSelectedSchoolLabelId();
	return useSchoolAndLabelFiltererFn({
		schoolId: teacherSchoolId,
		schoolLabelId,
	});
};

export const useSchoolStructureLabelOptions = ({
	structure,
	leaveOnlyLowLevel,
}: {
	structure: SchoolStructure | null;
	leaveOnlyLowLevel: boolean;
}): IOption<ObjectId>[] | null => {
	return useMemo(() => {
		if (!structure) return null;
		const labels = !leaveOnlyLowLevel
			? structure.labels
			: structure.labels.filter(
					e => structure.getDescendantLabels(e._id).length === 0
			  );
		return labels.map(
			(e): IOption<ObjectId> => {
				let name = e.name;
				if (e.parentLabelIds && e.parentLabelIds.length > 0) {
					const parent = structure.getParentOfLabel(e._id);
					if (parent) name = parent.name + " - " + name;
				}
				return {
					value: e._id,
					label: name,
				};
			}
		);
	}, [structure, leaveOnlyLowLevel]);
};
