import { IRTest, IUserTest } from "@app/api/tests/helper-schemas";
import { IRGETUserTest } from "@app/api/tests/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useEffect, useState } from "react";
import { FetchingDoc, useFetch } from "./fetch";

export const useTestById = (testId: ObjectId | null) => {
	const [test, setTest] = useState<IRTest>();
	useEffect(() => {
		if (!testId) {
			setTest(undefined);
			return;
		}
		const TestsController = inject("TestsController");
		TestsController.getById({ _id: testId }).then(setTest);
	}, [testId]);

	return test;
};

export const useUserTest = (
	testId: ObjectId | null,
	folderId: ObjectId | "none" | null,
	courseId: ObjectId | null
) => {
	const [userTest, setUserTest] = useState<FetchingDoc<IUserTest> | null>(
		null
	);

	useEffect(() => {
		if (!testId || !folderId || !courseId) return;
		const TestsController = inject("TestsController");
		TestsController.getUserTest({
			courseId,
			folderId: folderId === "none" ? null : folderId,
			testId,
			attempt: 1,
		}).then(userTest => {
			if (userTest) {
				setUserTest({
					hasFound: true,
					doc: userTest,
				});
			} else {
				setUserTest({
					hasFound: false,
				});
			}
		});
	}, [folderId, courseId, testId]);

	return userTest;
};

export type UserTestFetchInfo = FetchingDoc<IRGETUserTest, "test">;

export const useStudentTestByUserTestId = (userTestId: ObjectId | null) => {
	return useFetch(() => {
		return inject("TestsController")
			.getUserTestById({ _id: userTestId! })
			.then(
				(data): UserTestFetchInfo => {
					if (data) {
						return { hasFound: true, test: data };
					}
					return { hasFound: false };
				}
			)
			.catch((): UserTestFetchInfo => ({ hasFound: false }));
	}, [userTestId]);
};
