import {
	IStudentAssignmentEdge,
	IClassroomAssignmentEdge,
	IAssignmentSettings,
	IAssignmentEdge,
	AssignmentEdgeType,
	ShowAnswersStandardTime,
} from "@app/api/assignments/helper-schemas";

export class AssignmentPermissionsService {
	getAnswerVisibilityStatus = (args: {
		deadlinePassed: boolean;
		isSubmitted: boolean;
		settings: IAssignmentSettings;
	}): boolean => {
		if (args.settings.showAnswersAt instanceof Date) {
			return args.settings.showAnswersAt.getTime() >= Date.now();
		}
		if (
			args.settings.showAnswersAt === ShowAnswersStandardTime.submission
		) {
			return args.isSubmitted;
		}
		if (args.settings.showAnswersAt === ShowAnswersStandardTime.deadline) {
			return args.deadlinePassed;
		}
		return args.settings.showAnswersAt === ShowAnswersStandardTime.always;
	};

	getSettings = (args: {
		studentEdge: IStudentAssignmentEdge | null | undefined;
		classroomEdge: IClassroomAssignmentEdge | null | undefined;
		assignmentSettings: IAssignmentSettings;
	}): IAssignmentSettings => {
		const { studentEdge, classroomEdge, assignmentSettings } = args;
		if (studentEdge && studentEdge.settings) {
			return studentEdge.settings;
		}
		if (classroomEdge && classroomEdge.settings) {
			return classroomEdge.settings;
		}
		return assignmentSettings;
	};

	getClassroomAndStudentEdge = (
		edges: IAssignmentEdge[]
	): {
		studentEdge: IStudentAssignmentEdge | null;
		classroomEdge: IClassroomAssignmentEdge | null;
	} => {
		let studentEdge: IStudentAssignmentEdge | null = null;
		let classroomEdge: IClassroomAssignmentEdge | null = null;
		for (const edge of edges) {
			if (edge.info.type === AssignmentEdgeType.classroom) {
				classroomEdge = edge as IClassroomAssignmentEdge;
			} else if (edge.info.type === AssignmentEdgeType.student) {
				studentEdge = edge as IStudentAssignmentEdge;
			}
		}
		return {
			studentEdge,
			classroomEdge,
		};
	};

	getAssignmentStatus = (args: {
		settings: IAssignmentSettings;
		studentEdge: IStudentAssignmentEdge | null | undefined;
	}): { deadlinePassed: boolean; isSubmitted: boolean } => {
		const { deadline } = args.settings;
		let deadlinePassed = false;
		if (deadline && deadline.getTime() < Date.now()) {
			deadlinePassed = true;
		}
		let isSubmitted = false;
		if (args.studentEdge && args.studentEdge.info.isSubmitted) {
			isSubmitted = true;
		}
		return {
			deadlinePassed,
			isSubmitted,
		};
	};
}
