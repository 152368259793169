import { IRequest } from "../requests";
import { IRPOSTUploadMedia, RPOSTUploadMediaSchema } from "./validators";

export class PhotosController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	uploadPhotos = async (args: FormData): Promise<IRPOSTUploadMedia> => {
		return this.Request.send(
			"POST",
			"/api/photos/uploads",
			args,
			undefined,
			{
				responseSchema: RPOSTUploadMediaSchema,
			}
		);
	};

	uploadGeneric = async (args: FormData): Promise<IRPOSTUploadMedia> => {
		return this.Request.send(
			"POST",
			"/api/photos/guploads",
			args,
			undefined,
			{
				responseSchema: RPOSTUploadMediaSchema,
			}
		);
	};
}
