import React from "react";
import { css } from "emotion";

export const TechDifficulties = () => {
	return (
		<div className={techDiffCSS}>
			საიტზე მიმდინარეობს ტექნიკური გაახლება. შეგვამოწმეთ დილას
		</div>
	);
};

const techDiffCSS = css`
	text-align: center;
	padding-top: 50px;
	font-size: 24px;
	font-family: "Roboto Geo MT Bold";
`;

export const StudentsTechDiffPage = () => {
	return (
		<div className={techDiffCSS} style={{ color: "#5273e6" }}>
			მიმდინარეობს საიტის განახლება.
			<br />
			<br />
			კვირას, 24 მაისს, მთელი დღის განმავლობაში პლატფორმით სარგებლობა
			შეეძლებათ მხოლოდ მასწავლებლებს, ხოლო მოსწავლეებისთვის ვებგვერდი
			ჩვეულ რეჟიმში გაიხსნება ორშაბათს, 25 მაისს.
			<br />
			<br />
			შეკითხვების შემთხვევაში მოგვწერეთ{" "}
			<a href="https://www.facebook.com/TVskola/">FACEBOOK-ზე.</a>
		</div>
	);
};
