import { IRequest } from "@app/api/requests";
import {
	IADELETEStudentAssignmentGrade,
	IAPOSTMoveAssignmentGradeToGradebook,
	IAPOSTStudentAssignmentGrade,
} from "./validators";

export class AssignmentGradeController {
	constructor(private readonly _Request: IRequest) {}

	gradeUserAssignment = (args: IAPOSTStudentAssignmentGrade) => {
		return this._Request.send(
			"POST",
			"/api/classrooms/:classroomId/assignments/:assignmentId/grades",
			args
		);
	};

	deleteUserAssignmentGrade = (args: IADELETEStudentAssignmentGrade) => {
		return this._Request.send(
			"DELETE",
			"/api/classrooms/:classroomId/assignments/:assignmentId/grades",
			args
		);
	};

	moveToGradebook = (args: IAPOSTMoveAssignmentGradeToGradebook) => {
		return this._Request.send(
			"POST",
			"/api/classrooms/:classroomId/assignments/:assignmentId/grades/move",
			args
		);
	};
}
