import Joi from "@app/utils/joi";

export const CommentSchema = Joi.object({
	studentId: Joi.number().required(),
	comment: Joi.string().required(),
	schoolId: Joi.number().required(),
	author: Joi.number().required(),
});

export interface IComment {
	studentId: number;
	comment: string;
	schoolId: number;
	author: number;
}
