import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import React, { useRef } from "react";
import { HeightLessGreenBlock, InfoContainer } from "../../ui";
import styles from "./styles.module.scss";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { FancyPopup } from "@app/components/widgets/popup";
import classnames from "classnames";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { inject } from "@app/modules";
import { wrapInLoading } from "@app/utils/promise";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";

const actionKey = "wk2lInviteStudents";

export const TeacherProgressWeekTestsInvitation = React.memo<{
	displayEvenIfHasSeen?: boolean;
}>(function TeacherProgressWeekTestsInvitation({ displayEvenIfHasSeen }) {
	const displayEvenIfHasSeenRef = useRef(displayEvenIfHasSeen);
	const { value: isManuallyClosed, setTrue: closeManyally } = useBoolean(
		false
	);
	const userActions = useUserActions();
	const hasSeenBefore = !!userActions.doc?.actions[actionKey];
	const hasApprovedBefore = !!userActions.doc?.actions[actionKey]?.approve;
	const enable =
		!isManuallyClosed &&
		userActions.isSuccessfullyLoaded &&
		(displayEvenIfHasSeenRef.current || !hasSeenBefore);
	const handleAction = (approve: boolean) => {
		if (hasApprovedBefore) {
			closeManyally();
			return;
		}
		wrapInLoading(
			inject("UserActionsController")
				.performUserAction({
					name: actionKey,
					data: { approve },
				})
				.then(closeManyally)
		);
	};

	return (
		<DefaultPopupPrioritizer enabled={enable} priority={10}>
			<FancyPopup
				containerClassName={classnames(
					styles.popup_container,
					styles.teacherImg
				)}
				direction="left"
				onClose={() => handleAction(false)}
			>
				<TeacherContent onConfirm={() => handleAction(true)} />
			</FancyPopup>
		</DefaultPopupPrioritizer>
	);
});

const TeacherContent = React.memo<{ onConfirm: () => void }>(
	function TeacherContent({ onConfirm }) {
		return (
			<React.Fragment>
				<h1
					className={styles.popup_title__bold}
					style={{ textAlign: "center" }}
				>
					განმავითარებელი კვირეული
				</h1>
				<p className={styles.popup_paragraph}>ძვირფასო მასწავლებელო,</p>
				<p className={styles.popup_paragraph}>
					ჩვენ დავიწყეთ განმავითარებელი კვირეული, რომელიც:
				</p>
				<ul className={styles.list}>
					<li className={styles.list_item}>
						<span className={styles.list_item__line}></span>
						<p className={styles.list_item__text}>
							მოსწავლეს დაეხმარება იმ უნარების შეფასებასა და
							დახვეწაში, რომლებიც უჭირს;
						</p>
					</li>
					<li className={styles.list_item}>
						<span className={styles.list_item__line}></span>
						<p className={styles.list_item__text}>
							<span className={styles.text_bold}>
								დაადგენს მის პროგრესს
							</span>{" "}
							თვიდან თვემდე;
						</p>
					</li>
					<li className={styles.list_item}>
						<span className={styles.list_item__line}></span>
						<p className={styles.list_item__text}>
							წააახალისებს მის
							<span className={styles.text_bold}>
								{" "}
								ნაბიჯებს პატარ-პატარა გაზიარებადი მიღწევის
								ბეჯებითა
							</span>{" "}
							და{" "}
							<span className={styles.text_bold}>
								სერტიფიკატით.
							</span>
						</p>
					</li>
				</ul>

				<MainInfo />

				<h2 className={styles.popup_title__normal}>
					დააწკაპუნეთ ღილაკს, რომ მოიწვიოთ თქვენი მოსწავლეები.
				</h2>
				<section style={{ textAlign: "center" }}>
					<PrimaryButton
						style={{ marginTop: "1rem" }}
						onClick={onConfirm}
					>
						მოწვევა
					</PrimaryButton>
				</section>
			</React.Fragment>
		);
	}
);

const MainInfo = React.memo(function MainInfo() {
	return (
		<section className="py-2 xl:py-4">
			<InfoContainer style={{ margin: 0, alignItems: "stretch" }}>
				<HeightLessGreenBlock className="hidden sm:flex">
					<PriorityHighIcon
						style={{ color: "white", fontSize: "1.7rem" }}
					/>
				</HeightLessGreenBlock>
				<section className="flex-1">
					<p className="font-roboto-geo-nus mt-0 mb-0 text-xs sm:text-sm lg:text-base text-gray-800">
						კვირეული 2-6 კლასების მოსწავლეებისთვის ქართულ და
						ინგლისურ ენებსა და მათემატიკაში ტარდება. ერთი საგნის
						საფასური 13 ლარია, ხოლო 3 საგნის - მხოლოდ 30 ლარი.
					</p>
				</section>
			</InfoContainer>
		</section>
	);
});
