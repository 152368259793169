import { UserType } from "@app/api/helper-schemas";
import {
	IRUser,
	IUserPermissions,
	RUserSchema,
} from "@app/api/users/helper-schemas";
import { flatten, pickKeys, isNonNullable } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import Joi, { getJoiObjectKeys } from "@app/utils/joi";
import { UserPermissions } from "./permissions";
import { DeveloperPermissions } from "./permissions/developer";
import { HeadmasterPermissions } from "./permissions/headmaster";
import { IRAccessibleIds, RCanAccess } from "./permissions/interfaces";
import { MainAdminPermissions } from "./permissions/main-admin";
import { ParentPermissions } from "./permissions/parent";
import { StudentPermissions } from "./permissions/student";
import { TeacherPermissions } from "./permissions/teacher";
import { getActiveUserType, isActiveUserType } from "./active-user-type";

type OwnIdsGetterKeys = Extract<
	keyof UserPermissions,
	| "getAvailableClassrooms"
	| "getOwnClassrooms"
	| "getOwnGroups"
	| "getOwnLabelIds"
>;
type CanAccessGetterKeys = Extract<keyof UserPermissions, "canAccessFolder">;

export class User implements Omit<IRUser, "permissions"> {
	public static createUserInstance(user: IRUser): User {
		const validationResult = this.validateUserObject(user);
		const permissions = User.getPermissions(validationResult);
		return new User(user, permissions, user.permissions);
	}

	private static getPermissions(user: IRUser): UserPermissions[] {
		const permissions = Array.isArray(user.permissions)
			? user.permissions
			: [user.permissions];
		return permissions.map(p => {
			switch (p.userType) {
				case UserType.teacher:
					return new TeacherPermissions(p);
				case UserType.student:
					return new StudentPermissions(p);
				case UserType.parent:
					return new ParentPermissions(p);
				case UserType.mainAdmin:
					return new MainAdminPermissions();
				case UserType.developer:
					return new DeveloperPermissions();
				case UserType.headmaster:
					return new HeadmasterPermissions(p.school, p.info);
				default:
					return new StudentPermissions(p);
			}
		});
	}

	getRawUser(): IRUser {
		return pickKeys(
			{ ...(this as any), permissions: this.rawPermission } as IRUser,
			...(getJoiObjectKeys(RUserSchema) as (keyof IRUser)[])
		);
	}

	private static validateUserObject(userObject: IRUser): IRUser {
		const validationResult = RUserSchema.keys({
			iat: Joi.number()
				.integer()
				.optional(),
			exp: Joi.number()
				.integer()
				.optional(),
		}).validate(userObject, { stripUnknown: true });
		if (validationResult.error) {
			console.log("validationResult.error", validationResult.error);
			throw validationResult.error;
		}
		return validationResult.value;
	}

	id: IRUser["id"];
	murtskuId: IRUser["murtskuId"];
	mobile: IRUser["mobile"];
	mail: IRUser["mail"];
	isValidMobile: IRUser["isValidMobile"];
	isValidMail: IRUser["isValidMail"];
	username: IRUser["username"];
	firstname: IRUser["firstname"];
	lastname: IRUser["lastname"];
	grade: IRUser["grade"];
	city: IRUser["city"];
	school: IRUser["school"];
	country: IRUser["country"];
	language: IRUser["language"];
	registrationOrigin: IRUser["registrationOrigin"];
	hasAgreedOnTerms: IRUser["hasAgreedOnTerms"];
	profiles: IRUser["profiles"];
	defaultUser: IRUser;
	testAccountType: IRUser["testAccountType"];
	constructor(
		user: IRUser,
		private readonly permissions: UserPermissions[],
		private readonly rawPermission: IUserPermissions | IUserPermissions[]
	) {
		this.id = user.id;
		this.murtskuId = user.murtskuId;
		this.mobile = user.mobile;
		this.mail = user.mail || null;
		this.isValidMobile = user.isValidMobile;
		this.isValidMail = user.isValidMail;
		this.username = user.username;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.grade = user.grade;
		this.city = user.city;
		this.school = user.school;
		this.country = user.country;
		this.language = user.language;
		this.registrationOrigin = user.registrationOrigin;
		this.hasAgreedOnTerms = user.hasAgreedOnTerms;
		this.profiles = user.profiles;
		this.defaultUser = user;
		this.testAccountType = user.testAccountType;
		this.activateProfile(getActiveUserType());
	}

	private getPermission(userType: UserType) {
		return this.permissions.find(e => e.type === userType);
	}

	private getActivePermissions() {
		return this.permissions.filter((e: UserPermissions) =>
			isActiveUserType(e.type)
		);
	}

	private getProfile(userType: UserType) {
		return this.profiles?.find(e => e.userType === userType);
	}

	private activateProfile(userType: UserType | null) {
		if (!userType) return;
		const activeProfile = this.getProfile(userType);
		if (!activeProfile) {
			this.school = this.defaultUser.school;
			return;
		}
		this.school = activeProfile.school;
	}

	getFullName(): string {
		return this.firstname + " " + this.lastname;
	}

	canAccessAllClassrooms(): boolean {
		return this.permissions.some(p => p.canAccessAllClassrooms());
	}

	canAccessClassroom(classroomId: ObjectId): boolean {
		return this.permissions.some(p => p.canAccessClassroom(classroomId));
	}

	canStudyInClassroom(classroomId: ObjectId): boolean {
		return this.permissions.some(p => p.canStudyInClassroom(classroomId));
	}

	isOwnGroup(groupId: ObjectId): boolean {
		return this.permissions.some(p => p.canAccessGroup(groupId));
	}

	getAvailableClassrooms(): ObjectId[] {
		return this.getOwnIds("getAvailableClassrooms");
	}

	getOwnClassrooms(): ObjectId[] {
		return this.getOwnIds("getOwnClassrooms");
	}

	getOwnGroups(): ObjectId[] {
		return this.getOwnIds("getOwnGroups");
	}

	getOwnLabelIds(): ObjectId[] | null {
		const idsOfPermission = this.getActivePermissions().map(p =>
			p.getOwnLabelIds?.()
		);
		if (idsOfPermission.includes(null)) return null;
		return flatten(idsOfPermission.filter(isNonNullable), "unique");
	}
	getViewableLabelIds(): ObjectId[] | null {
		const idsOfPermission = this.getActivePermissions().map(p =>
			p.getViewableLabelIds?.()
		);
		if (idsOfPermission.includes(null)) return null;
		return flatten(idsOfPermission.filter(isNonNullable), "unique");
	}

	private getOwnIds(fnKey: OwnIdsGetterKeys): ObjectId[] {
		const idsOfPermission = this.permissions.map(p => p[fnKey]?.() || []);
		return flatten(idsOfPermission, "unique");
	}

	private getCanAccess<K extends CanAccessGetterKeys>(
		fnKey: K
	): UserPermissions[K] {
		return (...args: Parameters<UserPermissions[K]>): RCanAccess => {
			const perm: RCanAccess[] = this.permissions.map(p =>
				(p[fnKey] as any)(...args)
			);
			const knownPerms = perm.filter(isKnown);
			if (knownPerms.some(ids => ids.canAccess)) {
				return {
					isKnown: true,
					canAccess: true,
				};
			}
			if (knownPerms.length !== perm.length) {
				return {
					isKnown: false,
				};
			}
			return {
				isKnown: true,
				canAccess: false,
			};
		};
	}

	hasConfirmedChild(childId: number): boolean {
		return this.permissions.some(p => p.hasConfirmedChild(childId));
	}

	getConfirmedChildrenIds(): number[] {
		return this.permissions[0].getConfirmedChildrenIds();
	}

	isTeacher(): boolean {
		return (
			isActiveUserType(UserType.teacher) &&
			this.hasPermissionsOfInstance(TeacherPermissions)
		);
	}

	isStudent(): boolean {
		return (
			isActiveUserType(UserType.student) &&
			this.hasPermissionsOfInstance(StudentPermissions)
		);
	}

	isMainAdmin(): boolean {
		return (
			isActiveUserType(UserType.mainAdmin) &&
			this.hasPermissionsOfInstance(MainAdminPermissions)
		);
	}

	isDeveloper(): boolean {
		return (
			isActiveUserType(UserType.developer) &&
			this.hasPermissionsOfInstance(DeveloperPermissions)
		);
	}

	isParent(): boolean {
		return (
			isActiveUserType(UserType.parent) &&
			this.hasPermissionsOfInstance(ParentPermissions)
		);
	}

	isHeadmaster(): boolean {
		return (
			isActiveUserType(UserType.headmaster) &&
			this.hasPermissionsOfInstance(HeadmasterPermissions)
		);
	}

	getActiveUserType() {
		return getActiveUserType();
	}

	getPacket() {
		const withPacket = this.permissions.find(
			e => e instanceof StudentPermissions && e.getPacket()
		);
		if (!withPacket) return null;
		return ((withPacket as UserPermissions) as StudentPermissions).getPacket();
	}

	getRandomType() {
		if (this.isTeacher()) return UserType.teacher;
		if (this.isParent()) return UserType.parent;
		if (this.isMainAdmin()) return UserType.mainAdmin;
		if (this.isDeveloper()) return UserType.developer;
		if (this.isHeadmaster()) return UserType.headmaster;
		return UserType.student;
	}

	private hasPermissionsOfInstance<
		T extends new (...args: any) => UserPermissions
	>(cls: T) {
		return this.permissions.some(p => p instanceof cls);
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		const idsOfPermission = this.permissions.map(p =>
			p.getAccessibleCourseIds()
		);
		const knownIdsOfPermission = idsOfPermission.filter(isKnown);
		if (knownIdsOfPermission.some(ids => ids.hasAll)) {
			return {
				isKnown: true,
				hasAll: true,
			};
		}
		if (knownIdsOfPermission.length !== idsOfPermission.length) {
			return {
				isKnown: false,
			};
		}
		return {
			isKnown: true,
			hasAll: false,
			ids: flatten(
				knownIdsOfPermission.map(e => (e.hasAll ? [] : e.ids)),
				"unique"
			),
		};
	}

	canAccessFolder(args: {
		courseId: ObjectId;
		folderId: ObjectId;
	}): RCanAccess {
		return this.getCanAccess("canAccessFolder")(args);
	}

	// school permissions
	canViewSchool(schoolId: number) {
		return this.permissions.some(p => p.canViewSchool(schoolId));
	}

	getAllAccessibleUserTypes() {
		const userTypes: UserType[] = [];

		PermissionsWithTypes.forEach(e => {
			if (this.hasPermissionsOfInstance(e.permission)) {
				userTypes.push(e.type);
			}
		});

		return userTypes;
	}
}

const isKnown = <T extends { isKnown: boolean }>(
	el: T
): el is Extract<T, { isKnown: true }> => {
	return el.isKnown;
};

const PermissionsWithTypes: {
	permission: any;
	type: UserType;
}[] = [
	{ permission: TeacherPermissions, type: UserType.teacher },
	{ permission: ParentPermissions, type: UserType.parent },
	{ permission: MainAdminPermissions, type: UserType.mainAdmin },
	{ permission: DeveloperPermissions, type: UserType.developer },
	{ permission: HeadmasterPermissions, type: UserType.headmaster },
	{ permission: StudentPermissions, type: UserType.student },
];
