import React, { useState, useCallback } from "react";
import { FancyPopup } from "@app/components/widgets/popup";
import { FilePreviewWithoutPopup } from "@app/components/widgets/files/file-preview";

import styles from "./styles/index.module.css";
import { LookIcon, DeleteIcon, ChangeIcon } from "@app/icons";

interface IFile {
	path: string;
	name: string;
}

interface IReviewUploadedFilesModalProps {
	files: IFile[];
	onSave: (approvedFiles: IFile[]) => void;
	onClose: () => void;
}

export const ReviewUploadedFilesModal: React.FC<IReviewUploadedFilesModalProps> = ({
	files,
	onClose,
	onSave,
}) => {
	const [currentSelecetedFile, setCurrentSelecetedFile] = useState<IFile>(
		files[0]
	);
	const [deletedIndexes, setDeletedIndexes] = useState<number[]>([]);

	const handleSave = useCallback(
		() => onSave(files.filter((e, i) => !deletedIndexes.includes(i))),
		[deletedIndexes, files, onSave]
	);

	const handleValueSwitch = (index: number) => {
		if (deletedIndexes.includes(index)) {
			setDeletedIndexes(deletedIndexes.filter(e => e !== index));
			return;
		}
		setDeletedIndexes([...deletedIndexes, index]);
	};

	const handleNameClick = (index: number) =>
		setCurrentSelecetedFile(files[index]);

	const title = `გადახედეთ თქვენ მიერ ატვირთულ ფაილ${
		files.length === 1 ? "ს" : "ებს"
	}`;

	return (
		<FancyPopup onClose={onClose}>
			<div className={styles.mainWrapper}>
				<div className={styles.mainContainer}>
					<div className={styles.heading}>{title}</div>
					{files.map((e, i) => (
						<FileRow
							key={i}
							index={i}
							fileName={e.name}
							handleSwitch={handleValueSwitch}
							onNameClick={handleNameClick}
							isSelected={currentSelecetedFile.path === e.path}
							isDeleted={deletedIndexes.includes(i)}
							canBeDeleted={files.length !== 1}
						/>
					))}
				</div>
				<ControlButtons onReject={onClose} onConfirm={handleSave} />
				{currentSelecetedFile && (
					<FilePreviewWithoutPopup
						fileName={currentSelecetedFile.name}
						fileUrl={currentSelecetedFile.path}
					/>
				)}
			</div>
		</FancyPopup>
	);
};

export const ControlButtons = React.memo<{
	onReject: () => void;
	onConfirm: () => void;
}>(function ControlButtons({ onReject, onConfirm }) {
	return (
		<div className={styles.buttonContainer}>
			<button className={styles.grayButton} onClick={onReject}>
				გაუქმება
			</button>
			<button className={styles.greenButton} onClick={onConfirm}>
				შენახვა
			</button>
		</div>
	);
});

const FileRow: React.FC<{
	index: number;
	isDeleted: boolean;
	isSelected: boolean;
	canBeDeleted: boolean;
	fileName: string;
	handleSwitch: (index: number) => void;
	onNameClick: (index: number) => void;
}> = React.memo(
	({
		fileName,
		index,
		isSelected,
		isDeleted,
		handleSwitch,
		onNameClick,
		canBeDeleted,
	}) => {
		const withIndex = (fn: (i: number) => void) => (
			e: React.MouseEvent<HTMLDivElement, MouseEvent>
		) => {
			e.stopPropagation();
			fn(index);
		};

		return (
			<div
				className={isSelected ? styles.selectedRow : styles.row}
				onClick={withIndex(onNameClick)}
			>
				<div>{fileName}</div>

				{isDeleted ? (
					<div
						className={styles.selectButton}
						onClick={withIndex(handleSwitch)}
					>
						აღდგენა
						<ChangeIcon className={styles.icon} />
					</div>
				) : (
					<div className={styles.centerFlex}>
						{!isSelected && (
							<div className={styles.selectButton}>
								ნახვა
								<LookIcon className={styles.icon} />
							</div>
						)}
						{canBeDeleted && (
							<div
								className={styles.deleteButton}
								onClick={withIndex(handleSwitch)}
							>
								წაშლა
								<DeleteIcon className={styles.icon} />
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
);
