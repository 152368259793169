import React, { useEffect, useRef } from "react";
import styles from "./styles/video-player.module.css";

export const VideoPlayer: React.FC<{ fileUrl: string; format: string }> = ({
	fileUrl,
	format,
}) => {
	const playerRef = React.createRef<HTMLVideoElement>();

	// useEffect(() => {
	// 	if (playerRef.current) {
	// 		playerRef.current.play();
	// 	}
	// }, [playerRef]);

	return (
		<div className={styles.container}>
			<video ref={playerRef} className={styles.player} controls>
				<source src={fileUrl} type={`video/${format}`} />
			</video>
		</div>
	);
};
