import React, { useContext } from "react";
import { LabelSelector } from "@app/components/headmaster/header/label-selector";
import { subWebsiteDistributor } from "@app/components/subwebsites";
import { TeacherSchoolsSelector } from "./schools-selector";
import { Calls } from "@app/components/widgets/calls";
import styles from "../../tv-school-landing/styles/tv-school-landing.module.css";
import { useWindowSize } from "@app/hooks/front";
import { GeneralContext } from "@app/components/general-context";

const BC = React.memo<any>(function BC(props) {
	const { width } = useWindowSize();
	const {
		data: { headerTheme },
	} = useContext(GeneralContext);
	return (
		<>
			<TeacherSchoolsSelector {...props} hideIfOnlyOneSchool={true} />
			<LabelSelector {...props} />
			{width > 800 && (
				// TODO: remove headmasterCallsBC and add support of light theme in Calls component
				<Calls
					containerClassname={
						headerTheme !== "dark"
							? styles.headmasterCallsBC
							: undefined
					}
				/>
			)}
		</>
	);
});

export const TitleNextToLogo = subWebsiteDistributor({
	main: TeacherSchoolsSelector,
	britishCenter: BC,
});
