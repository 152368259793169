import { IRequest } from "@app/api/requests";
import {
	IAPOSTTargetConcept,
	APOSTTargetConceptSchema,
	IRPOSTTargetConcept,
	IAPOSTComplexAssignmentTopic,
	IRPOSTComplexAssignmentTopic,
	APOSTComplexAssignmentTopicSchema,
	IAPOSTComplexAssignmentResults,
	IRPOSTComplexAssignmentResults,
	APOSTComplexAssignmentResultsSchema,
	IAPOSTComplexAssignment,
	IRPOSTComplexAssignment,
	APOSTComplexAssignmentSchema,
	RPOSTComplexAssignmentSchema,
	IAGETTargetConcepts,
	IRGETTargetConcepts,
	AGETTargetConceptsSchema,
	RGETTargetConceptsSchema,
	IAGETComplexAssignmentTopics,
	IRGETComplexAssignmentTopics,
	AGETComplexAssignmentTopicsSchema,
	RGETComplexAssignmentTopicsSchema,
	IAGETComplexAssignmentResults,
	IRGETComplexAssignmentResults,
	AGETComplexAssignmentResultsSchema,
	RGETComplexAssignmentResultsSchema,
	RPOSTTargetConceptSchema,
	RPOSTComplexAssignmentTopicSchema,
	RPOSTComplexAssignmentResultsSchema,
	IAGETTargetConceptById,
	IRGETTargetConceptById,
	AGETTargetConceptByIdSchema,
	RGETTargetConceptByIdSchema,
	IADELETETargetConceptById,
	ADELETETargetConceptByIdSchema,
	IAPUTTargetConcept,
	IRPUTTargetConcept,
	APUTTargetConceptSchema,
	RPUTTargetConceptSchema,
	AGETComplexAssignmentTopicByIdSchema,
	RGETComplexAssignmentTopicByIdSchema,
	IAGETComplexAssignmentTopicById,
	IRGETComplexAssignmentTopicById,
	IAPUTComplexAssignmentTopic,
	IRPUTComplexAssignmentTopic,
	APUTComplexAssignmentTopicSchema,
	RPUTComplexAssignmentTopicSchema,
	IADELETEComplexAssignmentTopicById,
	IRDELETEComplexAssignmentTopicById,
	ADELETEComplexAssignmentTopicByIdSchema,
	IAGETResultGroupById,
	IRGETResultGroupById,
	AGETResultGroupByIdSchema,
	RGETResultGroupByIdSchema,
	IAPUTResultGroupById,
	IRPUTResultGroupById,
	APUTResultGroupByIdSchema,
	RPUTResultGroupByIdSchema,
	ADELETEResultGroupByIdSchema,
	IADELETEResultGroupById,
	IRDELETEResultGroupById,
	IRGETResultGroups,
	RGETResultGroupsSchema,
	IAGETComplexAssignments,
	IRGETComplexAssignments,
	AGETComplexAssignmentsSchema,
	RGETComplexAssignmentsSchema,
	IAGETComplexAssignmentById,
	IRGETComplexAssignmentById,
	AGETComplexAssignmentByIdSchema,
	RGETComplexAssignmentByIdSchema,
} from "./validators";

export class ComplexAssignmentController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	createTargetConcept = async (
		args: IAPOSTTargetConcept
	): Promise<IRPOSTTargetConcept> =>
		this.Request.send(
			"POST",
			"/api/complex-assignments/target-concepts",
			args,
			null,
			{
				requestSchema: APOSTTargetConceptSchema,
				responseSchema: RPOSTTargetConceptSchema,
			}
		);

	createTopic = async (
		args: IAPOSTComplexAssignmentTopic
	): Promise<IRPOSTComplexAssignmentTopic> =>
		this.Request.send(
			"POST",
			"/api/complex-assignments/topics",
			args,
			null,
			{
				requestSchema: APOSTComplexAssignmentTopicSchema,
				responseSchema: RPOSTComplexAssignmentTopicSchema,
			}
		);

	createResults = async (
		args: IAPOSTComplexAssignmentResults
	): Promise<IRPOSTComplexAssignmentResults> =>
		this.Request.send(
			"POST",
			"/api/complex-assignments/results",
			args,
			null,
			{
				requestSchema: APOSTComplexAssignmentResultsSchema,
				responseSchema: RPOSTComplexAssignmentResultsSchema,
			}
		);

	createAssignment = async (
		args: IAPOSTComplexAssignment
	): Promise<IRPOSTComplexAssignment> =>
		this.Request.send("POST", "/api/complex-assignments/", args, null, {
			requestSchema: APOSTComplexAssignmentSchema,
			responseSchema: RPOSTComplexAssignmentSchema,
		});

	getAssignments = async (
		args: IAGETComplexAssignments
	): Promise<IRGETComplexAssignments> =>
		this.Request.send("GET", "/api/complex-assignments/", args, null, {
			requestSchema: AGETComplexAssignmentsSchema,
			responseSchema: RGETComplexAssignmentsSchema,
		});

	getAssignmentById = async (
		args: IAGETComplexAssignmentById
	): Promise<IRGETComplexAssignmentById> =>
		this.Request.send("GET", "/api/complex-assignments/:_id", args, null, {
			requestSchema: AGETComplexAssignmentByIdSchema,
			responseSchema: RGETComplexAssignmentByIdSchema,
		});

	getTargetConcepts = async (
		args: IAGETTargetConcepts
	): Promise<IRGETTargetConcepts> =>
		this.Request.send(
			"GET",
			"/api/complex-assignments/target-concepts",
			args,
			null,
			{
				requestSchema: AGETTargetConceptsSchema,
				responseSchema: RGETTargetConceptsSchema,
			}
		);

	getTopics = async (
		args: IAGETComplexAssignmentTopics
	): Promise<IRGETComplexAssignmentTopics> =>
		this.Request.send(
			"GET",
			"/api/complex-assignments/topics",
			args,
			null,
			{
				requestSchema: AGETComplexAssignmentTopicsSchema,
				responseSchema: RGETComplexAssignmentTopicsSchema,
			}
		);

	getResults = async (
		args: IAGETComplexAssignmentResults
	): Promise<IRGETComplexAssignmentResults> =>
		this.Request.send(
			"GET",
			"/api/complex-assignments/results",
			args,
			null,
			{
				requestSchema: AGETComplexAssignmentResultsSchema,
				responseSchema: RGETComplexAssignmentResultsSchema,
			}
		);

	async getTargetConceptById(
		args: IAGETTargetConceptById
	): Promise<IRGETTargetConceptById> {
		return this.Request.send(
			"GET",
			"/api/complex-assignments/target-concepts/:_id",
			args,
			null,
			{
				requestSchema: AGETTargetConceptByIdSchema,
				responseSchema: RGETTargetConceptByIdSchema,
			}
		);
	}

	async deleteTargetConceptById(
		args: IADELETETargetConceptById
	): Promise<void> {
		return this.Request.send(
			"DELETE",
			"/api/complex-assignments/target-concepts/:_id",
			args,
			null,
			{
				requestSchema: ADELETETargetConceptByIdSchema,
			}
		);
	}

	async updateTargetConcept(
		args: IAPUTTargetConcept
	): Promise<IRPUTTargetConcept> {
		return this.Request.send(
			"PUT",
			"/api/complex-assignments/target-concepts/:_id",
			args,
			null,
			{
				requestSchema: APUTTargetConceptSchema,
				responseSchema: RPUTTargetConceptSchema,
			}
		);
	}

	async getTopicById(
		args: IAGETComplexAssignmentTopicById
	): Promise<IRGETComplexAssignmentTopicById> {
		return this.Request.send(
			"GET",
			"/api/complex-assignments/topics/:_id",
			args,
			null,
			{
				requestSchema: AGETComplexAssignmentTopicByIdSchema,
				responseSchema: RGETComplexAssignmentTopicByIdSchema,
			}
		);
	}

	async updateTopic(
		args: IAPUTComplexAssignmentTopic
	): Promise<IRPUTComplexAssignmentTopic> {
		return this.Request.send(
			"PUT",
			"/api/complex-assignments/topics/:_id",
			args,
			null,
			{
				requestSchema: APUTComplexAssignmentTopicSchema,
				responseSchema: RPUTComplexAssignmentTopicSchema,
			}
		);
	}

	async deleteTopic(
		args: IADELETEComplexAssignmentTopicById
	): Promise<IRDELETEComplexAssignmentTopicById> {
		return this.Request.send(
			"DELETE",
			"/api/complex-assignments/topics/:_id",
			args,
			null,
			{
				requestSchema: ADELETEComplexAssignmentTopicByIdSchema,
			}
		);
	}

	async getResultGroups(): Promise<IRGETResultGroups> {
		return this.Request.send(
			"GET",
			"/api/complex-assignments/result-groups",
			undefined,
			null,
			{
				responseSchema: RGETResultGroupsSchema,
			}
		);
	}

	async getResultGroupsById(
		args: IAGETResultGroupById
	): Promise<IRGETResultGroupById> {
		return this.Request.send(
			"GET",
			"/api/complex-assignments/result-groups/:_id",
			args,
			null,
			{
				requestSchema: AGETResultGroupByIdSchema,
				responseSchema: RGETResultGroupByIdSchema,
			}
		);
	}

	async updateResultGroupById(
		args: IAPUTResultGroupById
	): Promise<IRPUTResultGroupById> {
		return this.Request.send(
			"PUT",
			"/api/complex-assignments/result-groups/:_id",
			args,
			null,
			{
				requestSchema: APUTResultGroupByIdSchema,
				responseSchema: RPUTResultGroupByIdSchema,
			}
		);
	}

	async deleteResultGroupById(
		args: IADELETEResultGroupById
	): Promise<IRDELETEResultGroupById> {
		return this.Request.send(
			"DELETE",
			"/api/complex-assignments/result-groups/:_id",
			args,
			null,
			{
				requestSchema: ADELETEResultGroupByIdSchema,
			}
		);
	}
}
