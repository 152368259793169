import { ObjectId } from "@app/utils/generics";

export const isSuitable = <T extends string | number | ObjectId>(
	query: T | T[] | null | undefined,
	original: T | T[] | null | undefined
) => {
	if (
		query === undefined ||
		query === null ||
		original === undefined ||
		original === null
	) {
		return true;
	}
	const suitable = Array.isArray(original) ? original : [original];
	if (Array.isArray(query)) {
		return (
			suitable.findIndex(each => {
				return (
					query.findIndex(e => each.toString() === e.toString()) !==
					-1
				);
			}) !== -1
		);
		/* if (getIntersectionOfSets(new Set(many), new Set(one)).size === 0) {
			return false;
		} */
	} else if (
		suitable.findIndex(e => query.toString() === e.toString()) === -1
	) {
		return false;
	}
	return true;
};
