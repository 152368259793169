import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const UserRequestSchema = Joi.object({
	_id: Joi.objectId().required(),
	message: Joi.string()
		.allow("", null)
		.required(),
	meta: Joi.object({
		subjectId: Joi.alternatives(
			Joi.objectId(),
			Joi.array().items(Joi.objectId())
		).allow(null),
		courseId: Joi.objectId().allow(null),
		grade: Joi.number().allow(null),
	})
		.unknown()
		.required(),
	author: Joi.number(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IUserRequest {
	_id: ObjectId;
	message: string | null;
	meta: {
		subjectId?: ObjectId | ObjectId[] | null;
		courseId?: ObjectId | null;
		grade?: number | null;
	} & Record<any, any>;
	author?: number;
	createdAt: Date;
	updatedAt: Date;
}
