import {
	CourseSettingsSchema,
	ICourseSettings,
} from "@app/api/courses-info/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "courseId";
type IdKey = typeof keyOfId;
type DOC = ICourseSettings;
export type IStateCoursesSettings = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(CourseSettingsSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("courseSettings", "coursesSettings");
const storageSettings = getDefaultStorageSettings("coursesSettings");
const metaSettingsrmationName = "coursesSettingsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(10 * 60 * 1000, MIN_LOAD_TIME);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().coursesSettings) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				CourseSettingsSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class CourseSettings extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByCourseIdSync = Model.findByIdSync;
	static findManyByCourseIdsSync = Model.findManyByIdsSync;

	static meta = new CommonMetaInfo(storage, metaSettingsrmationName);
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaSettingsrmationName,
	CourseSettings.meta
);

export const coursesSettingsReducer = getDefaultReducer(
	storageSettings,
	() => CourseSettings
);

///
