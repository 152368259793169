import { useSubWebsite } from "@app/hooks/bc";
import { SubWebsiteOrigin } from "@app/globals";
import { createComponentDistributor } from "@pckgs/component-distributor";

export const subWebsiteDistributor = createComponentDistributor(
	(): keyof SubWebsiteOrigin => {
		const subWebsite = useSubWebsite();
		if (subWebsite === SubWebsiteOrigin.britishCenter) {
			return "britishCenter";
		}
		return "main";
	}
);
