import { FancyPopup } from "@app/components/widgets/popup";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { User } from "@app/user";
import React from "react";
import EduFairMobSVG from "./edu-fair-mob.svg";
import EduFairSVG from "./edu-fair.svg";
import styles from "./styles.module.scss";

export const EducationalFairPopupDistributor = React.memo<{}>(
	function EducationalFairPopupDistributor({}) {
		const user = useClassroomsUser();
		const userActions = useUserActions();

		const { value: isClosedManually, setTrue: closeManually } = useBoolean(
			false
		);
		const isEligibleForFair = isUserEligibleForEducationalFair(user);

		const showPopup =
			isEligibleForFair &&
			userActions.isSuccessfullyLoaded &&
			timeToShowPopup(
				userActions.doc.actions[EDUCATION_FAIR_POPUP1_ACTION_KEY]
			) &&
			!isClosedManually;

		return (
			<DefaultPopupPrioritizer
				preemptive={true}
				priority={100}
				enabled={showPopup}
			>
				<EducationalFairPopup onClose={closeManually} />
			</DefaultPopupPrioritizer>
		);
	}
);

export const isUserEligibleForEducationalFair = (
	user: User | null | undefined
) => {
	if (!user || !user.isStudent()) return false;
	return !!user.grade && user.grade >= 11 && user.grade <= 12;
};

export const isUserLightlyEligibleForEducationalFair = (
	user: User | null | undefined
) => {
	if (!user) return false;
	if (user.isTeacher()) return true;
	if (!user.isStudent()) return null;
	return !!user.grade && user.grade >= 9 && user.grade <= 12;
};

const dayInMs = 24 * 60 * 60 * 1000;

const timeToShowPopup = (data: any): boolean => {
	if (!data) return true;
	if (data.registered) return false;
	if (
		!data.displayDate ||
		isTimePassed(new Date(data.displayDate), dayInMs) ||
		!sessionStorage.getItem(EDUCATION_FAIR_POPUP1_ACTION_KEY)
	) {
		return true;
	}
	return false;
};

const isTimePassed = (date: Date, timeMs: number) => {
	return Date.now() - date.getTime() >= timeMs;
};

const EDUCATION_FAIR_POPUP1_ACTION_KEY = "edfair1";

export const EducationalFairPopup = React.memo<{ onClose: () => void }>(
	function EducationalFairPopup({ onClose }) {
		const onReject = () => {
			inject("UserActionsController").performUserAction({
				name: EDUCATION_FAIR_POPUP1_ACTION_KEY,
				data: { displayDate: new Date() },
			});
			sessionStorage.setItem(EDUCATION_FAIR_POPUP1_ACTION_KEY, "f");
			onClose();
		};
		const onConfirm = () => {
			inject("UserActionsController").performUserAction({
				name: EDUCATION_FAIR_POPUP1_ACTION_KEY,
				data: { registered: true, displayDate: new Date() },
			});
			window.open("https://educationfair.ge/?111t=", "__blank");
			sessionStorage.setItem(EDUCATION_FAIR_POPUP1_ACTION_KEY, "t");
			onClose();
		};
		return <ValidationPopup onReject={onReject} onConfirm={onConfirm} />;
	}
);

const ValidationPopup: React.FC<{
	onReject: () => void;
	onConfirm: () => void;
}> = ({ onReject, onConfirm }) => {
	const listItems = [
		"საქართველოს 51 უნივერსიტეტი",
		"NAEC-ის ექსპერტებისაგან ინფორმაცია საგამოცდო სიახლეების შესახებ",
		"მომავლის პროფესიები",
		"სტაჟირების შესაძლებლობა",
		"გაცვლითი პროგრამები საზღვარგარეთ",
		"გრანტები და სხვა",
	];
	const { width } = useWindowSize();

	return (
		<FancyPopup
			containerClassName={`${styles.content} ${styles["edu-fair"]}`}
			hideCloseIcon={true}
			containerStyles={{
				width: 1000,
				padding: 0,
				boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				borderRadius: 21,
			}}
			direction="left"
		>
			<div className={styles.imageContainer}>
				<img src={width > 1223 ? EduFairSVG : EduFairMobSVG} alt="" />
			</div>
			<div className={styles.contentDiv}>
				<button className={styles.doNotMiss}>
					არ <br /> გამოტოვო!
				</button>
				<br />
				<h3>27-28 მაისი</h3>
				<p>
					<b>გამოფენა უკვე დაიწყო!</b> არ გამოტოვო შესაძლებლობა,
					დაესწრო განათლების საერთაშორისო გამოფენას, სადაც დაგხვდება:
					<br />
					<ul>
						{listItems.map((item, i) => (
							<li key={i}>
								<span>{item}</span>
							</li>
						))}
					</ul>
				</p>
				<div className={styles.buttonsContainer}>
					{width > 1223 && <span onClick={onReject}>დახურვა</span>}
					<button onClick={onConfirm}>გადასვლა</button>
					{width <= 1223 && (
						<>
							<br />
							<span onClick={onReject}>დახურვა</span>
						</>
					)}
				</div>
			</div>
		</FancyPopup>
	);
};
