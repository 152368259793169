import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { SetState } from "react-prop-hooks";
import { useTeacherClassroomsSchoolIds } from "@app/hooks/teacher";
import { useClassroomsUser } from "@app/hooks/users";
import { ObjectId } from "@app/utils/generics";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useSimplifiedSavedValue } from "./hooks";
import { SchoolLabelInitializer } from "./school-labels";

interface TeacherSchoolContextValue {
	schoolId: number | null;
	setSchoolId: SetState<number | null>;
}

const TeacherSchoolContext = React.createContext<TeacherSchoolContextValue>(
	{} as any
);

interface SchoolLabelContextValue {
	labelId: ObjectId | null;
	setLabelId: SetState<ObjectId | null>;
}

const SchoolLabelContext = React.createContext<SchoolLabelContextValue>(
	{} as any
);

export const useTeacherSelectedSchoolId = () => {
	return useContext(TeacherSchoolContext).schoolId;
};
export const useTeacherSchoolSetterFn = () => {
	return useContext(TeacherSchoolContext).setSchoolId;
};

export const useSelectedSchoolLabelId = () => {
	return useContext(SchoolLabelContext).labelId;
};
export const useSelectedSchoolLabelSetterFn = () => {
	return useContext(SchoolLabelContext).setLabelId;
};

export const TeacherSchool = ({ children }) => {
	const [schoolId, setSchoolId] = useState<number | null>(null);
	const [labelId, setLabelId] = useState<ObjectId | null>(null);
	useEffect(() => {
		console.log("selectedLabelId", labelId);
	}, [labelId]);
	const user = useClassroomsUser();
	const scValue = useMemo(() => ({ schoolId, setSchoolId }), [schoolId]);
	const lbValue = useMemo(() => ({ labelId, setLabelId }), [labelId]);
	const currentSubWebsite = useSubWebsite();

	const showLabelInitializer =
		user?.isHeadmaster() ||
		(currentSubWebsite === SubWebsiteOrigin.britishCenter &&
			user?.isTeacher());

	return (
		<TeacherSchoolContext.Provider value={scValue}>
			<SchoolLabelContext.Provider value={lbValue}>
				{user?.isTeacher() && (
					<TeacherSchoolInitializer
						schoolId={schoolId}
						setSchoolId={setSchoolId}
					/>
				)}
				{showLabelInitializer && (
					<SchoolLabelInitializer
						labelId={labelId}
						setLabelId={setLabelId}
					/>
				)}
				{children}
			</SchoolLabelContext.Provider>
		</TeacherSchoolContext.Provider>
	);
};

export const SELECTED_SCHOOL_ID_QUERY_KEY = "tSchoolId";
const LAST_SELECTED_SCHOOL_ID_KEY = "LAST_SELECTED_SCHOOL_ID";
const LAST_SCHOOL_ACTION_KEY = "lastVisitedSchoolId";

const TeacherSchoolInitializer = React.memo<TeacherSchoolContextValue>(
	({ schoolId, setSchoolId }) => {
		const userSchool = useClassroomsUser()!.school;
		const teacherSchoolsInfo = useTeacherClassroomsSchoolIds();
		const isValidSchoolMemoizedFn = useCallback(
			(schoolId: number | null) =>
				isValidSchool(schoolId, teacherSchoolsInfo!),
			[teacherSchoolsInfo]
		);

		const getLastPrioritySchool = useCallback(() => {
			if (teacherSchoolsInfo!.schoolIds.length > 0) {
				return teacherSchoolsInfo!.schoolIds[0];
			}
			return userSchool;
		}, [teacherSchoolsInfo, userSchool]);

		useSimplifiedSavedValue({
			currentValue: schoolId,
			setValue: setSchoolId,
			isLoaded: !!teacherSchoolsInfo,
			isValidValue: isValidSchoolMemoizedFn,
			getLastPriorityValue: getLastPrioritySchool,
			keys: {
				action: LAST_SCHOOL_ACTION_KEY,
				session: LAST_SELECTED_SCHOOL_ID_KEY,
				query: SELECTED_SCHOOL_ID_QUERY_KEY,
			},
			parseValueFromStr: parseIdFromStr,
		});
		return null;
	}
);

const isValidSchool = (
	schoolId: number | null,
	teacherSchoolsInfo: NonNullable<
		ReturnType<typeof useTeacherClassroomsSchoolIds>
	>
): boolean => {
	if (schoolId === null) return teacherSchoolsInfo.hasSchoollessClassroom;
	return teacherSchoolsInfo.schoolIds.includes(schoolId);
};

const parseIdFromStr = (
	value: string | null | undefined
): number | null | undefined => {
	if (value === "null") return null;
	return !value ? undefined : +(value as string);
};
