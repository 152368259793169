import React from "react";
import { BoldButton, ItalicButton, UnderlineButton } from "draft-js-buttons";
import createInlineStyleButton from "draft-js-buttons/lib/utils/createInlineStyleButton";
import { ToolbarChildrenProps } from "draft-js-inline-toolbar-plugin";

const SupButton = createInlineStyleButton({
	style: "SUPERSCRIPT",
	children: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22px"
			height="22px"
			viewBox="0 0 24 24"
			version="1.1"
		>
			<path
				style={{
					stroke: "none",
					fillRule: "nonzero",
					fill: "rgb(0%,0%,0%)",
				}}
				d="M 10.308594 15.199219 L 16.746094 22.425781 L 12.785156 22.425781 L 8.277344 17.140625 L 3.875 22.425781 L 0 22.425781 L 6.382812 15.199219 L 0.308594 8.375 L 4.214844 8.375 L 8.449219 13.320312 L 12.71875 8.375 L 16.457031 8.375 Z M 19.109375 10.308594 L 21.761719 8.355469 C 22.664062 7.734375 23.261719 7.160156 23.546875 6.628906 C 23.828125 6.09375 23.972656 5.523438 23.972656 4.910156 C 23.972656 3.910156 23.636719 3.105469 22.96875 2.488281 C 22.300781 1.875 21.421875 1.566406 20.332031 1.566406 C 19.28125 1.566406 18.441406 1.878906 17.8125 2.5 C 17.183594 3.121094 16.867188 4.058594 16.867188 5.3125 L 18.894531 5.3125 C 18.894531 4.5625 19.027344 4.046875 19.292969 3.761719 C 19.554688 3.472656 19.917969 3.332031 20.382812 3.332031 C 20.84375 3.332031 21.207031 3.476562 21.476562 3.769531 C 21.746094 4.058594 21.878906 4.421875 21.878906 4.851562 C 21.878906 5.285156 21.757812 5.675781 21.507812 6.027344 C 21.253906 6.375 20.558594 6.964844 19.417969 7.796875 C 18.441406 8.511719 17.121094 9.183594 16.726562 9.816406 L 16.746094 12.160156 L 23.996094 12.160156 L 23.996094 10.308594 Z M 19.109375 10.308594 "
			/>
		</svg>
	),
});
const SubButton = createInlineStyleButton({
	style: "SUBSCRIPT",
	children: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 24 24"
			version="1.1"
			style={{ verticalAlign: "bottom" }}
		>
			<path
				style={{
					stroke: "none",
					fillRule: "nonzero",
					fill: "rgb(0%,0%,0%)",
				}}
				d="M 16.746094 16.625 L 12.785156 16.625 L 8.277344 11.339844 L 3.875 16.625 L 0 16.625 L 6.382812 9.398438 L 0.308594 2.574219 L 4.214844 2.574219 L 8.449219 7.519531 L 12.714844 2.574219 L 16.453125 2.574219 L 10.308594 9.398438 Z M 19.179688 19.5625 L 21.757812 17.609375 C 22.660156 16.988281 23.253906 16.414062 23.539062 15.882812 C 23.824219 15.347656 23.96875 14.777344 23.96875 14.164062 C 23.96875 13.164062 23.632812 12.359375 22.964844 11.742188 C 22.296875 11.128906 21.417969 10.820312 20.328125 10.820312 C 19.277344 10.820312 18.4375 11.132812 17.808594 11.753906 C 17.179688 12.375 16.863281 13.3125 16.863281 14.566406 L 18.890625 14.566406 C 18.890625 13.816406 19.023438 13.300781 19.285156 13.015625 C 19.550781 12.726562 19.914062 12.585938 20.375 12.585938 C 20.835938 12.585938 21.203125 12.730469 21.472656 13.019531 C 21.742188 13.3125 21.875 13.675781 21.875 14.105469 C 21.875 14.539062 21.75 14.929688 21.5 15.28125 C 21.25 15.628906 20.554688 16.21875 19.414062 17.050781 C 18.4375 17.765625 17.117188 18.4375 16.71875 19.066406 L 16.742188 21.414062 L 23.988281 21.414062 L 23.988281 19.5625 Z M 19.179688 19.5625 "
			/>
		</svg>
	),
});

export const toolbar = (externalProps: ToolbarChildrenProps) => {
	const props = externalProps as any;
	return (
		<>
			<BoldButton {...props} />
			<ItalicButton {...props} />
			<UnderlineButton {...props} />
			<SupButton {...props} />
			<SubButton {...props} />
		</>
	);
};

export const inlineToolbarOptions = {
	theme: {
		toolbarStyles: {
			toolbar: "inline-toolbar",
		},
		buttonStyles: {
			button: "inline-toolbar-button",
			buttonWrapper: "inline-toolbar-button-wrapper",
			active: "inline-toolbar-button-active",
		},
	},
};
