import Joi from "@app/utils/joi";
import { ObjectId, OptionalKeys } from "@app/utils/generics";
import { IStudentGradebook, StudentGradebookSchema } from "./helper-schemas";
import { IStudentGrade, StudentGradeSchema } from "../grades/helper-schemas";
import { markKeysOptional, markKeysStripped } from "../../helper-schemas";

export const APOSTStudentGradesSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.required(),
				grade: StudentGradeSchema.fork("id", markKeysOptional)
					.fork(["gradedAt", "gradedBy"], markKeysStripped)
					.required(),
			})
		)
		.required(),
	includeDisabled: Joi.boolean(),
});
export interface IAPOSTStudentGrades {
	classroomId: ObjectId;
	grades: {
		userId: number;
		grade: Omit<OptionalKeys<IStudentGrade, "id">, "gradedAt" | "gradedBy">;
	}[];
	includeDisabled?: boolean;
}

export const RPOSTStudentGradesSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRPOSTStudentGrades = IStudentGradebook[];

///

export const AGETClassroomStudentGradebooksSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	includeDisabled: Joi.boolean(),
});
export interface IAGETClassroomStudentGradebooks {
	classroomId: ObjectId;
	includeDisabled?: boolean;
}

export const RGETClassroomStudentGradebooksSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRGETClassroomStudentGradebooks = IStudentGradebook[];

///

export const AGETStudentGradebookSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	includeDisabled: Joi.boolean(),
});
export interface IAGETStudentGradebook {
	classroomId: ObjectId;
	userId: number;
	includeDisabled?: boolean;
}

export type IRGETStudentGradebook = IStudentGradebook;

export const ADELETEStudentGradesSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.min(0)
					.required(),
				id: Joi.objectId().required(),
			})
		)
		.required(),
});
export interface IADELETEStudentGrades {
	classroomId: ObjectId;
	grades: {
		userId: number;
		id: ObjectId;
	}[];
}

export const RDELETEStudentGradesSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRDELETEStudentGrades = IStudentGradebook[];

export const APUTGradesDisabilitySchema = Joi.object({
	disabilityStatus: Joi.boolean().required(),
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.min(0)
					.required(),
				id: Joi.objectId().required(),
			})
		)
		.required(),
});
export interface IAPUTGradesDisability {
	disabilityStatus: boolean;
	classroomId: ObjectId;
	grades: {
		userId: number;
		id: ObjectId;
	}[];
}

export const RPUTGradesDisabilityschema = Joi.array().items(
	StudentGradebookSchema
);
export type IRPUTGradesDisability = IStudentGradebook[];

///

export const APUTConvertGradebookCategoriesSchema = Joi.object().keys({
	sourceCategoryId: Joi.objectId().required(),
	targetCategoryId: Joi.objectId().required(),
	schoolId: Joi.number().optional(),
});
export interface IAPUTConvertGradebookCategoriesSchema {
	sourceCategoryId: ObjectId;
	targetCategoryId: ObjectId;
	schoolId?: number;
}
//

export interface IAPOSTSavePassedSubject {
	studentId: number;
	gradebookId: ObjectId;
	isPassed: boolean;
}
