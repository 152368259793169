import React from "react";
import styles from "./styles/settings.module.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useBoolean } from "@app/hooks/general";

interface ISettingsProps<ValueType extends string | number> {
	icon: JSX.Element;
	className?: string;
	options: {
		value: ValueType;
		label: string;
		icon?: JSX.Element;
	}[];
	onSelect: (args: {
		value: ValueType;
		label: string;
		icon?: JSX.Element;
	}) => void;
	optionsIconPosition: "left" | "right" | null;
}

export const Settings = <T extends string | number>(
	props: ISettingsProps<T>
) => {
	const {
		value: isMenuDisplayed,
		setFalse: hideMenu,
		switchValue: switchMenuVisibility,
	} = useBoolean();
	return (
		<ClickAwayListener onClickAway={hideMenu}>
			<div
				onClick={switchMenuVisibility}
				className={
					props.className ? props.className : styles.iconContainer
				}
				style={{ zIndex: 10 }}
			>
				{props.icon}

				{isMenuDisplayed && (
					<div className={styles.optionsButtonContainer}>
						{props.options.map(option => (
							<div
								key={option.value}
								onClick={() => props.onSelect(option)}
								className={
									props.optionsIconPosition === "right"
										? styles.optionsButtonRightIcon
										: styles.optionsButtonLeftIcon
								}
							>
								{props.optionsIconPosition &&
									props.optionsIconPosition === "left" &&
									option.icon}
								<div style={{ margin: "0px 10px" }}>
									{option.label}
								</div>
								{props.optionsIconPosition &&
									props.optionsIconPosition === "right" &&
									option.icon}
							</div>
						))}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};
