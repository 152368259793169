import { ISubject } from "@app/api/subjects/helper-schemas";
import {
	IRGETProfileInfoAsTeacher,
	IRGETProfileInfoAsStudent,
} from "@app/api/users/validators";
import classnames from "classnames";
import React, { useState } from "react";
import { ReactComponent as ScrollDownIcon } from "./general_imgs/scroll-down.svg";
import { ReactComponent as ScrollUpIcon } from "./general_imgs/scroll-up.svg";
import genStyles from "./styles/general_styles.module.css";
import { TeacherSubject } from "../teacher-profile/teacher_subjects";
import { FormattedMessage } from "react-intl";
import SertificateIcon from "./general_imgs/Award.jpg";
import { ProfileSubjectPagnations } from "../student-profile/right-panel";
import { useSubjectsForProfileWithPagnation } from "./hooks";

export const BottomSideBar: React.FC<{
	subjects: ISubject[];
	teacherInfo: IRGETProfileInfoAsTeacher;
}> = ({ subjects, teacherInfo }) => {
	const [toggle, setToggle] = useState(false);

	const toggleBottomBar = () => {
		setToggle(!toggle);
	};
	return (
		<section
			className={classnames(
				genStyles.profile_bottom__sidebar,
				!toggle && genStyles.show_additional_info
			)}
			onClick={toggleBottomBar}
		>
			{!toggle ? (
				<section className={genStyles.blue_arc}></section>
			) : (
				<section className={genStyles.white_arc}></section>
			)}
			{!toggle ? (
				<ScrollUpIcon className={genStyles.scroll_up} />
			) : (
				<ScrollDownIcon className={genStyles.scroll_down} />
			)}

			{!toggle ? (
				<p className={genStyles.show_info_text}>
					<FormattedMessage id="additionalInformation" />
				</p>
			) : (
				""
			)}

			<section className={genStyles.right__sidebar_content_wrapper}>
				{teacherInfo && subjects && (
					<TeacherSubject
						subjects={subjects}
						teacherInfo={teacherInfo}
					/>
				)}
			</section>
		</section>
	);
};

export const BottomSideBarStudent: React.FC<{
	info: IRGETProfileInfoAsStudent;
	onCertificateClick: () => void;
}> = ({ info, onCertificateClick }) => {
	const [toggle, setToggle] = useState(false);
	const {
		canShiftLeft,
		canShiftRight,
		currentChunk,
		shiftLeft,
		shiftRigth,
	} = useSubjectsForProfileWithPagnation(info.subjects, 8);

	const toggleBottomBar = () => {
		setToggle(!toggle);
	};
	return (
		<section
			className={classnames(
				genStyles.profile_bottom__sidebar,
				!toggle && genStyles.show_additional_info
			)}
			onClick={toggleBottomBar}
		>
			{!toggle ? (
				<section className={genStyles.blue_arc}></section>
			) : (
				<section className={genStyles.white_arc}></section>
			)}
			{!toggle ? (
				<ScrollUpIcon className={genStyles.scroll_up} />
			) : (
				<ScrollDownIcon className={genStyles.scroll_down} />
			)}

			{!toggle ? (
				<p className={genStyles.show_info_text}>
					<FormattedMessage id="additionalInformation" />
				</p>
			) : (
				""
			)}
			<section
				className={genStyles.student_right__sidebar_content_wrapper}
			>
				{currentChunk && (
					<>
						<ProfileSubjectPagnations
							canShiftLeft={canShiftLeft}
							canShiftRight={canShiftRight}
							shiftLeft={shiftLeft}
							shiftRight={shiftRigth}
						/>
						<section className={genStyles.right__sidebar_box}>
							{currentChunk.map((e, i) => (
								<section
									key={i}
									className={
										genStyles.student_subject_box__item
									}
									style={{ width: "176px", height: "60px" }}
								>
									<img src={e.photo || ""} alt="icon" />
									<p>{e.name}</p>
								</section>
							))}
						</section>
					</>
				)}

				<section className={genStyles.divider}></section>
				<section className={genStyles.right__sidebar_box}>
					<section
						className={classnames(
							genStyles.sertificate_box__item,
							genStyles.box_height
						)}
					>
						<p className={genStyles.secondary_text}>
							სულ შესრულებული დავალებები
						</p>
						<span>{info.submitedAssignments || 0}</span>
						<p className={genStyles.blue_text}>დავალება</p>
					</section>
					<section
						className={classnames(
							genStyles.sertificate_box__item,
							genStyles.box_height
						)}
						onClick={onCertificateClick}
					>
						<img
							src={SertificateIcon}
							alt=""
							className={genStyles.sertification_img}
						/>
						<p className={genStyles.sertification}>
							ჩემი სერთიფიკატი
						</p>
					</section>
				</section>
			</section>
		</section>
	);
};
