import { useClassroomsUser } from "@app/hooks/users";
import React from "react";
// import { EducationalFairPopupDistributor } from "./edu-fair/educational-fair";

export const StudentGlobalPopups = React.memo<{}>(
	function StudentGlobalPopups({}) {
		const user = useClassroomsUser()!;
		return null;
	}
);
