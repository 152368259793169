import {
	RFolderSchema,
	IRFolder,
	IFolderSingleItem,
	ItemType,
} from "@app/api/folders/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IRFolder;
export type IStateFolders = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(RFolderSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("folder");
const storageSettings = getDefaultStorageSettings("folders");
const metaInformationName = "foldersMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().folders) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", RFolderSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Folder extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static addItemInParentSync(
		parentId: ObjectId | ObjectId[],
		item: IFolderSingleItem
	) {
		const folders = this.findManyByIdsSync(
			Array.isArray(parentId) ? parentId : [parentId]
		);
		for (const folder of folders) {
			folder.items = [...(folder.items || []), item];
			folder.saveSync();
		}
	}

	static updateItemNameInParentSync(
		parentId: ObjectId,
		itemId: ObjectId,
		name: string
	) {
		const folder = this.findByIdSync(parentId);
		if (!folder || !folder.items) return;
		folder.items = folder.items.map(item => {
			if (item.id !== itemId) return item;
			return { ...item, name };
		});
		folder.saveSync();
	}

	static findClonnablesSync(courseId: ObjectId, ids: ObjectId[]): any[] {
		return [];
	}

	static updateItemInParentSync(
		parentId: ObjectId,
		item: Partial<IFolderSingleItem> &
			Pick<IFolderSingleItem, "id" | "type">,
		upsert?: boolean
	) {
		const folder = this.findByIdSync(parentId);
		if (!folder || !folder.items) return;
		let hasFound = false;
		folder.items = folder.items.map(e => {
			if (e.id !== item.id || e.type !== item.type) return e;
			hasFound = true;
			return { ...e, ...item };
		});
		if (upsert && !hasFound) {
			folder.items = [...folder.items, item as IFolderSingleItem];
		}
		folder.saveSync();
	}

	static deleteItemInParentSync(
		parentId: ObjectId | ObjectId[],
		itemId: ObjectId
	) {
		const folders = this.findManyByIdsSync(
			Array.isArray(parentId) ? parentId : [parentId]
		);
		for (const folder of folders) {
			if (!folder || !folder.items) return;
			folder.items = folder.items.filter(item => {
				return item.id !== itemId;
			});
			folder.saveSync();
		}
	}

	static findManyByItemsSync(itemId: ObjectId, itemType: ItemType): DOC[] {
		const folders = this.getAllSync();
		return folders.filter(folder => {
			if (!folder.items) return false;
			return !!folder.items.find(
				item => item.id === itemId && item.type === itemType
			);
		});
	}

	static getFolderIdsWithItemSync(
		item: { id: ObjectId; type: ItemType },
		optionalFolderIds?: ObjectId[]
	): ObjectId[] {
		const folders = !optionalFolderIds
			? this.getAllSync()
			: this.findManyByIdsSync(optionalFolderIds);
		return folders
			.filter(folder => {
				if (!folder.items) return false;
				return !!folder.items.find(
					e => e.id === item.id && e.type === item.type
				);
			})
			.map(e => e._id);
	}

	static loadAllSubFoldersSync(
		folderId: ObjectId,
		depth?: number
	): { folders: Folder[]; fullyLoaded: boolean } {
		const folders: Folder[] = [];
		const fullyLoaded = this.loadAllSubFoldersImpureSync(
			folders,
			folderId,
			depth
		);
		return { folders, fullyLoaded };
	}

	private static loadAllSubFoldersImpureSync(
		folders: Folder[],
		folderId: ObjectId,
		depth?: number
	): boolean {
		const folder = Folder.findByIdSync(folderId);
		if (!folder) return false;
		folders.push(folder);

		if (depth !== undefined) {
			depth--;
			if (depth < 0) return true;
		}

		let hasFoundAll = true;
		const items = folder.items || [];
		for (let i = 0; i < items.length; ++i) {
			const item = items[i];
			if (item.type !== ItemType.folder) continue;
			if (
				(depth === undefined || depth >= 0) &&
				!this.loadAllSubFoldersImpureSync(folders, item.id, depth)
			) {
				hasFoundAll = false;
			}
		}
		return hasFoundAll;
	}

	static getItemsByFolderIdSync(id: ObjectId) {
		const folder = this.findByIdSync(id);
		if (!folder) return undefined;
		return folder.items;
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

export type IFolderInstance = Folder;
export type IFolderModel = typeof Folder;

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Folder.meta);

export const foldersReducer = getDefaultReducer(storageSettings, () => Folder);
