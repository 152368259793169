import React, { useCallback } from "react";
import { useQuestionAppeal } from "@app/hooks/appeals";
import styled from "@emotion/styled";
import { ReactComponent as QuestionMarkIcon } from "@app/components/styles/img/icons/question-mark.svg";
import { useBoolean, useUnsafeDynamicRef } from "@app/hooks/general";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { ObjectId } from "@app/utils/generics";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useInput } from "@app/hooks/front";
import { inject } from "@app/modules";
import { ItemType } from "@app/api/folders/helper-schemas";
import { openConfirmationPopup } from "../widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import styles from "./styles/appeal.module.scss";
import { FormattedMessage } from "react-intl";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useLocale } from "@app/hooks/intl";

export const AppealButton: React.FC<{
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
	style?: React.CSSProperties;
	className?: string;
}> = ({ itemId, courseId, itemType, className, style }) => {
	const {
		value: isObjectionPopupOpen,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean();

	const appeal = useQuestionAppeal(itemId, courseId);

	if (!appeal.isSuccessfullyLoaded) return null;

	const popup = !isObjectionPopupOpen ? null : (
		<ObjectionPopup
			onClose={closePopup}
			courseId={courseId}
			itemId={itemId}
			itemType={itemType}
		/>
	);

	return (
		<React.Fragment>
			{popup}
			{!appeal.doc && (
				<div style={style} className={className}>
					<BoxedButton onClick={openPopup}>
						<FormattedMessage id="appeal.CTA" />
						<QuestionMarkIcon style={{ width: 18 }} />
					</BoxedButton>
				</div>
			)}
		</React.Fragment>
	);
};

const ObjectionPopup: React.FC<{
	onClose: () => void;
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}> = ({ onClose, courseId, itemId, itemType }) => {
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();
	const { value: hasBeenSent, setTrue: markSent } = useBoolean();

	const { value, onChange } = useInput<HTMLTextAreaElement>();
	const valueRef = useUnsafeDynamicRef(value);
	const isAppealTitleInvissible =
		useLocale() === "nl" && getCurrentWebsite() === WebsiteOrigin.tvSchool;

	const handleSubmit = useCallback(async () => {
		const value = valueRef.current;
		startLoading();
		let isCancelled = false;
		try {
			const controller = inject("AppealsController");
			await controller.add({
				courseId,
				itemId: itemId,
				itemType,
				message: value,
				objected: true,
			});
			if (isCancelled) return;
			markSent();
			stopLoading();
		} catch (e) {
			if (isCancelled) return;
			stopLoading();
			openConfirmationPopup({
				text: getFormattedMessage("errorAlert"),
			});
		}
		return () => {
			isCancelled = true;
		};
	}, [
		courseId,
		itemId,
		startLoading,
		stopLoading,
		valueRef,
		markSent,
		itemType,
	]);

	/* useEffect(() => {
		if (!hasBeenSent) return;
		const planned = setTimeout(() => {
			onClose();
		}, 4000);
		return () => {
			clearTimeout(planned);
		};
	}, [hasBeenSent, onClose]); */

	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose
				onClose={onClose}
				className={styles.popupContent}
			>
				{!hasBeenSent && (
					<React.Fragment>
						{!isAppealTitleInvissible && (
							<h2 className={styles.title}>
								<FormattedMessage id="appeal.title" />
							</h2>
						)}

						<span
							className={
								isAppealTitleInvissible
									? styles.biggerSecondaryTitle
									: styles.secondaryTitle
							}
						>
							<FormattedMessage id="appeal.writeSpeficicError" />
						</span>
						<textarea
							className={styles.textarea}
							value={value}
							onChange={onChange}
						/>
						<div style={{ textAlign: "center" }}>
							<button
								css={TvSchoolButton as any}
								style={{ opacity: isLoading ? 0.4 : 1 }}
								onClick={handleSubmit}
							>
								<FormattedMessage id="formSubmitCTA" />
								{isLoading && (
									<SvgloadingWhite
										style={{ width: 20, height: 20 }}
									/>
								)}
							</button>
						</div>
					</React.Fragment>
				)}
				{hasBeenSent && (
					<span className={styles.secondaryTitle}>
						{getFormattedMessage("appeal.thankYou")}
					</span>
				)}
			</PopupContentWithClose>
		</Popup>
	);
};

const BoxedButton = styled.div`
	font-family: "Roboto Geo MT Bold";
	font-size: 12px;
	border: 1px solid #5273e6;
	color: #5273e6;
	border-radius: 8px;
	background: white;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		color: white;
		background: #5273e6;
	}
`;

const TvSchoolButton = css`
	font-family: "Roboto Geo MT Bold";
	color: white;
	background: #5273e6;
	border: none;
	padding: 8px 35px;
	display: inline-flex;
	align-items: center;
	font-size: 19px;
	border-radius: 13px;
	cursor: pointer;
	outline: none;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	&:hover {
		box-shadow: -3px 5px 10px 0 rgba(82, 115, 230, 0.5);
	}

	&:focus {
		outline: none;
	}
`;
