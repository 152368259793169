import React from "react";
import termStyles from "./styles/terms.module.css";

export const EngConfidentiality = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					This document defines the Privacy Statement of the internet
					platform Lupiai.com{" "}
				</h1>
				<p className={termStyles.primary}>
					For the purposes of this Privacy Statement, the following
					terms have the following meanings:
				</p>
				<p>
					User: any legal/natural person (registered or not) who uses
					the Website or in any way uses lupiai.com, such as viewing
					and placing Materials.
					<br />
					Website: www.lupiai.com and all of its components.
					<br />
					(Learning) Materials: All materials that the User places on
					the Website, or assignments that the User creates himself.
				</p>
				<p>
					‘We’, ‘Our’, ‘us’: Team of lupiai.com <br />
					The rules in this Privacy Statement are a legal agreement
					between the company that owns the Website on the one hand -
					"Lupi Ai LTD" (hereinafter - lupiai.com) and on the other
					hand the Users of the Website. <br />
				</p>
				<p>
					By using the Website, the User confirms that he/she is at
					least 18 years old or, if underage, has permission from a
					parent or a legal guardian. The User must indicate that
					he/she has read the Privacy Statement, agrees and takes full
					responsibility for the rules in this Privacy Statement.
				</p>
				<p>
					Our team is committed to protecting your privacy and strives
					to respect your personal data in accordance with the EU
					General Data Protection Regulation (GDPR). In this Privacy
					Statement we explain exactly when and why we collect your
					personal information and how we use it.
				</p>

				<p className={termStyles.secondary}>Who are we?</p>
				<p className={termStyles.marginTB}>
					Lupiai.com is the project of Lupi AI LTD, which aims to
					create the fun online learning environment and facilitate
					the creation of homework assignments. Our company
					headquarter is located at Tsotne Dadiani Street N7a,
					Tbilisi, Georgia. <br /> You can contact us by e-mail:
					info@lupiai.com
				</p>
				<p className={termStyles.secondary}>Personal data</p>
				<p className={termStyles.marginTB}>
					We process your personal information when you register on
					the Website, use it otherwise, or when you contact us by
					e-mail. During the registration we process the following
					information: <br />
					Your first and last name <br />
					Your e-mail address <br />
					We process the information using so-called logs. When the
					User visits the Website, the following information is
					displayed on the server: the date and method of accessing
					the Website, Internet Protocol address, referrer and the
					visited source address.
				</p>

				<p className={termStyles.secondary}>Google Analytics</p>
				<p>
					Lupiai.com uses Google Analytics to collect web statistics
					about the use and visit of the website. For example, we can
					analyze website statistics, number of page views, where the
					Users come from (Country / City), the average time the User
					spends on the Website, etc. The purpose of this service is
					to statistically process the information and to gain better
					insight into our website. The technical identification of
					the User is impossible.
				</p>

				<p className={termStyles.secondary}>Cookies</p>
				<p>
					We use cookies. Cookies are small text files that carry some
					information. When a user visits a website, these cookies can
					be placed on his/her computer, tablet or smartphone. The
					important function of cookies is to distinguish one user
					from another. Information about your website visit is stored
					in these cookies. Cookies allow us to recognize the User on
					a subsequent visit. This is useful if the User does not want
					to log in every time he/she visits the Website. When the
					User is visiting our Website, we see the following: the
					referring URL, the time of the User's visit, keywords used
					by him/her, operating system, device, browser IP address and
					if specified, name and e-mail address of the User. This
					information helps us improve the functioning of the website.{" "}
					<br />
					We use cookies to allow the User to navigate more
					effectively between different pages on our Website, to
					improve the overall User experience and provide the
					information in the desired format.
				</p>

				<p className={termStyles.secondary}>
					Why do we collect and use your personal data?
				</p>
				<p>
					We process Users' personal data to provide a service that is
					requested from us. To make the use of our Website easier,
					for marketing and statistical purposes and to contact our
					Users.
				</p>
				<p className={termStyles.secondary}>
					How long do we keep your personal data?{" "}
				</p>
				<p>
					Personal data is kept for as long as necessary and no longer
					than necessary for the purpose for which the personal data
					was collected. <br />
					If storing your personal data is not necessary anymore to
					achieve those goals, we will delete or change it so that it
					does not impact your privacy.
				</p>
				<p className={termStyles.secondary}>Security </p>
				<p>
					We have implemented all reasonable technical security
					measures, so that your personal data, which is placed on our
					Website is protected against unauthorized access, use, loss
					or destruction. <br />
					When the User registers on our Website, he/she must select a
					strong password and not pass it on to others.
				</p>
				<p className={termStyles.secondary}>
					Do we pass on your personal data to others?{" "}
				</p>
				<p>
					We can pass on your personal data to our partners for the
					purposes indicated in this Privacy Statement. We may also
					transfer your personal data to the authorities of the state
					based on an applicable law or a court order.
				</p>
				<p className={termStyles.secondary}>
					Your rights regarding your personal data{" "}
				</p>
				<p>
					You have the right to request a copy of the personal data
					that lupiai.com has about you.
					<br />
					You have the right to ask lupiai.com to correct your
					personal data if it is incorrect or out of date.
					<br /> You can ask lupiai.com to delete your personal data.
					<br />
				</p>
			</div>
		</>
	);
};
