import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { useClassroom } from "@app/hooks/classrooms";
import { useGroupById } from "@app/hooks/groups";
import { useIsTestUser } from "@app/hooks/users";
import { Classroom } from "@app/models/classroom";
import { Group } from "@app/models/group";
import { isNonNullable } from "@app/utils/common";
import { ISidebarItem, SidebarType, SidebarWhazz } from "./interfaces";

interface IArgs {
	whazz: SidebarWhazz;
	classroom: Classroom | null;
	group: Group | null;
	isTestUser: boolean;
	subWebsite: SubWebsiteOrigin[keyof SubWebsiteOrigin];
}

export interface ISidebarExtendedItem extends ISidebarItem {
	isAvaliableForOnlyTestUsers?: boolean;
	notAvailableSchoolIds?: number[];
	subWesbites?:
		| SubWebsiteOrigin[keyof SubWebsiteOrigin]
		| SubWebsiteOrigin[keyof SubWebsiteOrigin][];
}

export const wrapInSidebarItemsFilter = (
	fn: (args: IArgs) => (ISidebarExtendedItem | null | undefined)[] | null
) => {
	return function(whazz: SidebarWhazz): ISidebarItem[] | null {
		const isTestUser = useIsTestUser();
		const subWebsite = useSubWebsite();
		const classroom = useClassroom(
			whazz.type === SidebarType.classroom ? whazz.classroomId : null
		);
		const group = useGroupById(
			whazz.type === SidebarType.group ? whazz.groupId : null
		);

		const args: IArgs = {
			whazz,
			classroom: classroom.doc ?? null,
			group: group.doc ?? null,
			isTestUser,
			subWebsite,
		};

		const items = fn(args)?.filter(isNonNullable);

		if (!items) return null;

		if (whazz.type === SidebarType.classroom) {
			if (!classroom.doc) return [];
			return items.filter(getClassroomFilterer(args));
		}

		return items;
	};
};

const getClassroomFilterer = ({ classroom, isTestUser, subWebsite }: IArgs) => (
	item: ISidebarExtendedItem
): boolean => {
	if (!classroom || !classroom.isPremium()) return false;
	if (item.isAvaliableForOnlyTestUsers && !isTestUser) {
		return false;
	}
	if (!doSubWebsitesMatch(item, subWebsite)) {
		return false;
	}
	if (
		item.notAvailableSchoolIds &&
		item.notAvailableSchoolIds.includes(classroom.schoolId!)
	) {
		return false;
	}
	return true;
};

const doSubWebsitesMatch = (
	item: ISidebarExtendedItem,
	subWebsite: IArgs["subWebsite"]
) => {
	if (item.subWesbites === undefined) return true;
	if (Array.isArray(item.subWesbites)) {
		return item.subWesbites.includes(subWebsite);
	}
	return item.subWesbites === subWebsite;
};

export const getResourceIdFromWhazz = (whazz: SidebarWhazz) => {
	if (whazz.type === SidebarType.classroom) return whazz.classroomId;
	if (whazz.type === SidebarType.group) return whazz.groupId;
	return null;
};

export const getResourceTypeFromWhazz = (
	whazz: SidebarWhazz
): VideoLessonResourceTypes | null => {
	if (whazz.type === SidebarType.classroom) {
		return VideoLessonResourceTypes.CLASSROOM;
	}
	if (whazz.type === SidebarType.group) return VideoLessonResourceTypes.GROUP;
	return null;
};
