import React, { useMemo, useState, useEffect, ChangeEvent } from "react";
import { IRPublicTest } from "@app/api/public-tests/helper-schemas";
import {
	useComplexAssignmentTargetConcepts,
	useComplexAssignmentTopics,
} from "@app/hooks/complex-assignments";
import {
	getTargetConceptsForComplexAssignment,
	getTopicForComplexAssignment,
} from "./helpers/helper-functions";
import { IRGETPublicAssignmentByCode } from "@app/api/assignments/validators";
import { User } from "@app/user";
import { inject } from "@app/modules";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { IComplexAssignment } from "@app/api/assignments/complex/helper-schemas";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { getChosenConceptsResults } from "@app/components/teachers/assignments-2/complex-assignments/helper-functions";
import { ComplexAssignmentContentMode } from "@app/components/teachers/assignments-2/complex-assignments";
import { AssignmentContentWrapper } from "@app/components/teachers/assignments-2/complex-assignments/assignmentContent";
import styles from "./styles/index.module.css";
import { useSubject } from "@app/hooks/subjects";
import Tooltip from "@material-ui/core/Tooltip";
import { FilesInput } from "@app/components/widgets/files/files-input";
import { FileBox, FancyFileBox } from "@app/components/widgets/files/file-box";

interface TVSchoolPublicComplexAssignmentProps {
	assignmentInfo: IRGETPublicAssignmentByCode;
	fullname: string;
	user: User | null;
}

interface WrittenComplexAssignment {
	text: string;
	files: { fileName: string; fileUrl: string }[];
}

export const TVSchoolPublicComplexAssignment: React.FC<TVSchoolPublicComplexAssignmentProps> = ({
	assignmentInfo,
	fullname,
	user,
}) => {
	const [writtenAssignment, setWrittenAssignmet] = useState<
		WrittenComplexAssignment
	>({ text: "", files: [] });
	const [publicTest, setPublicTest] = useState<IRPublicTest>();
	const [complex, setComplex] = useState<IComplexAssignment | null>(null);
	const concepts = useComplexAssignmentTargetConcepts(
		publicTest?.originalCourseId
	);
	const topics = useComplexAssignmentTopics(publicTest?.originalCourseId);
	const subjectId =
		(assignmentInfo.assignment.subjects &&
			assignmentInfo.assignment.subjects[0]) ||
		null;
	const subject = useSubject(subjectId);
	console.log("complex", complex);

	useEffect(() => {
		if (!publicTest) return;
		inject("ComplexAssignmentController")
			.getAssignmentById({
				_id: publicTest.testId,
			})
			.then(data => setComplex(data))
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, [publicTest]);

	useEffect(() => {
		const error = () =>
			openConfirmationPopup({ text: getFormattedMessage("errorAlert") });
		const id = assignmentInfo.assignment.publicTestId;
		if (!id) {
			error();
			return;
		}

		inject("PublicTestsController")
			.getById({ id })
			.then(e => setPublicTest(e))
			.catch(error);
	}, [assignmentInfo.assignment.publicTestId]);

	const targetConcepts = useMemo(() => {
		if (!concepts || !publicTest) return null;
		return getTargetConceptsForComplexAssignment(
			publicTest.metadata?.complexAssignmentInfo?.targetConceptIds!,
			concepts
		);
	}, [concepts, publicTest]);

	const topic = useMemo(() => {
		if (!topics || !publicTest) return null;
		return getTopicForComplexAssignment(
			publicTest.metadata?.complexAssignmentInfo?.topicId!,
			topics
		);
	}, [publicTest, topics]);

	const handleAttachFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const formData = new FormData();
		if (!event.target.files) return;
		for (let i = 0; i < event.target.files.length; i += 1) {
			formData.append("files", event.target.files[i]);
		}

		inject("PhotosController")
			.uploadGeneric(formData as any)
			.then(data => {
				const files = data.files.map(e => ({
					fileName: e.fileOriginalName
						? e.fileOriginalName
						: e.fileName,
					fileUrl: e.fileFullUrl,
				}));
				setWrittenAssignmet(wa => ({ ...wa, files }));
			});
	};

	const handleDeleteFile = (file: { name: string; path: string }) =>
		setWrittenAssignmet(c => ({
			...c,
			files: c.files.filter(e => e.fileName !== file.name),
		}));

	if (!complex || !concepts) {
		return <FancyLoading />;
	}

	return (
		<div className={styles.flexCenterColumn}>
			<div className={styles.heading}> კოგნიტური დავალება </div>
			{subject.isSuccessfullyLoaded && (
				<ComplexInfo
					subjectName={subject.doc.name}
					topic={topic}
					targetConcepts={targetConcepts}
					photoURL={subject.doc.photo}
				/>
			)}
			<AssignmentContentWrapper
				statement={complex.statement}
				coreTasks={complex.coreTasks}
				practicalSuggestions={complex.practicalSuggestions}
				originalCourseId={complex.originalCourseId}
				chosenTopics={[complex.topicId]}
				topicId={complex.topicId}
				targetConceptIds={complex.targetConceptIds}
				resultIds={getChosenConceptsResults(
					concepts,
					complex.targetConceptIds
				)}
				formatedLinks={[]}
				handleAssignemntContentSubmission={() =>
					console.log("submision")
				}
				editOrPreviewMode={ComplexAssignmentContentMode.view}
				classNames={{
					assignmentMandatoryContentWrapper:
						styles.assignmentMandatoryContentWrapper,
					assignmentPracticalSuggestions:
						styles.assignmentPracticalSuggestions,
				}}
				withoutSelectsAndResults
				showFancyFileBox
			/>

			<textarea
				value={writtenAssignment.text}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
					e.persist();
					setWrittenAssignmet(c => ({ ...c, text: e.target.value }));
				}}
				placeholder="ჩაწერეთ პასუხი"
				className={styles.answerInput}
			/>

			<div className={styles.attachFilescontainer}>
				<FilesInput
					files={writtenAssignment.files.map(e => ({
						path: e.fileUrl,
						name: e.fileName,
					}))}
					onDelete={handleDeleteFile}
					direction="right"
					onAttachFile={handleAttachFile}
				/>
			</div>

			<div className={styles.send}>გაგზავნა</div>
		</div>
	);
};

interface ComplexInfo {
	subjectName: string;
	topic: string | null;
	targetConcepts: string | null;
	photoURL?: string | null;
}

const ComplexInfo: React.FC<ComplexInfo> = React.memo(
	({ subjectName, targetConcepts, topic, photoURL }) =>
		topic && targetConcepts && photoURL ? (
			<div className={styles.subjectBoxContiner}>
				<div className={styles.flexCenterColumn}>
					<div className={styles.subject}>{subjectName}</div>
					<div className={styles.topic}>{topic}</div>
					{targetConcepts.indexOf(",") === -1 ? (
						<div className={styles.target}>{targetConcepts}</div>
					) : (
						<Tooltip title={targetConcepts}>
							<div className={styles.target}>
								{targetConcepts.substr(
									0,
									targetConcepts.indexOf(",")
								) + ".."}
							</div>
						</Tooltip>
					)}
				</div>
				{photoURL && (
					<img className={styles.logo} alt="subject" src={photoURL} />
				)}
			</div>
		) : null
);
