import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import HierarchyInfoService from "../base";
import { HierarchyItemType } from "../interfaces";
import { ITaskTypeModel } from "@app/models/task-type";
import { IMultipleSelectItem, IItemsHierarchy } from "../clonnable";

export default class TaskTypeHierarchyService extends HierarchyInfoService {
	private _TaskType: ITaskTypeModel;

	constructor() {
		const TaskTypeModel = inject("TaskTypeModel");
		const TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
		super(
			TaskTypeHierarchyModel as any,
			TaskTypeModel as any,
			inject("CourseModel"),
			HierarchyItemType.taskType
		);
		this._TaskType = TaskTypeModel;
	}

	public setItemParentSync(
		courseId: ObjectId,
		taskTypeId: ObjectId,
		parentId: ObjectId
	): void {
		return super.setItemParentSync(courseId, taskTypeId, parentId, {});
	}

	public getAllItemsAndHierarchyObj(
		courseId: ObjectId
	): {
		items: IMultipleSelectItem<string>[];
		itemsHierarchy: IItemsHierarchy;
		rootId: string;
	} {
		return super.getAllItemsAndHierarchyObj(courseId, -1);
	}
}
