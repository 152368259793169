import React, { useState, useMemo, useEffect } from "react";
import { FormatedLink } from "./assignmentContent";
import styles from "./styles/modal.module.css";
import { CloseIcon } from "@app/icons";
import { Cancel } from "@app/components/widgets/styles/styles";
import { useWindowSize } from "@app/hooks/front";
import Loading from "@app/components/widgets/raw-loading";

export const CreateLinkModal: React.FC<{
	top: number | undefined;
	left: number | undefined;
	label?: string;
	link?: string;
	closeOnScroll?: boolean;
	onClose: () => void;
	onSave: (link: FormatedLink) => void;
}> = ({ onClose, onSave, label, link, top, left, closeOnScroll }) => {
	const { width, height } = useWindowSize();
	const [li, setLink] = useState<string>("");
	const [la, setLabel] = useState<string>("");
	const modalHeight = 256;
	const modalWidth = 340;
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLabel(label || "");
		setLink(link || "");
	}, [label, link]);

	useEffect(() => {
		if (!closeOnScroll) return;
		const handleCloseOnScroll = () => onClose();
		window.addEventListener("scroll", handleCloseOnScroll);
		return () => window.removeEventListener("scroll", handleCloseOnScroll);
	}, [closeOnScroll, onClose]);

	const handleSave = () => {
		const save = () => {
			onSave({
				label: la,
				link: li,
			});
			onClose();
		};
		if (label || link) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				save();
			}, 750);
		} else {
			save();
		}
	};

	const placements = useMemo((): Record<string, string> | undefined => {
		const res = {};
		if (!top || !left) {
			res["top"] = height / 2 - modalHeight / 2;
			res["left"] = width / 2 - modalWidth / 2;
			return res;
		}

		if (top + modalHeight >= height) {
			res["bottom"] = `${height - top}px`;
			res["top"] = undefined;
		} else {
			res["top"] = `${top}px`;
		}

		if (left + modalWidth >= width) {
			res["right"] = "10px";
		} else {
			res["left"] = left;
		}

		return res;
	}, [height, left, top, width]);

	return (
		<div className={styles.modal_container} style={placements}>
			<div className={styles.modal_conent}>
				<div>
					<CloseIcon
						className={Cancel}
						onClick={onClose}
						width={15}
						style={{ marginRight: "7px", marginTop: "7px" }}
					/>
				</div>
				<div>
					<div className={styles.modal_title}>ბმულის სათაური</div>
					<input
						className={styles.modal_input}
						value={la}
						type="text"
						placeholder="ჩაწერეთ ბმულის სათაური"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setLabel(e.target.value)
						}
					/>
				</div>
				{loading && (
					<div style={{ position: "absolute", zIndex: 1000 }}>
						<Loading />
					</div>
				)}
				<div>
					<div className={styles.modal_title}>ბმული</div>
					<input
						className={styles.modal_input}
						value={li}
						type="text"
						placeholder="ჩააკოპირეთ ბმული"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setLink(e.target.value)
						}
					/>
				</div>

				<button className={styles.modal_button} onClick={handleSave}>
					შენახვა
				</button>
			</div>
		</div>
	);
};
