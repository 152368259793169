import { useSetVideoInstructionHidden } from "@app/components/general-context";
import React from "react";
import BCLandingFooter from "./bc_landingFooter";
import { BcRole } from "./bc_role";

export const BCLanding: React.FC<{}> = props => {
	useSetVideoInstructionHidden(true);

	return (
		<div>
			<BcRole />
			<BCLandingFooter />
		</div>
	);
};
