import Joi from "../../../../utils/joi";
import { IRawQuestionContent, IRQuestionContent } from "../schemas";
import { IUserAnswer } from "../ans-schema";
import { Omit } from "../../../../utils/generics";
import {
	CommonQuestionPartsSchema,
	ContentType,
	ICommonQuestionParts,
	ITextStatement,
	TextStatementSchema,
} from "../common-schemas";
import {
	FillingBlanksContentSchema,
	RFillingBlanksContentSchema,
} from "../filling-blanks/schema";
import {
	GroupingItemsContentSchema,
	RGroupingItemsContentSchema,
} from "../grouping-items/schema";
import {
	MultipleChoiceContentSchema,
	RMultipleChoiceContentSchema,
} from "../multiple-choice/schema";
import {
	RSortItemsContentSchema,
	SortItemsContentSchema,
} from "../sort-items/schema";
import {
	RTextCorrectionContentSchema,
	TextCorrectionContentSchema,
} from "../text-correction/schema";
import {
	RTwoColumnsContentSchema,
	TwoColumnsContentSchema,
} from "../two-columns/schema";
import { markKeysForbidden } from "../../../../schemas/helper";

export const MultipleContentsSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.MultipleContents)
		.required(),
	items: Joi.array()
		.items(
			Joi.object({
				id: Joi.number().required(),
				content: Joi.alternatives(
					MultipleChoiceContentSchema,
					TwoColumnsContentSchema,
					SortItemsContentSchema,
					TextCorrectionContentSchema,
					GroupingItemsContentSchema,
					FillingBlanksContentSchema,
					TextStatementSchema.fork("id", markKeysForbidden),
					Joi.link("#MultipleContentsSchema")
				).required(),
				settings: Joi.object({
					nextContentOnSamePage: Joi.boolean(),
				}),
			})
		)
		.required(),
	restrictViewingPagesBeforehand: Joi.boolean(),
}).id("MultipleContentsSchema");

export interface IMultipleContents extends ICommonQuestionParts {
	type: ContentType.MultipleContents;
	items: {
		id: number;
		content: IRawQuestionContent | Omit<ITextStatement, "id">;
		settings?: {
			nextContentOnSamePage?: boolean;
		};
	}[];
	restrictViewingPagesBeforehand?: boolean;
}

export const RMultipleContentsSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.MultipleContents)
		.required(),
	items: Joi.array()
		.items(
			Joi.object({
				id: Joi.number().required(),
				content: Joi.alternatives(
					RMultipleChoiceContentSchema,
					RTwoColumnsContentSchema,
					RSortItemsContentSchema,
					RTextCorrectionContentSchema,
					RGroupingItemsContentSchema,
					RFillingBlanksContentSchema,
					TextStatementSchema.fork("id", markKeysForbidden),
					Joi.link("#RMultipleContentsSchema")
				).required(),
				settings: Joi.object({
					nextContentOnSamePage: Joi.boolean(),
				}),
			})
		)
		.required(),
	restrictViewingPagesBeforehand: Joi.boolean(),
}).id("RMultipleContentsSchema") as any;

export type IRMultipleContents = Omit<
	ICommonQuestionParts & {
		type: ContentType.MultipleContents;
		items: {
			id: number;
			content: IRQuestionContent | Omit<ITextStatement, "id">;
			settings?: {
				nextContentOnSamePage?: boolean;
			};
		}[];
		restrictViewingPagesBeforehand?: boolean;
	},
	"explanation"
>;

export type IMultipleContentsUserAns = {
	[contentId: number]: IUserAnswer | null | undefined;
} | null;
