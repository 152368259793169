import { IStudentPermissions } from "@app/api/users/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds, RCanAccess } from "./interfaces";
import { UserType } from "@app/api/helper-schemas";

export class StudentPermissions extends UserPermissions {
	constructor(private readonly permissions: IStudentPermissions) {
		super(UserType.student);
	}

	getPacket() {
		return this.permissions.packet;
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	getOwnLabelIds = () => {
		return [];
	};
	getViewableLabelIds = () => {
		return [];
	};

	hasConfirmedChild(): boolean {
		return false;
	}

	getConfirmedChildrenIds(): number[] {
		return [];
	}

	getAvailableClassrooms(): ObjectId[] {
		return this.permissions.availableClassrooms;
	}

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(): boolean {
		return false;
	}

	canStudyInClassroom(classroomId: ObjectId): boolean {
		return (
			!!this.permissions &&
			this.permissions.availableClassrooms.some(id => id === classroomId)
		);
	}

	canAccessGroup(): boolean {
		return false;
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return {
			isKnown: true,
			hasAll: false,
			ids: this.permissions.availableCourses,
		} as const;
	}

	canAccessFolder({
		courseId,
		folderId,
	}: {
		courseId: ObjectId;
		folderId: ObjectId;
	}): RCanAccess {
		const canAccessCourse = this.canAccessCourse(courseId);
		if (!canAccessCourse.isKnown) {
			return { isKnown: false };
		}
		return { isKnown: true, canAccess: true };
	}
}
