/* eslint-disable max-lines-per-function */
import { useRef, useEffect, useState, useMemo, useLayoutEffect } from "react";
import { createMemoHook, depsDeepEquality } from "react-dependency-hooks";

export const useElementResize = <T extends HTMLElement>(
	ref: React.MutableRefObject<T | null>,
	cb: (element: T) => void
) => {
	const cbRef = useRef(cb);
	cbRef.current = cb;

	const lastRef = useRef<{
		element: HTMLElement | null;
		unsubscribeFn?: () => void;
	}>({ element: null });

	useLayoutEffect(() => {
		const element = ref.current;
		if (lastRef.current.element === element) return;
		lastRef.current.element = element;

		if (lastRef.current.unsubscribeFn) {
			lastRef.current.unsubscribeFn();
		}

		if (!element) return;
		cb(element);
		const ResizeObserver = (window as any).ResizeObserver;
		if (!ResizeObserver) return;
		const fn = () => {
			cbRef.current(element);
		};
		const observer = new ResizeObserver(fn);
		observer.observe(element);
		lastRef.current.unsubscribeFn = () => {
			observer.disconnect();
		};
	});

	useLayoutEffect(() => {
		return () => {
			lastRef.current.unsubscribeFn?.();
		};
	}, []);
};

export const useElementSize = (
	ref: React.MutableRefObject<HTMLElement | null>
) => {
	const [state, setState] = useState<{
		width: number | null;
		height: number | null;
	}>({ width: null, height: null });

	useElementResize(ref, element => {
		setState({
			width: element.offsetWidth,
			height: element.offsetHeight,
		});
	});

	return state;
};

export const useElementSizeAdvanced = (
	ref: React.MutableRefObject<HTMLElement | null>
) => {
	const [state, setState] = useState<{
		element: HTMLElement | null;
	}>({ element: null });

	useElementResize(ref, element => {
		setState({
			element,
		});
	});

	return state;
};

type ReactRef<T> = React.RefObject<T> | ((instance: T | null) => void);
export interface ResizeOptions<K extends string = any> {
	ref?: ReactRef<HTMLElement>;
	styles?: Record<any, string | undefined>;
	useWindowSize?: boolean;
	sizes: Record<K, number | null>;
	useSingleClass?: boolean;
}
export interface ResizeFullOptions extends ResizeOptions<string> {
	prefix?: string;
	suffix?: string;
}

interface InnerOptions extends ResizeFullOptions {
	width?: number;
	chosenRawClassNames: (string | undefined)[];
	chosenClassNames: (string | undefined)[];
	suffixedSizes: Record<any, any>;
}
export function useResponsiveSize<K extends string>(
	options: ResizeOptions<K>
): ResponsiveSize<K>;
export function useResponsiveSize(
	options: ResizeFullOptions
): ResponsiveSize<any>;
export function useResponsiveSize<K extends string>({
	ref: defaultRef,
	...options
}: ResizeOptions<K>): ResponsiveSize<K> {
	const memoizedOptions = useDeepMemo(() => ({ ...options }), [options]);
	const [className, setClassName] = useState<string | undefined>(undefined);
	const myRef = useRef<HTMLElement | null>(
		defaultRef && typeof defaultRef === "object"
			? defaultRef.current || null
			: null
	);
	const defaultRefRef = useRef(defaultRef);
	const useWindowSize = options.useWindowSize;
	const lastRef = useRef<{
		element: HTMLElement | null;
		unsubscribeFn?: () => void;
	}>(undefined as any);
	const optionsRef = useRef<InnerOptions>(undefined as any);
	if (optionsRef.current === undefined) {
		optionsRef.current = memoizedOptions as InnerOptions;
		if (useWindowSize) optionsRef.current.width = window.innerWidth;
		else if (myRef.current) {
			optionsRef.current.width = myRef.current.getBoundingClientRect().width;
		}
		setClassName(calculateClassName(optionsRef.current));
	}

	useEffect(() => {
		if (!lastRef.current) lastRef.current = { element: null };

		const lastWidth = optionsRef.current.width;
		const lastChosenRawClassNames = optionsRef.current.chosenRawClassNames;
		const lastChosenClassNames = optionsRef.current.chosenClassNames;
		const lastSuffixedSizesSizes = optionsRef.current.suffixedSizes;
		optionsRef.current = memoizedOptions as InnerOptions;
		optionsRef.current.width = lastWidth;
		optionsRef.current.chosenRawClassNames = lastChosenRawClassNames;
		optionsRef.current.chosenClassNames = lastChosenClassNames;
		optionsRef.current.suffixedSizes = lastSuffixedSizesSizes;

		defaultRefRef.current = defaultRef;
		if (typeof defaultRef === "function") {
			defaultRef(myRef.current || null);
		} else if (defaultRef) {
			(defaultRef as any).current = myRef.current;
		}
		if (useWindowSize) {
			lastRef.current.element = null;
			return;
		}
		if (lastRef.current.element === myRef.current) return;

		if (lastRef.current.unsubscribeFn) {
			lastRef.current.unsubscribeFn();
		}

		const ResizeObserver = (window as any).ResizeObserver;
		const observer = new ResizeObserver(entries => {
			const entry = entries[0];
			if (!entry) return;
			optionsRef.current.width = entry.target.getBoundingClientRect().width;
			setClassName(calculateClassName(optionsRef.current));
		});
		const current = myRef.current;
		if (current) {
			optionsRef.current.width = current.getBoundingClientRect().width;
		}
		setClassName(calculateClassName(optionsRef.current));
		const timer = setTimeout(() => {
			lastRef.current.element = current;
			observer.observe(current);
		}, 1);
		lastRef.current.unsubscribeFn = () => {
			clearTimeout(timer);
			observer.unobserve(current);
		};
	});

	useEffect(() => {
		return () => {
			if (typeof defaultRefRef.current === "function") {
				defaultRefRef.current(null);
			}
			const cb = lastRef.current.unsubscribeFn;
			if (cb) cb();
		};
	}, []);

	useEffect(() => {
		if (useWindowSize) {
			const handler = () => {
				optionsRef.current.width = window.innerWidth;
				setClassName(calculateClassName(optionsRef.current));
			};
			window.addEventListener("resize", handler);
			handler();
			return () => window.removeEventListener("resize", handler);
		}
	}, [useWindowSize]);

	return useMemo(
		() => getObject(className, optionsRef.current, myRef as any),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[className, memoizedOptions]
	);
}

const getObject = (
	className: string | undefined,
	options: InnerOptions,
	ref: ReactRef<HTMLElement>
): ResponsiveSize<any> => {
	const useSingleClass = options.useSingleClass;
	const chosenRawClassNames = options.chosenRawClassNames;
	const chosenClassNames = options.chosenClassNames;
	const primaryClassName = chosenClassNames[chosenClassNames.length - 1];
	const primaryRawClassName =
		chosenRawClassNames[chosenRawClassNames.length - 1];
	const obj: ResponsiveSize<any> = {
		is: cls => primaryRawClassName === cls,
		has: cls => (useSingleClass ? obj.is(cls) : obj.satisfies(cls)),
		satisfies: cls =>
			cls !== undefined && chosenRawClassNames.indexOf(cls) > -1,
		isPrimaryClassName: cls => primaryClassName === cls,
		hasClass: cls =>
			useSingleClass
				? obj.isPrimaryClassName(cls)
				: obj.satisfiesClass(cls),
		satisfiesClass: cls =>
			cls !== undefined && chosenClassNames.indexOf(cls) > -1,
		primaryValue: primaryRawClassName,
		primaryClassName: primaryClassName,
		className,
		__isOption: cls => cls in options.suffixedSizes,
		getRef: () => ref as any,
	};
	return obj;
};

interface RawResponsiveSize<K> {
	is(rawValue: K): boolean;
	has(rawValue: K): boolean;
	satisfies(rawValue: K): boolean;
	__isOption(rawValue: K): boolean;
	primaryValue: K | undefined;
}

export interface ResponsiveSize<K> extends RawResponsiveSize<K> {
	isPrimaryClassName(className: string | undefined): boolean;
	hasClass(className: string | undefined): boolean;
	satisfiesClass(className: string | undefined): boolean;
	primaryClassName: string | undefined;
	className: string | undefined;
	getRef: <T extends HTMLElement = HTMLElement>() => ReactRef<T>;
}

const calculateClassName = (options: InnerOptions): string | undefined => {
	const { sizes, useSingleClass, width } = options;
	const defaultKeys: string[] = [];
	let chosenKeys: (string | undefined)[] = [];
	const suffixedSizes: Record<any, number | null | undefined> = {};
	for (const key in sizes) {
		const value = sizes[key];
		if (value === undefined) continue;
		let newKey = key;
		if (options.prefix) {
			newKey = options.prefix + newKey;
		}
		if (options.suffix) {
			newKey = newKey + options.suffix;
		}
		suffixedSizes[newKey] = value;
		if (value === null) {
			defaultKeys.push(newKey);
			continue;
		}
		if (width !== undefined && width >= value) {
			chosenKeys.push(newKey);
		}
	}
	if (chosenKeys.length === 0) {
		chosenKeys = defaultKeys;
	}
	options.suffixedSizes = suffixedSizes;
	options.chosenRawClassNames = chosenKeys;
	options.chosenClassNames = chosenKeys;
	if (chosenKeys.length === 0) return undefined;

	const transform = getStylesTransformationFn(options);

	const rawChosenKeys = chosenKeys;
	options.chosenClassNames = rawChosenKeys.map(transform);

	if (useSingleClass) {
		chosenKeys = [
			options.chosenClassNames[options.chosenClassNames.length - 1],
		];
	} else {
		chosenKeys = options.chosenClassNames;
	}
	if (chosenKeys.length === 0) return undefined;
	return chosenKeys.join(" ");
};

const getStylesTransformationFn = ({
	styles,
}: Pick<InnerOptions, "styles">): ((
	className: string | undefined
) => string | undefined) => {
	return (className: string | undefined) => {
		if (className === undefined || !styles) return className;
		return styles[className!];
	};
};

export const defaultSizes = {
	zero: 0,
	xsm: 375,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	"2xl": 1450,
};

const useDeepMemo = createMemoHook(depsDeepEquality);
