import { cx } from "emotion";

export interface IAnyObj {
	[key: string]: any;
}

export type EditorText = string | any;
export type IStyles = string;

export function reorder<T>(
	list: T[],
	startIndex: number,
	endIndex: number
): T[] {
	const result = [...list] as T[];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

export function mergeStylesObjects<T1 extends IAnyObj, T2 extends IAnyObj>(
	object1: T1,
	object2: T2
): T1 & T2 {
	const obj1 = { ...object1 };
	const obj2 = { ...object2 };
	for (const p in obj2) {
		if (obj2.hasOwnProperty(p)) {
			try {
				// Property in destination object set; update its value.
				if (obj2[p].constructor === Object) {
					obj1[p] = mergeStylesObjects(obj1[p], obj2[p]) as any;
				} else if (obj1.hasOwnProperty(p)) {
					obj1[p] = cx(obj2[p], obj1[p]) as any;
				} else {
					obj1[p] = obj2[p] as any;
				}
				if (obj1[p] === undefined) delete obj1[p];
			} catch (e) {
				// Property in destination object not set; create it and set its value.
				obj1[p] = obj2[p] as any;
				if (obj1[p] === undefined) delete obj1[p];
			}
		}
	}
	return obj1 as any;
}

export function mergeComponentObjects<T1 extends IAnyObj, T2 extends IAnyObj>(
	object1: T1,
	object2: T2
): T1 & T2 {
	const obj1 = { ...object1 };
	const obj2 = { ...object2 };
	for (const p in obj2) {
		if (obj2.hasOwnProperty(p)) {
			try {
				// Property in destination object set; update its value.
				if (obj2[p].constructor === Object) {
					obj1[p] = mergeComponentObjects(obj1[p], obj2[p]) as any;
				} else if (!obj1.hasOwnProperty(p)) {
					obj1[p] = obj2[p] as any;
				}
				if (obj1[p] === undefined) delete obj1[p];
			} catch (e) {
				// Property in destination object not set; create it and set its value.
				obj1[p] = obj2[p] as any;
				if (obj1[p] === undefined) delete obj1[p];
			}
		}
	}
	return obj1 as any;
}
