import { CreateCusto, buildCusto } from "custo";
import { UnwrapDeepOptionalValue } from "custo/lib/components/providers";
import { Sidebar } from "./container";
import { ISidebarItem, SidebarWhazz } from "./helpers/interfaces";

export const [SidebarTree, SidebarProviders] = buildCusto({
	defaultValue: {
		items: CreateCusto.hookOf.Data(
			(whazz: SidebarWhazz): ISidebarItem[] | null => null
		),
		Container: CreateCusto.Component(Sidebar),
	},
});

export type SidebarValueType = UnwrapDeepOptionalValue<typeof SidebarProviders>;
