import React from "react";
import { Link } from "react-router-dom";
import BrandLogoSvg from "./general_imgs/TVSchoolLogoBlue.svg";
import genStyles from "./styles/general_styles.module.css";

const BrandLogo = () => {
	return (
		<Link to="/" className={genStyles.brandLogo}>
			<img src={BrandLogoSvg} alt="TV school Logo" />
		</Link>
	);
};

export default BrandLogo;
