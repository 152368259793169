import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const TaskTypeSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseId: Joi.objectId().required(),
	name: Joi.string().required(),
	author: Joi.number().required(),
	photo: Joi.string(),
	consistsOfMultipleQuestions: Joi.boolean(),
	approximateNumOfQuestionsPerText: Joi.number().allow(null),
});

export interface ITaskType {
	_id: ObjectId;
	originalCourseId: ObjectId;
	name: string;
	author: number;
	photo?: string;
	consistsOfMultipleQuestions?: boolean;
	approximateNumOfQuestionsPerText?: number | null;
}

export const TaskTypeItemsSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	taskTypeId: Joi.objectId().required(),
	questions: Joi.array().items(Joi.objectId()),
	cards: Joi.array().items(Joi.objectId()),
	texts: Joi.array().items(Joi.objectId()),
});
export interface ITaskTypeItems {
	_id: ObjectId;
	courseId: ObjectId;
	taskTypeId: ObjectId;
	questions?: ObjectId[];
	cards?: ObjectId[];
	texts?: ObjectId[];
}

export const TaskTypeLevelsSchema = Joi.object().pattern(/^[a-f\d]{24}$/i, {
	questionsLevel: Joi.number(),
	cardsLevel: Joi.number(),
	totalLevel: Joi.number().required(),
});

export interface ITaskTypeLevels {
	[x: string]:
		| {
				totalLevel: number;
				questionsLevel?: number;
				cardsLevel?: number;
		  }
		| undefined;
}

export const UserTaskTypeLevelSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	courseId: Joi.objectId().required(),
	levelsByTaskTypeId: TaskTypeLevelsSchema.required(),
});
export interface IUserTaskTypeLevel {
	_id: ObjectId;
	userId: number;
	courseId: ObjectId;
	levelsByTaskTypeId: {
		[x: string]:
			| {
					totalLevel: number;
					questionsLevel?: number;
					cardsLevel?: number;
			  }
			| undefined;
	};
}
