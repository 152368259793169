import React from "react";
import footerStyles from "../../landing/styles/landing-footer.module.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useLocale } from "@app/hooks/intl";
import { FormattedMessage } from "react-intl";

const LandingFooter = () => {
	const isGeo = useLocale() === "ka";

	return (
		<footer
			className={footerStyles.footer}
			style={{ paddingBottom: isGeo ? 0 : 40 }}
		>
			<div className={footerStyles.bc_footer_container}>
				<div className={footerStyles.bc_lineWrapper}></div>
				<div className={footerStyles.terms}>
					<Link to="/confidentiality" target="_blank">
						<FormattedMessage id="footer.confidentiality" />
					</Link>
					<span></span>

					<Link to="/terms" target="_blank">
						<FormattedMessage id="footer.terms" />
					</Link>
				</div>
				<p className={footerStyles.footer_date}>
					&copy; საგანმანათლებლო ტექნოლოგიები საქართველო 2021. ყველა
					უფლება დაცულია
				</p>
			</div>
		</footer>
	);
};

export default LandingFooter;
