/* eslint-disable max-lines-per-function */
import { UserTestStatus } from "@app/api/users/helper-schemas";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { inject } from "@app/modules";
import { arrayToObject, removeKeys } from "@app/utils/common";
import { getFormattedMessage } from "@app/utils/locale";
import { useEffect, useState } from "react";
import { useBoolean, useMiniCallback } from "./general";

export const useStudentStatus = ({ userId: id }: { userId: number | null }) => {
	const [status, setStatus] = useState<UserTestStatus | null>(null);
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean(true);

	useEffect(() => {
		if (!id) {
			setStatus(null);
			return;
		}
		inject("FindUserController")
			.getUserStatus({ userId: id })
			.then(x => {
				stopLoading();
				if (!x) {
					setStatus(UserTestStatus.STUDENT);
				} else {
					setStatus(x.status);
				}
			})
			.catch(e => {
				stopLoading();
				setStatus(UserTestStatus.STUDENT);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const toggleStatus = useMiniCallback(() => {
		if (isLoading || !id) return;
		startLoading();
		let newStatus = status;
		if (status === UserTestStatus.TEST) {
			newStatus = UserTestStatus.STUDENT;
		} else newStatus = UserTestStatus.TEST;
		return inject("FindUserController")
			.saveOrUpdateTestUserStatusById({
				status: newStatus,
				userId: id,
			})
			.then(() => {
				stopLoading();
				setStatus(newStatus);
			})
			.catch(() => {
				stopLoading();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	});

	const toggleStatusWithConfirmation = useMiniCallback(() => {
		openConfirmationPopup({
			text: getConfirmationText(),
			onApprove: toggleStatus,
			approveTitle: "კი",
			rejectTitle: "არა",
		});
	});

	return {
		status,
		isLoading,
		toggleStatusWithConfirmation,
		toggleStatus,
		normalizedStatus: isLoading ? null : status,
	};
};

export const useManyUsersStatus = ({
	userIds,
}: {
	userIds: number[] | null;
}) => {
	const [statusByIds, setStatusByIds] = useState<
		{ [userId in string]?: UserTestStatus }
	>();
	const [loadingByIds, setLoadingByIds] = useState<
		{ [userId in string]?: true }
	>({});

	useEffect(() => {
		if (!userIds) return;
		setStatusByIds(undefined);
		inject("FindUserController")
			.getManyUsersStatus({ userIds })
			.then(statusDocs => {
				const emptyStates = arrayToObject(userIds, id => ({
					key: id,
					value: UserTestStatus.STUDENT,
				}));
				const statusses = arrayToObject(statusDocs, doc => ({
					key: doc.userId,
					value: doc.status,
				}));
				setLoadingByIds({});
				setStatusByIds({ ...emptyStates, ...statusses });
			});
	}, [userIds]);

	const toggleStatus = useMiniCallback((id: number) => {
		if (loadingByIds[id] || !statusByIds) return;
		setLoadingByIds(x => ({ ...x, [id]: true }));
		const status = statusByIds[id];
		let newStatus = status;
		if (status === UserTestStatus.TEST) {
			newStatus = UserTestStatus.STUDENT;
		} else newStatus = UserTestStatus.TEST;
		const stopLoading = () => setLoadingByIds(x => removeKeys(x, id + ""));
		return inject("FindUserController")
			.saveOrUpdateTestUserStatusById({
				status: newStatus,
				userId: id,
			})
			.then(() => {
				stopLoading();
				setStatusByIds(x => ({ ...x, [id]: newStatus }));
			})
			.catch(() => {
				stopLoading();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	});

	const toggleStatusWithConfirmation = useMiniCallback((id: number) => {
		openConfirmationPopup({
			text: getConfirmationText(),
			onApprove: () => toggleStatus(id),
			approveTitle: "კი",
			rejectTitle: "არა",
		});
	});

	return {
		toggleStatusWithConfirmation,
		toggleStatus,
		statusByIds,
		loadingByIds,
	};
};

const getConfirmationText = () => "ნამდვილად გსურთ სტატუსის შეცვლა?";
