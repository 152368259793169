import { IClassroom, ISemester } from "@app/api/classrooms/helper-schemas";
import { IGroup } from "@app/api/groups/helper-schemas";
import { useLocationQuery } from "@app/hooks/front";
import { uniquelize } from "@app/utils/array";
import { useMemo } from "react";

export const useArchivedSemesters = ({
	classrooms,
	groups,
}: {
	classrooms: IClassroom[] | undefined | null;
	groups: IGroup[] | undefined | null;
}) => {
	return useMemo(() => {
		if (!classrooms && !groups) return null;
		const clsSemesters = (classrooms || [])
			.filter(cls => !!cls.semester && cls.isArchived)
			.map(e => e.semester!);
		const groupSemesters = (groups || [])
			.filter(grp => !!grp.semester && grp.isArchived)
			.map(e => e.semester!);
		return uniquelize(
			clsSemesters.concat(groupSemesters),
			s => `${s.startYear}$${s.ordinal}`
		).sort((s1, s2) => {
			if (s1.startYear !== s2.startYear) {
				return s2.startYear - s1.startYear;
			}
			return s2.ordinal - s1.ordinal;
		});
	}, [classrooms, groups]);
};

export const useSemesterFromQuery = () => {
	const query = useLocationQuery(true);
	const startYear = +(query.startYear as string);
	const ordinal = +(query.ordinal as string);
	return useMemo(
		(): ISemester | null => (!startYear ? null : { startYear, ordinal }),
		[ordinal, startYear]
	);
};

export const areSemestersEqual = (
	semester1: ISemester | undefined | null,
	semester2: ISemester | undefined | null
) => {
	if (!semester2 && !semester1) return true;
	if (!semester2 && !!semester1) return false;
	if (!!semester2 && !semester1) return false;
	return (
		semester1!.startYear === semester2!.startYear &&
		semester1!.ordinal === semester2!.ordinal
	);
};
