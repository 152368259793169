import React from "react";
import styles from "./styles/half-moon.module.scss";
import classNames from "classnames";

export const HalfMoon: React.FC<{
	bottomTitle?: string | number | JSX.Element;
	title?: string;
	style?: React.CSSProperties;
	className?: string;
	showBottomTitleInResponsiveAsRegular?: boolean;
}> = ({
	bottomTitle,
	children,
	title,
	style,
	className,
	showBottomTitleInResponsiveAsRegular,
}) => {
	return (
		<div className={styles.header + " " + className} style={style}>
			<div className={styles["half-moon"]} />
			<div className={"main main2 " + styles.rest}>
				{title && <div className={styles.mainTitle}>{title}</div>}
				{children}
			</div>
			{bottomTitle && (
				<div
					className={classNames(
						styles["bottom-title"],
						!showBottomTitleInResponsiveAsRegular &&
							styles["bottom-title-responsive"]
					)}
				>
					{bottomTitle}
				</div>
			)}
		</div>
	);
};
