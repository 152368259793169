import Joi from "@app/utils/joi";
import { ConferenceSchema, IConference } from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateSchoolConferenceSchema = ConferenceSchema.keys(
	insertStripKeys
);
export type IAPOSTCreateSchoolConference = ToInsertKeys<IConference>;

export const RPOSTCreateSchoolConferenceSchema = ConferenceSchema;
export type IRPOSTCreateSchoolConference = IConference;

///

export const APUTUpdateSchoolConferenceSchema = ConferenceSchema.keys(
	updateStripKeys
);
export type IAPUTUpdateSchoolConference = ToUpdateKeys<IConference>;

export const RPUTUpdateSchoolConferenceSchema = ConferenceSchema;
export type IRPUTUpdateSchoolConference = IConference;

///

export const AGETSchoolConferencesSchema = Joi.object({
	schoolId: Joi.number()
		.integer()
		.required(),
	schoolLabelId: Joi.objectId()
		.empty("null")
		.allow(null)
		.default(null),
	includeLabelless: Joi.boolean(),
});
export interface IAGETSchoolConferences {
	schoolId: number;
	schoolLabelId: ObjectId | null;
	includeLabelless?: boolean;
}

export const RGETSchoolConferencesSchema = Joi.array().items(ConferenceSchema);
export type IRGETSchoolConferences = IConference[];

///

export const ADELETESchoolConferenceSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETESchoolConference {
	_id: ObjectId;
}

///

export const AGETSchoolConferenceSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETSchoolConference {
	_id: ObjectId;
}

export const RGETSchoolConferenceSchema = ConferenceSchema;
export type IRGETSchoolConference = IConference;
