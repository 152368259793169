import * as React from "react";
import ContentEditable, { HANDLED, NOT_HANDLED } from "../../../editor";
import GalleryIcon from "@material-ui/icons/Collections";
import memoizeOne from "memoize-one";
import { AnyComponent } from "../../../../utils/generics";
import { css } from "emotion";
import { IAnyObj } from "../../../../utils";
import { getStateAfterMediaInsertion } from "@tests-core/components/editor/modifiers";

export const itemEditClassName = css({
	width: "fit-content",
	padding: 5,
	cursor: "initial",
});

export const itemEditContainerClassName = css({
	width: "fit-content",
	padding: 5,
	border: "1px solid #ccc",
	borderRadius: 5,
	".DraftEditor-root": {
		minWidth: 300,
	},
	position: "relative",
});

const itemEditContainerWithGalleryClassName = css({
	paddingRight: "28px !important",
});

export interface IItemEditClassNames {
	container?: string;
	text?: string;
}

export interface IItemEditNeighbors {
	stat: IEditableStat;
}
export interface IItemEditComponents {
	head?: AnyComponent<IItemEditNeighbors>;
	tail?: AnyComponent<IItemEditNeighbors>;
}

export interface IEditableStat {
	id: number;
	text: any;
	[key: string]: any;
}
export interface IItemEditEditProps {
	stat: IEditableStat;
	onChange: (stat: IEditableStat) => void;
	styles?: IItemEditClassNames;
	components?: IItemEditComponents;
	placeholder?: string;
	galleryComponent?: AnyComponent<IGalleryComponentProps>;
	disableEditing?: boolean;
	onPaste?: (text: string) => NOT_HANDLED | HANDLED;
	extraInfo?: IAnyObj;
}

interface IItemEditEditState {
	isGalleryOpen: boolean;
}

export interface IGalleryComponentProps {
	onUpload: (path: string, mimetype: string | null) => void;
	onClose: () => void;
}

class ItemEdit extends React.PureComponent<
	IItemEditEditProps,
	IItemEditEditState
> {
	state: IItemEditEditState = {
		isGalleryOpen: false,
	};

	getOnChange = memoizeOne(
		(id: number, onChange: IItemEditEditProps["onChange"]) => {
			return (e: any) => onChange({ id, text: e });
		}
	);

	getContainerClassName = memoizeOne(
		(container?: string, showGalleryIcon = false) => {
			if (!showGalleryIcon) return container;
			return (
				(container || "") + " " + itemEditContainerWithGalleryClassName
			);
		}
	);

	getOnGalleryClick = () => {
		if (!this.props.galleryComponent) return;
		this.setState({
			isGalleryOpen: true,
		});
	};

	onGalleryClose = () => {
		this.setState({
			isGalleryOpen: false,
		});
	};

	onUpload = (path: string, mimetype: string | null) => {
		this.props.onChange({
			id: this.props.stat.id,
			text: getStateAfterMediaInsertion({
				source: path,
				editorState: this.props.stat.text,
				mimetype,
			}),
		});
		this.setState({
			isGalleryOpen: false,
		});
	};

	render() {
		const { components, galleryComponent: GalleryComponent } = this.props;
		return (
			<div
				className={this.getContainerClassName(
					this.props.styles?.container,
					!!GalleryComponent && !this.props.disableEditing
				)}
			>
				{components && components.head && (
					<components.head stat={this.props.stat} />
				)}
				<ContentEditable
					data={this.props.stat.text as any}
					onChange={this.getOnChange(
						this.props.stat.id,
						this.props.onChange
					)}
					placeholder={this.props.placeholder}
					className={this.props.styles?.text}
					disableEditing={this.props.disableEditing}
					onPaste={this.props.onPaste}
				/>
				{!!GalleryComponent && !this.props.disableEditing && (
					<GalleryIcon
						className={GalleryButtonCSS}
						onClick={this.getOnGalleryClick}
					/>
				)}
				{components && components.tail && (
					<components.tail stat={this.props.stat} />
				)}
				{this.state.isGalleryOpen &&
					!!GalleryComponent &&
					!this.props.disableEditing && (
						<GalleryComponent
							onClose={this.onGalleryClose}
							onUpload={this.onUpload}
						/>
					)}
			</div>
		);
	}
}

export { ItemEdit };

export const addButtonClassName = css`
	display: inline-flex;
	align-items: center;
	padding: 6px;
	margin: 5px;
	vertical-align: middle;
	background: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

export const GalleryButtonCSS = css`
	position: absolute;
	top: 0;
	right: 0px;
	cursor: pointer;
	color: #88aac8;
`;
