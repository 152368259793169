import { useGoToUrl } from "@app/hooks/front";
import { useClassroomsUser } from "@app/hooks/users";
import { CreateCusto } from "custo";
import React from "react";
import styles from "./styles.module.scss";
import { HeaderTree } from "./tree";

const ProfileContainer = React.memo<{}>(function ProfileContainer({}) {
	return (
		<div className={styles.profileContainer}>
			<HeaderTree.components.profile.Title />
			<HeaderTree.components.profile.Icon />
		</div>
	);
});

const Title = React.memo<{}>(function Title({}) {
	const user = useClassroomsUser();
	const colors = HeaderTree.data.colors.use();
	const UpperTitle = HeaderTree.data.profileUpperTitle;
	if (!user) return null;
	return (
		<div
			className={styles.userStatusAndName}
			style={{ color: colors.secondaryText }}
		>
			<span style={{ fontSize: 12 }}>
				<UpperTitle />
			</span>
			<br />
			<span style={{ fontSize: 15, fontWeight: "bold" }}>
				{user.firstname} {user.lastname}
			</span>
		</div>
	);
});

const Icon = React.memo<{}>(function Icon({}) {
	const onGoToProfile = useGoToUrl(`/profile`);

	const iconSrc = HeaderTree.data.profileImg.use();

	return (
		<div className={styles.profileIcon} onClick={onGoToProfile}>
			<HeaderTree.elements.ProfileImg
				src={iconSrc}
				className={styles.img}
			/>
			<HeaderTree.components.profile.Warning />
		</div>
	);
});

export const Warning = React.memo<{}>(function Warning({}) {
	const showWarningIcon = HeaderTree.data.showWarningIcon.use();
	const showWarningText = HeaderTree.data.showWarningText.use();
	const onGoToProfile = useGoToUrl(`/profile`);

	if (!showWarningIcon) return null;
	return (
		<>
			<div
				// onMouseEnter={hideOptions}
				onClick={onGoToProfile}
				className={styles.alertIconContainer}
			>
				!
			</div>
			{showWarningText && (
				<div className={styles.mobileVerificationWarning}>
					გთხოვთ, დაადასტუროთ მობილურის ნომერი
				</div>
			)}
		</>
	);
});

export const HeaderProfileComponents = {
	Container: ProfileContainer,
	Icon,
	Title,
	Warning,
};

export const HeaderProfileElements = {
	Img: CreateCusto.Component("img", { className: styles.img }),
};
