export enum Language {
	Georgian = "GEO",
}

export const LanguageKeys = [Language.Georgian];

export enum Domain {
	Murtsku = 1,
	ProM = 2,
	Courses = 3,
}

export const DomainKeys = [Domain.Murtsku, Domain.ProM, Domain.Courses];
