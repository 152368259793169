import { techDiffForStudents } from "@app/globals";
import { useClassroomsUser } from "@app/hooks/users";
import { StudentsTechDiffPage } from "@app/tech-difficulties";
import React from "react";
import { TVSchoolStudentMainPage } from "./subjects/students-subjects";

export const StudentLoadingPage: React.FC<{}> = props => {
	const user = useClassroomsUser();
	if (!user) return null;
	if (techDiffForStudents) {
		return <StudentsTechDiffPage />;
	}
	return <TVSchoolStudentMainPage />;
};
