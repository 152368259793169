import Joi from "@app/utils/joi";

export const CognitiveTestStatsFilterSchema = Joi.object({
	fromDate: Joi.date().allow(null),
	toDate: Joi.date().allow(null),
	publicTestIds: Joi.array().items(Joi.number().integer()),
});
export interface ICognitiveTestStatsFilter {
	fromDate?: Date;
	toDate?: Date;
	publicTestIds?: number[];
}

export const AGETCognitiveTestStatsSchema = CognitiveTestStatsFilterSchema;
export type IAGETCognitiveTestStats = ICognitiveTestStatsFilter;

const UserDataSchema = Joi.object({
	id: Joi.number().integer(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	mobile: Joi.string()
		.allow(null)
		.required(),
});
export interface IUserData {
	id?: number;
	firstname: string;
	lastname: string;
	mobile: string | null;
}

export const RGETCognitiveTestAuthorTeacherStatsSchema = Joi.object({
	numTests: Joi.number()
		.integer()
		.required(),
	authorTeachers: Joi.array()
		.items(UserDataSchema)
		.required(),
});
export interface IRGETCognitiveTestAuthorTeacherStats {
	numTests: number;
	authorTeachers: IUserData[];
}

export const RGETCognitiveTestWriterStudentStatsSchema = Joi.object({
	writers: Joi.array()
		.items(UserDataSchema)
		.required(),
	assignedStudents: Joi.array()
		.items(UserDataSchema)
		.required(),
	activeStudentsHavingNotCompleted: Joi.array()
		.items(UserDataSchema)
		.required(),
});
export interface IRGETCognitiveTestWriterStudentStats {
	writers: IUserData[];
	assignedStudents: IUserData[];
	activeStudentsHavingNotCompleted: IUserData[];
}
