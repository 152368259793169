import { IAssignmentSettings } from "@app/api/assignments/helper-schemas";
import { ITime } from "@app/api/classrooms/helper-schemas-2";
import { addMinutes, differenceInMinutes } from "date-fns";

export const getTimerForAssignment = (
	defaultTimer: ITime,
	deadline?: Date
): ITime => {
	if (!deadline) {
		return defaultTimer;
	}

	const curDate = new Date();
	const totalMinutes = defaultTimer.hour * 60 + defaultTimer.minute;
	const timerWinnRunout = addMinutes(curDate, totalMinutes);
	if (deadline >= timerWinnRunout) {
		return defaultTimer;
	} else {
		const avaliableMinutes = differenceInMinutes(deadline, curDate);
		const timerObject = {
			hour: Math.floor(avaliableMinutes / 60),
			minute: avaliableMinutes % 60,
		};
		if (timerObject.hour <= 0 || timerObject.minute <= 0) {
			return { hour: 0, minute: 0 };
		} else {
			return timerObject;
		}
	}
};
