import { IRequest } from "../../requests";
import {
	IAPUTFolderlessItem,
	IRPUTFolderlessItem,
	RPUTFolderlessItemSchema,
} from "./validators";

export class FolderlessItemsController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	saveItem = async (
		args: IAPUTFolderlessItem
	): Promise<IRPUTFolderlessItem> =>
		this.Request.send(
			"PUT",
			"/api/courses/:courseId/folderless-items",
			args,
			null,
			{
				responseSchema: RPUTFolderlessItemSchema,
			}
		);
}
