import React from "react";
import { beautifyText } from "../../utils/text";
import { css } from "emotion";
import { IText } from "../../schemas/texts/helper-schemas";

export const TextContainer = React.memo(
	({ text }: { text: Pick<IText, "text" | "_id"> }) => (
		<div className={textContainerCSS}>
			<div
				dangerouslySetInnerHTML={{ __html: beautifyText(text.text) }}
			/>
		</div>
	)
);

const textContainerCSS = css`
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
	img {
		max-width: 100%;
		width: block;
	}
	audio {
		display: block;
		max-width: 100%;
	}
`;
