import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	ICommonQuestionParts,
	forbiddenProperties,
	forbiddenProperty,
} from "../common-schemas";
import { markKeysForbidden } from "../../../../schemas/helper";

export const TwoColumnsContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number()
		.valid(ContentType.TwoColumns)
		.required(),
	statements: Joi.array()
		.items(Joi.string().allow(null, ""))
		.required(),
	correctAnswer: Joi.number()
		.integer()
		.min(0)
		.max(3)
		.required(),
});
export interface ITwoColumnsContent extends ICommonQuestionParts {
	type: ContentType.TwoColumns;
	statements: (string | null)[];
	correctAnswer: number;
}
export const RTwoColumnsContentSchema = TwoColumnsContentSchema.fork(
	[...forbiddenProperties, "correctAnswer"],
	markKeysForbidden
);
export type IRTwoColumnsContent = Omit<
	ITwoColumnsContent,
	forbiddenProperty | "correctAnswer"
>;

///
