import React, { useMemo } from "react";
import styles from "../../styles/index.module.scss";

export enum HeadmasterGroupBoxInfoType {
	mentor = "mentor",
	students = "students",
	subject = "subject",
}

interface GroupInfoContainerProps {
	info:
		| { type: HeadmasterGroupBoxInfoType.mentor; name: string }
		| { type: HeadmasterGroupBoxInfoType.students; quantity: number }
		| {
				type: HeadmasterGroupBoxInfoType.subject;
				name: string;
				photo: string | null;
		  };
	right?: JSX.Element | boolean | null;
}

export const HeadmasterGroupInfoContainer: React.FC<GroupInfoContainerProps> = ({
	info,
	right,
}) => {
	const details = useMemo(() => {
		if (info.type === HeadmasterGroupBoxInfoType.mentor) {
			return info.name;
		}
		if (info.type === HeadmasterGroupBoxInfoType.subject) {
			return info.name;
		}
		if (info.type === HeadmasterGroupBoxInfoType.students) {
			return `${info.quantity} მოსწავლე`;
		}
		return null;
	}, [info]);

	const imgSrc = useMemo(() => {
		if (info.type === HeadmasterGroupBoxInfoType.mentor) {
			return "/imgs/TeacherAvatar.svg";
		}
		if (info.type === HeadmasterGroupBoxInfoType.subject) {
			return info.photo ?? "";
		}
		return "/imgs/StudentAvatar.svg";
	}, [info]);

	return (
		<div className={styles.groupInfoContainer}>
			<div className={styles.avatarContainer}>
				<img className={styles.avatar} src={imgSrc} alt="avatar" />
			</div>
			<div className={styles.details}>
				{details}
				{right}
			</div>
		</div>
	);
};
