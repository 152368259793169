import { IRequest } from "../requests";
import {
	IAGETOnlinePaymentPurpose,
	IAGETWeeklyTestReservation,
} from "../weekly-tests/validators";

export class OnlinePaymentController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	originate = async (
		args: IAGETWeeklyTestReservation
	): Promise<{ url: string }> =>
		this.Request.send("GET", "/api/online-payments/originate", args);

	getOnlineTransactions = async (args: IAGETOnlinePaymentPurpose) => {
		return this.Request.send(
			"GET",
			"/api/online-payments/online-test-transactions",
			args,
			{
				responseType: "blob",
			}
		);
	};
}
