import Joi from "@app/utils/joi";
import { ItemType } from "../folders/helper-schemas";
import {
	ITopicItems,
	TopicHierarchySchema,
	TopicSchema,
	UserTopicLevelSchema,
	ITopic,
	ITopicHierarchy,
	IUserTopicLevel,
} from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateTopicSchema = TopicSchema.keys(insertStripKeys).keys({
	parentId: Joi.objectId(),
});

export type IAPOSTCreateTopic = ToInsertKeys<ITopic> & {
	parentId?: ObjectId;
};

export const RPOSTCreateTopicSchema = TopicSchema;
export type IRPOSTCreateTopic = ITopic;

///

export const APUTTopicSchema = TopicSchema.keys(updateStripKeys);

export type IAPUTTopic = ToUpdateKeys<ITopic>;

///

export const AGETTopicSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseId: Joi.objectId().required(),
});
export interface IAGETTopic {
	_id: ObjectId;
	originalCourseId: ObjectId;
}

export const RGETTopicSchema = TopicSchema.required();
export type IRGETTopic = ITopic;

///

export const ADELETETopicSchema = AGETTopicSchema;
export type IADELETETopic = IAGETTopic;

///

export const RGETAllTopicsSchema = Joi.array()
	.items(TopicSchema)
	.required();
export type IRGETAllTopics = ITopic[];

///

export const AGETALLCourseTopicsSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETALLCourseTopics {
	courseId: ObjectId;
}

///

export const APUTEditTopicItemsSchema = Joi.object({
	labelId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	questions: Joi.array().items(Joi.objectId()),
	cards: Joi.array().items(Joi.objectId()),
	texts: Joi.array().items(Joi.objectId()),
});
export interface IAPUTEditTopicItems {
	labelId: ObjectId;
	courseId: ObjectId;
	questions?: ObjectId[];
	cards?: ObjectId[];
	texts?: ObjectId[];
}
export type IRPUTEditTopicItems = ITopicItems;

export const APUTAddItemsToTopicSchema = APUTEditTopicItemsSchema;
export type IAPUTAddItemsToTopic = IAPUTEditTopicItems;

export type IRPUTAddItemsToTopic = ITopicItems;

///

export const APUTRemoveItemsFromTopicSchema = APUTEditTopicItemsSchema;
export type IAPUTRemoveItemsFromTopic = IAPUTEditTopicItems;

export type IRPUTRemoveItemsFromTopic = ITopicItems;

///

export const AGETTopicHierarchySchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETTopicHierarchy {
	courseId: ObjectId;
}

export const RGETTopicHierarchySchema = TopicHierarchySchema;
export type IRGETTopicHierarchy = ITopicHierarchy;

///

export const AGETTopicLevelsSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETTopicLevels {
	courseId: ObjectId;
}

export const RGETTopicLevelsSchema = UserTopicLevelSchema;
export type IRGETTopicLevels = IUserTopicLevel;

///

export const AGETTopicsByQuestionSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(ItemType.question, ItemType.card, ItemType.text)
		.required(),
});
export interface IAGETTopicsByQuestion {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType.question | ItemType.card | ItemType.text;
}

export const RGETTopicsByQuestionSchema = Joi.array()
	.items(Joi.objectId().required())
	.required();
export type IRGETTopicsByQuestion = ObjectId[];
