import Joi from "@app/utils/joi";
import { IAnyObj } from "@app/utils/generics";

export const UserActionSchema = Joi.object({
	userId: Joi.number().required(),
	actions: Joi.object().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IUserAction {
	userId: number;
	actions: IAnyObj;
	createdAt: Date;
	updatedAt: Date;
}
