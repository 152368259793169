import { IMultipleContentsUserAns } from "./multiple-contents/schema";
import { ISortItemsUserAns, SortItemsUserAnsSchema } from "./sort-items/schema";
import {
	FillingBlanksUserAnsSchema,
	IFillingBlanksUserAns,
} from "./filling-blanks/schema";
import {
	IMultipleChoiceUserAns,
	MultipleChoiceUserAnsSchema,
} from "./multiple-choice/schema";
import Joi from "../../../utils/joi";

export const MultipleContentsUserAnsSchema = Joi.object()
	.pattern(/\d+/, Joi.link().ref("#UserAnswerSchema"))
	.allow(null) as any;

export const UserAnswerSchema = Joi.alternatives([
	MultipleChoiceUserAnsSchema,
	SortItemsUserAnsSchema,
	FillingBlanksUserAnsSchema,
	MultipleContentsUserAnsSchema,
]).id("UserAnswerSchema");

export type IUserAnswer =
	| IFillingBlanksUserAns
	| IMultipleChoiceUserAns
	| ISortItemsUserAns
	| IMultipleContentsUserAns;
