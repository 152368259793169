import { WeeklyTestReservation } from "@app/models/weekly-test-reservation";
import { inject } from "@app/modules";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import { WeekType } from "./helper-schemas";
import {
	IAGETWeeklyTestReservation,
	IAGETWeeklyTestReservationByUser,
	IAPOSTWeeklyCertificates,
	IAPOSTWeeklyTestReservation,
	IAPUTWeeklyTestCourse,
	IRGETWeeklyTestReservation,
	IRGETWeeklyTestReservationByUser,
	IRPOSTWeeklyCertificates,
	IRPOSTWeeklyTestReservation,
	RGETWeeklyTestReservationByUserSchema,
	RGETWeeklyTestReservationSchema,
	RPOSTWeeklyCertificatesSchema,
	RPOSTWeeklyTestReservationSchema,
	RPUTWeeklyTestCourseSchema,
} from "./validators";

export class WeeklyTestController {
	constructor(private readonly _Request: IRequest) {}

	private readonly _WeeklyTestReservationModel = inject(
		"WeeklyTestReservationModel"
	);

	private readonly getUser = inject("assertAndGetClassroomsUser");
	private reservationPromises = collmise<
		{ userId: number; weekType: WeekType },
		IRGETWeeklyTestReservation,
		WeeklyTestReservation
	>({
		findCachedData: query =>
			this._WeeklyTestReservationModel.findByStudent(
				query.userId,
				query.weekType
			),
		dataTransformer: data =>
			this._WeeklyTestReservationModel.loadOneSync(data),
	});

	reserve = (
		args: IAPOSTWeeklyTestReservation
	): Promise<WeeklyTestReservation> => {
		return this._Request
			.send("POST", "/api/weekly-tests/reservations", args, null, {
				responseSchema: RPOSTWeeklyTestReservationSchema,
			})
			.then((data: IRPOSTWeeklyTestReservation) => {
				return this._WeeklyTestReservationModel.loadOneSync(data);
			});
	};

	getReservation = async (
		weekType: WeekType,
		loadFresh?: boolean
	): Promise<WeeklyTestReservation> => {
		const user = this.getUser();
		return this.reservationPromises
			.on({ userId: user.id, weekType })
			.fresh(loadFresh)
			.request(() =>
				this._Request.send<
					IRPOSTWeeklyTestReservation,
					IAGETWeeklyTestReservation
				>("GET", "/api/weekly-tests/reservations", { weekType }, null, {
					responseSchema: RGETWeeklyTestReservationSchema,
				})
			);
	};

	getReservationByUserId = async (
		args: IAGETWeeklyTestReservationByUser
	): Promise<WeeklyTestReservation> => {
		return this._Request
			.send(
				"GET",
				"/api/weekly-tests/reservations/users/:id",
				args,
				null,
				{
					responseSchema: RGETWeeklyTestReservationByUserSchema,
				}
			)
			.then((data: IRGETWeeklyTestReservationByUser) => {
				return new this._WeeklyTestReservationModel(data);
			});
	};

	joinCourse(args: IAPUTWeeklyTestCourse): Promise<WeeklyTestReservation> {
		return this._Request
			.send("PUT", "/api/weekly-tests/reservations/courses", args, null, {
				responseSchema: RPUTWeeklyTestCourseSchema,
			})
			.then((data: IRPOSTWeeklyTestReservation) => {
				return this._WeeklyTestReservationModel.loadOneSync(data);
			});
	}

	generateStudentCertificate(
		args: IAPOSTWeeklyCertificates
	): Promise<IRPOSTWeeklyCertificates> {
		return this._Request.send(
			"POST",
			"/api/weekly-tests/student-certificates",
			args,
			null,
			{
				responseSchema: RPOSTWeeklyCertificatesSchema,
			}
		);
	}
}
