import { RHierarchySchema, IRHierarchy } from "@app/api/courses/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF_HIERARCHIES, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IRHierarchy;
export type IStateTaskTypeHierarchies = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(RHierarchySchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("taskTypeHierarchy");
const storageSettings = getDefaultStorageSettings("taskTypeHierarchies");
const metaInformationName = "taskTypeHierarchiesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF_HIERARCHIES,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().taskTypeHierarchies) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", RHierarchySchema),
			filter: isLoadedRecentlyEnough,
		}),
	indices: [{ fields: ["courseId"], unique: true }],
});

// ==============Main Model=================

export class TaskTypeHierarchy extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findOneByCourseSync(courseId: ObjectId) {
		return this.findOneSync({ courseId });
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

export type ITaskTypeHierarchyInstance = TaskTypeHierarchy;
export type ITaskTypeHierarchyModel = typeof TaskTypeHierarchy;

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	TaskTypeHierarchy.meta
);

export const taskTypeHierarchiesReducer = getDefaultReducer(
	storageSettings,
	() => TaskTypeHierarchy
);
