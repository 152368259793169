import { SubjectSchema, ISubject } from "./helper-schemas";
import {
	ToInsertKeys,
	insertStripKeys,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const AGETSubjectsSchema = Joi.object({
	grade: Joi.number()
		.integer()
		.allow(null),
	language: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	country: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	website: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
});

export interface IAGETSubjects {
	grade?: number | null;
	language?: string | null;
	country?: string | null;
	website?: string | null;
}

export const APOSTCreateSubjectSchema = SubjectSchema.keys(insertStripKeys);
export type IAPOSTCreateSubject = ToInsertKeys<ISubject>;

export const RPOSTCreateSubjectSchema = SubjectSchema;
export type IRPOSTCreateSubject = ISubject;

///

export const APUTCreateSubjectSchema = SubjectSchema.keys(updateStripKeys);
export type IAPUTCreateSubject = ToUpdateKeys<ISubject>;

export const RPUTCreateSubjectSchema = SubjectSchema;
export type IRPUTCreateSubject = ISubject;

///

export const AGETManySubjectsByIdsSchema = Joi.array().items(Joi.objectId());
export type IAGETManySubjectsByIds = ObjectId[];

export const RGETManySubjectsByIdsSchema = Joi.array().items(SubjectSchema);
export type IRGETManySubjectsByIds = ISubject[];
