import { useGlobalParamProperty } from "@app/routes/containers/params-observer";
import { useParams } from "react-router-dom";

export const useCurrentChildId = () => {
	const params = useParams<{ childId?: string }>();
	return typeof params.childId === "string" ? +params.childId : null;
};

export const useUnstableCurrentChildId = () => {
	const childId = useGlobalParamProperty("childId");
	return typeof childId === "string" ? +childId : null;
};
