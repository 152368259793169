import { IQuestionContent } from "../../schemas/questions/contnets/schemas";
import { ObjectId } from "../../utils/joi";
import { IGradableItemInfo } from "../questions/contents/grading";
import { IUserAnswer } from "@tests-core/schemas/questions/contnets/ans-schema";
import { ContentType } from "@tests-core/schemas/questions/contnets/common-schemas";
import { IMultipleContentsUserAns } from "@tests-core/schemas/questions/contnets/multiple-contents/schema";

interface IQuestionMinimalContentInfo {
	contnet: IQuestionContent;
	isFullContent: boolean;
}

export interface IQuestionContentInfo extends IQuestionMinimalContentInfo {
	qId: ObjectId;
	gradableItems: IGradableItemInfo[];
}

export const defaultRawAnswerToRichAnswer = (
	rawAnswer: IUserAnswer | undefined,
	contentInfo: IQuestionMinimalContentInfo
) => {
	return rawAnswer;
};
export const defaultRichAnswerToRawAnswer = (
	richAnswer: any,
	contentInfo: IQuestionMinimalContentInfo
): IUserAnswer | undefined => {
	if (richAnswer === undefined || richAnswer === null) {
		return richAnswer;
	}
	if (contentInfo.contnet.type === ContentType.MultipleContents) {
		const totalRawAnser: NonNullable<IMultipleContentsUserAns> = {};
		for (const item of contentInfo.contnet.items) {
			if (item.content.type === ContentType.Text) {
				continue;
			}
			const rawAnser = defaultRichAnswerToRawAnswer(richAnswer[item.id], {
				contnet: item.content,
				isFullContent: contentInfo.isFullContent,
			});
			totalRawAnser[item.id] = rawAnser;
		}
		return totalRawAnser;
	}
	/* if (contentInfo.contnet.type === ContentType.FillingBlanks) {
		// trim user inputs
		const totalRawAnser: NonNullable<IFillingBlanksUserAns> = {};
		for (const item of contentInfo.contnet.items) {
			if (item.type === SIItemType.Text) continue;
			const rAns = richAnswer[item.id];
			if (rAns === undefined || rAns === null) continue;
			totalRawAnser[item.id] = {
				id: item.id,
				text: rAns.text.toString().trim(),
			};
		}
		return totalRawAnser;
	} */
	return richAnswer;
};
