import React from "react";
/** @jsx jsx */ import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import styles from "./styles/containers.module.scss";
import classNames from "classnames";

const RawContainer = styled.div`
	display:${(props: { display?: string }) =>
		props.display ? props.display : "block"};
	width: 100%;
	border-radius 4px;
	padding : 12px;
`;

interface BoxContainerProps {
	topLabel?: string | number | JSX.Element | null;
	bottomLabel?: string | number | JSX.Element | null;
	display?: "flex" | "block" | "inline-block" | " inline-flex";
	ellipsedTextOnChildren?: boolean;
	containerStyle?: React.CSSProperties;
	containerClassName?: string;
	shadow?: boolean;
	filled?: boolean;
	border?: boolean;
}
export const BoxContainer: React.FC<BoxContainerProps> = ({
	topLabel,
	bottomLabel,
	children,
	ellipsedTextOnChildren,
	containerStyle,
	containerClassName,
	display,
	shadow,
	filled,
	border = true,
}) => (
	<div>
		{topLabel !== null && topLabel !== undefined && (
			<Label placement="top">{topLabel}</Label>
		)}
		<div
			className={classNames(
				styles.boxContainer,
				shadow && styles.shadow,
				filled && styles.defaultBackground,
				border && styles.bordered
			)}
		>
			<RawContainer
				style={containerStyle}
				className={containerClassName}
				display={display}
			>
				<Child ellipsed={ellipsedTextOnChildren}>{children}</Child>
			</RawContainer>
		</div>

		{bottomLabel !== null && bottomLabel !== undefined && (
			<Label placement="bottom">{bottomLabel}</Label>
		)}
	</div>
);

export const VerticalLabelContainer: React.FC<{
	children: React.ReactElement | string | number | null | undefined | boolean;
	topLabel?: string | number | JSX.Element | null;
	bottomLabel?: string | number | JSX.Element | null;
	hasError?: boolean;
	ellipsedTextOnChildren?: boolean;
	containerStyle?: React.CSSProperties;
	containerClassName?: string;
}> = ({
	topLabel,
	bottomLabel,
	children,
	hasError,
	ellipsedTextOnChildren,
	containerStyle,
	containerClassName,
}) => {
	return (
		<div
			css={verticalLabelContainerCSS as any}
			style={containerStyle}
			className={containerClassName}
		>
			{topLabel !== null && topLabel !== undefined && (
				<Label placement="top" hasError={hasError}>
					{topLabel}
				</Label>
			)}
			<Child ellipsed={ellipsedTextOnChildren}>{children}</Child>
			{bottomLabel !== null && bottomLabel !== undefined && (
				<Label placement="bottom" hasError={hasError}>
					{bottomLabel}
				</Label>
			)}
		</div>
	);
};

export const HorizontalLabelContainer: React.FC<{
	children: React.ReactElement | string | number | null | undefined | boolean;
	leftLabel?: string | number | JSX.Element | null;
	rightLabel?: string | number | JSX.Element | null;
	hasError?: boolean;
	ellipsedTextOnChildren?: boolean;
	containerStyle?: React.CSSProperties;
	containerClassName?: string;
}> = ({
	leftLabel,
	rightLabel,
	children,
	hasError,
	ellipsedTextOnChildren,
	containerStyle,
	containerClassName,
}) => {
	return (
		<div
			css={horizontalLabelContainerCSS as any}
			style={containerStyle}
			className={containerClassName}
		>
			{leftLabel !== null && leftLabel !== undefined && (
				<Label placement="left" hasError={hasError}>
					{leftLabel}
				</Label>
			)}
			<Child ellipsed={ellipsedTextOnChildren}>{children}</Child>
			{rightLabel !== null && rightLabel !== undefined && (
				<Label placement="right" hasError={hasError}>
					{rightLabel}
				</Label>
			)}
		</div>
	);
};

const verticalLabelContainerCSS = css`
	display: inline-flex;
	flex-direction: column;
	width: 100%;
`;

const horizontalLabelContainerCSS = css`
	display: inline-flex;
	max-width: 100%;
`;

interface LabelProps {
	placement: "top" | "bottom" | "left" | "right";
	hasError?: boolean;
}

const Label = styled.div`
	font-size: 12px;
	width: 100%;
	${(props: LabelProps) =>
		props.placement === "top" || props.placement === "bottom"
			? "margin-left: 5px"
			: "margin-top: 5px"};
	${(props: LabelProps) => (props.hasError ? "color: #ea2424;" : "")}
	${(props: LabelProps) => {
		switch (props.placement) {
			case "top":
				return "margin-bottom: 5px";
			case "bottom":
				return "margin-top: 5px";
			case "left":
				return "margin-right: 5px";
			case "right":
				return "margin-left: 5px";
			default:
				return "";
		}
	}};
`;

interface ChildProps {
	ellipsed?: boolean;
}

const Child = styled.div`
	width: 100%;
	${(props: ChildProps) =>
		props.ellipsed
			? `text-overflow: ellipsis; white-space: nowrap; overflow: hidden;`
			: ""}
`;

interface MarginProps {
	itemsMargin?: number | null;
	verticalMargin?: number;
	topMargin?: number;
	bottomMargin?: number;
}

export const MarginContainer = styled.div({}, (props: MarginProps) => {
	const obj: any = {};
	const itemsMargin =
		props.itemsMargin === undefined ? 10 : props.itemsMargin || 0;
	const topMargin =
		(props.topMargin !== undefined
			? props.topMargin
			: props.verticalMargin || 0) - itemsMargin;
	const bottomMargin =
		(props.bottomMargin !== undefined
			? props.bottomMargin
			: props.verticalMargin || 0) - itemsMargin;
	obj.margin = `${topMargin}px ${-itemsMargin}px ${bottomMargin}px ${-itemsMargin}px`;
	if (props.itemsMargin !== null) {
		obj["&>*"] = {
			margin: itemsMargin,
		};
	}
	return obj;
});
