/* eslint-disable max-lines-per-function */
import * as React from "react";
import { connect } from "react-redux";
import { History, Path } from "history";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { IRootState } from "@app/redux";
import { UserType } from "@app/api/helper-schemas";
import { WebsiteOrigin, getCurrentWebsite } from "@app/globals";
import { removeKeys } from "@app/utils/common";
import { HeaderHider } from "./common";
import { SidebarDistributor } from "@app/components/layouts/sidebar";
import { getActiveUserTypeFromPermissions } from "@app/user/active-user-type";
import { ParamsObserver } from "./params-observer";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	availableUserTypes?: UserType | UserType[];
	availableWebsite?: WebsiteOrigin | WebsiteOrigin[];
	redirectIfNotAuthorizedUserType?: boolean;
	dontRedirectIfNotAuthenticated?: boolean;
	componentProps?: Record<any, any>;
	hideHeader?: boolean; // TODO: remove this property
	allowUnauthenticated?: boolean;
	ComponentForNonAvailableUserTypes?: React.ComponentType<any>;
	renderHook?: () => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const UserRoute = (props: IProps) => {
	return (
		<Route {...removeKeys(props as any, "component", "children")}>
			<RouteRendered {...props} />
		</Route>
	);
};

const RouteRendered = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	userType,
	availableUserTypes,
	availableWebsite,
	redirectIfNotAuthorizedUserType,
	dontRedirectIfNotAuthenticated,
	hideHeader,
	componentProps,
	allowUnauthenticated,
	ComponentForNonAvailableUserTypes,
	renderHook,
	...rest
}: IProps) => {
	const history = useHistory();
	const match = useRouteMatch();
	renderHook?.();
	if (
		availableWebsite !== undefined &&
		!areWebsitesMatched(availableWebsite)
	) {
		return null;
	}

	if (
		!allowUnauthenticated &&
		(!isAuthenticated || userType === null || !user.userData) &&
		!dontRedirectIfNotAuthenticated
	) {
		return (
			<Redirect to={`/login?redirect=${encodeURI(getHref(location))}`} />
		);
	}
	if (availableUserTypes && (allowUnauthenticated ? !!userType : true)) {
		if (
			(Array.isArray(availableUserTypes) &&
				availableUserTypes.indexOf(userType!) === -1) ||
			(!Array.isArray(availableUserTypes) &&
				availableUserTypes !== userType)
		) {
			if (ComponentForNonAvailableUserTypes) {
				Component = ComponentForNonAvailableUserTypes;
			} else if (!redirectIfNotAuthorizedUserType) {
				return null;
			} else return <Redirect to="/" />;
		}
	}
	if (!allowUnauthenticated && userType === null) {
		return null;
	}

	return (
		<div>
			<HeaderHider hideHeader={hideHeader} />
			<div className="user-page">
				<div
					className={
						"user-page-without-header " +
						(userType === UserType.teacher
							? " tvschool-teacher-page"
							: "")
					}
				>
					{userType !== null && (
						<SidebarDistributor userType={userType} />
					)}

					<div>
						<Component
							userType={userType}
							{...rest}
							history={history}
							location={history.location}
							match={match}
							{...match.params}
							userData={user.userData}
							{...componentProps}
						/>
					</div>
					<ParamsObserver params={match.params} />
				</div>
			</div>
		</div>
	);
};

const areWebsitesMatched = (
	availableWebsite: WebsiteOrigin | WebsiteOrigin[]
) => {
	const currentWebsite = getCurrentWebsite();
	if (
		Array.isArray(availableWebsite) &&
		availableWebsite.indexOf(currentWebsite) === -1
	) {
		return false;
	} else if (
		!Array.isArray(availableWebsite) &&
		availableWebsite !== currentWebsite
	) {
		return false;
	}
	return true;
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
	userType: getActiveUserTypeFromPermissions(
		state.user.userData?.permissions
	),
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserRoute
);

const getHref = (location: History["location"]) => {
	return location.pathname + location.search;
};
