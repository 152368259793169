import { IRequest } from "../requests";
import {
	IAChangeGradeCategories,
	IADELETEGroupClassrooms,
	IAGetCompletedAssignmentStats,
	IAGETExportStudentDb,
	IAGETStudentCount,
	IAGetTeachersInfo,
	IAPOSTAssignBranchAdmin,
	IAPOSTCreateSchoolBranch,
	IAPOSTEditBranchName,
	IAPOSTEnrollStudentInClassrooms,
	IAPOSTMissingCourse,
	IAPOSTMissingCourses,
	IAPOSTRemoveClassroomGroups,
	IAPOSTRemoveGradeIssues,
	IAPOSTReplaceBCGroupAdmin,
	IAPOSTStudentWeeklyTestPayment,
	IAPOSTUpdateUserSchool,
	IAPUTClassroomPackets,
	IAPUTClassroomPacketsBySchool,
	IAPUTClassroomPacketsByTeachers,
	IAPUTEnsureStudentsInfo,
	IAPUTImportWeeklyBalance,
	IAPUTTeacherResourceOwnership,
	IPUTArchiveBySchoolId,
	IRGETStudentCount,
	IRGETUsersStats,
	IRPOSTMissingCourse,
	IRPOSTMissingCourses,
} from "./validators";
import { inject } from "@app/modules";

export class AdminController {
	private readonly _CourseInfoModel = inject("CourseInfoModel");

	constructor(
		private readonly Request: IRequest,
		private readonly CoursesRequest: IRequest
	) {}

	createMissingCourse = async (
		args: IAPOSTMissingCourse
	): Promise<IRPOSTMissingCourse> => {
		return this.Request.send("POST", "/api/admin/courses/missing", args, {
			headers: {
				"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
			},
		}).then((data: IRPOSTMissingCourse) => {
			if (!data) return data;
			return this._CourseInfoModel.loadOneSync(data);
		});
	};

	getStats = async (): Promise<IRGETUsersStats> => {
		return this.Request.send("GET", "/api/admin/stats");
	};

	bulkCreateMissingCourses = async (
		args: IAPOSTMissingCourses
	): Promise<IRPOSTMissingCourses> => {
		return this.Request.send(
			"POST",
			"/api/admin/courses/missing/bulk",
			args,
			{
				headers: {
					"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
				},
			}
		).then((data: IRPOSTMissingCourses) => {
			return this._CourseInfoModel.loadManySync(data);
		});
	};

	exportStudentsDb = async (args: IAGETExportStudentDb): Promise<unknown> => {
		return this.Request.send("GET", "/api/admin/students", args);
	};

	getStudentCount = async (
		args: IAGETStudentCount
	): Promise<IRGETStudentCount> => {
		return this.Request.send("GET", "/api/admin/students/count", args);
	};

	public async updateClassroomPackets(
		args: IAPUTClassroomPackets
	): Promise<void> {
		return this.Request.send("PUT", "/api/admin/classrooms/packets", args);
	}

	public async updateClassroomPacketsByTeachers(
		args: IAPUTClassroomPacketsByTeachers
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/admin/classrooms/packets-by-teachers",
			args
		);
	}

	public async updateClassroomPacketsBySchool(
		args: IAPUTClassroomPacketsBySchool
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/admin/classrooms/packets-by-school",
			args
		);
	}

	public async importVideoLectureLinks(args: FormData): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/video-lectures/links",
			args
		);
	}

	public async ensureStudentsInfo(
		args: IAPUTEnsureStudentsInfo
	): Promise<void> {
		return this.Request.send("POST", "/api/dev/ensure-students-info", args);
	}

	public async addWeeklyTestPayment(
		args: IAPOSTStudentWeeklyTestPayment
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/weekly-tests/students/:studentId/payments",
			args
		);
	}

	public importWeeklyBalance(args: IAPUTImportWeeklyBalance): Promise<void> {
		return this.Request.send("PUT", "/api/weekly-balance/import", args);
	}

	public async changeClassroomResourceOwner(
		args: IAPUTTeacherResourceOwnership
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/admin/classrooms/resource-ownership",
			args
		);
	}

	public async resetClassroomGradeBookGradesAuthor(
		args: IAPOSTRemoveGradeIssues
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/classrooms/reset-grade-authors",
			args
		);
	}

	public async deleteEmptyGradesForAllSchools(
		args: IAPOSTRemoveGradeIssues
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/classrooms/delete-empty-grades",
			args
		);
	}

	public async deleteAllDuplicateGrades(
		args: IAPOSTRemoveGradeIssues
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/classrooms/delete-duplicate-grades",
			args
		);
	}

	public async enrollStudentInClassrooms(
		args: IAPOSTEnrollStudentInClassrooms
	) {
		return this.Request.send("POST", "/api/admin/students/enroll", args);
	}

	public async changeOldGradeCategoriesForGroupClassrooms(
		args: IAChangeGradeCategories
	) {
		return this.Request.send(
			"POST",
			"/api/groups/change-grade-categories",
			args
		);
	}

	public async removeGroupsFromClassrooms(args: IAPOSTRemoveClassroomGroups) {
		return this.Request.send(
			"POST",
			"/api/admin/classrooms/remove-groups",
			args
		);
	}

	public async getTeacherInfoGlobal(args: IAGetTeachersInfo) {
		return this.Request.send("GET", "/api/admin/total-teachers", args, {
			responseType: "blob",
		});
	}

	public async getCompletedAssignmentStats(
		args: IAGetCompletedAssignmentStats
	) {
		return this.Request.send("GET", "/api/admin/assignment-stats", args, {
			responseType: "blob",
		});
	}

	public async deleteGroupClassrooms(args: IADELETEGroupClassrooms) {
		return this.Request.send(
			"DELETE",
			"/api/admin/delete-group-classrooms",
			args
		);
	}

	public async archiveClassrooms(args: IPUTArchiveBySchoolId) {
		return this.Request.send(
			"PUT",
			"/api/schools/classrooms/archive",
			args
		);
	}

	public async populateUsers() {
		return this.Request.send(
			"POST",
			"/api/chats/schools/4437/users/populate",
			{
				options: {
					includeArchive: false,
				},
			}
		);
	}

	public async populateGroups() {
		return this.Request.send(
			"POST",
			"/api/chats/schools/4437/groups/populate",
			{
				options: {
					includeArchive: false,
				},
			}
		);
	}

	public async replaceBCAdmin(args: IAPOSTReplaceBCGroupAdmin) {
		return this.Request.send("POST", "/api/admin/replace-bc-admin", args);
	}

	public async createSchoolBranch(args: IAPOSTCreateSchoolBranch) {
		return this.Request.send("POST", "/api/admin/create-branch", args);
	}

	public async editBranchName(args: IAPOSTEditBranchName) {
		return this.Request.send("POST", "/api/admin/rename-branch", args);
	}

	public async assignBranchAdmin(args: IAPOSTAssignBranchAdmin) {
		return this.Request.send(
			"POST",
			"/api/admin/assign-branch-admin",
			args
		);
	}

	public async deleteBranchAdmin(args: IAPOSTAssignBranchAdmin) {
		return this.Request.send(
			"POST",
			"/api/admin/delete-branch-admin",
			args
		);
	}

	public async updateUserSchoolAndCity(args: IAPOSTUpdateUserSchool) {
		return this.Request.send(
			"POST",
			"/api/admin/update-city-and-school",
			args
		);
	}
}
