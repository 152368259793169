import { IRPOSTLogin } from "@app/api/auth/validators";
import { UserType } from "@app/api/helper-schemas";
import { useCitiesAndSchools } from "@app/hooks/cities-and-schools";
import { useBoolean, useErrors } from "@app/hooks/general";
import { inject } from "@app/modules";
import { getJoiErrorItems } from "@app/utils/joi-errors";
import { getFormattedMessage } from "@app/utils/locale";
import LocationIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import SmartPhoneIcon from "@material-ui/icons/Smartphone";
import React, { useCallback, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import { StylesConfig } from "react-select";
import SvgLoading from "../styles/img/loading";
import { AuthSelect, Button, MailInput, MobileInput } from "./input-forms";
import StudentRegisterStyles from "./styles/student-register-page.module.css";
import { getAuthErrors } from "./validations";
import { InputLabel } from "./student-register-page";

const RegisterTeacher = (): Promise<{}> =>
	new Promise(resolve => {
		setTimeout(() => {
			resolve({ userId: Math.floor(Math.random() * 1000000) });
		}, 2000);
	});

export interface ISchoolRegisterPageProps {
	onSuccess: (data: IRPOSTLogin) => void;
	userType: UserType.school;
	componentName?: string;
	buttonName?: string;
	classNames?: {
		button?: string;
		input?: string;
		container?: string;
	};
	isIconVissible?: boolean;
	defaultValue?: string;
	placeholder?: string;
	isUpperTextVissible?: boolean;
	inputClassname?: string;
	registrationButtonClassname?: string;
	isCustomSylesInVissible?: boolean;
	isRegisterIllustrationVissible?: boolean;
}

export const SchoolRegisterPage: React.FC<ISchoolRegisterPageProps> = props => {
	const credentials = useRef({
		school: "",
		city: "",
		mail: "",
		mobile: "",
	});
	const { errors, removeErrorMessage, setErrors } = useErrors<
		typeof credentials.current
	>(null);

	const [selectedCityId, setSelectedCityId] = useState<null | number>(null);

	const [selectedSchoolId, setSelectedSchoolId] = useState<null | number>(
		null
	);

	const { cities, schools } = useCitiesAndSchools(null);

	let schoolsOfSelectedCity: {
		value: number;
		cityId: number;
		label: string;
	}[];
	if (selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		schoolsOfSelectedCity = (schools || []).filter(
			s => s.cityId === selectedCityId
		);
	}

	const onCityChange = useCallback(
		(selection: { value: number; label: string }) => {
			setSelectedSchoolId(null);
			setSelectedCityId(selection.value);
			removeErrorMessage("city");
		},
		[removeErrorMessage]
	);
	const onSchoolChange = useCallback(
		(selection: { value: number; label: string; cityId: number }) => {
			setSelectedSchoolId(selection.value);
			removeErrorMessage("school");
		},
		[removeErrorMessage]
	);

	const onMailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.mail = e.target.value;
			removeErrorMessage("mail");
		},
		[removeErrorMessage]
	);

	const onPhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.mobile = e.target.value;
			removeErrorMessage("mobile");
		},
		[removeErrorMessage]
	);
	const [isLoading, setIsLoading] = useState(false);
	const sendRequest = useCallback(() => {
		setIsLoading(true);
		RegisterTeacher().then(() => {
			setIsLoading(false);
		});
	}, []);

	const {
		value: isRegistrationSuccessVisible,
		setTrue: showText,
		setFalse: hideText,
	} = useBoolean();

	const registerSchool = useCallback(() => {
		const SchoolsController = inject("SchoolsController");
		setIsLoading(true);
		SchoolsController.registerSchool({
			school: selectedSchoolId!,
			city: selectedCityId!,
			mobile: credentials.current.mobile,
			mail: credentials.current.mail,
		})
			.then(() => {
				setIsLoading(false);
				showText();
			})
			.catch(e => {
				setIsLoading(false);
				const joiErrors = getJoiErrorItems(e);
				if (joiErrors) {
					const errorMessages = getAuthErrors<
						typeof credentials.current
					>(joiErrors);
					setErrors(errorMessages);
				}
			});
	}, [selectedCityId, selectedSchoolId, setErrors, showText]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === "Enter") {
				sendRequest();
			}
		},
		[sendRequest]
	);

	const customStyles: StylesConfig = {
		option: (provided, state) => ({
			...provided,
			padding: "7px 10px",
		}),

		control: () =>
			props.isCustomSylesInVissible
				? { backgroundColor: "transparent" }
				: {
						padding: "5px 0px",
						backgroundColor: "transparent",
						borderRadius: 5,
						fontSize: 15,
						margin: "4px 0",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						boxShadow: "rgba(0, 0, 0, 0.1) 0 0 0 1px inset",
				  },
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return { ...provided, opacity, transition };
		},
		indicatorsContainer: (provided, state) => {
			return { display: "none" };
		},
	};

	return (
		<div
			className={
				props.isRegisterIllustrationVissible
					? StudentRegisterStyles.regiterPageInRegistrationPopup
					: StudentRegisterStyles.registerPage
			}
			style={{ backgroundColor: "transparent" }}
			tabIndex={0}
			onKeyDown={onKeyDown}
		>
			{!cities || !schools ? (
				<div>
					<SchoolRegisterPageLoader
						inputClassname={props.inputClassname}
						isCustomSylesInVissible={props.isCustomSylesInVissible}
						isRegisterIllustrationVissible={
							props.isRegisterIllustrationVissible
						}
						isUpperTextVissible={props.isUpperTextVissible}
						registrationButtonClassname={
							props.registrationButtonClassname
						}
					/>
				</div>
			) : !isRegistrationSuccessVisible ? (
				<div className={StudentRegisterStyles.register}>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.city" />
							</div>
						)}
						<AuthSelect
							errorText={errors && errors.city}
							icon={<LocationIcon />}
							isDisabled={isLoading}
							onChange={onCityChange}
							options={cities}
							placeholder={
								props.placeholder ||
								getFormattedMessage("inputs.city")
							}
							styles={customStyles}
							value={
								(cities &&
									cities.find(
										c => c.value === selectedCityId
									)) ||
								null
							}
							containerClassName={
								StudentRegisterStyles.mainContainerWrapperClassname
							}
							className={
								props.inputClassname
									? props.inputClassname
									: StudentRegisterStyles.inputA
							}
						/>
					</div>

					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.school" />
							</div>
						)}
						<AuthSelect
							errorText={errors && errors.school}
							icon={<SchoolIcon />}
							isDisabled={isLoading}
							onChange={onSchoolChange}
							options={schoolsOfSelectedCity}
							styles={customStyles}
							placeholder={
								props.placeholder ||
								getFormattedMessage("inputs.school")
							}
							value={
								schoolsOfSelectedCity.find(
									s => s.value === selectedSchoolId
								) || null
							}
							containerClassName={
								StudentRegisterStyles.mainContainerWrapperClassname
							}
							className={
								props.inputClassname
									? props.inputClassname
									: StudentRegisterStyles.inputA
							}
						/>
					</div>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						<InputLabel
							label={getFormattedMessage("inputs.mail")}
							isLabelHidden={!props.isUpperTextVissible}
						>
							{ref => (
								<MailInput
									onChange={onMailChange}
									errorText={errors && errors.mail}
									isDisabled={isLoading}
									className={
										props.inputClassname
											? props.inputClassname
											: StudentRegisterStyles.input
									}
									placeholder={
										props.placeholder ||
										getFormattedMessage("inputs.mail")
									}
									mainContainerWrapperClassname={
										StudentRegisterStyles.mainContainerWrapperClassname
									}
									inputRef={ref}
								/>
							)}
						</InputLabel>
					</div>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						<InputLabel
							label={getFormattedMessage("inputs.mobile")}
							isLabelHidden={!props.isUpperTextVissible}
						>
							{ref => (
								<MobileInput
									onChange={onPhoneChange}
									errorText={errors && errors.mobile}
									isDisabled={isLoading}
									className={
										props.inputClassname
											? props.inputClassname
											: StudentRegisterStyles.input
									}
									placeholder={
										props.placeholder ||
										getFormattedMessage("inputs.mobile")
									}
									mainContainerWrapperClassname={
										StudentRegisterStyles.mainContainerWrapperClassname
									}
									inputRef={ref}
								/>
							)}
						</InputLabel>
					</div>

					<div
						className={StudentRegisterStyles.inputContainer}
						style={{ padding: "1px 0" }}
					></div>

					<Button
						onClick={registerSchool}
						isLoading={isLoading}
						text={getFormattedMessage("registration")}
						className={
							props.isUpperTextVissible
								? StudentRegisterStyles.registrationButtoInPopup
								: StudentRegisterStyles.actionButton
						}
					/>
				</div>
			) : (
				<div>თქვენი მოთხოვნა მიღებულია, ჩვენ მალე დაგიკავშირდებით</div>
			)}
		</div>
	);
};

export const SchoolRegisterPageLoader: React.FC<{
	isUpperTextVissible?: boolean;
	inputClassname?: string;
	registrationButtonClassname?: string;
	isCustomSylesInVissible?: boolean;
	isRegisterIllustrationVissible?: boolean;
}> = props => {
	return (
		<>
			<div
				className={
					props.isRegisterIllustrationVissible
						? StudentRegisterStyles.regiterPageInRegistrationPopupContentLoader
						: StudentRegisterStyles.registerPage
				}
				style={{ backgroundColor: "transparent" }}
			>
				<div className={StudentRegisterStyles.register}>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.city" />
							</div>
						)}
						<ContentLoader
							style={{ width: 314, height: 49 }}
							width={314}
							height={44}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="314"
								height="44"
							/>
						</ContentLoader>
					</div>

					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.school" />
							</div>
						)}
						<ContentLoader
							style={{ width: 314, height: 49 }}
							width={314}
							height={44}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="314"
								height="44"
							/>
						</ContentLoader>
					</div>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.mail" />
							</div>
						)}
						<ContentLoader
							style={{ width: 314, height: 49 }}
							width={314}
							height={44}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="314"
								height="44"
							/>
						</ContentLoader>
					</div>
					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{props.isUpperTextVissible && (
							<div className={StudentRegisterStyles.upperText}>
								<FormattedMessage id="inputs.mobile" />
							</div>
						)}
						<ContentLoader
							style={{ width: 314, height: 49 }}
							width={314}
							height={44}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="314"
								height="44"
							/>
						</ContentLoader>
					</div>

					<div
						className={StudentRegisterStyles.inputContainer}
						style={{ padding: "1px 0" }}
					></div>
					<div
						className={
							props.isUpperTextVissible
								? StudentRegisterStyles.registrationButtoInPopupContentLoadeder
								: StudentRegisterStyles.actionButtonPopupContentLoadeder
						}
					>
						<ContentLoader
							style={{ width: 175, height: 40 }}
							width={175}
							height={40}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="175"
								height="40"
							/>
						</ContentLoader>
					</div>
				</div>
			</div>
		</>
	);
};

export const TeacherRegisterPage: React.FC<{}> = props => {
	const [selectedCityId, setSelectedCityId] = useState<null | number>(null);
	const [selectedSchoolId, setSelectedSchoolId] = useState<null | number>(
		null
	);
	const [cities, setCities] = useState<{ value: number; label: string }[]>();
	const [schools, setSchools] = useState<
		{
			value: number;
			cityId: number;
			label: string;
		}[]
	>();
	const classes: { value: number; label: string }[] = [];
	for (let i = 2; i < 12; i++) {
		classes.push({ value: i, label: "მე-" + i });
	}

	const onCityChange = useCallback(
		(selection: { value: number; label: string }) => {
			setSelectedSchoolId(null);
			setSelectedCityId(selection.value);
		},
		[]
	);
	const onSchoolChange = useCallback(
		(selection: { value: number; label: string; cityId: number }) => {
			setSelectedSchoolId(selection.value);
		},
		[]
	);

	const customStyles: StylesConfig = {
		option: (provided, state) => ({
			...provided,
			padding: "7px 10px",
		}),
		control: () => ({
			padding: "7px 10px",
			border: "none",
			backgroundColor: "white",
			borderRadius: 5,
			margin: "5px",
			boxShadow: "rgba(0, 0, 0, 0.1) 0 0 0 1px inset",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return { ...provided, opacity, transition };
		},
		indicatorsContainer: (provided, state) => {
			return { display: "none" };
		},
	};
	return (
		<div className={StudentRegisterStyles.registerPage}>
			{!cities || !schools ? (
				<div>
					<SvgLoading style={{ width: 30, height: 30 }} />
				</div>
			) : (
				<>
					<div className={StudentRegisterStyles.inputContainer}>
						<Select
							styles={customStyles}
							options={cities || undefined}
							placeholder={getFormattedMessage("inputs.city")}
							onChange={onCityChange}
							value={
								(cities &&
									cities.find(
										c => c.value === selectedCityId
									)) ||
								null
							}
						/>
						<div className={StudentRegisterStyles.icon}>
							<LocationIcon />
						</div>
					</div>
					<div className={StudentRegisterStyles.inputContainer}>
						<Select
							styles={customStyles}
							options={schools}
							placeholder={getFormattedMessage("inputs.school")}
							onChange={onSchoolChange}
							value={
								schools.find(
									s => s.value === selectedSchoolId
								) || null
							}
						/>
						<div className={StudentRegisterStyles.icon}>
							<SchoolIcon />
						</div>
					</div>
				</>
			)}
		</div>
	);
};
