import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const ParentChildrenSchema = Joi.object({
	_id: Joi.objectId().required(),
	parentId: Joi.number()
		.integer()
		.required(),
	confirmedChildrenIds: Joi.array().items(Joi.number().integer()),
	unConfirmedChildrenIds: Joi.array().items(Joi.number().integer()),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IParentChildren {
	_id: ObjectId;
	parentId: number;
	confirmedChildrenIds?: number[];
	unConfirmedChildrenIds?: number[];
	createdAt: Date;
	updatedAt: Date;
}
