import Joi from "@app/utils/joi";
import { CommentSchema, IComment } from "./helper-schemas";

export const AGETSingleStudentCommentSchema = Joi.object({
	studentId: Joi.number().required(),
});

export interface IAGETSingleStudentComment {
	studentId: number;
}

export const RGETSingleStudentCommentSchema = CommentSchema;
export type IRGETSingleStudentComment = IComment;

///

export const AGETManyStudentCommentsSchema = Joi.object({
	studentIds: Joi.array()
		.items(Joi.number())
		.required(),
});
export interface IAGETManyStudentComments {
	studentIds: number[];
}

export const RGETManyStudentCommentsSchema = Joi.array().items(CommentSchema);
export type IRGETManyStudentComments = IComment[];

///

export const APUTCommentsSchema = Joi.object({
	studentId: Joi.number().required(),
	comment: Joi.string()
		.optional()
		.allow(""),
});

export interface IAPUTComments {
	studentId: number;
	comment?: string;
}
