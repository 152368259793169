import { ISemester } from "@app/api/classrooms/helper-schemas";
import { WeekType } from "@app/api/weekly-tests/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { addPrefix, addMergingArgumentPrefix, withParams } from "link-builders";

export const studentBasePath = "/s";

interface CourseSubjectPair {
	courseId: ObjectId;
	subjectId: ObjectId;
}
interface WeeklyTestArgs extends CourseSubjectPair {
	folderId: ObjectId;
	testId: ObjectId;
	day: number;
}

const weeklyTestCourses = addPrefix("courses")({
	list: "",
	testsList: withParams(
		({ courseId, subjectId }: CourseSubjectPair) =>
			`${courseId}/subjects/${subjectId}`,
		":courseId/subjects/:subjectId"
	),
	test: withParams(
		({ courseId, subjectId, folderId, testId, day }: WeeklyTestArgs) =>
			`${courseId}/subjects/${subjectId}/folders/${folderId}/tests/${testId}?day=${day}`,
		":courseId/subjects/:subjectId/folders/:folderId/tests/:testId"
	),
	report: withParams(
		({ courseId, subjectId }: CourseSubjectPair) =>
			`${courseId}/subjects/${subjectId}/report`,
		":courseId/subjects/:subjectId/report"
	),
});

export const studentClassroomInnerLinks = {
	assignments: addPrefix("assignments")({
		list: "",
		/** see publicLinks.assignment for new url */
		oldView: withParams(
			({ assignmentId }: { assignmentId: ObjectId }) => `${assignmentId}`,
			":assignmentId"
		),
	}),
	gradebook: "gradebook",
	semesterbook: "semesterbook",
};

export const studentLinks = addPrefix(studentBasePath)({
	cognitiveTests: "cognitive-tests",
	weeklies: addPrefix("sh")({
		payment: {
			info: withParams(
				(weekType: WeekType) => `payment/weeks/${weekType}`,
				"payment/weeks/:week"
			),
			success: withParams(
				(weekType: WeekType) => `payment/weeks/${weekType}/success`,
				"payment/weeks/:week/success"
			),
			failure: withParams(
				(weekType: WeekType) => `payment/weeks/${weekType}/failure`,
				"payment/weeks/:week/failure"
			),
		},
		weeksList: "weeks",
		finals: addPrefix("weeks/1")({
			landing: "landing",
			newLanding: "landing2",
			chooseSubjects: "choose-subjects",
			newChooseSubjects: "new-choose-subjects",
			parentInfo: "parent-info",
			reservationFinish: "reservation-finish",
			courses: {
				...weeklyTestCourses,
				detailedReport: withParams(
					({ courseId, subjectId }: CourseSubjectPair) =>
						`courses/${courseId}/subjects/${subjectId}/detailed-report`,
					"courses/:courseId/subjects/:subjectId/detailed-report"
				),
				recommendations: withParams(
					({ courseId, subjectId }: CourseSubjectPair) =>
						`courses/${courseId}/subjects/${subjectId}/recommendations`,
					"courses/:courseId/subjects/:subjectId/recommendations"
				),
			},
		}),
		progress: addPrefix("weeks/2")({
			landing: "landing",
			newLanding: "landing",
			reservation: "choose-subjects",
			reservationFinish: "reservation-finish",
			courses: {
				...weeklyTestCourses,
				progress: withParams(
					({ courseId, subjectId }: CourseSubjectPair) =>
						`courses/${courseId}/subjects/${subjectId}/progress`,
					"courses/:courseId/subjects/:subjectId/progress"
				),
			},
			awards: withParams(
				({ subjectId }: { subjectId: ObjectId }) =>
					`awards/subjects/${subjectId}`,
				"awards/subjects/:subjectId"
			),
		}),
		certificates: "certificates",
	}),
	v2Popup: "v2-popup",
	classrooms: addMergingArgumentPrefix(
		({ classroomId }: { classroomId: ObjectId }) =>
			`classrooms/${classroomId}`,
		`classrooms/:classroomId`
	)(studentClassroomInnerLinks),
	archived: addPrefix("archive")({
		classrooms: withParams(
			(semester: ISemester) =>
				`classrooms?startYear=${semester.startYear}&ordinal=${semester.ordinal}`,
			"classrooms"
		),
	}),
	offsers: "offers",
	timetable: "timetable",
});
