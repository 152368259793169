import { UserType } from "@app/api/helper-schemas";
import { WebsiteOrigin } from "@app/globals";
import UserRoute from "@app/routes/containers/user-route";
import { createRouteList } from "../config";
import { parentLinks } from "./links";
import { CommonRouteProps, defaultRouteMapper } from "../route-props-map-utils";
import { defaultComponentWrapper } from "../wrapper";

const routes = createRouteList<CommonRouteProps>({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.parent,
		availableWebsite: WebsiteOrigin.tvSchool,
	},
	componentWrapper: defaultComponentWrapper,
	routeMapFn: defaultRouteMapper,
});

///

const TVSchoolParentChildMainPage = () =>
	import("../../components/users/subjects/students-subjects").then(
		module => ({
			default: module.TVSchoolParentChildMainPage,
		})
	);

routes.add({
	path: parentLinks.childMainPage.routePath,
	component: TVSchoolParentChildMainPage,
});

///

const StudentPersonalTimetablePageAsParent = () =>
	import("../../components/users/timetables").then(module => ({
		default: module.StudentPersonalTimetablePageAsParent,
	}));

routes.add({
	path: parentLinks.timetable.routePath,
	component: StudentPersonalTimetablePageAsParent,
	headerTheme: "light",
});

///

const ChildAssignments = () =>
	import("../../components/parents/parent-assignments").then(module => ({
		default: module.MyAssignmentsAsParent,
	}));

routes.add({
	path: parentLinks.classrooms.assignments.list.routePath,
	component: ChildAssignments,
	headerTheme: "light",
	showSidebar: true,
});

///

const ChildGradebook = () =>
	import("../../components/parents/parent-gradebook").then(module => ({
		default: module.StudentGradebookAsParent,
	}));

routes.add({
	path: parentLinks.classrooms.gradebook.routePath,
	component: ChildGradebook,
	headerTheme: "light",
	showSidebar: true,
});

///

const ChildSemesterbook = () =>
	import("../../components/parents/parent-semesterbook").then(module => ({
		default: module.SemesterbookAsParent,
	}));

routes.add({
	path: parentLinks.classrooms.semesterbook.routePath,
	component: ChildSemesterbook,
	headerTheme: "light",
	showSidebar: true,
});

///

const NewArchivedClassroomsContainer = () =>
	import("../../components/teachers/main/tvschool/classrooms").then(
		module => ({
			default: module.StudentsClassroomsArchivePage,
		})
	);

routes.add({
	path: parentLinks.archived.classrooms.routePath,
	component: NewArchivedClassroomsContainer,
});

///

export default routes;
