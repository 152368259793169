import React, { useCallback, useState, useEffect } from "react";
import { AuthInput, Button, MobileInput } from "../auth/input-forms";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { useBoolean, useUnsafeDynamicRef } from "@app/hooks/general";
import profileStyles from "./styles/profile.module.css";
import envelopeImg from "./styles/img/envelope.png";
import { useClassroomsUser } from "@app/hooks/users";
import { UserType } from "@app/api/helper-schemas";
import { css } from "emotion";
import { openConfirmationPopup } from "../widgets/confirmation-popup";

export const MobileVerification: React.FC<{
	onSuccess?: () => void;
	laterVerify?: () => void;
	isLaterVerifyButtonVissible?: boolean | undefined;
}> = React.memo(props => {
	const { value: isLoading, setValue: setLoading } = useBoolean();
	const [verificationCode, setVerificationCode] = useState("");
	const user = useClassroomsUser();
	const [token, setToken] = useState("");
	const onVerificationCodeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setVerificationCode(e.target.value);
		},
		[]
	);
	const [isCorrectUsersCode, setIsCorrectUsersCode] = useState(false);
	const { onSuccess } = props;
	const {
		value: isSendCodeTwiceRequested,
		setTrue: setSendCodeTwice,
	} = useBoolean(false);

	const {
		value: isResendCodeButtonVissible,
		setTrue: setResendCodeButtonVissible,
	} = useBoolean(false);

	const sendCode = useCallback(() => {
		triggerEvent({
			category: "Popup",
			action: "send verification code",
			label: "automatically",
		});
		const UsersController = inject("UsersController");
		UsersController.sendVerificationCode({}).then(({ token }) => {
			setToken(token);
		});
	}, []);
	const resendCode = useCallback(() => {
		sendCode();
		setSendCodeTwice();
	}, [sendCode, setSendCodeTwice]);
	const sendCodeRef = useUnsafeDynamicRef(sendCode);

	useEffect(() => {
		setTimeout(() => {
			setResendCodeButtonVissible();
		}, 25000);
		sendCodeRef.current();
	}, [setResendCodeButtonVissible, sendCodeRef]);

	const onSubmit = useCallback(() => {
		const UsersController = inject("UsersController");
		setLoading(true);
		UsersController.verifyMobileCode({
			code: verificationCode,
			token,
		})
			.then(() => {
				triggerEvent(
					{
						category: "Popup",
						action: "Check Verification Code",
						label: "response",
					},
					{
						success: true,
					}
				);
				// setLoading(false); this comment is intented
				setIsCorrectUsersCode(true);
				if (onSuccess) onSuccess();
			})
			.catch(() => {
				triggerEvent(
					{
						category: "Popup",
						action: "Check Verification Code",
						label: "response",
					},
					{
						success: false,
					}
				);
				openConfirmationPopup({
					text: getFormattedMessage("mobileVerificationError"),
				});
				setLoading(false);
				setIsCorrectUsersCode(false);
			});
	}, [onSuccess, setLoading, token, verificationCode]);

	if (!user) return null;

	const hasPacket = user.isStudent() && !!user.getPacket();
	//
	return (
		<div className={profileStyles.mobileVerificationContainer}>
			<div style={{ fontWeight: "bold", fontSize: 20 }}>
				<FormattedMessage id="verification.mainTitle" />
			</div>
			<MessageIllustration />

			<div style={{ marginTop: 10 }}>
				<FormattedMessage
					id={
						hasPacket
							? "verification.withPacket"
							: "verification.withoutPacket"
					}
				/>
			</div>
			<AuthInput
				onChange={onVerificationCodeChange}
				defaultValue={verificationCode}
				errorText={isCorrectUsersCode ? null : ""}
				placeholder={getFormattedMessage("verification.text")}
			/>
			{isResendCodeButtonVissible && !isSendCodeTwiceRequested && (
				<div
					onClick={resendCode}
					className={profileStyles.resendVerificationCode}
				>
					<FormattedMessage id="resendVerificationCode" />{" "}
				</div>
			)}
			{isSendCodeTwiceRequested && (
				<div className={profileStyles.resendVerificationCodeReceived}>
					<FormattedMessage id="resendVerificationCodeReceived" />{" "}
				</div>
			)}
			<div>
				<Button
					onClick={onSubmit}
					isLoading={isLoading}
					text={getFormattedMessage("approve")}
				/>
				{props.isLaterVerifyButtonVissible && (
					<div
						onClick={props.laterVerify}
						className={profileStyles.laterVerify}
					>
						<FormattedMessage id="laterVerify" />
					</div>
				)}
			</div>
		</div>
	);
});

export const MessageIllustration: React.FC<{}> = props => {
	return (
		<>
			<img src={envelopeImg} alt="message" />
		</>
	);
};

export const MobileEnterLogic: React.FC<{
	defaultValue?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onSubmit: () => void;
	isLoading: boolean;
}> = props => {
	return (
		<div className={profileStyles.mobileVerificationContainer}>
			<img src={envelopeImg} alt="message" />
			<div style={{ marginTop: 10 }}>
				პროფ ორიენტაციის ტესტის შედეგის სანახავად, გთხოვთ, შეიყვანოთ
				ტელეფონის ნომერი, რომელიც მიუთითეთ ტესტირების დროს
			</div>
			<div className={mobCSS}>
				<MobileInput
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					errorText={null}
				/>
			</div>
			<Button
				onClick={props.onSubmit}
				isLoading={props.isLoading}
				text={getFormattedMessage("approve")}
			/>
		</div>
	);
};

const mobCSS = css`
	width: 240px;
`;
