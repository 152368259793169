import { IAnyObj, ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { CardSchema, ICard } from "@tests-core/schemas/cards";
import {
	FullQuestionSchema,
	ShortQuestionSchema,
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { TextSchema, IText } from "@tests-core/schemas/texts/helper-schemas";
import { ItemType } from "../folders/helper-schemas";
import {
	IItemStats,
	IPropertiesStats,
	ItemStatsSchema,
	PropertiesStatsSchema,
} from "../folders/user-folder-information/helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
} from "../helper-schemas";
import {
	CorrectedQuestionSchema,
	IQuestionStatsCoefficients,
	QuestionStatsCoefficientsSchema,
} from "../questions/helper-schemas";
import {
	INumQuestionsSettings,
	IRTest,
	IUserTest,
	IUserTestQuestionInfo,
	NumQuestionsSettingsSchema,
	RTestSchema,
	UserTestQuestionInfoSchema,
	UserTestSchema,
} from "./helper-schemas";

export const APOSTCreateTestSchema = RTestSchema.keys(insertStripKeys).keys({
	folderId: Joi.objectId()
		.allow(null)
		.required(),
	courseId: Joi.objectId().required(),
	originalCourseId: Joi.any().strip(),
});
export type IAPOSTCreateTest = ToInsertKeys<
	IRTest,
	never,
	"originalCourseId"
> & {
	folderId: ObjectId | null;
	courseId: ObjectId;
};

export const RPOSTCreateTestSchema = RTestSchema;
export type IRPOSTCreateTest = IRTest;

///

export const APUTTestSchema = RTestSchema.keys(updateStripKeys).keys({
	courseId: Joi.objectId().required(),
	originalCourseId: Joi.any().strip(),
});
export type IAPUTTest = ToUpdateKeys<IRTest, never, "originalCourseId"> & {
	courseId: ObjectId;
};

///

export const AGETTestByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETTestById {
	_id: ObjectId;
}

export const RGETTestByIdSchema = RTestSchema;
export type IRGETTestById = IRTest;

///

export const RGETAllTestsSchema = Joi.array().items(RGETTestByIdSchema);
export type IRGETAllTests = IRGETTestById[];

///

export const ADELETETestSchema = Joi.object({
	_id: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IADELETETest {
	_id: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
}

///

export const APOSTFinishTestSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
	score: Joi.number().required(),
	questions: Joi.array()
		.items(
			UserTestQuestionInfoSchema.keys({
				maxCredit: Joi.number()
					.min(0)
					.required(),
			})
		)
		.required(),
	progress: Joi.number().required(),
	attempt: Joi.number().required(),
});

export interface IAPOSTFinishTest {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	score: number;
	questions: (IUserTestQuestionInfo & {
		maxCredit: number;
	})[];
	progress: number;
	attempt: number;
}

export const RPOSTFinishTestSchema = Joi.object({
	userTestId: Joi.objectId().required(),
});
export interface IRPOSTFinishTest {
	userTestId: ObjectId;
}

///

export const APOSTFinishPracticeTestSchema = Joi.object({
	courseId: Joi.objectId().required(),
	answers: Joi.array()
		.items(
			UserTestQuestionInfoSchema.keys({
				maxCredit: Joi.number().required(),
			})
		)
		.required(),
});
export interface IAPOSTFinishPracticeTest {
	courseId: ObjectId;
	answers: (IUserTestQuestionInfo & {
		maxCredit: number;
	})[];
}

export type IRPOSTFinishPracticeTest = void;

///

export const AGETUserTestSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId()
		.allow(null)
		.required(),
	testId: Joi.objectId().required(),
	attempt: Joi.number()
		.integer()
		.required(),
});

export interface IAGETUserTest {
	courseId: ObjectId;
	folderId: ObjectId | null;
	testId: ObjectId;
	attempt: number;
}

export const RGETUserTestSchema = UserTestSchema.allow(null);

export type IRGETUserTest = IUserTest | null;

export const AGETUserTestForAdminSchema = AGETUserTestSchema.keys({
	userId: Joi.number()
		.integer()
		.required(),
});
export type IAGETUserTestForAdmin = IAGETUserTest & {
	userId: number;
};

export const AGETUsersByTestSchema = Joi.object({
	courseId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
});
export interface IAGETUsersByTest {
	courseId: ObjectId;
	testId: ObjectId;
}

export const RGETUsersByTestSchema = Joi.array()
	.items(
		Joi.object({
			userId: Joi.number().required(),
			attempt: Joi.number().required(),
			startedAt: Joi.date().required(),
			assessmentProgress: Joi.number().required(),
		})
	)
	.required();
export type IRGETUsersByTest = {
	userId: number;
	attempt: number;
	startedAt: Date;
	assessmentProgress: number;
}[];

export const AGETTestContentsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	testId: Joi.objectId().required(),
	getAnswers: Joi.boolean().required(),
	getLevelStats: Joi.boolean(),
});
export interface IAGETTestContents {
	courseId: ObjectId;
	folderId: ObjectId | null;
	testId: ObjectId;
	getAnswers: boolean;
	getLevelStats?: boolean;
}

export const TestContentStatsSchema = Joi.object({
	contents: Joi.array()
		.items(
			Joi.alternatives(
				Joi.object({
					type: Joi.number()
						.valid(ItemType.question)
						.required(),
					id: Joi.objectId().required(),
					topicIds: Joi.array()
						.items(Joi.objectId())
						.required(),
					taskTypeIds: Joi.array()
						.items(Joi.objectId())
						.required(),
					coefficients: QuestionStatsCoefficientsSchema,
					memoryStats: ItemStatsSchema,
				}),
				Joi.object({
					type: Joi.number()
						.valid(ItemType.card)
						.required(),
					id: Joi.objectId().required(),
					topicIds: Joi.array()
						.items(Joi.objectId())
						.required(),
					taskTypeIds: Joi.array()
						.items(Joi.objectId())
						.required(),
					memoryStats: PropertiesStatsSchema,
				})
			)
		)
		.required(),
	topicsInfo: Joi.object({
		hierarchy: Joi.object()
			.pattern(/^[a-f\d]{24}$/i, Joi.objectId())
			.required(),
		levelsById: Joi.object()
			.pattern(/^[a-f\d]{24}$/i, Joi.number())
			.required(),
	}).required(),
});
export interface ITestContentStats {
	contents: (
		| {
				id: ObjectId;
				type: ItemType.question;
				topicIds: ObjectId[];
				coefficients?: IQuestionStatsCoefficients;
				taskTypeIds: ObjectId[];
				memoryStats?: IItemStats;
		  }
		| {
				id: ObjectId;
				type: ItemType.card;
				topicIds: ObjectId[];
				taskTypeIds: ObjectId[];
				memoryStats?: IPropertiesStats;
		  }
	)[];
	topicsInfo: {
		hierarchy: {
			[x: string]: ObjectId | undefined;
		};
		levelsById: {
			[x: string]: number | undefined;
		};
	};
}

export const RGETTestContentsSchema = Joi.object({
	questions: Joi.array()
		.items(Joi.alternatives([FullQuestionSchema, ShortQuestionSchema]))
		.required(),
	cards: Joi.array()
		.items(CardSchema)
		.required(),
	texts: Joi.array()
		.items(TextSchema)
		.required(),
	stats: TestContentStatsSchema,
});
export interface IRGETTestContents {
	questions: (IFullQuestion | IShortQuestion)[];
	cards: ICard[];
	texts: IText[];
	stats?: ITestContentStats;
}

///

export const AGETTaskTypeContentsSchema = Joi.object({
	topicIds: Joi.array().items(Joi.objectId()),
	getAnswers: Joi.boolean().required(),
	getLevelStats: Joi.boolean(),
	taskTypes: Joi.array().items(Joi.objectId()),
	numQuestions: NumQuestionsSettingsSchema,
});
export interface IAGETTaskTypeContents {
	getAnswers: boolean;
	topicIds?: ObjectId[];
	getLevelStats?: boolean;
	taskTypes?: ObjectId[];
	numQuestions: INumQuestionsSettings;
}

export type IRGETTaskTypeContents = IRGETTestContents;

///

export const AGETTestQuestionsInOrderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	userId: Joi.number().required(),
	folderId: Joi.objectId().required(),
	questions: Joi.array()
		.items(Joi.object())
		.required(),
});
export interface IAGETTestQuestionsInOrder {
	courseId: ObjectId;
	userId: number;
	folderId: ObjectId;
	questions: IAnyObj[];
}

export const APUTUserFolderLevelSchema = Joi.object({
	courseId: Joi.objectId().required(),
	userId: Joi.number().required(),
	folderId: Joi.objectId().required(),
	itemsToUpdate: Joi.array()
		.items(
			Joi.alternatives([
				ItemType.folder,
				ItemType.question,
				ItemType.card,
			])
		)

		.required(),
});
export interface IAPUTUserFolderLevel {
	courseId: ObjectId;
	userId: number;
	folderId: ObjectId;
	itemsToUpdate: ItemType[];
}

///

export const APUTTestTypeItemsIntoTestItemsScema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAPUTTestTypeItemsIntoTestItems {
	courseId: ObjectId;
}

///

export const AGETTestsByItemSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.any()
		.valid(ItemType.question, ItemType.card, ItemType.text)
		.required(),
});
export interface IAGETTestsByItem {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType.question | ItemType.card | ItemType.text;
}

export const RGETTestsByItemSchema = Joi.array().items(Joi.objectId());
export type IRGETTestsByItem = ObjectId[];

///

export const APOSTSaveTestSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
	questions: Joi.array()
		.items(UserTestQuestionInfoSchema.keys({ credit: Joi.any().strip() }))
		.required(),
});
export interface IAPOSTSaveTest {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	questions: Omit<IUserTestQuestionInfo, "credit">[];
}

export const RPOSTSaveTestSchema = Joi.object({
	userTestId: Joi.objectId().required(),
	questionsCredit: Joi.array()
		.items(CorrectedQuestionSchema)
		.required(),
});
export interface IRPOSTSaveTest {
	userTestId: ObjectId;
	questionsCredit: {
		id: ObjectId;
		credit: number;
		maxCredit: number;
	}[];
}
export const APOSTSaveAdminCreditsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
	userId: Joi.number().required(),
	attempt: Joi.number().required(),
	credits: Joi.object()
		.pattern(
			/[\da-f]+/,
			Joi.object()
				.keys({
					numGradableItems: Joi.number().required(),
					gradedItems: Joi.object().pattern(
						/[\d]+/,
						Joi.number().required()
					),
				})
				.required()
		)
		.required(),
});
export interface IAPOSTSaveAdminCredits {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	userId: number;
	attempt: number;
	credits: Record<
		string,
		| {
				numGradableItems: number;
				gradedItems?: Record<string, number | undefined>;
		  }
		| undefined
	>;
}

///

export const AGETManyTestsByIdsSchema = Joi.object({
	testIds: Joi.array()
		.items(Joi.objectId().required())
		.required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETManyTestsByIds {
	testIds: ObjectId[];
	courseId: ObjectId;
}

export const RGETManyTestsByIdsSchema = RGETAllTestsSchema;
export type IRGETManyTestsByIds = IRGETAllTests;

///

export const AGETQuestionsByTaskTypesSchema = Joi.object({
	courseId: Joi.objectId().required(),
	taskTypeIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	topicIds: Joi.array().items(Joi.objectId()),
	onlyIncludeCoveredFolders: Joi.boolean(),
	includeStats: Joi.boolean(),
});
export interface IAGETQuestionsByTaskTypes {
	courseId: ObjectId;
	taskTypeIds: ObjectId[];
	topicIds?: ObjectId[];
	onlyIncludeCoveredFolders?: boolean;
	includeStats?: boolean;
}

export const RGETQuestionsByTaskTypesSchema = Joi.object({
	questions: Joi.array()
		.items(FullQuestionSchema)
		.required(),
	cards: Joi.array()
		.items(CardSchema)
		.required(),
	texts: Joi.array()
		.items(TextSchema)
		.required(),
	stats: TestContentStatsSchema,
});
export interface IRGETQuestionsByTaskTypes {
	questions: IFullQuestion[];
	cards: ICard[];
	texts: IText[];
	stats?: ITestContentStats;
}
