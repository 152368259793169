/* eslint-disable max-lines-per-function */
import { UserType } from "@app/api/helper-schemas";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { IAnyObj } from "@tests-core/utils";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { useGeneralContext } from "../general-context";
import { LoginPopup } from "../landing/login";
import { RegisterPopup } from "../landing/register-type-selection";
import { HeaderProviders, HeaderTree } from "../layouts/header/tree";
import { Calls } from "../widgets/calls";
import { ReactComponent as VideoIcon } from "./styles/imgs/video.svg";
import styles from "./styles/tv-school-landing.module.css";
import { VideoInstruction } from "./video-instruction";

export type DivReference = React.MutableRefObject<HTMLDivElement | null>;

export const GuestHeader: React.FC<IAnyObj> = props => {
	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				components: {
					NextToLogo: CallContainer,
					RightContainer,
				},
			}}
		>
			<HeaderTree.components.OuterContainer />
		</HeaderProviders.PartialMergingProvider>
	);
};

const CallContainer = () => {
	return (
		<div style={{ marginLeft: 20 }}>
			<Calls />
		</div>
	);
};

const RightContainer = React.memo<
	{
		isStudentHeader?: boolean;
		openLoginPage?: React.Dispatch<React.SetStateAction<boolean>>;
		openRegistarionPage?: React.Dispatch<React.SetStateAction<boolean>>;
	} & any
>(function RightContainer({
	isStudentHeader,
	openLoginPage,
	openRegistarionPage,
}) {
	const [defaultSelectedUserType, setDefaultSelectedUserType] = useState<
		UserType
	>();

	const history = useHistory();

	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";

	const isMainPage = history.location.pathname === "/";

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const { hideVideoInstructions } = useGeneralContext();

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(
		(userType?: UserType) => {
			setDefaultSelectedUserType(userType);
			closeLoginPopup();
			openRegistrationPopup();
		},
		[closeLoginPopup, openRegistrationPopup]
	);

	const {
		value: isVideoInstructioVissible,
		setFalse: hideVideoInstruction,
		setTrue: setVideoInstructionVissible,
	} = useBoolean(false);

	const { width } = useWindowSize();
	const isResponsive = width < 1100;

	const isVideoInstructionButtonInvissible =
		hideVideoInstructions || isStudentHeader || isNl;

	const videoInstructionButton = isVideoInstructionButtonInvissible ? null : (
		<div
			className={styles.videoInstructionButton}
			onClick={setVideoInstructionVissible}
		>
			{isResponsive ? (
				<FormattedMessage id="instruction" />
			) : (
				<>
					<VideoIcon className={styles.videoIcon} />
					<FormattedMessage id="videoInstruction" />
				</>
			)}
		</div>
	);

	const onRedirectToLogin = useCallback(() => {
		if (openLoginPage && openRegistarionPage) {
			openLoginPage(true);
			openRegistarionPage(false);
		}
	}, [openLoginPage, openRegistarionPage]);

	const onRedirectToRegistration = useCallback(() => {
		if (openLoginPage && openRegistarionPage) {
			openLoginPage(false);
			openRegistarionPage(true);
		}
	}, [openLoginPage, openRegistarionPage]);

	return (
		<div className={styles.headerButtonsContainer}>
			{videoInstructionButton}

			{width > 800 && (
				<div
					className={styles.headerButton}
					onClick={openLoginPage ? onRedirectToLogin : openLogin}
				>
					<FormattedMessage id="signInTitle" />
				</div>
			)}

			<div className={styles.signUpAndSignInButtons}>
				<div
					onClick={() =>
						openLoginPage
							? onRedirectToRegistration()
							: openRegisterPage(
									isMainPage ? undefined : UserType.student
							  )
					}
					className={styles.headerRegistrationButton}
				>
					<FormattedMessage id="signUp" />
				</div>
			</div>

			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
					userType={isMainPage ? undefined : UserType.student}
					isCountryInputInvissible={true}
					isToTest={!!openLoginPage}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
					isFromTvSchoolPopUp={isGeo}
				/>
			)}
			{isVideoInstructioVissible && (
				<VideoInstruction onClose={hideVideoInstruction} />
			)}
		</div>
	);
});
