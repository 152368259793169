import { PrimaryButton } from "@app/components/widgets/buttons-widget";
import { FancyPopup } from "@app/components/widgets/popup";
import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { CenterContainer } from "@app/components/widgets/tools";
import { useGoToUrl } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useUserActions } from "@app/hooks/user-actions";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { studentLinks } from "@app/routes/student/links";
import { classNamed } from "@pckgs/classed-components";
import React from "react";
import { isUserEligibleForTryingWeeklyTest } from "../popups/weekly-test/utils";
import { sh20v2NewKey3 } from "./v2popup-for-reserved";
import { useSubWebsite } from "@app/hooks/bc";
import { SubWebsiteOrigin } from "@app/globals";

const sh20v2NewKey2 = "sh20v2p2";

const Sh20V2Popup2 = React.memo<{ onClose: () => void }>(function Sh20V2Popup2({
	onClose,
}) {
	const markAsSeen = async () => {
		await inject("UserActionsController").performUserAction({
			name: sh20v2NewKey2,
			data: true,
		});
	};

	const handleClose = () => {
		markAsSeen();
		onClose();
	};

	const gotoProgressWeekLanding = useGoToUrl(
		studentLinks.weeklies.progress.landing
	);

	const handleSubmit = () => {
		markAsSeen();
		gotoProgressWeekLanding();
	};

	return (
		<FancyPopup
			onClose={handleClose}
			title="გამარჯობა,"
			containerStyles={{ width: 900 }}
			containerClassName="bg-light"
		>
			<P>
				უნარების განმავითარებელი კვირეული იწყება. არ გამოტოვო
				შესაძლებლობა განივითარო უნარები, გაზომო შენი პროგრესი და მოიპოვო
				გაზიარებადი ჯილდოები და სერტიფიკატი.
			</P>

			<CenterContainer className="mt-4">
				<PrimaryButton onClick={handleSubmit}>გაიგე მეტი</PrimaryButton>
			</CenterContainer>
		</FancyPopup>
	);
});
const P = classNamed("p")("my-2");

export const Sh20V2NewPopupDistributor = React.memo(
	function Sh20V2NewPopupDistributor() {
		const { value: isClosedManually, setTrue: closeManually } = useBoolean(
			false
		);

		const isMainWebsite = useSubWebsite() === SubWebsiteOrigin.main;

		const user = useClassroomsUser();
		const isEligible = isUserEligibleForTryingWeeklyTest(user);
		const userActions = useUserActions();
		const isLoaded = !!userActions.doc;
		const hasSeen =
			!!userActions.doc?.actions[sh20v2NewKey2] ||
			!!userActions.doc?.actions[sh20v2NewKey3];
		const enabled =
			isEligible &&
			isLoaded &&
			!hasSeen &&
			!isClosedManually &&
			isMainWebsite;

		return (
			<DefaultPopupPrioritizer priority={50} enabled={enabled}>
				<Sh20V2Popup2 onClose={closeManually} />
			</DefaultPopupPrioritizer>
		);
	}
);
