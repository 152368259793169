import Joi from "@app/utils/joi";
import {
	FullQuestionSchema,
	FullQuestionWithNameSchema,
	IFullQuestion,
	IFullQuestionWithName,
	IRQuestion,
	RQuestionSchema,
} from "@tests-core/schemas/questions/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	insertStripKeys,
	ToInsertKeys,
	updateStripKeys,
	ToUpdateKeys,
	markKeysStripped,
} from "../helper-schemas";

export const QuestionSchemaForPost = FullQuestionSchema.keys(
	insertStripKeys
).fork("domain", markKeysStripped);
export type IQuestionForPost = ToInsertKeys<IFullQuestion, never, "domain">;

export const APOSTCreateQuestionSchema = Joi.object({
	courseId: Joi.objectId().required(),
	question: QuestionSchemaForPost.required(),
	testIds: Joi.array().items(Joi.objectId()),
	taskTypeIds: Joi.array().items(Joi.objectId()),
	topicIds: Joi.array().items(Joi.objectId()),
	folderIds: Joi.array().items(Joi.objectId()),
});

export interface IAPOSTCreateQuestion {
	courseId: ObjectId;
	question: IQuestionForPost;
	testIds?: ObjectId[];
	taskTypeIds?: ObjectId[];
	topicIds?: ObjectId[];
	folderIds?: ObjectId[];
}

export const RPOSTCreateQuestionSchema = FullQuestionWithNameSchema;
export type IRPOSTCreateQuestion = IFullQuestionWithName;

///

export const QuestionSchemaForPut = FullQuestionSchema.keys(
	updateStripKeys
).fork("domain", markKeysStripped);
export type IQuestionForPut = ToUpdateKeys<IFullQuestion, never, "domain">;

export const APUTQuestionSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	question: QuestionSchemaForPut.required(),
	testIds: Joi.array().items(Joi.objectId()),
	taskTypeIds: Joi.array().items(Joi.objectId()),
	topicIds: Joi.array().items(Joi.objectId()),
	folderIds: Joi.array().items(Joi.objectId()),
});
export interface IAPUTQuestion {
	_id: ObjectId;
	courseId: ObjectId;
	question: IQuestionForPut;
	testIds?: ObjectId[];
	taskTypeIds?: ObjectId[];
	topicIds?: ObjectId[];
	folderIds?: ObjectId[];
}

export const RPUTQuestionSchema = FullQuestionWithNameSchema;
export type IRPUTQuestion = IFullQuestionWithName;

///

export const AGETQuestionSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	getAnswer: Joi.boolean().required(),
});
export interface IAGETQuestion {
	_id: ObjectId;
	courseId: ObjectId;
	getAnswer?: boolean;
}

export const RGETQuestionSchema = RQuestionSchema;
export type IRGETQuestion = IRQuestion;

///

export const ADELETEQuestionSchema = Joi.object({
	_id: Joi.objectId().required(),
	folderId: Joi.objectId()
		.allow(null)
		.empty(["", "null"])
		.default(null),
	courseId: Joi.objectId().required(),
	eraseQuestionFromDatabase: Joi.boolean(),
	optOutDeletingInFolders: Joi.boolean(),
	optOutDeletingInTests: Joi.boolean(),
});
export interface IADELETEQuestion {
	_id: ObjectId;
	folderId: ObjectId | null;
	courseId: ObjectId;
	eraseQuestionFromDatabase?: boolean;
	optOutDeletingInFolders?: boolean;
	optOutDeletingInTests?: boolean;
}

///

export const RGETAllQuestionsSchema = Joi.array()
	.items(RQuestionSchema)
	.required();
export type IRGETAllQuestions = IRQuestion[];

///

export const AGETManyByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
	courseId: Joi.objectId().required(),
	getAnswers: Joi.boolean().required(),
});
export interface IAGETManyByIds {
	ids: ObjectId[];
	courseId: ObjectId;
	getAnswers: boolean;
}

export const RGETManyByIdsSchema = RQuestionSchema;
export type IRGETManyByIds = IRQuestion[];

///

export const AGETNamesByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETNamesByIds {
	ids: ObjectId[];
	courseId: ObjectId;
}

export const RGETNamesByIdsSchema = Joi.array().items(
	Joi.object({
		id: Joi.objectId().required(),
		name: Joi.string().required(),
	})
);
export type IRGETNamesByIds = {
	ids: ObjectId;
	name: string;
}[];

///

export const APOSTRecalculateCoefficientsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	questionIds: Joi.array().items(Joi.objectId()),
	updatedBefore: Joi.date(),
});
export interface IAPOSTRecalculateCoefficients {
	courseId: ObjectId;
	questionIds?: ObjectId[];
	updatedBefore?: Date;
}

export type IRPOSTRecalculateCoefficients = void;
