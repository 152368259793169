import { IRGETTestContents } from "@app/api/tests/validators";
import { ReactComponent as QuestionIcon } from "@app/components/styles/img/icons/question.svg";
import styles from "@app/components/users/assignments/tv-school/styles/index.module.scss";
import { ReactComponent as UserIcon } from "@app/components/users/styles/img/user.svg";
import { HalfMoon } from "@app/components/widgets/half-moon";
import { useHistory } from "@app/hooks/front";
import { readTextFile } from "locale";
import React, {
	useEffect,
	useState,
	useCallback,
	useContext,
	useMemo,
} from "react";
import { FormattedMessage } from "react-intl";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { useBoolean } from "@app/hooks/general";
import triggerEvent from "@app/utils/events";
import { IUserTestQuestionInfo, IRTest } from "@app/api/tests/helper-schemas";
import {
	ITestFinishArgs,
	IFinishPageProps,
} from "@tests-core/components/tests";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { UniversalTestWrapper } from "../prof-orientation/test-wrapper";
import { LupiAd } from "../../subjects/lupiAd";
import testStyles from "@app/components/users/assignments/tv-school/styles/test-styles.module.css";

const testsInfo: Record<
	string,
	{ testId: string; courseId: string; folderId: string }
> = {
	1: {
		courseId: "5e889153f4175d33debff908",
		folderId: "5e889288f794d734c37e1250",
		testId: "5e889288f794d734c37e1251",
	},
	2: {
		courseId: "5e889153f4175d33debff908",
		folderId: "5e889288f794d734c37e1254",
		testId: "5e889288f794d734c37e1255",
	},
};

export const TVSChoolWrapper = ({ level }: { level: string }) => {
	const history = useHistory();
	const record = testsInfo[level];

	useEffect(() => {
		if (!record) {
			// history.push("/");
		}
	}, [record, history]);

	return <TvQuiz level={level} />;
};

const TvQuiz: React.FC<{
	level: string;
}> = ({ level }) => {
	const [testContent, setTestContent] = useState<IRGETTestContents>();
	const { value: isStarted, setTrue: onStart } = useBoolean();
	useEffect(() => {
		readTextFile("/locales/t/q/" + level + ".json?date=" + Date.now())
			.then(data => {
				const content = JSON.parse(data);
				setTestContent(content);
				console.log(content);
			})
			.catch(e => {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	}, [level]);

	const handleStart = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			triggerEvent(
				{
					category: "test",
					action: "TV-quiz",
					label: "start",
				},
				{
					level,
				}
			);
			onStart();
		},
		[onStart, level]
	);
	const handleFinish = useCallback(() => {
		triggerEvent(
			{
				category: "test",
				action: "TV-quiz",
				label: "finish",
			},
			{
				level,
			}
		);
	}, [level]);

	if (!testContent) {
		return <FancyLoadingCenter />;
	}

	if (isStarted) {
		return (
			<TVQuiz
				content={testContent}
				onFinish={handleFinish}
				level={level}
			/>
		);
	}

	return (
		<div>
			<HalfMoon className={styles["halfmoon-container"]}>
				<div className={styles["st-assignment-top"]}>
					<div
						className={styles.subject_icon}
						style={{
							backgroundImage: `url(/photos/subjects/books.svg)`,
						}}
					/>
					<div className={styles.right}>
						<h2 className={styles.subject_title}>მეცნიერება</h2>
						<p className={styles.subject_theme_title}>ქვიზი</p>
					</div>
				</div>
			</HalfMoon>
			<div className="main main2">
				<div className={styles.subject_infobox_wrapper}>
					<div className={styles.subject_info_box}>
						<p className={styles.subject_info_box__title}>
							<QuestionIcon />
							<FormattedMessage id="numOfQuestions" />
						</p>
						<div
							className={
								styles.subject_info_box__content_questions
							}
						>
							{testContent?.questions.length}
						</div>
					</div>
					<div className={styles.subject_info_box}>
						<p className={styles.subject_info_box__title}>
							<UserIcon
								style={{
									width: "15px",
									color: "#8b8f9b",
									marginRight: "5px",
								}}
							/>
							წარმდგენი
						</p>
						<div
							className={styles.subject_info_box__content_author}
						>
							TV სკოლა
						</div>
					</div>
				</div>
				<form className={styles.tasks} onSubmit={handleStart}>
					<button className={styles.tasks_button}>დაწყება</button>
				</form>
			</div>
		</div>
	);
};

interface IProps {
	content: IRGETTestContents;
	onFinish: (args: ITestFinishArgs) => void;
	level: string;
}

class TVQuiz extends React.PureComponent<IProps> {
	defaultUserAnswers: IUserTestQuestionInfo[] = [];
	test = {} as IRTest;
	content = this.props.content;

	save = async (args: ITestFinishArgs) => {
		this.props.onFinish(args);
	};

	handleTestError = (e: any) => {
		openConfirmationPopup({
			text: getFormattedMessage("student:test.errorWhileSaving"),
		});
		throw e;
	};

	onFinish = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};
	onSave = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	onGotoNext = () => {
		//
	};
	redirectoToFolder = () => {
		//
	};

	testTypeSettings: ITestTypeSettings = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: false,
		checkInBackEnd: false,
		maxNumOfWritingTests: 1,
		showAnswersAt: TestTypeAnswersShowTime.immediately,
		submitAnswerAfterAnswering: true,
	};

	render() {
		return (
			<div className="rest-container-of-page">
				<TestCotext.Provider value={{ level: this.props.level }}>
					<UniversalTestWrapper
						test={this.test}
						testTypeSettings={this.testTypeSettings}
						content={this.content}
						defaultUserAnswers={this.defaultUserAnswers}
						onFinish={this.onFinish}
						onSave={this.onSave}
						onGotoNext={this.onGotoNext}
						currentAttempt={1}
						FinishPageComponent={FinishPageComponent}
						testNavigationProps={{
							styles: {
								item: {
									container: testStyles.itemContainer,
									isFinishPage: testStyles.finishItem,
									isStartPage: testStyles.startItem,
									isSelected: testStyles.isSelected,
									containsGradableItemsByEditor:
										testStyles.containsGradableItemsByEditor,
									isAccessible: testStyles.isAccessible,
									isNotAccessible: testStyles.isNotAccessible,
									hasAnswered: testStyles.hasAnswered,
									hasAnsweredFully:
										testStyles.hasAnsweredFully,
									hasAnsweredCorreclty:
										testStyles.hasAnsweredCorreclty,
									hasAnsweredPartiallyCorreclty:
										testStyles.hasAnsweredPartiallyCorreclty,
									hasAnsweredIncorreclty:
										testStyles.hasAnsweredIncorreclty,
								},
								OuterContainer: testStyles.outerContainer,
								ContainerWithScrollbar:
									testStyles.containerWithScrollbar,
							},
						}}
					/>
				</TestCotext.Provider>
			</div>
		);
	}
}

const FinishPageComponent: React.FC<IFinishPageProps> = props => {
	const { level } = useContext(TestCotext);
	const correctAnswers = props.info.userAnswers.filter(
		e => e.credit === e.maxCredit
	).length;
	const totalNumber = props.info.userAnswers.length;
	const correctRatio = correctAnswers / totalNumber;

	const feedback = useMemo(() => {
		if (level === "2") {
			if (correctRatio >= 0.9) {
				return "შენ დააფიქსირე ყველაზე მაღალი შედეგი! შენში იმხელა პოტენციალია, გირჩევთ ვაშლის ხეებთან ახლოს იყო, შესაძლოა, ნიუტონივით შენი სიტყვა თქვა მეცნიერებაში! ასე განაგრძე!";
			} else if (correctRatio >= 0.6) {
				return "როგორც ჩანს, დაინტერესებული ხარ მეცნიერებითა და სიახლეების აღმოჩენით. ახალგაზრდა ჩარლზ დარვინმაც ზუსტად ასე დაიწყო სამეცნიერო მოღვაწეობა და საბოლოოდ კაცობრიობის ერთ-ერთ უდიდეს აღმოჩენამდე, ევოლუციის თეორიამდე მივიდა.";
			} else {
				return "აშკარად მეტიც შეგიძლია! თუკი უკეთ გაეცნობი მეცნიერულ საკითხებს, სამომავლოდ შედეგსაც გააუმჯობესებ! მონდომებისა და სწავლის შედეგად რომ წარმატების მიღწევა შეიძლება, ეს აინშტაინმაც დაგვიმტკიცა-სკოლაში არცთუ კარგად სწავლობდა, შემდეგ კი მსოფლიო დონის ფიზიკოსი გახდა!";
			}
		} else {
			if (correctRatio >= 0.9) {
				return "თქვენ დააფიქსირეთ ყველაზე მაღალი შედეგი. აშკარაა, რომ გიყვართ მეცნიერება, ხართ დაკვირვებული და შრომისმოყვარე. ასე განაგრძეთ და ბევრ წარმატებას მიაღწევთ!";
			} else if (correctRatio >= 0.6) {
				return "თქვენ დააფიქსირეთ საშუალო შედეგი. როგორც ჩანს, დაინტერესებული ხართ მეცნიერებითა და სიახლეების აღმოჩენით. მეტი მონდომებისა და შრომისმოყვარეობის შედეგად, უმაღლეს შეფასებასაც დაიმსახურებთ!";
			} else {
				return "მეტი შეგიძლიათ! თუკი უკეთ გაეცნობით მეცნიერულ საკითხებს, სამომავლოდ შედეგსაც გააუმჯობესებთ!";
			}
		}
	}, [level, correctRatio]);

	return (
		<div>
			<div
				style={{
					fontFamily: "Roboto Geo Caps",
					textAlign: "center",
					color: "#5273e6",
					fontSize: 22,
				}}
			>
				<span style={{ fontSize: 27, marginBottom: 10 }}>
					თქვენი შედეგია:{" "}
					<span style={{ fontSize: 32 }}>{correctAnswers}</span>/
					{totalNumber}
				</span>
				<br />
				{feedback}
			</div>
			<div style={{ textAlign: "center" }}>
				<LupiAd
					title="მსგავსი ტესტებისთვის გამოიყენე"
					link="https://lupi.ge/#/profile/main/hotsale/subjectId/10/?a=001#/"
				/>
			</div>
		</div>
	);
};

const TestCotext = React.createContext(
	{} as {
		level: string;
	}
);
