import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const SchoolHeadmasterSchema = Joi.object({
	userId: Joi.number().required(),
	schoolId: Joi.number().required(),
	schoolLabelIds: Joi.array()
		.items(Joi.objectId())
		.allow(null),
	classrooms: Joi.array().items(
		Joi.object({
			clsId: Joi.objectId().required(),
			labelId: Joi.objectId(),
			canEdit: Joi.boolean(),
		})
	),
});

export interface ISchoolHeadmaster {
	userId: number;
	schoolId: number;
	schoolLabelIds: ObjectId[] | null;
	classrooms?: {
		clsId: ObjectId;
		labelId: ObjectId | undefined;
		canEdit?: boolean;
	}[];
}
