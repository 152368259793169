import React, { useLayoutEffect, useState } from "react";
import { DynamicContext } from "react-flexible-contexts";

const ParamsParentContext = DynamicContext.createDestructured<{
	value: Record<any, any>;
	setValue: (newVal: Record<any, any>) => void;
}>();

export const ParamsContext = ParamsParentContext.addInternalContext(
	v => v.value
);

export const useGlobalParams = <
	T extends Record<any, any> = Record<any, any>
>(): T => ParamsContext.useValue();

export const useGlobalParamProperty = (key: string): string | undefined =>
	ParamsContext.useProperty(key);

export const ParamsContainer = React.memo<{ children: React.ReactNode }>(
	function ParamsContainer({ children }) {
		const [params, setParams] = useState<Record<any, any>>({});
		// TODO: merge params
		return (
			<ParamsParentContext.Provider value={params} setValue={setParams}>
				{children}
			</ParamsParentContext.Provider>
		);
	}
);

export const ParamsObserver = React.memo<{ params: Record<any, any> }>(
	function ParamsObserver({ params }) {
		const setParams = ParamsParentContext.useProperty("setValue");
		useLayoutEffect(() => {
			setParams(params);
		}, [setParams, params]);
		return null;
	}
);
