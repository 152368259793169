/* eslint-disable react-hooks/rules-of-hooks */
import { ValueHistory } from "@app/utils/value-history";
import React, { useContext, useLayoutEffect } from "react";
import { removeKeys } from "@app/utils/common";

type Unsubscribe = () => void;

export type PropHistoryOnChange<T extends {}> = <K extends keyof T>(
	key: K,
	value: T[K] | ((olValue: T[K]) => T[K])
) => Unsubscribe;

interface St<T extends {}> {
	readonly data: Readonly<T>;
	readonly onChange: PropHistoryOnChange<T>;
}

export const createPropHistoryContent = <T extends {}>(
	initialValue: T,
	displayName?: string
) => {
	const PropContext = React.createContext<St<T>>({
		data: initialValue,
		onChange: () => {
			throw new Error("cannot use this context without provider");
		},
	});
	PropContext.displayName = displayName;

	class PropContextComponent extends React.Component<{}, St<T>> {
		onPropChange = <K extends keyof T>(
			key: K,
			value: T[K] | ((olValue: T[K]) => T[K])
		) => {
			if (!this.histories[key]) {
				this.histories[key] = new ValueHistory(initialValue[key]);
			}
			let hasCancelled = false;
			const hist = this.histories[key]!;
			let histId: string | null = null;
			this.setState(state => {
				const realValue =
					typeof value === "function"
						? (value as (olValue: T[K]) => T[K])(state.data[key])
						: value;
				if (hasCancelled) return state;
				histId = hist.push(realValue);
				return { ...state, data: { ...state.data, [key]: realValue } };
			});
			return () => {
				hasCancelled = true;
				this.setState(state => {
					if (!histId) return state;
					try {
						hist.delete(histId);
						return {
							...state,
							data: {
								...state.data,
								[key]: hist.getLatestValue(),
							},
						};
					} catch (e) {
						return {
							...state,
							data: removeKeys(state.data, key) as any,
						};
					}
				});
			};
		};

		state: St<T> = {
			data: initialValue,
			onChange: this.onPropChange,
		};

		histories: { [key in keyof T]?: ValueHistory<T[key]> } = {};
		// new ValueHistory(this.state.title);

		render() {
			return (
				<PropContext.Provider value={this.state}>
					{this.props.children}
				</PropContext.Provider>
			);
		}
	}

	return {
		provider: PropContextComponent,
		context: PropContext,
	};
};

export const getPropHistoryChangeHook = <T extends {}, K extends keyof T>(
	context: React.Context<St<T>>,
	key: K
) => {
	return (value: T[K], skip = false) => {
		const { onChange } = useContext(context);
		useLayoutEffect(() => {
			if (skip) return;
			return onChange(key, value);
		}, [value, onChange, skip]);
	};
};
