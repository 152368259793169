import React from "react";
import { UserType } from "@app/api/helper-schemas";
import { WebsiteOrigin } from "@app/globals";
import UserRoute from "@app/routes/containers/user-route";
import { createRouteList } from "../config";
import { teacherLinks } from "./links";
import { defaultComponentWrapper } from "../wrapper";
import { Redirect } from "react-router-dom";
import { CommonRouteProps, defaultRouteMapper } from "../route-props-map-utils";

const routes = createRouteList<CommonRouteProps>({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.teacher,
		availableWebsite: WebsiteOrigin.tvSchool,
	},
	componentWrapper: defaultComponentWrapper,
	routeMapFn: defaultRouteMapper,
});

///

const TVSchoolClassroomPage = () =>
	import("../../components/teachers/main/tvschool/in-classroom").then(
		module => ({
			default: module.TVSchoolClassroomPage,
		})
	);

routes.add({
	path: teacherLinks.classrooms.assignments.routePath,
	component: TVSchoolClassroomPage,
	showSidebar: true,
});

///

routes.add({
	path: "/tch-p-wk/",
	component: () => React.createElement(Redirect, { to: "/?progW=1" }),
});

///

routes.add({
	path: "/classrooms/:classroomId/videoURL/:videoURL",
	component: TVSchoolClassroomPage,
});

///

const GroupAssignments = () =>
	import("@app/components/teachers/assignments/index").then(module => ({
		default: module.GroupAssignments,
	}));

routes.add({
	path: teacherLinks.groups.assignments.routePath,
	component: GroupAssignments,
	showSidebar: true,
});

///
const TeacherAssignmentReview = () =>
	import("../../components/teachers/assignments/review").then(module => ({
		default: module.TeacherAssignmentReview,
	}));

routes.add({
	path: "/classrooms/:classroomId/assignments/:assignmentId/review",
	component: TeacherAssignmentReview,
	routeProps: {
		availableUserTypes: [UserType.headmaster, UserType.teacher],
	},
	headerTheme: "dark",
});

///
const TeacherAssignmentIndividualUserReview = () =>
	import("../../components/teachers/assignments/review/individual").then(
		module => ({
			default: module.TeacherAssignmentIndividualUserReview,
		})
	);

routes.add({
	path:
		"/classrooms/:classroomId/assignments/:assignmentId/users/:userId/review",
	component: TeacherAssignmentIndividualUserReview,
	headerTheme: "dark",
});

///
const TeacherPublicWrittenAssignment = () =>
	import("../../components/teachers/assignments/review/individual").then(
		module => ({
			default: module.TeacherPublicWrittenAssignment,
		})
	);

routes.add({
	path:
		"/classrooms/:classroomId/passignments/:assignmentId/wt/:writtenAssignmentId/review",
	component: TeacherPublicWrittenAssignment,
	routeProps: {
		availableUserTypes: [UserType.headmaster, UserType.teacher],
	},
	headerTheme: "dark",
});

///

const ClassroomGradebook = () =>
	import("../../components/teachers/gradebook").then(module => ({
		default: module.ClassroomGradebook,
	}));

routes.add({
	path: teacherLinks.classrooms.gradebook.routePath,
	component: ClassroomGradebook,
	showSidebar: true,
});

///
const NewAssignment2 = () =>
	import("../../components/teachers/assignments-2").then(module => ({
		default: module.NewAssignment2,
	}));

routes.add({
	path: "/assignments/new",
	component: NewAssignment2,
	headerTheme: "dark",
});

///
const RequestSubject = () =>
	import("../../components/users/request-subject").then(module => ({
		default: module.RequestSubject,
	}));

routes.add({
	path: "/request-test/:subjectId?/:grade?/:courseId?",
	component: RequestSubject,
});

///
const StudentCertificateCandidatesFrontPage = () =>
	import(
		"../../components/teachers/certificates/certificate-candidates"
	).then(module => ({
		default: module.StudentCertificateCandidatesFrontPage,
	}));

routes.add({
	path: "/certificate-candidates",
	component: StudentCertificateCandidatesFrontPage,
});

///
const StudentCertificateCandidatesListPage = () =>
	import(
		"../../components/teachers/certificates/certificate-candidates"
	).then(module => ({
		default: module.StudentCertificateCandidatesListPage,
	}));

routes.add({
	path: "/certificate-candidates-list",
	component: StudentCertificateCandidatesListPage,
});

///
const CreateCognitiveAssignmentContainer = () =>
	import(
		"../../components/teachers/assignments-2/cognitive-assignments/create-container"
	).then(module => ({
		default: module.CreateCognitiveAssignmentContainer,
	}));

routes.add({
	path: "/cognitive-assignment/new",
	component: CreateCognitiveAssignmentContainer,
	headerTheme: "dark",
});

///
const CognitiveAssignmentResults = () =>
	import(
		"../../components/teachers/assignments-2/cognitive-assignments/results"
	).then(module => ({
		default: module.CognitiveAssignmentResults,
	}));

routes.add({
	path: "/cognitive-assignment/results/:classroomId",
	component: CognitiveAssignmentResults,
	headerTheme: "dark",
});

///
const CreateClassroom = () =>
	import(
		"../../components/teachers/classrooms/tvschool-create-classroom"
	).then(module => ({
		default: module.CreateClassroom,
	}));

routes.add({
	path: "/new-classroom",
	component: CreateClassroom,
	headerTheme: "dark",
});

///
const TeacherGroupTimetablePage = () =>
	import("../../components/teachers/timetables/groups").then(module => ({
		default: module.TeacherGroupTimetablePage,
	}));

routes.add({
	path: "/group/:groupId/timetable",
	component: TeacherGroupTimetablePage,
	showSidebar: true,
});

///
const GroupGradebook = () =>
	import("@app/components/teachers/gradebook/group").then(module => ({
		default: module.GroupGradebook,
	}));

routes.add({
	path: "/group/:groupId/gradebook",
	component: GroupGradebook,
	showSidebar: true,
});

///

const GroupSemesterbook = () =>
	import("@app/components/teachers/semesterbook/viewer-types/index").then(
		module => ({
			default: module.SemesterbookAsGroup,
		})
	);

routes.add({
	path: "/group/:groupId/semesterbook",
	component: GroupSemesterbook,
	showSidebar: true,
});

///
const TeacherPersonalTimetablePage = () =>
	import("../../components/teachers/timetables/personal").then(module => ({
		default: module.TeacherPersonalTimetablePage,
	}));

routes.add({
	path: "/t/timetable",
	component: TeacherPersonalTimetablePage,
});

///
const StudentListing = () =>
	import("../../components/teachers/student-listing/index").then(module => ({
		default: module.StudentListing,
	}));

routes.add({
	path: teacherLinks.classrooms.students.routePath,
	component: StudentListing,
	showSidebar: true,
});

const SemesterBook = () =>
	import("@app/components/teachers/semesterbook/viewer-types/index").then(
		module => ({
			default: module.SemesterbookAsTeacher,
		})
	);

routes.add({
	path: teacherLinks.classrooms.semesterbook.routePath,
	component: SemesterBook,
	showSidebar: true,
});

///

const StudentPortfolio = () =>
	import("../../components/teachers/portfolio").then(module => ({
		default: module.StudentPortfolio,
	}));

routes.add({
	path: "/classrooms/:classroomId/portfolio/:studentId",
	component: StudentPortfolio,
	routeProps: {
		availableUserTypes: [UserType.teacher, UserType.headmaster],
	},
	showSidebar: true,
});

///

///

const GroupStudentList = () =>
	import("../../components/teachers/student-listing/group").then(module => ({
		default: module.GroupStudentList,
	}));

routes.add({
	path: "/group/:groupId/student-list",
	component: GroupStudentList,
	showSidebar: true,
});

///

const ArchivedClassroomsContainerForTeacher = () =>
	import("../../components/teachers/main/tvschool/classrooms").then(
		module => ({
			default: module.TeachersClassroomsArchivePage,
		})
	);

routes.add({
	path: teacherLinks.archived.classrooms.routePath,
	component: ArchivedClassroomsContainerForTeacher,
});

///

const StatsPage = () =>
	import("../../components/teachers/main/tvschool/stats-page").then(
		module => ({
			default: module.FinalStatsPage,
		})
	);

routes.add({
	path: teacherLinks.classrooms.finalStats.routePath,
	component: StatsPage,
	showSidebar: true,
});
export default routes;
