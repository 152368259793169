import { EditorState } from "draft-js";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import Editor from "draft-js-plugins-editor";
import "draft-js/dist/Draft.css";
import { decorators } from "./decorators";
import React from "react";
import { MATH_EQUATION_REGEX } from "./math";
import "./styles/editor-styles.css";
import { toolbar, inlineToolbarOptions } from "./toolbar";
import {
	focusPlugin,
	resizeablePlugin,
	blockDndPlugin,
	imagePlugin,
	linkifyPlugin,
} from "./plugins";
import { blockRendererFn } from "./html";

const customStyleMap = {
	SUPERSCRIPT: { fontSize: "smaller", verticalAlign: "super" },
	SUBSCRIPT: { fontSize: "smaller", verticalAlign: "sub" },
};

export const NOT_HANDLED = "not-handled";
export type NOT_HANDLED = typeof NOT_HANDLED;
export const HANDLED = "handled";
export type HANDLED = typeof HANDLED;

interface IProps {
	data: EditorState;
	className?: string;
	placeholder?: string;
	onChange?: (editorState: EditorState) => void;
	onPaste?: (text: string) => NOT_HANDLED | HANDLED;
	onBlur?: (e: any) => void;
	disableEditing?: boolean;
}

interface IState {
	disableInlineToolbar: boolean;
}
class ContentEditable extends React.PureComponent<IProps, IState> {
	inlineToolbarPlugin = createInlineToolbarPlugin(inlineToolbarOptions);

	InlineToolbar = this.inlineToolbarPlugin.InlineToolbar;

	plugins = [
		focusPlugin,
		resizeablePlugin,
		blockDndPlugin,
		imagePlugin,
		linkifyPlugin,
		this.inlineToolbarPlugin,
	];

	state: IState = {
		disableInlineToolbar: false,
	};

	onChange = (editorState: EditorState) => {
		if (this.props.disableEditing) return;
		const selectionState = editorState.getSelection();
		const start = selectionState.getStartOffset();
		const end = selectionState.getEndOffset();
		if (start !== end) {
			this.setState({
				disableInlineToolbar: this.checkIfMathEquationIsSelected(
					editorState,
					selectionState,
					start,
					end
				),
			});
		} else this.setState({ disableInlineToolbar: false });
		if (this.props.onChange) this.props.onChange(editorState);
	};

	checkIfMathEquationIsSelected = (
		editorState: EditorState,
		selectionState = editorState.getSelection(),
		start: number,
		end: number
	) => {
		if (typeof start === "undefined" || typeof end === "undefined") {
			start = selectionState.getStartOffset();
			end = selectionState.getEndOffset();
		}
		const anchorKey = selectionState.getAnchorKey();
		const currentContent = editorState.getCurrentContent();
		const currentContentBlock = currentContent.getBlockForKey(anchorKey);
		const currentContentBlockText = currentContentBlock.getText();
		const selectedText = currentContentBlockText.slice(start, end);
		if (!selectedText) return false;

		let matchArr;
		while (
			(matchArr = MATH_EQUATION_REGEX.exec(currentContentBlockText)) !==
			null
		) {
			if (
				start >= matchArr.index &&
				end <= matchArr.index + matchArr[0].length
			) {
				return true;
			}
		}
		return false;
	};

	onPaste = (
		text: string,
		_,
		editorState: EditorState
	): NOT_HANDLED | HANDLED => {
		try {
			if (this.props.onPaste) return this.props.onPaste(text);
		} catch (e) {
			return NOT_HANDLED;
		}
		return NOT_HANDLED;
	};

	render() {
		const { className, data, onChange, onPaste, ...restProps } = this.props;
		const { InlineToolbar } = this;
		const EditorComponent = Editor as any;
		return (
			<div className={className}>
				<EditorComponent
					{...restProps}
					editorState={this.props.data}
					onChange={this.onChange}
					plugins={this.plugins}
					handlePastedText={this.onPaste}
					decorators={decorators}
					customStyleMap={customStyleMap}
					readOnly={!!this.props.disableEditing}
					blockRendererFn={blockRendererFn}
				/>
				{!this.state.disableInlineToolbar && (
					<InlineToolbar>{toolbar}</InlineToolbar>
				)}
				<div style={{ clear: "both" }} />
			</div>
		);
	}
}

export default ContentEditable;
