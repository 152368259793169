import Joi from "@app/utils/joi";
import {
	ClassroomSchema,
	IClassroom,
	SemesterSchema,
	ISemester,
} from "../classrooms/helper-schemas";
import { BasicDocument, BasicDocumentSchema } from "../interface";
import {
	IVideoLesson,
	VideoLessonSchema,
} from "../video-lessons/helper-schemas";

export const GroupSchema = BasicDocumentSchema.keys({
	schoolId: Joi.number()
		.integer()
		.min(1)
		.required(),
	grade: Joi.number()
		.integer()
		.min(1)
		.required(),
	name: Joi.string().optional(),
	mentorIds: Joi.array()
		.items(
			Joi.number()
				.integer()
				.min(1)
				.required()
		)
		.required(),
	studentIds: Joi.array()
		.items(
			Joi.number()
				.integer()
				.min(1)
				.required()
		)
		.required(),
	isArchived: Joi.boolean().optional(),
	semester: SemesterSchema.optional(),
});
export interface IGroup extends BasicDocument {
	schoolId: number;
	grade: number;
	name?: string;
	/** User ids of teachers, who serve as mentors for the group */
	mentorIds: number[];
	studentIds: number[];
	isArchived?: boolean;
	semester?: ISemester;
}

export const GroupClassroomInfoSchema = Joi.object({
	classroom: ClassroomSchema.required(),
	videoLesson: VideoLessonSchema.optional(),
});
export interface IGroupClassroomInfo {
	classroom: IClassroom;
	videoLesson?: IVideoLesson;
}
