import { UserActionSchema, IUserAction } from "./helper-schemas";
import Joi from "@app/utils/joi";

export const RGETUserActionSchema = UserActionSchema.allow(null);
export type IRGETUserAction = IUserAction | null;

///

export const APOSTUserActionSchema = Joi.object({
	name: Joi.string().required(),
	data: Joi.any().required(),
});
export interface IAPOSTUserAction {
	name: string;
	data: any;
}

export const RPOSTUserActionSchema = UserActionSchema;
export type IRPOSTUserAction = IUserAction;
