import { IAssignment } from "@app/api/assignments/helper-schemas";
import {
	APOSTWeeklyTestReservationSchema,
	IAPOSTWeeklyTestReservation,
} from "@app/api/weekly-tests/validators";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

const storageKey = "userPublicWrittenAssignments";

export const UserWAssignments = {
	getAll: (): UserPublicWrittenAssignmentInfo[] => {
		const strValue = localStorage[storageKey];
		if (!strValue) return [];
		try {
			const val = UserPublicWrittenAssignmentInfosSchema.validate(
				JSON.parse(strValue)
			);
			if (val.error) return [];
			return val.value || [];
		} catch (e) {
			return [];
		}
	},
	push: (
		assignment: IAssignment,
		writtenAssignmentId: ObjectId
	): UserPublicWrittenAssignmentInfo[] => {
		try {
			const olds = UserWAssignments.getAll();
			const code = assignment.code;
			if (!code) return olds;
			const newItems: UserPublicWrittenAssignmentInfo[] = [
				...olds,
				{
					assignmentCode: code,
					writtenAssignmentId,
					assignmentId: assignment._id,
					classroomIds: assignment.classroomIds || null,
				},
			];
			localStorage.setItem(storageKey, JSON.stringify(newItems));
			return newItems;
		} catch (e) {
			///
			return [];
		}
	},
};

interface UserPublicWrittenAssignmentInfo {
	assignmentCode: string;
	writtenAssignmentId: ObjectId;
	assignmentId: ObjectId;
	classroomIds: ObjectId[] | null;
}

const UserPublicWrittenAssignmentInfoSchema = Joi.object({
	assignmentCode: Joi.string().required(),
	writtenAssignmentId: Joi.objectId().required(),
	assignmentId: Joi.objectId(), // TODO: make required after some time
	classroomIds: Joi.array()
		.items()
		.allow(null), // TODO: make required after some time
});

const UserPublicWrittenAssignmentInfosSchema = Joi.array().items(
	UserPublicWrittenAssignmentInfoSchema
);

///

/**
 * For unauthenticated users
 */

const wTestsStorageKey = "UWeeklyTestReservationsUnauthenticated";

export const UWeeklyTestReservations = {
	get(): IAPOSTWeeklyTestReservation | undefined {
		const strValue = localStorage[wTestsStorageKey];
		if (!strValue) return undefined;
		try {
			const val = APOSTWeeklyTestReservationSchema.validate(
				JSON.parse(strValue)
			);
			return val.value;
		} catch (e) {
			return undefined;
		}
	},
	set(value: IAPOSTWeeklyTestReservation) {
		localStorage.setItem(wTestsStorageKey, JSON.stringify(value));
	},
	clear(value: IAPOSTWeeklyTestReservation) {
		localStorage.removeItem(wTestsStorageKey);
	},
};
