import React from "react";
import termStyles from "./styles/terms.module.css";

export const DutchTerms = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					Dit document definieert de Algemene Voorwaarden van het
					internetplatform Mijntaken.com
				</h1>
				<p className={termStyles.primary}>
					Voor de toepassing van deze ‘Algemene Voorwaarden’ wordt het
					volgende verstaan onder:
				</p>
				<p>
					Gebruiker : iedere rechtspersoon of natuurlijk persoon, die
					de Website Gebruikt dan wel op een of andere wijze gebruik
					maakt van Mijntaken.com, zoals bijvoorbeeld het inzien van
					en plaatsen van Materiaal (wel of niet geregistreerd).
				</p>
				<p>Website : www.mijntaken.com en alle onderdelen ervan.</p>
				<p>
					Materiaal: Alle materiaal die de Gebruiker op de Website
					plaatst, of taken die de Gebruiker zelf aanmaakt.
				</p>
				<p className={termStyles.primary}>
					De regels zijn een juridische overeenkomst tussen het
					bedrijf dat de site bezit enerzijds – ‘Lupi Ai LTD’ (hierna
					– mijntaken.com) en anderzijds de Gebruikers van de Website.
					Door de website te gebruiken, bevestigt de Gebruiker dat
					hij/zij minstens 18 jaar oud is of indien minderjarig,
					toestemming heeft van een ouder of voogd. De gebruiker moet
					aangeven dat hij/zij de algemene voorwaarden heeft gelezen,
					akkoord gaat en de volledige verantwoordelijkheid opneemt
					voor de regels in deze Algemene Voorwaarden.
				</p>
				<p className={termStyles.termHeader}>1. Registratie </p>
				<div className={termStyles.termText}>
					<p>
						1.1. De Gebruiker is verplicht correcte gegevens in te
						vullen bij het aanmaken van het account, tijdens de
						registratie op de Website.
					</p>
					<p>
						1.2. De Gebruiker deelt zijn/haar account met niemand of
						staat niemand anders buiten zichzelf toe om de toegang
						tot zijn/haar account te krijgen of te gebruiken.
					</p>
					<p>
						1.3. De Gebruiker mag de tijdens de registratie
						aangegeven informatie niet openbaar maken of die
						informatie aan derden overdragen, waardoor de Website
						geautoriseerd kan worden.
					</p>
					<p>
						1.4. De informatie die in het gebruikersprofiel is
						opgeslagen, moet door de Gebruiker worden bijgewerkt als
						deze wordt gewijzigd.
					</p>
					<p>
						1.5. De Gebruiker bevestigt dat het gebruik van de
						Website niet in strijd is met de normen van lokale,
						nationale wetgeving en / of internationaal recht, en
						geen inbreuk maakt op de rechten van derden.
					</p>
				</div>
				<p className={termStyles.termHeader}>
					2. Materiaal plaatsen op de Website{" "}
				</p>
				<div className={termStyles.termText}>
					<p>
						2.1. De Gebruikers die als leerkrachten zijn
						geregistreerd (hierna - leerkrachten) op de Website,
						kunnen zelf een taak aanmaken en op de Website plaatsen.
					</p>
					<p>
						2.2. De Gebruiker (hierna – leerkracht) is
						verantwoordelijk voor de inhoud van de taken die hij/zij
						op Mijntaken.com aanmaakt en/of plaatst.
					</p>
					<p>
						2.3. Bij het toevoegen van het Materiaal bevestigt en
						garandeert de leerkracht dat:
					</p>
					<p>
						2.3.1. Het plaatsen/aanmaken van zijn/haar Materiaal op
						de Website niet in strijd is met de normen van
						territoriaal, nationaal of internationaal recht;
					</p>
					<p>
						2.3.2. Het plaatsen/aanmaken van zijn/haar Materiaal op
						de Website Geen inbreuk maakt op de rechten van derden
						(inclusief andere rechten die worden toegeschreven aan
						auteursrechten, koopwaar, patenten, intellectuele
						eigendommen, persoonlijke eigendomsrechten, persoonlijk
						niet-eigendomsrechten of persoonlijke gegevensrechten);
					</p>
					<p>
						2.3.3. Het Materiaal omvat geen laster, belediging,
						bedreigingen of oproepen tot geweld;
					</p>
					<p>
						2.4. Bij het toevoegen van het Materiaal staat de
						leerkracht Mijntaken.com toe om het Materiaal voor welk
						doel dan ook te gebruiken, ook voor commerciële
						doeleinden. Mijntaken.com is bevoegd dit recht aan
						derden over te dragen.
					</p>
				</div>
				<p className={termStyles.termHeader}>3. Auteursrechten</p>
				<div className={termStyles.termText}>
					<p>
						3.1. Het Materiaal dat op de Website voor de Gebruiker
						beschikbaar is, is het intellectuele eigendom van
						Mijntaken.com waarvoor het copyright, alle eigendoms- en
						niet-eigendomsrechten van Mijntaken.com, verschaft door
						de wetgeving van Georgië, geldt.
					</p>
					<p>
						3.2. Het gebruik van het materiaal van Mijntaken.com is
						alleen toegestaan op de Website, voor niet-commerciële
						doeleinden.
					</p>
					<p>
						3.3. De Gebruiker heeft niet het recht om het materiaal
						op de Website te reproduceren, publiceren, wijzigen,
						over te dragen aan derden of er gebruik van te maken in
						welke vorm dan ook zonder voorafgaande toestemming van
						Mijntaken.com.
					</p>
				</div>
				<p className={termStyles.termHeader}>4. Verantwoordelijkheid</p>
				<div className={termStyles.termText}>
					<p>
						4.1. Mijntaken.com is niet verantwoordelijk voor de
						schade die veroorzaakt wordt door een vertraging van de
						Website of van een individuele component ervan of door
						de schorsing van de Website.
					</p>
					<p>
						4.2. Mijntaken.com is niet verantwoordelijk voor de
						schade die veroorzaakt wordt door het gebruik van de
						Website door de Gebruiker.
					</p>
					<p>
						4.3. De Gebruiker is verantwoordelijk voor de eventuele
						schade die hij/zij veroorzaakt door het gebruik van de
						Website.
					</p>
					<p>
						4.4. Als de overtreding van deze regels plaatsvindt,
						heeft Mijntaken.com het recht om voor de Gebruiker het
						gebruik van de Website te beperken, en zijn/haar
						registratie te annuleren.
					</p>
					<p>
						4.5. In het geval van het optreden van artikel 4.4.
						verliest Mijntaken.com geen enkele van haar rechten
						onder deze regels van de Algemene Voorwarden.
					</p>
					<p>
						4.6. Het optreden van artikel 4.4. ontslaat de Gebruiker
						niet van zijn verplichtingen, inclusief
						aansprakelijkheid voor schade. Het optreden van artikel
						4.4. staat de Gebruiker niet toe om enige
						schadevergoeding te eisen, die veroorzaakt kan worden
						door het gebruik maken van het in artikel 4.4. voorziene
						recht.
					</p>
				</div>
				<p className={termStyles.termHeader}>5. Privacy Statement</p>
				<div className={termStyles.termText}>
					<p>
						5.1. Door de Website te gebruiken, bevestigt de
						gebruiker dat hij/zij bekend is met en akkoord gaat met
						de praktijk van het verzamelen van persoonlijke
						gegevens, die gedefinieerd wordt in het Privacy
						Statement.
					</p>
					<p>
						5.2. Het Privacy Statement van onze Website maakt deel
						uit van deze Algemene Voorwaarden en alle regels in deze
						Algemene Voorwaarden zijn er volledig op van toepassing.
					</p>
				</div>
				<p className={termStyles.termHeader}>6. Vergoedingen</p>
				<div className={termStyles.termText}>
					<p>
						6.1. De Gebruiker moet alle schade aan Mijntaken.com
						vergoeden die veroorzaakt zijn door het gebruik van de
						Website door hem/haar en/of het niet naleven of van
						onjuiste naleving van de regels van deze Algemene
						Voorwaarden.
					</p>
				</div>
				<p className={termStyles.termHeader}>
					7. Wijzigingen algemene voorwaarden
				</p>
				<div className={termStyles.termText}>
					<p>
						7.1. Mijntaken.com is gerechtigd te allen tijde
						wijzigingen en/of aanvullingen aan te brengen aan de
						Algemene Voorwaarden of het Privacy Statement zonder
						voorafgaande kennisgeving aan de Gebruikers.
					</p>
				</div>
				<p className={termStyles.termHeader}>
					8. Aanvullende voorwaarden
				</p>
				<div className={termStyles.termText}>
					<p>
						8.1. De regels in deze Algemene Voorwaarden zijn
						verklaard door de wetgeving van Georgië en elk geschil
						dat voortvloeit uit het gebruik van de Website moet
						behandeld worden door de rechtbank van Tbilisi.
					</p>
					<p>
						8.2. De Website is bedoeld voor het gebruik op het
						grondgebied van het Koninkrijk der Nederlanden en het
						Koninkrijk België en de Gebruiker is verantwoordelijk
						voor het naleven van de lokale wetgeving en is
						verantwoordelijk voor alle schade veroorzaakt door de
						overtreding van deze wetgeving die ontstaan is bij het
						gebruik van de Website door hem/haar. <br /> Als de
						Gebruiker de Website gebruikt buiten dit grondgebied,
						dan is hij/zij verantwoordelijk voor de naleving van
						lokale wetgeving en eventuele schade veroorzaakt door
						schending ervan.
					</p>
					<p>
						8.3. Ongeldigheid en/of onmogelijkheid van uitvoering
						van individuele bepalingen van deze Algemene Voorwaarden
						heeft geen invloed op de rest van de bepalingen van deze
						Algemene Voorwaarden. De niet-afdwingbare regel of
						bepaling zal worden vervangen door de afdwingbare regel
						of bepaling, die het dichtst bij het doel van een
						niet-afdwingbare regel of bepaling ligt.
					</p>
				</div>
			</div>
		</>
	);
};
