import { UserAction } from "@app/models/user-action";
import { inject } from "@app/modules";
import validateSchema from "@app/utils/validate-schema";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import { IUserAction } from "./helper-schemas";
import stringify from "json-stable-stringify";
import {
	IAPOSTUserAction,
	IRGETUserAction,
	IRPOSTUserAction,
	RGETUserActionSchema,
	RPOSTUserActionSchema,
} from "./validators";

export class UserActionsController {
	private readonly Request: IRequest;

	private readonly _UserActionModel = inject("UserActionModel");
	private readonly assertAndGetClassroomsUser = inject(
		"assertAndGetClassroomsUser"
	);

	private actionPromises = collmise<number, IUserAction, UserAction>({
		findCachedData: userId => this._UserActionModel.findByIdSync(userId),
		dataTransformer: data => this._UserActionModel.loadOneSync(data),
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	async getUserAction(loadFresh = false): Promise<UserAction> {
		const user = this.assertAndGetClassroomsUser();
		return this.actionPromises
			.on(user.id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/user-actions").then(
					(data: IRGETUserAction): IUserAction => {
						if (data) {
							return validateSchema(data, RGETUserActionSchema);
						}
						return {
							userId: user.id,
							actions: {},
							createdAt: new Date(),
							updatedAt: new Date(),
						};
					}
				)
			);
	}

	async performUserAction(
		args: IAPOSTUserAction,
		skipIfSame = false
	): Promise<UserAction> {
		const user = this.assertAndGetClassroomsUser();
		const existing = this._UserActionModel.findByIdSync(user.id);
		if (
			skipIfSame &&
			existing &&
			existing.actions.hasOwnProperty(args.name) &&
			stringify(existing.actions[args.name]) === stringify(args.data)
		) {
			return existing;
		}
		return this.Request.send("POST", "/api/user-actions/", args, null, {
			responseSchema: RPOSTUserActionSchema,
		}).then((data: IRPOSTUserAction) => {
			return this._UserActionModel.loadOneSync(data);
		});
	}

	async logVisit(): Promise<void> {
		this.Request.send("POST", "/api/user-actions/visit");
	}

	sendTextAfterGrantIsApproved = () => {
		this.Request.send("POST", "/api/user-actions/grant-information");
	};
}
