import { SchoolStructure } from "@app/models/school-structure";
import { inject } from "@app/modules";
import { collmise } from "collmise";
import { IRequest } from "../../requests";
import {
	IAGETSchoolStructure,
	IRGETSchoolStructure,
	RGETSchoolStructureSchema,
} from "./validators";

export class SchoolStructureController {
	private readonly Request: IRequest;

	private readonly _SchoolStructureModel = inject("SchoolStructureModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	private structurePromises = collmise<
		number,
		IRGETSchoolStructure,
		SchoolStructure
	>({
		findCachedData: schoolId =>
			this._SchoolStructureModel.findBySchool(schoolId),
		dataTransformer: data => this._SchoolStructureModel.loadOneSync(data),
	});

	getForSchool = async (
		schoolId: number,
		loadFresh?: boolean
	): Promise<SchoolStructure> => {
		return this.structurePromises
			.on(schoolId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send<IRGETSchoolStructure, IAGETSchoolStructure>(
					"GET",
					"/api/schools/:schoolId/structure",
					{ schoolId },
					null,
					{
						responseSchema: RGETSchoolStructureSchema,
					}
				)
			);
	};
}
