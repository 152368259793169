import Joi from "@app/utils/joi";
import { markKeysForbidden, UserType, UserTypes } from "../helper-schemas";
import {
	IRCoursesUser,
	IUserEssentials,
	RCoursesUserSchema,
	UserEssentialsSchema,
} from "../users/helper-schemas";
import {
	AuthMetaDataSchema,
	IAuthMetaData,
	ITokens,
	TokensSchema,
} from "./helper-schemas";

export const APOSTLoginSchema = Joi.object().keys({
	mobile: Joi.string()
		.allow(null)
		.required(),
	mail: Joi.string()
		.allow(null)
		.required(),
	password: Joi.string().required(),
	authMetaData: AuthMetaDataSchema,
});

export interface IAPOSTLogin {
	mobile: string | null;
	mail: string | null;
	password: string;
	authMetaData?: IAuthMetaData;
}

export const RPOSTLoginSchema = UserEssentialsSchema.keys({
	classroomTokens: TokensSchema.required(),
	courseTokens: TokensSchema.required(),
	coursesUserData: RCoursesUserSchema.required(),
});
export interface IRPOSTLogin extends IUserEssentials {
	classroomTokens: ITokens;
	courseTokens: ITokens;
	coursesUserData: IRCoursesUser;
}

/////

export const RPOSTCoursesLoginSchema = Joi.object({
	courseTokens: TokensSchema.required(),
	coursesUserData: RCoursesUserSchema.required(),
});
export interface IRPOSTCoursesLogin {
	courseTokens: ITokens;
	coursesUserData: IRCoursesUser;
}

///

export const APOSTCoursesRegisterUserSchema = Joi.object({
	userId: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	type: Joi.string()
		.valid(...UserTypes)
		.required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	username: Joi.string().required(),
	mobile: Joi.string().allow(null),
	mail: Joi.string().allow(null),
	password: Joi.string().required(),
});
export interface IAPOSTCoursesRegisterUser {
	userId: number;
	murtskuId: number | null;
	type: UserType;
	firstname: string;
	lastname: string;
	username: string;
	mobile?: string | null;
	password: string;
	mail?: string | null;
}

export const RPOSTCoursesRegisterUserSchema = RPOSTCoursesLoginSchema;
export type IRPOSTCoursesRegisterUser = IRPOSTLogin;

////

export const APUTLoginByAccessTokenSchema = Joi.object().keys({
	murtskuUserId: Joi.number().required(),
	token: Joi.string().required(),
	coursesToken: Joi.string().required(),
});
export interface IAPUTLoginByAccessToken {
	murtskuUserId: number;
	token: string;
	coursesToken: string;
}

export const RPUTLoginByAccessTokenSchema = RPOSTLoginSchema;
export type IRPUTLoginByAccessToken = IRPOSTLogin;

/////

export const APOSTLogoutSchema = Joi.object().keys({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
	coursesUserId: Joi.number().required(),
	coursesRefreshToken: Joi.string().required(),
	coursesAccessToken: Joi.string().required(),
});

export interface IAPOSTLogout {
	userId: number;
	refreshToken: string;
	coursesUserId: number;
	coursesRefreshToken: string;
	coursesAccessToken: string;
}
export type IRPOSTLogout = void;

/////

export const APUTAccessTokensSchema = Joi.object().keys({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
});
export interface IAPUTAccessTokens {
	userId: number;
	refreshToken: string;
}

export const RPUTAccessTokensSchema = RPOSTLoginSchema.fork(
	["courseTokens", "coursesUserData"],
	markKeysForbidden
);
export type IRPUTAccessTokens = Omit<
	IRPOSTLogin,
	"courseTokens" | "coursesUserData"
>;

///

export const APOSTRegisterUserSchema = Joi.object({
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	mobile: Joi.string().allow(null),
	password: Joi.string()
		.min(5)
		.required(),
	country: Joi.string().allow(null),
	language: Joi.string().allow(null),
	mail: Joi.string()
		.email({ tlds: { allow: false } })
		.allow(null),
	registrationOrigin: Joi.string()
		.allow(null)
		.required(),
	authMetaData: AuthMetaDataSchema,
	hasAgreedOnTerms: Joi.boolean(),
	childrenIds: Joi.array()
		.items(Joi.number())
		.optional(),
});

export interface IAPOSTRegisterUser {
	firstname: string;
	lastname: string;
	mobile?: string | null;
	password: string;
	country?: string | null;
	language?: string | null;
	registrationOrigin: string | null;
	authMetaData?: IAuthMetaData;
	mail?: string | null;
	hasAgreedOnTerms?: boolean;
	childrenIds?: number[];
}

export const APOSTRegisterStudentSchema = APOSTRegisterUserSchema;
export type IAPOSTRegisterStudent = IAPOSTRegisterUser;

export const RPOSTRegisterUserSchema = RPOSTLoginSchema;
export type IRPOSTRegisterUser = IRPOSTLogin;

///

export const APOSTRegisterSchoolSchema = Joi.object({
	city: Joi.string().required(),
	school: Joi.string().required(),
	mail: Joi.string()
		.min(5)
		.required(),
	mobile: Joi.string().required(),
	registrationOrigin: Joi.string()
		.allow(null)
		.required(),
});

export interface IAPOSTRegisterSchool {
	city: string;
	school: string;
	mail: string;
	mobile: string;
	registrationOrigin: string | null;
}

export const RPOSTRegisterSchoolSchema = RPOSTLoginSchema;
export type IRPOSTRegisterSchool = IRPOSTLogin;

///

export const APUTPasswordSchema = Joi.object({
	currentPassword: Joi.string().required(),
	newPassword: Joi.string()
		.min(5)
		.required(),
});
export interface IAPUTPassword {
	currentPassword: string;
	newPassword: string;
}

///

export type IAPUTCoursesAccessTokens = IAPUTAccessTokens;
export const RPUTCoursesAccessTokensSchema = TokensSchema.keys({
	userData: RCoursesUserSchema.required(),
});
export interface IRPUTCoursesAccessTokens extends IAPUTAccessTokens {
	userData: IRCoursesUser;
}

///

export const APOSTConfirmationCodeSchema = Joi.object({
	mobile: Joi.string().allow(null),
	mail: Joi.string().allow(null),
	text: Joi.string().allow(null),
	attachmentPath: Joi.string(),
	attachmentFilename: Joi.string(),
	senderMail: Joi.string(),
	subject: Joi.string(),
	isNotRegistered: Joi.boolean(),
	userId: Joi.number().allow(null),
});

export interface IAPOSTConfirmationCode {
	mobile?: string | null;
	mail?: string | null;
	text?: string | null;
	attachmentPath?: string | undefined;
	attachmentFilename?: string | undefined;
	senderMail?: string | undefined;
	subject?: string | undefined;
	isNotRegistered?: boolean | undefined;
	userId?: number | undefined;
}
///

export const APOSTForgotPasswordSchema = Joi.object({
	mobile: Joi.string().allow(null),
	mail: Joi.string().allow(null),
	code: Joi.string().required(),
	newPassword: Joi.string()
		.min(5)
		.required(),
	authMetaData: AuthMetaDataSchema,
});
export interface IAPOSTForgotPassword {
	mobile?: string | null;
	mail?: string | null;
	code: string;
	newPassword: string;
	authMetaData?: IAuthMetaData;
}

export const RPOSTForgotPasswordSchema = RPOSTLoginSchema;
export type IRPOSTForgotPassword = IRPOSTLogin;

///

export const APOSTScoolRegisterSchema = Joi.object({
	city: Joi.string().required(),
	school: Joi.string().required(),
	mail: Joi.string().required(),
	mobile: Joi.string().required(),
});
export interface IAPOSTScoolRegister {
	city: string;
	school: string;
	mail: string;
	mobile: string;
}

///

export const AGETConfrimationCodeSchema = Joi.object({
	mail: Joi.string(),
	mobile: Joi.string(),
	code: Joi.number().required(),
});

export interface IAGETConfrimationCode {
	mail?: string;
	mobile?: string;
	code: number;
}

export interface IRGETConfrimationCode {
	isCorrectCode: boolean;
}

export const RGETConfrimationCodeSchema = Joi.object({
	isCorrectCode: Joi.boolean().required(),
});

export const APOSTCheckParentSchema = APOSTRegisterUserSchema;

export interface IAPOSTCheckParent {
	firstname: string;
	lastname: string;
	mobile?: string | null;
	password: string;
	country?: string | null;
	language?: string | null;
	registrationOrigin: string | null;
	authMetaData?: IAuthMetaData;
	mail?: string | null;
	hasAgreedOnTerms?: boolean;
	childrenIds?: number[];
}

export const IRGETCheckParentSchema = Joi.object({
	isValid: Joi.boolean(),
});

export interface IRGETCheckParent {
	isValid: boolean;
}
