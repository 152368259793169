import Joi from "@app/utils/joi";
import { ItemType } from "../folders/helper-schemas";
import {
	TestTypeSettingsSchema,
	ITestTypeSettings,
} from "../test-types/helper-schemas";
import {
	UserAnswerSchema,
	IUserAnswer,
} from "@tests-core/schemas/questions/contnets/ans-schema";
import { ObjectId } from "@app/utils/generics";

export const TestSettingContentIdSchema = Joi.object({
	type: Joi.number()
		.valid(ItemType.question, ItemType.card, ItemType.text)
		.required(),
	id: Joi.objectId().required(),
});
export interface ITestSettingContentId {
	type: ItemType.question | ItemType.card | ItemType.text;
	id: ObjectId;
}

export const TestSettingsSchema = Joi.object({
	contentIds: Joi.array().items(TestSettingContentIdSchema),
	isContentSorted: Joi.boolean(),
});
export interface ITestSettings {
	contentIds?: ITestSettingContentId[];
	isContentSorted?: boolean | undefined;
}

export const RTestSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	originalCourseId: Joi.objectId(),
	description: Joi.string().allow(""),
	testTypeId: Joi.objectId(),
	testTypeSettings: TestTypeSettingsSchema,
	settings: TestSettingsSchema,
	author: Joi.number().required(),
});
export interface IRTest {
	_id: ObjectId;
	name: string;
	originalCourseId: ObjectId;
	description?: string;
	testTypeId?: ObjectId;
	testTypeSettings?: ITestTypeSettings;
	settings?: ITestSettings;
	author: number;
}

///

export const UserTestQuestionInfoSchema = Joi.object({
	id: Joi.objectId().required(),
	userAnswer: UserAnswerSchema.required(),
	numAttempts: Joi.number().required(),
	credit: Joi.number().required(),
	answeredAt: Joi.date().required(),
});

export interface IUserTestQuestionInfo {
	id: ObjectId;
	credit: number;
	userAnswer: IUserAnswer;
	numAttempts: number;
	answeredAt: Date;
}

const QuestionItemAssessmentSchema = Joi.object({
	credit: Joi.number().required(),
	lastAssessedBy: Joi.number()
		.integer()
		.required(),
	updatedAt: Joi.date().required(),
});

export const QuestionItemsAssessmentExtendedSchema = Joi.object({
	items: Joi.object().pattern(
		/[\d]+/,
		QuestionItemAssessmentSchema.required()
	),
});

export const EditorAssessmentsSchema = Joi.object({
	assessmentProgress: Joi.number().required(),
	updatedAt: Joi.date().required(),
	questions: Joi.object().pattern(
		/[\da-f]+/,
		QuestionItemsAssessmentExtendedSchema.keys({
			credit: Joi.number(),
			assessmentProgress: Joi.number().required(),
		}).required()
	),
});

export interface IEditorAssessments {
	assessmentProgress: number;
	updatedAt: Date;
	questions: {
		[questionId: string]:
			| {
					credit?: number;
					assessmentProgress: number;
					items: {
						[itemId: number]:
							| {
									credit: number;
									lastAssessedBy: number;
									updatedAt: Date;
							  }
							| undefined;
					};
			  }
			| undefined;
	};
}

export const UserTestSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testId: Joi.objectId().required(),
	attempt: Joi.number()
		.integer()
		.required(),
	questions: Joi.array()
		.items(UserTestQuestionInfoSchema)
		.required(),
	editorAssessments: EditorAssessmentsSchema,
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IUserTest {
	_id: ObjectId;
	userId: number;
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	attempt: number;
	questions: IUserTestQuestionInfo[];
	editorAssessments?: IEditorAssessments;
	createdAt: Date;
	updatedAt: Date;
}

///
export const NumQuestionsSettingsSchema = Joi.object({
	total: Joi.number()
		.integer()
		.greater(0),
	byTaskTypes: Joi.object().pattern(
		/^[a-f\d]{24}$/i,
		Joi.alternatives(
			Joi.object({
				questions: Joi.number().required(),
			}),
			Joi.object({
				texts: Joi.number().required(),
			})
		)
	),
});
/** Used as an argument for fetching questions */
export interface INumQuestionsSettings {
	total?: number | undefined;
	byTaskTypes?: {
		[x: string]:
			| {
					questions: number;
			  }
			| {
					texts: number;
			  }
			| undefined;
	};
}
