import React, {
	useState,
	useMemo,
	useCallback,
	useEffect,
	createRef,
} from "react";
import { ObjectId } from "@app/utils/generics";
import { useSubject } from "@app/hooks/subjects";
import { useGradeById } from "@app/hooks/grades";
import styles from "./styles/index.module.css";
import assignmentContentStyles from "./styles/assignment-content-styles.module.css";
import { TargetConceptSelectsWrapper, ComplexTopicSelect } from "./select";
import {
	IComplexAssignmentStatement,
	ComplexAssignmentStatementType,
	IComplexAssignmentFileStatement,
} from "@app/api/assignments/complex/helper-schemas";
import {
	AssignmentContentTypes,
	AssignmentContentWrapper,
} from "./assignmentContent";
import { ContentEditableEvent } from "react-contenteditable";
import { inject } from "@app/modules";
import { History } from "history";
import { removeKeys } from "@app/utils/common";
import {
	getHTMLElementCoords,
	animateWindowScroll,
} from "@app/common-javascript";
import { refactorValueAfterLinkChange } from "./helper-functions";
import { getJoiErrorItems, JoiValidationDetail } from "@app/utils/joi-errors";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { useBoolean } from "@app/hooks/general";
import { CloseIcon } from "@app/icons";
import {
	IAPOSTComplexAssignment,
	APOSTComplexAssignmentSchema,
} from "@app/api/assignments/complex/validators";

interface CreateComplexAssignmentDraft {
	timeStamp: string;
	state: CreateComplexAssignmentsState;
	formatedLinks: { link: string; label: string }[];
}
interface CreateComplexAssignmentsProps {
	originalCourseId: ObjectId;
	grade: number;
	subjectId: ObjectId;
	history: History;
}

interface CreateComplexAssignmentsState {
	originalCourseId: ObjectId;
	targetConceptIds: string[];
	topicId: string;
	step: Steps;
	resultIds: ObjectId[];
	statement: IComplexAssignmentStatement[];
	coreTasks: IComplexAssignmentStatement[];
	practicalSuggestions?: IComplexAssignmentStatement[];
}

enum Steps {
	fisrtStep = 1,
	secondStep = 2,
	thirdStep = 3,
}

export enum ComplexAssignmentContentMode {
	edit = "EDIT",
	preview = "PREVIEW",
	view = "VIEW",
}
export const CreateComplexAssignments: React.FC<CreateComplexAssignmentsProps> = ({
	grade,
	originalCourseId,
	subjectId,
	history,
}) => {
	const [state, setState] = useState<CreateComplexAssignmentsState | null>(
		null
	);
	const [formatedLinks, setFormatedLinks] = useState<
		{ link: string; label: string }[]
	>([]);
	const [editOrPreviewMode, setEditOrPreviewMode] = useState<
		ComplexAssignmentContentMode
	>(ComplexAssignmentContentMode.edit);

	const [scrollToTopicsSelect, setScrollToTopicsSelect] = useState<boolean>(
		false
	);
	const [errors, setErrors] = useState<string | null>(null);
	const { value: hasSubmitted, setTrue: setHasSubmitted } = useBoolean(false);
	const topicsSelectRef = createRef<HTMLDivElement>();

	useEffect(() => {
		if (topicsSelectRef.current && scrollToTopicsSelect) {
			const { top } = getHTMLElementCoords(topicsSelectRef.current);
			animateWindowScroll(top, 500);
			setScrollToTopicsSelect(false);
		}
	}, [scrollToTopicsSelect, topicsSelectRef]);

	useEffect(() => {
		const localStorageKey = `complex_draft_${originalCourseId}`;
		if (!state) {
			const defaultState: CreateComplexAssignmentsState = {
				originalCourseId,
				resultIds: [],
				targetConceptIds: [""],
				topicId: "",
				step: Steps.fisrtStep,
				coreTasks: [
					{
						[ComplexAssignmentStatementType.text]: {
							type: ComplexAssignmentStatementType.text,
							text: "",
						},
					},
					{
						[ComplexAssignmentStatementType.text]: {
							type: ComplexAssignmentStatementType.text,
							text: "",
						},
					},
				],
				statement: [
					{
						[ComplexAssignmentStatementType.text]: {
							type: ComplexAssignmentStatementType.text,
							text: "",
						},
					},
				],
				practicalSuggestions: [
					{
						[ComplexAssignmentStatementType.text]: {
							type: ComplexAssignmentStatementType.text,
							text: "",
						},
					},
				],
			};
			if (window.localStorage.getItem(localStorageKey)) {
				const draft = JSON.parse(
					window.localStorage.getItem(localStorageKey) as any
				) as CreateComplexAssignmentDraft;
				if (new Date(draft.timeStamp) < new Date()) {
					window.localStorage.removeItem(localStorageKey);
					setState(defaultState);
					return;
				}
				setFormatedLinks(draft.formatedLinks);
				setState(draft.state);
			} else {
				setState(defaultState);
			}
		} else {
			const timeStamp = new Date();
			timeStamp.setSeconds(timeStamp.getSeconds() + 12);
			window.localStorage.setItem(
				localStorageKey,
				JSON.stringify({
					state: state,
					timeStamp: timeStamp.toString(),
					formatedLinks,
				})
			);
		}
	}, [formatedLinks, originalCourseId, state]);

	const handleTargetConceptsChange = useCallback(
		(targetConceptIds: ObjectId[], resultIds: ObjectId[]) => {
			if (!state) return;
			setState({ ...state, targetConceptIds, resultIds });
		},
		[state]
	);

	const handleComplexTopicChange = useCallback((topicId: string) => {
		setState(cur => (cur ? { ...cur, topicId } : cur));
	}, []);

	const handleAddLink = useCallback(
		(formatedLink: {
			link: string;
			label: string;
			activeChanges: boolean;
		}) => {
			setFormatedLinks([...formatedLinks, formatedLink]);
		},
		[formatedLinks]
	);

	const handleAssignmentContentTextChange = useCallback(
		(
			event: ContentEditableEvent,
			id: number,
			type: AssignmentContentTypes
		) => {
			if (!state) return;
			setState({
				...state,
				[type]: state[type]!.map((e, i) => {
					if (i !== id) return e;
					return {
						...e,
						[ComplexAssignmentStatementType.text]: {
							...e[ComplexAssignmentStatementType.text],
							text: event.target.value,
						},
					};
				}),
			});
		},
		[state]
	);

	const addAssignmentContentText = useCallback(
		(type: AssignmentContentTypes) => {
			if (!state) return;
			setState({
				...state,
				[type]: state[type]
					? [
							...state[type]!,
							{
								[ComplexAssignmentStatementType.text]: {
									type: ComplexAssignmentStatementType.text,
									text: "",
								},
							},
					  ]
					: [
							{
								[ComplexAssignmentStatementType.text]: {
									type: ComplexAssignmentStatementType.text,
									text: "",
								},
							},
					  ],
			});
		},
		[state]
	);

	const handleEditLink = useCallback(
		(
			formatedLink: {
				link: string;
				label: string;
			},
			index: number,
			id: number,
			type: AssignmentContentTypes
		) => {
			const newFormated = formatedLinks.map((e, i) =>
				i === index ? formatedLink : e
			);
			setFormatedLinks(newFormated);
			handleAssignmentContentTextChange(
				{
					target: {
						value: refactorValueAfterLinkChange(
							newFormated,
							state?.statement[id][
								ComplexAssignmentStatementType.text
							]?.text || ""
						),
					},
				} as any,
				id,
				type
			);
		},
		[formatedLinks, handleAssignmentContentTextChange, state]
	);

	const deleteAssignmentContentText = useCallback(
		(index: number, type: AssignmentContentTypes) => {
			if (!state) return;
			setState({
				...state,
				[type]: state[type]!.filter((e, i) => i !== index),
			});
		},
		[state]
	);

	const attachAsignmentContentFile = useCallback(
		(
			event: React.ChangeEvent<HTMLInputElement>,
			index: number,
			type: AssignmentContentTypes
		) => {
			if (!state) return;
			const formData = new FormData();
			if (!event.target.files) return;
			for (let i = 0; i < event.target.files.length; i += 1) {
				formData.append("files", event.target.files[i]);
			}
			inject("PhotosController")
				.uploadGeneric(formData as any)
				.then(data => {
					const files = data.files.map(e => {
						return {
							filename: e.fileOriginalName
								? e.fileOriginalName
								: "",
							url: e.fileFullUrl,
							type: ComplexAssignmentStatementType.file,
						};
					});
					setState({
						...state,
						[type]: state[type]!.map((e, i) => {
							if (i !== index) return e;
							return {
								...e,
								[ComplexAssignmentStatementType.file]: e[
									ComplexAssignmentStatementType.file
								]
									? [
											...(e[
												ComplexAssignmentStatementType
													.file
											] || []),
											...files,
									  ]
									: [...files],
							};
						}),
					});
				});
		},
		[state]
	);

	const deleteAssignmentContentFile = useCallback(
		(
			file: {
				name: string;
				path: string;
			},
			inputIndex: number,
			type: AssignmentContentTypes
		) => {
			if (!state) return;
			let filteredFiles:
				| IComplexAssignmentFileStatement[]
				| undefined = undefined;
			for (let i = 0; i < state[type]!.length; i++) {
				if (i === inputIndex) {
					filteredFiles = state[type]![i][
						ComplexAssignmentStatementType.file
					]!.filter(
						f => f.filename !== file.name && f.url !== file.path
					);
				}
			}
			setState({
				...state,
				[type]: state[type]?.map((e, i) =>
					i === inputIndex
						? {
								...e,
								[ComplexAssignmentStatementType.file]:
									filteredFiles && filteredFiles.length > 0
										? filteredFiles
										: undefined,
						  }
						: e
				),
			});
		},
		[state]
	);

	const handleEditOrPreviewModeChange = useCallback(
		editOrPreviewMode => {
			if (editOrPreviewMode === ComplexAssignmentContentMode.edit) {
				if (state) {
					const parsedData: IAPOSTComplexAssignment = parseComplexAssignemntContent(
						{
							...removeKeys(state, "resultIds", "step"),
						}
					);
					const validationErrors = validateAssignmentContentOnPreview(
						parsedData
					);
					if (validationErrors) {
						setErrors(validationErrors);
						return;
					} else {
						setState(filterComplexAssignemntContentTexts(state));
					}
				}
			}
			setEditOrPreviewMode(
				editOrPreviewMode === ComplexAssignmentContentMode.preview
					? ComplexAssignmentContentMode.edit
					: ComplexAssignmentContentMode.preview
			);
			window.scrollTo(0, 0);
		},
		[state]
	);

	const handleAssignemntContentSubmission = useCallback(() => {
		if (!state) return;
		const parsedData = parseComplexAssignemntContent({
			...removeKeys(state, "step"),
		});
		inject("ComplexAssignmentController")
			.createAssignment(parsedData)
			.then(data => {
				setHasSubmitted();
			})
			.catch(e => {
				const joiErrors = getJoiErrorItems(e);
				if (joiErrors) {
					setErrors(
						getComplexAssignmentSubmissionErrorMessage(
							joiErrors[0]
						) || "დაფიქსირდა შეცდომა"
					);
				}
			});
	}, [setHasSubmitted, state]);

	const isNextButtonDisabled = useMemo(() => {
		if (!state) return false;
		if (state.step === Steps.fisrtStep) {
			return (
				state.targetConceptIds.length >= 1 &&
				state.targetConceptIds[0] === ""
			);
		}
		if (state.step === Steps.secondStep) {
			return state.topicId === "";
		}
		return false;
	}, [state]);

	const handleNext = useCallback(() => {
		if (!state || state.step === Steps.thirdStep) return;
		if (state.step === Steps.fisrtStep) {
			setScrollToTopicsSelect(true);
		}
		setState({ ...state, step: state.step + 1 });
	}, [state]);

	const renderContent = useMemo(() => {
		if (!state) return null;
		switch (state.step) {
			case Steps.fisrtStep:
				return (
					<React.Fragment>
						<SubjectDescription
							gradeId={grade}
							subjectId={subjectId}
						/>
						<TargetConceptSelectsWrapper
							conceptResults={state.resultIds}
							originalCourseId={originalCourseId}
							chosenConcepts={state.targetConceptIds}
							onChange={handleTargetConceptsChange}
						/>
						<NextButton
							isDisabled={isNextButtonDisabled}
							onClick={handleNext}
						/>
					</React.Fragment>
				);
			case Steps.secondStep:
				return (
					<React.Fragment>
						<SubjectDescription
							gradeId={grade}
							subjectId={subjectId}
						/>
						<TargetConceptSelectsWrapper
							conceptResults={state.resultIds}
							originalCourseId={originalCourseId}
							chosenConcepts={state.targetConceptIds}
							onChange={handleTargetConceptsChange}
						/>
						<div ref={topicsSelectRef}>
							<ComplexTopicSelect
								originalCourseId={originalCourseId}
								onChange={handleComplexTopicChange}
								value={state.topicId}
								targetConcepts={state.targetConceptIds}
								autoFocus={true}
							/>
						</div>
						<NextButton
							isDisabled={isNextButtonDisabled}
							onClick={handleNext}
						/>
					</React.Fragment>
				);
			case Steps.thirdStep:
				return (
					<AssignmentContentWrapper
						handleAssignmentContentTextChange={
							handleAssignmentContentTextChange
						}
						statement={state.statement}
						coreTasks={state.coreTasks}
						practicalSuggestions={state.practicalSuggestions}
						addAssignmentContent={addAssignmentContentText}
						deleteAssignmentContent={deleteAssignmentContentText}
						originalCourseId={originalCourseId}
						chosenTopics={state.targetConceptIds}
						handleTargetConceptsChange={handleTargetConceptsChange}
						handleComplexTopicChange={handleComplexTopicChange}
						topicId={state.topicId}
						targetConceptIds={state.targetConceptIds}
						resultIds={state.resultIds}
						formatedLinks={formatedLinks}
						attachAsignmentContentFile={attachAsignmentContentFile}
						handleEditOrPreviewModeChange={
							handleEditOrPreviewModeChange
						}
						editOrPreviewMode={editOrPreviewMode}
						handleAddFormatedLink={handleAddLink}
						handleAssignemntContentSubmission={
							handleAssignemntContentSubmission
						}
						handleEditLink={handleEditLink}
						deleteAssignmentContentFile={
							deleteAssignmentContentFile
						}
						// isNextButtonDisabled={isNextButtonDisabled}
						// handleNext={handleNext}
					/>
				);
			default:
				return null;
		}
	}, [
		state,
		grade,
		subjectId,
		originalCourseId,
		handleTargetConceptsChange,
		isNextButtonDisabled,
		handleNext,
		topicsSelectRef,
		handleComplexTopicChange,
		handleAssignmentContentTextChange,
		addAssignmentContentText,
		deleteAssignmentContentText,
		formatedLinks,
		attachAsignmentContentFile,
		handleEditOrPreviewModeChange,
		editOrPreviewMode,
		handleAddLink,
		handleAssignemntContentSubmission,
		handleEditLink,
		deleteAssignmentContentFile,
	]);
	return (
		<div>
			{errors && (
				<Popup>
					<PopupContentWithClose
						onClose={() => setErrors(null)}
						icon={<CloseIcon />}
						className={assignmentContentStyles.popup}
					>
						<div className={styles.countError}>{errors}</div>
					</PopupContentWithClose>
				</Popup>
			)}
			{hasSubmitted && (
				<Popup>
					<PopupContentWithClose
						onClose={() => history.goBack()}
						icon={<CloseIcon />}
						className={assignmentContentStyles.popup}
					>
						<div className={styles.countError}>
							თქვენი დავალება შენახულია და დამატებულია თქვენი
							კომპლექსური დავალებების ჩამონათვალში, ხოლო საჯარო
							გახდება ჩვენი გუნდის გადამოწმების შემდეგ.
						</div>
					</PopupContentWithClose>
				</Popup>
			)}
			{renderContent}
		</div>
	);
};

const NextButton: React.FC<{
	onClick: () => void;
	isDisabled: boolean;
}> = React.memo(({ isDisabled, onClick }) => (
	<div className={styles.nextButtonContainer}>
		<div
			onClick={() => (isDisabled ? undefined : onClick())}
			className={styles.nextButton}
			style={
				isDisabled
					? {
							backgroundColor: "#e8e8e8",
							cursor: "not-allowed",
					  }
					: undefined
			}
		>
			შემდეგი
		</div>
	</div>
));

const SubjectDescription: React.FC<{
	subjectId: ObjectId;
	gradeId: number;
}> = React.memo(({ gradeId, subjectId }) => {
	const subject = useSubject(subjectId);
	const grade = useGradeById(gradeId);
	if (subject.hasFoundError || !subject.doc || !grade.doc) return null;

	return (
		<div className={styles.flexCenterContainer}>
			<div className={styles.subjectDescription}>
				<div
					className={styles.subjectAvatar}
					style={{
						backgroundImage: subject.doc.photo
							? `url(${subject.doc.photo})`
							: undefined,
					}}
				/>
				<div className={styles.direction}>
					<div className={styles.subjectName}>{subject.doc.name}</div>
					<div className={styles.subjectGrade}>
						{grade.doc.getName()}
					</div>
				</div>
			</div>
		</div>
	);
});

const parseComplexAssignemntContent = (
	data: Omit<
		CreateComplexAssignmentsState,
		"formatedLinks" | "step" | "resultIds"
	>
) => {
	return {
		...removeKeys(data, "coreTasks", "practicalSuggestions"),
		targetConceptIds: data.targetConceptIds.filter(e => e !== ""),
		statement: data.statement.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
		coreTasks: data.coreTasks.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
		practicalSuggestions: data.practicalSuggestions?.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
	};
};

const filterComplexAssignemntContentTexts = (
	data: CreateComplexAssignmentsState
): CreateComplexAssignmentsState => {
	return {
		...data,
		statement: data.statement.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
		coreTasks: data.coreTasks.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
		practicalSuggestions: data.practicalSuggestions?.filter(
			e =>
				e[ComplexAssignmentStatementType.file] ||
				(e[ComplexAssignmentStatementType.text] &&
					e[ComplexAssignmentStatementType.text]?.text !== "")
		),
	};
};

const getComplexAssignmentSubmissionErrorMessage = (e: JoiValidationDetail) => {
	if (e.mainKey === AssignmentContentTypes.statement) {
		return "გთხოვთ, ჩაწეროთ დავალების პირობა.";
	} else if (e.mainKey === AssignmentContentTypes.coreTasks) {
		return 'გთხოვთ, ჩაწეროთ მინიმუმ ერთი კრიტერიუმი/მითითება გრაფაში "ნაშრომში ხაზგასმით წარმოაჩინეთ"';
	} else if (e.mainKey === "topicId") {
		return "გთხოვთ, მიუთითოთ საკითხი, რომელსაც ეხება დავალება.";
	}
};

const validateAssignmentContentOnPreview = (data: IAPOSTComplexAssignment) => {
	const validatedData = APOSTComplexAssignmentSchema.validate(data);
	const joiErrors =
		validatedData.error && getJoiErrorItems(validatedData.error);
	if (joiErrors) {
		return (
			getComplexAssignmentSubmissionErrorMessage(joiErrors[0]) ||
			"დაფიქსირდა შეცდომა"
		);
	}
};
