import LogOutComponent from "@app/components/widgets/LogOutComponent";
import { useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { getFormattedMessage } from "@app/utils/locale";
import { CreateCusto, buildCusto } from "custo";
import { toCustComponents } from "custo/lib/classes/helper-fns/transformations";
import { UnwrapDeepOptionalValue } from "custo/lib/components/providers";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Containers } from "./containers";
import { HeaderTheme } from "./interfaces";
import { HeaderDefaultLogo } from "./logo";
import { HeaderMenu } from "./menu";
import { HeaderProfileComponents, HeaderProfileElements } from "./profile";

export const [HeaderTree, HeaderProviders] = buildCusto({
	defaultValue: {
		components: toCustComponents({
			OuterContainer: Containers.OuterContainer,
			LeftContainer: Containers.LeftContainer,
			RightContainer: Containers.RightContainer,
			Logo: HeaderDefaultLogo,
			NextToLogo: () => null,
			profile: HeaderProfileComponents,
			Chat: () => null,
			Logout: LogOutComponent,
			BurgerMenu: HeaderMenu.BurgerMenu,
		}),
		elements: {
			ProfileImg: HeaderProfileElements.Img,
		},
		data: {
			profileImg: CreateCusto.Data(""),
			showWarningIcon: CreateCusto.hookOf.Data(() => {
				const user = useClassroomsUser();
				return useLocale() === "ka" && !!user && !user.isValidMobile;
			}),
			profileUpperTitle: CreateCusto.hookOf.Text(useUpperTitle),
			showWarningText: CreateCusto.hookOf.Data(() => {
				const loc = useLocation();
				return loc.pathname === "/";
			}),
			colors: CreateCusto.hookOf.Data(() =>
				useHeaderColorsByTheme("light")
			),
			useBurgerMenu: CreateCusto.hookOf.Data(() => {
				const { width } = useWindowSize();
				return width < 500;
			}),
			menuOptions: CreateCusto.Data<
				{
					value: any;
					label: any;
					onSelect?: () => void;
				}[]
			>([]),
		},
	},
});
export type HeaderValueType = UnwrapDeepOptionalValue<typeof HeaderProviders>;

export function useHeaderColorsByTheme(theme: HeaderTheme) {
	return useMemo(() => {
		if (theme === "light" || theme === "white") {
			return {
				background: theme === "light" ? "rgba(239, 241, 247)" : "white",
				text: "#5273e6",
				secondaryText: "#15171d",
				theme,
			};
		}
		return {
			background: "#5273e6",
			text: "white",
			secondaryText: "white",
			theme,
		};
	}, [theme]);
}

function useUpperTitle(): string {
	const user = useClassroomsUser();
	if (!user) return "";
	if (user.isHeadmaster()) {
		return getFormattedMessage("headmaster");
	}
	if (user.isStudent()) {
		return getFormattedMessage("student");
	}
	if (user.isTeacher()) {
		return getFormattedMessage("teacher");
	}
	if (user.isParent()) {
		return getFormattedMessage("parent");
	}
	if (user.isMainAdmin()) {
		return "Admin";
	}
	return "";
}
