import { UserType } from "@app/api/helper-schemas";
import { IRCoursesUser, IRUser } from "@app/api/users/helper-schemas";
import {
	CHANGE_ACTIVE_TYPE,
	LOAD_USER,
	USERDATA_CHANGE,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
} from "@app/redux/action-types";
import { UserActions } from "@app/redux/actions/user";
import {
	getAllUserTypesFromPermissions,
	getRandomUserTypeFromPermissions,
} from "@app/user/active-user-type";
import { deepEqual, removeKeys } from "@app/utils/common";
import {
	defaultSpecialActionKeyOfOtherTabsActions,
	defaultSpecialItemNameOfOtherTabsActions,
} from "m-model-common";

function getNewUserState(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	switch (action.type) {
		case USER_AUTHORIZATION_SUCCESS:
		case USERDATA_CHANGE:
			return normalizeActiveUserType({
				...state,
				loggedIn: true,
				userId: action.userData ? action.userData.id : state.userId!,
				coursesUserId: action.coursesUserData
					? action.coursesUserData.id
					: state.coursesUserId!,
				userData: action.userData ? action.userData : state.userData!,
				coursesUserData: action.coursesUserData
					? action.coursesUserData
					: state.coursesUserData!,
				activeUserType: undefined as any,
			});

		case LOAD_USER:
			return normalizeActiveUserType({
				...state,
				...(action.user as any),
				activeUserType: undefined as any,
			});
		case CHANGE_ACTIVE_TYPE:
			return normalizeActiveUserType(state, action.desiredType);
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

const normalizeActiveUserType = (
	state: IStateUser,
	desiredType: UserType | undefined = state.activeUserType
): IStateUser => {
	const newState = { ...state };
	const newType = getNewActiveUserType(state, desiredType);
	newState.activeUserType = newType as any;
	sessionStorage[ACTIVE_USER_TYPE_STORAGE_KEY] = newType;
	localStorage[ACTIVE_USER_TYPE_STORAGE_KEY] = newType;
	if (newState.activeUserType === state.activeUserType) {
		return state;
	}
	return newState;
};

export const ACTIVE_USER_TYPE_STORAGE_KEY = "active_user_type";

const getNewActiveUserType = (
	state: IStateUser,
	desired: UserType | undefined = sessionStorage[
		ACTIVE_USER_TYPE_STORAGE_KEY
	] || localStorage[ACTIVE_USER_TYPE_STORAGE_KEY]
): UserType | undefined => {
	if (!state.loggedIn) return undefined;
	const allTypes = getAllUserTypesFromPermissions(state.userData.permissions);
	if (desired && allTypes.includes(desired)) {
		return desired;
	}
	const current = state.activeUserType;
	if (current && allTypes.includes(current)) return current;
	return (
		getRandomUserTypeFromPermissions(state.userData.permissions) ??
		undefined
	);
};

export default function user(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	const newState = getNewUserState(state, action);
	const areNotEqual =
		newState !== state &&
		!deepEqual(
			removeKeys(newState, "activeUserType"),
			removeKeys(state, "activeUserType")
		);

	if (
		areNotEqual &&
		typeof (action as any)[defaultSpecialActionKeyOfOtherTabsActions] ===
			"undefined"
	) {
		localStorage.setItem("user", JSON.stringify(newState));
		localStorage.setItem(
			defaultSpecialItemNameOfOtherTabsActions,
			JSON.stringify({
				...action,
				[defaultSpecialActionKeyOfOtherTabsActions]: Math.random(),
			})
		);
	}
	return newState;
}

export interface IUnauthentificatedUser {
	loggedIn?: false;
	userId?: undefined;
	coursesUserId?: undefined;
	userData?: undefined;
	coursesUserData?: undefined;
	activeUserType?: undefined;
}

export interface IAuthentificatedUser {
	loggedIn: true;
	userId: number;
	coursesUserId: number;
	userData: IRUser;
	coursesUserData: IRCoursesUser;
	activeUserType: UserType;
}

export type IStateUser = IUnauthentificatedUser | IAuthentificatedUser;
