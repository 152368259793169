import { groupArray } from "@app/utils/array";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import { IModel, ModelClass, ModelDocType, ModelIdType } from "m-model-core";

// type IModel = any;
// type ModelClass<A, B, C> = any;
// type ModelDocType<T> = any;
// type ModelIdType<T> = any;
// type InstanceType<T> = any;

export const createModelSingleCollmise = <
	ModelType extends ModelClass<any, any, any>,
	Doc = ModelDocType<ModelType>,
	IdType = ModelIdType<ModelType>
>({
	model,
	name,
	findCachedData,
}: {
	model: ModelType;
	name?: string;
	findCachedData?: (
		id: IdType
	) =>
		| InstanceType<ModelType>
		| Promise<InstanceType<ModelType> | null | undefined>
		| null
		| undefined;
}) => {
	return collmise<IdType, Doc, InstanceType<ModelType>>({
		findCachedData: findCachedData || (id => model.findByIdSync(id)),
		dataTransformer: doc => model.loadOneSync(doc),
		getNotFoundError: id =>
			new Error(`${name || "Document"} with id ${id} Not found`),
		collectingTimeoutMS: 6,
	});
};

export const createModelCollmise = <
	ModelType extends IModel,
	Doc = ModelDocType<ModelType>,
	IdType = ModelIdType<ModelType>
>({
	model,
	getMany,
	name,
	findCachedData,
	idKey: idKeyRaw,
}: {
	model: ModelType;
	getMany: (ids: IdType[]) => Promise<Doc[]>; //
	name?: string;
	findCachedData?: (
		id: IdType
	) =>
		| InstanceType<ModelType>
		| Promise<InstanceType<ModelType> | null | undefined>
		| null
		| undefined;
} & (ModelType extends ModelClass<infer IdKey, infer Doc, any>
	? IdKey extends "_id"
		? { idKey?: keyof Doc }
		: { idKey: keyof Doc }
	: { idKey?: undefined })) => {
	const idKey = idKeyRaw || "_id";
	return createModelSingleCollmise({
		name,
		model,
		findCachedData,
	}).addCollector<"many", Doc[], InstanceType<ModelType>[]>({
		name: "many",
		onRequest: getMany,
		findOne: (id, docs) => docs.find(e => e[idKey] === id),
		multiDataTransformer: (docs, ids) => model.loadManySync(docs, ids),
		mergeOnData: (many, cached) => [
			...(many || []),
			...cached.map(e => e.data),
		],
	});
};

// eslint-disable-next-line max-lines-per-function
export const createCoursedModelCollmise = <
	ModelType extends ModelClass<"_id", any, any>,
	MultiId extends string = "ids",
	Doc = ModelDocType<ModelType>,
	IdType = ModelIdType<ModelType>
>({
	model,
	getMany,
	name,
	multiId,
}: {
	model: ModelType;
	getMany: (
		ids: Record<MultiId, IdType[]> & {
			courseId: ObjectId;
		}
	) => Promise<Doc[]>; //
	name?: string;
	multiId?: MultiId;
}) => {
	const multiKey = ((multiId || "ids") as any) as MultiId;
	return collmise<
		{ _id: IdType; courseId: ObjectId },
		Doc,
		InstanceType<ModelType>
	>({
		findCachedData: query => model.findByIdSync(query),
		dataTransformer: doc => model.loadOneSync(doc),
		getNotFoundError: query =>
			new Error(`${name || "Document"} with id ${query._id} Not found`),
		collectingTimeoutMS: 6,
	}).addCollector<
		"many",
		Record<MultiId, IdType[]> & { courseId: ObjectId },
		Doc[],
		InstanceType<ModelType>[]
	>({
		name: "many",
		onRequest: getMany,
		findOne: (query, docs) => docs.find(e => e._id === query._id),
		multiDataTransformer: (docs, cluster) =>
			model.loadManySync(docs, cluster[multiKey]),
		mergeOnData: (many, cached) => [
			...(many || []),
			...cached.map(e => e.data),
		],
		splitInIdClusters: queries =>
			groupArray(
				queries,
				e => e.courseId,
				(grouped, courseId) => ({
					cluster: {
						courseId,
						[multiKey]: grouped.map(e => e._id),
					} as Record<MultiId, IdType[]> & {
						courseId: ObjectId;
					},
					ids: grouped,
				})
			),
		clusterToIds: cluster =>
			cluster[multiKey].map(id => ({
				courseId: cluster.courseId,
				_id: id,
			})),
	});
};
