/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-lines-per-function */
import { IUserWrittenAssignment } from "@app/api/assignments/helper-schemas";
import { IRGETPublicAssignmentByCodeWithIncorrcetKey } from "@app/api/assignments/validators";
import { UserType } from "@app/api/helper-schemas";
import { addLeadingZeroes } from "@app/common-javascript";
import {
	GeneralContext,
	MathEquationContext,
} from "@app/components/general-context";
import { ReactComponent as Calendar } from "@app/components/styles/img/icons/calendar.svg";
import { ReactComponent as QuestionIcon } from "@app/components/styles/img/icons/question.svg";
import { MarginContainer } from "@app/components/ui/containers";
import { ReactComponent as UserIcon } from "@app/components/users/styles/img/user.svg";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { FileBox } from "@app/components/widgets/files/file-box";
import { HalfMoon } from "@app/components/widgets/half-moon";
import {
	useClassroomsByIds,
	useClassroomsByIdsWithourFiltering,
} from "@app/hooks/classrooms";
import { useDays } from "@app/hooks/dates";
import {
	useGoToUrl,
	useHistory,
	useInput,
	useWindowSize,
} from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useSubject } from "@app/hooks/subjects";
import { useStudentTestByUserTestId, useTestById } from "@app/hooks/tests";
import { useClassroomsUser } from "@app/hooks/users";
import { Classroom } from "@app/models/classroom";
import { inject } from "@app/modules";
import { withEllipsis } from "@app/utils/common";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import styles from "./styles/index.module.scss";
import { PublicAssignmentTest } from "./test";
import { PublicTestType } from "@app/api/public-tests/helper-schemas";
import { TVSchoolPublicComplexAssignment } from "../complex";
import { TimerIcon } from "@app/icons";
import {
	getTimeWithZerosAndColon,
	getITimeWithZerosAndColon,
} from "@app/utils/dates";
import { getTimerForAssignment } from "../../tests/functions/timer";
import { showMathInstructionForSubject } from "@app/components/teachers/assignments-2/edit-assignments/math-instruction";

export const StudentPublicAssignment: React.FC<{
	assignmentCode: string;
}> = React.memo(({ assignmentCode }) => {
	const history = useHistory();
	const user = useClassroomsUser();
	const { value: fullname, onChange: onFullnameChange } = useInput(
		user ? `${user.firstname} ${user.lastname}` : ""
	);
	const { width } = useWindowSize();
	const {
		value: isAssignmentStarted,
		setTrue: startAssignment,
	} = useBoolean();
	const [complexAssignmentData, setComplexAssignmentData] = useState<{
		isComplex: boolean;
		isComplexStarted: boolean;
	}>({ isComplex: false, isComplexStarted: false });
	const assignmentInfo = usePublicAssignmentByCode(assignmentCode);
	const assignment = assignmentInfo?.assignment;
	const subjectId =
		(assignment && assignment.assignment!.subjects?.[0]) || null;
	const subject = useSubject(subjectId);
	const classrooms = useClassroomsByIdsWithourFiltering(
		assignment?.assignment.classroomIds || null
	);
	const days = useDays();
	const { onChange: onGeneralContextChange } = useContext(GeneralContext);
	const isLoggedStudent = user ? user.isStudent() : false;

	const isTeacherAndOwnsAssignment = useMemo(() => {
		if (!user?.isTeacher()) {
			return false;
		}
		if (!assignmentInfo?.assignment?.assignment._id) {
			return false;
		}
		if (classrooms.doc?.length !== 1) {
			return false;
		}
		return classrooms.doc[0].teacherId === user.id;
	}, [assignmentInfo, classrooms.doc, user]);

	const canBeEnrolledInClassroom = useMemo(() => {
		if (!classrooms.doc) return false;
		for (let i = 0; i < classrooms.doc.length; i++) {
			const element = classrooms.doc[i];
			if (!element.isEnrollable()) {
				return user
					? !!element.studentIds.find(e => e === user.id)
					: false;
			}
		}
		return true;
	}, [classrooms.doc, user]);

	const studentShouldEnrollInClassroom = useMemo(() => {
		if (
			!user ||
			!assignmentInfo ||
			!assignment ||
			!isLoggedStudent ||
			!assignment.assignment.classroomIds
		) {
			return false;
		}

		for (const classroomId of assignment.assignment.classroomIds) {
			if (user.canStudyInClassroom(classroomId)) return false;
		}
		return true;
	}, [assignmentInfo, assignment, isLoggedStudent, user]);

	const enroll = useCallback(async () => {
		if (canBeEnrolledInClassroom && studentShouldEnrollInClassroom) {
			await inject("ClassroomsController").enrollByAssignmentCode({
				code: assignmentCode,
			});
		}
	}, [
		assignmentCode,
		canBeEnrolledInClassroom,
		studentShouldEnrollInClassroom,
	]);

	useEffect(() => {
		if (canBeEnrolledInClassroom) {
			return onGeneralContextChange("authMetaData", val => {
				return {
					...val,
					assignmentCode,
				};
			});
		}
	}, [onGeneralContextChange, assignmentCode, canBeEnrolledInClassroom]);

	useEffect(() => {
		if (
			!assignmentInfo ||
			!assignmentInfo.assignment ||
			!assignmentInfo.assignment.assignment.publicTestId
		) {
			return;
		}
		inject("PublicTestsController")
			.getById({ id: assignmentInfo.assignment.assignment.publicTestId })
			.then(e => {
				if (e.type === PublicTestType.complex) {
					setComplexAssignmentData(cur => ({
						...cur,
						isComplex: true,
					}));
				}
			})
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, [assignmentInfo]);

	useEffect(() => {
		if (
			assignmentInfo &&
			assignment &&
			assignment.writtenAssignment &&
			assignment.writtenAssignment.teacherFeedBack
		) {
			inject("AssignmentsController")
				.updateWrittenAssignment({
					...assignment.writtenAssignment,
					teacherFeedBack: {
						...assignment.writtenAssignment.teacherFeedBack,
						hasStudentSeen: true,
					},
				})
				.catch(error =>
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					})
				);
		}
	}, [assignmentInfo, assignment]);

	const deadlinePassed = !!assignment?.deadlinePassed;

	const handleReviewAssignment = useCallback(() => {
		history.push(
			`/classrooms/${classrooms.doc![0]._id}/assignments/${
				assignmentInfo?.assignment?.assignment._id
			}/review`
		);
	}, [assignmentInfo, classrooms.doc, history]);

	const triggerAssignmentStart = useCallback(async () => {
		triggerEvent(
			{
				category: "Button",
				action: "startDoingHomework",
				label: "TVonClickStartDoingHomework",
			},
			{}
		);
		if (!fullname && !isLoggedStudent && !deadlinePassed) {
			openConfirmationPopup({
				text: getFormattedMessage("guestStudent.fillNameAndSurname"),
			});
			return;
		}
		try {
			await enroll();
			startAssignment();
		} catch (e) {
			openConfirmationPopup({
				text: getFormattedMessage("errorAlert"),
			});
		}
	}, [startAssignment, fullname, isLoggedStudent, enroll, deadlinePassed]);

	const handleComplexStart = useCallback(async () => {
		await enroll();
		setComplexAssignmentData(cur => ({ ...cur, isComplexStarted: true }));
	}, [enroll]);

	const handleAssignmentStart = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (complexAssignmentData.isComplex) {
				await handleComplexStart();
			} else {
				await triggerAssignmentStart();
			}
		},
		[
			complexAssignmentData.isComplex,
			handleComplexStart,
			triggerAssignmentStart,
		]
	);
	const test = useTestById(assignment?.assignment.testId || null);

	const userTest = useStudentTestByUserTestId(
		assignment?.writtenAssignment?.userTestId || null
	);

	if (!assignmentInfo) return <FancyLoadingCenter />;
	if (assignmentInfo && assignmentInfo.isIncorrectCode) {
		return (
			<div className={styles.incorrectCode}>
				<FormattedMessage id="incorrectCode" />
			</div>
		);
	}
	if (
		(assignmentInfo && !assignment && !assignmentInfo.isIncorrectCode) ||
		!assignment ||
		(subjectId && !subject.isSuccessfullyLoaded) ||
		(userTest.isIdentificationKnown && !userTest.doc) ||
		!test
	) {
		return <FancyLoadingCenter />;
	}

	if (complexAssignmentData.isComplexStarted) {
		return (
			<TVSchoolPublicComplexAssignment
				assignmentInfo={assignment}
				fullname={fullname}
				user={user || null}
			/>
		);
	}

	if (isAssignmentStarted) {
		const showMathEquation =
			!!subject.doc && showMathInstructionForSubject(subject.doc.name);
		return (
			<MathEquationContext.Provider value={showMathEquation}>
				<PublicAssignmentTest
					assignmentInfo={assignment}
					fullname={fullname}
					history={history}
					user={user || null}
					userTest={userTest.doc?.hasFound ? userTest.doc.test : null}
					test={test}
				/>
			</MathEquationContext.Provider>
		);
	}

	const isAssignmentCompleted =
		(assignment.isSubmitted || assignment.deadlinePassed) &&
		!assignment.writtenAssignment?.canBeRewritten;

	const date = assignment.assignment.createdAt;
	const availableFrom = assignment.assignment.settings.availableFrom;
	let isNotAvailable = availableFrom
		? availableFrom.getTime() > Date.now()
		: false;
	if (!assignment.content) {
		isNotAvailable = true;
	}

	const teacher = assignment.teacherInfo;

	const dateToStr = (d: Date) =>
		`${addLeadingZeroes(d.getDate(), 2)}.${addLeadingZeroes(
			d.getMonth() + 1,
			2
		)}.${d.getFullYear()}`;

	const isMobile = width < 600;
	const isTablet = width < 1000;

	const getStartTriggerButtonLabel = () => {
		if (assignmentInfo.assignment?.writtenAssignment?.canBeRewritten) {
			return getFormattedMessage("guestStudent.rewrite");
		}

		if (isAssignmentCompleted) {
			return isLoggedStudent
				? getFormattedMessage("guestStudent.workReview")
				: getFormattedMessage("guestStudent.answersReview");
		}
		if (
			Object.keys(assignment.writtenAssignment?.answeredQuestions || {})
				.length > 0
		) {
			return getFormattedMessage("continue");
		}
		return getFormattedMessage("start");
	};
	const timer = assignmentInfo.assignment?.settings.timer;
	return (
		<div>
			<HalfMoon className={styles["halfmoon-container"]}>
				<div className={styles["st-assignment-top"]}>
					{subject.doc && (
						<div
							className={styles.subject_icon}
							style={{
								backgroundImage: `url(${subject.doc.photo})`,
							}}
						/>
					)}
					<div className={styles.right}>
						{subject.doc && (
							<h2 className={styles.subject_title}>
								{subject.doc.name}
							</h2>
						)}
						<p className={styles.subject_theme_title}>
							{withEllipsis(
								assignment.assignment.name,
								isMobile ? 32 : isTablet ? 60 : 100
							)}
						</p>
					</div>
				</div>
			</HalfMoon>
			<div className="main main2">
				<div className={styles.subject_infobox_wrapper}>
					<div className={styles.subject_info_box}>
						<p className={styles.subject_info_box__title}>
							<Calendar
								style={{
									width: 18,
									marginRight: 5,
									transform: "translate(0, 2px)",
								}}
							/>
							{availableFrom ? (
								" გახსნის დრო"
							) : (
								<FormattedMessage id="createDate" />
							)}
						</p>
						<p className={styles.subject_info_box__content_time}>
							{dateToStr(availableFrom || date)}
							<span>
								{
									days[
										availableFrom
											? availableFrom.getDay()
											: date.getDay()
									]
								}
							</span>
						</p>
					</div>
					{timer && width >= 800 && (
						<div className={styles.subject_info_box}>
							<p className={styles.subject_info_box__title}>
								<TimerIcon style={{ width: "18px" }} />
								წამზომი
							</p>
							<div className={styles.infoBoxTimer}>
								<span className={styles.time}>
									{timer &&
										getITimeWithZerosAndColon(
											getTimerForAssignment(
												timer,
												assignment.assignment.settings
													.deadline
											),
											true
										)}
								</span>
								<div className={styles.titles}>
									<span className={styles.title}>საათი</span>
									<span className={styles.title}>წუთი</span>
								</div>
							</div>
						</div>
					)}
					{!complexAssignmentData.isComplex && (
						<div className={styles.subject_info_box}>
							<p className={styles.subject_info_box__title}>
								<QuestionIcon />
								<FormattedMessage id="numOfQuestions" />
							</p>
							<div
								className={
									styles.subject_info_box__content_questions
								}
							>
								{assignment.assignment.numQuestions}
							</div>
						</div>
					)}
					<div className={styles.subject_info_box}>
						<p className={styles.subject_info_box__title}>
							<UserIcon
								style={{
									width: "15px",
									color: "#8b8f9b",
									marginRight: "5px",
								}}
							/>
							<FormattedMessage id="teacher" />
						</p>
						<div
							className={styles.subject_info_box__content_author}
						>
							{teacher.id === undefined
								? teacher.fullname
								: `${teacher.firstname} ${teacher.lastname}`}
						</div>
					</div>
				</div>
				<form className={styles.tasks} onSubmit={handleAssignmentStart}>
					{canBeEnrolledInClassroom &&
					(!user || user?.isStudent()) ? (
						<React.Fragment>
							{!isLoggedStudent && !deadlinePassed && (
								<React.Fragment>
									<h3 className={styles.tasks_info}>
										<FormattedMessage id="guestStudent.mainText" />
									</h3>
									<input
										value={fullname}
										onChange={onFullnameChange}
										className={styles.tasks_input}
										placeholder={getFormattedMessage(
											"guestStudent.fillNameAndSurname"
										)}
									/>
								</React.Fragment>
							)}
							<AssignmentFeedBack
								isMobile={isMobile}
								writtenAssignment={assignment.writtenAssignment}
							/>
							<StartButton
								isNotAvailable={isNotAvailable}
								label={getStartTriggerButtonLabel()}
								triggerAssignmentStart={triggerAssignmentStart}
							/>
						</React.Fragment>
					) : (
						isTeacherAndOwnsAssignment && (
							<button
								className={styles.tasks_button}
								onClick={handleReviewAssignment}
							>
								გადახედვა
							</button>
						)
					)}
					{!canBeEnrolledInClassroom &&
						!isTeacherAndOwnsAssignment && (
							<h3 className={styles.tasks_info}>
								{isLoggedStudent
									? "თქვენ ვერ შეასრულებთ ამ დავალებას, რადგან არ ხართ ამ კლასის მოსწავლე."
									: "დავალების შესასრულებლად, გთხოვთ, გაიაროთ ავტორიზაცია."}
							</h3>
						)}
				</form>
			</div>
		</div>
	);
});

export const StartButton: React.FC<{
	label: string;
	triggerAssignmentStart: () => void;
	isNotAvailable: boolean;
}> = ({ isNotAvailable, label, triggerAssignmentStart }) => {
	return (
		<button
			className={styles.tasks_button}
			style={
				isNotAvailable
					? {
							cursor: "not-allowed",
							backgroundColor: "#eff1f7",
					  }
					: {}
			}
			onClick={
				isNotAvailable
					? e => e.preventDefault()
					: triggerAssignmentStart
			}
		>
			{label}
		</button>
	);
};

const AssignmentFeedBack: React.FC<{
	writtenAssignment: IUserWrittenAssignment | null | undefined;
	isMobile: boolean;
}> = React.memo(({ writtenAssignment, isMobile }) => {
	const isTextEmpty =
		writtenAssignment && writtenAssignment.teacherFeedBack
			? writtenAssignment.teacherFeedBack.text.length === 0
			: false;
	const isFileAttached =
		!!writtenAssignment?.teacherFeedBack?.attachedFiles &&
		writtenAssignment!.teacherFeedBack!.attachedFiles!.length > 0;
	console.log("attached files:");
	console.log(writtenAssignment?.teacherFeedBack?.attachedFiles);
	const robotoFont = "Roboto Geo Nus";
	const AttachedFiles = useMemo(
		() =>
			writtenAssignment?.teacherFeedBack ? (
				<div
					style={{
						fontFamily: robotoFont,
						color: " #626a84",
					}}
				>
					<p
						style={{
							fontSize: "16px",
							lineHeight: "1.31",
							margin: "20px 0",
							padding: "0 0 0 8px",
							textAlign: isTextEmpty ? "center" : undefined,
						}}
					>
						{isTextEmpty &&
							getFormattedMessage("teacherEstimation")}
						{!isTextEmpty && isFileAttached
							? getFormattedMessage("viewAttachedFiles")
							: null}
					</p>
					{writtenAssignment.teacherFeedBack.attachedFiles && (
						<MarginContainer
							itemsMargin={7}
							style={{
								display: "flex",
								width: "100%",
								flexFlow: "wrap",
							}}
							bottomMargin={10}
						>
							{writtenAssignment.teacherFeedBack.attachedFiles.map(
								e => {
									return (
										<FileBox
											fileName={e.fileName}
											fileUrl={e.fileUrl}
											canDownload
											showPreviewOnClick
										/>
									);
								}
							)}
						</MarginContainer>
					)}
				</div>
			) : null,
		[isTextEmpty, writtenAssignment]
	);

	if (!writtenAssignment || !writtenAssignment.teacherFeedBack) return null;

	return (
		<div>
			{isTextEmpty ? null : AttachedFiles}
			<div
				style={{
					fontFamily: robotoFont,
					fontSize: isMobile ? "14px" : "16px",
					textAlign: "justify",
					color: "#626a84",
					marginBottom: "20px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: "20px",
					border: isMobile ? undefined : "solid 1px #626a84",
					padding: isMobile ? "20px" : "20px 40px 30px",
				}}
			>
				{isTextEmpty ? (
					AttachedFiles
				) : (
					<div>
						<p
							style={{
								fontFamily: "Roboto Geo Caps",
								fontSize: isMobile ? "16px" : "18px",
								fontWeight: "bold",
								textAlign: "center",
								color: "#626a84",
								margin: "0 auto 15px",
								letterSpacing: ".5px",
							}}
						>
							{getFormattedMessage("teacherEstimation")}
						</p>
						<p
							style={{
								fontFamily: robotoFont,
								fontSize: isMobile ? "14px" : "16px",
								textAlign: "justify",
								color: "#626a84",
							}}
						>
							{writtenAssignment.teacherFeedBack.text}
						</p>
					</div>
				)}
			</div>
		</div>
	);
});

const usePublicAssignmentByCode = (code: string | null) => {
	const [resource, setResource] = useState<
		IRGETPublicAssignmentByCodeWithIncorrcetKey
	>();

	const gotoMainPage = useGoToUrl("");

	useEffect(() => {
		if (!code) return;
		let isCancelled = false;
		inject("AssignmentsController")
			.getByCode({ code })
			.then(data => {
				if (isCancelled) return;
				setResource(data);
			})
			.catch(error => {
				if (isCancelled) return;
				if (error?.response?.status !== 401) {
					throw error;
				}
				openConfirmationPopup({
					text: "თქვენ არ გაქვთ უფლება, დაწეროთ აღნიშნული დავალება",
					approveTitle: "გასაგებია",
					onClose: gotoMainPage,
				});
			});

		return () => {
			isCancelled = true;
		};
	}, [code, gotoMainPage]);
	return resource;
};
