import { withParams, addPrefix } from "link-builders";

export const publicLinks = addPrefix("")({
	landingPage: "/",
	login: "login",
	TOU: {
		video: "/open-uni/v",
		certificate: withParams(
			({ code }: { code: string }) => `open-uni/d/${code}`,
			`open-uni/d/:code`
		),
	},
	registration: {
		withCode: "registration-with-code",
	},
	dev: {
		classNames: "dev/cls",
	},
	ads: "ads",
	assignment: withParams(
		({ assignmentCode }: { assignmentCode: string }) =>
			`/s/hw/${assignmentCode}`,
		`/s/hw/:assignmentCode`
	),
});
