import { IRequest } from "../requests";

import {
	IAExportStats,
	IAGETStatsByClassroomId,
	IAPUTSaveFinalStat,
	IFinalStats,
} from "./helper-schemas";

export class FinalStatsController {
	constructor(private readonly Request: IRequest) {}

	getByClassroomId = async (
		args: IAGETStatsByClassroomId
	): Promise<IFinalStats> => {
		return this.Request.send("GET", "/api/final-stats/", args);
	};

	createOrUpdate = async (args: IAPUTSaveFinalStat): Promise<any> => {
		return await this.Request.send("PUT", "/api/final-stats/", args);
	};

	export = async (args: IAExportStats) => {
		return await this.Request.send("GET", "/api/final-stats/export", args, {
			responseType: "blob",
		});
	};
}
