import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CopyIcon from "@material-ui/icons/FileCopy";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export interface IQSettingTexts {
	multipleSelect: string;
	disableShuffle: string;
}

const MCSettingsHead: React.FC<IMCSettingsProps> = React.memo(props => (
	<>
		<FormControlLabel
			control={
				<Checkbox
					checked={props.canSelectMultiple}
					onChange={(e, c) => props.onMultipleSelectabilityChange(c)}
				/>
			}
			label={props.texts.multipleSelect}
		/>
		{props.onCopy && (
			<CopyIcon
				style={{ opacity: 0.1, cursor: "pointer", float: "right" }}
				onClick={props.onCopy}
			/>
		)}
	</>
));

export const MCSettingsTail: React.FC<IMCSettingsProps> = React.memo(props => (
	<FormControlLabel
		control={
			<Checkbox
				checked={props.disableShuffle}
				onChange={(e, c) => props.onDisablingShuffleChange(c)}
			/>
		}
		label={props.texts.disableShuffle}
	/>
));

export interface IMCSettingsProps {
	canSelectMultiple: boolean;
	onMultipleSelectabilityChange: (checked: boolean) => void;
	disableShuffle: boolean;
	onDisablingShuffleChange: (checked: boolean) => void;
	texts: IQSettingTexts;
	onCopy: () => void;
}

export { MCSettingsHead };
