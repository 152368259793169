import { UserType } from "@app/api/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { changeActiveTypeAction } from "@app/redux/actions/user";
import { store } from "index";

export const getActiveUserType = (): UserType | null => {
	return store.getState().user.activeUserType ?? null;
};

export const setActiveUserType = (newType: UserType) =>
	store.dispatch(changeActiveTypeAction(newType));

(window as any).setActiveUserType = setActiveUserType;
(window as any).getActiveUserType = getActiveUserType;

export const isActiveUserType = (userType: UserType) => {
	const current = getActiveUserType();
	if (!current) {
		return false;
	}
	return current === userType;
};

export const getRandomUserTypeFromPermissions = (
	persmissions: IRUser["permissions"]
): UserType | null => {
	if (Array.isArray(persmissions)) {
		if (persmissions.length === 0) return null;
		return getRandomUserTypeFromPermissions(persmissions[0]);
	}
	return persmissions.userType;
};

export const getAllUserTypesFromPermissions = (
	persmissions: IRUser["permissions"]
): UserType[] => {
	if (Array.isArray(persmissions)) {
		return persmissions.map(e => e.userType);
	}
	return [persmissions.userType];
};

export const getActiveUserTypeFromPermissions = (
	persmissions?: IRUser["permissions"] | null
): UserType | null => {
	if (!persmissions) return null;
	const userTypes = getAllUserTypesFromPermissions(persmissions);
	const active = getActiveUserType();
	if (userTypes.indexOf(active as UserType) > -1) {
		return active;
	}
	if (userTypes.length === 0) return null;
	return userTypes[0];
};
