/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useCallback, useContext } from "react";
import StudentRegisterStyles from "./styles/student-register-page.module.css";
import { useHistory, useLocationQuery, useWindowSize } from "@app/hooks/front";
import { useErrors, useBoolean } from "@app/hooks/general";
import {
	PasswordInput,
	MobileInput,
	Button,
	CodeInput,
	MailInput,
} from "./input-forms";
import { inject } from "@app/modules";
import { getJoiErrorItems } from "@app/utils/joi-errors";
import { getAuthErrors } from "./validations";
import classNames from "classnames";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { RegisterButton } from "../landing/register-type-selection";
import { ReactComponent as LogInIllustration } from "./styles/imgs/Log-In.svg";
import { ReactComponent as LogInIllustrationEngVersion } from "./styles/imgs/login-eng.svg";
import { useLocale } from "@app/hooks/intl";
import { MessageIllustration } from "../profile/mobile-verification";
import { InputLabel } from "./student-register-page";
import { GeneralContext } from "../general-context";
import { UserWAssignments } from "../users/assignments/tv-school/helpers/utils";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import triggerEvent from "@app/utils/events";

export interface IStudentLoginProps {
	onSuccess?: () => void;
	doNotRedirect?: boolean;
	welcomeTitle?: string | JSX.Element;
	classNames?: {
		button?: string;
		input?: string;
		container?: string;
	};
	isDivTitleHidden?: boolean;
	defaultCredentials?: {
		mobile: string | null;
		mail: string | null;
		password: string;
	};
	isIconVissible?: boolean;
	defaultValue?: string;
	placeholder?: string;
	isUpperTextVissible?: boolean;
	isWelcomeTitleVissible?: boolean;
	isRegisterPageOpen: boolean;
	hideRegisterPage: () => void;
	openRegisterPage: () => void;
	inputClassname?: string;
	isLowerMainQuestionVissible?: boolean;
	isIllustrationVissible?: boolean;
	isMessageIllustrationVissible?: boolean;
	switchForgotPassword?: (e: any) => void;
	isForgotPassword?: boolean;
	isRegisterButtonVissible?: boolean;
	registerButtonClassname?: string;
	forgetPasswordQuestionContainerClassname?: string;
	signInButtonClassname?: string;
	isFromTvSchoolLanding?: boolean;
	isFromTvSchoolPopUp?: boolean;
}

export const StudentLogin: React.FC<IStudentLoginProps> = props => {
	const { width } = useWindowSize();
	const local = useLocale();
	const currentWebsite = getCurrentWebsite();
	const isTvSchool = currentWebsite === WebsiteOrigin.tvSchool;
	const isNLVersion = local !== "ka" && isTvSchool;
	const history = useHistory();
	const locationQuery = useLocationQuery(true);
	const [isForgotPasswordClicked, setForgotPasswordClicked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isGeo = local === "ka";
	// const isForgotPasswordVissible = isGeo || isNLVersion;
	const isForgotPasswordVissible = isGeo;
	const {
		data: { authMetaData },
	} = useContext(GeneralContext);

	const credentials = useRef(
		props.defaultCredentials || {
			password: "",
			mobile: !isNLVersion ? "" : null,
			mail: isNLVersion ? "" : null,
		}
	);

	const { errors, removeErrorMessage, setErrors } = useErrors<
		typeof credentials.current
	>(null);

	const onPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.password = e.target.value;
			removeErrorMessage("password");
		},
		[removeErrorMessage]
	);
	const onPhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.mobile = e.target.value;
			removeErrorMessage("mobile");
		},
		[removeErrorMessage]
	);

	const onMailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			credentials.current.mail = e.target.value;
			removeErrorMessage("mail");
		},
		[removeErrorMessage]
	);
	let redirectTo =
		locationQuery && typeof locationQuery.red === "string"
			? decodeURI(locationQuery.red)
			: null;
	if (!redirectTo) {
		redirectTo =
			locationQuery && typeof locationQuery.redirect === "string"
				? decodeURI(locationQuery.redirect)
				: null;
	}

	const handleLogin = useCallback(() => {
		setIsLoading(true);
		const AuthController = inject("AuthController");

		const writtenAssignmentsInfo =
			authMetaData?.writtenAssignmentsInfo || UserWAssignments.getAll();
		AuthController.login({
			mobile: credentials.current.mobile,
			mail: credentials.current.mail,
			password: credentials.current.password,
			authMetaData: {
				...authMetaData,
				writtenAssignmentsInfo:
					writtenAssignmentsInfo && writtenAssignmentsInfo.length > 0
						? writtenAssignmentsInfo
						: undefined,
			},
		})
			.then(() => {
				if (
					!window.localStorage.getItem("invitedStudentPopup") &&
					authMetaData?.classroomEnrollmentCodes !== undefined
				) {
					window.localStorage.setItem("invitedStudentPopup", "1");
				}
				if (props.onSuccess) {
					props.onSuccess();
				}
				if (props.doNotRedirect) return;
				if (redirectTo !== null) {
					// TODO: check if redirectTo is safe (and does not contain url of another website)
					history.push(redirectTo);
				}
				props.isFromTvSchoolLanding
					? triggerEvent(
							{
								category: "Button",
								action: "login",
								label: "TVLandingattempt",
							},
							{ success: true }
					  )
					: triggerEvent(
							{
								category: "Button",
								action: "login",
								label: "Hubuattempt",
							},
							{ success: true }
					  );

				props.isFromTvSchoolPopUp &&
					triggerEvent(
						{
							category: "Button",
							action: "login",
							label: "TVPopupattempt",
						},
						{ success: true }
					);
			})
			.catch(e => {
				const joiErrors = getJoiErrorItems(e);
				if (joiErrors) {
					const errorMessages = getAuthErrors<
						typeof credentials.current
					>(joiErrors);
					setErrors(errorMessages);
				} else {
					if (e && e.response && e.response.data) {
						const data = e.response.data;
						if (data.password) {
							setErrors(e => ({
								...e,
								password: getFormattedMessage(
									"incorrectPassword"
								),
							}));
						}
						if (data.mobile) {
							setErrors(e => ({
								...e,
								mobile: getFormattedMessage("incorrectMobile"),
							}));
						}
						if (data.mail) {
							setErrors(e => ({
								...e,
								mail: getFormattedMessage("incorrectMail"),
							}));
						}
					}
				}
				setIsLoading(false);
			});
	}, [history, locationQuery, props, setErrors, authMetaData]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === "Enter") {
				handleLogin();
			}
		},
		[handleLogin]
	);

	if (!isForgotPasswordClicked) {
		return (
			<div
				className={
					props.isIllustrationVissible
						? StudentRegisterStyles.illustrationAndInnerBodyContainer
						: StudentRegisterStyles.innerBody
				}
			>
				{props.isIllustrationVissible &&
					width > 700 &&
					local === "ka" && (
						<LogInIllustration
							className={
								StudentRegisterStyles.logInIllustrationContainer
							}
						/>
					)}
				{props.isIllustrationVissible &&
					width > 700 &&
					local === "en" && (
						<LogInIllustrationEngVersion
							className={
								StudentRegisterStyles.logInIllustrationContainer
							}
						/>
					)}
				<div
					className={
						props.isIllustrationVissible
							? StudentRegisterStyles.registerPageInPopup
							: classNames(
									StudentRegisterStyles.registerPage,
									props.classNames &&
										props.classNames.container
							  )
					}
					tabIndex={0}
					onKeyDown={onKeyDown}
				>
					{!props.isDivTitleHidden && (
						<div
							className={
								isNLVersion
									? StudentRegisterStyles.registerNLD
									: StudentRegisterStyles.register
							}
						>
							<FormattedMessage id="signInTitle" />
						</div>
					)}
					{props.isWelcomeTitleVissible && (
						<div
							className={
								isNLVersion
									? StudentRegisterStyles.welcomeTitleNLD
									: StudentRegisterStyles.welcomeTitle
							}
						>
							{props.welcomeTitle || (
								<FormattedMessage id="landing:welcome" />
							)}
						</div>
					)}

					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						{isNLVersion ? (
							<InputLabel
								label={getFormattedMessage("inputs.mail")}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<MailInput
										onChange={onMailChange}
										errorText={errors && errors.mail}
										isDisabled={isLoading}
										className={
											props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										defaultValue={
											credentials.current.mail || ""
										}
										placeholder={
											props.placeholder ||
											getFormattedMessage("inputs.mail")
										}
										mainContainerWrapperClassname={
											StudentRegisterStyles.mainContainerWrapperClassname
										}
										inputRef={ref}
									/>
								)}
							</InputLabel>
						) : (
							<InputLabel
								label={getFormattedMessage("inputs.mobile")}
								isLabelHidden={!props.isUpperTextVissible}
							>
								{ref => (
									<MobileInput
										onChange={onPhoneChange}
										errorText={errors && errors.mobile}
										isDisabled={isLoading}
										className={
											props.inputClassname
												? props.inputClassname
												: StudentRegisterStyles.input
										}
										defaultValue={
											credentials.current.mobile || ""
										}
										placeholder={
											props.placeholder ||
											getFormattedMessage("inputs.mobile")
										}
										mainContainerWrapperClassname={
											StudentRegisterStyles.mainContainerWrapperClassname
										}
										inputRef={ref}
									/>
								)}
							</InputLabel>
						)}
					</div>

					<div
						className={
							StudentRegisterStyles.registerInputAndUpperText
						}
					>
						<InputLabel
							label={getFormattedMessage("inputs.password")}
							isLabelHidden={!props.isUpperTextVissible}
						>
							{ref => (
								<PasswordInput
									onChange={onPasswordChange}
									errorText={errors && errors.password}
									isDisabled={isLoading}
									className={
										props.inputClassname
											? props.inputClassname
											: StudentRegisterStyles.input
									}
									defaultValue={credentials.current.password}
									placeholder={
										props.placeholder ||
										getFormattedMessage("inputs.password")
									}
									mainContainerWrapperClassname={
										StudentRegisterStyles.mainContainerWrapperClassname
									}
									inputRef={ref}
									isIconVissible={true}
								/>
							)}
						</InputLabel>
					</div>

					{width > 800 && isForgotPasswordVissible && (
						<div
							className={
								props.forgetPasswordQuestionContainerClassname
									? props.forgetPasswordQuestionContainerClassname
									: props.inputClassname
									? StudentRegisterStyles.secondaryActioniInPopup
									: StudentRegisterStyles.secondaryAction
							}
							onClick={() => setForgotPasswordClicked(true)}
						>
							<FormattedMessage id="forgotPassword?" />
						</div>
					)}
					<div
						className={StudentRegisterStyles.actionButtonContainer}
					>
						<Button
							isLoading={isLoading}
							onClick={handleLogin}
							text={getFormattedMessage("signIn")}
							className={
								props.signInButtonClassname
									? props.signInButtonClassname
									: StudentRegisterStyles.singIn
							}
						/>
					</div>
					{width <= 800 && isForgotPasswordVissible && (
						<div
							className={
								props.forgetPasswordQuestionContainerClassname
									? props.forgetPasswordQuestionContainerClassname
									: props.inputClassname
									? StudentRegisterStyles.secondaryActioniInPopup
									: StudentRegisterStyles.secondaryAction
							}
							onClick={() => setForgotPasswordClicked(true)}
						>
							<FormattedMessage id="forgotPassword?" />
						</div>
					)}
					{props.isLowerMainQuestionVissible && (
						<div
							className={
								isNLVersion
									? StudentRegisterStyles.areYouREgisteredQuestionandButtonNLD
									: StudentRegisterStyles.areYouREgisteredQuestionandButton
							}
							onClick={props.openRegisterPage}
						>
							<FormattedMessage id="landing:areYouRegisteredQuestion" />
						</div>
					)}
					{!props.isLowerMainQuestionVissible &&
						props.isRegisterButtonVissible &&
						width > 800 && (
							<RegisterButton
								openRegistrationPopup={props.openRegisterPage}
								buttonClassname={
									props.registerButtonClassname ||
									StudentRegisterStyles.registerButtonInpopup
								}
							/>
						)}
				</div>
			</div>
		);
	}
	return (
		<ForgotPassword
			defaultMobile={credentials.current.mobile}
			defaultMail={credentials.current.mail}
			isMessageIllustrationVissible={props.isMessageIllustrationVissible}
			onReset={() => redirectTo && history.push(redirectTo)}
		/>
	);
};

const ForgotPassword: React.FC<{
	defaultMobile?: string | null;
	defaultMail?: string | null;
	isMessageIllustrationVissible?: boolean;
	onReset?: () => void;
}> = props => {
	const [isInputMobileClicked, setIsInputMobileClicked] = useState(false);
	const [didPasswordUpdate, setDidPasswordUpdate] = useState(false);
	const mobileRef = useRef(props.defaultMobile || "");
	const mailRef = useRef(props.defaultMail || "");
	const codeRef = useRef("");
	const passwordRef = useRef("");
	const repeatedPasswordRef = useRef("");

	if (!isInputMobileClicked) {
		return (
			<CheckCode
				setIsInputMobileClicked={setIsInputMobileClicked}
				mobileRef={mobileRef}
				mailRef={mailRef}
				isIllustrationVissible={props.isMessageIllustrationVissible}
			/>
		);
	}
	if (!didPasswordUpdate) {
		return (
			<CheckPasswordReset
				setDidPasswordUpdate={setDidPasswordUpdate}
				onReset={props.onReset}
				mobileRef={mobileRef || ""}
				codeRef={codeRef}
				passwordRef={passwordRef}
				mailRef={mailRef}
				repeatedPasswordRef={repeatedPasswordRef}
			/>
		);
	}
	return (
		<div className={StudentRegisterStyles.registerPage} tabIndex={0}>
			<div className={StudentRegisterStyles.resetPasswordTitle}>
				<FormattedMessage id="refreshPassword" />{" "}
			</div>
		</div>
	);
};

interface ICheckCodeProps {
	setIsInputMobileClicked: (args: boolean) => void;
	mobileRef?: React.MutableRefObject<string> | null;
	mailRef?: React.MutableRefObject<string> | null;
	isIllustrationVissible?: boolean;
}

const CheckCode: React.FC<ICheckCodeProps> = props => {
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const locale = useLocale();
	const currentWebsite = getCurrentWebsite();
	const isTvSchool = currentWebsite === WebsiteOrigin.tvSchool;
	const isByMail = locale !== "ka" && isTvSchool;
	const isDutch = locale === "nl";
	const isEng = locale === "en";

	const { errors, setErrors } = useErrors<{
		mobile?: string;
		mail?: string;
	}>(null);

	const sendCodeRequest = useCallback(() => {
		const AuthController = inject("AuthController");
		startLoading();
		AuthController.sendConfirmationCode({
			mobile: isByMail
				? null
				: props.mobileRef && props.mobileRef.current,
			mail: isByMail ? props.mailRef && props.mailRef.current : null,
			senderMail: isDutch ? "info@mijntaken.com" : "info@lupiai.com",
			subject: isDutch ? "Uw wachtwoord herstellen" : "Verification code",
		})
			.then(() => {
				props.setIsInputMobileClicked(true);
				stopLoading();
			})
			.catch(e => {
				if (e.response && e.response.data === "user not found") {
					setErrors({
						mobile: getFormattedMessage("incorrectMobile"),
						mail: getFormattedMessage("incorrectMobile"),
					});
				}
				stopLoading();
			});
	}, [props, setErrors, startLoading, stopLoading]);

	const onPhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (props.mobileRef) {
				props.mobileRef.current = e.target.value;
			}
		},
		[props.mobileRef]
	);

	const onMailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (props.mailRef) {
				props.mailRef.current = e.target.value;
			}
		},
		[props.mailRef]
	);

	return (
		<>
			<div
				className={StudentRegisterStyles.resetPasswordContainer}
				tabIndex={0}
			>
				<div
					className={
						props.isIllustrationVissible
							? StudentRegisterStyles.resetPasswordTitleBold
							: StudentRegisterStyles.resetPasswordTitle
					}
				>
					{isByMail ? (
						<FormattedMessage id="fillYourMail" />
					) : (
						<FormattedMessage id="resetPasswordTitle" />
					)}
				</div>
				{props.isIllustrationVissible && (
					<div
						className={
							StudentRegisterStyles.messageIllustrationContainer
						}
					>
						{" "}
						<MessageIllustration />
					</div>
				)}

				{isByMail ? (
					<MailInput
						onChange={onMailChange}
						errorText={errors && errors.mail}
						isDisabled={isLoading}
						defaultValue={
							props.mailRef ? props.mailRef.current : ""
						}
					/>
				) : (
					<MobileInput
						onChange={onPhoneChange}
						errorText={errors && errors.mobile}
						isDisabled={isLoading}
						defaultValue={
							props.mobileRef ? props.mobileRef.current : ""
						}
					/>
				)}

				<Button
					isLoading={isLoading}
					onClick={() => sendCodeRequest()}
					text={getFormattedMessage("formCompleteCTA")}
					className={StudentRegisterStyles.resetPasswordButton}
				/>
			</div>
		</>
	);
};

interface ICheckPasswordResetProps {
	setDidPasswordUpdate: (args: boolean) => void;
	mobileRef?: React.MutableRefObject<string> | null;
	mailRef?: React.MutableRefObject<string> | null;
	codeRef: React.MutableRefObject<string>;
	passwordRef: React.MutableRefObject<string>;
	repeatedPasswordRef: React.MutableRefObject<string>;
	onReset?: () => void;
}

const CheckPasswordReset: React.FC<ICheckPasswordResetProps> = props => {
	const currentWebsite = getCurrentWebsite();
	const isTvSchool = currentWebsite === WebsiteOrigin.tvSchool;
	const isNLVersion = useLocale() !== "ka" && isTvSchool;

	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const { errors, removeErrorMessage, setErrors } = useErrors<{
		code?: string;
		password?: string;
		repeatedPassword?: string;
	}>(null);

	const onCodeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			props.codeRef.current = e.target.value;
			removeErrorMessage("code");
		},
		[props.codeRef, removeErrorMessage]
	);

	const onPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			props.passwordRef.current = e.target.value;
			removeErrorMessage("password");
		},
		[props.passwordRef, removeErrorMessage]
	);

	const onRepeatedPasswordChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			props.repeatedPasswordRef.current = e.target.value;
			removeErrorMessage("password");
		},
		[props.repeatedPasswordRef, removeErrorMessage]
	);

	const {
		data: { authMetaData },
	} = useContext(GeneralContext);

	const resetPassword = useCallback(() => {
		if (props.codeRef.current.length < 1) {
			setErrors({
				code: getFormattedMessage("validations.emptyCode"),
			});
			return;
		} else if (props.passwordRef.current.length < 5) {
			setErrors({
				password: getFormattedMessage("passwordLength", {
					requiredLength: 5,
				}),
			});
			return;
		} else if (
			props.passwordRef.current !== props.repeatedPasswordRef.current
		) {
			setErrors({
				repeatedPassword: getFormattedMessage("passwordDontMatch"),
			});
			return;
		}

		const writtenAssignmentsInfo =
			authMetaData?.writtenAssignmentsInfo || UserWAssignments.getAll();
		startLoading();
		const AuthController = inject("AuthController");
		AuthController.resetPassword({
			mobile: isNLVersion
				? null
				: props.mobileRef && props.mobileRef.current,
			mail: isNLVersion ? props.mailRef && props.mailRef.current : null,
			code: props.codeRef.current,
			newPassword: props.passwordRef.current,
			authMetaData: {
				...authMetaData,
				writtenAssignmentsInfo:
					writtenAssignmentsInfo && writtenAssignmentsInfo.length > 0
						? writtenAssignmentsInfo
						: undefined,
			},
		})
			.then(() => {
				props.setDidPasswordUpdate(true);
				stopLoading();
				props.onReset?.();
			})
			.catch(e => {
				if (
					e.response &&
					(e.response.data ===
						"incorrect code and mobile combination" ||
						e.response.data ===
							"incorrect code and mail combination")
				) {
					setErrors({
						code: getFormattedMessage("incorrectVerifCode"),
					});
				}

				stopLoading();
			});
	}, [
		props,
		authMetaData,
		startLoading,
		isNLVersion,
		setErrors,
		stopLoading,
	]);

	return (
		<div className={StudentRegisterStyles.registerPage} tabIndex={0}>
			<div className={StudentRegisterStyles.resetPasswordTitle}>
				<FormattedMessage id="receiveCode" />
			</div>
			<CodeInput
				onChange={onCodeChange}
				isDisabled={isLoading}
				errorText={!errors ? undefined : errors.code}
			/>
			<div
				className={StudentRegisterStyles.register}
				style={{ marginTop: 20 }}
			>
				<FormattedMessage id="enterNewPassword" />
			</div>
			<PasswordInput
				onChange={onPasswordChange}
				isDisabled={isLoading}
				errorText={!errors ? undefined : errors.password}
			/>
			<div
				className={StudentRegisterStyles.register}
				style={{ marginTop: 20 }}
			>
				<FormattedMessage id="inputs.repeatPassword" />
			</div>
			<PasswordInput
				onChange={onRepeatedPasswordChange}
				isDisabled={isLoading}
				errorText={!errors ? undefined : errors.repeatedPassword}
			/>
			<Button
				isLoading={isLoading}
				onClick={resetPassword}
				text={getFormattedMessage("formCompleteCTA")}
			/>
		</div>
	);
};
