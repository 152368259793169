import { removeKeys } from "@app/utils/common";
import { join } from "link-builders";
import { AnyRoute, AnyRouteOptional, BasicRoute } from "./interfaces";

interface RouteConfig {
	basePath?: string;
	exact: boolean;
	RouteComponent: BasicRoute["RouteComponent"];
	componentProps?: Record<any, any>;
	routeProps?: Record<any, any>;
	avoidSwitch?: boolean;
	componentWrapper?: (
		comp: any
	) => React.LazyExoticComponent<any> | React.ComponentType<any>;
	routeMapFn?: undefined;
}

type RouteOptionalKeys = "RouteComponent" | "exact";

interface MappedRouteConfig<AdditionalProps extends Record<any, any>>
	extends Omit<RouteConfig, "routeMapFn"> {
	routeMapFn: (route: AdditionalProps & AnyRoute) => AnyRoute;
}

interface RouteList<AdditionalProps extends Record<any, any> = {}> {
	add: (
		...routes: (AdditionalProps & AnyRouteOptional<RouteOptionalKeys>)[]
	) => void;
	addRaw: (route: AnyRoute) => void;
	toArray: () => AnyRoute[];
}

export function createRouteList(config: RouteConfig): RouteList;
export function createRouteList<AdditionalProps extends Record<any, any>>(
	config: MappedRouteConfig<AdditionalProps>
): RouteList<AdditionalProps>;
export function createRouteList(
	config: RouteConfig | MappedRouteConfig<any>
): RouteList {
	const list: AnyRoute[] = [];

	return {
		add: (...routes: AnyRouteOptional<RouteOptionalKeys>[]) => {
			for (const route of routes) {
				const anyRoute = turnToRoute(route, config);
				list.push(anyRoute);
			}
		},
		addRaw: (route: AnyRoute) => {
			list.push(route);
		},
		toArray: () => list,
	};
}

const turnToRoute = (
	route: AnyRouteOptional<RouteOptionalKeys>,
	config: RouteConfig | MappedRouteConfig<any>
): AnyRoute => {
	const transform = config.routeMapFn || defFunc;
	if (route.$switch) {
		return transform({
			...route,
			$switch: route.$switch.map(r =>
				turnToRoute(r, removeKeys(config, "avoidSwitch") as RouteConfig)
			),
		});
	}
	const { basePath, routeMapFn, ...restConfig } = config;

	const component = config.componentWrapper
		? config.componentWrapper(route.component)
		: route.component;

	return transform({
		...restConfig,
		...(route as BasicRoute),
		component,
		path: join(basePath ? "" : "/", basePath || "", route.path || ""),
	});
};

const defFunc = <T extends any>(val: T): T => val;
