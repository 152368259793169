import React, { useCallback } from "react";
import LogOutSvg from "./general_imgs/LogOut.svg";
import genStyles from "../general_components/styles/general_styles.module.css";
import { useHistory } from "@app/hooks/front";
import { inject } from "@app/modules";

const LogOut = () => {
	const history = useHistory();
	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout();
		history.push(`/`);
	}, [history]);
	return (
		<section
			className={genStyles.desktop_log_out_container}
			onClick={onLogout}
		>
			<p className={genStyles.log_out_text}>გასვლა</p>
			<img
				src={LogOutSvg}
				alt="გასვლა"
				className={genStyles.log_out_icon}
			/>
		</section>
	);
};

export default LogOut;
