import { css } from "emotion";

export const iconClassName = css({
	fill: "currentColor",
	width: "1em",
	height: "1em",
	display: "inline-block",
	fontSize: 24,
	transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
	userSelect: "none",
	flexShrink: 0,
});
