import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { markKeysForbidden } from "../../helper-schemas";
import { BasicDocument, BasicDocumentSchema } from "../../interface";
import { ISubject, SubjectSchema } from "../../subjects/helper-schemas";

export const WeeklyTestSubjectMetaSchema = BasicDocumentSchema.keys({
	subjectId: Joi.objectId().required(),
	imageUrl: Joi.string()
		.allow(null)
		.required(),
});
export interface IWeeklyTestSubjectMeta extends BasicDocument {
	subjectId: ObjectId;
	imageUrl: string | null;
}

export const WeeklyTestSubjectSchema = SubjectSchema.keys({
	meta: WeeklyTestSubjectMetaSchema.fork(
		["_id", "subjectId"],
		markKeysForbidden
	),
});
export interface IWeeklyTestSubject extends ISubject {
	meta: Omit<IWeeklyTestSubjectMeta, "_id" | "subjectId">;
}
